import * as React from 'react';
import { SelectInputGroup, selectListItem } from './select-input-group';
import { TextInputGroup } from './text-input-group';

export type inputGroup = {
    labelText?: string;
    validationText?: string;
};

interface IMailingListInputGroup {
    form: formInputGroupStructure;
    submitText?: string;
    onFinish(dataSet: unknown): void;
    onFieldValidityCheck?(value: string, id: string): boolean;
}

type formInputGroupItem = {
    validity: boolean;
    value: string;
    useDatePicker?: boolean;
    minDate?: string;
    selectList?: selectListItem[];
    externalGroup: inputGroup;
    htmlProps?: unknown;
    regex?: RegExp;
    customRender?(jsx: React.ReactNode): JSX.Element;
};

export type formInputGroupStructure = {
    [fieldName: string]: formInputGroupItem;
};

/**
 * Constructs only the necessary components of the mailing list inputs
 *
 * To add a new field, add it to the internal form structure object and
 * the external props typing
 */
export class FormInputGroupBuilder extends React.Component<IMailingListInputGroup> {

    private _formStructure: formInputGroupStructure = {};

    constructor(props: IMailingListInputGroup) {
        super(props);

        this._formStructure = props.form;
        this._handleFormExport = this._handleFormExport.bind(this);

        this._handleValidityUpdate = this._handleValidityUpdate.bind(this);
        this._handleValueFinish = this._handleValueFinish.bind(this);

        this._handleSelectionUpdate = this._handleSelectionUpdate.bind(this);
        this._handleSelectionValidity = this._handleSelectionValidity.bind(this);
    }

    public render(): JSX.Element {
        const { submitText } = this.props;
        return (
            <React.Fragment>
                {Object.keys(this._formStructure).map(fieldName => {
                    const field = this._formStructure[fieldName];
                    if (field.customRender) {
                        return field.customRender(this._renderField(fieldName, field));
                    } else {
                        return this._renderField(fieldName, field);
                    }
                })}
                <button
                    className='input-group-button btn btn-primary'
                    onClick={this._handleFormExport}
                    disabled={!this._isFormValid()}
                >
                    {submitText || 'SUBMIT'}
                </button>
            </React.Fragment>
        );
    }

    private _renderField(fieldName: string, field: formInputGroupItem): JSX.Element {
        if (field.selectList && field.selectList.length > 0) {
            const defaultValue = field.selectList.find(item => item.id === field.value);
            return (
                <SelectInputGroup
                    key={fieldName}
                    id={fieldName}
                    initialSelect={defaultValue || undefined}
                    label={field.externalGroup.labelText}
                    selectList={field.selectList}
                    onChange={this._handleSelectionUpdate}
                    isValid={this._handleSelectionValidity}
                    htmlProps={field.htmlProps as React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> || {}}
                />
            );
        } else {
            return (
                <TextInputGroup
                    key={fieldName}
                    label={field.externalGroup.labelText}
                    id={fieldName}
                    useDatePicker={field.useDatePicker}
                    minDate={field.minDate}
                    initialValue={field.value || ''}
                    onChange={this._handleValueFinish}
                    isValid={this._handleValidityUpdate}
                    htmlProps={field.htmlProps as React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> || {}}
                />
            );
        }
    }

    private _handleFormExport(): void {
        const formData = {};
        Object.keys(this._formStructure).forEach(input => {
            formData[input] = this._formStructure[input].value;
        });
        this.props.onFinish(formData);
    }

    private _isFormValid(): boolean {
        const containsInvalidField = !!Object.keys(this._formStructure)
            .find((fieldName) => {
                const field = this._formStructure[fieldName];
                return !field.validity;
            });
        return !containsInvalidField;
    }

    private _handleSelectionUpdate(value: selectListItem, isValid: boolean, id: string): void {
        const fieldData = this._formStructure[id];
        fieldData.validity = isValid;
        fieldData.value = value.id;

        this.setState({});
    }

    private _handleSelectionValidity(value: selectListItem, id: string): void {
        // no validation necessary
        return;
    }

    private _handleValueFinish(value: string, isValid: boolean, id: string): void {
        const fieldData = this._formStructure[id];
        fieldData.validity = isValid;
        fieldData.value = value;

        // re-render, no reason to use mobx for this
        this.setState({});
    }

    private _handleValidityUpdate(value: string, id: string): string | void {
        const fieldData = this._formStructure[id];

        // should always be true but just in case
        if (fieldData) {
            const validationText = fieldData.externalGroup.validationText;
            if (this.props.onFieldValidityCheck) {
                const passedCheck = this.props.onFieldValidityCheck(value, id);
                if (!passedCheck) { return validationText; }
            }
            if (value.length === 0) { return validationText; }
            if (fieldData.regex && !fieldData.regex.test(value)) { return validationText; }
        }
    }
}