import {
  GenericInput,
  getGenericAction
} from '@msdyn365-commerce-modules/retail-actions';
import {
  createObservableDataAction,
  IAction,
  IActionInput,
  IAny,
  ICreateActionContext,
  IGeneric
} from '@msdyn365-commerce/core';

export function createSkuSelectorContainerClasses(
  result: string
): GenericInput<string> {
  return new GenericInput<string>('SkuSelectorClasses', result, 'String');
}

const createSkuSelectorContainerClassesInput = (
  inputData: ICreateActionContext<IGeneric<IAny>>
): IActionInput => {
  return createSkuSelectorContainerClasses('');
};

export default createObservableDataAction({
  action: <IAction<string>>getGenericAction,
  input: createSkuSelectorContainerClassesInput
});
