import { Module } from '@msdyn365-commerce-modules/utilities';
import * as React from 'react';
import { IIframeViewProps } from './smwe-iframe';

/**
 *
 * IFrameView component
 * @extends {React.PureComponent<IIframeViewProps>}
 */
export class IFrameView extends React.PureComponent<IIframeViewProps> {

    public render(): JSX.Element | null {
        const { Heading, IframeContainer, IFrame, alert, cookieConsentAccepted } = this.props;

        return (
            <Module {...IframeContainer} style={{height: this.props.config.height}}>
                {alert}
                {cookieConsentAccepted && cookieConsentAccepted === true ? (
                    <>
                        {Heading}
                        {IFrame}
                    </>) : null}
            </Module>
        );
    }
}
export default IFrameView;