import { observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';

export type selectListItem = {
    label: string;
    id: string;
};

interface ISelectInputGroupProps {
    id: string;
    label?: string;
    selectList: selectListItem[];
    initialSelect?: selectListItem;
    htmlProps?: React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>;
    isValid(selection: selectListItem, id: string): string | void;
    onChange(selection: selectListItem, currentValidity: boolean, id: string): void;
}

/**
 * TextInputGroup is a wrapper that allows you to validate text values from the
 * user and returns the correct value after
 */
@observer
export class SelectInputGroup extends React.Component<ISelectInputGroupProps> {

    @observable private _internalValue: selectListItem;
    @observable private _errorMessage: string = '';
    @observable private _isFirstBlur: boolean = true;

    constructor(props: ISelectInputGroupProps) {
        super(props);

        if (props.initialSelect) {
            this._internalValue = props.initialSelect;
        } else {
            this._internalValue = props.selectList.find(select => !!select)!;
        }

        this._valueChanged = this._valueChanged.bind(this);
        this._blurred = this._blurred.bind(this);
    }

    public render(): JSX.Element {
        return (
            <div className='form-group select-input-group'>
                <label>
                    {this.props.label}
                </label>
                <select
                    className='form-control'
                    onBlur={this._blurred}
                    value={this._internalValue.id}
                    onChange={this._valueChanged}
                    {...this.props.htmlProps}
                >
                    {
                        this.props.selectList
                            .map(
                                (select, key) =>
                                    <option
                                        value={select.id}
                                        aria-selected={select.id === this._internalValue.id}
                                        key={key}
                                    >
                                        {select.label}
                                    </option>)
                    }
                </select>
                {this._jsxErrorMessage}
            </div>
        );
    }

    private _blurred(event: React.ChangeEvent<HTMLSelectElement>): void {
        this._isFirstBlur = false;
        this._valueChanged(event);
    }

    private _valueChanged(event: React.ChangeEvent<HTMLSelectElement>): void {
        const id = event.target.value;
        this._internalValue = this.props.selectList.find(select => select.id === id)!;
        const currentlyInvalid = this.props.isValid(this._internalValue, this.props.id);
        const isValid = (currentlyInvalid) ? false : true;

        // we only want to show an error if a user has clicked away and its
        // still wrong
        if (currentlyInvalid && !this._isFirstBlur) {
            this._errorMessage = currentlyInvalid;
        } else {
            this._errorMessage = '';
        }

        this.props.onChange(this._internalValue, isValid, this.props.id);
    }

    private get _jsxErrorMessage(): JSX.Element | void {
        if (this._errorMessage.length > 0) {
            return (
                <p className='alert alert-danger text-input-validation-error'>
                    {this._errorMessage}
                </p>
            );
        }
    }
}