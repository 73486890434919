
    import * as Entities from './CommerceTypes.g';
    import { jsonLightReadStringPropertyValue } from '../externals';

    const entityDictionary = {};
    
      /**
      * Category entity class.
      */
      export class CategoryClass implements Entities.Category {
      public RecordId: number;
      public OfflineImage: string;
      public Name: string;
      public ParentCategory: number;
      public DisplayOrder: number;
      public LocalizedDescription: string;
      public Images: Entities.MediaLocation[];
      public NameTranslations: Entities.TextValueTranslation[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.OfflineImage = 
                odataObject.OfflineImage;
              
            this.Name = 
                odataObject.Name;
              
            this.ParentCategory = 
                (odataObject.ParentCategory != null) ? parseInt(odataObject.ParentCategory, 10) : undefined;
              
            this.DisplayOrder = 
                (odataObject.DisplayOrder != null) ? parseFloat(odataObject.DisplayOrder) : undefined;
              
            this.LocalizedDescription = 
                odataObject.LocalizedDescription;
              
        this.Images = undefined;
        if (odataObject.Images) {
        this.Images = [];
        for (var i = 0; i < odataObject.Images.length; i++) {
        if (odataObject.Images[i] != null) {
        if (odataObject.Images[i]['@odata.type'] != null) {
          var className: string = odataObject.Images[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Images[i] = new entityDictionary[className](odataObject.Images[i])
          }
        } else {
            this.Images[i] = new MediaLocationClass(odataObject.Images[i]);
        }
                    } else {
        this.Images[i] = undefined;
        }
        }
        }
      
        this.NameTranslations = undefined;
        if (odataObject.NameTranslations) {
        this.NameTranslations = [];
        for (var i = 0; i < odataObject.NameTranslations.length; i++) {
        if (odataObject.NameTranslations[i] != null) {
        if (odataObject.NameTranslations[i]['@odata.type'] != null) {
          var className: string = odataObject.NameTranslations[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.NameTranslations[i] = new entityDictionary[className](odataObject.NameTranslations[i])
          }
        } else {
            this.NameTranslations[i] = new TextValueTranslationClass(odataObject.NameTranslations[i]);
        }
                    } else {
        this.NameTranslations[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CategoryClass'] = CategoryClass;
    
      /**
      * Cart entity class.
      */
      export class CartClass implements Entities.Cart {
      public AffiliationLines: Entities.AffiliationLoyaltyTier[];
      public IsRequiredAmountPaid: boolean;
      public IsDiscountFullyCalculated: boolean;
      public IgnoreDiscountCalculation: boolean;
      public AmountDue: number;
      public AmountPaid: number;
      public AttributeValues: Entities.AttributeValueBase[];
      public IsTaxIncludedInPrice: boolean;
      public BeginDateTime: Date;
      public BusinessDate: Date;
      public CancellationChargeAmount: number;
      public EstimatedShippingAmount: number;
      public CartLines: Entities.CartLine[];
      public CartTypeValue: number;
      public ChannelId: number;
      public ChargeAmount: number;
      public CustomerOrderRemainingBalance: number;
      public ChargeLines: Entities.ChargeLine[];
      public TaxViewLines: Entities.TaxViewLine[];
      public Comment: string;
      public InvoiceComment: string;
      public Coupons: Entities.Coupon[];
      public CustomerId: string;
      public CustomerOrderModeValue: number;
      public DeliveryMode: string;
      public DeliveryModeChargeAmount: number;
      public DiscountAmount: number;
      public DiscountAmountWithoutTax: number;
      public DiscountCodes: string[];
      public Id: string;
      public SuspendedCartId: string;
      public TransactionTypeValue: number;
      public CustomerAccountDepositLines: Entities.CustomerAccountDepositLine[];
      public IncomeExpenseLines: Entities.IncomeExpenseLine[];
      public IncomeExpenseTotalAmount: number;
      public IsReturnByReceipt: boolean;
      public ReturnTransactionHasLoyaltyPayment: boolean;
      public IsFavorite: boolean;
      public IsRecurring: boolean;
      public IsSuspended: boolean;
      public LoyaltyCardId: string;
      public ModifiedDateTime: Date;
      public Name: string;
      public OrderNumber: string;
      public AvailableDepositAmount: number;
      public OverriddenDepositAmount: number;
      public OverriddenDepositWithoutCarryoutAmount: number;
      public PrepaymentAmountPaid: number;
      public PrepaymentRoundingDifference: number;
      public PrepaymentAppliedOnPickup: number;
      public PrepaymentAmountInvoiced: number;
      public PromotionLines: string[];
      public QuotationExpiryDate: Date;
      public ReasonCodeLines: Entities.ReasonCodeLine[];
      public ReceiptEmail: string;
      public RequestedDeliveryDate: Date;
      public RequiredDepositAmount: number;
      public RequiredDepositWithoutCarryoutAmount: number;
      public SalesId: string;
      public ShippingAddress: Entities.Address;
      public StaffId: string;
      public SubtotalAmount: number;
      public SubtotalAmountWithoutTax: number;
      public NetPrice: number;
      public SubtotalSalesAmount: number;
      public TaxAmount: number;
      public TaxOnCancellationCharge: number;
      public TaxOnShippingCharge: number;
      public TaxOnNonShippingCharges: number;
      public TaxOverrideCode: string;
      public TenderLines: Entities.TenderLine[];
      public RefundableTenderLines: Entities.TenderLine[];
      public TerminalId: string;
      public TotalAmount: number;
      public TotalSalesAmount: number;
      public TotalReturnAmount: number;
      public TotalCarryoutSalesAmount: number;
      public TotalCustomerOrderSalesAmount: number;
      public TotalManualDiscountAmount: number;
      public TotalManualDiscountPercentage: number;
      public WarehouseId: string;
      public IsCreatedOffline: boolean;
      public CartStatusValue: number;
      public ReceiptTransactionTypeValue: number;
      public CommissionSalesGroup: string;
      public Version: number;
      public TotalItems: number;
      public HasTaxCalculationTriggered: boolean;
      public MerchantProperties: Entities.PaymentMerchantInformation[];
      public HasChargeCalculationTriggered: boolean;
      public FiscalTransactions: Entities.FiscalTransaction[];
      public ShippingChargeAmount: number;
      public OtherChargeAmount: number;
      public PeriodicDiscountsCalculateScopeValue: number;
      public TaxCalculationTypeValue: number;
      public Notes: Entities.Note[];
      public CustomerRequisition: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.AffiliationLines = undefined;
        if (odataObject.AffiliationLines) {
        this.AffiliationLines = [];
        for (var i = 0; i < odataObject.AffiliationLines.length; i++) {
        if (odataObject.AffiliationLines[i] != null) {
        if (odataObject.AffiliationLines[i]['@odata.type'] != null) {
          var className: string = odataObject.AffiliationLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.AffiliationLines[i] = new entityDictionary[className](odataObject.AffiliationLines[i])
          }
        } else {
            this.AffiliationLines[i] = new AffiliationLoyaltyTierClass(odataObject.AffiliationLines[i]);
        }
                    } else {
        this.AffiliationLines[i] = undefined;
        }
        }
        }
      
            this.IsRequiredAmountPaid = 
                odataObject.IsRequiredAmountPaid;
              
            this.IsDiscountFullyCalculated = 
                odataObject.IsDiscountFullyCalculated;
              
            this.IgnoreDiscountCalculation = 
                odataObject.IgnoreDiscountCalculation;
              
            this.AmountDue = 
                (odataObject.AmountDue != null) ? parseFloat(odataObject.AmountDue) : undefined;
              
            this.AmountPaid = 
                (odataObject.AmountPaid != null) ? parseFloat(odataObject.AmountPaid) : undefined;
              
        this.AttributeValues = undefined;
        if (odataObject.AttributeValues) {
        this.AttributeValues = [];
        for (var i = 0; i < odataObject.AttributeValues.length; i++) {
        if (odataObject.AttributeValues[i] != null) {
        if (odataObject.AttributeValues[i]['@odata.type'] != null) {
          var className: string = odataObject.AttributeValues[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.AttributeValues[i] = new entityDictionary[className](odataObject.AttributeValues[i])
          }
        } else {
            this.AttributeValues[i] = new AttributeValueBaseClass(odataObject.AttributeValues[i]);
        }
                    } else {
        this.AttributeValues[i] = undefined;
        }
        }
        }
      
            this.IsTaxIncludedInPrice = 
                odataObject.IsTaxIncludedInPrice;
              
            this.BeginDateTime = 
                (odataObject.BeginDateTime instanceof Date) ? odataObject.BeginDateTime
                : (odataObject.BeginDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.BeginDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.BusinessDate = 
                (odataObject.BusinessDate instanceof Date) ? odataObject.BusinessDate
                : (odataObject.BusinessDate != null) ? jsonLightReadStringPropertyValue(odataObject.BusinessDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.CancellationChargeAmount = 
                (odataObject.CancellationChargeAmount != null) ? parseFloat(odataObject.CancellationChargeAmount) : undefined;
              
            this.EstimatedShippingAmount = 
                (odataObject.EstimatedShippingAmount != null) ? parseFloat(odataObject.EstimatedShippingAmount) : undefined;
              
        this.CartLines = undefined;
        if (odataObject.CartLines) {
        this.CartLines = [];
        for (var i = 0; i < odataObject.CartLines.length; i++) {
        if (odataObject.CartLines[i] != null) {
        if (odataObject.CartLines[i]['@odata.type'] != null) {
          var className: string = odataObject.CartLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.CartLines[i] = new entityDictionary[className](odataObject.CartLines[i])
          }
        } else {
            this.CartLines[i] = new CartLineClass(odataObject.CartLines[i]);
        }
                    } else {
        this.CartLines[i] = undefined;
        }
        }
        }
      
            this.CartTypeValue = 
                odataObject.CartTypeValue;
              
            this.ChannelId = 
                (odataObject.ChannelId != null) ? parseInt(odataObject.ChannelId, 10) : undefined;
              
            this.ChargeAmount = 
                (odataObject.ChargeAmount != null) ? parseFloat(odataObject.ChargeAmount) : undefined;
              
            this.CustomerOrderRemainingBalance = 
                (odataObject.CustomerOrderRemainingBalance != null) ? parseFloat(odataObject.CustomerOrderRemainingBalance) : undefined;
              
        this.ChargeLines = undefined;
        if (odataObject.ChargeLines) {
        this.ChargeLines = [];
        for (var i = 0; i < odataObject.ChargeLines.length; i++) {
        if (odataObject.ChargeLines[i] != null) {
        if (odataObject.ChargeLines[i]['@odata.type'] != null) {
          var className: string = odataObject.ChargeLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ChargeLines[i] = new entityDictionary[className](odataObject.ChargeLines[i])
          }
        } else {
            this.ChargeLines[i] = new ChargeLineClass(odataObject.ChargeLines[i]);
        }
                    } else {
        this.ChargeLines[i] = undefined;
        }
        }
        }
      
        this.TaxViewLines = undefined;
        if (odataObject.TaxViewLines) {
        this.TaxViewLines = [];
        for (var i = 0; i < odataObject.TaxViewLines.length; i++) {
        if (odataObject.TaxViewLines[i] != null) {
        if (odataObject.TaxViewLines[i]['@odata.type'] != null) {
          var className: string = odataObject.TaxViewLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.TaxViewLines[i] = new entityDictionary[className](odataObject.TaxViewLines[i])
          }
        } else {
            this.TaxViewLines[i] = new TaxViewLineClass(odataObject.TaxViewLines[i]);
        }
                    } else {
        this.TaxViewLines[i] = undefined;
        }
        }
        }
      
            this.Comment = 
                odataObject.Comment;
              
            this.InvoiceComment = 
                odataObject.InvoiceComment;
              
        this.Coupons = undefined;
        if (odataObject.Coupons) {
        this.Coupons = [];
        for (var i = 0; i < odataObject.Coupons.length; i++) {
        if (odataObject.Coupons[i] != null) {
        if (odataObject.Coupons[i]['@odata.type'] != null) {
          var className: string = odataObject.Coupons[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Coupons[i] = new entityDictionary[className](odataObject.Coupons[i])
          }
        } else {
            this.Coupons[i] = new CouponClass(odataObject.Coupons[i]);
        }
                    } else {
        this.Coupons[i] = undefined;
        }
        }
        }
      
            this.CustomerId = 
                odataObject.CustomerId;
              
            this.CustomerOrderModeValue = 
                odataObject.CustomerOrderModeValue;
              
            this.DeliveryMode = 
                odataObject.DeliveryMode;
              
            this.DeliveryModeChargeAmount = 
                (odataObject.DeliveryModeChargeAmount != null) ? parseFloat(odataObject.DeliveryModeChargeAmount) : undefined;
              
            this.DiscountAmount = 
                (odataObject.DiscountAmount != null) ? parseFloat(odataObject.DiscountAmount) : undefined;
              
            this.DiscountAmountWithoutTax = 
                (odataObject.DiscountAmountWithoutTax != null) ? parseFloat(odataObject.DiscountAmountWithoutTax) : undefined;
              
        this.DiscountCodes = undefined;
        if (odataObject.DiscountCodes) {
        this.DiscountCodes = [];
        for (var i = 0; i < odataObject.DiscountCodes.length; i++) {
        if (odataObject.DiscountCodes[i] != null) {
            this.DiscountCodes[i] = 
                odataObject.DiscountCodes[i];
                            } else {
        this.DiscountCodes[i] = undefined;
        }
        }
        }
      
            this.Id = 
                odataObject.Id;
              
            this.SuspendedCartId = 
                odataObject.SuspendedCartId;
              
            this.TransactionTypeValue = 
                odataObject.TransactionTypeValue;
              
        this.CustomerAccountDepositLines = undefined;
        if (odataObject.CustomerAccountDepositLines) {
        this.CustomerAccountDepositLines = [];
        for (var i = 0; i < odataObject.CustomerAccountDepositLines.length; i++) {
        if (odataObject.CustomerAccountDepositLines[i] != null) {
        if (odataObject.CustomerAccountDepositLines[i]['@odata.type'] != null) {
          var className: string = odataObject.CustomerAccountDepositLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.CustomerAccountDepositLines[i] = new entityDictionary[className](odataObject.CustomerAccountDepositLines[i])
          }
        } else {
            this.CustomerAccountDepositLines[i] = new CustomerAccountDepositLineClass(odataObject.CustomerAccountDepositLines[i]);
        }
                    } else {
        this.CustomerAccountDepositLines[i] = undefined;
        }
        }
        }
      
        this.IncomeExpenseLines = undefined;
        if (odataObject.IncomeExpenseLines) {
        this.IncomeExpenseLines = [];
        for (var i = 0; i < odataObject.IncomeExpenseLines.length; i++) {
        if (odataObject.IncomeExpenseLines[i] != null) {
        if (odataObject.IncomeExpenseLines[i]['@odata.type'] != null) {
          var className: string = odataObject.IncomeExpenseLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.IncomeExpenseLines[i] = new entityDictionary[className](odataObject.IncomeExpenseLines[i])
          }
        } else {
            this.IncomeExpenseLines[i] = new IncomeExpenseLineClass(odataObject.IncomeExpenseLines[i]);
        }
                    } else {
        this.IncomeExpenseLines[i] = undefined;
        }
        }
        }
      
            this.IncomeExpenseTotalAmount = 
                (odataObject.IncomeExpenseTotalAmount != null) ? parseFloat(odataObject.IncomeExpenseTotalAmount) : undefined;
              
            this.IsReturnByReceipt = 
                odataObject.IsReturnByReceipt;
              
            this.ReturnTransactionHasLoyaltyPayment = 
                odataObject.ReturnTransactionHasLoyaltyPayment;
              
            this.IsFavorite = 
                odataObject.IsFavorite;
              
            this.IsRecurring = 
                odataObject.IsRecurring;
              
            this.IsSuspended = 
                odataObject.IsSuspended;
              
            this.LoyaltyCardId = 
                odataObject.LoyaltyCardId;
              
            this.ModifiedDateTime = 
                (odataObject.ModifiedDateTime instanceof Date) ? odataObject.ModifiedDateTime
                : (odataObject.ModifiedDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.ModifiedDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.Name = 
                odataObject.Name;
              
            this.OrderNumber = 
                odataObject.OrderNumber;
              
            this.AvailableDepositAmount = 
                (odataObject.AvailableDepositAmount != null) ? parseFloat(odataObject.AvailableDepositAmount) : undefined;
              
            this.OverriddenDepositAmount = 
                (odataObject.OverriddenDepositAmount != null) ? parseFloat(odataObject.OverriddenDepositAmount) : undefined;
              
            this.OverriddenDepositWithoutCarryoutAmount = 
                (odataObject.OverriddenDepositWithoutCarryoutAmount != null) ? parseFloat(odataObject.OverriddenDepositWithoutCarryoutAmount) : undefined;
              
            this.PrepaymentAmountPaid = 
                (odataObject.PrepaymentAmountPaid != null) ? parseFloat(odataObject.PrepaymentAmountPaid) : undefined;
              
            this.PrepaymentRoundingDifference = 
                (odataObject.PrepaymentRoundingDifference != null) ? parseFloat(odataObject.PrepaymentRoundingDifference) : undefined;
              
            this.PrepaymentAppliedOnPickup = 
                (odataObject.PrepaymentAppliedOnPickup != null) ? parseFloat(odataObject.PrepaymentAppliedOnPickup) : undefined;
              
            this.PrepaymentAmountInvoiced = 
                (odataObject.PrepaymentAmountInvoiced != null) ? parseFloat(odataObject.PrepaymentAmountInvoiced) : undefined;
              
        this.PromotionLines = undefined;
        if (odataObject.PromotionLines) {
        this.PromotionLines = [];
        for (var i = 0; i < odataObject.PromotionLines.length; i++) {
        if (odataObject.PromotionLines[i] != null) {
            this.PromotionLines[i] = 
                odataObject.PromotionLines[i];
                            } else {
        this.PromotionLines[i] = undefined;
        }
        }
        }
      
            this.QuotationExpiryDate = 
                (odataObject.QuotationExpiryDate instanceof Date) ? odataObject.QuotationExpiryDate
                : (odataObject.QuotationExpiryDate != null) ? jsonLightReadStringPropertyValue(odataObject.QuotationExpiryDate, "Edm.DateTimeOffset", false) : undefined;
              
        this.ReasonCodeLines = undefined;
        if (odataObject.ReasonCodeLines) {
        this.ReasonCodeLines = [];
        for (var i = 0; i < odataObject.ReasonCodeLines.length; i++) {
        if (odataObject.ReasonCodeLines[i] != null) {
        if (odataObject.ReasonCodeLines[i]['@odata.type'] != null) {
          var className: string = odataObject.ReasonCodeLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ReasonCodeLines[i] = new entityDictionary[className](odataObject.ReasonCodeLines[i])
          }
        } else {
            this.ReasonCodeLines[i] = new ReasonCodeLineClass(odataObject.ReasonCodeLines[i]);
        }
                    } else {
        this.ReasonCodeLines[i] = undefined;
        }
        }
        }
      
            this.ReceiptEmail = 
                odataObject.ReceiptEmail;
              
            this.RequestedDeliveryDate = 
                (odataObject.RequestedDeliveryDate instanceof Date) ? odataObject.RequestedDeliveryDate
                : (odataObject.RequestedDeliveryDate != null) ? jsonLightReadStringPropertyValue(odataObject.RequestedDeliveryDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.RequiredDepositAmount = 
                (odataObject.RequiredDepositAmount != null) ? parseFloat(odataObject.RequiredDepositAmount) : undefined;
              
            this.RequiredDepositWithoutCarryoutAmount = 
                (odataObject.RequiredDepositWithoutCarryoutAmount != null) ? parseFloat(odataObject.RequiredDepositWithoutCarryoutAmount) : undefined;
              
            this.SalesId = 
                odataObject.SalesId;
              
        if (odataObject.ShippingAddress == null) {
            this.ShippingAddress = undefined;
        } else if (odataObject.ShippingAddress['@odata.type'] == null) {
            this.ShippingAddress = new AddressClass(odataObject.ShippingAddress);
        } else {
            var className: string = odataObject.ShippingAddress['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.ShippingAddress = new entityDictionary[className](odataObject.ShippingAddress)
            }
        }

      
            this.StaffId = 
                odataObject.StaffId;
              
            this.SubtotalAmount = 
                (odataObject.SubtotalAmount != null) ? parseFloat(odataObject.SubtotalAmount) : undefined;
              
            this.SubtotalAmountWithoutTax = 
                (odataObject.SubtotalAmountWithoutTax != null) ? parseFloat(odataObject.SubtotalAmountWithoutTax) : undefined;
              
            this.NetPrice = 
                (odataObject.NetPrice != null) ? parseFloat(odataObject.NetPrice) : undefined;
              
            this.SubtotalSalesAmount = 
                (odataObject.SubtotalSalesAmount != null) ? parseFloat(odataObject.SubtotalSalesAmount) : undefined;
              
            this.TaxAmount = 
                (odataObject.TaxAmount != null) ? parseFloat(odataObject.TaxAmount) : undefined;
              
            this.TaxOnCancellationCharge = 
                (odataObject.TaxOnCancellationCharge != null) ? parseFloat(odataObject.TaxOnCancellationCharge) : undefined;
              
            this.TaxOnShippingCharge = 
                (odataObject.TaxOnShippingCharge != null) ? parseFloat(odataObject.TaxOnShippingCharge) : undefined;
              
            this.TaxOnNonShippingCharges = 
                (odataObject.TaxOnNonShippingCharges != null) ? parseFloat(odataObject.TaxOnNonShippingCharges) : undefined;
              
            this.TaxOverrideCode = 
                odataObject.TaxOverrideCode;
              
        this.TenderLines = undefined;
        if (odataObject.TenderLines) {
        this.TenderLines = [];
        for (var i = 0; i < odataObject.TenderLines.length; i++) {
        if (odataObject.TenderLines[i] != null) {
        if (odataObject.TenderLines[i]['@odata.type'] != null) {
          var className: string = odataObject.TenderLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.TenderLines[i] = new entityDictionary[className](odataObject.TenderLines[i])
          }
        } else {
            this.TenderLines[i] = new TenderLineClass(odataObject.TenderLines[i]);
        }
                    } else {
        this.TenderLines[i] = undefined;
        }
        }
        }
      
        this.RefundableTenderLines = undefined;
        if (odataObject.RefundableTenderLines) {
        this.RefundableTenderLines = [];
        for (var i = 0; i < odataObject.RefundableTenderLines.length; i++) {
        if (odataObject.RefundableTenderLines[i] != null) {
        if (odataObject.RefundableTenderLines[i]['@odata.type'] != null) {
          var className: string = odataObject.RefundableTenderLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.RefundableTenderLines[i] = new entityDictionary[className](odataObject.RefundableTenderLines[i])
          }
        } else {
            this.RefundableTenderLines[i] = new TenderLineClass(odataObject.RefundableTenderLines[i]);
        }
                    } else {
        this.RefundableTenderLines[i] = undefined;
        }
        }
        }
      
            this.TerminalId = 
                odataObject.TerminalId;
              
            this.TotalAmount = 
                (odataObject.TotalAmount != null) ? parseFloat(odataObject.TotalAmount) : undefined;
              
            this.TotalSalesAmount = 
                (odataObject.TotalSalesAmount != null) ? parseFloat(odataObject.TotalSalesAmount) : undefined;
              
            this.TotalReturnAmount = 
                (odataObject.TotalReturnAmount != null) ? parseFloat(odataObject.TotalReturnAmount) : undefined;
              
            this.TotalCarryoutSalesAmount = 
                (odataObject.TotalCarryoutSalesAmount != null) ? parseFloat(odataObject.TotalCarryoutSalesAmount) : undefined;
              
            this.TotalCustomerOrderSalesAmount = 
                (odataObject.TotalCustomerOrderSalesAmount != null) ? parseFloat(odataObject.TotalCustomerOrderSalesAmount) : undefined;
              
            this.TotalManualDiscountAmount = 
                (odataObject.TotalManualDiscountAmount != null) ? parseFloat(odataObject.TotalManualDiscountAmount) : undefined;
              
            this.TotalManualDiscountPercentage = 
                (odataObject.TotalManualDiscountPercentage != null) ? parseFloat(odataObject.TotalManualDiscountPercentage) : undefined;
              
            this.WarehouseId = 
                odataObject.WarehouseId;
              
            this.IsCreatedOffline = 
                odataObject.IsCreatedOffline;
              
            this.CartStatusValue = 
                odataObject.CartStatusValue;
              
            this.ReceiptTransactionTypeValue = 
                odataObject.ReceiptTransactionTypeValue;
              
            this.CommissionSalesGroup = 
                odataObject.CommissionSalesGroup;
              
            this.Version = 
                (odataObject.Version != null) ? parseInt(odataObject.Version, 10) : undefined;
              
            this.TotalItems = 
                (odataObject.TotalItems != null) ? parseFloat(odataObject.TotalItems) : undefined;
              
            this.HasTaxCalculationTriggered = 
                odataObject.HasTaxCalculationTriggered;
              
        this.MerchantProperties = undefined;
        if (odataObject.MerchantProperties) {
        this.MerchantProperties = [];
        for (var i = 0; i < odataObject.MerchantProperties.length; i++) {
        if (odataObject.MerchantProperties[i] != null) {
        if (odataObject.MerchantProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.MerchantProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.MerchantProperties[i] = new entityDictionary[className](odataObject.MerchantProperties[i])
          }
        } else {
            this.MerchantProperties[i] = new PaymentMerchantInformationClass(odataObject.MerchantProperties[i]);
        }
                    } else {
        this.MerchantProperties[i] = undefined;
        }
        }
        }
      
            this.HasChargeCalculationTriggered = 
                odataObject.HasChargeCalculationTriggered;
              
        this.FiscalTransactions = undefined;
        if (odataObject.FiscalTransactions) {
        this.FiscalTransactions = [];
        for (var i = 0; i < odataObject.FiscalTransactions.length; i++) {
        if (odataObject.FiscalTransactions[i] != null) {
        if (odataObject.FiscalTransactions[i]['@odata.type'] != null) {
          var className: string = odataObject.FiscalTransactions[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.FiscalTransactions[i] = new entityDictionary[className](odataObject.FiscalTransactions[i])
          }
        } else {
            this.FiscalTransactions[i] = new FiscalTransactionClass(odataObject.FiscalTransactions[i]);
        }
                    } else {
        this.FiscalTransactions[i] = undefined;
        }
        }
        }
      
            this.ShippingChargeAmount = 
                (odataObject.ShippingChargeAmount != null) ? parseFloat(odataObject.ShippingChargeAmount) : undefined;
              
            this.OtherChargeAmount = 
                (odataObject.OtherChargeAmount != null) ? parseFloat(odataObject.OtherChargeAmount) : undefined;
              
            this.PeriodicDiscountsCalculateScopeValue = 
                odataObject.PeriodicDiscountsCalculateScopeValue;
              
            this.TaxCalculationTypeValue = 
                odataObject.TaxCalculationTypeValue;
              
        this.Notes = undefined;
        if (odataObject.Notes) {
        this.Notes = [];
        for (var i = 0; i < odataObject.Notes.length; i++) {
        if (odataObject.Notes[i] != null) {
        if (odataObject.Notes[i]['@odata.type'] != null) {
          var className: string = odataObject.Notes[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Notes[i] = new entityDictionary[className](odataObject.Notes[i])
          }
        } else {
            this.Notes[i] = new NoteClass(odataObject.Notes[i]);
        }
                    } else {
        this.Notes[i] = undefined;
        }
        }
        }
      
            this.CustomerRequisition = 
                odataObject.CustomerRequisition;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CartClass'] = CartClass;
    
      /**
      * Customer entity class.
      */
      export class CustomerClass implements Entities.Customer {
      public AccountNumber: string;
      public RecordId: number;
      public CreatedDateTime: Date;
      public ChargeGroup: string;
      public PriceGroup: string;
      public IsCustomerTaxInclusive: boolean;
      public Phone: string;
      public PhoneRecordId: number;
      public PhoneExt: string;
      public Cellphone: string;
      public Email: string;
      public EmailRecordId: number;
      public Url: string;
      public UrlRecordId: number;
      public Name: string;
      public PersonNameId: number;
      public FirstName: string;
      public MiddleName: string;
      public LastName: string;
      public DirectoryPartyRecordId: number;
      public PartyNumber: string;
      public CustomerTypeValue: number;
      public Language: string;
      public CustomerGroup: string;
      public CurrencyCode: string;
      public CNPJCPFNumber: string;
      public IdentificationNumber: string;
      public InvoiceAccount: string;
      public MandatoryCreditLimit: boolean;
      public CreditRating: string;
      public CreditLimit: number;
      public Balance: number;
      public Blocked: boolean;
      public BlockedType: number;
      public UseOrderNumberReference: boolean;
      public OrganizationId: string;
      public UsePurchaseRequest: boolean;
      public MultilineDiscountGroup: string;
      public TotalDiscountGroup: string;
      public LineDiscountGroup: string;
      public TaxGroup: string;
      public SalesTaxGroup: string;
      public CommissionSalesGroupId: string;
      public TaxExemptNumber: string;
      public VatNumber: string;
      public TaxOffice: string;
      public NonChargeableAccount: boolean;
      public Tag: string;
      public ReceiptSettings: number;
      public ReceiptEmail: string;
      public OptOutPersonalization: boolean;
      public OptOutWebActivityTracking: boolean;
      public RetailCustomerTableRecordId: number;
      public OfflineImage: string;
      public IsAsyncCustomer: boolean;
      public NewCustomerPartyNumber: string;
      public CustomerAffiliations: Entities.CustomerAffiliation[];
      public Addresses: Entities.Address[];
      public Attributes: Entities.CustomerAttribute[];
      public Images: Entities.MediaLocation[];
      public TitleRecordId: number;
      public IsFloorLimitBlocked: boolean;
      public IsB2b: boolean;
      public IsB2bAdmin: boolean;
      public BusinessPartnerId: string;
      public Contacts: Entities.ContactInfo[];
      public AllowOnAccountPayment: boolean;
      public DefaultPaymentMethod: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.AccountNumber = 
                odataObject.AccountNumber;
              
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.CreatedDateTime = 
                (odataObject.CreatedDateTime instanceof Date) ? odataObject.CreatedDateTime
                : (odataObject.CreatedDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.CreatedDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.ChargeGroup = 
                odataObject.ChargeGroup;
              
            this.PriceGroup = 
                odataObject.PriceGroup;
              
            this.IsCustomerTaxInclusive = 
                odataObject.IsCustomerTaxInclusive;
              
            this.Phone = 
                odataObject.Phone;
              
            this.PhoneRecordId = 
                (odataObject.PhoneRecordId != null) ? parseInt(odataObject.PhoneRecordId, 10) : undefined;
              
            this.PhoneExt = 
                odataObject.PhoneExt;
              
            this.Cellphone = 
                odataObject.Cellphone;
              
            this.Email = 
                odataObject.Email;
              
            this.EmailRecordId = 
                (odataObject.EmailRecordId != null) ? parseInt(odataObject.EmailRecordId, 10) : undefined;
              
            this.Url = 
                odataObject.Url;
              
            this.UrlRecordId = 
                (odataObject.UrlRecordId != null) ? parseInt(odataObject.UrlRecordId, 10) : undefined;
              
            this.Name = 
                odataObject.Name;
              
            this.PersonNameId = 
                (odataObject.PersonNameId != null) ? parseInt(odataObject.PersonNameId, 10) : undefined;
              
            this.FirstName = 
                odataObject.FirstName;
              
            this.MiddleName = 
                odataObject.MiddleName;
              
            this.LastName = 
                odataObject.LastName;
              
            this.DirectoryPartyRecordId = 
                (odataObject.DirectoryPartyRecordId != null) ? parseInt(odataObject.DirectoryPartyRecordId, 10) : undefined;
              
            this.PartyNumber = 
                odataObject.PartyNumber;
              
            this.CustomerTypeValue = 
                odataObject.CustomerTypeValue;
              
            this.Language = 
                odataObject.Language;
              
            this.CustomerGroup = 
                odataObject.CustomerGroup;
              
            this.CurrencyCode = 
                odataObject.CurrencyCode;
              
            this.CNPJCPFNumber = 
                odataObject.CNPJCPFNumber;
              
            this.IdentificationNumber = 
                odataObject.IdentificationNumber;
              
            this.InvoiceAccount = 
                odataObject.InvoiceAccount;
              
            this.MandatoryCreditLimit = 
                odataObject.MandatoryCreditLimit;
              
            this.CreditRating = 
                odataObject.CreditRating;
              
            this.CreditLimit = 
                (odataObject.CreditLimit != null) ? parseFloat(odataObject.CreditLimit) : undefined;
              
            this.Balance = 
                (odataObject.Balance != null) ? parseFloat(odataObject.Balance) : undefined;
              
            this.Blocked = 
                odataObject.Blocked;
              
            this.BlockedType = 
                odataObject.BlockedType;
              
            this.UseOrderNumberReference = 
                odataObject.UseOrderNumberReference;
              
            this.OrganizationId = 
                odataObject.OrganizationId;
              
            this.UsePurchaseRequest = 
                odataObject.UsePurchaseRequest;
              
            this.MultilineDiscountGroup = 
                odataObject.MultilineDiscountGroup;
              
            this.TotalDiscountGroup = 
                odataObject.TotalDiscountGroup;
              
            this.LineDiscountGroup = 
                odataObject.LineDiscountGroup;
              
            this.TaxGroup = 
                odataObject.TaxGroup;
              
            this.SalesTaxGroup = 
                odataObject.SalesTaxGroup;
              
            this.CommissionSalesGroupId = 
                odataObject.CommissionSalesGroupId;
              
            this.TaxExemptNumber = 
                odataObject.TaxExemptNumber;
              
            this.VatNumber = 
                odataObject.VatNumber;
              
            this.TaxOffice = 
                odataObject.TaxOffice;
              
            this.NonChargeableAccount = 
                odataObject.NonChargeableAccount;
              
            this.Tag = 
                odataObject.Tag;
              
            this.ReceiptSettings = 
                odataObject.ReceiptSettings;
              
            this.ReceiptEmail = 
                odataObject.ReceiptEmail;
              
            this.OptOutPersonalization = 
                odataObject.OptOutPersonalization;
              
            this.OptOutWebActivityTracking = 
                odataObject.OptOutWebActivityTracking;
              
            this.RetailCustomerTableRecordId = 
                (odataObject.RetailCustomerTableRecordId != null) ? parseInt(odataObject.RetailCustomerTableRecordId, 10) : undefined;
              
            this.OfflineImage = 
                odataObject.OfflineImage;
              
            this.IsAsyncCustomer = 
                odataObject.IsAsyncCustomer;
              
            this.NewCustomerPartyNumber = 
                odataObject.NewCustomerPartyNumber;
              
        this.CustomerAffiliations = undefined;
        if (odataObject.CustomerAffiliations) {
        this.CustomerAffiliations = [];
        for (var i = 0; i < odataObject.CustomerAffiliations.length; i++) {
        if (odataObject.CustomerAffiliations[i] != null) {
        if (odataObject.CustomerAffiliations[i]['@odata.type'] != null) {
          var className: string = odataObject.CustomerAffiliations[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.CustomerAffiliations[i] = new entityDictionary[className](odataObject.CustomerAffiliations[i])
          }
        } else {
            this.CustomerAffiliations[i] = new CustomerAffiliationClass(odataObject.CustomerAffiliations[i]);
        }
                    } else {
        this.CustomerAffiliations[i] = undefined;
        }
        }
        }
      
        this.Addresses = undefined;
        if (odataObject.Addresses) {
        this.Addresses = [];
        for (var i = 0; i < odataObject.Addresses.length; i++) {
        if (odataObject.Addresses[i] != null) {
        if (odataObject.Addresses[i]['@odata.type'] != null) {
          var className: string = odataObject.Addresses[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Addresses[i] = new entityDictionary[className](odataObject.Addresses[i])
          }
        } else {
            this.Addresses[i] = new AddressClass(odataObject.Addresses[i]);
        }
                    } else {
        this.Addresses[i] = undefined;
        }
        }
        }
      
        this.Attributes = undefined;
        if (odataObject.Attributes) {
        this.Attributes = [];
        for (var i = 0; i < odataObject.Attributes.length; i++) {
        if (odataObject.Attributes[i] != null) {
        if (odataObject.Attributes[i]['@odata.type'] != null) {
          var className: string = odataObject.Attributes[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Attributes[i] = new entityDictionary[className](odataObject.Attributes[i])
          }
        } else {
            this.Attributes[i] = new CustomerAttributeClass(odataObject.Attributes[i]);
        }
                    } else {
        this.Attributes[i] = undefined;
        }
        }
        }
      
        this.Images = undefined;
        if (odataObject.Images) {
        this.Images = [];
        for (var i = 0; i < odataObject.Images.length; i++) {
        if (odataObject.Images[i] != null) {
        if (odataObject.Images[i]['@odata.type'] != null) {
          var className: string = odataObject.Images[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Images[i] = new entityDictionary[className](odataObject.Images[i])
          }
        } else {
            this.Images[i] = new MediaLocationClass(odataObject.Images[i]);
        }
                    } else {
        this.Images[i] = undefined;
        }
        }
        }
      
            this.TitleRecordId = 
                (odataObject.TitleRecordId != null) ? parseInt(odataObject.TitleRecordId, 10) : undefined;
              
            this.IsFloorLimitBlocked = 
                odataObject.IsFloorLimitBlocked;
              
            this.IsB2b = 
                odataObject.IsB2b;
              
            this.IsB2bAdmin = 
                odataObject.IsB2bAdmin;
              
            this.BusinessPartnerId = 
                odataObject.BusinessPartnerId;
              
        this.Contacts = undefined;
        if (odataObject.Contacts) {
        this.Contacts = [];
        for (var i = 0; i < odataObject.Contacts.length; i++) {
        if (odataObject.Contacts[i] != null) {
        if (odataObject.Contacts[i]['@odata.type'] != null) {
          var className: string = odataObject.Contacts[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Contacts[i] = new entityDictionary[className](odataObject.Contacts[i])
          }
        } else {
            this.Contacts[i] = new ContactInfoClass(odataObject.Contacts[i]);
        }
                    } else {
        this.Contacts[i] = undefined;
        }
        }
        }
      
            this.AllowOnAccountPayment = 
                odataObject.AllowOnAccountPayment;
              
            this.DefaultPaymentMethod = 
                odataObject.DefaultPaymentMethod;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CustomerClass'] = CustomerClass;
    
      /**
      * Employee entity class.
      */
      export class EmployeeClass implements Entities.Employee {
      public StaffId: string;
      public NameOnReceipt: string;
      public Name: string;
      public CultureName: string;
      public NumberOfDaysToPasswordExpiry: number;
      public Permissions: Entities.EmployeePermissions;
      public Images: Entities.MediaLocation[];
      public DefaultImage: string;
      public DefaultCommissionSalesGroup: string;
      public IdentifierTypeValue: number;
      public RecordId: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.StaffId = 
                odataObject.StaffId;
              
            this.NameOnReceipt = 
                odataObject.NameOnReceipt;
              
            this.Name = 
                odataObject.Name;
              
            this.CultureName = 
                odataObject.CultureName;
              
            this.NumberOfDaysToPasswordExpiry = 
                odataObject.NumberOfDaysToPasswordExpiry;
              
        if (odataObject.Permissions == null) {
            this.Permissions = undefined;
        } else if (odataObject.Permissions['@odata.type'] == null) {
            this.Permissions = new EmployeePermissionsClass(odataObject.Permissions);
        } else {
            var className: string = odataObject.Permissions['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.Permissions = new entityDictionary[className](odataObject.Permissions)
            }
        }

      
        this.Images = undefined;
        if (odataObject.Images) {
        this.Images = [];
        for (var i = 0; i < odataObject.Images.length; i++) {
        if (odataObject.Images[i] != null) {
        if (odataObject.Images[i]['@odata.type'] != null) {
          var className: string = odataObject.Images[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Images[i] = new entityDictionary[className](odataObject.Images[i])
          }
        } else {
            this.Images[i] = new MediaLocationClass(odataObject.Images[i]);
        }
                    } else {
        this.Images[i] = undefined;
        }
        }
        }
      
            this.DefaultImage = 
                odataObject.DefaultImage;
              
            this.DefaultCommissionSalesGroup = 
                odataObject.DefaultCommissionSalesGroup;
              
            this.IdentifierTypeValue = 
                odataObject.IdentifierTypeValue;
              
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['EmployeeClass'] = EmployeeClass;
    
      /**
      * Checklist entity class.
      */
      export class ChecklistClass implements Entities.Checklist {
      public RecordId: number;
      public Tasks: Entities.ChecklistTask[];
      public Version: string;
      public ProcessId: string;
      public Name: string;
      public Description: string;
      public AssignedToChannelId: number;
      public AssignedToWorkerPersonnelNumber: string;
      public StatusValue: number;
      public StartDateTime: Date;
      public EndDateTime: Date;
      public TargetDateTime: Date;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
        this.Tasks = undefined;
        if (odataObject.Tasks) {
        this.Tasks = [];
        for (var i = 0; i < odataObject.Tasks.length; i++) {
        if (odataObject.Tasks[i] != null) {
        if (odataObject.Tasks[i]['@odata.type'] != null) {
          var className: string = odataObject.Tasks[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Tasks[i] = new entityDictionary[className](odataObject.Tasks[i])
          }
        } else {
            this.Tasks[i] = new ChecklistTaskClass(odataObject.Tasks[i]);
        }
                    } else {
        this.Tasks[i] = undefined;
        }
        }
        }
      
            this.Version = 
                odataObject.Version;
              
            this.ProcessId = 
                odataObject.ProcessId;
              
            this.Name = 
                odataObject.Name;
              
            this.Description = 
                odataObject.Description;
              
            this.AssignedToChannelId = 
                (odataObject.AssignedToChannelId != null) ? parseInt(odataObject.AssignedToChannelId, 10) : undefined;
              
            this.AssignedToWorkerPersonnelNumber = 
                odataObject.AssignedToWorkerPersonnelNumber;
              
            this.StatusValue = 
                odataObject.StatusValue;
              
            this.StartDateTime = 
                (odataObject.StartDateTime instanceof Date) ? odataObject.StartDateTime
                : (odataObject.StartDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.StartDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.EndDateTime = 
                (odataObject.EndDateTime instanceof Date) ? odataObject.EndDateTime
                : (odataObject.EndDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.EndDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.TargetDateTime = 
                (odataObject.TargetDateTime instanceof Date) ? odataObject.TargetDateTime
                : (odataObject.TargetDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.TargetDateTime, "Edm.DateTimeOffset", false) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ChecklistClass'] = ChecklistClass;
    
      /**
      * SalesOrder entity class.
      */
      export class SalesOrderClass implements Entities.SalesOrder {
      public DocumentStatusValue: number;
      public RecordId: number;
      public StatusValue: number;
      public McrOrderStopped: boolean;
      public Shipments: Entities.Shipment[];
      public AggregateInventoryQuantities: Entities.InventoryQuantities;
      public UnifiedDeliveryInformation: Entities.DeliveryInformation;
      public PaymentStatusValue: number;
      public DetailedOrderStatusValue: number;
      public AffiliationLoyaltyTierLines: Entities.SalesAffiliationLoyaltyTier[];
      public IsRequiredAmountPaid: boolean;
      public IsDiscountFullyCalculated: boolean;
      public IgnoreDiscountCalculation: boolean;
      public AmountDue: number;
      public EstimatedShippingAmount: number;
      public AmountPaid: number;
      public CustomerOrderRemainingBalance: number;
      public AttributeValues: Entities.AttributeValueBase[];
      public AvailableDepositAmount: number;
      public BeginDateTime: Date;
      public CreatedDateTime: Date;
      public BusinessDate: Date;
      public CalculatedDepositAmount: number;
      public CancellationCharge: number;
      public ChannelId: number;
      public ChannelReferenceId: string;
      public ChargeAmount: number;
      public ChargeLines: Entities.ChargeLine[];
      public Comment: string;
      public Coupons: Entities.Coupon[];
      public InvoiceComment: string;
      public ContactInformationCollection: Entities.ContactInformation[];
      public CurrencyCode: string;
      public CustomerAccountDepositLines: Entities.CustomerAccountDepositLine[];
      public CustomerId: string;
      public CustomerOrderModeValue: number;
      public CustomerOrderTypeValue: number;
      public DeliveryMode: string;
      public DeliveryModeChargeAmount: number;
      public DiscountAmount: number;
      public DiscountAmountWithoutTax: number;
      public NetPrice: number;
      public DiscountCodes: string[];
      public EntryStatusValue: number;
      public GrossAmount: number;
      public HasLoyaltyPayment: boolean;
      public Id: string;
      public InternalTransactionId: string;
      public SuspendedTransactionId: string;
      public IncomeExpenseLines: Entities.IncomeExpenseLine[];
      public IncomeExpenseTotalAmount: number;
      public InventoryLocationId: string;
      public IsCreatedOffline: boolean;
      public IsReturnByReceipt: boolean;
      public IsSuspended: boolean;
      public IsTaxIncludedInPrice: boolean;
      public IsTaxExemptedForPriceInclusive: boolean;
      public LineDiscount: number;
      public LineDiscountCalculationTypeValue: number;
      public LoyaltyCardId: string;
      public LoyaltyDiscountAmount: number;
      public LoyaltyManualDiscountAmount: number;
      public LoyaltyRewardPointLines: Entities.LoyaltyRewardPointLine[];
      public ModifiedDateTime: Date;
      public Name: string;
      public NetAmount: number;
      public NetAmountWithoutTax: number;
      public NetAmountWithNoTax: number;
      public NetAmountWithTax: number;
      public NumberOfItems: number;
      public OverriddenDepositAmount: number;
      public OverriddenDepositWithoutCarryoutAmount: number;
      public PeriodicDiscountAmount: number;
      public TenderDiscountAmount: number;
      public PrepaymentAmountAppliedOnPickup: number;
      public PrepaymentAmountInvoiced: number;
      public PrepaymentAmountPaid: number;
      public PrepaymentRoundingDifference: number;
      public QuotationExpiryDate: Date;
      public ReasonCodeLines: Entities.ReasonCodeLine[];
      public ReceiptEmail: string;
      public ReceiptId: string;
      public RequestedDeliveryDate: Date;
      public ConfirmedShipDate: Date;
      public RequiredDepositAmount: number;
      public RequiredDepositWithoutCarryoutAmount: number;
      public ReturnTransactionHasLoyaltyPayment: boolean;
      public SalesId: string;
      public SalesPaymentDifference: number;
      public SalesLines: Entities.SalesLine[];
      public ShiftId: number;
      public ShiftTerminalId: string;
      public ShippingAddress: Entities.Address;
      public StaffId: string;
      public StatementCode: string;
      public StoreId: string;
      public SubtotalAmount: number;
      public SubtotalAmountWithoutTax: number;
      public SubtotalSalesAmount: number;
      public TaxAmount: number;
      public TaxAmountExclusive: number;
      public TaxAmountInclusive: number;
      public TaxLines: Entities.TaxLine[];
      public TaxOnCancellationCharge: number;
      public TaxOnShippingCharge: number;
      public TaxOnNonShippingCharges: number;
      public TaxOverrideCode: string;
      public TenderLines: Entities.TenderLine[];
      public RefundableTenderLines: Entities.TenderLine[];
      public TerminalId: string;
      public TotalAmount: number;
      public TotalSalesAmount: number;
      public TotalReturnAmount: number;
      public TotalCarryoutSalesAmount: number;
      public TotalCustomerOrderSalesAmount: number;
      public TotalDiscount: number;
      public TotalManualDiscountAmount: number;
      public TotalManualDiscountPercentage: number;
      public TransactionTypeValue: number;
      public TaxCalculationTypeValue: number;
      public SalesInvoiceAmount: number;
      public GiftCardActiveFrom: Date;
      public GiftCardBalance: number;
      public GiftCardExpireDate: Date;
      public GiftCardHistoryDetails: string;
      public GiftCardIssueAmount: number;
      public GiftCardIdMasked: string;
      public CommissionSalesGroup: string;
      public IsCommissionSalesGroupOverriddenByCustomer: boolean;
      public CommissionSalesGroupSetByCashier: string;
      public HasTaxCalculationTriggered: boolean;
      public HasChargeCalculationTriggered: boolean;
      public ShippingChargeAmount: number;
      public OtherChargeAmount: number;
      public PeriodicDiscountsCalculateScopeValue: number;
      public CustomerName: string;
      public OriginalOrderTransactionId: string;
      public Notes: Entities.Note[];
      public CustomerRequisition: string;
      public FiscalTransactions: Entities.FiscalTransaction[];
      public LanguageId: string;
      public SalesAgreementRecordId: number;
      public TransactionDate: Date;
      public TransactionTime: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.DocumentStatusValue = 
                odataObject.DocumentStatusValue;
              
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.StatusValue = 
                odataObject.StatusValue;
              
            this.McrOrderStopped = 
                odataObject.McrOrderStopped;
              
        this.Shipments = undefined;
        if (odataObject.Shipments) {
        this.Shipments = [];
        for (var i = 0; i < odataObject.Shipments.length; i++) {
        if (odataObject.Shipments[i] != null) {
        if (odataObject.Shipments[i]['@odata.type'] != null) {
          var className: string = odataObject.Shipments[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Shipments[i] = new entityDictionary[className](odataObject.Shipments[i])
          }
        } else {
            this.Shipments[i] = new ShipmentClass(odataObject.Shipments[i]);
        }
                    } else {
        this.Shipments[i] = undefined;
        }
        }
        }
      
        if (odataObject.AggregateInventoryQuantities == null) {
            this.AggregateInventoryQuantities = undefined;
        } else if (odataObject.AggregateInventoryQuantities['@odata.type'] == null) {
            this.AggregateInventoryQuantities = new InventoryQuantitiesClass(odataObject.AggregateInventoryQuantities);
        } else {
            var className: string = odataObject.AggregateInventoryQuantities['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.AggregateInventoryQuantities = new entityDictionary[className](odataObject.AggregateInventoryQuantities)
            }
        }

      
        if (odataObject.UnifiedDeliveryInformation == null) {
            this.UnifiedDeliveryInformation = undefined;
        } else if (odataObject.UnifiedDeliveryInformation['@odata.type'] == null) {
            this.UnifiedDeliveryInformation = new DeliveryInformationClass(odataObject.UnifiedDeliveryInformation);
        } else {
            var className: string = odataObject.UnifiedDeliveryInformation['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.UnifiedDeliveryInformation = new entityDictionary[className](odataObject.UnifiedDeliveryInformation)
            }
        }

      
            this.PaymentStatusValue = 
                odataObject.PaymentStatusValue;
              
            this.DetailedOrderStatusValue = 
                odataObject.DetailedOrderStatusValue;
              
        this.AffiliationLoyaltyTierLines = undefined;
        if (odataObject.AffiliationLoyaltyTierLines) {
        this.AffiliationLoyaltyTierLines = [];
        for (var i = 0; i < odataObject.AffiliationLoyaltyTierLines.length; i++) {
        if (odataObject.AffiliationLoyaltyTierLines[i] != null) {
        if (odataObject.AffiliationLoyaltyTierLines[i]['@odata.type'] != null) {
          var className: string = odataObject.AffiliationLoyaltyTierLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.AffiliationLoyaltyTierLines[i] = new entityDictionary[className](odataObject.AffiliationLoyaltyTierLines[i])
          }
        } else {
            this.AffiliationLoyaltyTierLines[i] = new SalesAffiliationLoyaltyTierClass(odataObject.AffiliationLoyaltyTierLines[i]);
        }
                    } else {
        this.AffiliationLoyaltyTierLines[i] = undefined;
        }
        }
        }
      
            this.IsRequiredAmountPaid = 
                odataObject.IsRequiredAmountPaid;
              
            this.IsDiscountFullyCalculated = 
                odataObject.IsDiscountFullyCalculated;
              
            this.IgnoreDiscountCalculation = 
                odataObject.IgnoreDiscountCalculation;
              
            this.AmountDue = 
                (odataObject.AmountDue != null) ? parseFloat(odataObject.AmountDue) : undefined;
              
            this.EstimatedShippingAmount = 
                (odataObject.EstimatedShippingAmount != null) ? parseFloat(odataObject.EstimatedShippingAmount) : undefined;
              
            this.AmountPaid = 
                (odataObject.AmountPaid != null) ? parseFloat(odataObject.AmountPaid) : undefined;
              
            this.CustomerOrderRemainingBalance = 
                (odataObject.CustomerOrderRemainingBalance != null) ? parseFloat(odataObject.CustomerOrderRemainingBalance) : undefined;
              
        this.AttributeValues = undefined;
        if (odataObject.AttributeValues) {
        this.AttributeValues = [];
        for (var i = 0; i < odataObject.AttributeValues.length; i++) {
        if (odataObject.AttributeValues[i] != null) {
        if (odataObject.AttributeValues[i]['@odata.type'] != null) {
          var className: string = odataObject.AttributeValues[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.AttributeValues[i] = new entityDictionary[className](odataObject.AttributeValues[i])
          }
        } else {
            this.AttributeValues[i] = new AttributeValueBaseClass(odataObject.AttributeValues[i]);
        }
                    } else {
        this.AttributeValues[i] = undefined;
        }
        }
        }
      
            this.AvailableDepositAmount = 
                (odataObject.AvailableDepositAmount != null) ? parseFloat(odataObject.AvailableDepositAmount) : undefined;
              
            this.BeginDateTime = 
                (odataObject.BeginDateTime instanceof Date) ? odataObject.BeginDateTime
                : (odataObject.BeginDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.BeginDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.CreatedDateTime = 
                (odataObject.CreatedDateTime instanceof Date) ? odataObject.CreatedDateTime
                : (odataObject.CreatedDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.CreatedDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.BusinessDate = 
                (odataObject.BusinessDate instanceof Date) ? odataObject.BusinessDate
                : (odataObject.BusinessDate != null) ? jsonLightReadStringPropertyValue(odataObject.BusinessDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.CalculatedDepositAmount = 
                (odataObject.CalculatedDepositAmount != null) ? parseFloat(odataObject.CalculatedDepositAmount) : undefined;
              
            this.CancellationCharge = 
                (odataObject.CancellationCharge != null) ? parseFloat(odataObject.CancellationCharge) : undefined;
              
            this.ChannelId = 
                (odataObject.ChannelId != null) ? parseInt(odataObject.ChannelId, 10) : undefined;
              
            this.ChannelReferenceId = 
                odataObject.ChannelReferenceId;
              
            this.ChargeAmount = 
                (odataObject.ChargeAmount != null) ? parseFloat(odataObject.ChargeAmount) : undefined;
              
        this.ChargeLines = undefined;
        if (odataObject.ChargeLines) {
        this.ChargeLines = [];
        for (var i = 0; i < odataObject.ChargeLines.length; i++) {
        if (odataObject.ChargeLines[i] != null) {
        if (odataObject.ChargeLines[i]['@odata.type'] != null) {
          var className: string = odataObject.ChargeLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ChargeLines[i] = new entityDictionary[className](odataObject.ChargeLines[i])
          }
        } else {
            this.ChargeLines[i] = new ChargeLineClass(odataObject.ChargeLines[i]);
        }
                    } else {
        this.ChargeLines[i] = undefined;
        }
        }
        }
      
            this.Comment = 
                odataObject.Comment;
              
        this.Coupons = undefined;
        if (odataObject.Coupons) {
        this.Coupons = [];
        for (var i = 0; i < odataObject.Coupons.length; i++) {
        if (odataObject.Coupons[i] != null) {
        if (odataObject.Coupons[i]['@odata.type'] != null) {
          var className: string = odataObject.Coupons[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Coupons[i] = new entityDictionary[className](odataObject.Coupons[i])
          }
        } else {
            this.Coupons[i] = new CouponClass(odataObject.Coupons[i]);
        }
                    } else {
        this.Coupons[i] = undefined;
        }
        }
        }
      
            this.InvoiceComment = 
                odataObject.InvoiceComment;
              
        this.ContactInformationCollection = undefined;
        if (odataObject.ContactInformationCollection) {
        this.ContactInformationCollection = [];
        for (var i = 0; i < odataObject.ContactInformationCollection.length; i++) {
        if (odataObject.ContactInformationCollection[i] != null) {
        if (odataObject.ContactInformationCollection[i]['@odata.type'] != null) {
          var className: string = odataObject.ContactInformationCollection[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ContactInformationCollection[i] = new entityDictionary[className](odataObject.ContactInformationCollection[i])
          }
        } else {
            this.ContactInformationCollection[i] = new ContactInformationClass(odataObject.ContactInformationCollection[i]);
        }
                    } else {
        this.ContactInformationCollection[i] = undefined;
        }
        }
        }
      
            this.CurrencyCode = 
                odataObject.CurrencyCode;
              
        this.CustomerAccountDepositLines = undefined;
        if (odataObject.CustomerAccountDepositLines) {
        this.CustomerAccountDepositLines = [];
        for (var i = 0; i < odataObject.CustomerAccountDepositLines.length; i++) {
        if (odataObject.CustomerAccountDepositLines[i] != null) {
        if (odataObject.CustomerAccountDepositLines[i]['@odata.type'] != null) {
          var className: string = odataObject.CustomerAccountDepositLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.CustomerAccountDepositLines[i] = new entityDictionary[className](odataObject.CustomerAccountDepositLines[i])
          }
        } else {
            this.CustomerAccountDepositLines[i] = new CustomerAccountDepositLineClass(odataObject.CustomerAccountDepositLines[i]);
        }
                    } else {
        this.CustomerAccountDepositLines[i] = undefined;
        }
        }
        }
      
            this.CustomerId = 
                odataObject.CustomerId;
              
            this.CustomerOrderModeValue = 
                odataObject.CustomerOrderModeValue;
              
            this.CustomerOrderTypeValue = 
                odataObject.CustomerOrderTypeValue;
              
            this.DeliveryMode = 
                odataObject.DeliveryMode;
              
            this.DeliveryModeChargeAmount = 
                (odataObject.DeliveryModeChargeAmount != null) ? parseFloat(odataObject.DeliveryModeChargeAmount) : undefined;
              
            this.DiscountAmount = 
                (odataObject.DiscountAmount != null) ? parseFloat(odataObject.DiscountAmount) : undefined;
              
            this.DiscountAmountWithoutTax = 
                (odataObject.DiscountAmountWithoutTax != null) ? parseFloat(odataObject.DiscountAmountWithoutTax) : undefined;
              
            this.NetPrice = 
                (odataObject.NetPrice != null) ? parseFloat(odataObject.NetPrice) : undefined;
              
        this.DiscountCodes = undefined;
        if (odataObject.DiscountCodes) {
        this.DiscountCodes = [];
        for (var i = 0; i < odataObject.DiscountCodes.length; i++) {
        if (odataObject.DiscountCodes[i] != null) {
            this.DiscountCodes[i] = 
                odataObject.DiscountCodes[i];
                            } else {
        this.DiscountCodes[i] = undefined;
        }
        }
        }
      
            this.EntryStatusValue = 
                odataObject.EntryStatusValue;
              
            this.GrossAmount = 
                (odataObject.GrossAmount != null) ? parseFloat(odataObject.GrossAmount) : undefined;
              
            this.HasLoyaltyPayment = 
                odataObject.HasLoyaltyPayment;
              
            this.Id = 
                odataObject.Id;
              
            this.InternalTransactionId = 
                odataObject.InternalTransactionId;
              
            this.SuspendedTransactionId = 
                odataObject.SuspendedTransactionId;
              
        this.IncomeExpenseLines = undefined;
        if (odataObject.IncomeExpenseLines) {
        this.IncomeExpenseLines = [];
        for (var i = 0; i < odataObject.IncomeExpenseLines.length; i++) {
        if (odataObject.IncomeExpenseLines[i] != null) {
        if (odataObject.IncomeExpenseLines[i]['@odata.type'] != null) {
          var className: string = odataObject.IncomeExpenseLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.IncomeExpenseLines[i] = new entityDictionary[className](odataObject.IncomeExpenseLines[i])
          }
        } else {
            this.IncomeExpenseLines[i] = new IncomeExpenseLineClass(odataObject.IncomeExpenseLines[i]);
        }
                    } else {
        this.IncomeExpenseLines[i] = undefined;
        }
        }
        }
      
            this.IncomeExpenseTotalAmount = 
                (odataObject.IncomeExpenseTotalAmount != null) ? parseFloat(odataObject.IncomeExpenseTotalAmount) : undefined;
              
            this.InventoryLocationId = 
                odataObject.InventoryLocationId;
              
            this.IsCreatedOffline = 
                odataObject.IsCreatedOffline;
              
            this.IsReturnByReceipt = 
                odataObject.IsReturnByReceipt;
              
            this.IsSuspended = 
                odataObject.IsSuspended;
              
            this.IsTaxIncludedInPrice = 
                odataObject.IsTaxIncludedInPrice;
              
            this.IsTaxExemptedForPriceInclusive = 
                odataObject.IsTaxExemptedForPriceInclusive;
              
            this.LineDiscount = 
                (odataObject.LineDiscount != null) ? parseFloat(odataObject.LineDiscount) : undefined;
              
            this.LineDiscountCalculationTypeValue = 
                odataObject.LineDiscountCalculationTypeValue;
              
            this.LoyaltyCardId = 
                odataObject.LoyaltyCardId;
              
            this.LoyaltyDiscountAmount = 
                (odataObject.LoyaltyDiscountAmount != null) ? parseFloat(odataObject.LoyaltyDiscountAmount) : undefined;
              
            this.LoyaltyManualDiscountAmount = 
                (odataObject.LoyaltyManualDiscountAmount != null) ? parseFloat(odataObject.LoyaltyManualDiscountAmount) : undefined;
              
        this.LoyaltyRewardPointLines = undefined;
        if (odataObject.LoyaltyRewardPointLines) {
        this.LoyaltyRewardPointLines = [];
        for (var i = 0; i < odataObject.LoyaltyRewardPointLines.length; i++) {
        if (odataObject.LoyaltyRewardPointLines[i] != null) {
        if (odataObject.LoyaltyRewardPointLines[i]['@odata.type'] != null) {
          var className: string = odataObject.LoyaltyRewardPointLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.LoyaltyRewardPointLines[i] = new entityDictionary[className](odataObject.LoyaltyRewardPointLines[i])
          }
        } else {
            this.LoyaltyRewardPointLines[i] = new LoyaltyRewardPointLineClass(odataObject.LoyaltyRewardPointLines[i]);
        }
                    } else {
        this.LoyaltyRewardPointLines[i] = undefined;
        }
        }
        }
      
            this.ModifiedDateTime = 
                (odataObject.ModifiedDateTime instanceof Date) ? odataObject.ModifiedDateTime
                : (odataObject.ModifiedDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.ModifiedDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.Name = 
                odataObject.Name;
              
            this.NetAmount = 
                (odataObject.NetAmount != null) ? parseFloat(odataObject.NetAmount) : undefined;
              
            this.NetAmountWithoutTax = 
                (odataObject.NetAmountWithoutTax != null) ? parseFloat(odataObject.NetAmountWithoutTax) : undefined;
              
            this.NetAmountWithNoTax = 
                (odataObject.NetAmountWithNoTax != null) ? parseFloat(odataObject.NetAmountWithNoTax) : undefined;
              
            this.NetAmountWithTax = 
                (odataObject.NetAmountWithTax != null) ? parseFloat(odataObject.NetAmountWithTax) : undefined;
              
            this.NumberOfItems = 
                (odataObject.NumberOfItems != null) ? parseFloat(odataObject.NumberOfItems) : undefined;
              
            this.OverriddenDepositAmount = 
                (odataObject.OverriddenDepositAmount != null) ? parseFloat(odataObject.OverriddenDepositAmount) : undefined;
              
            this.OverriddenDepositWithoutCarryoutAmount = 
                (odataObject.OverriddenDepositWithoutCarryoutAmount != null) ? parseFloat(odataObject.OverriddenDepositWithoutCarryoutAmount) : undefined;
              
            this.PeriodicDiscountAmount = 
                (odataObject.PeriodicDiscountAmount != null) ? parseFloat(odataObject.PeriodicDiscountAmount) : undefined;
              
            this.TenderDiscountAmount = 
                (odataObject.TenderDiscountAmount != null) ? parseFloat(odataObject.TenderDiscountAmount) : undefined;
              
            this.PrepaymentAmountAppliedOnPickup = 
                (odataObject.PrepaymentAmountAppliedOnPickup != null) ? parseFloat(odataObject.PrepaymentAmountAppliedOnPickup) : undefined;
              
            this.PrepaymentAmountInvoiced = 
                (odataObject.PrepaymentAmountInvoiced != null) ? parseFloat(odataObject.PrepaymentAmountInvoiced) : undefined;
              
            this.PrepaymentAmountPaid = 
                (odataObject.PrepaymentAmountPaid != null) ? parseFloat(odataObject.PrepaymentAmountPaid) : undefined;
              
            this.PrepaymentRoundingDifference = 
                (odataObject.PrepaymentRoundingDifference != null) ? parseFloat(odataObject.PrepaymentRoundingDifference) : undefined;
              
            this.QuotationExpiryDate = 
                (odataObject.QuotationExpiryDate instanceof Date) ? odataObject.QuotationExpiryDate
                : (odataObject.QuotationExpiryDate != null) ? jsonLightReadStringPropertyValue(odataObject.QuotationExpiryDate, "Edm.DateTimeOffset", false) : undefined;
              
        this.ReasonCodeLines = undefined;
        if (odataObject.ReasonCodeLines) {
        this.ReasonCodeLines = [];
        for (var i = 0; i < odataObject.ReasonCodeLines.length; i++) {
        if (odataObject.ReasonCodeLines[i] != null) {
        if (odataObject.ReasonCodeLines[i]['@odata.type'] != null) {
          var className: string = odataObject.ReasonCodeLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ReasonCodeLines[i] = new entityDictionary[className](odataObject.ReasonCodeLines[i])
          }
        } else {
            this.ReasonCodeLines[i] = new ReasonCodeLineClass(odataObject.ReasonCodeLines[i]);
        }
                    } else {
        this.ReasonCodeLines[i] = undefined;
        }
        }
        }
      
            this.ReceiptEmail = 
                odataObject.ReceiptEmail;
              
            this.ReceiptId = 
                odataObject.ReceiptId;
              
            this.RequestedDeliveryDate = 
                (odataObject.RequestedDeliveryDate instanceof Date) ? odataObject.RequestedDeliveryDate
                : (odataObject.RequestedDeliveryDate != null) ? jsonLightReadStringPropertyValue(odataObject.RequestedDeliveryDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.ConfirmedShipDate = 
                (odataObject.ConfirmedShipDate instanceof Date) ? odataObject.ConfirmedShipDate
                : (odataObject.ConfirmedShipDate != null) ? jsonLightReadStringPropertyValue(odataObject.ConfirmedShipDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.RequiredDepositAmount = 
                (odataObject.RequiredDepositAmount != null) ? parseFloat(odataObject.RequiredDepositAmount) : undefined;
              
            this.RequiredDepositWithoutCarryoutAmount = 
                (odataObject.RequiredDepositWithoutCarryoutAmount != null) ? parseFloat(odataObject.RequiredDepositWithoutCarryoutAmount) : undefined;
              
            this.ReturnTransactionHasLoyaltyPayment = 
                odataObject.ReturnTransactionHasLoyaltyPayment;
              
            this.SalesId = 
                odataObject.SalesId;
              
            this.SalesPaymentDifference = 
                (odataObject.SalesPaymentDifference != null) ? parseFloat(odataObject.SalesPaymentDifference) : undefined;
              
        this.SalesLines = undefined;
        if (odataObject.SalesLines) {
        this.SalesLines = [];
        for (var i = 0; i < odataObject.SalesLines.length; i++) {
        if (odataObject.SalesLines[i] != null) {
        if (odataObject.SalesLines[i]['@odata.type'] != null) {
          var className: string = odataObject.SalesLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.SalesLines[i] = new entityDictionary[className](odataObject.SalesLines[i])
          }
        } else {
            this.SalesLines[i] = new SalesLineClass(odataObject.SalesLines[i]);
        }
                    } else {
        this.SalesLines[i] = undefined;
        }
        }
        }
      
            this.ShiftId = 
                (odataObject.ShiftId != null) ? parseInt(odataObject.ShiftId, 10) : undefined;
              
            this.ShiftTerminalId = 
                odataObject.ShiftTerminalId;
              
        if (odataObject.ShippingAddress == null) {
            this.ShippingAddress = undefined;
        } else if (odataObject.ShippingAddress['@odata.type'] == null) {
            this.ShippingAddress = new AddressClass(odataObject.ShippingAddress);
        } else {
            var className: string = odataObject.ShippingAddress['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.ShippingAddress = new entityDictionary[className](odataObject.ShippingAddress)
            }
        }

      
            this.StaffId = 
                odataObject.StaffId;
              
            this.StatementCode = 
                odataObject.StatementCode;
              
            this.StoreId = 
                odataObject.StoreId;
              
            this.SubtotalAmount = 
                (odataObject.SubtotalAmount != null) ? parseFloat(odataObject.SubtotalAmount) : undefined;
              
            this.SubtotalAmountWithoutTax = 
                (odataObject.SubtotalAmountWithoutTax != null) ? parseFloat(odataObject.SubtotalAmountWithoutTax) : undefined;
              
            this.SubtotalSalesAmount = 
                (odataObject.SubtotalSalesAmount != null) ? parseFloat(odataObject.SubtotalSalesAmount) : undefined;
              
            this.TaxAmount = 
                (odataObject.TaxAmount != null) ? parseFloat(odataObject.TaxAmount) : undefined;
              
            this.TaxAmountExclusive = 
                (odataObject.TaxAmountExclusive != null) ? parseFloat(odataObject.TaxAmountExclusive) : undefined;
              
            this.TaxAmountInclusive = 
                (odataObject.TaxAmountInclusive != null) ? parseFloat(odataObject.TaxAmountInclusive) : undefined;
              
        this.TaxLines = undefined;
        if (odataObject.TaxLines) {
        this.TaxLines = [];
        for (var i = 0; i < odataObject.TaxLines.length; i++) {
        if (odataObject.TaxLines[i] != null) {
        if (odataObject.TaxLines[i]['@odata.type'] != null) {
          var className: string = odataObject.TaxLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.TaxLines[i] = new entityDictionary[className](odataObject.TaxLines[i])
          }
        } else {
            this.TaxLines[i] = new TaxLineClass(odataObject.TaxLines[i]);
        }
                    } else {
        this.TaxLines[i] = undefined;
        }
        }
        }
      
            this.TaxOnCancellationCharge = 
                (odataObject.TaxOnCancellationCharge != null) ? parseFloat(odataObject.TaxOnCancellationCharge) : undefined;
              
            this.TaxOnShippingCharge = 
                (odataObject.TaxOnShippingCharge != null) ? parseFloat(odataObject.TaxOnShippingCharge) : undefined;
              
            this.TaxOnNonShippingCharges = 
                (odataObject.TaxOnNonShippingCharges != null) ? parseFloat(odataObject.TaxOnNonShippingCharges) : undefined;
              
            this.TaxOverrideCode = 
                odataObject.TaxOverrideCode;
              
        this.TenderLines = undefined;
        if (odataObject.TenderLines) {
        this.TenderLines = [];
        for (var i = 0; i < odataObject.TenderLines.length; i++) {
        if (odataObject.TenderLines[i] != null) {
        if (odataObject.TenderLines[i]['@odata.type'] != null) {
          var className: string = odataObject.TenderLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.TenderLines[i] = new entityDictionary[className](odataObject.TenderLines[i])
          }
        } else {
            this.TenderLines[i] = new TenderLineClass(odataObject.TenderLines[i]);
        }
                    } else {
        this.TenderLines[i] = undefined;
        }
        }
        }
      
        this.RefundableTenderLines = undefined;
        if (odataObject.RefundableTenderLines) {
        this.RefundableTenderLines = [];
        for (var i = 0; i < odataObject.RefundableTenderLines.length; i++) {
        if (odataObject.RefundableTenderLines[i] != null) {
        if (odataObject.RefundableTenderLines[i]['@odata.type'] != null) {
          var className: string = odataObject.RefundableTenderLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.RefundableTenderLines[i] = new entityDictionary[className](odataObject.RefundableTenderLines[i])
          }
        } else {
            this.RefundableTenderLines[i] = new TenderLineClass(odataObject.RefundableTenderLines[i]);
        }
                    } else {
        this.RefundableTenderLines[i] = undefined;
        }
        }
        }
      
            this.TerminalId = 
                odataObject.TerminalId;
              
            this.TotalAmount = 
                (odataObject.TotalAmount != null) ? parseFloat(odataObject.TotalAmount) : undefined;
              
            this.TotalSalesAmount = 
                (odataObject.TotalSalesAmount != null) ? parseFloat(odataObject.TotalSalesAmount) : undefined;
              
            this.TotalReturnAmount = 
                (odataObject.TotalReturnAmount != null) ? parseFloat(odataObject.TotalReturnAmount) : undefined;
              
            this.TotalCarryoutSalesAmount = 
                (odataObject.TotalCarryoutSalesAmount != null) ? parseFloat(odataObject.TotalCarryoutSalesAmount) : undefined;
              
            this.TotalCustomerOrderSalesAmount = 
                (odataObject.TotalCustomerOrderSalesAmount != null) ? parseFloat(odataObject.TotalCustomerOrderSalesAmount) : undefined;
              
            this.TotalDiscount = 
                (odataObject.TotalDiscount != null) ? parseFloat(odataObject.TotalDiscount) : undefined;
              
            this.TotalManualDiscountAmount = 
                (odataObject.TotalManualDiscountAmount != null) ? parseFloat(odataObject.TotalManualDiscountAmount) : undefined;
              
            this.TotalManualDiscountPercentage = 
                (odataObject.TotalManualDiscountPercentage != null) ? parseFloat(odataObject.TotalManualDiscountPercentage) : undefined;
              
            this.TransactionTypeValue = 
                odataObject.TransactionTypeValue;
              
            this.TaxCalculationTypeValue = 
                odataObject.TaxCalculationTypeValue;
              
            this.SalesInvoiceAmount = 
                (odataObject.SalesInvoiceAmount != null) ? parseFloat(odataObject.SalesInvoiceAmount) : undefined;
              
            this.GiftCardActiveFrom = 
                (odataObject.GiftCardActiveFrom instanceof Date) ? odataObject.GiftCardActiveFrom
                : (odataObject.GiftCardActiveFrom != null) ? jsonLightReadStringPropertyValue(odataObject.GiftCardActiveFrom, "Edm.DateTimeOffset", false) : undefined;
              
            this.GiftCardBalance = 
                (odataObject.GiftCardBalance != null) ? parseFloat(odataObject.GiftCardBalance) : undefined;
              
            this.GiftCardExpireDate = 
                (odataObject.GiftCardExpireDate instanceof Date) ? odataObject.GiftCardExpireDate
                : (odataObject.GiftCardExpireDate != null) ? jsonLightReadStringPropertyValue(odataObject.GiftCardExpireDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.GiftCardHistoryDetails = 
                odataObject.GiftCardHistoryDetails;
              
            this.GiftCardIssueAmount = 
                (odataObject.GiftCardIssueAmount != null) ? parseFloat(odataObject.GiftCardIssueAmount) : undefined;
              
            this.GiftCardIdMasked = 
                odataObject.GiftCardIdMasked;
              
            this.CommissionSalesGroup = 
                odataObject.CommissionSalesGroup;
              
            this.IsCommissionSalesGroupOverriddenByCustomer = 
                odataObject.IsCommissionSalesGroupOverriddenByCustomer;
              
            this.CommissionSalesGroupSetByCashier = 
                odataObject.CommissionSalesGroupSetByCashier;
              
            this.HasTaxCalculationTriggered = 
                odataObject.HasTaxCalculationTriggered;
              
            this.HasChargeCalculationTriggered = 
                odataObject.HasChargeCalculationTriggered;
              
            this.ShippingChargeAmount = 
                (odataObject.ShippingChargeAmount != null) ? parseFloat(odataObject.ShippingChargeAmount) : undefined;
              
            this.OtherChargeAmount = 
                (odataObject.OtherChargeAmount != null) ? parseFloat(odataObject.OtherChargeAmount) : undefined;
              
            this.PeriodicDiscountsCalculateScopeValue = 
                odataObject.PeriodicDiscountsCalculateScopeValue;
              
            this.CustomerName = 
                odataObject.CustomerName;
              
            this.OriginalOrderTransactionId = 
                odataObject.OriginalOrderTransactionId;
              
        this.Notes = undefined;
        if (odataObject.Notes) {
        this.Notes = [];
        for (var i = 0; i < odataObject.Notes.length; i++) {
        if (odataObject.Notes[i] != null) {
        if (odataObject.Notes[i]['@odata.type'] != null) {
          var className: string = odataObject.Notes[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Notes[i] = new entityDictionary[className](odataObject.Notes[i])
          }
        } else {
            this.Notes[i] = new NoteClass(odataObject.Notes[i]);
        }
                    } else {
        this.Notes[i] = undefined;
        }
        }
        }
      
            this.CustomerRequisition = 
                odataObject.CustomerRequisition;
              
        this.FiscalTransactions = undefined;
        if (odataObject.FiscalTransactions) {
        this.FiscalTransactions = [];
        for (var i = 0; i < odataObject.FiscalTransactions.length; i++) {
        if (odataObject.FiscalTransactions[i] != null) {
        if (odataObject.FiscalTransactions[i]['@odata.type'] != null) {
          var className: string = odataObject.FiscalTransactions[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.FiscalTransactions[i] = new entityDictionary[className](odataObject.FiscalTransactions[i])
          }
        } else {
            this.FiscalTransactions[i] = new FiscalTransactionClass(odataObject.FiscalTransactions[i]);
        }
                    } else {
        this.FiscalTransactions[i] = undefined;
        }
        }
        }
      
            this.LanguageId = 
                odataObject.LanguageId;
              
            this.SalesAgreementRecordId = 
                (odataObject.SalesAgreementRecordId != null) ? parseInt(odataObject.SalesAgreementRecordId, 10) : undefined;
              
            this.TransactionDate = 
                (odataObject.TransactionDate instanceof Date) ? odataObject.TransactionDate
                : (odataObject.TransactionDate != null) ? jsonLightReadStringPropertyValue(odataObject.TransactionDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.TransactionTime = 
                odataObject.TransactionTime;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['SalesOrderClass'] = SalesOrderClass;
    
      /**
      * Shift entity class.
      */
      export class ShiftClass implements Entities.Shift {
      public ShiftId: number;
      public TerminalId: string;
      public StartingAmountTotal: number;
      public FloatingEntryAmountTotal: number;
      public AddToTenderAmountTotal: number;
      public SafeDropTotal: number;
      public BankDropTotal: number;
      public RemoveTenderAmountTotal: number;
      public DeclareTenderAmountTotal: number;
      public OverShortTotal: number;
      public TenderedTotal: number;
      public ChangeTotal: number;
      public IncomeAccountTotal: number;
      public ExpenseAccountTotal: number;
      public CashDrawer: string;
      public StoreRecordId: number;
      public StoreId: string;
      public StaffId: string;
      public CurrentStaffId: string;
      public StatusValue: number;
      public StartDateTime: Date;
      public CloseDateTime: Date;
      public ClosedAtTerminalId: string;
      public ClosedAtShiftId: number;
      public CurrentTerminalId: string;
      public SalesTotal: number;
      public StatusDateTime: Date;
      public ReturnsTotal: number;
      public PaidToAccountTotal: number;
      public TaxTotal: number;
      public ChargeTotal: number;
      public DiscountTotal: number;
      public RoundedAmountTotal: number;
      public CustomerCount: number;
      public SaleTransactionCount: number;
      public NoSaleTransactionCount: number;
      public VoidTransactionCount: number;
      public LogOnTransactionCount: number;
      public SuspendedTransactionCount: number;
      public TransactionCount: number;
      public ReceiptsCount: number;
      public ReturnsCount: number;
      public GoodsSoldQty: number;
      public ServicesSoldQty: number;
      public ReceiptCopiesCount: number;
      public ReceiptCopiesTotal: number;
      public PriceOverrideTotal: number;
      public SuspendedTotal: number;
      public SalesTotalIncludingTax: number;
      public SalesTotalExcludingTax: number;
      public SalesGrandTotal: number;
      public ReturnsGrandTotal: number;
      public TaxLines: Entities.ShiftTaxLine[];
      public TenderLines: Entities.ShiftTenderLine[];
      public AccountLines: Entities.ShiftAccountLine[];
      public IsShared: boolean;
      public StartingAmountRunningTotal: number;
      public GiftCardsTotal: number;
      public GiftCardCashOutTotal: number;
      public VoidedSalesTotal: number;
      public ShiftSalesTotal: number;
      public ShiftReturnsTotal: number;
      public HasPendingOfflineTransactions: number;
      public NumberOfUnreconciledLines: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ShiftId = 
                (odataObject.ShiftId != null) ? parseInt(odataObject.ShiftId, 10) : undefined;
              
            this.TerminalId = 
                odataObject.TerminalId;
              
            this.StartingAmountTotal = 
                (odataObject.StartingAmountTotal != null) ? parseFloat(odataObject.StartingAmountTotal) : undefined;
              
            this.FloatingEntryAmountTotal = 
                (odataObject.FloatingEntryAmountTotal != null) ? parseFloat(odataObject.FloatingEntryAmountTotal) : undefined;
              
            this.AddToTenderAmountTotal = 
                (odataObject.AddToTenderAmountTotal != null) ? parseFloat(odataObject.AddToTenderAmountTotal) : undefined;
              
            this.SafeDropTotal = 
                (odataObject.SafeDropTotal != null) ? parseFloat(odataObject.SafeDropTotal) : undefined;
              
            this.BankDropTotal = 
                (odataObject.BankDropTotal != null) ? parseFloat(odataObject.BankDropTotal) : undefined;
              
            this.RemoveTenderAmountTotal = 
                (odataObject.RemoveTenderAmountTotal != null) ? parseFloat(odataObject.RemoveTenderAmountTotal) : undefined;
              
            this.DeclareTenderAmountTotal = 
                (odataObject.DeclareTenderAmountTotal != null) ? parseFloat(odataObject.DeclareTenderAmountTotal) : undefined;
              
            this.OverShortTotal = 
                (odataObject.OverShortTotal != null) ? parseFloat(odataObject.OverShortTotal) : undefined;
              
            this.TenderedTotal = 
                (odataObject.TenderedTotal != null) ? parseFloat(odataObject.TenderedTotal) : undefined;
              
            this.ChangeTotal = 
                (odataObject.ChangeTotal != null) ? parseFloat(odataObject.ChangeTotal) : undefined;
              
            this.IncomeAccountTotal = 
                (odataObject.IncomeAccountTotal != null) ? parseFloat(odataObject.IncomeAccountTotal) : undefined;
              
            this.ExpenseAccountTotal = 
                (odataObject.ExpenseAccountTotal != null) ? parseFloat(odataObject.ExpenseAccountTotal) : undefined;
              
            this.CashDrawer = 
                odataObject.CashDrawer;
              
            this.StoreRecordId = 
                (odataObject.StoreRecordId != null) ? parseInt(odataObject.StoreRecordId, 10) : undefined;
              
            this.StoreId = 
                odataObject.StoreId;
              
            this.StaffId = 
                odataObject.StaffId;
              
            this.CurrentStaffId = 
                odataObject.CurrentStaffId;
              
            this.StatusValue = 
                odataObject.StatusValue;
              
            this.StartDateTime = 
                (odataObject.StartDateTime instanceof Date) ? odataObject.StartDateTime
                : (odataObject.StartDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.StartDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.CloseDateTime = 
                (odataObject.CloseDateTime instanceof Date) ? odataObject.CloseDateTime
                : (odataObject.CloseDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.CloseDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.ClosedAtTerminalId = 
                odataObject.ClosedAtTerminalId;
              
            this.ClosedAtShiftId = 
                (odataObject.ClosedAtShiftId != null) ? parseInt(odataObject.ClosedAtShiftId, 10) : undefined;
              
            this.CurrentTerminalId = 
                odataObject.CurrentTerminalId;
              
            this.SalesTotal = 
                (odataObject.SalesTotal != null) ? parseFloat(odataObject.SalesTotal) : undefined;
              
            this.StatusDateTime = 
                (odataObject.StatusDateTime instanceof Date) ? odataObject.StatusDateTime
                : (odataObject.StatusDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.StatusDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.ReturnsTotal = 
                (odataObject.ReturnsTotal != null) ? parseFloat(odataObject.ReturnsTotal) : undefined;
              
            this.PaidToAccountTotal = 
                (odataObject.PaidToAccountTotal != null) ? parseFloat(odataObject.PaidToAccountTotal) : undefined;
              
            this.TaxTotal = 
                (odataObject.TaxTotal != null) ? parseFloat(odataObject.TaxTotal) : undefined;
              
            this.ChargeTotal = 
                (odataObject.ChargeTotal != null) ? parseFloat(odataObject.ChargeTotal) : undefined;
              
            this.DiscountTotal = 
                (odataObject.DiscountTotal != null) ? parseFloat(odataObject.DiscountTotal) : undefined;
              
            this.RoundedAmountTotal = 
                (odataObject.RoundedAmountTotal != null) ? parseFloat(odataObject.RoundedAmountTotal) : undefined;
              
            this.CustomerCount = 
                odataObject.CustomerCount;
              
            this.SaleTransactionCount = 
                odataObject.SaleTransactionCount;
              
            this.NoSaleTransactionCount = 
                odataObject.NoSaleTransactionCount;
              
            this.VoidTransactionCount = 
                odataObject.VoidTransactionCount;
              
            this.LogOnTransactionCount = 
                odataObject.LogOnTransactionCount;
              
            this.SuspendedTransactionCount = 
                odataObject.SuspendedTransactionCount;
              
            this.TransactionCount = 
                odataObject.TransactionCount;
              
            this.ReceiptsCount = 
                odataObject.ReceiptsCount;
              
            this.ReturnsCount = 
                odataObject.ReturnsCount;
              
            this.GoodsSoldQty = 
                (odataObject.GoodsSoldQty != null) ? parseFloat(odataObject.GoodsSoldQty) : undefined;
              
            this.ServicesSoldQty = 
                (odataObject.ServicesSoldQty != null) ? parseFloat(odataObject.ServicesSoldQty) : undefined;
              
            this.ReceiptCopiesCount = 
                odataObject.ReceiptCopiesCount;
              
            this.ReceiptCopiesTotal = 
                (odataObject.ReceiptCopiesTotal != null) ? parseFloat(odataObject.ReceiptCopiesTotal) : undefined;
              
            this.PriceOverrideTotal = 
                (odataObject.PriceOverrideTotal != null) ? parseFloat(odataObject.PriceOverrideTotal) : undefined;
              
            this.SuspendedTotal = 
                (odataObject.SuspendedTotal != null) ? parseFloat(odataObject.SuspendedTotal) : undefined;
              
            this.SalesTotalIncludingTax = 
                (odataObject.SalesTotalIncludingTax != null) ? parseFloat(odataObject.SalesTotalIncludingTax) : undefined;
              
            this.SalesTotalExcludingTax = 
                (odataObject.SalesTotalExcludingTax != null) ? parseFloat(odataObject.SalesTotalExcludingTax) : undefined;
              
            this.SalesGrandTotal = 
                (odataObject.SalesGrandTotal != null) ? parseFloat(odataObject.SalesGrandTotal) : undefined;
              
            this.ReturnsGrandTotal = 
                (odataObject.ReturnsGrandTotal != null) ? parseFloat(odataObject.ReturnsGrandTotal) : undefined;
              
        this.TaxLines = undefined;
        if (odataObject.TaxLines) {
        this.TaxLines = [];
        for (var i = 0; i < odataObject.TaxLines.length; i++) {
        if (odataObject.TaxLines[i] != null) {
        if (odataObject.TaxLines[i]['@odata.type'] != null) {
          var className: string = odataObject.TaxLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.TaxLines[i] = new entityDictionary[className](odataObject.TaxLines[i])
          }
        } else {
            this.TaxLines[i] = new ShiftTaxLineClass(odataObject.TaxLines[i]);
        }
                    } else {
        this.TaxLines[i] = undefined;
        }
        }
        }
      
        this.TenderLines = undefined;
        if (odataObject.TenderLines) {
        this.TenderLines = [];
        for (var i = 0; i < odataObject.TenderLines.length; i++) {
        if (odataObject.TenderLines[i] != null) {
        if (odataObject.TenderLines[i]['@odata.type'] != null) {
          var className: string = odataObject.TenderLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.TenderLines[i] = new entityDictionary[className](odataObject.TenderLines[i])
          }
        } else {
            this.TenderLines[i] = new ShiftTenderLineClass(odataObject.TenderLines[i]);
        }
                    } else {
        this.TenderLines[i] = undefined;
        }
        }
        }
      
        this.AccountLines = undefined;
        if (odataObject.AccountLines) {
        this.AccountLines = [];
        for (var i = 0; i < odataObject.AccountLines.length; i++) {
        if (odataObject.AccountLines[i] != null) {
        if (odataObject.AccountLines[i]['@odata.type'] != null) {
          var className: string = odataObject.AccountLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.AccountLines[i] = new entityDictionary[className](odataObject.AccountLines[i])
          }
        } else {
            this.AccountLines[i] = new ShiftAccountLineClass(odataObject.AccountLines[i]);
        }
                    } else {
        this.AccountLines[i] = undefined;
        }
        }
        }
      
            this.IsShared = 
                odataObject.IsShared;
              
            this.StartingAmountRunningTotal = 
                (odataObject.StartingAmountRunningTotal != null) ? parseFloat(odataObject.StartingAmountRunningTotal) : undefined;
              
            this.GiftCardsTotal = 
                (odataObject.GiftCardsTotal != null) ? parseFloat(odataObject.GiftCardsTotal) : undefined;
              
            this.GiftCardCashOutTotal = 
                (odataObject.GiftCardCashOutTotal != null) ? parseFloat(odataObject.GiftCardCashOutTotal) : undefined;
              
            this.VoidedSalesTotal = 
                (odataObject.VoidedSalesTotal != null) ? parseFloat(odataObject.VoidedSalesTotal) : undefined;
              
            this.ShiftSalesTotal = 
                (odataObject.ShiftSalesTotal != null) ? parseFloat(odataObject.ShiftSalesTotal) : undefined;
              
            this.ShiftReturnsTotal = 
                (odataObject.ShiftReturnsTotal != null) ? parseFloat(odataObject.ShiftReturnsTotal) : undefined;
              
            this.HasPendingOfflineTransactions = 
                odataObject.HasPendingOfflineTransactions;
              
            this.NumberOfUnreconciledLines = 
                (odataObject.NumberOfUnreconciledLines != null) ? parseFloat(odataObject.NumberOfUnreconciledLines) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ShiftClass'] = ShiftClass;
    
      /**
      * StockCountJournal entity class.
      */
      export class StockCountJournalClass implements Entities.StockCountJournal {
      public JournalId: string;
      public RecordId: string;
      public Description: string;
      public InventLocationId: string;
      public DataAreaId: string;
      public StockCountTransactionLines: Entities.StockCountJournalTransaction[];
      public Worker: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.JournalId = 
                odataObject.JournalId;
              
            this.RecordId = 
                odataObject.RecordId;
              
            this.Description = 
                odataObject.Description;
              
            this.InventLocationId = 
                odataObject.InventLocationId;
              
            this.DataAreaId = 
                odataObject.DataAreaId;
              
        this.StockCountTransactionLines = undefined;
        if (odataObject.StockCountTransactionLines) {
        this.StockCountTransactionLines = [];
        for (var i = 0; i < odataObject.StockCountTransactionLines.length; i++) {
        if (odataObject.StockCountTransactionLines[i] != null) {
        if (odataObject.StockCountTransactionLines[i]['@odata.type'] != null) {
          var className: string = odataObject.StockCountTransactionLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.StockCountTransactionLines[i] = new entityDictionary[className](odataObject.StockCountTransactionLines[i])
          }
        } else {
            this.StockCountTransactionLines[i] = new StockCountJournalTransactionClass(odataObject.StockCountTransactionLines[i]);
        }
                    } else {
        this.StockCountTransactionLines[i] = undefined;
        }
        }
        }
      
            this.Worker = 
                odataObject.Worker;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['StockCountJournalClass'] = StockCountJournalClass;
    
      /**
      * OrgUnit entity class.
      */
      export class OrgUnitClass implements Entities.OrgUnit {
      public OrgUnitNumber: string;
      public RecordId: number;
      public InventoryLocationId: string;
      public TaxGroup: string;
      public FunctionalityProfileId: string;
      public UseCustomerBasedTax: boolean;
      public UseCustomerBasedTaxExemption: boolean;
      public UseDestinationBasedTax: boolean;
      public Currency: string;
      public TimeZoneCode: number;
      public OrgUnitAddress: Entities.Address;
      public OrgUnitFullAddress: string;
      public OrgUnitName: string;
      public Latitude: number;
      public Longitude: number;
      public EmailReceiptProfileId: string;
      public ShippingWarehouseAddress: Entities.Address;
      public TaxRegistrations: Entities.TaxRegistration[];
      public EventNotificationProfileId: string;
      public OMOperatingUnitNumber: string;
      public Name: string;
      public DefaultCustomerAccount: string;
      public CategoryHierarchyId: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.OrgUnitNumber = 
                odataObject.OrgUnitNumber;
              
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.InventoryLocationId = 
                odataObject.InventoryLocationId;
              
            this.TaxGroup = 
                odataObject.TaxGroup;
              
            this.FunctionalityProfileId = 
                odataObject.FunctionalityProfileId;
              
            this.UseCustomerBasedTax = 
                odataObject.UseCustomerBasedTax;
              
            this.UseCustomerBasedTaxExemption = 
                odataObject.UseCustomerBasedTaxExemption;
              
            this.UseDestinationBasedTax = 
                odataObject.UseDestinationBasedTax;
              
            this.Currency = 
                odataObject.Currency;
              
            this.TimeZoneCode = 
                odataObject.TimeZoneCode;
              
        if (odataObject.OrgUnitAddress == null) {
            this.OrgUnitAddress = undefined;
        } else if (odataObject.OrgUnitAddress['@odata.type'] == null) {
            this.OrgUnitAddress = new AddressClass(odataObject.OrgUnitAddress);
        } else {
            var className: string = odataObject.OrgUnitAddress['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.OrgUnitAddress = new entityDictionary[className](odataObject.OrgUnitAddress)
            }
        }

      
            this.OrgUnitFullAddress = 
                odataObject.OrgUnitFullAddress;
              
            this.OrgUnitName = 
                odataObject.OrgUnitName;
              
            this.Latitude = 
                (odataObject.Latitude != null) ? parseFloat(odataObject.Latitude) : undefined;
              
            this.Longitude = 
                (odataObject.Longitude != null) ? parseFloat(odataObject.Longitude) : undefined;
              
            this.EmailReceiptProfileId = 
                odataObject.EmailReceiptProfileId;
              
        if (odataObject.ShippingWarehouseAddress == null) {
            this.ShippingWarehouseAddress = undefined;
        } else if (odataObject.ShippingWarehouseAddress['@odata.type'] == null) {
            this.ShippingWarehouseAddress = new AddressClass(odataObject.ShippingWarehouseAddress);
        } else {
            var className: string = odataObject.ShippingWarehouseAddress['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.ShippingWarehouseAddress = new entityDictionary[className](odataObject.ShippingWarehouseAddress)
            }
        }

      
        this.TaxRegistrations = undefined;
        if (odataObject.TaxRegistrations) {
        this.TaxRegistrations = [];
        for (var i = 0; i < odataObject.TaxRegistrations.length; i++) {
        if (odataObject.TaxRegistrations[i] != null) {
        if (odataObject.TaxRegistrations[i]['@odata.type'] != null) {
          var className: string = odataObject.TaxRegistrations[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.TaxRegistrations[i] = new entityDictionary[className](odataObject.TaxRegistrations[i])
          }
        } else {
            this.TaxRegistrations[i] = new TaxRegistrationClass(odataObject.TaxRegistrations[i]);
        }
                    } else {
        this.TaxRegistrations[i] = undefined;
        }
        }
        }
      
            this.EventNotificationProfileId = 
                odataObject.EventNotificationProfileId;
              
            this.OMOperatingUnitNumber = 
                odataObject.OMOperatingUnitNumber;
              
            this.Name = 
                odataObject.Name;
              
            this.DefaultCustomerAccount = 
                odataObject.DefaultCustomerAccount;
              
            this.CategoryHierarchyId = 
                (odataObject.CategoryHierarchyId != null) ? parseInt(odataObject.CategoryHierarchyId, 10) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['OrgUnitClass'] = OrgUnitClass;
    
      /**
      * Product entity class.
      */
      export class ProductClass implements Entities.Product {
      public RecordId: number;
      public ProductName: string;
      public ProductNumber: string;
      public Description: string;
      public IsMasterProduct: boolean;
      public IsKit: boolean;
      public ItemId: string;
      public HasLinkedProducts: boolean;
      public Rules: Entities.ProductRules;
      public DefaultUnitOfMeasure: string;
      public Name: string;
      public Locale: string;
      public OfflineImage: string;
      public IsRemote: boolean;
      public ChangeTrackingInformation: Entities.ProductChangeTrackingInformation;
      public Image: Entities.RichMediaLocations;
      public UnitsOfMeasureSymbol: string[];
      public LinkedProducts: Entities.LinkedProduct[];
      public BasePrice: number;
      public Price: number;
      public AdjustedPrice: number;
      public RetailContext: Entities.ProjectionDomain;
      public PrimaryCategoryId: number;
      public CategoryIds: number[];
      public DisplayOrderInCategories: Entities.DisplayOrderInCategory[];
      public RelatedProducts: Entities.RelatedProduct[];
      public ProductsRelatedToThis: Entities.RelatedProduct[];
      public ProductSchema: string[];
      public ProductProperties: Entities.ProductPropertyTranslation[];
      public CompositionInformation: Entities.ProductCompositionInformation;
      public ParentKits: Entities.KitComponent[];
      public SearchName: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.ProductName = 
                odataObject.ProductName;
              
            this.ProductNumber = 
                odataObject.ProductNumber;
              
            this.Description = 
                odataObject.Description;
              
            this.IsMasterProduct = 
                odataObject.IsMasterProduct;
              
            this.IsKit = 
                odataObject.IsKit;
              
            this.ItemId = 
                odataObject.ItemId;
              
            this.HasLinkedProducts = 
                odataObject.HasLinkedProducts;
              
        if (odataObject.Rules == null) {
            this.Rules = undefined;
        } else if (odataObject.Rules['@odata.type'] == null) {
            this.Rules = new ProductRulesClass(odataObject.Rules);
        } else {
            var className: string = odataObject.Rules['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.Rules = new entityDictionary[className](odataObject.Rules)
            }
        }

      
            this.DefaultUnitOfMeasure = 
                odataObject.DefaultUnitOfMeasure;
              
            this.Name = 
                odataObject.Name;
              
            this.Locale = 
                odataObject.Locale;
              
            this.OfflineImage = 
                odataObject.OfflineImage;
              
            this.IsRemote = 
                odataObject.IsRemote;
              
        if (odataObject.ChangeTrackingInformation == null) {
            this.ChangeTrackingInformation = undefined;
        } else if (odataObject.ChangeTrackingInformation['@odata.type'] == null) {
            this.ChangeTrackingInformation = new ProductChangeTrackingInformationClass(odataObject.ChangeTrackingInformation);
        } else {
            var className: string = odataObject.ChangeTrackingInformation['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.ChangeTrackingInformation = new entityDictionary[className](odataObject.ChangeTrackingInformation)
            }
        }

      
        if (odataObject.Image == null) {
            this.Image = undefined;
        } else if (odataObject.Image['@odata.type'] == null) {
            this.Image = new RichMediaLocationsClass(odataObject.Image);
        } else {
            var className: string = odataObject.Image['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.Image = new entityDictionary[className](odataObject.Image)
            }
        }

      
        this.UnitsOfMeasureSymbol = undefined;
        if (odataObject.UnitsOfMeasureSymbol) {
        this.UnitsOfMeasureSymbol = [];
        for (var i = 0; i < odataObject.UnitsOfMeasureSymbol.length; i++) {
        if (odataObject.UnitsOfMeasureSymbol[i] != null) {
            this.UnitsOfMeasureSymbol[i] = 
                odataObject.UnitsOfMeasureSymbol[i];
                            } else {
        this.UnitsOfMeasureSymbol[i] = undefined;
        }
        }
        }
      
        this.LinkedProducts = undefined;
        if (odataObject.LinkedProducts) {
        this.LinkedProducts = [];
        for (var i = 0; i < odataObject.LinkedProducts.length; i++) {
        if (odataObject.LinkedProducts[i] != null) {
        if (odataObject.LinkedProducts[i]['@odata.type'] != null) {
          var className: string = odataObject.LinkedProducts[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.LinkedProducts[i] = new entityDictionary[className](odataObject.LinkedProducts[i])
          }
        } else {
            this.LinkedProducts[i] = new LinkedProductClass(odataObject.LinkedProducts[i]);
        }
                    } else {
        this.LinkedProducts[i] = undefined;
        }
        }
        }
      
            this.BasePrice = 
                (odataObject.BasePrice != null) ? parseFloat(odataObject.BasePrice) : undefined;
              
            this.Price = 
                (odataObject.Price != null) ? parseFloat(odataObject.Price) : undefined;
              
            this.AdjustedPrice = 
                (odataObject.AdjustedPrice != null) ? parseFloat(odataObject.AdjustedPrice) : undefined;
              
        if (odataObject.RetailContext == null) {
            this.RetailContext = undefined;
        } else if (odataObject.RetailContext['@odata.type'] == null) {
            this.RetailContext = new ProjectionDomainClass(odataObject.RetailContext);
        } else {
            var className: string = odataObject.RetailContext['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.RetailContext = new entityDictionary[className](odataObject.RetailContext)
            }
        }

      
            this.PrimaryCategoryId = 
                (odataObject.PrimaryCategoryId != null) ? parseInt(odataObject.PrimaryCategoryId, 10) : undefined;
              
        this.CategoryIds = undefined;
        if (odataObject.CategoryIds) {
        this.CategoryIds = [];
        for (var i = 0; i < odataObject.CategoryIds.length; i++) {
        if (odataObject.CategoryIds[i] != null) {
            this.CategoryIds[i] = 
                parseInt(odataObject.CategoryIds[i], 10);
                            } else {
        this.CategoryIds[i] = undefined;
        }
        }
        }
      
        this.DisplayOrderInCategories = undefined;
        if (odataObject.DisplayOrderInCategories) {
        this.DisplayOrderInCategories = [];
        for (var i = 0; i < odataObject.DisplayOrderInCategories.length; i++) {
        if (odataObject.DisplayOrderInCategories[i] != null) {
        if (odataObject.DisplayOrderInCategories[i]['@odata.type'] != null) {
          var className: string = odataObject.DisplayOrderInCategories[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.DisplayOrderInCategories[i] = new entityDictionary[className](odataObject.DisplayOrderInCategories[i])
          }
        } else {
            this.DisplayOrderInCategories[i] = new DisplayOrderInCategoryClass(odataObject.DisplayOrderInCategories[i]);
        }
                    } else {
        this.DisplayOrderInCategories[i] = undefined;
        }
        }
        }
      
        this.RelatedProducts = undefined;
        if (odataObject.RelatedProducts) {
        this.RelatedProducts = [];
        for (var i = 0; i < odataObject.RelatedProducts.length; i++) {
        if (odataObject.RelatedProducts[i] != null) {
        if (odataObject.RelatedProducts[i]['@odata.type'] != null) {
          var className: string = odataObject.RelatedProducts[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.RelatedProducts[i] = new entityDictionary[className](odataObject.RelatedProducts[i])
          }
        } else {
            this.RelatedProducts[i] = new RelatedProductClass(odataObject.RelatedProducts[i]);
        }
                    } else {
        this.RelatedProducts[i] = undefined;
        }
        }
        }
      
        this.ProductsRelatedToThis = undefined;
        if (odataObject.ProductsRelatedToThis) {
        this.ProductsRelatedToThis = [];
        for (var i = 0; i < odataObject.ProductsRelatedToThis.length; i++) {
        if (odataObject.ProductsRelatedToThis[i] != null) {
        if (odataObject.ProductsRelatedToThis[i]['@odata.type'] != null) {
          var className: string = odataObject.ProductsRelatedToThis[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ProductsRelatedToThis[i] = new entityDictionary[className](odataObject.ProductsRelatedToThis[i])
          }
        } else {
            this.ProductsRelatedToThis[i] = new RelatedProductClass(odataObject.ProductsRelatedToThis[i]);
        }
                    } else {
        this.ProductsRelatedToThis[i] = undefined;
        }
        }
        }
      
        this.ProductSchema = undefined;
        if (odataObject.ProductSchema) {
        this.ProductSchema = [];
        for (var i = 0; i < odataObject.ProductSchema.length; i++) {
        if (odataObject.ProductSchema[i] != null) {
            this.ProductSchema[i] = 
                odataObject.ProductSchema[i];
                            } else {
        this.ProductSchema[i] = undefined;
        }
        }
        }
      
        this.ProductProperties = undefined;
        if (odataObject.ProductProperties) {
        this.ProductProperties = [];
        for (var i = 0; i < odataObject.ProductProperties.length; i++) {
        if (odataObject.ProductProperties[i] != null) {
        if (odataObject.ProductProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ProductProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ProductProperties[i] = new entityDictionary[className](odataObject.ProductProperties[i])
          }
        } else {
            this.ProductProperties[i] = new ProductPropertyTranslationClass(odataObject.ProductProperties[i]);
        }
                    } else {
        this.ProductProperties[i] = undefined;
        }
        }
        }
      
        if (odataObject.CompositionInformation == null) {
            this.CompositionInformation = undefined;
        } else if (odataObject.CompositionInformation['@odata.type'] == null) {
            this.CompositionInformation = new ProductCompositionInformationClass(odataObject.CompositionInformation);
        } else {
            var className: string = odataObject.CompositionInformation['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.CompositionInformation = new entityDictionary[className](odataObject.CompositionInformation)
            }
        }

      
        this.ParentKits = undefined;
        if (odataObject.ParentKits) {
        this.ParentKits = [];
        for (var i = 0; i < odataObject.ParentKits.length; i++) {
        if (odataObject.ParentKits[i] != null) {
        if (odataObject.ParentKits[i]['@odata.type'] != null) {
          var className: string = odataObject.ParentKits[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ParentKits[i] = new entityDictionary[className](odataObject.ParentKits[i])
          }
        } else {
            this.ParentKits[i] = new KitComponentClass(odataObject.ParentKits[i]);
        }
                    } else {
        this.ParentKits[i] = undefined;
        }
        }
        }
      
            this.SearchName = 
                odataObject.SearchName;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ProductClass'] = ProductClass;
    
      /**
      * ProductCatalog entity class.
      */
      export class ProductCatalogClass implements Entities.ProductCatalog {
      public RecordId: number;
      public Name: string;
      public Description: string;
      public Language: string;
      public IsSnapshotEnabled: boolean;
      public ValidFrom: Date;
      public ValidTo: Date;
      public CreatedOn: Date;
      public ModifiedOn: Date;
      public PublishedOn: Date;
      public Images: Entities.MediaLocation[];
      public ChannelId: number;
      public DefaultImage: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.Name = 
                odataObject.Name;
              
            this.Description = 
                odataObject.Description;
              
            this.Language = 
                odataObject.Language;
              
            this.IsSnapshotEnabled = 
                odataObject.IsSnapshotEnabled;
              
            this.ValidFrom = 
                (odataObject.ValidFrom instanceof Date) ? odataObject.ValidFrom
                : (odataObject.ValidFrom != null) ? jsonLightReadStringPropertyValue(odataObject.ValidFrom, "Edm.DateTimeOffset", false) : undefined;
              
            this.ValidTo = 
                (odataObject.ValidTo instanceof Date) ? odataObject.ValidTo
                : (odataObject.ValidTo != null) ? jsonLightReadStringPropertyValue(odataObject.ValidTo, "Edm.DateTimeOffset", false) : undefined;
              
            this.CreatedOn = 
                (odataObject.CreatedOn instanceof Date) ? odataObject.CreatedOn
                : (odataObject.CreatedOn != null) ? jsonLightReadStringPropertyValue(odataObject.CreatedOn, "Edm.DateTimeOffset", false) : undefined;
              
            this.ModifiedOn = 
                (odataObject.ModifiedOn instanceof Date) ? odataObject.ModifiedOn
                : (odataObject.ModifiedOn != null) ? jsonLightReadStringPropertyValue(odataObject.ModifiedOn, "Edm.DateTimeOffset", false) : undefined;
              
            this.PublishedOn = 
                (odataObject.PublishedOn instanceof Date) ? odataObject.PublishedOn
                : (odataObject.PublishedOn != null) ? jsonLightReadStringPropertyValue(odataObject.PublishedOn, "Edm.DateTimeOffset", false) : undefined;
              
        this.Images = undefined;
        if (odataObject.Images) {
        this.Images = [];
        for (var i = 0; i < odataObject.Images.length; i++) {
        if (odataObject.Images[i] != null) {
        if (odataObject.Images[i]['@odata.type'] != null) {
          var className: string = odataObject.Images[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Images[i] = new entityDictionary[className](odataObject.Images[i])
          }
        } else {
            this.Images[i] = new MediaLocationClass(odataObject.Images[i]);
        }
                    } else {
        this.Images[i] = undefined;
        }
        }
        }
      
            this.ChannelId = 
                (odataObject.ChannelId != null) ? parseInt(odataObject.ChannelId, 10) : undefined;
              
            this.DefaultImage = 
                odataObject.DefaultImage;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ProductCatalogClass'] = ProductCatalogClass;
    
      /**
      * CommerceList entity class.
      */
      export class CommerceListClass implements Entities.CommerceList {
      public Id: number;
      public Name: string;
      public CustomerId: string;
      public CustomerName: string;
      public IsFavorite: boolean;
      public IsRecurring: boolean;
      public IsPrivate: boolean;
      public IsCollaborative: boolean;
      public CreatedDateTime: Date;
      public DueDateTime: Date;
      public CommerceListTypeValue: number;
      public CommerceListLines: Entities.CommerceListLine[];
      public CommerceListContributors: Entities.CommerceListContributor[];
      public CommerceListInvitations: Entities.CommerceListInvitation[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Id = 
                (odataObject.Id != null) ? parseInt(odataObject.Id, 10) : undefined;
              
            this.Name = 
                odataObject.Name;
              
            this.CustomerId = 
                odataObject.CustomerId;
              
            this.CustomerName = 
                odataObject.CustomerName;
              
            this.IsFavorite = 
                odataObject.IsFavorite;
              
            this.IsRecurring = 
                odataObject.IsRecurring;
              
            this.IsPrivate = 
                odataObject.IsPrivate;
              
            this.IsCollaborative = 
                odataObject.IsCollaborative;
              
            this.CreatedDateTime = 
                (odataObject.CreatedDateTime instanceof Date) ? odataObject.CreatedDateTime
                : (odataObject.CreatedDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.CreatedDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.DueDateTime = 
                (odataObject.DueDateTime instanceof Date) ? odataObject.DueDateTime
                : (odataObject.DueDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.DueDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.CommerceListTypeValue = 
                odataObject.CommerceListTypeValue;
              
        this.CommerceListLines = undefined;
        if (odataObject.CommerceListLines) {
        this.CommerceListLines = [];
        for (var i = 0; i < odataObject.CommerceListLines.length; i++) {
        if (odataObject.CommerceListLines[i] != null) {
        if (odataObject.CommerceListLines[i]['@odata.type'] != null) {
          var className: string = odataObject.CommerceListLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.CommerceListLines[i] = new entityDictionary[className](odataObject.CommerceListLines[i])
          }
        } else {
            this.CommerceListLines[i] = new CommerceListLineClass(odataObject.CommerceListLines[i]);
        }
                    } else {
        this.CommerceListLines[i] = undefined;
        }
        }
        }
      
        this.CommerceListContributors = undefined;
        if (odataObject.CommerceListContributors) {
        this.CommerceListContributors = [];
        for (var i = 0; i < odataObject.CommerceListContributors.length; i++) {
        if (odataObject.CommerceListContributors[i] != null) {
        if (odataObject.CommerceListContributors[i]['@odata.type'] != null) {
          var className: string = odataObject.CommerceListContributors[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.CommerceListContributors[i] = new entityDictionary[className](odataObject.CommerceListContributors[i])
          }
        } else {
            this.CommerceListContributors[i] = new CommerceListContributorClass(odataObject.CommerceListContributors[i]);
        }
                    } else {
        this.CommerceListContributors[i] = undefined;
        }
        }
        }
      
        this.CommerceListInvitations = undefined;
        if (odataObject.CommerceListInvitations) {
        this.CommerceListInvitations = [];
        for (var i = 0; i < odataObject.CommerceListInvitations.length; i++) {
        if (odataObject.CommerceListInvitations[i] != null) {
        if (odataObject.CommerceListInvitations[i]['@odata.type'] != null) {
          var className: string = odataObject.CommerceListInvitations[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.CommerceListInvitations[i] = new entityDictionary[className](odataObject.CommerceListInvitations[i])
          }
        } else {
            this.CommerceListInvitations[i] = new CommerceListInvitationClass(odataObject.CommerceListInvitations[i]);
        }
                    } else {
        this.CommerceListInvitations[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CommerceListClass'] = CommerceListClass;
    
      /**
      * ProductList entity class.
      */
      export class ProductListClass implements Entities.ProductList {
      public Id: string;
      public Name: string;
      public CustomerId: string;
      public CreatedDateTime: Date;
      public LastUpdated: Date;
      public DeletedDateTime: Date;
      public ProductListTypeValue: number;
      public DataAreaId: string;
      public ChannelId: number;
      public ProductListLines: Entities.ProductListLine[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Id = 
                odataObject.Id;
              
            this.Name = 
                odataObject.Name;
              
            this.CustomerId = 
                odataObject.CustomerId;
              
            this.CreatedDateTime = 
                (odataObject.CreatedDateTime instanceof Date) ? odataObject.CreatedDateTime
                : (odataObject.CreatedDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.CreatedDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.LastUpdated = 
                (odataObject.LastUpdated instanceof Date) ? odataObject.LastUpdated
                : (odataObject.LastUpdated != null) ? jsonLightReadStringPropertyValue(odataObject.LastUpdated, "Edm.DateTimeOffset", false) : undefined;
              
            this.DeletedDateTime = 
                (odataObject.DeletedDateTime instanceof Date) ? odataObject.DeletedDateTime
                : (odataObject.DeletedDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.DeletedDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.ProductListTypeValue = 
                odataObject.ProductListTypeValue;
              
            this.DataAreaId = 
                odataObject.DataAreaId;
              
            this.ChannelId = 
                (odataObject.ChannelId != null) ? parseInt(odataObject.ChannelId, 10) : undefined;
              
        this.ProductListLines = undefined;
        if (odataObject.ProductListLines) {
        this.ProductListLines = [];
        for (var i = 0; i < odataObject.ProductListLines.length; i++) {
        if (odataObject.ProductListLines[i] != null) {
        if (odataObject.ProductListLines[i]['@odata.type'] != null) {
          var className: string = odataObject.ProductListLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ProductListLines[i] = new entityDictionary[className](odataObject.ProductListLines[i])
          }
        } else {
            this.ProductListLines[i] = new ProductListLineClass(odataObject.ProductListLines[i]);
        }
                    } else {
        this.ProductListLines[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ProductListClass'] = ProductListClass;
    
      /**
      * Recommendation entity class.
      */
      export class RecommendationClass implements Entities.Recommendation {
      public Id: string;
      public RecommendationTypeValue: number;
      public Title: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Id = 
                odataObject.Id;
              
            this.RecommendationTypeValue = 
                odataObject.RecommendationTypeValue;
              
            this.Title = 
                odataObject.Title;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['RecommendationClass'] = RecommendationClass;
    
      /**
      * TransferOrder entity class.
      */
      export class TransferOrderClass implements Entities.TransferOrder {
      public OrderId: string;
      public RecordId: string;
      public Status: string;
      public Lines: number;
      public TotalItems: number;
      public OrderTypeValue: number;
      public InventLocationIdFrom: string;
      public InventLocationIdTo: string;
      public QuantityShipped: number;
      public QuantityReceived: number;
      public QuantityShipNow: number;
      public QuantityReceiveNow: number;
      public QuantityShipRemaining: number;
      public QuantityReceiveRemaining: number;
      public ShipDate: Date;
      public ReceiveDate: Date;
      public DeliveryModeId: string;
      public OrderLines: Entities.TransferOrderLine[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.OrderId = 
                odataObject.OrderId;
              
            this.RecordId = 
                odataObject.RecordId;
              
            this.Status = 
                odataObject.Status;
              
            this.Lines = 
                (odataObject.Lines != null) ? parseFloat(odataObject.Lines) : undefined;
              
            this.TotalItems = 
                (odataObject.TotalItems != null) ? parseFloat(odataObject.TotalItems) : undefined;
              
            this.OrderTypeValue = 
                odataObject.OrderTypeValue;
              
            this.InventLocationIdFrom = 
                odataObject.InventLocationIdFrom;
              
            this.InventLocationIdTo = 
                odataObject.InventLocationIdTo;
              
            this.QuantityShipped = 
                (odataObject.QuantityShipped != null) ? parseFloat(odataObject.QuantityShipped) : undefined;
              
            this.QuantityReceived = 
                (odataObject.QuantityReceived != null) ? parseFloat(odataObject.QuantityReceived) : undefined;
              
            this.QuantityShipNow = 
                (odataObject.QuantityShipNow != null) ? parseFloat(odataObject.QuantityShipNow) : undefined;
              
            this.QuantityReceiveNow = 
                (odataObject.QuantityReceiveNow != null) ? parseFloat(odataObject.QuantityReceiveNow) : undefined;
              
            this.QuantityShipRemaining = 
                (odataObject.QuantityShipRemaining != null) ? parseFloat(odataObject.QuantityShipRemaining) : undefined;
              
            this.QuantityReceiveRemaining = 
                (odataObject.QuantityReceiveRemaining != null) ? parseFloat(odataObject.QuantityReceiveRemaining) : undefined;
              
            this.ShipDate = 
                (odataObject.ShipDate instanceof Date) ? odataObject.ShipDate
                : (odataObject.ShipDate != null) ? jsonLightReadStringPropertyValue(odataObject.ShipDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.ReceiveDate = 
                (odataObject.ReceiveDate instanceof Date) ? odataObject.ReceiveDate
                : (odataObject.ReceiveDate != null) ? jsonLightReadStringPropertyValue(odataObject.ReceiveDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.DeliveryModeId = 
                odataObject.DeliveryModeId;
              
        this.OrderLines = undefined;
        if (odataObject.OrderLines) {
        this.OrderLines = [];
        for (var i = 0; i < odataObject.OrderLines.length; i++) {
        if (odataObject.OrderLines[i] != null) {
        if (odataObject.OrderLines[i]['@odata.type'] != null) {
          var className: string = odataObject.OrderLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.OrderLines[i] = new entityDictionary[className](odataObject.OrderLines[i])
          }
        } else {
            this.OrderLines[i] = new TransferOrderLineClass(odataObject.OrderLines[i]);
        }
                    } else {
        this.OrderLines[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['TransferOrderClass'] = TransferOrderClass;
    
      /**
      * PurchaseOrder entity class.
      */
      export class PurchaseOrderClass implements Entities.PurchaseOrder {
      public OrderId: string;
      public RecordId: string;
      public Status: string;
      public Lines: number;
      public TotalItems: number;
      public OrderTypeValue: number;
      public TotalReceived: number;
      public OrderLines: Entities.PurchaseOrderLine[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.OrderId = 
                odataObject.OrderId;
              
            this.RecordId = 
                odataObject.RecordId;
              
            this.Status = 
                odataObject.Status;
              
            this.Lines = 
                (odataObject.Lines != null) ? parseFloat(odataObject.Lines) : undefined;
              
            this.TotalItems = 
                (odataObject.TotalItems != null) ? parseFloat(odataObject.TotalItems) : undefined;
              
            this.OrderTypeValue = 
                odataObject.OrderTypeValue;
              
            this.TotalReceived = 
                (odataObject.TotalReceived != null) ? parseFloat(odataObject.TotalReceived) : undefined;
              
        this.OrderLines = undefined;
        if (odataObject.OrderLines) {
        this.OrderLines = [];
        for (var i = 0; i < odataObject.OrderLines.length; i++) {
        if (odataObject.OrderLines[i] != null) {
        if (odataObject.OrderLines[i]['@odata.type'] != null) {
          var className: string = odataObject.OrderLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.OrderLines[i] = new entityDictionary[className](odataObject.OrderLines[i])
          }
        } else {
            this.OrderLines[i] = new PurchaseOrderLineClass(odataObject.OrderLines[i]);
        }
                    } else {
        this.OrderLines[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['PurchaseOrderClass'] = PurchaseOrderClass;
    
      /**
      * PickingList entity class.
      */
      export class PickingListClass implements Entities.PickingList {
      public OrderId: string;
      public RecordId: string;
      public Status: string;
      public Lines: number;
      public TotalItems: number;
      public OrderTypeValue: number;
      public InventLocationId: string;
      public DeliveryDate: Date;
      public DeliveryMode: string;
      public TotalReceived: number;
      public OrderLines: Entities.PickingListLine[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.OrderId = 
                odataObject.OrderId;
              
            this.RecordId = 
                odataObject.RecordId;
              
            this.Status = 
                odataObject.Status;
              
            this.Lines = 
                (odataObject.Lines != null) ? parseFloat(odataObject.Lines) : undefined;
              
            this.TotalItems = 
                (odataObject.TotalItems != null) ? parseFloat(odataObject.TotalItems) : undefined;
              
            this.OrderTypeValue = 
                odataObject.OrderTypeValue;
              
            this.InventLocationId = 
                odataObject.InventLocationId;
              
            this.DeliveryDate = 
                (odataObject.DeliveryDate instanceof Date) ? odataObject.DeliveryDate
                : (odataObject.DeliveryDate != null) ? jsonLightReadStringPropertyValue(odataObject.DeliveryDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.DeliveryMode = 
                odataObject.DeliveryMode;
              
            this.TotalReceived = 
                (odataObject.TotalReceived != null) ? parseFloat(odataObject.TotalReceived) : undefined;
              
        this.OrderLines = undefined;
        if (odataObject.OrderLines) {
        this.OrderLines = [];
        for (var i = 0; i < odataObject.OrderLines.length; i++) {
        if (odataObject.OrderLines[i] != null) {
        if (odataObject.OrderLines[i]['@odata.type'] != null) {
          var className: string = odataObject.OrderLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.OrderLines[i] = new entityDictionary[className](odataObject.OrderLines[i])
          }
        } else {
            this.OrderLines[i] = new PickingListLineClass(odataObject.OrderLines[i]);
        }
                    } else {
        this.OrderLines[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['PickingListClass'] = PickingListClass;
    
      /**
      * Warehouse entity class.
      */
      export class WarehouseClass implements Entities.Warehouse {
      public InventLocation: string;
      public Name: string;
      public IsWMSEnabled: boolean;
      public DefaultInventoryStatus: string;
      public DefaultReceiptLocation: string;
      public DefaultLocationId: string;
      public DefaultIssueLocation: string;
      public Address: Entities.Address;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.InventLocation = 
                odataObject.InventLocation;
              
            this.Name = 
                odataObject.Name;
              
            this.IsWMSEnabled = 
                odataObject.IsWMSEnabled;
              
            this.DefaultInventoryStatus = 
                odataObject.DefaultInventoryStatus;
              
            this.DefaultReceiptLocation = 
                odataObject.DefaultReceiptLocation;
              
            this.DefaultLocationId = 
                odataObject.DefaultLocationId;
              
            this.DefaultIssueLocation = 
                odataObject.DefaultIssueLocation;
              
        if (odataObject.Address == null) {
            this.Address = undefined;
        } else if (odataObject.Address['@odata.type'] == null) {
            this.Address = new AddressClass(odataObject.Address);
        } else {
            var className: string = odataObject.Address['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.Address = new entityDictionary[className](odataObject.Address)
            }
        }

      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['WarehouseClass'] = WarehouseClass;
    
      /**
      * ScanResult entity class.
      */
      export class ScanResultClass implements Entities.ScanResult {
      public ScannedText: string;
      public MaskTypeValue: number;
      public Barcode: Entities.Barcode;
      public Product: Entities.SimpleProduct;
      public LoyaltyCard: Entities.LoyaltyCard;
      public GiftCard: Entities.GiftCard;
      public ExtensionProperties: Entities.CommerceProperty[];
      public Customer: Entities.Customer;
      
      // Navigation properties names
      public static customerPropertyName: string = "Customer";
        
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ScannedText = 
                odataObject.ScannedText;
              
            this.MaskTypeValue = 
                odataObject.MaskTypeValue;
              
        if (odataObject.Barcode == null) {
            this.Barcode = undefined;
        } else if (odataObject.Barcode['@odata.type'] == null) {
            this.Barcode = new BarcodeClass(odataObject.Barcode);
        } else {
            var className: string = odataObject.Barcode['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.Barcode = new entityDictionary[className](odataObject.Barcode)
            }
        }

      
        if (odataObject.Product == null) {
            this.Product = undefined;
        } else if (odataObject.Product['@odata.type'] == null) {
            this.Product = new SimpleProductClass(odataObject.Product);
        } else {
            var className: string = odataObject.Product['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.Product = new entityDictionary[className](odataObject.Product)
            }
        }

      
        if (odataObject.LoyaltyCard == null) {
            this.LoyaltyCard = undefined;
        } else if (odataObject.LoyaltyCard['@odata.type'] == null) {
            this.LoyaltyCard = new LoyaltyCardClass(odataObject.LoyaltyCard);
        } else {
            var className: string = odataObject.LoyaltyCard['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.LoyaltyCard = new entityDictionary[className](odataObject.LoyaltyCard)
            }
        }

      
        if (odataObject.GiftCard == null) {
            this.GiftCard = undefined;
        } else if (odataObject.GiftCard['@odata.type'] == null) {
            this.GiftCard = new GiftCardClass(odataObject.GiftCard);
        } else {
            var className: string = odataObject.GiftCard['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.GiftCard = new entityDictionary[className](odataObject.GiftCard)
            }
        }

      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
        if (odataObject.Customer == null) {
            this.Customer = undefined;
        } else if (odataObject.Customer['@odata.type'] == null) {
            this.Customer = new CustomerClass(odataObject.Customer);
        } else {
            var className: string = odataObject.Customer['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.Customer = new entityDictionary[className](odataObject.Customer)
            }
        }

      
      }
      }
      entityDictionary['ScanResultClass'] = ScanResultClass;
    
      /**
      * SalesOrderContextualOperationsMap entity class.
      */
      export class SalesOrderContextualOperationsMapClass implements Entities.SalesOrderContextualOperationsMap {
      public Id: string;
      public ApplicableOperationsValues: number[];
      public ExtensionProperties: Entities.CommerceProperty[];
      public SalesOrder: Entities.SalesOrder;
      
      // Navigation properties names
      public static salesOrderPropertyName: string = "SalesOrder";
        
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Id = 
                odataObject.Id;
              
        this.ApplicableOperationsValues = undefined;
        if (odataObject.ApplicableOperationsValues) {
        this.ApplicableOperationsValues = [];
        for (var i = 0; i < odataObject.ApplicableOperationsValues.length; i++) {
        if (odataObject.ApplicableOperationsValues[i] != null) {
            this.ApplicableOperationsValues[i] = 
                odataObject.ApplicableOperationsValues[i];
                            } else {
        this.ApplicableOperationsValues[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
        if (odataObject.SalesOrder == null) {
            this.SalesOrder = undefined;
        } else if (odataObject.SalesOrder['@odata.type'] == null) {
            this.SalesOrder = new SalesOrderClass(odataObject.SalesOrder);
        } else {
            var className: string = odataObject.SalesOrder['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.SalesOrder = new entityDictionary[className](odataObject.SalesOrder)
            }
        }

      
      }
      }
      entityDictionary['SalesOrderContextualOperationsMapClass'] = SalesOrderContextualOperationsMapClass;
    
      /**
      * InventoryInboundOutboundDocument entity class.
      */
      export class InventoryInboundOutboundDocumentClass implements Entities.InventoryInboundOutboundDocument {
      public WorkDocumentId: string;
      public TerminalId: string;
      public CommitRequestId: string;
      public ReceiveDate: Date;
      public ShipDate: Date;
      public Sender: string;
      public Receiver: string;
      public SenderDetails: string;
      public ReceiverDetails: string;
      public DeliveryMode: string;
      public OperationTypeValue: number;
      public StatusValue: number;
      public ReceiptId: string;
      public Note: string;
      public OperationResultMessage: string;
      public DocumentId: string;
      public LongVersion: number;
      public SourceDocument: Entities.InventoryInboundOutboundSourceDocument;
      public SourceDocumentTypeValue: number;
      public CreatedDateTime: Date;
      public NoteModifiedByStaffId: string;
      public NoteModifiedDateTime: Date;
      public DocumentTypeId: string;
      public Description: string;
      public StaffId: string;
      public TransactionDate: Date;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.WorkDocumentId = 
                odataObject.WorkDocumentId;
              
            this.TerminalId = 
                odataObject.TerminalId;
              
            this.CommitRequestId = 
                odataObject.CommitRequestId;
              
            this.ReceiveDate = 
                (odataObject.ReceiveDate instanceof Date) ? odataObject.ReceiveDate
                : (odataObject.ReceiveDate != null) ? jsonLightReadStringPropertyValue(odataObject.ReceiveDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.ShipDate = 
                (odataObject.ShipDate instanceof Date) ? odataObject.ShipDate
                : (odataObject.ShipDate != null) ? jsonLightReadStringPropertyValue(odataObject.ShipDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.Sender = 
                odataObject.Sender;
              
            this.Receiver = 
                odataObject.Receiver;
              
            this.SenderDetails = 
                odataObject.SenderDetails;
              
            this.ReceiverDetails = 
                odataObject.ReceiverDetails;
              
            this.DeliveryMode = 
                odataObject.DeliveryMode;
              
            this.OperationTypeValue = 
                odataObject.OperationTypeValue;
              
            this.StatusValue = 
                odataObject.StatusValue;
              
            this.ReceiptId = 
                odataObject.ReceiptId;
              
            this.Note = 
                odataObject.Note;
              
            this.OperationResultMessage = 
                odataObject.OperationResultMessage;
              
            this.DocumentId = 
                odataObject.DocumentId;
              
            this.LongVersion = 
                (odataObject.LongVersion != null) ? parseInt(odataObject.LongVersion, 10) : undefined;
              
        if (odataObject.SourceDocument == null) {
            this.SourceDocument = undefined;
        } else if (odataObject.SourceDocument['@odata.type'] == null) {
            this.SourceDocument = new InventoryInboundOutboundSourceDocumentClass(odataObject.SourceDocument);
        } else {
            var className: string = odataObject.SourceDocument['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.SourceDocument = new entityDictionary[className](odataObject.SourceDocument)
            }
        }

      
            this.SourceDocumentTypeValue = 
                odataObject.SourceDocumentTypeValue;
              
            this.CreatedDateTime = 
                (odataObject.CreatedDateTime instanceof Date) ? odataObject.CreatedDateTime
                : (odataObject.CreatedDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.CreatedDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.NoteModifiedByStaffId = 
                odataObject.NoteModifiedByStaffId;
              
            this.NoteModifiedDateTime = 
                (odataObject.NoteModifiedDateTime instanceof Date) ? odataObject.NoteModifiedDateTime
                : (odataObject.NoteModifiedDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.NoteModifiedDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.DocumentTypeId = 
                odataObject.DocumentTypeId;
              
            this.Description = 
                odataObject.Description;
              
            this.StaffId = 
                odataObject.StaffId;
              
            this.TransactionDate = 
                (odataObject.TransactionDate instanceof Date) ? odataObject.TransactionDate
                : (odataObject.TransactionDate != null) ? jsonLightReadStringPropertyValue(odataObject.TransactionDate, "Edm.DateTimeOffset", false) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['InventoryInboundOutboundDocumentClass'] = InventoryInboundOutboundDocumentClass;
    
      /**
      * BusinessPartner entity class.
      */
      export class BusinessPartnerClass implements Entities.BusinessPartner {
      public BusinessPartnerId: string;
      public Name: string;
      public SellerChannelId: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.BusinessPartnerId = 
                odataObject.BusinessPartnerId;
              
            this.Name = 
                odataObject.Name;
              
            this.SellerChannelId = 
                odataObject.SellerChannelId;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['BusinessPartnerClass'] = BusinessPartnerClass;
    
      /**
      * BusinessPartnerUser entity class.
      */
      export class BusinessPartnerUserClass implements Entities.BusinessPartnerUser {
      public B2BUserId: string;
      public BusinessPartnerId: string;
      public FirstName: string;
      public LastName: string;
      public Email: string;
      public SpendingLimit: number;
      public LastOperationId: string;
      public StatusValue: number;
      public IsAdmin: boolean;
      public AccountNumber: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.B2BUserId = 
                odataObject.B2BUserId;
              
            this.BusinessPartnerId = 
                odataObject.BusinessPartnerId;
              
            this.FirstName = 
                odataObject.FirstName;
              
            this.LastName = 
                odataObject.LastName;
              
            this.Email = 
                odataObject.Email;
              
            this.SpendingLimit = 
                (odataObject.SpendingLimit != null) ? parseFloat(odataObject.SpendingLimit) : undefined;
              
            this.LastOperationId = 
                odataObject.LastOperationId;
              
            this.StatusValue = 
                odataObject.StatusValue;
              
            this.IsAdmin = 
                odataObject.IsAdmin;
              
            this.AccountNumber = 
                odataObject.AccountNumber;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['BusinessPartnerUserClass'] = BusinessPartnerUserClass;
    
      /**
      * SuspendedCart entity class.
      */
      export class SuspendedCartClass implements Entities.SuspendedCart {
      public Id: string;
      public ReceiptId: string;
      public ShiftId: number;
      public ShiftTerminalId: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      public Cart: Entities.Cart;
      
      // Navigation properties names
      public static cartPropertyName: string = "Cart";
        
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Id = 
                odataObject.Id;
              
            this.ReceiptId = 
                odataObject.ReceiptId;
              
            this.ShiftId = 
                (odataObject.ShiftId != null) ? parseInt(odataObject.ShiftId, 10) : undefined;
              
            this.ShiftTerminalId = 
                odataObject.ShiftTerminalId;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
        if (odataObject.Cart == null) {
            this.Cart = undefined;
        } else if (odataObject.Cart['@odata.type'] == null) {
            this.Cart = new CartClass(odataObject.Cart);
        } else {
            var className: string = odataObject.Cart['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.Cart = new entityDictionary[className](odataObject.Cart)
            }
        }

      
      }
      }
      entityDictionary['SuspendedCartClass'] = SuspendedCartClass;
    
      /**
      * ExtensibleEnumeration entity class.
      */
      export class ExtensibleEnumerationClass implements Entities.ExtensibleEnumeration {
      public Name: string;
      public Value: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Name = 
                odataObject.Name;
              
            this.Value = 
                odataObject.Value;
              
      }
      }
      entityDictionary['ExtensibleEnumerationClass'] = ExtensibleEnumerationClass;
    
      /**
      * ExtensibleEnumeration_1OfT entity class.
      */
      export class ExtensibleEnumeration_1OfTClass
        extends ExtensibleEnumerationClass
       implements Entities.ExtensibleEnumeration_1OfT {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.Framework.ExtensibleEnumeration_1OfT";
      
      }
      }
      entityDictionary['ExtensibleEnumeration_1OfTClass'] = ExtensibleEnumeration_1OfTClass;
    
      /**
      * ExtensibleTransactionType entity class.
      */
      export class ExtensibleTransactionTypeClass
        extends ExtensibleEnumerationClass
       implements Entities.ExtensibleTransactionType {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.ExtensibleTransactionType";
      
      }
      }
      entityDictionary['ExtensibleTransactionTypeClass'] = ExtensibleTransactionTypeClass;
    
      /**
      * ExtensibleSalesTransactionType entity class.
      */
      export class ExtensibleSalesTransactionTypeClass
        extends ExtensibleEnumerationClass
       implements Entities.ExtensibleSalesTransactionType {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.ExtensibleSalesTransactionType";
      
      }
      }
      entityDictionary['ExtensibleSalesTransactionTypeClass'] = ExtensibleSalesTransactionTypeClass;
    
      /**
      * FulfillmentLineStatus entity class.
      */
      export class FulfillmentLineStatusClass
        extends ExtensibleEnumerationClass
       implements Entities.FulfillmentLineStatus {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.FulfillmentLineStatus";
      
      }
      }
      entityDictionary['FulfillmentLineStatusClass'] = FulfillmentLineStatusClass;
    
      /**
      * FulfillmentLineDeliveryType entity class.
      */
      export class FulfillmentLineDeliveryTypeClass
        extends ExtensibleEnumerationClass
       implements Entities.FulfillmentLineDeliveryType {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.FulfillmentLineDeliveryType";
      
      }
      }
      entityDictionary['FulfillmentLineDeliveryTypeClass'] = FulfillmentLineDeliveryTypeClass;
    
      /**
      * ExtensibleAddressType entity class.
      */
      export class ExtensibleAddressTypeClass
        extends ExtensibleEnumerationClass
       implements Entities.ExtensibleAddressType {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.ExtensibleAddressType";
      
      }
      }
      entityDictionary['ExtensibleAddressTypeClass'] = ExtensibleAddressTypeClass;
    
      /**
      * ExtensibleLoyaltyRewardPointActivityEntryType entity class.
      */
      export class ExtensibleLoyaltyRewardPointActivityEntryTypeClass
        extends ExtensibleEnumerationClass
       implements Entities.ExtensibleLoyaltyRewardPointActivityEntryType {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.ExtensibleLoyaltyRewardPointActivityEntryType";
      
      }
      }
      entityDictionary['ExtensibleLoyaltyRewardPointActivityEntryTypeClass'] = ExtensibleLoyaltyRewardPointActivityEntryTypeClass;
    
      /**
      * ExtensibleAttributeDataType entity class.
      */
      export class ExtensibleAttributeDataTypeClass
        extends ExtensibleEnumerationClass
       implements Entities.ExtensibleAttributeDataType {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.ExtensibleAttributeDataType";
      
      }
      }
      entityDictionary['ExtensibleAttributeDataTypeClass'] = ExtensibleAttributeDataTypeClass;
    
      /**
      * ReleasedProductServiceType entity class.
      */
      export class ReleasedProductServiceTypeClass
        extends ExtensibleEnumerationClass
       implements Entities.ReleasedProductServiceType {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.ReleasedProductServiceType";
      
      }
      }
      entityDictionary['ReleasedProductServiceTypeClass'] = ReleasedProductServiceTypeClass;
    
      /**
      * ExtensibleElectronicAddressType entity class.
      */
      export class ExtensibleElectronicAddressTypeClass
        extends ExtensibleEnumerationClass
       implements Entities.ExtensibleElectronicAddressType {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.ExtensibleElectronicAddressType";
      
      }
      }
      entityDictionary['ExtensibleElectronicAddressTypeClass'] = ExtensibleElectronicAddressTypeClass;
    
      /**
      * ReceiptOutputFormatType entity class.
      */
      export class ReceiptOutputFormatTypeClass
        extends ExtensibleEnumerationClass
       implements Entities.ReceiptOutputFormatType {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.ReceiptOutputFormatType";
      
      }
      }
      entityDictionary['ReceiptOutputFormatTypeClass'] = ReceiptOutputFormatTypeClass;
    
      /**
      * OrderLookupAdditionalCriteriaType entity class.
      */
      export class OrderLookupAdditionalCriteriaTypeClass
        extends ExtensibleEnumerationClass
       implements Entities.OrderLookupAdditionalCriteriaType {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.OrderLookupAdditionalCriteriaType";
      
      }
      }
      entityDictionary['OrderLookupAdditionalCriteriaTypeClass'] = OrderLookupAdditionalCriteriaTypeClass;
    
      /**
      * Address entity class.
      */
      export class AddressClass implements Entities.Address {
      public Name: string;
      public Id: string;
      public FullAddress: string;
      public RecordId: number;
      public Street: string;
      public StreetNumber: string;
      public County: string;
      public CountyName: string;
      public City: string;
      public DistrictName: string;
      public State: string;
      public StateName: string;
      public ZipCode: string;
      public ThreeLetterISORegionName: string;
      public Phone: string;
      public PhoneRecordId: number;
      public PhoneExt: string;
      public Email: string;
      public EmailContent: string;
      public EmailRecordId: number;
      public Url: string;
      public UrlRecordId: number;
      public TwoLetterISORegionName: string;
      public Deactivate: boolean;
      public AttentionTo: string;
      public BuildingCompliment: string;
      public Postbox: string;
      public TaxGroup: string;
      public AddressTypeValue: number;
      public IsPrimary: boolean;
      public IsPrivate: boolean;
      public PartyNumber: string;
      public IsAsyncAddress: boolean;
      public DirectoryPartyTableRecordId: number;
      public DirectoryPartyLocationRecordId: number;
      public DirectoryPartyLocationRoleRecordId: number;
      public LogisticsLocationId: string;
      public LogisticsLocationRecordId: number;
      public LogisticsLocationExtRecordId: number;
      public LogisticsLocationRoleRecordId: number;
      public PhoneLogisticsLocationRecordId: number;
      public PhoneLogisticsLocationId: string;
      public EmailLogisticsLocationRecordId: number;
      public EmailLogisticsLocationId: string;
      public UrlLogisticsLocationRecordId: number;
      public UrlLogisticsLocationId: string;
      public ExpireRecordId: number;
      public SortOrder: number;
      public RoleCount: number;
      public DataAreaId: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Name = 
                odataObject.Name;
              
            this.Id = 
                odataObject.Id;
              
            this.FullAddress = 
                odataObject.FullAddress;
              
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.Street = 
                odataObject.Street;
              
            this.StreetNumber = 
                odataObject.StreetNumber;
              
            this.County = 
                odataObject.County;
              
            this.CountyName = 
                odataObject.CountyName;
              
            this.City = 
                odataObject.City;
              
            this.DistrictName = 
                odataObject.DistrictName;
              
            this.State = 
                odataObject.State;
              
            this.StateName = 
                odataObject.StateName;
              
            this.ZipCode = 
                odataObject.ZipCode;
              
            this.ThreeLetterISORegionName = 
                odataObject.ThreeLetterISORegionName;
              
            this.Phone = 
                odataObject.Phone;
              
            this.PhoneRecordId = 
                (odataObject.PhoneRecordId != null) ? parseInt(odataObject.PhoneRecordId, 10) : undefined;
              
            this.PhoneExt = 
                odataObject.PhoneExt;
              
            this.Email = 
                odataObject.Email;
              
            this.EmailContent = 
                odataObject.EmailContent;
              
            this.EmailRecordId = 
                (odataObject.EmailRecordId != null) ? parseInt(odataObject.EmailRecordId, 10) : undefined;
              
            this.Url = 
                odataObject.Url;
              
            this.UrlRecordId = 
                (odataObject.UrlRecordId != null) ? parseInt(odataObject.UrlRecordId, 10) : undefined;
              
            this.TwoLetterISORegionName = 
                odataObject.TwoLetterISORegionName;
              
            this.Deactivate = 
                odataObject.Deactivate;
              
            this.AttentionTo = 
                odataObject.AttentionTo;
              
            this.BuildingCompliment = 
                odataObject.BuildingCompliment;
              
            this.Postbox = 
                odataObject.Postbox;
              
            this.TaxGroup = 
                odataObject.TaxGroup;
              
            this.AddressTypeValue = 
                odataObject.AddressTypeValue;
              
            this.IsPrimary = 
                odataObject.IsPrimary;
              
            this.IsPrivate = 
                odataObject.IsPrivate;
              
            this.PartyNumber = 
                odataObject.PartyNumber;
              
            this.IsAsyncAddress = 
                odataObject.IsAsyncAddress;
              
            this.DirectoryPartyTableRecordId = 
                (odataObject.DirectoryPartyTableRecordId != null) ? parseInt(odataObject.DirectoryPartyTableRecordId, 10) : undefined;
              
            this.DirectoryPartyLocationRecordId = 
                (odataObject.DirectoryPartyLocationRecordId != null) ? parseInt(odataObject.DirectoryPartyLocationRecordId, 10) : undefined;
              
            this.DirectoryPartyLocationRoleRecordId = 
                (odataObject.DirectoryPartyLocationRoleRecordId != null) ? parseInt(odataObject.DirectoryPartyLocationRoleRecordId, 10) : undefined;
              
            this.LogisticsLocationId = 
                odataObject.LogisticsLocationId;
              
            this.LogisticsLocationRecordId = 
                (odataObject.LogisticsLocationRecordId != null) ? parseInt(odataObject.LogisticsLocationRecordId, 10) : undefined;
              
            this.LogisticsLocationExtRecordId = 
                (odataObject.LogisticsLocationExtRecordId != null) ? parseInt(odataObject.LogisticsLocationExtRecordId, 10) : undefined;
              
            this.LogisticsLocationRoleRecordId = 
                (odataObject.LogisticsLocationRoleRecordId != null) ? parseInt(odataObject.LogisticsLocationRoleRecordId, 10) : undefined;
              
            this.PhoneLogisticsLocationRecordId = 
                (odataObject.PhoneLogisticsLocationRecordId != null) ? parseInt(odataObject.PhoneLogisticsLocationRecordId, 10) : undefined;
              
            this.PhoneLogisticsLocationId = 
                odataObject.PhoneLogisticsLocationId;
              
            this.EmailLogisticsLocationRecordId = 
                (odataObject.EmailLogisticsLocationRecordId != null) ? parseInt(odataObject.EmailLogisticsLocationRecordId, 10) : undefined;
              
            this.EmailLogisticsLocationId = 
                odataObject.EmailLogisticsLocationId;
              
            this.UrlLogisticsLocationRecordId = 
                (odataObject.UrlLogisticsLocationRecordId != null) ? parseInt(odataObject.UrlLogisticsLocationRecordId, 10) : undefined;
              
            this.UrlLogisticsLocationId = 
                odataObject.UrlLogisticsLocationId;
              
            this.ExpireRecordId = 
                (odataObject.ExpireRecordId != null) ? parseInt(odataObject.ExpireRecordId, 10) : undefined;
              
            this.SortOrder = 
                odataObject.SortOrder;
              
            this.RoleCount = 
                odataObject.RoleCount;
              
            this.DataAreaId = 
                odataObject.DataAreaId;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['AddressClass'] = AddressClass;
    
      /**
      * CustomerAffiliation entity class.
      */
      export class CustomerAffiliationClass implements Entities.CustomerAffiliation {
      public RecordId: number;
      public RetailAffiliationId: number;
      public Name: string;
      public Description: string;
      public HideInChannels: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.RetailAffiliationId = 
                (odataObject.RetailAffiliationId != null) ? parseInt(odataObject.RetailAffiliationId, 10) : undefined;
              
            this.Name = 
                odataObject.Name;
              
            this.Description = 
                odataObject.Description;
              
            this.HideInChannels = 
                odataObject.HideInChannels;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CustomerAffiliationClass'] = CustomerAffiliationClass;
    
      /**
      * CustomerBalances entity class.
      */
      export class CustomerBalancesClass implements Entities.CustomerBalances {
      public Balance: number;
      public PendingBalance: number;
      public CreditLimit: number;
      public InvoiceAccountBalance: number;
      public InvoiceAccountPendingBalance: number;
      public InvoiceAccountCreditLimit: number;
      public OpenInvoiceBalance: number;
      public OpenInvoiceCount: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Balance = 
                (odataObject.Balance != null) ? parseFloat(odataObject.Balance) : undefined;
              
            this.PendingBalance = 
                (odataObject.PendingBalance != null) ? parseFloat(odataObject.PendingBalance) : undefined;
              
            this.CreditLimit = 
                (odataObject.CreditLimit != null) ? parseFloat(odataObject.CreditLimit) : undefined;
              
            this.InvoiceAccountBalance = 
                (odataObject.InvoiceAccountBalance != null) ? parseFloat(odataObject.InvoiceAccountBalance) : undefined;
              
            this.InvoiceAccountPendingBalance = 
                (odataObject.InvoiceAccountPendingBalance != null) ? parseFloat(odataObject.InvoiceAccountPendingBalance) : undefined;
              
            this.InvoiceAccountCreditLimit = 
                (odataObject.InvoiceAccountCreditLimit != null) ? parseFloat(odataObject.InvoiceAccountCreditLimit) : undefined;
              
            this.OpenInvoiceBalance = 
                (odataObject.OpenInvoiceBalance != null) ? parseFloat(odataObject.OpenInvoiceBalance) : undefined;
              
            this.OpenInvoiceCount = 
                odataObject.OpenInvoiceCount;
              
      }
      }
      entityDictionary['CustomerBalancesClass'] = CustomerBalancesClass;
    
      /**
      * GlobalCustomer entity class.
      */
      export class GlobalCustomerClass implements Entities.GlobalCustomer {
      public PartyNumber: string;
      public RecordId: number;
      public IsAsyncCustomer: boolean;
      public AccountNumber: string;
      public FullName: string;
      public FullAddress: string;
      public Phone: string;
      public Email: string;
      public CustomerTypeValue: number;
      public Images: Entities.MediaLocation[];
      public OfflineImage: string;
      public IsB2b: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.PartyNumber = 
                odataObject.PartyNumber;
              
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.IsAsyncCustomer = 
                odataObject.IsAsyncCustomer;
              
            this.AccountNumber = 
                odataObject.AccountNumber;
              
            this.FullName = 
                odataObject.FullName;
              
            this.FullAddress = 
                odataObject.FullAddress;
              
            this.Phone = 
                odataObject.Phone;
              
            this.Email = 
                odataObject.Email;
              
            this.CustomerTypeValue = 
                odataObject.CustomerTypeValue;
              
        this.Images = undefined;
        if (odataObject.Images) {
        this.Images = [];
        for (var i = 0; i < odataObject.Images.length; i++) {
        if (odataObject.Images[i] != null) {
        if (odataObject.Images[i]['@odata.type'] != null) {
          var className: string = odataObject.Images[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Images[i] = new entityDictionary[className](odataObject.Images[i])
          }
        } else {
            this.Images[i] = new MediaLocationClass(odataObject.Images[i]);
        }
                    } else {
        this.Images[i] = undefined;
        }
        }
        }
      
            this.OfflineImage = 
                odataObject.OfflineImage;
              
            this.IsB2b = 
                odataObject.IsB2b;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['GlobalCustomerClass'] = GlobalCustomerClass;
    
      /**
      * AttributeValue entity class.
      */
      export class AttributeValueClass implements Entities.AttributeValue {
      public Name: string;
      public KeyName: string;
      public UnitOfMeasureSymbol: string;
      public DataTypeValue: number;
      public BooleanValue: boolean;
      public DateTimeOffsetValue: Date;
      public FloatValue: number;
      public IntegerValue: number;
      public TextValue: string;
      public CurrencyValue: number;
      public CurrencyCode: string;
      public AttributeValueId: number;
      public RecordId: number;
      public Swatches: Entities.AttributeSwatch[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Name = 
                odataObject.Name;
              
            this.KeyName = 
                odataObject.KeyName;
              
            this.UnitOfMeasureSymbol = 
                odataObject.UnitOfMeasureSymbol;
              
            this.DataTypeValue = 
                odataObject.DataTypeValue;
              
            this.BooleanValue = 
                odataObject.BooleanValue;
              
            this.DateTimeOffsetValue = 
                (odataObject.DateTimeOffsetValue instanceof Date) ? odataObject.DateTimeOffsetValue
                : (odataObject.DateTimeOffsetValue != null) ? jsonLightReadStringPropertyValue(odataObject.DateTimeOffsetValue, "Edm.DateTimeOffset", false) : undefined;
              
            this.FloatValue = 
                (odataObject.FloatValue != null) ? parseFloat(odataObject.FloatValue) : undefined;
              
            this.IntegerValue = 
                odataObject.IntegerValue;
              
            this.TextValue = 
                odataObject.TextValue;
              
            this.CurrencyValue = 
                (odataObject.CurrencyValue != null) ? parseFloat(odataObject.CurrencyValue) : undefined;
              
            this.CurrencyCode = 
                odataObject.CurrencyCode;
              
            this.AttributeValueId = 
                (odataObject.AttributeValueId != null) ? parseInt(odataObject.AttributeValueId, 10) : undefined;
              
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
        this.Swatches = undefined;
        if (odataObject.Swatches) {
        this.Swatches = [];
        for (var i = 0; i < odataObject.Swatches.length; i++) {
        if (odataObject.Swatches[i] != null) {
        if (odataObject.Swatches[i]['@odata.type'] != null) {
          var className: string = odataObject.Swatches[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Swatches[i] = new entityDictionary[className](odataObject.Swatches[i])
          }
        } else {
            this.Swatches[i] = new AttributeSwatchClass(odataObject.Swatches[i]);
        }
                    } else {
        this.Swatches[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['AttributeValueClass'] = AttributeValueClass;
    
      /**
      * AttributeValueBase entity class.
      */
      export class AttributeValueBaseClass implements Entities.AttributeValueBase {
      public Name: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Name = 
                odataObject.Name;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['AttributeValueBaseClass'] = AttributeValueBaseClass;
    
      /**
      * Barcode entity class.
      */
      export class BarcodeClass implements Entities.Barcode {
      public BarcodeId: string;
      public BarcodePrice: number;
      public Quantity: number;
      public CustomerId: string;
      public EmployeeId: string;
      public SalespersonId: string;
      public EANLicenseId: string;
      public DataEntry: string;
      public DiscountCode: string;
      public GiftCardNumber: string;
      public LoyaltyCardNumber: string;
      public ItemBarcode: Entities.ItemBarcode;
      public EntryMethodTypeValue: number;
      public ItemType: number;
      public EnterDimensions: boolean;
      public ItemGroupId: string;
      public ItemDepartmentId: string;
      public ScaleItem: boolean;
      public ZeroPriceValid: boolean;
      public NegativeQuantity: boolean;
      public DiscountNotAllowed: boolean;
      public DateToBeBlocked: Date;
      public DateToBeActivated: Date;
      public CheckDigitValidated: boolean;
      public CouponId: string;
      public Message: string;
      public LineDiscountGroup: string;
      public MultilineDiscountGroup: string;
      public IncludedInTotalDiscount: boolean;
      public CostPrice: number;
      public BatchId: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.BarcodeId = 
                odataObject.BarcodeId;
              
            this.BarcodePrice = 
                (odataObject.BarcodePrice != null) ? parseFloat(odataObject.BarcodePrice) : undefined;
              
            this.Quantity = 
                (odataObject.Quantity != null) ? parseFloat(odataObject.Quantity) : undefined;
              
            this.CustomerId = 
                odataObject.CustomerId;
              
            this.EmployeeId = 
                odataObject.EmployeeId;
              
            this.SalespersonId = 
                odataObject.SalespersonId;
              
            this.EANLicenseId = 
                odataObject.EANLicenseId;
              
            this.DataEntry = 
                odataObject.DataEntry;
              
            this.DiscountCode = 
                odataObject.DiscountCode;
              
            this.GiftCardNumber = 
                odataObject.GiftCardNumber;
              
            this.LoyaltyCardNumber = 
                odataObject.LoyaltyCardNumber;
              
        if (odataObject.ItemBarcode == null) {
            this.ItemBarcode = undefined;
        } else if (odataObject.ItemBarcode['@odata.type'] == null) {
            this.ItemBarcode = new ItemBarcodeClass(odataObject.ItemBarcode);
        } else {
            var className: string = odataObject.ItemBarcode['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.ItemBarcode = new entityDictionary[className](odataObject.ItemBarcode)
            }
        }

      
            this.EntryMethodTypeValue = 
                odataObject.EntryMethodTypeValue;
              
            this.ItemType = 
                odataObject.ItemType;
              
            this.EnterDimensions = 
                odataObject.EnterDimensions;
              
            this.ItemGroupId = 
                odataObject.ItemGroupId;
              
            this.ItemDepartmentId = 
                odataObject.ItemDepartmentId;
              
            this.ScaleItem = 
                odataObject.ScaleItem;
              
            this.ZeroPriceValid = 
                odataObject.ZeroPriceValid;
              
            this.NegativeQuantity = 
                odataObject.NegativeQuantity;
              
            this.DiscountNotAllowed = 
                odataObject.DiscountNotAllowed;
              
            this.DateToBeBlocked = 
                (odataObject.DateToBeBlocked instanceof Date) ? odataObject.DateToBeBlocked
                : (odataObject.DateToBeBlocked != null) ? jsonLightReadStringPropertyValue(odataObject.DateToBeBlocked, "Edm.DateTimeOffset", false) : undefined;
              
            this.DateToBeActivated = 
                (odataObject.DateToBeActivated instanceof Date) ? odataObject.DateToBeActivated
                : (odataObject.DateToBeActivated != null) ? jsonLightReadStringPropertyValue(odataObject.DateToBeActivated, "Edm.DateTimeOffset", false) : undefined;
              
            this.CheckDigitValidated = 
                odataObject.CheckDigitValidated;
              
            this.CouponId = 
                odataObject.CouponId;
              
            this.Message = 
                odataObject.Message;
              
            this.LineDiscountGroup = 
                odataObject.LineDiscountGroup;
              
            this.MultilineDiscountGroup = 
                odataObject.MultilineDiscountGroup;
              
            this.IncludedInTotalDiscount = 
                odataObject.IncludedInTotalDiscount;
              
            this.CostPrice = 
                (odataObject.CostPrice != null) ? parseFloat(odataObject.CostPrice) : undefined;
              
            this.BatchId = 
                odataObject.BatchId;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['BarcodeClass'] = BarcodeClass;
    
      /**
      * ItemBarcode entity class.
      */
      export class ItemBarcodeClass implements Entities.ItemBarcode {
      public RecordId: number;
      public ItemId: string;
      public Description: string;
      public BarcodeSetupId: string;
      public ItemBarcodeValue: string;
      public InventoryDimensionId: string;
      public IsBlocked: boolean;
      public Display: boolean;
      public VariantId: string;
      public UnitId: string;
      public UseForInput: boolean;
      public UseForPrinting: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.ItemId = 
                odataObject.ItemId;
              
            this.Description = 
                odataObject.Description;
              
            this.BarcodeSetupId = 
                odataObject.BarcodeSetupId;
              
            this.ItemBarcodeValue = 
                odataObject.ItemBarcodeValue;
              
            this.InventoryDimensionId = 
                odataObject.InventoryDimensionId;
              
            this.IsBlocked = 
                odataObject.IsBlocked;
              
            this.Display = 
                odataObject.Display;
              
            this.VariantId = 
                odataObject.VariantId;
              
            this.UnitId = 
                odataObject.UnitId;
              
            this.UseForInput = 
                odataObject.UseForInput;
              
            this.UseForPrinting = 
                odataObject.UseForPrinting;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ItemBarcodeClass'] = ItemBarcodeClass;
    
      /**
      * ButtonGrid entity class.
      */
      export class ButtonGridClass implements Entities.ButtonGrid {
      public Id: string;
      public Name: string;
      public DefaultColor: number;
      public SpaceBetweenButtonsInPixels: number;
      public Buttons: Entities.ButtonGridButton[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Id = 
                odataObject.Id;
              
            this.Name = 
                odataObject.Name;
              
            this.DefaultColor = 
                odataObject.DefaultColor;
              
            this.SpaceBetweenButtonsInPixels = 
                odataObject.SpaceBetweenButtonsInPixels;
              
        this.Buttons = undefined;
        if (odataObject.Buttons) {
        this.Buttons = [];
        for (var i = 0; i < odataObject.Buttons.length; i++) {
        if (odataObject.Buttons[i] != null) {
        if (odataObject.Buttons[i]['@odata.type'] != null) {
          var className: string = odataObject.Buttons[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Buttons[i] = new entityDictionary[className](odataObject.Buttons[i])
          }
        } else {
            this.Buttons[i] = new ButtonGridButtonClass(odataObject.Buttons[i]);
        }
                    } else {
        this.Buttons[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ButtonGridClass'] = ButtonGridClass;
    
      /**
      * GetButtonGridsByIdsCriteria entity class.
      */
      export class GetButtonGridsByIdsCriteriaClass implements Entities.GetButtonGridsByIdsCriteria {
      public ButtonGridIds: string[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.ButtonGridIds = undefined;
        if (odataObject.ButtonGridIds) {
        this.ButtonGridIds = [];
        for (var i = 0; i < odataObject.ButtonGridIds.length; i++) {
        if (odataObject.ButtonGridIds[i] != null) {
            this.ButtonGridIds[i] = 
                odataObject.ButtonGridIds[i];
                            } else {
        this.ButtonGridIds[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['GetButtonGridsByIdsCriteriaClass'] = GetButtonGridsByIdsCriteriaClass;
    
      /**
      * CartLine entity class.
      */
      export class CartLineClass implements Entities.CartLine {
      public LineId: string;
      public TaxOverrideCode: string;
      public ItemId: string;
      public Barcode: string;
      public EntryMethodTypeValue: number;
      public Description: string;
      public InventoryDimensionId: string;
      public Comment: string;
      public ProductId: number;
      public WarehouseId: string;
      public Quantity: number;
      public Price: number;
      public ExtendedPrice: number;
      public TaxAmount: number;
      public SalesTaxGroupId: string;
      public ItemTaxGroupId: string;
      public TotalAmount: number;
      public NetAmountWithoutTax: number;
      public NetPrice: number;
      public DiscountAmountWithoutTax: number;
      public DiscountAmount: number;
      public LineDiscount: number;
      public LinePercentageDiscount: number;
      public LineManualDiscountPercentage: number;
      public LineManualDiscountAmount: number;
      public UnitOfMeasureSymbol: string;
      public ShippingAddress: Entities.Address;
      public DeliveryMode: string;
      public DeliveryModeChargeAmount: number;
      public RequestedDeliveryDate: Date;
      public PickupTimeslotStartDateTime: Date;
      public PickupTimeslotEndDateTime: Date;
      public IsWarrantyLine: boolean;
      public WarrantableTransactionId: string;
      public WarrantableSalesId: string;
      public WarrantableLineNumber: number;
      public WarrantableSerialNumber: string;
      public WarrantiedId: string;
      public ReturnTransactionId: string;
      public ReturnLineNumber: number;
      public ReturnInventTransId: string;
      public ReturnStore: string;
      public ReturnTerminalId: string;
      public IsVoided: boolean;
      public IsTaxOverideCodeTaxExempt: boolean;
      public IsGiftCardLine: boolean;
      public IsPriceKeyedIn: boolean;
      public GiftCardId: string;
      public GiftCardCurrencyCode: string;
      public GiftCardOperationValue: number;
      public GiftCardTypeValue: number;
      public SalesStatusValue: number;
      public QuantityOrdered: number;
      public QuantityNotProcessed: number;
      public QuantityReserved: number;
      public QuantityPicked: number;
      public QuantityPacked: number;
      public QuantityInvoiced: number;
      public QuantityCanceled: number;
      public FulfillmentStoreId: string;
      public SerialNumber: string;
      public ElectronicDeliveryEmail: string;
      public ElectronicDeliveryEmailContent: string;
      public IsPriceOverridden: boolean;
      public OriginalPrice: number;
      public IsInvoiceLine: boolean;
      public InvoiceId: string;
      public InvoiceAmount: number;
      public GiftCardBalance: number;
      public LineVersion: number;
      public PromotionLines: string[];
      public PriceLines: Entities.PriceLine[];
      public DiscountLines: Entities.DiscountLine[];
      public RelatedDiscountedLineIds: string[];
      public ReasonCodeLines: Entities.ReasonCodeLine[];
      public ChargeLines: Entities.ChargeLine[];
      public TaxLines: Entities.TaxLine[];
      public ReturnTaxLines: Entities.TaxLine[];
      public TaxRatePercent: number;
      public IsCustomerAccountDeposit: boolean;
      public LinkedParentLineId: string;
      public QuantityReturnable: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      public LineNumber: number;
      public CommissionSalesGroup: string;
      public TrackingId: string;
      public StaffId: string;
      public QuantityRemained: number;
      public QuantityShipped: number;
      public CatalogId: number;
      public AttributeValues: Entities.AttributeValueBase[];
      public ThirdPartyGiftCardInfo: Entities.ThirdPartyGiftCardInfo;
      public BarcodeEmbeddedPrice: number;
      public PriceInBarcode: boolean;
      public InvoiceTypeValue: number;
      public InvoicedSalesId: string;
      public WarehouseLocation: string;
      public DetailedLineStatusValue: number;
      public SalesAgreementLineRecordId: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.LineId = 
                odataObject.LineId;
              
            this.TaxOverrideCode = 
                odataObject.TaxOverrideCode;
              
            this.ItemId = 
                odataObject.ItemId;
              
            this.Barcode = 
                odataObject.Barcode;
              
            this.EntryMethodTypeValue = 
                odataObject.EntryMethodTypeValue;
              
            this.Description = 
                odataObject.Description;
              
            this.InventoryDimensionId = 
                odataObject.InventoryDimensionId;
              
            this.Comment = 
                odataObject.Comment;
              
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
            this.WarehouseId = 
                odataObject.WarehouseId;
              
            this.Quantity = 
                (odataObject.Quantity != null) ? parseFloat(odataObject.Quantity) : undefined;
              
            this.Price = 
                (odataObject.Price != null) ? parseFloat(odataObject.Price) : undefined;
              
            this.ExtendedPrice = 
                (odataObject.ExtendedPrice != null) ? parseFloat(odataObject.ExtendedPrice) : undefined;
              
            this.TaxAmount = 
                (odataObject.TaxAmount != null) ? parseFloat(odataObject.TaxAmount) : undefined;
              
            this.SalesTaxGroupId = 
                odataObject.SalesTaxGroupId;
              
            this.ItemTaxGroupId = 
                odataObject.ItemTaxGroupId;
              
            this.TotalAmount = 
                (odataObject.TotalAmount != null) ? parseFloat(odataObject.TotalAmount) : undefined;
              
            this.NetAmountWithoutTax = 
                (odataObject.NetAmountWithoutTax != null) ? parseFloat(odataObject.NetAmountWithoutTax) : undefined;
              
            this.NetPrice = 
                (odataObject.NetPrice != null) ? parseFloat(odataObject.NetPrice) : undefined;
              
            this.DiscountAmountWithoutTax = 
                (odataObject.DiscountAmountWithoutTax != null) ? parseFloat(odataObject.DiscountAmountWithoutTax) : undefined;
              
            this.DiscountAmount = 
                (odataObject.DiscountAmount != null) ? parseFloat(odataObject.DiscountAmount) : undefined;
              
            this.LineDiscount = 
                (odataObject.LineDiscount != null) ? parseFloat(odataObject.LineDiscount) : undefined;
              
            this.LinePercentageDiscount = 
                (odataObject.LinePercentageDiscount != null) ? parseFloat(odataObject.LinePercentageDiscount) : undefined;
              
            this.LineManualDiscountPercentage = 
                (odataObject.LineManualDiscountPercentage != null) ? parseFloat(odataObject.LineManualDiscountPercentage) : undefined;
              
            this.LineManualDiscountAmount = 
                (odataObject.LineManualDiscountAmount != null) ? parseFloat(odataObject.LineManualDiscountAmount) : undefined;
              
            this.UnitOfMeasureSymbol = 
                odataObject.UnitOfMeasureSymbol;
              
        if (odataObject.ShippingAddress == null) {
            this.ShippingAddress = undefined;
        } else if (odataObject.ShippingAddress['@odata.type'] == null) {
            this.ShippingAddress = new AddressClass(odataObject.ShippingAddress);
        } else {
            var className: string = odataObject.ShippingAddress['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.ShippingAddress = new entityDictionary[className](odataObject.ShippingAddress)
            }
        }

      
            this.DeliveryMode = 
                odataObject.DeliveryMode;
              
            this.DeliveryModeChargeAmount = 
                (odataObject.DeliveryModeChargeAmount != null) ? parseFloat(odataObject.DeliveryModeChargeAmount) : undefined;
              
            this.RequestedDeliveryDate = 
                (odataObject.RequestedDeliveryDate instanceof Date) ? odataObject.RequestedDeliveryDate
                : (odataObject.RequestedDeliveryDate != null) ? jsonLightReadStringPropertyValue(odataObject.RequestedDeliveryDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.PickupTimeslotStartDateTime = 
                (odataObject.PickupTimeslotStartDateTime instanceof Date) ? odataObject.PickupTimeslotStartDateTime
                : (odataObject.PickupTimeslotStartDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.PickupTimeslotStartDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.PickupTimeslotEndDateTime = 
                (odataObject.PickupTimeslotEndDateTime instanceof Date) ? odataObject.PickupTimeslotEndDateTime
                : (odataObject.PickupTimeslotEndDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.PickupTimeslotEndDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.IsWarrantyLine = 
                odataObject.IsWarrantyLine;
              
            this.WarrantableTransactionId = 
                odataObject.WarrantableTransactionId;
              
            this.WarrantableSalesId = 
                odataObject.WarrantableSalesId;
              
            this.WarrantableLineNumber = 
                (odataObject.WarrantableLineNumber != null) ? parseFloat(odataObject.WarrantableLineNumber) : undefined;
              
            this.WarrantableSerialNumber = 
                odataObject.WarrantableSerialNumber;
              
            this.WarrantiedId = 
                odataObject.WarrantiedId;
              
            this.ReturnTransactionId = 
                odataObject.ReturnTransactionId;
              
            this.ReturnLineNumber = 
                (odataObject.ReturnLineNumber != null) ? parseFloat(odataObject.ReturnLineNumber) : undefined;
              
            this.ReturnInventTransId = 
                odataObject.ReturnInventTransId;
              
            this.ReturnStore = 
                odataObject.ReturnStore;
              
            this.ReturnTerminalId = 
                odataObject.ReturnTerminalId;
              
            this.IsVoided = 
                odataObject.IsVoided;
              
            this.IsTaxOverideCodeTaxExempt = 
                odataObject.IsTaxOverideCodeTaxExempt;
              
            this.IsGiftCardLine = 
                odataObject.IsGiftCardLine;
              
            this.IsPriceKeyedIn = 
                odataObject.IsPriceKeyedIn;
              
            this.GiftCardId = 
                odataObject.GiftCardId;
              
            this.GiftCardCurrencyCode = 
                odataObject.GiftCardCurrencyCode;
              
            this.GiftCardOperationValue = 
                odataObject.GiftCardOperationValue;
              
            this.GiftCardTypeValue = 
                odataObject.GiftCardTypeValue;
              
            this.SalesStatusValue = 
                odataObject.SalesStatusValue;
              
            this.QuantityOrdered = 
                (odataObject.QuantityOrdered != null) ? parseFloat(odataObject.QuantityOrdered) : undefined;
              
            this.QuantityNotProcessed = 
                (odataObject.QuantityNotProcessed != null) ? parseFloat(odataObject.QuantityNotProcessed) : undefined;
              
            this.QuantityReserved = 
                (odataObject.QuantityReserved != null) ? parseFloat(odataObject.QuantityReserved) : undefined;
              
            this.QuantityPicked = 
                (odataObject.QuantityPicked != null) ? parseFloat(odataObject.QuantityPicked) : undefined;
              
            this.QuantityPacked = 
                (odataObject.QuantityPacked != null) ? parseFloat(odataObject.QuantityPacked) : undefined;
              
            this.QuantityInvoiced = 
                (odataObject.QuantityInvoiced != null) ? parseFloat(odataObject.QuantityInvoiced) : undefined;
              
            this.QuantityCanceled = 
                (odataObject.QuantityCanceled != null) ? parseFloat(odataObject.QuantityCanceled) : undefined;
              
            this.FulfillmentStoreId = 
                odataObject.FulfillmentStoreId;
              
            this.SerialNumber = 
                odataObject.SerialNumber;
              
            this.ElectronicDeliveryEmail = 
                odataObject.ElectronicDeliveryEmail;
              
            this.ElectronicDeliveryEmailContent = 
                odataObject.ElectronicDeliveryEmailContent;
              
            this.IsPriceOverridden = 
                odataObject.IsPriceOverridden;
              
            this.OriginalPrice = 
                (odataObject.OriginalPrice != null) ? parseFloat(odataObject.OriginalPrice) : undefined;
              
            this.IsInvoiceLine = 
                odataObject.IsInvoiceLine;
              
            this.InvoiceId = 
                odataObject.InvoiceId;
              
            this.InvoiceAmount = 
                (odataObject.InvoiceAmount != null) ? parseFloat(odataObject.InvoiceAmount) : undefined;
              
            this.GiftCardBalance = 
                (odataObject.GiftCardBalance != null) ? parseFloat(odataObject.GiftCardBalance) : undefined;
              
            this.LineVersion = 
                odataObject.LineVersion;
              
        this.PromotionLines = undefined;
        if (odataObject.PromotionLines) {
        this.PromotionLines = [];
        for (var i = 0; i < odataObject.PromotionLines.length; i++) {
        if (odataObject.PromotionLines[i] != null) {
            this.PromotionLines[i] = 
                odataObject.PromotionLines[i];
                            } else {
        this.PromotionLines[i] = undefined;
        }
        }
        }
      
        this.PriceLines = undefined;
        if (odataObject.PriceLines) {
        this.PriceLines = [];
        for (var i = 0; i < odataObject.PriceLines.length; i++) {
        if (odataObject.PriceLines[i] != null) {
        if (odataObject.PriceLines[i]['@odata.type'] != null) {
          var className: string = odataObject.PriceLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.PriceLines[i] = new entityDictionary[className](odataObject.PriceLines[i])
          }
        } else {
            this.PriceLines[i] = new PriceLineClass(odataObject.PriceLines[i]);
        }
                    } else {
        this.PriceLines[i] = undefined;
        }
        }
        }
      
        this.DiscountLines = undefined;
        if (odataObject.DiscountLines) {
        this.DiscountLines = [];
        for (var i = 0; i < odataObject.DiscountLines.length; i++) {
        if (odataObject.DiscountLines[i] != null) {
        if (odataObject.DiscountLines[i]['@odata.type'] != null) {
          var className: string = odataObject.DiscountLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.DiscountLines[i] = new entityDictionary[className](odataObject.DiscountLines[i])
          }
        } else {
            this.DiscountLines[i] = new DiscountLineClass(odataObject.DiscountLines[i]);
        }
                    } else {
        this.DiscountLines[i] = undefined;
        }
        }
        }
      
        this.RelatedDiscountedLineIds = undefined;
        if (odataObject.RelatedDiscountedLineIds) {
        this.RelatedDiscountedLineIds = [];
        for (var i = 0; i < odataObject.RelatedDiscountedLineIds.length; i++) {
        if (odataObject.RelatedDiscountedLineIds[i] != null) {
            this.RelatedDiscountedLineIds[i] = 
                odataObject.RelatedDiscountedLineIds[i];
                            } else {
        this.RelatedDiscountedLineIds[i] = undefined;
        }
        }
        }
      
        this.ReasonCodeLines = undefined;
        if (odataObject.ReasonCodeLines) {
        this.ReasonCodeLines = [];
        for (var i = 0; i < odataObject.ReasonCodeLines.length; i++) {
        if (odataObject.ReasonCodeLines[i] != null) {
        if (odataObject.ReasonCodeLines[i]['@odata.type'] != null) {
          var className: string = odataObject.ReasonCodeLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ReasonCodeLines[i] = new entityDictionary[className](odataObject.ReasonCodeLines[i])
          }
        } else {
            this.ReasonCodeLines[i] = new ReasonCodeLineClass(odataObject.ReasonCodeLines[i]);
        }
                    } else {
        this.ReasonCodeLines[i] = undefined;
        }
        }
        }
      
        this.ChargeLines = undefined;
        if (odataObject.ChargeLines) {
        this.ChargeLines = [];
        for (var i = 0; i < odataObject.ChargeLines.length; i++) {
        if (odataObject.ChargeLines[i] != null) {
        if (odataObject.ChargeLines[i]['@odata.type'] != null) {
          var className: string = odataObject.ChargeLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ChargeLines[i] = new entityDictionary[className](odataObject.ChargeLines[i])
          }
        } else {
            this.ChargeLines[i] = new ChargeLineClass(odataObject.ChargeLines[i]);
        }
                    } else {
        this.ChargeLines[i] = undefined;
        }
        }
        }
      
        this.TaxLines = undefined;
        if (odataObject.TaxLines) {
        this.TaxLines = [];
        for (var i = 0; i < odataObject.TaxLines.length; i++) {
        if (odataObject.TaxLines[i] != null) {
        if (odataObject.TaxLines[i]['@odata.type'] != null) {
          var className: string = odataObject.TaxLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.TaxLines[i] = new entityDictionary[className](odataObject.TaxLines[i])
          }
        } else {
            this.TaxLines[i] = new TaxLineClass(odataObject.TaxLines[i]);
        }
                    } else {
        this.TaxLines[i] = undefined;
        }
        }
        }
      
        this.ReturnTaxLines = undefined;
        if (odataObject.ReturnTaxLines) {
        this.ReturnTaxLines = [];
        for (var i = 0; i < odataObject.ReturnTaxLines.length; i++) {
        if (odataObject.ReturnTaxLines[i] != null) {
        if (odataObject.ReturnTaxLines[i]['@odata.type'] != null) {
          var className: string = odataObject.ReturnTaxLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ReturnTaxLines[i] = new entityDictionary[className](odataObject.ReturnTaxLines[i])
          }
        } else {
            this.ReturnTaxLines[i] = new TaxLineClass(odataObject.ReturnTaxLines[i]);
        }
                    } else {
        this.ReturnTaxLines[i] = undefined;
        }
        }
        }
      
            this.TaxRatePercent = 
                (odataObject.TaxRatePercent != null) ? parseFloat(odataObject.TaxRatePercent) : undefined;
              
            this.IsCustomerAccountDeposit = 
                odataObject.IsCustomerAccountDeposit;
              
            this.LinkedParentLineId = 
                odataObject.LinkedParentLineId;
              
            this.QuantityReturnable = 
                (odataObject.QuantityReturnable != null) ? parseFloat(odataObject.QuantityReturnable) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
            this.LineNumber = 
                (odataObject.LineNumber != null) ? parseFloat(odataObject.LineNumber) : undefined;
              
            this.CommissionSalesGroup = 
                odataObject.CommissionSalesGroup;
              
            this.TrackingId = 
                odataObject.TrackingId;
              
            this.StaffId = 
                odataObject.StaffId;
              
            this.QuantityRemained = 
                (odataObject.QuantityRemained != null) ? parseFloat(odataObject.QuantityRemained) : undefined;
              
            this.QuantityShipped = 
                (odataObject.QuantityShipped != null) ? parseFloat(odataObject.QuantityShipped) : undefined;
              
            this.CatalogId = 
                (odataObject.CatalogId != null) ? parseInt(odataObject.CatalogId, 10) : undefined;
              
        this.AttributeValues = undefined;
        if (odataObject.AttributeValues) {
        this.AttributeValues = [];
        for (var i = 0; i < odataObject.AttributeValues.length; i++) {
        if (odataObject.AttributeValues[i] != null) {
        if (odataObject.AttributeValues[i]['@odata.type'] != null) {
          var className: string = odataObject.AttributeValues[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.AttributeValues[i] = new entityDictionary[className](odataObject.AttributeValues[i])
          }
        } else {
            this.AttributeValues[i] = new AttributeValueBaseClass(odataObject.AttributeValues[i]);
        }
                    } else {
        this.AttributeValues[i] = undefined;
        }
        }
        }
      
        if (odataObject.ThirdPartyGiftCardInfo == null) {
            this.ThirdPartyGiftCardInfo = undefined;
        } else if (odataObject.ThirdPartyGiftCardInfo['@odata.type'] == null) {
            this.ThirdPartyGiftCardInfo = new ThirdPartyGiftCardInfoClass(odataObject.ThirdPartyGiftCardInfo);
        } else {
            var className: string = odataObject.ThirdPartyGiftCardInfo['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.ThirdPartyGiftCardInfo = new entityDictionary[className](odataObject.ThirdPartyGiftCardInfo)
            }
        }

      
            this.BarcodeEmbeddedPrice = 
                (odataObject.BarcodeEmbeddedPrice != null) ? parseFloat(odataObject.BarcodeEmbeddedPrice) : undefined;
              
            this.PriceInBarcode = 
                odataObject.PriceInBarcode;
              
            this.InvoiceTypeValue = 
                odataObject.InvoiceTypeValue;
              
            this.InvoicedSalesId = 
                odataObject.InvoicedSalesId;
              
            this.WarehouseLocation = 
                odataObject.WarehouseLocation;
              
            this.DetailedLineStatusValue = 
                odataObject.DetailedLineStatusValue;
              
            this.SalesAgreementLineRecordId = 
                (odataObject.SalesAgreementLineRecordId != null) ? parseInt(odataObject.SalesAgreementLineRecordId, 10) : undefined;
              
      }
      }
      entityDictionary['CartLineClass'] = CartLineClass;
    
      /**
      * ThirdPartyGiftCardInfo entity class.
      */
      export class ThirdPartyGiftCardInfoClass implements Entities.ThirdPartyGiftCardInfo {
      public Amount: number;
      public Authorization: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Amount = 
                (odataObject.Amount != null) ? parseFloat(odataObject.Amount) : undefined;
              
            this.Authorization = 
                odataObject.Authorization;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ThirdPartyGiftCardInfoClass'] = ThirdPartyGiftCardInfoClass;
    
      /**
      * CardTypeInfo entity class.
      */
      export class CardTypeInfoClass implements Entities.CardTypeInfo {
      public RecordId: number;
      public TypeId: string;
      public Name: string;
      public PaymentMethodId: string;
      public CardTypeValue: number;
      public Issuer: string;
      public NumberFrom: string;
      public NumberTo: string;
      public CashBackLimit: number;
      public AllowManualInput: boolean;
      public CheckModulus: boolean;
      public HasTenderDiscount: boolean;
      public IsPinRequired: boolean;
      public IsExpirationDateRequired: boolean;
      public ConnectorName: string;
      public PaymentProcessorMethod: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.TypeId = 
                odataObject.TypeId;
              
            this.Name = 
                odataObject.Name;
              
            this.PaymentMethodId = 
                odataObject.PaymentMethodId;
              
            this.CardTypeValue = 
                odataObject.CardTypeValue;
              
            this.Issuer = 
                odataObject.Issuer;
              
            this.NumberFrom = 
                odataObject.NumberFrom;
              
            this.NumberTo = 
                odataObject.NumberTo;
              
            this.CashBackLimit = 
                (odataObject.CashBackLimit != null) ? parseFloat(odataObject.CashBackLimit) : undefined;
              
            this.AllowManualInput = 
                odataObject.AllowManualInput;
              
            this.CheckModulus = 
                odataObject.CheckModulus;
              
            this.HasTenderDiscount = 
                odataObject.HasTenderDiscount;
              
            this.IsPinRequired = 
                odataObject.IsPinRequired;
              
            this.IsExpirationDateRequired = 
                odataObject.IsExpirationDateRequired;
              
            this.ConnectorName = 
                odataObject.ConnectorName;
              
            this.PaymentProcessorMethod = 
                odataObject.PaymentProcessorMethod;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CardTypeInfoClass'] = CardTypeInfoClass;
    
      /**
      * CashDeclaration entity class.
      */
      export class CashDeclarationClass implements Entities.CashDeclaration {
      public RecordId: number;
      public Currency: string;
      public Amount: number;
      public CashTypeValue: number;
      public IncludeInQuickPayShortcuts: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.Currency = 
                odataObject.Currency;
              
            this.Amount = 
                (odataObject.Amount != null) ? parseFloat(odataObject.Amount) : undefined;
              
            this.CashTypeValue = 
                odataObject.CashTypeValue;
              
            this.IncludeInQuickPayShortcuts = 
                odataObject.IncludeInQuickPayShortcuts;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CashDeclarationClass'] = CashDeclarationClass;
    
      /**
      * IncomeExpenseAccount entity class.
      */
      export class IncomeExpenseAccountClass implements Entities.IncomeExpenseAccount {
      public Amount: number;
      public AccountNumber: string;
      public AccountName: string;
      public AccountNameAlias: string;
      public AccountTypeValue: number;
      public Quantity: number;
      public Message1Line: string;
      public Message2Line: string;
      public SlipText1Line: string;
      public SlipText2Line: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Amount = 
                (odataObject.Amount != null) ? parseFloat(odataObject.Amount) : undefined;
              
            this.AccountNumber = 
                odataObject.AccountNumber;
              
            this.AccountName = 
                odataObject.AccountName;
              
            this.AccountNameAlias = 
                odataObject.AccountNameAlias;
              
            this.AccountTypeValue = 
                odataObject.AccountTypeValue;
              
            this.Quantity = 
                (odataObject.Quantity != null) ? parseFloat(odataObject.Quantity) : undefined;
              
            this.Message1Line = 
                odataObject.Message1Line;
              
            this.Message2Line = 
                odataObject.Message2Line;
              
            this.SlipText1Line = 
                odataObject.SlipText1Line;
              
            this.SlipText2Line = 
                odataObject.SlipText2Line;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['IncomeExpenseAccountClass'] = IncomeExpenseAccountClass;
    
      /**
      * IncomeExpenseLine entity class.
      */
      export class IncomeExpenseLineClass implements Entities.IncomeExpenseLine {
      public Amount: number;
      public IncomeExpenseAccount: string;
      public StoreNumber: string;
      public AccountTypeValue: number;
      public Terminal: string;
      public Shift: string;
      public TransactionStatusValue: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Amount = 
                (odataObject.Amount != null) ? parseFloat(odataObject.Amount) : undefined;
              
            this.IncomeExpenseAccount = 
                odataObject.IncomeExpenseAccount;
              
            this.StoreNumber = 
                odataObject.StoreNumber;
              
            this.AccountTypeValue = 
                odataObject.AccountTypeValue;
              
            this.Terminal = 
                odataObject.Terminal;
              
            this.Shift = 
                odataObject.Shift;
              
            this.TransactionStatusValue = 
                odataObject.TransactionStatusValue;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['IncomeExpenseLineClass'] = IncomeExpenseLineClass;
    
      /**
      * CartTenderLine entity class.
      */
      export class CartTenderLineClass implements Entities.CartTenderLine {
      public PaymentCard: Entities.PaymentCard;
      public TokenizedPaymentCard: Entities.TokenizedPaymentCard;
      public CardPaymentAcceptResult: Entities.CardPaymentAcceptResult;
      public IsLinkedRefund: boolean;
      public LinkedPaymentStore: string;
      public LinkedPaymentTerminalId: string;
      public LinkedPaymentTransactionId: string;
      public LinkedPaymentLineNumber: number;
      public LinkedPaymentCurrency: string;
      public TenderLineId: string;
      public Amount: number;
      public CashBackAmount: number;
      public AmountInTenderedCurrency: number;
      public AmountInCompanyCurrency: number;
      public Currency: string;
      public ExchangeRate: number;
      public CompanyCurrencyExchangeRate: number;
      public TenderTypeId: string;
      public SignatureData: string;
      public ReasonCodeLines: Entities.ReasonCodeLine[];
      public LineNumber: number;
      public GiftCardId: string;
      public GiftCardPin: string;
      public GiftCardExpirationYear: number;
      public GiftCardExpirationMonth: number;
      public CreditMemoId: string;
      public CustomerId: string;
      public LoyaltyCardId: string;
      public CardTypeId: string;
      public IsChangeLine: boolean;
      public IsHistorical: boolean;
      public IsVoidable: boolean;
      public StatusValue: number;
      public VoidStatusValue: number;
      public AuthorizedAmount: number;
      public PaymentRefRecId: number;
      public CardPaymentAccountId: string;
      public ProcessingTypeValue: number;
      public CardProcessorStatusValue: number;
      public LinkedPaymentRefRecId: number;
      public IsPolicyBypassed: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        if (odataObject.PaymentCard == null) {
            this.PaymentCard = undefined;
        } else if (odataObject.PaymentCard['@odata.type'] == null) {
            this.PaymentCard = new PaymentCardClass(odataObject.PaymentCard);
        } else {
            var className: string = odataObject.PaymentCard['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.PaymentCard = new entityDictionary[className](odataObject.PaymentCard)
            }
        }

      
        if (odataObject.TokenizedPaymentCard == null) {
            this.TokenizedPaymentCard = undefined;
        } else if (odataObject.TokenizedPaymentCard['@odata.type'] == null) {
            this.TokenizedPaymentCard = new TokenizedPaymentCardClass(odataObject.TokenizedPaymentCard);
        } else {
            var className: string = odataObject.TokenizedPaymentCard['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.TokenizedPaymentCard = new entityDictionary[className](odataObject.TokenizedPaymentCard)
            }
        }

      
        if (odataObject.CardPaymentAcceptResult == null) {
            this.CardPaymentAcceptResult = undefined;
        } else if (odataObject.CardPaymentAcceptResult['@odata.type'] == null) {
            this.CardPaymentAcceptResult = new CardPaymentAcceptResultClass(odataObject.CardPaymentAcceptResult);
        } else {
            var className: string = odataObject.CardPaymentAcceptResult['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.CardPaymentAcceptResult = new entityDictionary[className](odataObject.CardPaymentAcceptResult)
            }
        }

      
            this.IsLinkedRefund = 
                odataObject.IsLinkedRefund;
              
            this.LinkedPaymentStore = 
                odataObject.LinkedPaymentStore;
              
            this.LinkedPaymentTerminalId = 
                odataObject.LinkedPaymentTerminalId;
              
            this.LinkedPaymentTransactionId = 
                odataObject.LinkedPaymentTransactionId;
              
            this.LinkedPaymentLineNumber = 
                (odataObject.LinkedPaymentLineNumber != null) ? parseFloat(odataObject.LinkedPaymentLineNumber) : undefined;
              
            this.LinkedPaymentCurrency = 
                odataObject.LinkedPaymentCurrency;
              
            this.TenderLineId = 
                odataObject.TenderLineId;
              
            this.Amount = 
                (odataObject.Amount != null) ? parseFloat(odataObject.Amount) : undefined;
              
            this.CashBackAmount = 
                (odataObject.CashBackAmount != null) ? parseFloat(odataObject.CashBackAmount) : undefined;
              
            this.AmountInTenderedCurrency = 
                (odataObject.AmountInTenderedCurrency != null) ? parseFloat(odataObject.AmountInTenderedCurrency) : undefined;
              
            this.AmountInCompanyCurrency = 
                (odataObject.AmountInCompanyCurrency != null) ? parseFloat(odataObject.AmountInCompanyCurrency) : undefined;
              
            this.Currency = 
                odataObject.Currency;
              
            this.ExchangeRate = 
                (odataObject.ExchangeRate != null) ? parseFloat(odataObject.ExchangeRate) : undefined;
              
            this.CompanyCurrencyExchangeRate = 
                (odataObject.CompanyCurrencyExchangeRate != null) ? parseFloat(odataObject.CompanyCurrencyExchangeRate) : undefined;
              
            this.TenderTypeId = 
                odataObject.TenderTypeId;
              
            this.SignatureData = 
                odataObject.SignatureData;
              
        this.ReasonCodeLines = undefined;
        if (odataObject.ReasonCodeLines) {
        this.ReasonCodeLines = [];
        for (var i = 0; i < odataObject.ReasonCodeLines.length; i++) {
        if (odataObject.ReasonCodeLines[i] != null) {
        if (odataObject.ReasonCodeLines[i]['@odata.type'] != null) {
          var className: string = odataObject.ReasonCodeLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ReasonCodeLines[i] = new entityDictionary[className](odataObject.ReasonCodeLines[i])
          }
        } else {
            this.ReasonCodeLines[i] = new ReasonCodeLineClass(odataObject.ReasonCodeLines[i]);
        }
                    } else {
        this.ReasonCodeLines[i] = undefined;
        }
        }
        }
      
            this.LineNumber = 
                (odataObject.LineNumber != null) ? parseFloat(odataObject.LineNumber) : undefined;
              
            this.GiftCardId = 
                odataObject.GiftCardId;
              
            this.GiftCardPin = 
                odataObject.GiftCardPin;
              
            this.GiftCardExpirationYear = 
                odataObject.GiftCardExpirationYear;
              
            this.GiftCardExpirationMonth = 
                odataObject.GiftCardExpirationMonth;
              
            this.CreditMemoId = 
                odataObject.CreditMemoId;
              
            this.CustomerId = 
                odataObject.CustomerId;
              
            this.LoyaltyCardId = 
                odataObject.LoyaltyCardId;
              
            this.CardTypeId = 
                odataObject.CardTypeId;
              
            this.IsChangeLine = 
                odataObject.IsChangeLine;
              
            this.IsHistorical = 
                odataObject.IsHistorical;
              
            this.IsVoidable = 
                odataObject.IsVoidable;
              
            this.StatusValue = 
                odataObject.StatusValue;
              
            this.VoidStatusValue = 
                odataObject.VoidStatusValue;
              
            this.AuthorizedAmount = 
                (odataObject.AuthorizedAmount != null) ? parseFloat(odataObject.AuthorizedAmount) : undefined;
              
            this.PaymentRefRecId = 
                (odataObject.PaymentRefRecId != null) ? parseInt(odataObject.PaymentRefRecId, 10) : undefined;
              
            this.CardPaymentAccountId = 
                odataObject.CardPaymentAccountId;
              
            this.ProcessingTypeValue = 
                odataObject.ProcessingTypeValue;
              
            this.CardProcessorStatusValue = 
                odataObject.CardProcessorStatusValue;
              
            this.LinkedPaymentRefRecId = 
                (odataObject.LinkedPaymentRefRecId != null) ? parseInt(odataObject.LinkedPaymentRefRecId, 10) : undefined;
              
            this.IsPolicyBypassed = 
                odataObject.IsPolicyBypassed;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CartTenderLineClass'] = CartTenderLineClass;
    
      /**
      * ChargeLine entity class.
      */
      export class ChargeLineClass implements Entities.ChargeLine {
      public ChargeLineId: string;
      public ChargeCode: string;
      public CurrencyCode: string;
      public ModuleTypeValue: number;
      public IsHeaderChargeProrated: boolean;
      public ChargeTypeValue: number;
      public ChargeMethodValue: number;
      public CalculatedAmount: number;
      public Value: number;
      public Description: string;
      public TransactionId: string;
      public MarkupAutoLineRecordId: number;
      public MarkupAutoTableRecId: number;
      public SaleLineNumber: number;
      public FromAmount: number;
      public ToAmount: number;
      public Keep: number;
      public AmountRefunded: number;
      public ChargeLineOverrides: Entities.ChargeLineOverride[];
      public ReasonCodeLines: Entities.ReasonCodeLine[];
      public IsRefundable: boolean;
      public IsShipping: boolean;
      public IsOverridden: boolean;
      public IsInvoiced: boolean;
      public CalculatedProratedAmount: number;
      public SpecificUnitSymbol: string;
      public ItemId: string;
      public Quantity: number;
      public Price: number;
      public ItemTaxGroupId: string;
      public SalesTaxGroupId: string;
      public OriginalSalesTaxGroupId: string;
      public OriginalItemTaxGroupId: string;
      public TaxAmount: number;
      public SalesOrderUnitOfMeasure: string;
      public NetAmount: number;
      public NetAmountPerUnit: number;
      public GrossAmount: number;
      public TaxLines: Entities.TaxLine[];
      public TaxMeasures: Entities.TaxMeasure[];
      public TaxAmountExemptInclusive: number;
      public TaxAmountInclusive: number;
      public TaxAmountExclusive: number;
      public NetAmountWithAllInclusiveTax: number;
      public BeginDateTime: Date;
      public EndDateTime: Date;
      public TaxRatePercent: number;
      public IsReturnByReceipt: boolean;
      public ReturnLineTaxAmount: number;
      public ReturnTaxLines: Entities.TaxLine[];
      public TaxExemptPriceInclusiveReductionAmount: number;
      public TaxExemptPriceInclusiveOriginalPrice: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ChargeLineId = 
                odataObject.ChargeLineId;
              
            this.ChargeCode = 
                odataObject.ChargeCode;
              
            this.CurrencyCode = 
                odataObject.CurrencyCode;
              
            this.ModuleTypeValue = 
                odataObject.ModuleTypeValue;
              
            this.IsHeaderChargeProrated = 
                odataObject.IsHeaderChargeProrated;
              
            this.ChargeTypeValue = 
                odataObject.ChargeTypeValue;
              
            this.ChargeMethodValue = 
                odataObject.ChargeMethodValue;
              
            this.CalculatedAmount = 
                (odataObject.CalculatedAmount != null) ? parseFloat(odataObject.CalculatedAmount) : undefined;
              
            this.Value = 
                (odataObject.Value != null) ? parseFloat(odataObject.Value) : undefined;
              
            this.Description = 
                odataObject.Description;
              
            this.TransactionId = 
                odataObject.TransactionId;
              
            this.MarkupAutoLineRecordId = 
                (odataObject.MarkupAutoLineRecordId != null) ? parseInt(odataObject.MarkupAutoLineRecordId, 10) : undefined;
              
            this.MarkupAutoTableRecId = 
                (odataObject.MarkupAutoTableRecId != null) ? parseInt(odataObject.MarkupAutoTableRecId, 10) : undefined;
              
            this.SaleLineNumber = 
                (odataObject.SaleLineNumber != null) ? parseFloat(odataObject.SaleLineNumber) : undefined;
              
            this.FromAmount = 
                (odataObject.FromAmount != null) ? parseFloat(odataObject.FromAmount) : undefined;
              
            this.ToAmount = 
                (odataObject.ToAmount != null) ? parseFloat(odataObject.ToAmount) : undefined;
              
            this.Keep = 
                odataObject.Keep;
              
            this.AmountRefunded = 
                (odataObject.AmountRefunded != null) ? parseFloat(odataObject.AmountRefunded) : undefined;
              
        this.ChargeLineOverrides = undefined;
        if (odataObject.ChargeLineOverrides) {
        this.ChargeLineOverrides = [];
        for (var i = 0; i < odataObject.ChargeLineOverrides.length; i++) {
        if (odataObject.ChargeLineOverrides[i] != null) {
        if (odataObject.ChargeLineOverrides[i]['@odata.type'] != null) {
          var className: string = odataObject.ChargeLineOverrides[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ChargeLineOverrides[i] = new entityDictionary[className](odataObject.ChargeLineOverrides[i])
          }
        } else {
            this.ChargeLineOverrides[i] = new ChargeLineOverrideClass(odataObject.ChargeLineOverrides[i]);
        }
                    } else {
        this.ChargeLineOverrides[i] = undefined;
        }
        }
        }
      
        this.ReasonCodeLines = undefined;
        if (odataObject.ReasonCodeLines) {
        this.ReasonCodeLines = [];
        for (var i = 0; i < odataObject.ReasonCodeLines.length; i++) {
        if (odataObject.ReasonCodeLines[i] != null) {
        if (odataObject.ReasonCodeLines[i]['@odata.type'] != null) {
          var className: string = odataObject.ReasonCodeLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ReasonCodeLines[i] = new entityDictionary[className](odataObject.ReasonCodeLines[i])
          }
        } else {
            this.ReasonCodeLines[i] = new ReasonCodeLineClass(odataObject.ReasonCodeLines[i]);
        }
                    } else {
        this.ReasonCodeLines[i] = undefined;
        }
        }
        }
      
            this.IsRefundable = 
                odataObject.IsRefundable;
              
            this.IsShipping = 
                odataObject.IsShipping;
              
            this.IsOverridden = 
                odataObject.IsOverridden;
              
            this.IsInvoiced = 
                odataObject.IsInvoiced;
              
            this.CalculatedProratedAmount = 
                (odataObject.CalculatedProratedAmount != null) ? parseFloat(odataObject.CalculatedProratedAmount) : undefined;
              
            this.SpecificUnitSymbol = 
                odataObject.SpecificUnitSymbol;
              
            this.ItemId = 
                odataObject.ItemId;
              
            this.Quantity = 
                (odataObject.Quantity != null) ? parseFloat(odataObject.Quantity) : undefined;
              
            this.Price = 
                (odataObject.Price != null) ? parseFloat(odataObject.Price) : undefined;
              
            this.ItemTaxGroupId = 
                odataObject.ItemTaxGroupId;
              
            this.SalesTaxGroupId = 
                odataObject.SalesTaxGroupId;
              
            this.OriginalSalesTaxGroupId = 
                odataObject.OriginalSalesTaxGroupId;
              
            this.OriginalItemTaxGroupId = 
                odataObject.OriginalItemTaxGroupId;
              
            this.TaxAmount = 
                (odataObject.TaxAmount != null) ? parseFloat(odataObject.TaxAmount) : undefined;
              
            this.SalesOrderUnitOfMeasure = 
                odataObject.SalesOrderUnitOfMeasure;
              
            this.NetAmount = 
                (odataObject.NetAmount != null) ? parseFloat(odataObject.NetAmount) : undefined;
              
            this.NetAmountPerUnit = 
                (odataObject.NetAmountPerUnit != null) ? parseFloat(odataObject.NetAmountPerUnit) : undefined;
              
            this.GrossAmount = 
                (odataObject.GrossAmount != null) ? parseFloat(odataObject.GrossAmount) : undefined;
              
        this.TaxLines = undefined;
        if (odataObject.TaxLines) {
        this.TaxLines = [];
        for (var i = 0; i < odataObject.TaxLines.length; i++) {
        if (odataObject.TaxLines[i] != null) {
        if (odataObject.TaxLines[i]['@odata.type'] != null) {
          var className: string = odataObject.TaxLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.TaxLines[i] = new entityDictionary[className](odataObject.TaxLines[i])
          }
        } else {
            this.TaxLines[i] = new TaxLineClass(odataObject.TaxLines[i]);
        }
                    } else {
        this.TaxLines[i] = undefined;
        }
        }
        }
      
        this.TaxMeasures = undefined;
        if (odataObject.TaxMeasures) {
        this.TaxMeasures = [];
        for (var i = 0; i < odataObject.TaxMeasures.length; i++) {
        if (odataObject.TaxMeasures[i] != null) {
        if (odataObject.TaxMeasures[i]['@odata.type'] != null) {
          var className: string = odataObject.TaxMeasures[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.TaxMeasures[i] = new entityDictionary[className](odataObject.TaxMeasures[i])
          }
        } else {
            this.TaxMeasures[i] = new TaxMeasureClass(odataObject.TaxMeasures[i]);
        }
                    } else {
        this.TaxMeasures[i] = undefined;
        }
        }
        }
      
            this.TaxAmountExemptInclusive = 
                (odataObject.TaxAmountExemptInclusive != null) ? parseFloat(odataObject.TaxAmountExemptInclusive) : undefined;
              
            this.TaxAmountInclusive = 
                (odataObject.TaxAmountInclusive != null) ? parseFloat(odataObject.TaxAmountInclusive) : undefined;
              
            this.TaxAmountExclusive = 
                (odataObject.TaxAmountExclusive != null) ? parseFloat(odataObject.TaxAmountExclusive) : undefined;
              
            this.NetAmountWithAllInclusiveTax = 
                (odataObject.NetAmountWithAllInclusiveTax != null) ? parseFloat(odataObject.NetAmountWithAllInclusiveTax) : undefined;
              
            this.BeginDateTime = 
                (odataObject.BeginDateTime instanceof Date) ? odataObject.BeginDateTime
                : (odataObject.BeginDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.BeginDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.EndDateTime = 
                (odataObject.EndDateTime instanceof Date) ? odataObject.EndDateTime
                : (odataObject.EndDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.EndDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.TaxRatePercent = 
                (odataObject.TaxRatePercent != null) ? parseFloat(odataObject.TaxRatePercent) : undefined;
              
            this.IsReturnByReceipt = 
                odataObject.IsReturnByReceipt;
              
            this.ReturnLineTaxAmount = 
                (odataObject.ReturnLineTaxAmount != null) ? parseFloat(odataObject.ReturnLineTaxAmount) : undefined;
              
        this.ReturnTaxLines = undefined;
        if (odataObject.ReturnTaxLines) {
        this.ReturnTaxLines = [];
        for (var i = 0; i < odataObject.ReturnTaxLines.length; i++) {
        if (odataObject.ReturnTaxLines[i] != null) {
        if (odataObject.ReturnTaxLines[i]['@odata.type'] != null) {
          var className: string = odataObject.ReturnTaxLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ReturnTaxLines[i] = new entityDictionary[className](odataObject.ReturnTaxLines[i])
          }
        } else {
            this.ReturnTaxLines[i] = new TaxLineClass(odataObject.ReturnTaxLines[i]);
        }
                    } else {
        this.ReturnTaxLines[i] = undefined;
        }
        }
        }
      
            this.TaxExemptPriceInclusiveReductionAmount = 
                (odataObject.TaxExemptPriceInclusiveReductionAmount != null) ? parseFloat(odataObject.TaxExemptPriceInclusiveReductionAmount) : undefined;
              
            this.TaxExemptPriceInclusiveOriginalPrice = 
                (odataObject.TaxExemptPriceInclusiveOriginalPrice != null) ? parseFloat(odataObject.TaxExemptPriceInclusiveOriginalPrice) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ChargeLineClass'] = ChargeLineClass;
    
      /**
      * CityInfo entity class.
      */
      export class CityInfoClass implements Entities.CityInfo {
      public Name: string;
      public Description: string;
      public CountryRegionId: string;
      public StateId: string;
      public CountyId: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Name = 
                odataObject.Name;
              
            this.Description = 
                odataObject.Description;
              
            this.CountryRegionId = 
                odataObject.CountryRegionId;
              
            this.StateId = 
                odataObject.StateId;
              
            this.CountyId = 
                odataObject.CountyId;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CityInfoClass'] = CityInfoClass;
    
      /**
      * CommissionSalesGroup entity class.
      */
      export class CommissionSalesGroupClass implements Entities.CommissionSalesGroup {
      public GroupId: string;
      public Name: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.GroupId = 
                odataObject.GroupId;
              
            this.Name = 
                odataObject.Name;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CommissionSalesGroupClass'] = CommissionSalesGroupClass;
    
      /**
      * ContactInformation entity class.
      */
      export class ContactInformationClass implements Entities.ContactInformation {
      public Value: string;
      public ContactInformationTypeValue: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Value = 
                odataObject.Value;
              
            this.ContactInformationTypeValue = 
                odataObject.ContactInformationTypeValue;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ContactInformationClass'] = ContactInformationClass;
    
      /**
      * AttributeCategory entity class.
      */
      export class AttributeCategoryClass implements Entities.AttributeCategory {
      public Value: Entities.CommercePropertyValue;
      public RecordId: number;
      public Name: string;
      public KeyName: string;
      public DataTypeValue: number;
      public Attribute: number;
      public NameTranslations: Entities.TextValueTranslation[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        if (odataObject.Value == null) {
            this.Value = undefined;
        } else if (odataObject.Value['@odata.type'] == null) {
            this.Value = new CommercePropertyValueClass(odataObject.Value);
        } else {
            var className: string = odataObject.Value['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.Value = new entityDictionary[className](odataObject.Value)
            }
        }

      
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.Name = 
                odataObject.Name;
              
            this.KeyName = 
                odataObject.KeyName;
              
            this.DataTypeValue = 
                odataObject.DataTypeValue;
              
            this.Attribute = 
                (odataObject.Attribute != null) ? parseInt(odataObject.Attribute, 10) : undefined;
              
        this.NameTranslations = undefined;
        if (odataObject.NameTranslations) {
        this.NameTranslations = [];
        for (var i = 0; i < odataObject.NameTranslations.length; i++) {
        if (odataObject.NameTranslations[i] != null) {
        if (odataObject.NameTranslations[i]['@odata.type'] != null) {
          var className: string = odataObject.NameTranslations[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.NameTranslations[i] = new entityDictionary[className](odataObject.NameTranslations[i])
          }
        } else {
            this.NameTranslations[i] = new TextValueTranslationClass(odataObject.NameTranslations[i]);
        }
                    } else {
        this.NameTranslations[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['AttributeCategoryClass'] = AttributeCategoryClass;
    
      /**
      * AttributeProduct entity class.
      */
      export class AttributeProductClass implements Entities.AttributeProduct {
      public MetadataFlags: Entities.ProductAttributeMetadataFlags;
      public IsDimension: boolean;
      public RecordId: number;
      public Name: string;
      public KeyName: string;
      public DataTypeValue: number;
      public Attribute: number;
      public NameTranslations: Entities.TextValueTranslation[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        if (odataObject.MetadataFlags == null) {
            this.MetadataFlags = undefined;
        } else if (odataObject.MetadataFlags['@odata.type'] == null) {
            this.MetadataFlags = new ProductAttributeMetadataFlagsClass(odataObject.MetadataFlags);
        } else {
            var className: string = odataObject.MetadataFlags['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.MetadataFlags = new entityDictionary[className](odataObject.MetadataFlags)
            }
        }

      
            this.IsDimension = 
                odataObject.IsDimension;
              
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.Name = 
                odataObject.Name;
              
            this.KeyName = 
                odataObject.KeyName;
              
            this.DataTypeValue = 
                odataObject.DataTypeValue;
              
            this.Attribute = 
                (odataObject.Attribute != null) ? parseInt(odataObject.Attribute, 10) : undefined;
              
        this.NameTranslations = undefined;
        if (odataObject.NameTranslations) {
        this.NameTranslations = [];
        for (var i = 0; i < odataObject.NameTranslations.length; i++) {
        if (odataObject.NameTranslations[i] != null) {
        if (odataObject.NameTranslations[i]['@odata.type'] != null) {
          var className: string = odataObject.NameTranslations[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.NameTranslations[i] = new entityDictionary[className](odataObject.NameTranslations[i])
          }
        } else {
            this.NameTranslations[i] = new TextValueTranslationClass(odataObject.NameTranslations[i]);
        }
                    } else {
        this.NameTranslations[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['AttributeProductClass'] = AttributeProductClass;
    
      /**
      * ProductComparisonEntry entity class.
      */
      export class ProductComparisonEntryClass implements Entities.ProductComparisonEntry {
      public ProductId: number;
      public AttributeValue: Entities.AttributeValue;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
        if (odataObject.AttributeValue == null) {
            this.AttributeValue = undefined;
        } else if (odataObject.AttributeValue['@odata.type'] == null) {
            this.AttributeValue = new AttributeValueClass(odataObject.AttributeValue);
        } else {
            var className: string = odataObject.AttributeValue['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.AttributeValue = new entityDictionary[className](odataObject.AttributeValue)
            }
        }

      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ProductComparisonEntryClass'] = ProductComparisonEntryClass;
    
      /**
      * ProductComparisonLine entity class.
      */
      export class ProductComparisonLineClass implements Entities.ProductComparisonLine {
      public AttributeRecordId: number;
      public AttributeName: string;
      public ProductAttributeComparisonEntries: Entities.ProductComparisonEntry[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.AttributeRecordId = 
                (odataObject.AttributeRecordId != null) ? parseInt(odataObject.AttributeRecordId, 10) : undefined;
              
            this.AttributeName = 
                odataObject.AttributeName;
              
        this.ProductAttributeComparisonEntries = undefined;
        if (odataObject.ProductAttributeComparisonEntries) {
        this.ProductAttributeComparisonEntries = [];
        for (var i = 0; i < odataObject.ProductAttributeComparisonEntries.length; i++) {
        if (odataObject.ProductAttributeComparisonEntries[i] != null) {
        if (odataObject.ProductAttributeComparisonEntries[i]['@odata.type'] != null) {
          var className: string = odataObject.ProductAttributeComparisonEntries[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ProductAttributeComparisonEntries[i] = new entityDictionary[className](odataObject.ProductAttributeComparisonEntries[i])
          }
        } else {
            this.ProductAttributeComparisonEntries[i] = new ProductComparisonEntryClass(odataObject.ProductAttributeComparisonEntries[i]);
        }
                    } else {
        this.ProductAttributeComparisonEntries[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ProductComparisonLineClass'] = ProductComparisonLineClass;
    
      /**
      * CustomerSearchField entity class.
      */
      export class CustomerSearchFieldClass implements Entities.CustomerSearchField {
      public IsShortcut: boolean;
      public DisplayName: string;
      public CanBeRefined: boolean;
      public SearchField: Entities.CustomerSearchFieldType;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.IsShortcut = 
                odataObject.IsShortcut;
              
            this.DisplayName = 
                odataObject.DisplayName;
              
            this.CanBeRefined = 
                odataObject.CanBeRefined;
              
        if (odataObject.SearchField == null) {
            this.SearchField = undefined;
        } else if (odataObject.SearchField['@odata.type'] == null) {
            this.SearchField = new CustomerSearchFieldTypeClass(odataObject.SearchField);
        } else {
            var className: string = odataObject.SearchField['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.SearchField = new entityDictionary[className](odataObject.SearchField)
            }
        }

      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CustomerSearchFieldClass'] = CustomerSearchFieldClass;
    
      /**
      * CustomerSearchByFieldCriteria entity class.
      */
      export class CustomerSearchByFieldCriteriaClass implements Entities.CustomerSearchByFieldCriteria {
      public Criteria: Entities.CustomerSearchByFieldCriterion[];
      public DataLevelValue: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.Criteria = undefined;
        if (odataObject.Criteria) {
        this.Criteria = [];
        for (var i = 0; i < odataObject.Criteria.length; i++) {
        if (odataObject.Criteria[i] != null) {
        if (odataObject.Criteria[i]['@odata.type'] != null) {
          var className: string = odataObject.Criteria[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Criteria[i] = new entityDictionary[className](odataObject.Criteria[i])
          }
        } else {
            this.Criteria[i] = new CustomerSearchByFieldCriterionClass(odataObject.Criteria[i]);
        }
                    } else {
        this.Criteria[i] = undefined;
        }
        }
        }
      
            this.DataLevelValue = 
                odataObject.DataLevelValue;
              
      }
      }
      entityDictionary['CustomerSearchByFieldCriteriaClass'] = CustomerSearchByFieldCriteriaClass;
    
      /**
      * CustomerSearchByFieldCriterion entity class.
      */
      export class CustomerSearchByFieldCriterionClass implements Entities.CustomerSearchByFieldCriterion {
      public SearchTerm: string;
      public SearchField: Entities.CustomerSearchFieldType;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.SearchTerm = 
                odataObject.SearchTerm;
              
        if (odataObject.SearchField == null) {
            this.SearchField = undefined;
        } else if (odataObject.SearchField['@odata.type'] == null) {
            this.SearchField = new CustomerSearchFieldTypeClass(odataObject.SearchField);
        } else {
            var className: string = odataObject.SearchField['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.SearchField = new entityDictionary[className](odataObject.SearchField)
            }
        }

      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CustomerSearchByFieldCriterionClass'] = CustomerSearchByFieldCriterionClass;
    
      /**
      * CustomerSearchFieldType entity class.
      */
      export class CustomerSearchFieldTypeClass
        extends ExtensibleEnumerationClass
       implements Entities.CustomerSearchFieldType {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.CustomerSearchFieldType";
      
      }
      }
      entityDictionary['CustomerSearchFieldTypeClass'] = CustomerSearchFieldTypeClass;
    
      /**
      * OrderShipments entity class.
      */
      export class OrderShipmentsClass implements Entities.OrderShipments {
      public SalesId: string;
      public ChannelId: number;
      public ChannelReferenceId: string;
      public TransactionId: string;
      public CreatedDateTime: Date;
      public StatusValue: number;
      public CurrencyCode: string;
      public CustomerId: string;
      public DeliveryMode: string;
      public GrossAmount: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      public InventoryLocationId: string;
      public ReceiptId: string;
      public RequestedDeliveryDate: Date;
      public TotalAmount: number;
      public TaxAmount: number;
      public SalesLines: Entities.SalesLine[];
      public Shipments: Entities.Shipment[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.SalesId = 
                odataObject.SalesId;
              
            this.ChannelId = 
                (odataObject.ChannelId != null) ? parseInt(odataObject.ChannelId, 10) : undefined;
              
            this.ChannelReferenceId = 
                odataObject.ChannelReferenceId;
              
            this.TransactionId = 
                odataObject.TransactionId;
              
            this.CreatedDateTime = 
                (odataObject.CreatedDateTime instanceof Date) ? odataObject.CreatedDateTime
                : (odataObject.CreatedDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.CreatedDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.StatusValue = 
                odataObject.StatusValue;
              
            this.CurrencyCode = 
                odataObject.CurrencyCode;
              
            this.CustomerId = 
                odataObject.CustomerId;
              
            this.DeliveryMode = 
                odataObject.DeliveryMode;
              
            this.GrossAmount = 
                (odataObject.GrossAmount != null) ? parseFloat(odataObject.GrossAmount) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
            this.InventoryLocationId = 
                odataObject.InventoryLocationId;
              
            this.ReceiptId = 
                odataObject.ReceiptId;
              
            this.RequestedDeliveryDate = 
                (odataObject.RequestedDeliveryDate instanceof Date) ? odataObject.RequestedDeliveryDate
                : (odataObject.RequestedDeliveryDate != null) ? jsonLightReadStringPropertyValue(odataObject.RequestedDeliveryDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.TotalAmount = 
                (odataObject.TotalAmount != null) ? parseFloat(odataObject.TotalAmount) : undefined;
              
            this.TaxAmount = 
                (odataObject.TaxAmount != null) ? parseFloat(odataObject.TaxAmount) : undefined;
              
        this.SalesLines = undefined;
        if (odataObject.SalesLines) {
        this.SalesLines = [];
        for (var i = 0; i < odataObject.SalesLines.length; i++) {
        if (odataObject.SalesLines[i] != null) {
        if (odataObject.SalesLines[i]['@odata.type'] != null) {
          var className: string = odataObject.SalesLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.SalesLines[i] = new entityDictionary[className](odataObject.SalesLines[i])
          }
        } else {
            this.SalesLines[i] = new SalesLineClass(odataObject.SalesLines[i]);
        }
                    } else {
        this.SalesLines[i] = undefined;
        }
        }
        }
      
        this.Shipments = undefined;
        if (odataObject.Shipments) {
        this.Shipments = [];
        for (var i = 0; i < odataObject.Shipments.length; i++) {
        if (odataObject.Shipments[i] != null) {
        if (odataObject.Shipments[i]['@odata.type'] != null) {
          var className: string = odataObject.Shipments[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Shipments[i] = new entityDictionary[className](odataObject.Shipments[i])
          }
        } else {
            this.Shipments[i] = new ShipmentClass(odataObject.Shipments[i]);
        }
                    } else {
        this.Shipments[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['OrderShipmentsClass'] = OrderShipmentsClass;
    
      /**
      * Shipment entity class.
      */
      export class ShipmentClass implements Entities.Shipment {
      public SalesId: string;
      public ShipmentId: string;
      public DeliveryAddress: Entities.Address;
      public ShipDate: Date;
      public DeliveryMode: string;
      public TermsOfDelivery: string;
      public WeightUnit: string;
      public TrackingNumber: string;
      public TrackingUrl: string;
      public LatestCarrierTrackingInfo: Entities.TrackingInfo;
      public CarrierId: string;
      public CarrierName: string;
      public ShippingWeight: number;
      public ShipmentLines: Entities.ShipmentLine[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.SalesId = 
                odataObject.SalesId;
              
            this.ShipmentId = 
                odataObject.ShipmentId;
              
        if (odataObject.DeliveryAddress == null) {
            this.DeliveryAddress = undefined;
        } else if (odataObject.DeliveryAddress['@odata.type'] == null) {
            this.DeliveryAddress = new AddressClass(odataObject.DeliveryAddress);
        } else {
            var className: string = odataObject.DeliveryAddress['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.DeliveryAddress = new entityDictionary[className](odataObject.DeliveryAddress)
            }
        }

      
            this.ShipDate = 
                (odataObject.ShipDate instanceof Date) ? odataObject.ShipDate
                : (odataObject.ShipDate != null) ? jsonLightReadStringPropertyValue(odataObject.ShipDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.DeliveryMode = 
                odataObject.DeliveryMode;
              
            this.TermsOfDelivery = 
                odataObject.TermsOfDelivery;
              
            this.WeightUnit = 
                odataObject.WeightUnit;
              
            this.TrackingNumber = 
                odataObject.TrackingNumber;
              
            this.TrackingUrl = 
                odataObject.TrackingUrl;
              
        if (odataObject.LatestCarrierTrackingInfo == null) {
            this.LatestCarrierTrackingInfo = undefined;
        } else if (odataObject.LatestCarrierTrackingInfo['@odata.type'] == null) {
            this.LatestCarrierTrackingInfo = new TrackingInfoClass(odataObject.LatestCarrierTrackingInfo);
        } else {
            var className: string = odataObject.LatestCarrierTrackingInfo['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.LatestCarrierTrackingInfo = new entityDictionary[className](odataObject.LatestCarrierTrackingInfo)
            }
        }

      
            this.CarrierId = 
                odataObject.CarrierId;
              
            this.CarrierName = 
                odataObject.CarrierName;
              
            this.ShippingWeight = 
                (odataObject.ShippingWeight != null) ? parseFloat(odataObject.ShippingWeight) : undefined;
              
        this.ShipmentLines = undefined;
        if (odataObject.ShipmentLines) {
        this.ShipmentLines = [];
        for (var i = 0; i < odataObject.ShipmentLines.length; i++) {
        if (odataObject.ShipmentLines[i] != null) {
        if (odataObject.ShipmentLines[i]['@odata.type'] != null) {
          var className: string = odataObject.ShipmentLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ShipmentLines[i] = new entityDictionary[className](odataObject.ShipmentLines[i])
          }
        } else {
            this.ShipmentLines[i] = new ShipmentLineClass(odataObject.ShipmentLines[i]);
        }
                    } else {
        this.ShipmentLines[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ShipmentClass'] = ShipmentClass;
    
      /**
      * ShipmentLine entity class.
      */
      export class ShipmentLineClass implements Entities.ShipmentLine {
      public ShipmentLineId: string;
      public ItemId: string;
      public Description: string;
      public InventDimId: string;
      public OrderedQuantity: number;
      public DeliveredQuantity: number;
      public RemainingQuantity: number;
      public InventoryTransactionId: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ShipmentLineId = 
                odataObject.ShipmentLineId;
              
            this.ItemId = 
                odataObject.ItemId;
              
            this.Description = 
                odataObject.Description;
              
            this.InventDimId = 
                odataObject.InventDimId;
              
            this.OrderedQuantity = 
                (odataObject.OrderedQuantity != null) ? parseFloat(odataObject.OrderedQuantity) : undefined;
              
            this.DeliveredQuantity = 
                (odataObject.DeliveredQuantity != null) ? parseFloat(odataObject.DeliveredQuantity) : undefined;
              
            this.RemainingQuantity = 
                (odataObject.RemainingQuantity != null) ? parseFloat(odataObject.RemainingQuantity) : undefined;
              
            this.InventoryTransactionId = 
                odataObject.InventoryTransactionId;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ShipmentLineClass'] = ShipmentLineClass;
    
      /**
      * Transaction entity class.
      */
      export class TransactionClass implements Entities.Transaction {
      public Id: string;
      public StoreId: string;
      public TerminalId: string;
      public IsCreatedOffline: boolean;
      public StaffId: string;
      public LanguageId: string;
      public ShiftId: string;
      public ShiftTerminalId: string;
      public Description: string;
      public ReceiptId: string;
      public GrossAmount: number;
      public TotalAmount: number;
      public CreatedDateTime: Date;
      public TransactionTypeValue: number;
      public TransactionStatusValue: number;
      public BeginDateTime: Date;
      public GiftCardActiveFrom: Date;
      public GiftCardBalance: number;
      public GiftCardExpireDate: Date;
      public GiftCardHistoryDetails: string;
      public GiftCardIssueAmount: number;
      public GiftCardIdMasked: string;
      public FromSafe: string;
      public ToSafe: string;
      public FromShiftTerminalId: string;
      public ToShiftTerminalId: string;
      public FromShiftId: string;
      public TransactionSourceContextTypeValue: number;
      public TransactionDestinationContextTypeValue: number;
      public ToShiftId: string;
      public TransactionDateTime: Date;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Id = 
                odataObject.Id;
              
            this.StoreId = 
                odataObject.StoreId;
              
            this.TerminalId = 
                odataObject.TerminalId;
              
            this.IsCreatedOffline = 
                odataObject.IsCreatedOffline;
              
            this.StaffId = 
                odataObject.StaffId;
              
            this.LanguageId = 
                odataObject.LanguageId;
              
            this.ShiftId = 
                odataObject.ShiftId;
              
            this.ShiftTerminalId = 
                odataObject.ShiftTerminalId;
              
            this.Description = 
                odataObject.Description;
              
            this.ReceiptId = 
                odataObject.ReceiptId;
              
            this.GrossAmount = 
                (odataObject.GrossAmount != null) ? parseFloat(odataObject.GrossAmount) : undefined;
              
            this.TotalAmount = 
                (odataObject.TotalAmount != null) ? parseFloat(odataObject.TotalAmount) : undefined;
              
            this.CreatedDateTime = 
                (odataObject.CreatedDateTime instanceof Date) ? odataObject.CreatedDateTime
                : (odataObject.CreatedDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.CreatedDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.TransactionTypeValue = 
                odataObject.TransactionTypeValue;
              
            this.TransactionStatusValue = 
                odataObject.TransactionStatusValue;
              
            this.BeginDateTime = 
                (odataObject.BeginDateTime instanceof Date) ? odataObject.BeginDateTime
                : (odataObject.BeginDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.BeginDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.GiftCardActiveFrom = 
                (odataObject.GiftCardActiveFrom instanceof Date) ? odataObject.GiftCardActiveFrom
                : (odataObject.GiftCardActiveFrom != null) ? jsonLightReadStringPropertyValue(odataObject.GiftCardActiveFrom, "Edm.DateTimeOffset", false) : undefined;
              
            this.GiftCardBalance = 
                (odataObject.GiftCardBalance != null) ? parseFloat(odataObject.GiftCardBalance) : undefined;
              
            this.GiftCardExpireDate = 
                (odataObject.GiftCardExpireDate instanceof Date) ? odataObject.GiftCardExpireDate
                : (odataObject.GiftCardExpireDate != null) ? jsonLightReadStringPropertyValue(odataObject.GiftCardExpireDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.GiftCardHistoryDetails = 
                odataObject.GiftCardHistoryDetails;
              
            this.GiftCardIssueAmount = 
                (odataObject.GiftCardIssueAmount != null) ? parseFloat(odataObject.GiftCardIssueAmount) : undefined;
              
            this.GiftCardIdMasked = 
                odataObject.GiftCardIdMasked;
              
            this.FromSafe = 
                odataObject.FromSafe;
              
            this.ToSafe = 
                odataObject.ToSafe;
              
            this.FromShiftTerminalId = 
                odataObject.FromShiftTerminalId;
              
            this.ToShiftTerminalId = 
                odataObject.ToShiftTerminalId;
              
            this.FromShiftId = 
                odataObject.FromShiftId;
              
            this.TransactionSourceContextTypeValue = 
                odataObject.TransactionSourceContextTypeValue;
              
            this.TransactionDestinationContextTypeValue = 
                odataObject.TransactionDestinationContextTypeValue;
              
            this.ToShiftId = 
                odataObject.ToShiftId;
              
            this.TransactionDateTime = 
                (odataObject.TransactionDateTime instanceof Date) ? odataObject.TransactionDateTime
                : (odataObject.TransactionDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.TransactionDateTime, "Edm.DateTimeOffset", false) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['TransactionClass'] = TransactionClass;
    
      /**
      * ShiftReconciliationLine entity class.
      */
      export class ShiftReconciliationLineClass
        extends TransactionClass
       implements Entities.ShiftReconciliationLine {
      public GroupId: number;
      public GroupDescription: string;
      public ShiftGroupLinesCount: number;
      public ShiftGroupLinesClosedCount: number;
      public AmountInOriginalCurrency: number;
      public TransactionCurrencyCode: string;
      public TenderType: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.GroupId = 
                (odataObject.GroupId != null) ? parseInt(odataObject.GroupId, 10) : undefined;
              
            this.GroupDescription = 
                odataObject.GroupDescription;
              
            this.ShiftGroupLinesCount = 
                (odataObject.ShiftGroupLinesCount != null) ? parseInt(odataObject.ShiftGroupLinesCount, 10) : undefined;
              
            this.ShiftGroupLinesClosedCount = 
                (odataObject.ShiftGroupLinesClosedCount != null) ? parseInt(odataObject.ShiftGroupLinesClosedCount, 10) : undefined;
              
            this.AmountInOriginalCurrency = 
                (odataObject.AmountInOriginalCurrency != null) ? parseFloat(odataObject.AmountInOriginalCurrency) : undefined;
              
            this.TransactionCurrencyCode = 
                odataObject.TransactionCurrencyCode;
              
            this.TenderType = 
                odataObject.TenderType;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.ShiftReconciliationLine";
      
      }
      }
      entityDictionary['ShiftReconciliationLineClass'] = ShiftReconciliationLineClass;
    
      /**
      * ShiftReconciliationLineRetrievalCriteria entity class.
      */
      export class ShiftReconciliationLineRetrievalCriteriaClass implements Entities.ShiftReconciliationLineRetrievalCriteria {
      public TransactionTypeValues: number[];
      public ShiftReconciliationLineStatusValue: number;
      public Shifts: Entities.ShiftKey[];
      public DestinationShift: Entities.ShiftKey;
      public SourceShift: Entities.ShiftKey;
      public SourceSafe: string;
      public DestinationSafe: string;
      public TransactionSourceContextTypeValue: number;
      public TransactionDestinationContextTypeValue: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.TransactionTypeValues = undefined;
        if (odataObject.TransactionTypeValues) {
        this.TransactionTypeValues = [];
        for (var i = 0; i < odataObject.TransactionTypeValues.length; i++) {
        if (odataObject.TransactionTypeValues[i] != null) {
            this.TransactionTypeValues[i] = 
                odataObject.TransactionTypeValues[i];
                            } else {
        this.TransactionTypeValues[i] = undefined;
        }
        }
        }
      
            this.ShiftReconciliationLineStatusValue = 
                odataObject.ShiftReconciliationLineStatusValue;
              
        this.Shifts = undefined;
        if (odataObject.Shifts) {
        this.Shifts = [];
        for (var i = 0; i < odataObject.Shifts.length; i++) {
        if (odataObject.Shifts[i] != null) {
        if (odataObject.Shifts[i]['@odata.type'] != null) {
          var className: string = odataObject.Shifts[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Shifts[i] = new entityDictionary[className](odataObject.Shifts[i])
          }
        } else {
            this.Shifts[i] = new ShiftKeyClass(odataObject.Shifts[i]);
        }
                    } else {
        this.Shifts[i] = undefined;
        }
        }
        }
      
        if (odataObject.DestinationShift == null) {
            this.DestinationShift = undefined;
        } else if (odataObject.DestinationShift['@odata.type'] == null) {
            this.DestinationShift = new ShiftKeyClass(odataObject.DestinationShift);
        } else {
            var className: string = odataObject.DestinationShift['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.DestinationShift = new entityDictionary[className](odataObject.DestinationShift)
            }
        }

      
        if (odataObject.SourceShift == null) {
            this.SourceShift = undefined;
        } else if (odataObject.SourceShift['@odata.type'] == null) {
            this.SourceShift = new ShiftKeyClass(odataObject.SourceShift);
        } else {
            var className: string = odataObject.SourceShift['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.SourceShift = new entityDictionary[className](odataObject.SourceShift)
            }
        }

      
            this.SourceSafe = 
                odataObject.SourceSafe;
              
            this.DestinationSafe = 
                odataObject.DestinationSafe;
              
            this.TransactionSourceContextTypeValue = 
                odataObject.TransactionSourceContextTypeValue;
              
            this.TransactionDestinationContextTypeValue = 
                odataObject.TransactionDestinationContextTypeValue;
              
      }
      }
      entityDictionary['ShiftReconciliationLineRetrievalCriteriaClass'] = ShiftReconciliationLineRetrievalCriteriaClass;
    
      /**
      * SearchConfiguration entity class.
      */
      export class SearchConfigurationClass implements Entities.SearchConfiguration {
      public ProductSortColumns: Entities.SortColumn[];
      public ProductSearchServiceTypeValue: number;
      public ProductSortColumnsLabels: Entities.LocalizedText[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.ProductSortColumns = undefined;
        if (odataObject.ProductSortColumns) {
        this.ProductSortColumns = [];
        for (var i = 0; i < odataObject.ProductSortColumns.length; i++) {
        if (odataObject.ProductSortColumns[i] != null) {
        if (odataObject.ProductSortColumns[i]['@odata.type'] != null) {
          var className: string = odataObject.ProductSortColumns[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ProductSortColumns[i] = new entityDictionary[className](odataObject.ProductSortColumns[i])
          }
        } else {
            this.ProductSortColumns[i] = new SortColumnClass(odataObject.ProductSortColumns[i]);
        }
                    } else {
        this.ProductSortColumns[i] = undefined;
        }
        }
        }
      
            this.ProductSearchServiceTypeValue = 
                odataObject.ProductSearchServiceTypeValue;
              
        this.ProductSortColumnsLabels = undefined;
        if (odataObject.ProductSortColumnsLabels) {
        this.ProductSortColumnsLabels = [];
        for (var i = 0; i < odataObject.ProductSortColumnsLabels.length; i++) {
        if (odataObject.ProductSortColumnsLabels[i] != null) {
        if (odataObject.ProductSortColumnsLabels[i]['@odata.type'] != null) {
          var className: string = odataObject.ProductSortColumnsLabels[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ProductSortColumnsLabels[i] = new entityDictionary[className](odataObject.ProductSortColumnsLabels[i])
          }
        } else {
            this.ProductSortColumnsLabels[i] = new LocalizedTextClass(odataObject.ProductSortColumnsLabels[i]);
        }
                    } else {
        this.ProductSortColumnsLabels[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['SearchConfigurationClass'] = SearchConfigurationClass;
    
      /**
      * CustomerTimelineItem entity class.
      */
      export class CustomerTimelineItemClass implements Entities.CustomerTimelineItem {
      public CustomerId: string;
      public Description: string;
      public Id: string;
      public EntityType: string;
      public TypeId: string;
      public StaffId: string;
      public StartDateTime: Date;
      public Title: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.CustomerId = 
                odataObject.CustomerId;
              
            this.Description = 
                odataObject.Description;
              
            this.Id = 
                odataObject.Id;
              
            this.EntityType = 
                odataObject.EntityType;
              
            this.TypeId = 
                odataObject.TypeId;
              
            this.StaffId = 
                odataObject.StaffId;
              
            this.StartDateTime = 
                (odataObject.StartDateTime instanceof Date) ? odataObject.StartDateTime
                : (odataObject.StartDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.StartDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.Title = 
                odataObject.Title;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CustomerTimelineItemClass'] = CustomerTimelineItemClass;
    
      /**
      * AttributeDefinition entity class.
      */
      export class AttributeDefinitionClass implements Entities.AttributeDefinition {
      public RecordId: number;
      public Name: string;
      public AttributeTypeName: string;
      public ExtDataType: Entities.ExtensibleAttributeDataType;
      public DataTypeValue: number;
      public IsHidden: boolean;
      public IsMandatory: boolean;
      public IsMultiValue: boolean;
      public IsEnumeration: boolean;
      public TranslationDetails: Entities.AttributeTranslationDetails[];
      public EnumerationDetails: Entities.AttributeEnumeration[];
      public DefaultValue: Entities.CommercePropertyValue;
      public UpperBound: Entities.CommercePropertyValue;
      public LowerBound: Entities.CommercePropertyValue;
      public DisplayOrder: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.Name = 
                odataObject.Name;
              
            this.AttributeTypeName = 
                odataObject.AttributeTypeName;
              
        if (odataObject.ExtDataType == null) {
            this.ExtDataType = undefined;
        } else if (odataObject.ExtDataType['@odata.type'] == null) {
            this.ExtDataType = new ExtensibleAttributeDataTypeClass(odataObject.ExtDataType);
        } else {
            var className: string = odataObject.ExtDataType['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.ExtDataType = new entityDictionary[className](odataObject.ExtDataType)
            }
        }

      
            this.DataTypeValue = 
                odataObject.DataTypeValue;
              
            this.IsHidden = 
                odataObject.IsHidden;
              
            this.IsMandatory = 
                odataObject.IsMandatory;
              
            this.IsMultiValue = 
                odataObject.IsMultiValue;
              
            this.IsEnumeration = 
                odataObject.IsEnumeration;
              
        this.TranslationDetails = undefined;
        if (odataObject.TranslationDetails) {
        this.TranslationDetails = [];
        for (var i = 0; i < odataObject.TranslationDetails.length; i++) {
        if (odataObject.TranslationDetails[i] != null) {
        if (odataObject.TranslationDetails[i]['@odata.type'] != null) {
          var className: string = odataObject.TranslationDetails[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.TranslationDetails[i] = new entityDictionary[className](odataObject.TranslationDetails[i])
          }
        } else {
            this.TranslationDetails[i] = new AttributeTranslationDetailsClass(odataObject.TranslationDetails[i]);
        }
                    } else {
        this.TranslationDetails[i] = undefined;
        }
        }
        }
      
        this.EnumerationDetails = undefined;
        if (odataObject.EnumerationDetails) {
        this.EnumerationDetails = [];
        for (var i = 0; i < odataObject.EnumerationDetails.length; i++) {
        if (odataObject.EnumerationDetails[i] != null) {
        if (odataObject.EnumerationDetails[i]['@odata.type'] != null) {
          var className: string = odataObject.EnumerationDetails[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.EnumerationDetails[i] = new entityDictionary[className](odataObject.EnumerationDetails[i])
          }
        } else {
            this.EnumerationDetails[i] = new AttributeEnumerationClass(odataObject.EnumerationDetails[i]);
        }
                    } else {
        this.EnumerationDetails[i] = undefined;
        }
        }
        }
      
        if (odataObject.DefaultValue == null) {
            this.DefaultValue = undefined;
        } else if (odataObject.DefaultValue['@odata.type'] == null) {
            this.DefaultValue = new CommercePropertyValueClass(odataObject.DefaultValue);
        } else {
            var className: string = odataObject.DefaultValue['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.DefaultValue = new entityDictionary[className](odataObject.DefaultValue)
            }
        }

      
        if (odataObject.UpperBound == null) {
            this.UpperBound = undefined;
        } else if (odataObject.UpperBound['@odata.type'] == null) {
            this.UpperBound = new CommercePropertyValueClass(odataObject.UpperBound);
        } else {
            var className: string = odataObject.UpperBound['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.UpperBound = new entityDictionary[className](odataObject.UpperBound)
            }
        }

      
        if (odataObject.LowerBound == null) {
            this.LowerBound = undefined;
        } else if (odataObject.LowerBound['@odata.type'] == null) {
            this.LowerBound = new CommercePropertyValueClass(odataObject.LowerBound);
        } else {
            var className: string = odataObject.LowerBound['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.LowerBound = new entityDictionary[className](odataObject.LowerBound)
            }
        }

      
            this.DisplayOrder = 
                (odataObject.DisplayOrder != null) ? parseFloat(odataObject.DisplayOrder) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['AttributeDefinitionClass'] = AttributeDefinitionClass;
    
      /**
      * AttributeTranslationDetails entity class.
      */
      export class AttributeTranslationDetailsClass implements Entities.AttributeTranslationDetails {
      public AttributeRecordId: number;
      public LanguageId: string;
      public FriendlyName: string;
      public Description: string;
      public HelpText: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.AttributeRecordId = 
                (odataObject.AttributeRecordId != null) ? parseInt(odataObject.AttributeRecordId, 10) : undefined;
              
            this.LanguageId = 
                odataObject.LanguageId;
              
            this.FriendlyName = 
                odataObject.FriendlyName;
              
            this.Description = 
                odataObject.Description;
              
            this.HelpText = 
                odataObject.HelpText;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['AttributeTranslationDetailsClass'] = AttributeTranslationDetailsClass;
    
      /**
      * AttributeEnumeration entity class.
      */
      export class AttributeEnumerationClass implements Entities.AttributeEnumeration {
      public OrdinalNumber: number;
      public EnumerationValue: string;
      public AttributeEnumerationTranslations: Entities.AttributeEnumerationTranslation[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.OrdinalNumber = 
                odataObject.OrdinalNumber;
              
            this.EnumerationValue = 
                odataObject.EnumerationValue;
              
        this.AttributeEnumerationTranslations = undefined;
        if (odataObject.AttributeEnumerationTranslations) {
        this.AttributeEnumerationTranslations = [];
        for (var i = 0; i < odataObject.AttributeEnumerationTranslations.length; i++) {
        if (odataObject.AttributeEnumerationTranslations[i] != null) {
        if (odataObject.AttributeEnumerationTranslations[i]['@odata.type'] != null) {
          var className: string = odataObject.AttributeEnumerationTranslations[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.AttributeEnumerationTranslations[i] = new entityDictionary[className](odataObject.AttributeEnumerationTranslations[i])
          }
        } else {
            this.AttributeEnumerationTranslations[i] = new AttributeEnumerationTranslationClass(odataObject.AttributeEnumerationTranslations[i]);
        }
                    } else {
        this.AttributeEnumerationTranslations[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['AttributeEnumerationClass'] = AttributeEnumerationClass;
    
      /**
      * AttributeEnumerationTranslation entity class.
      */
      export class AttributeEnumerationTranslationClass implements Entities.AttributeEnumerationTranslation {
      public LanguageId: string;
      public EnumerationTranslationValue: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.LanguageId = 
                odataObject.LanguageId;
              
            this.EnumerationTranslationValue = 
                odataObject.EnumerationTranslationValue;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['AttributeEnumerationTranslationClass'] = AttributeEnumerationTranslationClass;
    
      /**
      * AttributeDefinitionCriteria entity class.
      */
      export class AttributeDefinitionCriteriaClass implements Entities.AttributeDefinitionCriteria {
      public AttributeGroupIdentifier: number;
      public Languages: string[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.AttributeGroupIdentifier = 
                (odataObject.AttributeGroupIdentifier != null) ? parseInt(odataObject.AttributeGroupIdentifier, 10) : undefined;
              
        this.Languages = undefined;
        if (odataObject.Languages) {
        this.Languages = [];
        for (var i = 0; i < odataObject.Languages.length; i++) {
        if (odataObject.Languages[i] != null) {
            this.Languages[i] = 
                odataObject.Languages[i];
                            } else {
        this.Languages[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['AttributeDefinitionCriteriaClass'] = AttributeDefinitionCriteriaClass;
    
      /**
      * AttributeGroupDefinition entity class.
      */
      export class AttributeGroupDefinitionClass implements Entities.AttributeGroupDefinition {
      public RecordId: number;
      public Name: string;
      public TranslationDetails: Entities.AttributeGroupTranslationDetails[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.Name = 
                odataObject.Name;
              
        this.TranslationDetails = undefined;
        if (odataObject.TranslationDetails) {
        this.TranslationDetails = [];
        for (var i = 0; i < odataObject.TranslationDetails.length; i++) {
        if (odataObject.TranslationDetails[i] != null) {
        if (odataObject.TranslationDetails[i]['@odata.type'] != null) {
          var className: string = odataObject.TranslationDetails[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.TranslationDetails[i] = new entityDictionary[className](odataObject.TranslationDetails[i])
          }
        } else {
            this.TranslationDetails[i] = new AttributeGroupTranslationDetailsClass(odataObject.TranslationDetails[i]);
        }
                    } else {
        this.TranslationDetails[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['AttributeGroupDefinitionClass'] = AttributeGroupDefinitionClass;
    
      /**
      * AttributeGroupTranslationDetails entity class.
      */
      export class AttributeGroupTranslationDetailsClass implements Entities.AttributeGroupTranslationDetails {
      public AttributeGroupRecordId: number;
      public LanguageId: string;
      public FriendlyName: string;
      public Description: string;
      public HelpText: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.AttributeGroupRecordId = 
                (odataObject.AttributeGroupRecordId != null) ? parseInt(odataObject.AttributeGroupRecordId, 10) : undefined;
              
            this.LanguageId = 
                odataObject.LanguageId;
              
            this.FriendlyName = 
                odataObject.FriendlyName;
              
            this.Description = 
                odataObject.Description;
              
            this.HelpText = 
                odataObject.HelpText;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['AttributeGroupTranslationDetailsClass'] = AttributeGroupTranslationDetailsClass;
    
      /**
      * AttributeGroupDefinitionCriteria entity class.
      */
      export class AttributeGroupDefinitionCriteriaClass implements Entities.AttributeGroupDefinitionCriteria {
      public AttributeGroupIdentifiers: number[];
      public Languages: string[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.AttributeGroupIdentifiers = undefined;
        if (odataObject.AttributeGroupIdentifiers) {
        this.AttributeGroupIdentifiers = [];
        for (var i = 0; i < odataObject.AttributeGroupIdentifiers.length; i++) {
        if (odataObject.AttributeGroupIdentifiers[i] != null) {
            this.AttributeGroupIdentifiers[i] = 
                parseInt(odataObject.AttributeGroupIdentifiers[i], 10);
                            } else {
        this.AttributeGroupIdentifiers[i] = undefined;
        }
        }
        }
      
        this.Languages = undefined;
        if (odataObject.Languages) {
        this.Languages = [];
        for (var i = 0; i < odataObject.Languages.length; i++) {
        if (odataObject.Languages[i] != null) {
            this.Languages[i] = 
                odataObject.Languages[i];
                            } else {
        this.Languages[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['AttributeGroupDefinitionCriteriaClass'] = AttributeGroupDefinitionCriteriaClass;
    
      /**
      * CountyInfo entity class.
      */
      export class CountyInfoClass implements Entities.CountyInfo {
      public Name: string;
      public CountyId: string;
      public CountryRegionId: string;
      public StateId: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Name = 
                odataObject.Name;
              
            this.CountyId = 
                odataObject.CountyId;
              
            this.CountryRegionId = 
                odataObject.CountryRegionId;
              
            this.StateId = 
                odataObject.StateId;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CountyInfoClass'] = CountyInfoClass;
    
      /**
      * CustomerGroup entity class.
      */
      export class CustomerGroupClass implements Entities.CustomerGroup {
      public CustomerGroupNumber: string;
      public CustomerGroupName: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.CustomerGroupNumber = 
                odataObject.CustomerGroupNumber;
              
            this.CustomerGroupName = 
                odataObject.CustomerGroupName;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CustomerGroupClass'] = CustomerGroupClass;
    
      /**
      * DiscountLine entity class.
      */
      export class DiscountLineClass implements Entities.DiscountLine {
      public SaleLineNumber: number;
      public OfferId: string;
      public OfferName: string;
      public OfferDescription: string;
      public Amount: number;
      public DiscountCost: number;
      public EffectiveAmount: number;
      public EffectivePercentage: number;
      public LineNumber: number;
      public RecordId: number;
      public SavedEffectiveAmount: number;
      public Percentage: number;
      public DealPrice: number;
      public DiscountLineTypeValue: number;
      public ManualDiscountTypeValue: number;
      public CustomerDiscountTypeValue: number;
      public PeriodicDiscountTypeValue: number;
      public DiscountApplicationGroup: string;
      public ConcurrencyModeValue: number;
      public IsCompoundable: boolean;
      public DiscountCode: string;
      public PricingPriorityNumber: number;
      public PricingAttributeCombinationPriority: number;
      public IsDiscountCodeRequired: boolean;
      public ThresholdAmountRequired: number;
      public BundleId: number;
      public ValidFrom: Date;
      public ValidTo: Date;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.SaleLineNumber = 
                (odataObject.SaleLineNumber != null) ? parseFloat(odataObject.SaleLineNumber) : undefined;
              
            this.OfferId = 
                odataObject.OfferId;
              
            this.OfferName = 
                odataObject.OfferName;
              
            this.OfferDescription = 
                odataObject.OfferDescription;
              
            this.Amount = 
                (odataObject.Amount != null) ? parseFloat(odataObject.Amount) : undefined;
              
            this.DiscountCost = 
                (odataObject.DiscountCost != null) ? parseFloat(odataObject.DiscountCost) : undefined;
              
            this.EffectiveAmount = 
                (odataObject.EffectiveAmount != null) ? parseFloat(odataObject.EffectiveAmount) : undefined;
              
            this.EffectivePercentage = 
                (odataObject.EffectivePercentage != null) ? parseFloat(odataObject.EffectivePercentage) : undefined;
              
            this.LineNumber = 
                (odataObject.LineNumber != null) ? parseFloat(odataObject.LineNumber) : undefined;
              
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.SavedEffectiveAmount = 
                (odataObject.SavedEffectiveAmount != null) ? parseFloat(odataObject.SavedEffectiveAmount) : undefined;
              
            this.Percentage = 
                (odataObject.Percentage != null) ? parseFloat(odataObject.Percentage) : undefined;
              
            this.DealPrice = 
                (odataObject.DealPrice != null) ? parseFloat(odataObject.DealPrice) : undefined;
              
            this.DiscountLineTypeValue = 
                odataObject.DiscountLineTypeValue;
              
            this.ManualDiscountTypeValue = 
                odataObject.ManualDiscountTypeValue;
              
            this.CustomerDiscountTypeValue = 
                odataObject.CustomerDiscountTypeValue;
              
            this.PeriodicDiscountTypeValue = 
                odataObject.PeriodicDiscountTypeValue;
              
            this.DiscountApplicationGroup = 
                odataObject.DiscountApplicationGroup;
              
            this.ConcurrencyModeValue = 
                odataObject.ConcurrencyModeValue;
              
            this.IsCompoundable = 
                odataObject.IsCompoundable;
              
            this.DiscountCode = 
                odataObject.DiscountCode;
              
            this.PricingPriorityNumber = 
                odataObject.PricingPriorityNumber;
              
            this.PricingAttributeCombinationPriority = 
                (odataObject.PricingAttributeCombinationPriority != null) ? parseFloat(odataObject.PricingAttributeCombinationPriority) : undefined;
              
            this.IsDiscountCodeRequired = 
                odataObject.IsDiscountCodeRequired;
              
            this.ThresholdAmountRequired = 
                (odataObject.ThresholdAmountRequired != null) ? parseFloat(odataObject.ThresholdAmountRequired) : undefined;
              
            this.BundleId = 
                odataObject.BundleId;
              
            this.ValidFrom = 
                (odataObject.ValidFrom instanceof Date) ? odataObject.ValidFrom
                : (odataObject.ValidFrom != null) ? jsonLightReadStringPropertyValue(odataObject.ValidFrom, "Edm.DateTimeOffset", false) : undefined;
              
            this.ValidTo = 
                (odataObject.ValidTo instanceof Date) ? odataObject.ValidTo
                : (odataObject.ValidTo != null) ? jsonLightReadStringPropertyValue(odataObject.ValidTo, "Edm.DateTimeOffset", false) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['DiscountLineClass'] = DiscountLineClass;
    
      /**
      * EmployeePermissions entity class.
      */
      export class EmployeePermissionsClass implements Entities.EmployeePermissions {
      public PriceOverrideRestrictionValue: number;
      public AllowPriceOverride: number;
      public MaximumDiscountPercentage: number;
      public MaximumLineDiscountAmount: number;
      public MaximumLineReturnAmount: number;
      public MaximumTotalDiscountAmount: number;
      public MaximumTotalDiscountPercentage: number;
      public MaxTotalReturnAmount: number;
      public StaffId: string;
      public Roles: string[];
      public AllowUseSharedShift: boolean;
      public AllowManageSharedShift: boolean;
      public AllowTaskGuides: boolean;
      public AllowSaleOutsideAssortment: boolean;
      public AllowPrintingReceiptCopy: string;
      public AllowSkipFiscalRegistration: boolean;
      public AllowSkipRegistrationOrMarkAsRegistered: boolean;
      public AllowPostponeRegistration: boolean;
      public AllowCreateTransferOrder: boolean;
      public AllowAcceptOrder: boolean;
      public AllowTaskManagement: boolean;
      public AllowRejectOrder: boolean;
      public AllowMassActivation: boolean;
      public AllowPostInventoryAdjustment: boolean;
      public AllowPostStockCounting: boolean;
      public AllowCreatePurchaseOrder: boolean;
      public AllowConfirmPurchaseOrder: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.PriceOverrideRestrictionValue = 
                odataObject.PriceOverrideRestrictionValue;
              
            this.AllowPriceOverride = 
                odataObject.AllowPriceOverride;
              
            this.MaximumDiscountPercentage = 
                (odataObject.MaximumDiscountPercentage != null) ? parseFloat(odataObject.MaximumDiscountPercentage) : undefined;
              
            this.MaximumLineDiscountAmount = 
                (odataObject.MaximumLineDiscountAmount != null) ? parseFloat(odataObject.MaximumLineDiscountAmount) : undefined;
              
            this.MaximumLineReturnAmount = 
                (odataObject.MaximumLineReturnAmount != null) ? parseFloat(odataObject.MaximumLineReturnAmount) : undefined;
              
            this.MaximumTotalDiscountAmount = 
                (odataObject.MaximumTotalDiscountAmount != null) ? parseFloat(odataObject.MaximumTotalDiscountAmount) : undefined;
              
            this.MaximumTotalDiscountPercentage = 
                (odataObject.MaximumTotalDiscountPercentage != null) ? parseFloat(odataObject.MaximumTotalDiscountPercentage) : undefined;
              
            this.MaxTotalReturnAmount = 
                (odataObject.MaxTotalReturnAmount != null) ? parseFloat(odataObject.MaxTotalReturnAmount) : undefined;
              
            this.StaffId = 
                odataObject.StaffId;
              
        this.Roles = undefined;
        if (odataObject.Roles) {
        this.Roles = [];
        for (var i = 0; i < odataObject.Roles.length; i++) {
        if (odataObject.Roles[i] != null) {
            this.Roles[i] = 
                odataObject.Roles[i];
                            } else {
        this.Roles[i] = undefined;
        }
        }
        }
      
            this.AllowUseSharedShift = 
                odataObject.AllowUseSharedShift;
              
            this.AllowManageSharedShift = 
                odataObject.AllowManageSharedShift;
              
            this.AllowTaskGuides = 
                odataObject.AllowTaskGuides;
              
            this.AllowSaleOutsideAssortment = 
                odataObject.AllowSaleOutsideAssortment;
              
            this.AllowPrintingReceiptCopy = 
                odataObject.AllowPrintingReceiptCopy;
              
            this.AllowSkipFiscalRegistration = 
                odataObject.AllowSkipFiscalRegistration;
              
            this.AllowSkipRegistrationOrMarkAsRegistered = 
                odataObject.AllowSkipRegistrationOrMarkAsRegistered;
              
            this.AllowPostponeRegistration = 
                odataObject.AllowPostponeRegistration;
              
            this.AllowCreateTransferOrder = 
                odataObject.AllowCreateTransferOrder;
              
            this.AllowAcceptOrder = 
                odataObject.AllowAcceptOrder;
              
            this.AllowTaskManagement = 
                odataObject.AllowTaskManagement;
              
            this.AllowRejectOrder = 
                odataObject.AllowRejectOrder;
              
            this.AllowMassActivation = 
                odataObject.AllowMassActivation;
              
            this.AllowPostInventoryAdjustment = 
                odataObject.AllowPostInventoryAdjustment;
              
            this.AllowPostStockCounting = 
                odataObject.AllowPostStockCounting;
              
            this.AllowCreatePurchaseOrder = 
                odataObject.AllowCreatePurchaseOrder;
              
            this.AllowConfirmPurchaseOrder = 
                odataObject.AllowConfirmPurchaseOrder;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['EmployeePermissionsClass'] = EmployeePermissionsClass;
    
      /**
      * CommerceProperty entity class.
      */
      export class CommercePropertyClass implements Entities.CommerceProperty {
      public Key: string;
      public Value: Entities.CommercePropertyValue;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Key = 
                odataObject.Key;
              
        if (odataObject.Value == null) {
            this.Value = undefined;
        } else if (odataObject.Value['@odata.type'] == null) {
            this.Value = new CommercePropertyValueClass(odataObject.Value);
        } else {
            var className: string = odataObject.Value['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.Value = new entityDictionary[className](odataObject.Value)
            }
        }

      
      }
      }
      entityDictionary['CommercePropertyClass'] = CommercePropertyClass;
    
      /**
      * CommercePropertyValue entity class.
      */
      export class CommercePropertyValueClass implements Entities.CommercePropertyValue {
      public BooleanValue: boolean;
      public ByteValue: number;
      public DateTimeOffsetValue: Date;
      public DecimalValue: number;
      public IntegerValue: number;
      public LongValue: number;
      public StringValue: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.BooleanValue = 
                odataObject.BooleanValue;
              
            this.ByteValue = 
                (odataObject.ByteValue != null) ? parseInt(odataObject.ByteValue, 10) : undefined;
              
            this.DateTimeOffsetValue = 
                (odataObject.DateTimeOffsetValue instanceof Date) ? odataObject.DateTimeOffsetValue
                : (odataObject.DateTimeOffsetValue != null) ? jsonLightReadStringPropertyValue(odataObject.DateTimeOffsetValue, "Edm.DateTimeOffset", false) : undefined;
              
            this.DecimalValue = 
                (odataObject.DecimalValue != null) ? parseFloat(odataObject.DecimalValue) : undefined;
              
            this.IntegerValue = 
                odataObject.IntegerValue;
              
            this.LongValue = 
                (odataObject.LongValue != null) ? parseInt(odataObject.LongValue, 10) : undefined;
              
            this.StringValue = 
                odataObject.StringValue;
              
      }
      }
      entityDictionary['CommercePropertyValueClass'] = CommercePropertyValueClass;
    
      /**
      * CountryRegionInfo entity class.
      */
      export class CountryRegionInfoClass implements Entities.CountryRegionInfo {
      public CountryRegionId: string;
      public ShortName: string;
      public LongName: string;
      public ISOCode: string;
      public TimeZone: number;
      public LanguageId: string;
      public AddressFormatLines: Entities.AddressFormattingInfo[];
      public AddressFormatId: string;
      public AddressFormatName: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.CountryRegionId = 
                odataObject.CountryRegionId;
              
            this.ShortName = 
                odataObject.ShortName;
              
            this.LongName = 
                odataObject.LongName;
              
            this.ISOCode = 
                odataObject.ISOCode;
              
            this.TimeZone = 
                odataObject.TimeZone;
              
            this.LanguageId = 
                odataObject.LanguageId;
              
        this.AddressFormatLines = undefined;
        if (odataObject.AddressFormatLines) {
        this.AddressFormatLines = [];
        for (var i = 0; i < odataObject.AddressFormatLines.length; i++) {
        if (odataObject.AddressFormatLines[i] != null) {
        if (odataObject.AddressFormatLines[i]['@odata.type'] != null) {
          var className: string = odataObject.AddressFormatLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.AddressFormatLines[i] = new entityDictionary[className](odataObject.AddressFormatLines[i])
          }
        } else {
            this.AddressFormatLines[i] = new AddressFormattingInfoClass(odataObject.AddressFormatLines[i]);
        }
                    } else {
        this.AddressFormatLines[i] = undefined;
        }
        }
        }
      
            this.AddressFormatId = 
                odataObject.AddressFormatId;
              
            this.AddressFormatName = 
                odataObject.AddressFormatName;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CountryRegionInfoClass'] = CountryRegionInfoClass;
    
      /**
      * CreditMemo entity class.
      */
      export class CreditMemoClass implements Entities.CreditMemo {
      public Id: string;
      public Balance: number;
      public CurrencyCode: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Id = 
                odataObject.Id;
              
            this.Balance = 
                (odataObject.Balance != null) ? parseFloat(odataObject.Balance) : undefined;
              
            this.CurrencyCode = 
                odataObject.CurrencyCode;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CreditMemoClass'] = CreditMemoClass;
    
      /**
      * Currency entity class.
      */
      export class CurrencyClass implements Entities.Currency {
      public CurrencyCode: string;
      public CurrencySymbol: string;
      public RoundOffPrice: number;
      public RoundOffSales: number;
      public RoundOffTypePrice: number;
      public RoundOffTypeSales: number;
      public NumberOfDecimals: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.CurrencyCode = 
                odataObject.CurrencyCode;
              
            this.CurrencySymbol = 
                odataObject.CurrencySymbol;
              
            this.RoundOffPrice = 
                (odataObject.RoundOffPrice != null) ? parseFloat(odataObject.RoundOffPrice) : undefined;
              
            this.RoundOffSales = 
                (odataObject.RoundOffSales != null) ? parseFloat(odataObject.RoundOffSales) : undefined;
              
            this.RoundOffTypePrice = 
                odataObject.RoundOffTypePrice;
              
            this.RoundOffTypeSales = 
                odataObject.RoundOffTypeSales;
              
            this.NumberOfDecimals = 
                odataObject.NumberOfDecimals;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CurrencyClass'] = CurrencyClass;
    
      /**
      * CurrencyAmount entity class.
      */
      export class CurrencyAmountClass implements Entities.CurrencyAmount {
      public CurrencyCode: string;
      public CurrencySymbol: string;
      public ConvertedAmount: number;
      public RoundedConvertedAmount: number;
      public ExchangeRate: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.CurrencyCode = 
                odataObject.CurrencyCode;
              
            this.CurrencySymbol = 
                odataObject.CurrencySymbol;
              
            this.ConvertedAmount = 
                (odataObject.ConvertedAmount != null) ? parseFloat(odataObject.ConvertedAmount) : undefined;
              
            this.RoundedConvertedAmount = 
                (odataObject.RoundedConvertedAmount != null) ? parseFloat(odataObject.RoundedConvertedAmount) : undefined;
              
            this.ExchangeRate = 
                (odataObject.ExchangeRate != null) ? parseFloat(odataObject.ExchangeRate) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CurrencyAmountClass'] = CurrencyAmountClass;
    
      /**
      * ScaleUnitConfiguration entity class.
      */
      export class ScaleUnitConfigurationClass implements Entities.ScaleUnitConfiguration {
      public Id: string;
      public Name: string;
      public Region: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Id = 
                odataObject.Id;
              
            this.Name = 
                odataObject.Name;
              
            this.Region = 
                odataObject.Region;
              
      }
      }
      entityDictionary['ScaleUnitConfigurationClass'] = ScaleUnitConfigurationClass;
    
      /**
      * EnvironmentConfiguration entity class.
      */
      export class EnvironmentConfigurationClass implements Entities.EnvironmentConfiguration {
      public EnvironmentId: string;
      public EnvironmentName: string;
      public TenantId: string;
      public ClientAppInsightsInstrumentationKey: string;
      public HardwareStationAppInsightsInstrumentationKey: string;
      public WindowsPhonePosAppInsightsInstrumentationKey: string;
      public BaseVersion: string;
      public ScaleUnit: Entities.ScaleUnitConfiguration;
      public AADAuthority: string;
      public FrameworkName: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.EnvironmentId = 
                odataObject.EnvironmentId;
              
            this.EnvironmentName = 
                odataObject.EnvironmentName;
              
            this.TenantId = 
                odataObject.TenantId;
              
            this.ClientAppInsightsInstrumentationKey = 
                odataObject.ClientAppInsightsInstrumentationKey;
              
            this.HardwareStationAppInsightsInstrumentationKey = 
                odataObject.HardwareStationAppInsightsInstrumentationKey;
              
            this.WindowsPhonePosAppInsightsInstrumentationKey = 
                odataObject.WindowsPhonePosAppInsightsInstrumentationKey;
              
            this.BaseVersion = 
                odataObject.BaseVersion;
              
        if (odataObject.ScaleUnit == null) {
            this.ScaleUnit = undefined;
        } else if (odataObject.ScaleUnit['@odata.type'] == null) {
            this.ScaleUnit = new ScaleUnitConfigurationClass(odataObject.ScaleUnit);
        } else {
            var className: string = odataObject.ScaleUnit['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.ScaleUnit = new entityDictionary[className](odataObject.ScaleUnit)
            }
        }

      
            this.AADAuthority = 
                odataObject.AADAuthority;
              
            this.FrameworkName = 
                odataObject.FrameworkName;
              
      }
      }
      entityDictionary['EnvironmentConfigurationClass'] = EnvironmentConfigurationClass;
    
      /**
      * OrderManagementConfiguration entity class.
      */
      export class OrderManagementConfigurationClass implements Entities.OrderManagementConfiguration {
      public EnableContactEditPromptForAsyncOrderCancellation: boolean;
      public SkipSalesInvoiceSelectionDuringReturn: boolean;
      public OrderAttributeForTIN: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.EnableContactEditPromptForAsyncOrderCancellation = 
                odataObject.EnableContactEditPromptForAsyncOrderCancellation;
              
            this.SkipSalesInvoiceSelectionDuringReturn = 
                odataObject.SkipSalesInvoiceSelectionDuringReturn;
              
            this.OrderAttributeForTIN = 
                (odataObject.OrderAttributeForTIN != null) ? parseInt(odataObject.OrderAttributeForTIN, 10) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['OrderManagementConfigurationClass'] = OrderManagementConfigurationClass;
    
      /**
      * DeviceConfiguration entity class.
      */
      export class DeviceConfigurationClass implements Entities.DeviceConfiguration {
      public UseInMemoryDeviceDataStorage: boolean;
      public AllowItemsAggregation: boolean;
      public AggregateItemsForPrinting: boolean;
      public AggregatePayments: boolean;
      public AlwaysExpandTransactionScreenLineDetails: boolean;
      public AuditEnabled: boolean;
      public DiscountAtTotal: string;
      public ProductDiscount: string;
      public ManuallyCalculateComplexDiscounts: boolean;
      public DisplaySecondaryTotalCurrency: boolean;
      public EndOfTransaction: string;
      public ItemNotOnFile: string;
      public LimitStaffListToStore: boolean;
      public LineItemTaxChange: string;
      public MarkUp: string;
      public MaximumPrice: number;
      public MaximumQuantity: number;
      public MinimumPasswordLength: number;
      public MustKeyInPriceIfZero: boolean;
      public NfcEContingencyModeEnabled: string;
      public NfcEVoided: string;
      public FunctionalityProfileDescription: string;
      public OverridePrice: string;
      public PrintVoidTransactionReceipts: boolean;
      public PrintReceiptsOnCardDecline: boolean;
      public PrintXZReportsOnTerminal: boolean;
      public PrintTenderDetailsOnXReport: boolean;
      public ProfileId: string;
      public RefundSale: string;
      public ReturnProduct: string;
      public SalesPerson: string;
      public SecondaryTotalCurrency: string;
      public SelectHardwareStationOnTendering: boolean;
      public SelectHardwareStationAtStartOfTransaction: boolean;
      public SerialNumber: string;
      public ShowStaffListAtLogOn: boolean;
      public StaffBarcodeLogOn: boolean;
      public StaffBarcodeLogOnRequiresPassword: boolean;
      public StaffCardLogOn: boolean;
      public StaffCardLogOnRequiresPassword: boolean;
      public StartOfTransaction: string;
      public EnableTimeRegistration: boolean;
      public TenderDeclaration: string;
      public TransactionTaxChange: string;
      public VoidItem: string;
      public VoidPayment: string;
      public SalesModeDefaultsAsCustomerOrder: boolean;
      public CreateOfflineCustomerOrders: boolean;
      public CreateAsyncCustomers: boolean;
      public EnableAxCustomerSearch: boolean;
      public VoidTransaction: string;
      public CultureName: string;
      public SystemLegalTermsUrl: string;
      public SystemPrivacyStatementUrl: string;
      public HideTrainingMode: boolean;
      public StorePhone: string;
      public StatementMethod: string;
      public StoreNumber: string;
      public ChannelId: number;
      public StoreTaxGroup: string;
      public TaxIdNumber: string;
      public TaxOverrideGroup: number;
      public TenderDeclarationCalculation: number;
      public UseCustomerBasedTax: boolean;
      public UseDestinationBasedTax: boolean;
      public AutoLogOffTimeoutInMinutes: number;
      public AutoExitMethodValue: number;
      public CustomerDisplayText1: string;
      public CustomerDisplayText2: string;
      public EFTStoreId: number;
      public EFTTerminalId: string;
      public ExitAfterEachTransaction: boolean;
      public HardwareProfile: string;
      public Placement: string;
      public TerminalDescription: string;
      public OpenDrawer: string;
      public OpenDrawerAtLogOnLogOff: boolean;
      public PrintTaxRefundChecks: boolean;
      public StandAlone: boolean;
      public TerminalId: string;
      public TerminalStatement: boolean;
      public VisualProfile: string;
      public Currency: string;
      public InventLocationId: string;
      public IncludeKitComponents: boolean;
      public AccentColor: number;
      public HeaderColor: number;
      public Theme: string;
      public FontScheme: number;
      public ShowAppBarLabel: boolean;
      public LogOnBackgroundPictureAsBase64: string;
      public LogOnBackgroundPicturePortraitAsBase64: string;
      public BackgroundPictureAsBase64: string;
      public LogonBackgroundPictureId: number;
      public LogonBackgroundPictureIdCompact: number;
      public LogonBackgroundPictureIdPortrait: number;
      public LogonBackgroundPictureIdCompactPortrait: number;
      public BackgroundPictureId: number;
      public RequireAmountDeclaration: boolean;
      public DecimalNotRequiredForMinorCurrencyUnit: boolean;
      public MaxTransactionSearchResults: number;
      public OfflineProfileId: number;
      public NewOfflineDatabaseCheckInterval: number;
      public OfflineTransactionUploadInterval: number;
      public ReconnectToOnlineInterval: number;
      public SwitchToOfflineTimeout: number;
      public OfflineSwitchApiPerformanceThresholdInSeconds: number;
      public ReconnectFromPerformanceInducedOfflineIntervalInSeconds: number;
      public RetentionDays: number;
      public HardwareConfigurations: Entities.HardwareConfigurations;
      public CreateRecording: boolean;
      public PlaybackRecording: boolean;
      public ShouldUseDefaultCommisionSalesGroup: boolean;
      public AskForSalesRepresentative: number;
      public IsSalesRepRequired: boolean;
      public ProhibitMixingSalesAndReturns: boolean;
      public ApplyDiscountsToPriceKeyedIn: boolean;
      public ApplyDiscountsToPriceOverrides: boolean;
      public IsProductSuggestionsEnabled: boolean;
      public ProductSearchType: string;
      public TerminalRecId: number;
      public DeviceRecId: number;
      public DenominationsToDisplayValue: number;
      public PromptOnSeamlessOffline: boolean;
      public RetryCountToOffline: number;
      public EnableAdvancedOfflineSwitching: boolean;
      public SystemHealthCheckInterval: number;
      public RejectOrderFulfillment: string;
      public ManuallyCalculateTaxes: boolean;
      public EmployeeLogonTypeValue: number;
      public StartAmountCalculation: number;
      public BankDropCalculation: number;
      public GenerateLoyaltyCardNumber: boolean;
      public FiscalRegistrationProcessId: string;
      public IncludeSalesOrderInvoices: boolean;
      public IncludeFreeTextInvoices: boolean;
      public IncludeProjectInvoices: boolean;
      public IncludeCreditNoteInvoices: boolean;
      public DisplayTaxExemptInLineDetails: boolean;
      public TaxExemptReceiptIndicator: string;
      public ManuallyCalculateCharges: boolean;
      public ChargeOverrideReasonCode: string;
      public ShiftReconcilation: string;
      public CustomerAccountFloorLimit: number;
      public VoidSuspendedTransactionsOnCloseShift: boolean;
      public ShowDateTime: boolean;
      public UseAdvancedCashManagement: boolean;
      public DefaultCustomerSearchModeValue: number;
      public AllowManualOfflineBeforeSignIn: boolean;
      public UseFinancialReconciliationInStore: boolean;
      public RequirePaymentForFulfillmentValue: number;
      public CardNotPresentProcessingConfigurationValue: number;
      public EnableQuickPayShortcuts: boolean;
      public ChangeDueBehaviorValue: number;
      public AutoSynchronizeDeviceNumberSequences: boolean;
      public SelfCheckout: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.UseInMemoryDeviceDataStorage = 
                odataObject.UseInMemoryDeviceDataStorage;
              
            this.AllowItemsAggregation = 
                odataObject.AllowItemsAggregation;
              
            this.AggregateItemsForPrinting = 
                odataObject.AggregateItemsForPrinting;
              
            this.AggregatePayments = 
                odataObject.AggregatePayments;
              
            this.AlwaysExpandTransactionScreenLineDetails = 
                odataObject.AlwaysExpandTransactionScreenLineDetails;
              
            this.AuditEnabled = 
                odataObject.AuditEnabled;
              
            this.DiscountAtTotal = 
                odataObject.DiscountAtTotal;
              
            this.ProductDiscount = 
                odataObject.ProductDiscount;
              
            this.ManuallyCalculateComplexDiscounts = 
                odataObject.ManuallyCalculateComplexDiscounts;
              
            this.DisplaySecondaryTotalCurrency = 
                odataObject.DisplaySecondaryTotalCurrency;
              
            this.EndOfTransaction = 
                odataObject.EndOfTransaction;
              
            this.ItemNotOnFile = 
                odataObject.ItemNotOnFile;
              
            this.LimitStaffListToStore = 
                odataObject.LimitStaffListToStore;
              
            this.LineItemTaxChange = 
                odataObject.LineItemTaxChange;
              
            this.MarkUp = 
                odataObject.MarkUp;
              
            this.MaximumPrice = 
                (odataObject.MaximumPrice != null) ? parseFloat(odataObject.MaximumPrice) : undefined;
              
            this.MaximumQuantity = 
                (odataObject.MaximumQuantity != null) ? parseFloat(odataObject.MaximumQuantity) : undefined;
              
            this.MinimumPasswordLength = 
                odataObject.MinimumPasswordLength;
              
            this.MustKeyInPriceIfZero = 
                odataObject.MustKeyInPriceIfZero;
              
            this.NfcEContingencyModeEnabled = 
                odataObject.NfcEContingencyModeEnabled;
              
            this.NfcEVoided = 
                odataObject.NfcEVoided;
              
            this.FunctionalityProfileDescription = 
                odataObject.FunctionalityProfileDescription;
              
            this.OverridePrice = 
                odataObject.OverridePrice;
              
            this.PrintVoidTransactionReceipts = 
                odataObject.PrintVoidTransactionReceipts;
              
            this.PrintReceiptsOnCardDecline = 
                odataObject.PrintReceiptsOnCardDecline;
              
            this.PrintXZReportsOnTerminal = 
                odataObject.PrintXZReportsOnTerminal;
              
            this.PrintTenderDetailsOnXReport = 
                odataObject.PrintTenderDetailsOnXReport;
              
            this.ProfileId = 
                odataObject.ProfileId;
              
            this.RefundSale = 
                odataObject.RefundSale;
              
            this.ReturnProduct = 
                odataObject.ReturnProduct;
              
            this.SalesPerson = 
                odataObject.SalesPerson;
              
            this.SecondaryTotalCurrency = 
                odataObject.SecondaryTotalCurrency;
              
            this.SelectHardwareStationOnTendering = 
                odataObject.SelectHardwareStationOnTendering;
              
            this.SelectHardwareStationAtStartOfTransaction = 
                odataObject.SelectHardwareStationAtStartOfTransaction;
              
            this.SerialNumber = 
                odataObject.SerialNumber;
              
            this.ShowStaffListAtLogOn = 
                odataObject.ShowStaffListAtLogOn;
              
            this.StaffBarcodeLogOn = 
                odataObject.StaffBarcodeLogOn;
              
            this.StaffBarcodeLogOnRequiresPassword = 
                odataObject.StaffBarcodeLogOnRequiresPassword;
              
            this.StaffCardLogOn = 
                odataObject.StaffCardLogOn;
              
            this.StaffCardLogOnRequiresPassword = 
                odataObject.StaffCardLogOnRequiresPassword;
              
            this.StartOfTransaction = 
                odataObject.StartOfTransaction;
              
            this.EnableTimeRegistration = 
                odataObject.EnableTimeRegistration;
              
            this.TenderDeclaration = 
                odataObject.TenderDeclaration;
              
            this.TransactionTaxChange = 
                odataObject.TransactionTaxChange;
              
            this.VoidItem = 
                odataObject.VoidItem;
              
            this.VoidPayment = 
                odataObject.VoidPayment;
              
            this.SalesModeDefaultsAsCustomerOrder = 
                odataObject.SalesModeDefaultsAsCustomerOrder;
              
            this.CreateOfflineCustomerOrders = 
                odataObject.CreateOfflineCustomerOrders;
              
            this.CreateAsyncCustomers = 
                odataObject.CreateAsyncCustomers;
              
            this.EnableAxCustomerSearch = 
                odataObject.EnableAxCustomerSearch;
              
            this.VoidTransaction = 
                odataObject.VoidTransaction;
              
            this.CultureName = 
                odataObject.CultureName;
              
            this.SystemLegalTermsUrl = 
                odataObject.SystemLegalTermsUrl;
              
            this.SystemPrivacyStatementUrl = 
                odataObject.SystemPrivacyStatementUrl;
              
            this.HideTrainingMode = 
                odataObject.HideTrainingMode;
              
            this.StorePhone = 
                odataObject.StorePhone;
              
            this.StatementMethod = 
                odataObject.StatementMethod;
              
            this.StoreNumber = 
                odataObject.StoreNumber;
              
            this.ChannelId = 
                (odataObject.ChannelId != null) ? parseInt(odataObject.ChannelId, 10) : undefined;
              
            this.StoreTaxGroup = 
                odataObject.StoreTaxGroup;
              
            this.TaxIdNumber = 
                odataObject.TaxIdNumber;
              
            this.TaxOverrideGroup = 
                (odataObject.TaxOverrideGroup != null) ? parseInt(odataObject.TaxOverrideGroup, 10) : undefined;
              
            this.TenderDeclarationCalculation = 
                odataObject.TenderDeclarationCalculation;
              
            this.UseCustomerBasedTax = 
                odataObject.UseCustomerBasedTax;
              
            this.UseDestinationBasedTax = 
                odataObject.UseDestinationBasedTax;
              
            this.AutoLogOffTimeoutInMinutes = 
                odataObject.AutoLogOffTimeoutInMinutes;
              
            this.AutoExitMethodValue = 
                odataObject.AutoExitMethodValue;
              
            this.CustomerDisplayText1 = 
                odataObject.CustomerDisplayText1;
              
            this.CustomerDisplayText2 = 
                odataObject.CustomerDisplayText2;
              
            this.EFTStoreId = 
                (odataObject.EFTStoreId != null) ? parseInt(odataObject.EFTStoreId, 10) : undefined;
              
            this.EFTTerminalId = 
                odataObject.EFTTerminalId;
              
            this.ExitAfterEachTransaction = 
                odataObject.ExitAfterEachTransaction;
              
            this.HardwareProfile = 
                odataObject.HardwareProfile;
              
            this.Placement = 
                odataObject.Placement;
              
            this.TerminalDescription = 
                odataObject.TerminalDescription;
              
            this.OpenDrawer = 
                odataObject.OpenDrawer;
              
            this.OpenDrawerAtLogOnLogOff = 
                odataObject.OpenDrawerAtLogOnLogOff;
              
            this.PrintTaxRefundChecks = 
                odataObject.PrintTaxRefundChecks;
              
            this.StandAlone = 
                odataObject.StandAlone;
              
            this.TerminalId = 
                odataObject.TerminalId;
              
            this.TerminalStatement = 
                odataObject.TerminalStatement;
              
            this.VisualProfile = 
                odataObject.VisualProfile;
              
            this.Currency = 
                odataObject.Currency;
              
            this.InventLocationId = 
                odataObject.InventLocationId;
              
            this.IncludeKitComponents = 
                odataObject.IncludeKitComponents;
              
            this.AccentColor = 
                odataObject.AccentColor;
              
            this.HeaderColor = 
                odataObject.HeaderColor;
              
            this.Theme = 
                odataObject.Theme;
              
            this.FontScheme = 
                odataObject.FontScheme;
              
            this.ShowAppBarLabel = 
                odataObject.ShowAppBarLabel;
              
            this.LogOnBackgroundPictureAsBase64 = 
                odataObject.LogOnBackgroundPictureAsBase64;
              
            this.LogOnBackgroundPicturePortraitAsBase64 = 
                odataObject.LogOnBackgroundPicturePortraitAsBase64;
              
            this.BackgroundPictureAsBase64 = 
                odataObject.BackgroundPictureAsBase64;
              
            this.LogonBackgroundPictureId = 
                (odataObject.LogonBackgroundPictureId != null) ? parseInt(odataObject.LogonBackgroundPictureId, 10) : undefined;
              
            this.LogonBackgroundPictureIdCompact = 
                (odataObject.LogonBackgroundPictureIdCompact != null) ? parseInt(odataObject.LogonBackgroundPictureIdCompact, 10) : undefined;
              
            this.LogonBackgroundPictureIdPortrait = 
                (odataObject.LogonBackgroundPictureIdPortrait != null) ? parseInt(odataObject.LogonBackgroundPictureIdPortrait, 10) : undefined;
              
            this.LogonBackgroundPictureIdCompactPortrait = 
                (odataObject.LogonBackgroundPictureIdCompactPortrait != null) ? parseInt(odataObject.LogonBackgroundPictureIdCompactPortrait, 10) : undefined;
              
            this.BackgroundPictureId = 
                (odataObject.BackgroundPictureId != null) ? parseInt(odataObject.BackgroundPictureId, 10) : undefined;
              
            this.RequireAmountDeclaration = 
                odataObject.RequireAmountDeclaration;
              
            this.DecimalNotRequiredForMinorCurrencyUnit = 
                odataObject.DecimalNotRequiredForMinorCurrencyUnit;
              
            this.MaxTransactionSearchResults = 
                odataObject.MaxTransactionSearchResults;
              
            this.OfflineProfileId = 
                (odataObject.OfflineProfileId != null) ? parseInt(odataObject.OfflineProfileId, 10) : undefined;
              
            this.NewOfflineDatabaseCheckInterval = 
                odataObject.NewOfflineDatabaseCheckInterval;
              
            this.OfflineTransactionUploadInterval = 
                odataObject.OfflineTransactionUploadInterval;
              
            this.ReconnectToOnlineInterval = 
                odataObject.ReconnectToOnlineInterval;
              
            this.SwitchToOfflineTimeout = 
                odataObject.SwitchToOfflineTimeout;
              
            this.OfflineSwitchApiPerformanceThresholdInSeconds = 
                odataObject.OfflineSwitchApiPerformanceThresholdInSeconds;
              
            this.ReconnectFromPerformanceInducedOfflineIntervalInSeconds = 
                odataObject.ReconnectFromPerformanceInducedOfflineIntervalInSeconds;
              
            this.RetentionDays = 
                odataObject.RetentionDays;
              
        if (odataObject.HardwareConfigurations == null) {
            this.HardwareConfigurations = undefined;
        } else if (odataObject.HardwareConfigurations['@odata.type'] == null) {
            this.HardwareConfigurations = new HardwareConfigurationsClass(odataObject.HardwareConfigurations);
        } else {
            var className: string = odataObject.HardwareConfigurations['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.HardwareConfigurations = new entityDictionary[className](odataObject.HardwareConfigurations)
            }
        }

      
            this.CreateRecording = 
                odataObject.CreateRecording;
              
            this.PlaybackRecording = 
                odataObject.PlaybackRecording;
              
            this.ShouldUseDefaultCommisionSalesGroup = 
                odataObject.ShouldUseDefaultCommisionSalesGroup;
              
            this.AskForSalesRepresentative = 
                odataObject.AskForSalesRepresentative;
              
            this.IsSalesRepRequired = 
                odataObject.IsSalesRepRequired;
              
            this.ProhibitMixingSalesAndReturns = 
                odataObject.ProhibitMixingSalesAndReturns;
              
            this.ApplyDiscountsToPriceKeyedIn = 
                odataObject.ApplyDiscountsToPriceKeyedIn;
              
            this.ApplyDiscountsToPriceOverrides = 
                odataObject.ApplyDiscountsToPriceOverrides;
              
            this.IsProductSuggestionsEnabled = 
                odataObject.IsProductSuggestionsEnabled;
              
            this.ProductSearchType = 
                odataObject.ProductSearchType;
              
            this.TerminalRecId = 
                (odataObject.TerminalRecId != null) ? parseInt(odataObject.TerminalRecId, 10) : undefined;
              
            this.DeviceRecId = 
                (odataObject.DeviceRecId != null) ? parseInt(odataObject.DeviceRecId, 10) : undefined;
              
            this.DenominationsToDisplayValue = 
                odataObject.DenominationsToDisplayValue;
              
            this.PromptOnSeamlessOffline = 
                odataObject.PromptOnSeamlessOffline;
              
            this.RetryCountToOffline = 
                odataObject.RetryCountToOffline;
              
            this.EnableAdvancedOfflineSwitching = 
                odataObject.EnableAdvancedOfflineSwitching;
              
            this.SystemHealthCheckInterval = 
                odataObject.SystemHealthCheckInterval;
              
            this.RejectOrderFulfillment = 
                odataObject.RejectOrderFulfillment;
              
            this.ManuallyCalculateTaxes = 
                odataObject.ManuallyCalculateTaxes;
              
            this.EmployeeLogonTypeValue = 
                odataObject.EmployeeLogonTypeValue;
              
            this.StartAmountCalculation = 
                odataObject.StartAmountCalculation;
              
            this.BankDropCalculation = 
                odataObject.BankDropCalculation;
              
            this.GenerateLoyaltyCardNumber = 
                odataObject.GenerateLoyaltyCardNumber;
              
            this.FiscalRegistrationProcessId = 
                odataObject.FiscalRegistrationProcessId;
              
            this.IncludeSalesOrderInvoices = 
                odataObject.IncludeSalesOrderInvoices;
              
            this.IncludeFreeTextInvoices = 
                odataObject.IncludeFreeTextInvoices;
              
            this.IncludeProjectInvoices = 
                odataObject.IncludeProjectInvoices;
              
            this.IncludeCreditNoteInvoices = 
                odataObject.IncludeCreditNoteInvoices;
              
            this.DisplayTaxExemptInLineDetails = 
                odataObject.DisplayTaxExemptInLineDetails;
              
            this.TaxExemptReceiptIndicator = 
                odataObject.TaxExemptReceiptIndicator;
              
            this.ManuallyCalculateCharges = 
                odataObject.ManuallyCalculateCharges;
              
            this.ChargeOverrideReasonCode = 
                odataObject.ChargeOverrideReasonCode;
              
            this.ShiftReconcilation = 
                odataObject.ShiftReconcilation;
              
            this.CustomerAccountFloorLimit = 
                (odataObject.CustomerAccountFloorLimit != null) ? parseFloat(odataObject.CustomerAccountFloorLimit) : undefined;
              
            this.VoidSuspendedTransactionsOnCloseShift = 
                odataObject.VoidSuspendedTransactionsOnCloseShift;
              
            this.ShowDateTime = 
                odataObject.ShowDateTime;
              
            this.UseAdvancedCashManagement = 
                odataObject.UseAdvancedCashManagement;
              
            this.DefaultCustomerSearchModeValue = 
                odataObject.DefaultCustomerSearchModeValue;
              
            this.AllowManualOfflineBeforeSignIn = 
                odataObject.AllowManualOfflineBeforeSignIn;
              
            this.UseFinancialReconciliationInStore = 
                odataObject.UseFinancialReconciliationInStore;
              
            this.RequirePaymentForFulfillmentValue = 
                odataObject.RequirePaymentForFulfillmentValue;
              
            this.CardNotPresentProcessingConfigurationValue = 
                odataObject.CardNotPresentProcessingConfigurationValue;
              
            this.EnableQuickPayShortcuts = 
                odataObject.EnableQuickPayShortcuts;
              
            this.ChangeDueBehaviorValue = 
                odataObject.ChangeDueBehaviorValue;
              
            this.AutoSynchronizeDeviceNumberSequences = 
                odataObject.AutoSynchronizeDeviceNumberSequences;
              
            this.SelfCheckout = 
                odataObject.SelfCheckout;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['DeviceConfigurationClass'] = DeviceConfigurationClass;
    
      /**
      * VisualProfile entity class.
      */
      export class VisualProfileClass implements Entities.VisualProfile {
      public ChannelId: number;
      public TerminalId: string;
      public AccentColor: number;
      public HeaderColor: number;
      public Theme: string;
      public FontSchemeTypeValue: number;
      public IsAppBarLabelVisible: boolean;
      public IsDateTimeVisible: boolean;
      public LogOnLayoutTypeValue: number;
      public LogOnKeyboardTypeValue: number;
      public LogonBackgroundPictureId: number;
      public CompactLogonBackgroundPictureId: number;
      public PortraitLogonBackgroundPictureId: number;
      public CompactPortraitLogonBackgroundPictureId: number;
      public BackgroundPictureId: number;
      public LogOnLogoPictureId: number;
      public IsProductIdVisibleOnCartLine: boolean;
      public DefaultSearchResultDisplayViewTypeValue: number;
      public BypassItemAddedDialog: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ChannelId = 
                (odataObject.ChannelId != null) ? parseInt(odataObject.ChannelId, 10) : undefined;
              
            this.TerminalId = 
                odataObject.TerminalId;
              
            this.AccentColor = 
                odataObject.AccentColor;
              
            this.HeaderColor = 
                odataObject.HeaderColor;
              
            this.Theme = 
                odataObject.Theme;
              
            this.FontSchemeTypeValue = 
                odataObject.FontSchemeTypeValue;
              
            this.IsAppBarLabelVisible = 
                odataObject.IsAppBarLabelVisible;
              
            this.IsDateTimeVisible = 
                odataObject.IsDateTimeVisible;
              
            this.LogOnLayoutTypeValue = 
                odataObject.LogOnLayoutTypeValue;
              
            this.LogOnKeyboardTypeValue = 
                odataObject.LogOnKeyboardTypeValue;
              
            this.LogonBackgroundPictureId = 
                (odataObject.LogonBackgroundPictureId != null) ? parseInt(odataObject.LogonBackgroundPictureId, 10) : undefined;
              
            this.CompactLogonBackgroundPictureId = 
                (odataObject.CompactLogonBackgroundPictureId != null) ? parseInt(odataObject.CompactLogonBackgroundPictureId, 10) : undefined;
              
            this.PortraitLogonBackgroundPictureId = 
                (odataObject.PortraitLogonBackgroundPictureId != null) ? parseInt(odataObject.PortraitLogonBackgroundPictureId, 10) : undefined;
              
            this.CompactPortraitLogonBackgroundPictureId = 
                (odataObject.CompactPortraitLogonBackgroundPictureId != null) ? parseInt(odataObject.CompactPortraitLogonBackgroundPictureId, 10) : undefined;
              
            this.BackgroundPictureId = 
                (odataObject.BackgroundPictureId != null) ? parseInt(odataObject.BackgroundPictureId, 10) : undefined;
              
            this.LogOnLogoPictureId = 
                (odataObject.LogOnLogoPictureId != null) ? parseInt(odataObject.LogOnLogoPictureId, 10) : undefined;
              
            this.IsProductIdVisibleOnCartLine = 
                odataObject.IsProductIdVisibleOnCartLine;
              
            this.DefaultSearchResultDisplayViewTypeValue = 
                odataObject.DefaultSearchResultDisplayViewTypeValue;
              
            this.BypassItemAddedDialog = 
                odataObject.BypassItemAddedDialog;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['VisualProfileClass'] = VisualProfileClass;
    
      /**
      * OperationalInsightsConfiguration entity class.
      */
      export class OperationalInsightsConfigurationClass implements Entities.OperationalInsightsConfiguration {
      public InstrumentationKey: string;
      public EnvironmentId: string;
      public IsEnabled: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.InstrumentationKey = 
                odataObject.InstrumentationKey;
              
            this.EnvironmentId = 
                odataObject.EnvironmentId;
              
            this.IsEnabled = 
                odataObject.IsEnabled;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['OperationalInsightsConfigurationClass'] = OperationalInsightsConfigurationClass;
    
      /**
      * PaymentConfiguration entity class.
      */
      export class PaymentConfigurationClass implements Entities.PaymentConfiguration {
      public AlwaysStoreCardTokenOnOrderBalanceAuthorization: boolean;
      public MinOrderProcessingDaysForStoringCardToken: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.AlwaysStoreCardTokenOnOrderBalanceAuthorization = 
                odataObject.AlwaysStoreCardTokenOnOrderBalanceAuthorization;
              
            this.MinOrderProcessingDaysForStoringCardToken = 
                odataObject.MinOrderProcessingDaysForStoringCardToken;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['PaymentConfigurationClass'] = PaymentConfigurationClass;
    
      /**
      * Device entity class.
      */
      export class DeviceClass implements Entities.Device {
      public ChannelId: number;
      public RecordId: number;
      public DeviceNumber: string;
      public DeviceId: string;
      public Description: string;
      public DeviceType: number;
      public TerminalRecordId: number;
      public TerminalId: string;
      public ChannelName: string;
      public UseInMemoryDeviceDataStorage: boolean;
      public AllowMassActivation: boolean;
      public TokenIssueTime: Date;
      public ActivationStatusValue: number;
      public Token: string;
      public CountryRegionIsoCode: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ChannelId = 
                (odataObject.ChannelId != null) ? parseInt(odataObject.ChannelId, 10) : undefined;
              
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.DeviceNumber = 
                odataObject.DeviceNumber;
              
            this.DeviceId = 
                odataObject.DeviceId;
              
            this.Description = 
                odataObject.Description;
              
            this.DeviceType = 
                odataObject.DeviceType;
              
            this.TerminalRecordId = 
                (odataObject.TerminalRecordId != null) ? parseInt(odataObject.TerminalRecordId, 10) : undefined;
              
            this.TerminalId = 
                odataObject.TerminalId;
              
            this.ChannelName = 
                odataObject.ChannelName;
              
            this.UseInMemoryDeviceDataStorage = 
                odataObject.UseInMemoryDeviceDataStorage;
              
            this.AllowMassActivation = 
                odataObject.AllowMassActivation;
              
            this.TokenIssueTime = 
                (odataObject.TokenIssueTime instanceof Date) ? odataObject.TokenIssueTime
                : (odataObject.TokenIssueTime != null) ? jsonLightReadStringPropertyValue(odataObject.TokenIssueTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.ActivationStatusValue = 
                odataObject.ActivationStatusValue;
              
            this.Token = 
                odataObject.Token;
              
            this.CountryRegionIsoCode = 
                odataObject.CountryRegionIsoCode;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['DeviceClass'] = DeviceClass;
    
      /**
      * DiscountCode entity class.
      */
      export class DiscountCodeClass implements Entities.DiscountCode {
      public RecordId: number;
      public Barcode: string;
      public Code: string;
      public ConcurrencyMode: number;
      public Description: string;
      public Disclaimer: string;
      public IsDiscountCodeRequired: boolean;
      public Name: string;
      public OfferId: string;
      public IsEnabled: boolean;
      public ValidFrom: Date;
      public ValidTo: Date;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.Barcode = 
                odataObject.Barcode;
              
            this.Code = 
                odataObject.Code;
              
            this.ConcurrencyMode = 
                odataObject.ConcurrencyMode;
              
            this.Description = 
                odataObject.Description;
              
            this.Disclaimer = 
                odataObject.Disclaimer;
              
            this.IsDiscountCodeRequired = 
                odataObject.IsDiscountCodeRequired;
              
            this.Name = 
                odataObject.Name;
              
            this.OfferId = 
                odataObject.OfferId;
              
            this.IsEnabled = 
                odataObject.IsEnabled;
              
            this.ValidFrom = 
                (odataObject.ValidFrom instanceof Date) ? odataObject.ValidFrom
                : (odataObject.ValidFrom != null) ? jsonLightReadStringPropertyValue(odataObject.ValidFrom, "Edm.DateTimeOffset", false) : undefined;
              
            this.ValidTo = 
                (odataObject.ValidTo instanceof Date) ? odataObject.ValidTo
                : (odataObject.ValidTo != null) ? jsonLightReadStringPropertyValue(odataObject.ValidTo, "Edm.DateTimeOffset", false) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['DiscountCodeClass'] = DiscountCodeClass;
    
      /**
      * DistrictInfo entity class.
      */
      export class DistrictInfoClass implements Entities.DistrictInfo {
      public Name: string;
      public Description: string;
      public CountryRegionId: string;
      public StateId: string;
      public CountyId: string;
      public CityName: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Name = 
                odataObject.Name;
              
            this.Description = 
                odataObject.Description;
              
            this.CountryRegionId = 
                odataObject.CountryRegionId;
              
            this.StateId = 
                odataObject.StateId;
              
            this.CountyId = 
                odataObject.CountyId;
              
            this.CityName = 
                odataObject.CityName;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['DistrictInfoClass'] = DistrictInfoClass;
    
      /**
      * HardwareProfile entity class.
      */
      export class HardwareProfileClass implements Entities.HardwareProfile {
      public RecordId: number;
      public LineDisplayDelayForLinkedItems: number;
      public LineDisplayBalanceText: string;
      public LineDisplayBinaryConversion: boolean;
      public LineDisplayCharacterSet: number;
      public LineDisplayClosedLine1: string;
      public LineDisplayClosedLine2: string;
      public LineDisplayDeviceDescription: string;
      public LineDisplayDeviceTypeValue: number;
      public LineDisplayDeviceName: string;
      public LineDisplayDisplayLinkedItem: boolean;
      public LineDisplayDisplayTerminalClosed: boolean;
      public LineDisplayTotalText: string;
      public LineDisplayKeepConnectionOpen: boolean;
      public DualDisplayActive: boolean;
      public DualDisplayWebBrowserUrl: string;
      public DualDisplayImageRotatorInterval: number;
      public DualDisplayImageRotatorPath: string;
      public DualDisplayReceiptWidthPercentage: number;
      public DualDisplayDisplayType: string;
      public EftTypeId: number;
      public EftCompanyId: string;
      public EftConfiguration: number;
      public EftPaymentConnectorName: string;
      public EftData: string;
      public EftDescription: string;
      public EftMerchantId: string;
      public EftPassword: string;
      public EftServerName: string;
      public EftServerPort: string;
      public EftUserId: string;
      public EftTestMode: boolean;
      public MsrEndTrack1: string;
      public MsrEndTrack2: string;
      public FiscalPrinterManagementReportPAFIdentification: string;
      public FiscalPrinterManagementReportConfigParameter: string;
      public FiscalPrinterManagementReportTenderType: string;
      public FiscalPrinterManagementReportGiftCard: string;
      public FiscalRegisterDeviceTypeValue: number;
      public FiscalRegisterConfiguration: string;
      public FiscalRegisterDeviceDescription: string;
      public FiscalRegisterDeviceName: string;
      public KeyboardMappingId: string;
      public KeyLockDeviceTypeValue: number;
      public KeyLockDeviceDescription: string;
      public KeyLockDeviceName: string;
      public MsrDeviceTypeValue: number;
      public MsrDeviceDescription: string;
      public MsrDeviceName: string;
      public MsrMake: string;
      public MsrModel: string;
      public MsrSeparator: string;
      public MsrStartTrack: string;
      public PinPadDeviceTypeValue: number;
      public PinPadDeviceName: string;
      public PinPadMake: string;
      public PinPadModel: string;
      public PinPadDeviceDescription: string;
      public ProfileDescription: string;
      public ProfileId: string;
      public RFIDDeviceTypeValue: number;
      public RFIDDeviceName: string;
      public RFIDDeviceDescription: string;
      public ScaleDeviceTypeValue: number;
      public ScaleDeviceName: string;
      public ScaleDeviceDescription: string;
      public ScaleManualInputAllowed: boolean;
      public ScaleTimeoutInSeconds: number;
      public SignatureCaptureDeviceTypeValue: number;
      public SignatureCaptureDeviceName: string;
      public SignatureCaptureMake: string;
      public SignatureCaptureModel: string;
      public SignatureCaptureDeviceDescription: string;
      public SignatureCaptureFormName: string;
      public PinPadAllowNfcPayment: boolean;
      public Printers: Entities.HardwareProfilePrinter[];
      public Scanners: Entities.HardwareProfileScanner[];
      public FiscalIntegrationTechnicalProfiles: Entities.FiscalIntegrationTechnicalProfile[];
      public CashDrawers: Entities.HardwareProfileCashDrawer[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.LineDisplayDelayForLinkedItems = 
                odataObject.LineDisplayDelayForLinkedItems;
              
            this.LineDisplayBalanceText = 
                odataObject.LineDisplayBalanceText;
              
            this.LineDisplayBinaryConversion = 
                odataObject.LineDisplayBinaryConversion;
              
            this.LineDisplayCharacterSet = 
                odataObject.LineDisplayCharacterSet;
              
            this.LineDisplayClosedLine1 = 
                odataObject.LineDisplayClosedLine1;
              
            this.LineDisplayClosedLine2 = 
                odataObject.LineDisplayClosedLine2;
              
            this.LineDisplayDeviceDescription = 
                odataObject.LineDisplayDeviceDescription;
              
            this.LineDisplayDeviceTypeValue = 
                odataObject.LineDisplayDeviceTypeValue;
              
            this.LineDisplayDeviceName = 
                odataObject.LineDisplayDeviceName;
              
            this.LineDisplayDisplayLinkedItem = 
                odataObject.LineDisplayDisplayLinkedItem;
              
            this.LineDisplayDisplayTerminalClosed = 
                odataObject.LineDisplayDisplayTerminalClosed;
              
            this.LineDisplayTotalText = 
                odataObject.LineDisplayTotalText;
              
            this.LineDisplayKeepConnectionOpen = 
                odataObject.LineDisplayKeepConnectionOpen;
              
            this.DualDisplayActive = 
                odataObject.DualDisplayActive;
              
            this.DualDisplayWebBrowserUrl = 
                odataObject.DualDisplayWebBrowserUrl;
              
            this.DualDisplayImageRotatorInterval = 
                odataObject.DualDisplayImageRotatorInterval;
              
            this.DualDisplayImageRotatorPath = 
                odataObject.DualDisplayImageRotatorPath;
              
            this.DualDisplayReceiptWidthPercentage = 
                (odataObject.DualDisplayReceiptWidthPercentage != null) ? parseFloat(odataObject.DualDisplayReceiptWidthPercentage) : undefined;
              
            this.DualDisplayDisplayType = 
                odataObject.DualDisplayDisplayType;
              
            this.EftTypeId = 
                odataObject.EftTypeId;
              
            this.EftCompanyId = 
                odataObject.EftCompanyId;
              
            this.EftConfiguration = 
                odataObject.EftConfiguration;
              
            this.EftPaymentConnectorName = 
                odataObject.EftPaymentConnectorName;
              
            this.EftData = 
                odataObject.EftData;
              
            this.EftDescription = 
                odataObject.EftDescription;
              
            this.EftMerchantId = 
                odataObject.EftMerchantId;
              
            this.EftPassword = 
                odataObject.EftPassword;
              
            this.EftServerName = 
                odataObject.EftServerName;
              
            this.EftServerPort = 
                odataObject.EftServerPort;
              
            this.EftUserId = 
                odataObject.EftUserId;
              
            this.EftTestMode = 
                odataObject.EftTestMode;
              
            this.MsrEndTrack1 = 
                odataObject.MsrEndTrack1;
              
            this.MsrEndTrack2 = 
                odataObject.MsrEndTrack2;
              
            this.FiscalPrinterManagementReportPAFIdentification = 
                odataObject.FiscalPrinterManagementReportPAFIdentification;
              
            this.FiscalPrinterManagementReportConfigParameter = 
                odataObject.FiscalPrinterManagementReportConfigParameter;
              
            this.FiscalPrinterManagementReportTenderType = 
                odataObject.FiscalPrinterManagementReportTenderType;
              
            this.FiscalPrinterManagementReportGiftCard = 
                odataObject.FiscalPrinterManagementReportGiftCard;
              
            this.FiscalRegisterDeviceTypeValue = 
                odataObject.FiscalRegisterDeviceTypeValue;
              
            this.FiscalRegisterConfiguration = 
                odataObject.FiscalRegisterConfiguration;
              
            this.FiscalRegisterDeviceDescription = 
                odataObject.FiscalRegisterDeviceDescription;
              
            this.FiscalRegisterDeviceName = 
                odataObject.FiscalRegisterDeviceName;
              
            this.KeyboardMappingId = 
                odataObject.KeyboardMappingId;
              
            this.KeyLockDeviceTypeValue = 
                odataObject.KeyLockDeviceTypeValue;
              
            this.KeyLockDeviceDescription = 
                odataObject.KeyLockDeviceDescription;
              
            this.KeyLockDeviceName = 
                odataObject.KeyLockDeviceName;
              
            this.MsrDeviceTypeValue = 
                odataObject.MsrDeviceTypeValue;
              
            this.MsrDeviceDescription = 
                odataObject.MsrDeviceDescription;
              
            this.MsrDeviceName = 
                odataObject.MsrDeviceName;
              
            this.MsrMake = 
                odataObject.MsrMake;
              
            this.MsrModel = 
                odataObject.MsrModel;
              
            this.MsrSeparator = 
                odataObject.MsrSeparator;
              
            this.MsrStartTrack = 
                odataObject.MsrStartTrack;
              
            this.PinPadDeviceTypeValue = 
                odataObject.PinPadDeviceTypeValue;
              
            this.PinPadDeviceName = 
                odataObject.PinPadDeviceName;
              
            this.PinPadMake = 
                odataObject.PinPadMake;
              
            this.PinPadModel = 
                odataObject.PinPadModel;
              
            this.PinPadDeviceDescription = 
                odataObject.PinPadDeviceDescription;
              
            this.ProfileDescription = 
                odataObject.ProfileDescription;
              
            this.ProfileId = 
                odataObject.ProfileId;
              
            this.RFIDDeviceTypeValue = 
                odataObject.RFIDDeviceTypeValue;
              
            this.RFIDDeviceName = 
                odataObject.RFIDDeviceName;
              
            this.RFIDDeviceDescription = 
                odataObject.RFIDDeviceDescription;
              
            this.ScaleDeviceTypeValue = 
                odataObject.ScaleDeviceTypeValue;
              
            this.ScaleDeviceName = 
                odataObject.ScaleDeviceName;
              
            this.ScaleDeviceDescription = 
                odataObject.ScaleDeviceDescription;
              
            this.ScaleManualInputAllowed = 
                odataObject.ScaleManualInputAllowed;
              
            this.ScaleTimeoutInSeconds = 
                odataObject.ScaleTimeoutInSeconds;
              
            this.SignatureCaptureDeviceTypeValue = 
                odataObject.SignatureCaptureDeviceTypeValue;
              
            this.SignatureCaptureDeviceName = 
                odataObject.SignatureCaptureDeviceName;
              
            this.SignatureCaptureMake = 
                odataObject.SignatureCaptureMake;
              
            this.SignatureCaptureModel = 
                odataObject.SignatureCaptureModel;
              
            this.SignatureCaptureDeviceDescription = 
                odataObject.SignatureCaptureDeviceDescription;
              
            this.SignatureCaptureFormName = 
                odataObject.SignatureCaptureFormName;
              
            this.PinPadAllowNfcPayment = 
                odataObject.PinPadAllowNfcPayment;
              
        this.Printers = undefined;
        if (odataObject.Printers) {
        this.Printers = [];
        for (var i = 0; i < odataObject.Printers.length; i++) {
        if (odataObject.Printers[i] != null) {
        if (odataObject.Printers[i]['@odata.type'] != null) {
          var className: string = odataObject.Printers[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Printers[i] = new entityDictionary[className](odataObject.Printers[i])
          }
        } else {
            this.Printers[i] = new HardwareProfilePrinterClass(odataObject.Printers[i]);
        }
                    } else {
        this.Printers[i] = undefined;
        }
        }
        }
      
        this.Scanners = undefined;
        if (odataObject.Scanners) {
        this.Scanners = [];
        for (var i = 0; i < odataObject.Scanners.length; i++) {
        if (odataObject.Scanners[i] != null) {
        if (odataObject.Scanners[i]['@odata.type'] != null) {
          var className: string = odataObject.Scanners[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Scanners[i] = new entityDictionary[className](odataObject.Scanners[i])
          }
        } else {
            this.Scanners[i] = new HardwareProfileScannerClass(odataObject.Scanners[i]);
        }
                    } else {
        this.Scanners[i] = undefined;
        }
        }
        }
      
        this.FiscalIntegrationTechnicalProfiles = undefined;
        if (odataObject.FiscalIntegrationTechnicalProfiles) {
        this.FiscalIntegrationTechnicalProfiles = [];
        for (var i = 0; i < odataObject.FiscalIntegrationTechnicalProfiles.length; i++) {
        if (odataObject.FiscalIntegrationTechnicalProfiles[i] != null) {
        if (odataObject.FiscalIntegrationTechnicalProfiles[i]['@odata.type'] != null) {
          var className: string = odataObject.FiscalIntegrationTechnicalProfiles[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.FiscalIntegrationTechnicalProfiles[i] = new entityDictionary[className](odataObject.FiscalIntegrationTechnicalProfiles[i])
          }
        } else {
            this.FiscalIntegrationTechnicalProfiles[i] = new FiscalIntegrationTechnicalProfileClass(odataObject.FiscalIntegrationTechnicalProfiles[i]);
        }
                    } else {
        this.FiscalIntegrationTechnicalProfiles[i] = undefined;
        }
        }
        }
      
        this.CashDrawers = undefined;
        if (odataObject.CashDrawers) {
        this.CashDrawers = [];
        for (var i = 0; i < odataObject.CashDrawers.length; i++) {
        if (odataObject.CashDrawers[i] != null) {
        if (odataObject.CashDrawers[i]['@odata.type'] != null) {
          var className: string = odataObject.CashDrawers[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.CashDrawers[i] = new entityDictionary[className](odataObject.CashDrawers[i])
          }
        } else {
            this.CashDrawers[i] = new HardwareProfileCashDrawerClass(odataObject.CashDrawers[i]);
        }
                    } else {
        this.CashDrawers[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['HardwareProfileClass'] = HardwareProfileClass;
    
      /**
      * SupportedLanguage entity class.
      */
      export class SupportedLanguageClass implements Entities.SupportedLanguage {
      public LanguageId: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.LanguageId = 
                odataObject.LanguageId;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['SupportedLanguageClass'] = SupportedLanguageClass;
    
      /**
      * PaymentCard entity class.
      */
      export class PaymentCardClass implements Entities.PaymentCard {
      public UseShippingAddress: boolean;
      public CardNumber: string;
      public CCID: string;
      public Track1: string;
      public Track2: string;
      public Track3: string;
      public VoiceAuthorizationCode: string;
      public EncryptedPin: string;
      public AdditionalSecurityData: string;
      public IsSwipe: boolean;
      public Phone: string;
      public Country: string;
      public House: string;
      public Address1: string;
      public Address2: string;
      public City: string;
      public State: string;
      public Zip: string;
      public NameOnCard: string;
      public CardTypeId: string;
      public ExpirationMonth: number;
      public ExpirationYear: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.UseShippingAddress = 
                odataObject.UseShippingAddress;
              
            this.CardNumber = 
                odataObject.CardNumber;
              
            this.CCID = 
                odataObject.CCID;
              
            this.Track1 = 
                odataObject.Track1;
              
            this.Track2 = 
                odataObject.Track2;
              
            this.Track3 = 
                odataObject.Track3;
              
            this.VoiceAuthorizationCode = 
                odataObject.VoiceAuthorizationCode;
              
            this.EncryptedPin = 
                odataObject.EncryptedPin;
              
            this.AdditionalSecurityData = 
                odataObject.AdditionalSecurityData;
              
            this.IsSwipe = 
                odataObject.IsSwipe;
              
            this.Phone = 
                odataObject.Phone;
              
            this.Country = 
                odataObject.Country;
              
            this.House = 
                odataObject.House;
              
            this.Address1 = 
                odataObject.Address1;
              
            this.Address2 = 
                odataObject.Address2;
              
            this.City = 
                odataObject.City;
              
            this.State = 
                odataObject.State;
              
            this.Zip = 
                odataObject.Zip;
              
            this.NameOnCard = 
                odataObject.NameOnCard;
              
            this.CardTypeId = 
                odataObject.CardTypeId;
              
            this.ExpirationMonth = 
                odataObject.ExpirationMonth;
              
            this.ExpirationYear = 
                odataObject.ExpirationYear;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['PaymentCardClass'] = PaymentCardClass;
    
      /**
      * CardPaymentAcceptSettings entity class.
      */
      export class CardPaymentAcceptSettingsClass implements Entities.CardPaymentAcceptSettings {
      public HostPageOrigin: string;
      public AdaptorPath: string;
      public CardTokenizationEnabled: boolean;
      public CardPaymentEnabled: boolean;
      public PaymentAmount: number;
      public HideBillingAddress: boolean;
      public TenderTypeId: string;
      public Locale: string;
      public RequireShippingAddress: boolean;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.HostPageOrigin = 
                odataObject.HostPageOrigin;
              
            this.AdaptorPath = 
                odataObject.AdaptorPath;
              
            this.CardTokenizationEnabled = 
                odataObject.CardTokenizationEnabled;
              
            this.CardPaymentEnabled = 
                odataObject.CardPaymentEnabled;
              
            this.PaymentAmount = 
                (odataObject.PaymentAmount != null) ? parseFloat(odataObject.PaymentAmount) : undefined;
              
            this.HideBillingAddress = 
                odataObject.HideBillingAddress;
              
            this.TenderTypeId = 
                odataObject.TenderTypeId;
              
            this.Locale = 
                odataObject.Locale;
              
            this.RequireShippingAddress = 
                odataObject.RequireShippingAddress;
              
      }
      }
      entityDictionary['CardPaymentAcceptSettingsClass'] = CardPaymentAcceptSettingsClass;
    
      /**
      * CardPaymentAcceptPoint entity class.
      */
      export class CardPaymentAcceptPointClass implements Entities.CardPaymentAcceptPoint {
      public AcceptPageUrl: string;
      public AcceptPageSubmitUrl: string;
      public MessageOrigin: string;
      public AcceptPageContent: string;
      public PaymentConnectorId: string;
      public NotReloadAcceptPageContentWhenAmountChanged: boolean;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.AcceptPageUrl = 
                odataObject.AcceptPageUrl;
              
            this.AcceptPageSubmitUrl = 
                odataObject.AcceptPageSubmitUrl;
              
            this.MessageOrigin = 
                odataObject.MessageOrigin;
              
            this.AcceptPageContent = 
                odataObject.AcceptPageContent;
              
            this.PaymentConnectorId = 
                odataObject.PaymentConnectorId;
              
            this.NotReloadAcceptPageContentWhenAmountChanged = 
                odataObject.NotReloadAcceptPageContentWhenAmountChanged;
              
      }
      }
      entityDictionary['CardPaymentAcceptPointClass'] = CardPaymentAcceptPointClass;
    
      /**
      * CardPaymentAcceptResult entity class.
      */
      export class CardPaymentAcceptResultClass implements Entities.CardPaymentAcceptResult {
      public TenderLine: Entities.TenderLine;
      public TokenizedPaymentCard: Entities.TokenizedPaymentCard;
      public PaymentSdkErrors: Entities.PaymentError[];
      public AdditionalContext: string;
      public AccessCode: string;
      public Settings: Entities.RetrievePaymentResultSettings;
      public Email: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        if (odataObject.TenderLine == null) {
            this.TenderLine = undefined;
        } else if (odataObject.TenderLine['@odata.type'] == null) {
            this.TenderLine = new TenderLineClass(odataObject.TenderLine);
        } else {
            var className: string = odataObject.TenderLine['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.TenderLine = new entityDictionary[className](odataObject.TenderLine)
            }
        }

      
        if (odataObject.TokenizedPaymentCard == null) {
            this.TokenizedPaymentCard = undefined;
        } else if (odataObject.TokenizedPaymentCard['@odata.type'] == null) {
            this.TokenizedPaymentCard = new TokenizedPaymentCardClass(odataObject.TokenizedPaymentCard);
        } else {
            var className: string = odataObject.TokenizedPaymentCard['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.TokenizedPaymentCard = new entityDictionary[className](odataObject.TokenizedPaymentCard)
            }
        }

      
        this.PaymentSdkErrors = undefined;
        if (odataObject.PaymentSdkErrors) {
        this.PaymentSdkErrors = [];
        for (var i = 0; i < odataObject.PaymentSdkErrors.length; i++) {
        if (odataObject.PaymentSdkErrors[i] != null) {
        if (odataObject.PaymentSdkErrors[i]['@odata.type'] != null) {
          var className: string = odataObject.PaymentSdkErrors[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.PaymentSdkErrors[i] = new entityDictionary[className](odataObject.PaymentSdkErrors[i])
          }
        } else {
            this.PaymentSdkErrors[i] = new PaymentErrorClass(odataObject.PaymentSdkErrors[i]);
        }
                    } else {
        this.PaymentSdkErrors[i] = undefined;
        }
        }
        }
      
            this.AdditionalContext = 
                odataObject.AdditionalContext;
              
            this.AccessCode = 
                odataObject.AccessCode;
              
        if (odataObject.Settings == null) {
            this.Settings = undefined;
        } else if (odataObject.Settings['@odata.type'] == null) {
            this.Settings = new RetrievePaymentResultSettingsClass(odataObject.Settings);
        } else {
            var className: string = odataObject.Settings['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.Settings = new entityDictionary[className](odataObject.Settings)
            }
        }

      
            this.Email = 
                odataObject.Email;
              
      }
      }
      entityDictionary['CardPaymentAcceptResultClass'] = CardPaymentAcceptResultClass;
    
      /**
      * ReasonCodeLine entity class.
      */
      export class ReasonCodeLineClass implements Entities.ReasonCodeLine {
      public LineId: string;
      public Amount: number;
      public Information: string;
      public InformationAmount: number;
      public InputTypeValue: number;
      public ItemTender: string;
      public LineNumber: number;
      public LineTypeValue: number;
      public ParentLineId: string;
      public ReasonCodeId: string;
      public StatementCode: string;
      public SubReasonCodeId: string;
      public SourceCode: string;
      public SourceCode2: string;
      public SourceCode3: string;
      public TransactionId: string;
      public CreatedDateTime: Date;
      public FiscalTransactionParentGuid: string;
      public IsChanged: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.LineId = 
                odataObject.LineId;
              
            this.Amount = 
                (odataObject.Amount != null) ? parseFloat(odataObject.Amount) : undefined;
              
            this.Information = 
                odataObject.Information;
              
            this.InformationAmount = 
                (odataObject.InformationAmount != null) ? parseFloat(odataObject.InformationAmount) : undefined;
              
            this.InputTypeValue = 
                odataObject.InputTypeValue;
              
            this.ItemTender = 
                odataObject.ItemTender;
              
            this.LineNumber = 
                (odataObject.LineNumber != null) ? parseFloat(odataObject.LineNumber) : undefined;
              
            this.LineTypeValue = 
                odataObject.LineTypeValue;
              
            this.ParentLineId = 
                odataObject.ParentLineId;
              
            this.ReasonCodeId = 
                odataObject.ReasonCodeId;
              
            this.StatementCode = 
                odataObject.StatementCode;
              
            this.SubReasonCodeId = 
                odataObject.SubReasonCodeId;
              
            this.SourceCode = 
                odataObject.SourceCode;
              
            this.SourceCode2 = 
                odataObject.SourceCode2;
              
            this.SourceCode3 = 
                odataObject.SourceCode3;
              
            this.TransactionId = 
                odataObject.TransactionId;
              
            this.CreatedDateTime = 
                (odataObject.CreatedDateTime instanceof Date) ? odataObject.CreatedDateTime
                : (odataObject.CreatedDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.CreatedDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.FiscalTransactionParentGuid = 
                odataObject.FiscalTransactionParentGuid;
              
            this.IsChanged = 
                odataObject.IsChanged;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ReasonCodeLineClass'] = ReasonCodeLineClass;
    
      /**
      * ReasonSubCode entity class.
      */
      export class ReasonSubCodeClass implements Entities.ReasonSubCode {
      public TriggerCode: string;
      public AmountPercent: number;
      public ReasonCodeId: string;
      public SubCodeId: string;
      public Description: string;
      public TriggerFunctionTypeValue: number;
      public ProductId: number;
      public PriceTypeValue: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.TriggerCode = 
                odataObject.TriggerCode;
              
            this.AmountPercent = 
                (odataObject.AmountPercent != null) ? parseFloat(odataObject.AmountPercent) : undefined;
              
            this.ReasonCodeId = 
                odataObject.ReasonCodeId;
              
            this.SubCodeId = 
                odataObject.SubCodeId;
              
            this.Description = 
                odataObject.Description;
              
            this.TriggerFunctionTypeValue = 
                odataObject.TriggerFunctionTypeValue;
              
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
            this.PriceTypeValue = 
                odataObject.PriceTypeValue;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ReasonSubCodeClass'] = ReasonSubCodeClass;
    
      /**
      * Receipt entity class.
      */
      export class ReceiptClass implements Entities.Receipt {
      public TransactionId: string;
      public Header: string;
      public Body: string;
      public Footer: string;
      public Width: number;
      public ReceiptId: string;
      public LayoutId: string;
      public ReceiptTitle: string;
      public ReceiptTypeValue: number;
      public ReceiptTypeStrValue: string;
      public Printers: Entities.Printer[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.TransactionId = 
                odataObject.TransactionId;
              
            this.Header = 
                odataObject.Header;
              
            this.Body = 
                odataObject.Body;
              
            this.Footer = 
                odataObject.Footer;
              
            this.Width = 
                odataObject.Width;
              
            this.ReceiptId = 
                odataObject.ReceiptId;
              
            this.LayoutId = 
                odataObject.LayoutId;
              
            this.ReceiptTitle = 
                odataObject.ReceiptTitle;
              
            this.ReceiptTypeValue = 
                odataObject.ReceiptTypeValue;
              
            this.ReceiptTypeStrValue = 
                odataObject.ReceiptTypeStrValue;
              
        this.Printers = undefined;
        if (odataObject.Printers) {
        this.Printers = [];
        for (var i = 0; i < odataObject.Printers.length; i++) {
        if (odataObject.Printers[i] != null) {
        if (odataObject.Printers[i]['@odata.type'] != null) {
          var className: string = odataObject.Printers[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Printers[i] = new entityDictionary[className](odataObject.Printers[i])
          }
        } else {
            this.Printers[i] = new PrinterClass(odataObject.Printers[i]);
        }
                    } else {
        this.Printers[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ReceiptClass'] = ReceiptClass;
    
      /**
      * ReceiptMask entity class.
      */
      export class ReceiptMaskClass implements Entities.ReceiptMask {
      public FunctionalityProfileId: string;
      public IsIndependent: boolean;
      public ReceiptTransactionTypeValue: number;
      public RecordId: number;
      public Mask: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.FunctionalityProfileId = 
                odataObject.FunctionalityProfileId;
              
            this.IsIndependent = 
                odataObject.IsIndependent;
              
            this.ReceiptTransactionTypeValue = 
                odataObject.ReceiptTransactionTypeValue;
              
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.Mask = 
                odataObject.Mask;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ReceiptMaskClass'] = ReceiptMaskClass;
    
      /**
      * SalesLine entity class.
      */
      export class SalesLineClass implements Entities.SalesLine {
      public StaffId: string;
      public LineId: string;
      public Description: string;
      public OriginLineId: string;
      public TaxOverrideCode: string;
      public IsPriceKeyedIn: boolean;
      public IsTaxOverideCodeTaxExempt: boolean;
      public ProductId: number;
      public Barcode: string;
      public BarcodeEmbeddedPrice: number;
      public PriceInBarcode: boolean;
      public EntryMethodTypeValue: number;
      public MasterProductId: number;
      public ListingId: number;
      public IsPriceOverridden: boolean;
      public OriginalPrice: number;
      public TotalAmount: number;
      public NetAmountWithoutTax: number;
      public DiscountAmount: number;
      public DiscountAmountWithoutTax: number;
      public NetPrice: number;
      public TotalDiscount: number;
      public TotalPercentageDiscount: number;
      public LineDiscount: number;
      public PeriodicDiscount: number;
      public LineManualDiscountPercentage: number;
      public LineManualDiscountAmount: number;
      public ShippingAddress: Entities.Address;
      public DeliveryMode: string;
      public Comment: string;
      public RequestedDeliveryDate: Date;
      public ConfirmedShipDate: Date;
      public PickupTimeslotStartDateTime: Date;
      public PickupTimeslotEndDateTime: Date;
      public InventoryLocationId: string;
      public InventorySiteId: string;
      public WarehouseLocation: string;
      public InventoryStatusId: string;
      public LicensePlate: string;
      public InventoryDimensionId: string;
      public InventoryTransactionId: string;
      public ItemType: number;
      public ReservationId: string;
      public LineNumber: number;
      public ReturnQuantity: number;
      public QuantityRemained: number;
      public QuantityShipped: number;
      public LineVersion: number;
      public StatusValue: number;
      public SalesStatusValue: number;
      public ProductSourceValue: number;
      public IsGiftCardLine: boolean;
      public GiftCardId: string;
      public GiftCardCurrencyCode: string;
      public GiftCardOperationValue: number;
      public GiftCardTypeValue: number;
      public IsInvoiceLine: boolean;
      public InvoiceId: string;
      public InvoiceAmount: number;
      public IsInvoiceSettled: boolean;
      public InvoiceTypeValue: number;
      public InvoicedSalesId: string;
      public IsVoided: boolean;
      public IsPriceLocked: boolean;
      public ChargeLines: Entities.ChargeLine[];
      public BasePrice: number;
      public AgreementPrice: number;
      public AdjustedPrice: number;
      public IsWarrantyLine: boolean;
      public WarrantableTransactionId: string;
      public WarrantableSalesId: string;
      public WarrantableLineNumber: number;
      public WarrantableSerialNumber: string;
      public ReturnTransactionId: string;
      public ReturnLineNumber: number;
      public ReturnInventTransId: string;
      public ReturnStore: string;
      public ReturnTerminalId: string;
      public ReturnChannelId: number;
      public FulfillmentStoreId: string;
      public SalesDate: Date;
      public QuantityInvoiced: number;
      public QuantityOrdered: number;
      public QuantityCanceled: number;
      public SavedQuantity: number;
      public IsSavedDiscount: boolean;
      public RecordId: number;
      public SerialNumber: string;
      public BatchId: string;
      public DeliveryModeChargeAmount: number;
      public UnitOfMeasureSymbol: string;
      public CatalogId: number;
      public ElectronicDeliveryEmailAddress: string;
      public ElectronicDeliveryEmailContent: string;
      public LoyaltyDiscountAmount: number;
      public LoyaltyPercentageDiscount: number;
      public TenderDiscountAmount: number;
      public TenderDiscountPercentage: number;
      public GiftCardBalance: number;
      public IsCustomerAccountDeposit: boolean;
      public Blocked: boolean;
      public Found: boolean;
      public DateToActivateItem: Date;
      public LinePercentageDiscount: number;
      public PeriodicPercentageDiscount: number;
      public QuantityDiscounted: number;
      public UnitQuantity: number;
      public UnitOfMeasureConversion: Entities.UnitOfMeasureConversion;
      public DiscountLines: Entities.DiscountLine[];
      public PriceLines: Entities.PriceLine[];
      public AttainablePriceLines: Entities.PriceLine[];
      public PeriodicDiscountPossibilities: Entities.DiscountLine[];
      public ReasonCodeLines: Entities.ReasonCodeLine[];
      public ReturnLabelProperties: Entities.ReturnLabelContent;
      public LineMultilineDiscOnItem: string;
      public RelatedDiscountedLineIds: string[];
      public LinkedParentLineId: string;
      public LineMultilineDiscOnItemValue: number;
      public WasChanged: boolean;
      public OriginalSalesOrderUnitOfMeasure: string;
      public InventOrderUnitOfMeasure: string;
      public TrackingId: string;
      public IsLoyaltyDiscountApplied: boolean;
      public CommissionSalesGroup: string;
      public IsCommissionSalesGroupOverriddenByCustomer: boolean;
      public CommissionSalesGroupSetByCashier: string;
      public QuantityNotProcessed: number;
      public QuantityReserved: number;
      public QuantityPicked: number;
      public QuantityPacked: number;
      public AttributeValues: Entities.AttributeValueBase[];
      public ThirdPartyGiftCardInfo: Entities.ThirdPartyGiftCardInfo;
      public QuantityReturnable: number;
      public LoyaltyRewardPointLines: Entities.LoyaltyRewardPointLine[];
      public ReturnLoyaltyRewardPointLines: Entities.LoyaltyRewardPointLine[];
      public FulfillmentStatus: Entities.FulfillmentLineStatus;
      public FulfillmentStatusValue: number;
      public DetailedLineStatusValue: number;
      public DetailedLineStatus: Entities.DetailedLineStatus;
      public LastReturnProcessedTime: Date;
      public SalesAgreementPrice: number;
      public SalesAgreementLineRecordId: number;
      public IsSalesAgreementSkipped: boolean;
      public ItemId: string;
      public Quantity: number;
      public Price: number;
      public ItemTaxGroupId: string;
      public SalesTaxGroupId: string;
      public OriginalSalesTaxGroupId: string;
      public OriginalItemTaxGroupId: string;
      public TaxAmount: number;
      public SalesOrderUnitOfMeasure: string;
      public NetAmount: number;
      public NetAmountPerUnit: number;
      public GrossAmount: number;
      public TaxLines: Entities.TaxLine[];
      public TaxMeasures: Entities.TaxMeasure[];
      public TaxAmountExemptInclusive: number;
      public TaxAmountInclusive: number;
      public TaxAmountExclusive: number;
      public NetAmountWithAllInclusiveTax: number;
      public BeginDateTime: Date;
      public EndDateTime: Date;
      public TaxRatePercent: number;
      public IsReturnByReceipt: boolean;
      public ReturnLineTaxAmount: number;
      public ReturnTaxLines: Entities.TaxLine[];
      public TaxExemptPriceInclusiveReductionAmount: number;
      public TaxExemptPriceInclusiveOriginalPrice: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.StaffId = 
                odataObject.StaffId;
              
            this.LineId = 
                odataObject.LineId;
              
            this.Description = 
                odataObject.Description;
              
            this.OriginLineId = 
                odataObject.OriginLineId;
              
            this.TaxOverrideCode = 
                odataObject.TaxOverrideCode;
              
            this.IsPriceKeyedIn = 
                odataObject.IsPriceKeyedIn;
              
            this.IsTaxOverideCodeTaxExempt = 
                odataObject.IsTaxOverideCodeTaxExempt;
              
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
            this.Barcode = 
                odataObject.Barcode;
              
            this.BarcodeEmbeddedPrice = 
                (odataObject.BarcodeEmbeddedPrice != null) ? parseFloat(odataObject.BarcodeEmbeddedPrice) : undefined;
              
            this.PriceInBarcode = 
                odataObject.PriceInBarcode;
              
            this.EntryMethodTypeValue = 
                odataObject.EntryMethodTypeValue;
              
            this.MasterProductId = 
                (odataObject.MasterProductId != null) ? parseInt(odataObject.MasterProductId, 10) : undefined;
              
            this.ListingId = 
                (odataObject.ListingId != null) ? parseInt(odataObject.ListingId, 10) : undefined;
              
            this.IsPriceOverridden = 
                odataObject.IsPriceOverridden;
              
            this.OriginalPrice = 
                (odataObject.OriginalPrice != null) ? parseFloat(odataObject.OriginalPrice) : undefined;
              
            this.TotalAmount = 
                (odataObject.TotalAmount != null) ? parseFloat(odataObject.TotalAmount) : undefined;
              
            this.NetAmountWithoutTax = 
                (odataObject.NetAmountWithoutTax != null) ? parseFloat(odataObject.NetAmountWithoutTax) : undefined;
              
            this.DiscountAmount = 
                (odataObject.DiscountAmount != null) ? parseFloat(odataObject.DiscountAmount) : undefined;
              
            this.DiscountAmountWithoutTax = 
                (odataObject.DiscountAmountWithoutTax != null) ? parseFloat(odataObject.DiscountAmountWithoutTax) : undefined;
              
            this.NetPrice = 
                (odataObject.NetPrice != null) ? parseFloat(odataObject.NetPrice) : undefined;
              
            this.TotalDiscount = 
                (odataObject.TotalDiscount != null) ? parseFloat(odataObject.TotalDiscount) : undefined;
              
            this.TotalPercentageDiscount = 
                (odataObject.TotalPercentageDiscount != null) ? parseFloat(odataObject.TotalPercentageDiscount) : undefined;
              
            this.LineDiscount = 
                (odataObject.LineDiscount != null) ? parseFloat(odataObject.LineDiscount) : undefined;
              
            this.PeriodicDiscount = 
                (odataObject.PeriodicDiscount != null) ? parseFloat(odataObject.PeriodicDiscount) : undefined;
              
            this.LineManualDiscountPercentage = 
                (odataObject.LineManualDiscountPercentage != null) ? parseFloat(odataObject.LineManualDiscountPercentage) : undefined;
              
            this.LineManualDiscountAmount = 
                (odataObject.LineManualDiscountAmount != null) ? parseFloat(odataObject.LineManualDiscountAmount) : undefined;
              
        if (odataObject.ShippingAddress == null) {
            this.ShippingAddress = undefined;
        } else if (odataObject.ShippingAddress['@odata.type'] == null) {
            this.ShippingAddress = new AddressClass(odataObject.ShippingAddress);
        } else {
            var className: string = odataObject.ShippingAddress['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.ShippingAddress = new entityDictionary[className](odataObject.ShippingAddress)
            }
        }

      
            this.DeliveryMode = 
                odataObject.DeliveryMode;
              
            this.Comment = 
                odataObject.Comment;
              
            this.RequestedDeliveryDate = 
                (odataObject.RequestedDeliveryDate instanceof Date) ? odataObject.RequestedDeliveryDate
                : (odataObject.RequestedDeliveryDate != null) ? jsonLightReadStringPropertyValue(odataObject.RequestedDeliveryDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.ConfirmedShipDate = 
                (odataObject.ConfirmedShipDate instanceof Date) ? odataObject.ConfirmedShipDate
                : (odataObject.ConfirmedShipDate != null) ? jsonLightReadStringPropertyValue(odataObject.ConfirmedShipDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.PickupTimeslotStartDateTime = 
                (odataObject.PickupTimeslotStartDateTime instanceof Date) ? odataObject.PickupTimeslotStartDateTime
                : (odataObject.PickupTimeslotStartDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.PickupTimeslotStartDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.PickupTimeslotEndDateTime = 
                (odataObject.PickupTimeslotEndDateTime instanceof Date) ? odataObject.PickupTimeslotEndDateTime
                : (odataObject.PickupTimeslotEndDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.PickupTimeslotEndDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.InventoryLocationId = 
                odataObject.InventoryLocationId;
              
            this.InventorySiteId = 
                odataObject.InventorySiteId;
              
            this.WarehouseLocation = 
                odataObject.WarehouseLocation;
              
            this.InventoryStatusId = 
                odataObject.InventoryStatusId;
              
            this.LicensePlate = 
                odataObject.LicensePlate;
              
            this.InventoryDimensionId = 
                odataObject.InventoryDimensionId;
              
            this.InventoryTransactionId = 
                odataObject.InventoryTransactionId;
              
            this.ItemType = 
                odataObject.ItemType;
              
            this.ReservationId = 
                odataObject.ReservationId;
              
            this.LineNumber = 
                (odataObject.LineNumber != null) ? parseFloat(odataObject.LineNumber) : undefined;
              
            this.ReturnQuantity = 
                (odataObject.ReturnQuantity != null) ? parseFloat(odataObject.ReturnQuantity) : undefined;
              
            this.QuantityRemained = 
                (odataObject.QuantityRemained != null) ? parseFloat(odataObject.QuantityRemained) : undefined;
              
            this.QuantityShipped = 
                (odataObject.QuantityShipped != null) ? parseFloat(odataObject.QuantityShipped) : undefined;
              
            this.LineVersion = 
                odataObject.LineVersion;
              
            this.StatusValue = 
                odataObject.StatusValue;
              
            this.SalesStatusValue = 
                odataObject.SalesStatusValue;
              
            this.ProductSourceValue = 
                odataObject.ProductSourceValue;
              
            this.IsGiftCardLine = 
                odataObject.IsGiftCardLine;
              
            this.GiftCardId = 
                odataObject.GiftCardId;
              
            this.GiftCardCurrencyCode = 
                odataObject.GiftCardCurrencyCode;
              
            this.GiftCardOperationValue = 
                odataObject.GiftCardOperationValue;
              
            this.GiftCardTypeValue = 
                odataObject.GiftCardTypeValue;
              
            this.IsInvoiceLine = 
                odataObject.IsInvoiceLine;
              
            this.InvoiceId = 
                odataObject.InvoiceId;
              
            this.InvoiceAmount = 
                (odataObject.InvoiceAmount != null) ? parseFloat(odataObject.InvoiceAmount) : undefined;
              
            this.IsInvoiceSettled = 
                odataObject.IsInvoiceSettled;
              
            this.InvoiceTypeValue = 
                odataObject.InvoiceTypeValue;
              
            this.InvoicedSalesId = 
                odataObject.InvoicedSalesId;
              
            this.IsVoided = 
                odataObject.IsVoided;
              
            this.IsPriceLocked = 
                odataObject.IsPriceLocked;
              
        this.ChargeLines = undefined;
        if (odataObject.ChargeLines) {
        this.ChargeLines = [];
        for (var i = 0; i < odataObject.ChargeLines.length; i++) {
        if (odataObject.ChargeLines[i] != null) {
        if (odataObject.ChargeLines[i]['@odata.type'] != null) {
          var className: string = odataObject.ChargeLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ChargeLines[i] = new entityDictionary[className](odataObject.ChargeLines[i])
          }
        } else {
            this.ChargeLines[i] = new ChargeLineClass(odataObject.ChargeLines[i]);
        }
                    } else {
        this.ChargeLines[i] = undefined;
        }
        }
        }
      
            this.BasePrice = 
                (odataObject.BasePrice != null) ? parseFloat(odataObject.BasePrice) : undefined;
              
            this.AgreementPrice = 
                (odataObject.AgreementPrice != null) ? parseFloat(odataObject.AgreementPrice) : undefined;
              
            this.AdjustedPrice = 
                (odataObject.AdjustedPrice != null) ? parseFloat(odataObject.AdjustedPrice) : undefined;
              
            this.IsWarrantyLine = 
                odataObject.IsWarrantyLine;
              
            this.WarrantableTransactionId = 
                odataObject.WarrantableTransactionId;
              
            this.WarrantableSalesId = 
                odataObject.WarrantableSalesId;
              
            this.WarrantableLineNumber = 
                (odataObject.WarrantableLineNumber != null) ? parseFloat(odataObject.WarrantableLineNumber) : undefined;
              
            this.WarrantableSerialNumber = 
                odataObject.WarrantableSerialNumber;
              
            this.ReturnTransactionId = 
                odataObject.ReturnTransactionId;
              
            this.ReturnLineNumber = 
                (odataObject.ReturnLineNumber != null) ? parseFloat(odataObject.ReturnLineNumber) : undefined;
              
            this.ReturnInventTransId = 
                odataObject.ReturnInventTransId;
              
            this.ReturnStore = 
                odataObject.ReturnStore;
              
            this.ReturnTerminalId = 
                odataObject.ReturnTerminalId;
              
            this.ReturnChannelId = 
                (odataObject.ReturnChannelId != null) ? parseInt(odataObject.ReturnChannelId, 10) : undefined;
              
            this.FulfillmentStoreId = 
                odataObject.FulfillmentStoreId;
              
            this.SalesDate = 
                (odataObject.SalesDate instanceof Date) ? odataObject.SalesDate
                : (odataObject.SalesDate != null) ? jsonLightReadStringPropertyValue(odataObject.SalesDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.QuantityInvoiced = 
                (odataObject.QuantityInvoiced != null) ? parseFloat(odataObject.QuantityInvoiced) : undefined;
              
            this.QuantityOrdered = 
                (odataObject.QuantityOrdered != null) ? parseFloat(odataObject.QuantityOrdered) : undefined;
              
            this.QuantityCanceled = 
                (odataObject.QuantityCanceled != null) ? parseFloat(odataObject.QuantityCanceled) : undefined;
              
            this.SavedQuantity = 
                (odataObject.SavedQuantity != null) ? parseFloat(odataObject.SavedQuantity) : undefined;
              
            this.IsSavedDiscount = 
                odataObject.IsSavedDiscount;
              
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.SerialNumber = 
                odataObject.SerialNumber;
              
            this.BatchId = 
                odataObject.BatchId;
              
            this.DeliveryModeChargeAmount = 
                (odataObject.DeliveryModeChargeAmount != null) ? parseFloat(odataObject.DeliveryModeChargeAmount) : undefined;
              
            this.UnitOfMeasureSymbol = 
                odataObject.UnitOfMeasureSymbol;
              
            this.CatalogId = 
                (odataObject.CatalogId != null) ? parseInt(odataObject.CatalogId, 10) : undefined;
              
            this.ElectronicDeliveryEmailAddress = 
                odataObject.ElectronicDeliveryEmailAddress;
              
            this.ElectronicDeliveryEmailContent = 
                odataObject.ElectronicDeliveryEmailContent;
              
            this.LoyaltyDiscountAmount = 
                (odataObject.LoyaltyDiscountAmount != null) ? parseFloat(odataObject.LoyaltyDiscountAmount) : undefined;
              
            this.LoyaltyPercentageDiscount = 
                (odataObject.LoyaltyPercentageDiscount != null) ? parseFloat(odataObject.LoyaltyPercentageDiscount) : undefined;
              
            this.TenderDiscountAmount = 
                (odataObject.TenderDiscountAmount != null) ? parseFloat(odataObject.TenderDiscountAmount) : undefined;
              
            this.TenderDiscountPercentage = 
                (odataObject.TenderDiscountPercentage != null) ? parseFloat(odataObject.TenderDiscountPercentage) : undefined;
              
            this.GiftCardBalance = 
                (odataObject.GiftCardBalance != null) ? parseFloat(odataObject.GiftCardBalance) : undefined;
              
            this.IsCustomerAccountDeposit = 
                odataObject.IsCustomerAccountDeposit;
              
            this.Blocked = 
                odataObject.Blocked;
              
            this.Found = 
                odataObject.Found;
              
            this.DateToActivateItem = 
                (odataObject.DateToActivateItem instanceof Date) ? odataObject.DateToActivateItem
                : (odataObject.DateToActivateItem != null) ? jsonLightReadStringPropertyValue(odataObject.DateToActivateItem, "Edm.DateTimeOffset", false) : undefined;
              
            this.LinePercentageDiscount = 
                (odataObject.LinePercentageDiscount != null) ? parseFloat(odataObject.LinePercentageDiscount) : undefined;
              
            this.PeriodicPercentageDiscount = 
                (odataObject.PeriodicPercentageDiscount != null) ? parseFloat(odataObject.PeriodicPercentageDiscount) : undefined;
              
            this.QuantityDiscounted = 
                (odataObject.QuantityDiscounted != null) ? parseFloat(odataObject.QuantityDiscounted) : undefined;
              
            this.UnitQuantity = 
                (odataObject.UnitQuantity != null) ? parseFloat(odataObject.UnitQuantity) : undefined;
              
        if (odataObject.UnitOfMeasureConversion == null) {
            this.UnitOfMeasureConversion = undefined;
        } else if (odataObject.UnitOfMeasureConversion['@odata.type'] == null) {
            this.UnitOfMeasureConversion = new UnitOfMeasureConversionClass(odataObject.UnitOfMeasureConversion);
        } else {
            var className: string = odataObject.UnitOfMeasureConversion['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.UnitOfMeasureConversion = new entityDictionary[className](odataObject.UnitOfMeasureConversion)
            }
        }

      
        this.DiscountLines = undefined;
        if (odataObject.DiscountLines) {
        this.DiscountLines = [];
        for (var i = 0; i < odataObject.DiscountLines.length; i++) {
        if (odataObject.DiscountLines[i] != null) {
        if (odataObject.DiscountLines[i]['@odata.type'] != null) {
          var className: string = odataObject.DiscountLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.DiscountLines[i] = new entityDictionary[className](odataObject.DiscountLines[i])
          }
        } else {
            this.DiscountLines[i] = new DiscountLineClass(odataObject.DiscountLines[i]);
        }
                    } else {
        this.DiscountLines[i] = undefined;
        }
        }
        }
      
        this.PriceLines = undefined;
        if (odataObject.PriceLines) {
        this.PriceLines = [];
        for (var i = 0; i < odataObject.PriceLines.length; i++) {
        if (odataObject.PriceLines[i] != null) {
        if (odataObject.PriceLines[i]['@odata.type'] != null) {
          var className: string = odataObject.PriceLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.PriceLines[i] = new entityDictionary[className](odataObject.PriceLines[i])
          }
        } else {
            this.PriceLines[i] = new PriceLineClass(odataObject.PriceLines[i]);
        }
                    } else {
        this.PriceLines[i] = undefined;
        }
        }
        }
      
        this.AttainablePriceLines = undefined;
        if (odataObject.AttainablePriceLines) {
        this.AttainablePriceLines = [];
        for (var i = 0; i < odataObject.AttainablePriceLines.length; i++) {
        if (odataObject.AttainablePriceLines[i] != null) {
        if (odataObject.AttainablePriceLines[i]['@odata.type'] != null) {
          var className: string = odataObject.AttainablePriceLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.AttainablePriceLines[i] = new entityDictionary[className](odataObject.AttainablePriceLines[i])
          }
        } else {
            this.AttainablePriceLines[i] = new PriceLineClass(odataObject.AttainablePriceLines[i]);
        }
                    } else {
        this.AttainablePriceLines[i] = undefined;
        }
        }
        }
      
        this.PeriodicDiscountPossibilities = undefined;
        if (odataObject.PeriodicDiscountPossibilities) {
        this.PeriodicDiscountPossibilities = [];
        for (var i = 0; i < odataObject.PeriodicDiscountPossibilities.length; i++) {
        if (odataObject.PeriodicDiscountPossibilities[i] != null) {
        if (odataObject.PeriodicDiscountPossibilities[i]['@odata.type'] != null) {
          var className: string = odataObject.PeriodicDiscountPossibilities[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.PeriodicDiscountPossibilities[i] = new entityDictionary[className](odataObject.PeriodicDiscountPossibilities[i])
          }
        } else {
            this.PeriodicDiscountPossibilities[i] = new DiscountLineClass(odataObject.PeriodicDiscountPossibilities[i]);
        }
                    } else {
        this.PeriodicDiscountPossibilities[i] = undefined;
        }
        }
        }
      
        this.ReasonCodeLines = undefined;
        if (odataObject.ReasonCodeLines) {
        this.ReasonCodeLines = [];
        for (var i = 0; i < odataObject.ReasonCodeLines.length; i++) {
        if (odataObject.ReasonCodeLines[i] != null) {
        if (odataObject.ReasonCodeLines[i]['@odata.type'] != null) {
          var className: string = odataObject.ReasonCodeLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ReasonCodeLines[i] = new entityDictionary[className](odataObject.ReasonCodeLines[i])
          }
        } else {
            this.ReasonCodeLines[i] = new ReasonCodeLineClass(odataObject.ReasonCodeLines[i]);
        }
                    } else {
        this.ReasonCodeLines[i] = undefined;
        }
        }
        }
      
        if (odataObject.ReturnLabelProperties == null) {
            this.ReturnLabelProperties = undefined;
        } else if (odataObject.ReturnLabelProperties['@odata.type'] == null) {
            this.ReturnLabelProperties = new ReturnLabelContentClass(odataObject.ReturnLabelProperties);
        } else {
            var className: string = odataObject.ReturnLabelProperties['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.ReturnLabelProperties = new entityDictionary[className](odataObject.ReturnLabelProperties)
            }
        }

      
            this.LineMultilineDiscOnItem = 
                odataObject.LineMultilineDiscOnItem;
              
        this.RelatedDiscountedLineIds = undefined;
        if (odataObject.RelatedDiscountedLineIds) {
        this.RelatedDiscountedLineIds = [];
        for (var i = 0; i < odataObject.RelatedDiscountedLineIds.length; i++) {
        if (odataObject.RelatedDiscountedLineIds[i] != null) {
            this.RelatedDiscountedLineIds[i] = 
                odataObject.RelatedDiscountedLineIds[i];
                            } else {
        this.RelatedDiscountedLineIds[i] = undefined;
        }
        }
        }
      
            this.LinkedParentLineId = 
                odataObject.LinkedParentLineId;
              
            this.LineMultilineDiscOnItemValue = 
                odataObject.LineMultilineDiscOnItemValue;
              
            this.WasChanged = 
                odataObject.WasChanged;
              
            this.OriginalSalesOrderUnitOfMeasure = 
                odataObject.OriginalSalesOrderUnitOfMeasure;
              
            this.InventOrderUnitOfMeasure = 
                odataObject.InventOrderUnitOfMeasure;
              
            this.TrackingId = 
                odataObject.TrackingId;
              
            this.IsLoyaltyDiscountApplied = 
                odataObject.IsLoyaltyDiscountApplied;
              
            this.CommissionSalesGroup = 
                odataObject.CommissionSalesGroup;
              
            this.IsCommissionSalesGroupOverriddenByCustomer = 
                odataObject.IsCommissionSalesGroupOverriddenByCustomer;
              
            this.CommissionSalesGroupSetByCashier = 
                odataObject.CommissionSalesGroupSetByCashier;
              
            this.QuantityNotProcessed = 
                (odataObject.QuantityNotProcessed != null) ? parseFloat(odataObject.QuantityNotProcessed) : undefined;
              
            this.QuantityReserved = 
                (odataObject.QuantityReserved != null) ? parseFloat(odataObject.QuantityReserved) : undefined;
              
            this.QuantityPicked = 
                (odataObject.QuantityPicked != null) ? parseFloat(odataObject.QuantityPicked) : undefined;
              
            this.QuantityPacked = 
                (odataObject.QuantityPacked != null) ? parseFloat(odataObject.QuantityPacked) : undefined;
              
        this.AttributeValues = undefined;
        if (odataObject.AttributeValues) {
        this.AttributeValues = [];
        for (var i = 0; i < odataObject.AttributeValues.length; i++) {
        if (odataObject.AttributeValues[i] != null) {
        if (odataObject.AttributeValues[i]['@odata.type'] != null) {
          var className: string = odataObject.AttributeValues[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.AttributeValues[i] = new entityDictionary[className](odataObject.AttributeValues[i])
          }
        } else {
            this.AttributeValues[i] = new AttributeValueBaseClass(odataObject.AttributeValues[i]);
        }
                    } else {
        this.AttributeValues[i] = undefined;
        }
        }
        }
      
        if (odataObject.ThirdPartyGiftCardInfo == null) {
            this.ThirdPartyGiftCardInfo = undefined;
        } else if (odataObject.ThirdPartyGiftCardInfo['@odata.type'] == null) {
            this.ThirdPartyGiftCardInfo = new ThirdPartyGiftCardInfoClass(odataObject.ThirdPartyGiftCardInfo);
        } else {
            var className: string = odataObject.ThirdPartyGiftCardInfo['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.ThirdPartyGiftCardInfo = new entityDictionary[className](odataObject.ThirdPartyGiftCardInfo)
            }
        }

      
            this.QuantityReturnable = 
                (odataObject.QuantityReturnable != null) ? parseFloat(odataObject.QuantityReturnable) : undefined;
              
        this.LoyaltyRewardPointLines = undefined;
        if (odataObject.LoyaltyRewardPointLines) {
        this.LoyaltyRewardPointLines = [];
        for (var i = 0; i < odataObject.LoyaltyRewardPointLines.length; i++) {
        if (odataObject.LoyaltyRewardPointLines[i] != null) {
        if (odataObject.LoyaltyRewardPointLines[i]['@odata.type'] != null) {
          var className: string = odataObject.LoyaltyRewardPointLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.LoyaltyRewardPointLines[i] = new entityDictionary[className](odataObject.LoyaltyRewardPointLines[i])
          }
        } else {
            this.LoyaltyRewardPointLines[i] = new LoyaltyRewardPointLineClass(odataObject.LoyaltyRewardPointLines[i]);
        }
                    } else {
        this.LoyaltyRewardPointLines[i] = undefined;
        }
        }
        }
      
        this.ReturnLoyaltyRewardPointLines = undefined;
        if (odataObject.ReturnLoyaltyRewardPointLines) {
        this.ReturnLoyaltyRewardPointLines = [];
        for (var i = 0; i < odataObject.ReturnLoyaltyRewardPointLines.length; i++) {
        if (odataObject.ReturnLoyaltyRewardPointLines[i] != null) {
        if (odataObject.ReturnLoyaltyRewardPointLines[i]['@odata.type'] != null) {
          var className: string = odataObject.ReturnLoyaltyRewardPointLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ReturnLoyaltyRewardPointLines[i] = new entityDictionary[className](odataObject.ReturnLoyaltyRewardPointLines[i])
          }
        } else {
            this.ReturnLoyaltyRewardPointLines[i] = new LoyaltyRewardPointLineClass(odataObject.ReturnLoyaltyRewardPointLines[i]);
        }
                    } else {
        this.ReturnLoyaltyRewardPointLines[i] = undefined;
        }
        }
        }
      
        if (odataObject.FulfillmentStatus == null) {
            this.FulfillmentStatus = undefined;
        } else if (odataObject.FulfillmentStatus['@odata.type'] == null) {
            this.FulfillmentStatus = new FulfillmentLineStatusClass(odataObject.FulfillmentStatus);
        } else {
            var className: string = odataObject.FulfillmentStatus['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.FulfillmentStatus = new entityDictionary[className](odataObject.FulfillmentStatus)
            }
        }

      
            this.FulfillmentStatusValue = 
                odataObject.FulfillmentStatusValue;
              
            this.DetailedLineStatusValue = 
                odataObject.DetailedLineStatusValue;
              
        if (odataObject.DetailedLineStatus == null) {
            this.DetailedLineStatus = undefined;
        } else if (odataObject.DetailedLineStatus['@odata.type'] == null) {
            this.DetailedLineStatus = new DetailedLineStatusClass(odataObject.DetailedLineStatus);
        } else {
            var className: string = odataObject.DetailedLineStatus['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.DetailedLineStatus = new entityDictionary[className](odataObject.DetailedLineStatus)
            }
        }

      
            this.LastReturnProcessedTime = 
                (odataObject.LastReturnProcessedTime instanceof Date) ? odataObject.LastReturnProcessedTime
                : (odataObject.LastReturnProcessedTime != null) ? jsonLightReadStringPropertyValue(odataObject.LastReturnProcessedTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.SalesAgreementPrice = 
                (odataObject.SalesAgreementPrice != null) ? parseFloat(odataObject.SalesAgreementPrice) : undefined;
              
            this.SalesAgreementLineRecordId = 
                (odataObject.SalesAgreementLineRecordId != null) ? parseInt(odataObject.SalesAgreementLineRecordId, 10) : undefined;
              
            this.IsSalesAgreementSkipped = 
                odataObject.IsSalesAgreementSkipped;
              
            this.ItemId = 
                odataObject.ItemId;
              
            this.Quantity = 
                (odataObject.Quantity != null) ? parseFloat(odataObject.Quantity) : undefined;
              
            this.Price = 
                (odataObject.Price != null) ? parseFloat(odataObject.Price) : undefined;
              
            this.ItemTaxGroupId = 
                odataObject.ItemTaxGroupId;
              
            this.SalesTaxGroupId = 
                odataObject.SalesTaxGroupId;
              
            this.OriginalSalesTaxGroupId = 
                odataObject.OriginalSalesTaxGroupId;
              
            this.OriginalItemTaxGroupId = 
                odataObject.OriginalItemTaxGroupId;
              
            this.TaxAmount = 
                (odataObject.TaxAmount != null) ? parseFloat(odataObject.TaxAmount) : undefined;
              
            this.SalesOrderUnitOfMeasure = 
                odataObject.SalesOrderUnitOfMeasure;
              
            this.NetAmount = 
                (odataObject.NetAmount != null) ? parseFloat(odataObject.NetAmount) : undefined;
              
            this.NetAmountPerUnit = 
                (odataObject.NetAmountPerUnit != null) ? parseFloat(odataObject.NetAmountPerUnit) : undefined;
              
            this.GrossAmount = 
                (odataObject.GrossAmount != null) ? parseFloat(odataObject.GrossAmount) : undefined;
              
        this.TaxLines = undefined;
        if (odataObject.TaxLines) {
        this.TaxLines = [];
        for (var i = 0; i < odataObject.TaxLines.length; i++) {
        if (odataObject.TaxLines[i] != null) {
        if (odataObject.TaxLines[i]['@odata.type'] != null) {
          var className: string = odataObject.TaxLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.TaxLines[i] = new entityDictionary[className](odataObject.TaxLines[i])
          }
        } else {
            this.TaxLines[i] = new TaxLineClass(odataObject.TaxLines[i]);
        }
                    } else {
        this.TaxLines[i] = undefined;
        }
        }
        }
      
        this.TaxMeasures = undefined;
        if (odataObject.TaxMeasures) {
        this.TaxMeasures = [];
        for (var i = 0; i < odataObject.TaxMeasures.length; i++) {
        if (odataObject.TaxMeasures[i] != null) {
        if (odataObject.TaxMeasures[i]['@odata.type'] != null) {
          var className: string = odataObject.TaxMeasures[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.TaxMeasures[i] = new entityDictionary[className](odataObject.TaxMeasures[i])
          }
        } else {
            this.TaxMeasures[i] = new TaxMeasureClass(odataObject.TaxMeasures[i]);
        }
                    } else {
        this.TaxMeasures[i] = undefined;
        }
        }
        }
      
            this.TaxAmountExemptInclusive = 
                (odataObject.TaxAmountExemptInclusive != null) ? parseFloat(odataObject.TaxAmountExemptInclusive) : undefined;
              
            this.TaxAmountInclusive = 
                (odataObject.TaxAmountInclusive != null) ? parseFloat(odataObject.TaxAmountInclusive) : undefined;
              
            this.TaxAmountExclusive = 
                (odataObject.TaxAmountExclusive != null) ? parseFloat(odataObject.TaxAmountExclusive) : undefined;
              
            this.NetAmountWithAllInclusiveTax = 
                (odataObject.NetAmountWithAllInclusiveTax != null) ? parseFloat(odataObject.NetAmountWithAllInclusiveTax) : undefined;
              
            this.BeginDateTime = 
                (odataObject.BeginDateTime instanceof Date) ? odataObject.BeginDateTime
                : (odataObject.BeginDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.BeginDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.EndDateTime = 
                (odataObject.EndDateTime instanceof Date) ? odataObject.EndDateTime
                : (odataObject.EndDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.EndDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.TaxRatePercent = 
                (odataObject.TaxRatePercent != null) ? parseFloat(odataObject.TaxRatePercent) : undefined;
              
            this.IsReturnByReceipt = 
                odataObject.IsReturnByReceipt;
              
            this.ReturnLineTaxAmount = 
                (odataObject.ReturnLineTaxAmount != null) ? parseFloat(odataObject.ReturnLineTaxAmount) : undefined;
              
        this.ReturnTaxLines = undefined;
        if (odataObject.ReturnTaxLines) {
        this.ReturnTaxLines = [];
        for (var i = 0; i < odataObject.ReturnTaxLines.length; i++) {
        if (odataObject.ReturnTaxLines[i] != null) {
        if (odataObject.ReturnTaxLines[i]['@odata.type'] != null) {
          var className: string = odataObject.ReturnTaxLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ReturnTaxLines[i] = new entityDictionary[className](odataObject.ReturnTaxLines[i])
          }
        } else {
            this.ReturnTaxLines[i] = new TaxLineClass(odataObject.ReturnTaxLines[i]);
        }
                    } else {
        this.ReturnTaxLines[i] = undefined;
        }
        }
        }
      
            this.TaxExemptPriceInclusiveReductionAmount = 
                (odataObject.TaxExemptPriceInclusiveReductionAmount != null) ? parseFloat(odataObject.TaxExemptPriceInclusiveReductionAmount) : undefined;
              
            this.TaxExemptPriceInclusiveOriginalPrice = 
                (odataObject.TaxExemptPriceInclusiveOriginalPrice != null) ? parseFloat(odataObject.TaxExemptPriceInclusiveOriginalPrice) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['SalesLineClass'] = SalesLineClass;
    
      /**
      * SearchArea entity class.
      */
      export class SearchAreaClass implements Entities.SearchArea {
      public Longitude: number;
      public Latitude: number;
      public Radius: number;
      public DistanceUnitValue: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Longitude = 
                (odataObject.Longitude != null) ? parseFloat(odataObject.Longitude) : undefined;
              
            this.Latitude = 
                (odataObject.Latitude != null) ? parseFloat(odataObject.Latitude) : undefined;
              
            this.Radius = 
                (odataObject.Radius != null) ? parseFloat(odataObject.Radius) : undefined;
              
            this.DistanceUnitValue = 
                odataObject.DistanceUnitValue;
              
      }
      }
      entityDictionary['SearchAreaClass'] = SearchAreaClass;
    
      /**
      * ShiftAccountLine entity class.
      */
      export class ShiftAccountLineClass implements Entities.ShiftAccountLine {
      public AccountTypeValue: number;
      public AccountNumber: string;
      public Amount: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.AccountTypeValue = 
                odataObject.AccountTypeValue;
              
            this.AccountNumber = 
                odataObject.AccountNumber;
              
            this.Amount = 
                (odataObject.Amount != null) ? parseFloat(odataObject.Amount) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ShiftAccountLineClass'] = ShiftAccountLineClass;
    
      /**
      * ShiftTenderLine entity class.
      */
      export class ShiftTenderLineClass implements Entities.ShiftTenderLine {
      public TenderTypeId: string;
      public TenderTypeName: string;
      public CardTypeId: string;
      public TenderCurrency: string;
      public CountingRequired: boolean;
      public StartingAmountOfStoreCurrency: number;
      public StartingAmountOfTenderCurrency: number;
      public FloatingEntryAmountOfStoreCurrency: number;
      public FloatingEntryAmountOfTenderCurrency: number;
      public SafeDropAmountOfStoreCurrency: number;
      public SafeDropAmountOfTenderCurrency: number;
      public BankDropAmountOfStoreCurrency: number;
      public BankDropAmountOfTenderCurrency: number;
      public RemoveTenderAmountOfStoreCurrency: number;
      public RemoveTenderAmountOfTenderCurrency: number;
      public DeclareTenderAmountOfStoreCurrency: number;
      public DeclareTenderAmountOfTenderCurrency: number;
      public TenderedAmountOfStoreCurrency: number;
      public TenderedAmountOfTenderCurrency: number;
      public ChangeAmountOfStoreCurrency: number;
      public ChangeAmountOfTenderCurrency: number;
      public Count: number;
      public Notes: string;
      public Overridden: boolean;
      public OverriddenFloatEntryAmountInStoreCurrency: number;
      public OverriddenFloatEntryAmountInTenderedCurrency: number;
      public OverriddenBankDropAmountInStoreCurrency: number;
      public OverriddenBankDropAmountInTenderedCurrency: number;
      public OverriddenChangeAmountInStoreCurrency: number;
      public OverriddenChangeAmountInTenderedCurrency: number;
      public OverriddenDeclaredTenderAmountInStoreCurrency: number;
      public OverriddenDeclaredTenderAmountInTenderedCurrency: number;
      public OverriddenRemovedTenderAmountInStoreCurrency: number;
      public OverriddenRemovedTenderAmountInTenderedCurrency: number;
      public OverriddenSafeDropAmountInStoreCurrency: number;
      public OverriddenSafeDropAmountInTenderedCurrency: number;
      public OverriddenStartingAmountInStoreCurrency: number;
      public OverriddenStartingAmountInTenderedCurrency: number;
      public OverriddenTenderedAmountInStoreCurrency: number;
      public OverriddenTenderedAmountInTenderedCurrency: number;
      public OverShortAmountOfTenderCurrency: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.TenderTypeId = 
                odataObject.TenderTypeId;
              
            this.TenderTypeName = 
                odataObject.TenderTypeName;
              
            this.CardTypeId = 
                odataObject.CardTypeId;
              
            this.TenderCurrency = 
                odataObject.TenderCurrency;
              
            this.CountingRequired = 
                odataObject.CountingRequired;
              
            this.StartingAmountOfStoreCurrency = 
                (odataObject.StartingAmountOfStoreCurrency != null) ? parseFloat(odataObject.StartingAmountOfStoreCurrency) : undefined;
              
            this.StartingAmountOfTenderCurrency = 
                (odataObject.StartingAmountOfTenderCurrency != null) ? parseFloat(odataObject.StartingAmountOfTenderCurrency) : undefined;
              
            this.FloatingEntryAmountOfStoreCurrency = 
                (odataObject.FloatingEntryAmountOfStoreCurrency != null) ? parseFloat(odataObject.FloatingEntryAmountOfStoreCurrency) : undefined;
              
            this.FloatingEntryAmountOfTenderCurrency = 
                (odataObject.FloatingEntryAmountOfTenderCurrency != null) ? parseFloat(odataObject.FloatingEntryAmountOfTenderCurrency) : undefined;
              
            this.SafeDropAmountOfStoreCurrency = 
                (odataObject.SafeDropAmountOfStoreCurrency != null) ? parseFloat(odataObject.SafeDropAmountOfStoreCurrency) : undefined;
              
            this.SafeDropAmountOfTenderCurrency = 
                (odataObject.SafeDropAmountOfTenderCurrency != null) ? parseFloat(odataObject.SafeDropAmountOfTenderCurrency) : undefined;
              
            this.BankDropAmountOfStoreCurrency = 
                (odataObject.BankDropAmountOfStoreCurrency != null) ? parseFloat(odataObject.BankDropAmountOfStoreCurrency) : undefined;
              
            this.BankDropAmountOfTenderCurrency = 
                (odataObject.BankDropAmountOfTenderCurrency != null) ? parseFloat(odataObject.BankDropAmountOfTenderCurrency) : undefined;
              
            this.RemoveTenderAmountOfStoreCurrency = 
                (odataObject.RemoveTenderAmountOfStoreCurrency != null) ? parseFloat(odataObject.RemoveTenderAmountOfStoreCurrency) : undefined;
              
            this.RemoveTenderAmountOfTenderCurrency = 
                (odataObject.RemoveTenderAmountOfTenderCurrency != null) ? parseFloat(odataObject.RemoveTenderAmountOfTenderCurrency) : undefined;
              
            this.DeclareTenderAmountOfStoreCurrency = 
                (odataObject.DeclareTenderAmountOfStoreCurrency != null) ? parseFloat(odataObject.DeclareTenderAmountOfStoreCurrency) : undefined;
              
            this.DeclareTenderAmountOfTenderCurrency = 
                (odataObject.DeclareTenderAmountOfTenderCurrency != null) ? parseFloat(odataObject.DeclareTenderAmountOfTenderCurrency) : undefined;
              
            this.TenderedAmountOfStoreCurrency = 
                (odataObject.TenderedAmountOfStoreCurrency != null) ? parseFloat(odataObject.TenderedAmountOfStoreCurrency) : undefined;
              
            this.TenderedAmountOfTenderCurrency = 
                (odataObject.TenderedAmountOfTenderCurrency != null) ? parseFloat(odataObject.TenderedAmountOfTenderCurrency) : undefined;
              
            this.ChangeAmountOfStoreCurrency = 
                (odataObject.ChangeAmountOfStoreCurrency != null) ? parseFloat(odataObject.ChangeAmountOfStoreCurrency) : undefined;
              
            this.ChangeAmountOfTenderCurrency = 
                (odataObject.ChangeAmountOfTenderCurrency != null) ? parseFloat(odataObject.ChangeAmountOfTenderCurrency) : undefined;
              
            this.Count = 
                odataObject.Count;
              
            this.Notes = 
                odataObject.Notes;
              
            this.Overridden = 
                odataObject.Overridden;
              
            this.OverriddenFloatEntryAmountInStoreCurrency = 
                (odataObject.OverriddenFloatEntryAmountInStoreCurrency != null) ? parseFloat(odataObject.OverriddenFloatEntryAmountInStoreCurrency) : undefined;
              
            this.OverriddenFloatEntryAmountInTenderedCurrency = 
                (odataObject.OverriddenFloatEntryAmountInTenderedCurrency != null) ? parseFloat(odataObject.OverriddenFloatEntryAmountInTenderedCurrency) : undefined;
              
            this.OverriddenBankDropAmountInStoreCurrency = 
                (odataObject.OverriddenBankDropAmountInStoreCurrency != null) ? parseFloat(odataObject.OverriddenBankDropAmountInStoreCurrency) : undefined;
              
            this.OverriddenBankDropAmountInTenderedCurrency = 
                (odataObject.OverriddenBankDropAmountInTenderedCurrency != null) ? parseFloat(odataObject.OverriddenBankDropAmountInTenderedCurrency) : undefined;
              
            this.OverriddenChangeAmountInStoreCurrency = 
                (odataObject.OverriddenChangeAmountInStoreCurrency != null) ? parseFloat(odataObject.OverriddenChangeAmountInStoreCurrency) : undefined;
              
            this.OverriddenChangeAmountInTenderedCurrency = 
                (odataObject.OverriddenChangeAmountInTenderedCurrency != null) ? parseFloat(odataObject.OverriddenChangeAmountInTenderedCurrency) : undefined;
              
            this.OverriddenDeclaredTenderAmountInStoreCurrency = 
                (odataObject.OverriddenDeclaredTenderAmountInStoreCurrency != null) ? parseFloat(odataObject.OverriddenDeclaredTenderAmountInStoreCurrency) : undefined;
              
            this.OverriddenDeclaredTenderAmountInTenderedCurrency = 
                (odataObject.OverriddenDeclaredTenderAmountInTenderedCurrency != null) ? parseFloat(odataObject.OverriddenDeclaredTenderAmountInTenderedCurrency) : undefined;
              
            this.OverriddenRemovedTenderAmountInStoreCurrency = 
                (odataObject.OverriddenRemovedTenderAmountInStoreCurrency != null) ? parseFloat(odataObject.OverriddenRemovedTenderAmountInStoreCurrency) : undefined;
              
            this.OverriddenRemovedTenderAmountInTenderedCurrency = 
                (odataObject.OverriddenRemovedTenderAmountInTenderedCurrency != null) ? parseFloat(odataObject.OverriddenRemovedTenderAmountInTenderedCurrency) : undefined;
              
            this.OverriddenSafeDropAmountInStoreCurrency = 
                (odataObject.OverriddenSafeDropAmountInStoreCurrency != null) ? parseFloat(odataObject.OverriddenSafeDropAmountInStoreCurrency) : undefined;
              
            this.OverriddenSafeDropAmountInTenderedCurrency = 
                (odataObject.OverriddenSafeDropAmountInTenderedCurrency != null) ? parseFloat(odataObject.OverriddenSafeDropAmountInTenderedCurrency) : undefined;
              
            this.OverriddenStartingAmountInStoreCurrency = 
                (odataObject.OverriddenStartingAmountInStoreCurrency != null) ? parseFloat(odataObject.OverriddenStartingAmountInStoreCurrency) : undefined;
              
            this.OverriddenStartingAmountInTenderedCurrency = 
                (odataObject.OverriddenStartingAmountInTenderedCurrency != null) ? parseFloat(odataObject.OverriddenStartingAmountInTenderedCurrency) : undefined;
              
            this.OverriddenTenderedAmountInStoreCurrency = 
                (odataObject.OverriddenTenderedAmountInStoreCurrency != null) ? parseFloat(odataObject.OverriddenTenderedAmountInStoreCurrency) : undefined;
              
            this.OverriddenTenderedAmountInTenderedCurrency = 
                (odataObject.OverriddenTenderedAmountInTenderedCurrency != null) ? parseFloat(odataObject.OverriddenTenderedAmountInTenderedCurrency) : undefined;
              
            this.OverShortAmountOfTenderCurrency = 
                (odataObject.OverShortAmountOfTenderCurrency != null) ? parseFloat(odataObject.OverShortAmountOfTenderCurrency) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ShiftTenderLineClass'] = ShiftTenderLineClass;
    
      /**
      * StockCountJournalTransaction entity class.
      */
      export class StockCountJournalTransactionClass implements Entities.StockCountJournalTransaction {
      public JournalId: string;
      public RecordId: number;
      public OperationType: number;
      public ItemId: string;
      public DataAreaId: string;
      public ProductRecordId: number;
      public ItemName: string;
      public InventDimId: string;
      public Counted: number;
      public Quantity: number;
      public EstimatedOnHandQuantity: number;
      public OnHandCalculationDateTime: Date;
      public Unit: string;
      public UserId: string;
      public TerminalId: string;
      public CountedDate: Date;
      public Status: number;
      public InventBatchId: string;
      public WarehouseLocationId: string;
      public InventoryStatus: string;
      public LicensePlate: string;
      public WarehousePalletId: string;
      public InventSiteId: string;
      public InventLocationId: string;
      public ConfigId: string;
      public InventSizeId: string;
      public InventColorId: string;
      public InventStyleId: string;
      public InventSerialId: string;
      public TrackingGuid: string;
      public UpdatedInAx: boolean;
      public Message: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.JournalId = 
                odataObject.JournalId;
              
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.OperationType = 
                odataObject.OperationType;
              
            this.ItemId = 
                odataObject.ItemId;
              
            this.DataAreaId = 
                odataObject.DataAreaId;
              
            this.ProductRecordId = 
                (odataObject.ProductRecordId != null) ? parseInt(odataObject.ProductRecordId, 10) : undefined;
              
            this.ItemName = 
                odataObject.ItemName;
              
            this.InventDimId = 
                odataObject.InventDimId;
              
            this.Counted = 
                (odataObject.Counted != null) ? parseFloat(odataObject.Counted) : undefined;
              
            this.Quantity = 
                (odataObject.Quantity != null) ? parseFloat(odataObject.Quantity) : undefined;
              
            this.EstimatedOnHandQuantity = 
                (odataObject.EstimatedOnHandQuantity != null) ? parseFloat(odataObject.EstimatedOnHandQuantity) : undefined;
              
            this.OnHandCalculationDateTime = 
                (odataObject.OnHandCalculationDateTime instanceof Date) ? odataObject.OnHandCalculationDateTime
                : (odataObject.OnHandCalculationDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.OnHandCalculationDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.Unit = 
                odataObject.Unit;
              
            this.UserId = 
                odataObject.UserId;
              
            this.TerminalId = 
                odataObject.TerminalId;
              
            this.CountedDate = 
                (odataObject.CountedDate instanceof Date) ? odataObject.CountedDate
                : (odataObject.CountedDate != null) ? jsonLightReadStringPropertyValue(odataObject.CountedDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.Status = 
                odataObject.Status;
              
            this.InventBatchId = 
                odataObject.InventBatchId;
              
            this.WarehouseLocationId = 
                odataObject.WarehouseLocationId;
              
            this.InventoryStatus = 
                odataObject.InventoryStatus;
              
            this.LicensePlate = 
                odataObject.LicensePlate;
              
            this.WarehousePalletId = 
                odataObject.WarehousePalletId;
              
            this.InventSiteId = 
                odataObject.InventSiteId;
              
            this.InventLocationId = 
                odataObject.InventLocationId;
              
            this.ConfigId = 
                odataObject.ConfigId;
              
            this.InventSizeId = 
                odataObject.InventSizeId;
              
            this.InventColorId = 
                odataObject.InventColorId;
              
            this.InventStyleId = 
                odataObject.InventStyleId;
              
            this.InventSerialId = 
                odataObject.InventSerialId;
              
            this.TrackingGuid = 
                odataObject.TrackingGuid;
              
            this.UpdatedInAx = 
                odataObject.UpdatedInAx;
              
            this.Message = 
                odataObject.Message;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['StockCountJournalTransactionClass'] = StockCountJournalTransactionClass;
    
      /**
      * TaxLine entity class.
      */
      export class TaxLineClass implements Entities.TaxLine {
      public TaxGroup: string;
      public Percentage: number;
      public IsExempt: boolean;
      public TaxBasis: number;
      public IsIncludedInPrice: boolean;
      public SaleLineNumber: number;
      public StoreId: string;
      public TerminalId: string;
      public TransactionId: string;
      public TaxCode: string;
      public Amount: number;
      public AmountRefunded: number;
      public IsTaxGroupRounding: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.TaxGroup = 
                odataObject.TaxGroup;
              
            this.Percentage = 
                (odataObject.Percentage != null) ? parseFloat(odataObject.Percentage) : undefined;
              
            this.IsExempt = 
                odataObject.IsExempt;
              
            this.TaxBasis = 
                (odataObject.TaxBasis != null) ? parseFloat(odataObject.TaxBasis) : undefined;
              
            this.IsIncludedInPrice = 
                odataObject.IsIncludedInPrice;
              
            this.SaleLineNumber = 
                (odataObject.SaleLineNumber != null) ? parseFloat(odataObject.SaleLineNumber) : undefined;
              
            this.StoreId = 
                odataObject.StoreId;
              
            this.TerminalId = 
                odataObject.TerminalId;
              
            this.TransactionId = 
                odataObject.TransactionId;
              
            this.TaxCode = 
                odataObject.TaxCode;
              
            this.Amount = 
                (odataObject.Amount != null) ? parseFloat(odataObject.Amount) : undefined;
              
            this.AmountRefunded = 
                (odataObject.AmountRefunded != null) ? parseFloat(odataObject.AmountRefunded) : undefined;
              
            this.IsTaxGroupRounding = 
                odataObject.IsTaxGroupRounding;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['TaxLineClass'] = TaxLineClass;
    
      /**
      * TenderLine entity class.
      */
      export class TenderLineClass implements Entities.TenderLine {
      public StoreId: string;
      public TerminalId: string;
      public TransactionId: string;
      public InternalTransactionId: string;
      public RefundableAmount: number;
      public CaptureToken: string;
      public CardToken: string;
      public Authorization: string;
      public TransactionStatusValue: number;
      public IncomeExpenseAccountTypeValue: number;
      public MaskedCardNumber: string;
      public TenderDate: Date;
      public IsPreProcessed: boolean;
      public IsDeposit: boolean;
      public IsCustomerAccountFloorLimitUsed: boolean;
      public AuthorizationReferenceId: string;
      public IsIncrementalCaptureEnabled: boolean;
      public IsReauthorizationEnabled: boolean;
      public IncrementalOffsetAmount: number;
      public ChannelId: number;
      public IsLinkedRefund: boolean;
      public LinkedPaymentStore: string;
      public LinkedPaymentTerminalId: string;
      public LinkedPaymentTransactionId: string;
      public LinkedPaymentLineNumber: number;
      public LinkedPaymentCurrency: string;
      public RoundingDifference: number;
      public RemainingLinkedRefundAmount: number;
      public TenderLineId: string;
      public Amount: number;
      public CashBackAmount: number;
      public AmountInTenderedCurrency: number;
      public AmountInCompanyCurrency: number;
      public Currency: string;
      public ExchangeRate: number;
      public CompanyCurrencyExchangeRate: number;
      public TenderTypeId: string;
      public SignatureData: string;
      public ReasonCodeLines: Entities.ReasonCodeLine[];
      public LineNumber: number;
      public GiftCardId: string;
      public GiftCardPin: string;
      public GiftCardExpirationYear: number;
      public GiftCardExpirationMonth: number;
      public CreditMemoId: string;
      public CustomerId: string;
      public LoyaltyCardId: string;
      public CardTypeId: string;
      public IsChangeLine: boolean;
      public IsHistorical: boolean;
      public IsVoidable: boolean;
      public StatusValue: number;
      public VoidStatusValue: number;
      public AuthorizedAmount: number;
      public PaymentRefRecId: number;
      public CardPaymentAccountId: string;
      public ProcessingTypeValue: number;
      public CardProcessorStatusValue: number;
      public LinkedPaymentRefRecId: number;
      public IsPolicyBypassed: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.StoreId = 
                odataObject.StoreId;
              
            this.TerminalId = 
                odataObject.TerminalId;
              
            this.TransactionId = 
                odataObject.TransactionId;
              
            this.InternalTransactionId = 
                odataObject.InternalTransactionId;
              
            this.RefundableAmount = 
                (odataObject.RefundableAmount != null) ? parseFloat(odataObject.RefundableAmount) : undefined;
              
            this.CaptureToken = 
                odataObject.CaptureToken;
              
            this.CardToken = 
                odataObject.CardToken;
              
            this.Authorization = 
                odataObject.Authorization;
              
            this.TransactionStatusValue = 
                odataObject.TransactionStatusValue;
              
            this.IncomeExpenseAccountTypeValue = 
                odataObject.IncomeExpenseAccountTypeValue;
              
            this.MaskedCardNumber = 
                odataObject.MaskedCardNumber;
              
            this.TenderDate = 
                (odataObject.TenderDate instanceof Date) ? odataObject.TenderDate
                : (odataObject.TenderDate != null) ? jsonLightReadStringPropertyValue(odataObject.TenderDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.IsPreProcessed = 
                odataObject.IsPreProcessed;
              
            this.IsDeposit = 
                odataObject.IsDeposit;
              
            this.IsCustomerAccountFloorLimitUsed = 
                odataObject.IsCustomerAccountFloorLimitUsed;
              
            this.AuthorizationReferenceId = 
                odataObject.AuthorizationReferenceId;
              
            this.IsIncrementalCaptureEnabled = 
                odataObject.IsIncrementalCaptureEnabled;
              
            this.IsReauthorizationEnabled = 
                odataObject.IsReauthorizationEnabled;
              
            this.IncrementalOffsetAmount = 
                (odataObject.IncrementalOffsetAmount != null) ? parseFloat(odataObject.IncrementalOffsetAmount) : undefined;
              
            this.ChannelId = 
                (odataObject.ChannelId != null) ? parseInt(odataObject.ChannelId, 10) : undefined;
              
            this.IsLinkedRefund = 
                odataObject.IsLinkedRefund;
              
            this.LinkedPaymentStore = 
                odataObject.LinkedPaymentStore;
              
            this.LinkedPaymentTerminalId = 
                odataObject.LinkedPaymentTerminalId;
              
            this.LinkedPaymentTransactionId = 
                odataObject.LinkedPaymentTransactionId;
              
            this.LinkedPaymentLineNumber = 
                (odataObject.LinkedPaymentLineNumber != null) ? parseFloat(odataObject.LinkedPaymentLineNumber) : undefined;
              
            this.LinkedPaymentCurrency = 
                odataObject.LinkedPaymentCurrency;
              
            this.RoundingDifference = 
                (odataObject.RoundingDifference != null) ? parseFloat(odataObject.RoundingDifference) : undefined;
              
            this.RemainingLinkedRefundAmount = 
                (odataObject.RemainingLinkedRefundAmount != null) ? parseFloat(odataObject.RemainingLinkedRefundAmount) : undefined;
              
            this.TenderLineId = 
                odataObject.TenderLineId;
              
            this.Amount = 
                (odataObject.Amount != null) ? parseFloat(odataObject.Amount) : undefined;
              
            this.CashBackAmount = 
                (odataObject.CashBackAmount != null) ? parseFloat(odataObject.CashBackAmount) : undefined;
              
            this.AmountInTenderedCurrency = 
                (odataObject.AmountInTenderedCurrency != null) ? parseFloat(odataObject.AmountInTenderedCurrency) : undefined;
              
            this.AmountInCompanyCurrency = 
                (odataObject.AmountInCompanyCurrency != null) ? parseFloat(odataObject.AmountInCompanyCurrency) : undefined;
              
            this.Currency = 
                odataObject.Currency;
              
            this.ExchangeRate = 
                (odataObject.ExchangeRate != null) ? parseFloat(odataObject.ExchangeRate) : undefined;
              
            this.CompanyCurrencyExchangeRate = 
                (odataObject.CompanyCurrencyExchangeRate != null) ? parseFloat(odataObject.CompanyCurrencyExchangeRate) : undefined;
              
            this.TenderTypeId = 
                odataObject.TenderTypeId;
              
            this.SignatureData = 
                odataObject.SignatureData;
              
        this.ReasonCodeLines = undefined;
        if (odataObject.ReasonCodeLines) {
        this.ReasonCodeLines = [];
        for (var i = 0; i < odataObject.ReasonCodeLines.length; i++) {
        if (odataObject.ReasonCodeLines[i] != null) {
        if (odataObject.ReasonCodeLines[i]['@odata.type'] != null) {
          var className: string = odataObject.ReasonCodeLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ReasonCodeLines[i] = new entityDictionary[className](odataObject.ReasonCodeLines[i])
          }
        } else {
            this.ReasonCodeLines[i] = new ReasonCodeLineClass(odataObject.ReasonCodeLines[i]);
        }
                    } else {
        this.ReasonCodeLines[i] = undefined;
        }
        }
        }
      
            this.LineNumber = 
                (odataObject.LineNumber != null) ? parseFloat(odataObject.LineNumber) : undefined;
              
            this.GiftCardId = 
                odataObject.GiftCardId;
              
            this.GiftCardPin = 
                odataObject.GiftCardPin;
              
            this.GiftCardExpirationYear = 
                odataObject.GiftCardExpirationYear;
              
            this.GiftCardExpirationMonth = 
                odataObject.GiftCardExpirationMonth;
              
            this.CreditMemoId = 
                odataObject.CreditMemoId;
              
            this.CustomerId = 
                odataObject.CustomerId;
              
            this.LoyaltyCardId = 
                odataObject.LoyaltyCardId;
              
            this.CardTypeId = 
                odataObject.CardTypeId;
              
            this.IsChangeLine = 
                odataObject.IsChangeLine;
              
            this.IsHistorical = 
                odataObject.IsHistorical;
              
            this.IsVoidable = 
                odataObject.IsVoidable;
              
            this.StatusValue = 
                odataObject.StatusValue;
              
            this.VoidStatusValue = 
                odataObject.VoidStatusValue;
              
            this.AuthorizedAmount = 
                (odataObject.AuthorizedAmount != null) ? parseFloat(odataObject.AuthorizedAmount) : undefined;
              
            this.PaymentRefRecId = 
                (odataObject.PaymentRefRecId != null) ? parseInt(odataObject.PaymentRefRecId, 10) : undefined;
              
            this.CardPaymentAccountId = 
                odataObject.CardPaymentAccountId;
              
            this.ProcessingTypeValue = 
                odataObject.ProcessingTypeValue;
              
            this.CardProcessorStatusValue = 
                odataObject.CardProcessorStatusValue;
              
            this.LinkedPaymentRefRecId = 
                (odataObject.LinkedPaymentRefRecId != null) ? parseInt(odataObject.LinkedPaymentRefRecId, 10) : undefined;
              
            this.IsPolicyBypassed = 
                odataObject.IsPolicyBypassed;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['TenderLineClass'] = TenderLineClass;
    
      /**
      * TextValueTranslation entity class.
      */
      export class TextValueTranslationClass implements Entities.TextValueTranslation {
      public Language: string;
      public Text: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Language = 
                odataObject.Language;
              
            this.Text = 
                odataObject.Text;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['TextValueTranslationClass'] = TextValueTranslationClass;
    
      /**
      * UnitOfMeasure entity class.
      */
      export class UnitOfMeasureClass implements Entities.UnitOfMeasure {
      public DecimalPrecision: number;
      public Symbol: string;
      public Description: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.DecimalPrecision = 
                odataObject.DecimalPrecision;
              
            this.Symbol = 
                odataObject.Symbol;
              
            this.Description = 
                odataObject.Description;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['UnitOfMeasureClass'] = UnitOfMeasureClass;
    
      /**
      * ProductUnitOfMeasure entity class.
      */
      export class ProductUnitOfMeasureClass
        extends UnitOfMeasureClass
       implements Entities.ProductUnitOfMeasure {
      public UnitOfMeasureSourceValue: number;
      public ProductQuantityLimitRules: Entities.ProductQuantityLimitRule;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.UnitOfMeasureSourceValue = 
                odataObject.UnitOfMeasureSourceValue;
              
        if (odataObject.ProductQuantityLimitRules == null) {
            this.ProductQuantityLimitRules = undefined;
        } else if (odataObject.ProductQuantityLimitRules['@odata.type'] == null) {
            this.ProductQuantityLimitRules = new ProductQuantityLimitRuleClass(odataObject.ProductQuantityLimitRules);
        } else {
            var className: string = odataObject.ProductQuantityLimitRules['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.ProductQuantityLimitRules = new entityDictionary[className](odataObject.ProductQuantityLimitRules)
            }
        }

      
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.ProductUnitOfMeasure";
      
      }
      }
      entityDictionary['ProductUnitOfMeasureClass'] = ProductUnitOfMeasureClass;
    
      /**
      * RecommendedElement entity class.
      */
      export class RecommendedElementClass implements Entities.RecommendedElement {
      public ElementId: string;
      public ElementTypeValue: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ElementId = 
                odataObject.ElementId;
              
            this.ElementTypeValue = 
                odataObject.ElementTypeValue;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['RecommendedElementClass'] = RecommendedElementClass;
    
      /**
      * RecommendationCriteria entity class.
      */
      export class RecommendationCriteriaClass implements Entities.RecommendationCriteria {
      public CustomerAccountNumber: string;
      public CatalogId: number;
      public ProductIds: number[];
      public CategoryIds: number[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.CustomerAccountNumber = 
                odataObject.CustomerAccountNumber;
              
            this.CatalogId = 
                (odataObject.CatalogId != null) ? parseInt(odataObject.CatalogId, 10) : undefined;
              
        this.ProductIds = undefined;
        if (odataObject.ProductIds) {
        this.ProductIds = [];
        for (var i = 0; i < odataObject.ProductIds.length; i++) {
        if (odataObject.ProductIds[i] != null) {
            this.ProductIds[i] = 
                parseInt(odataObject.ProductIds[i], 10);
                            } else {
        this.ProductIds[i] = undefined;
        }
        }
        }
      
        this.CategoryIds = undefined;
        if (odataObject.CategoryIds) {
        this.CategoryIds = [];
        for (var i = 0; i < odataObject.CategoryIds.length; i++) {
        if (odataObject.CategoryIds[i] != null) {
            this.CategoryIds[i] = 
                parseInt(odataObject.CategoryIds[i], 10);
                            } else {
        this.CategoryIds[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['RecommendationCriteriaClass'] = RecommendationCriteriaClass;
    
      /**
      * ProductListLine entity class.
      */
      export class ProductListLineClass implements Entities.ProductListLine {
      public ProductListId: string;
      public LineId: string;
      public ProductId: number;
      public Quantity: number;
      public UnitOfMeasure: string;
      public CreatedDateTime: Date;
      public LastUpdated: Date;
      public DeletedDateTime: Date;
      public DataAreaId: string;
      public CatalogId: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ProductListId = 
                odataObject.ProductListId;
              
            this.LineId = 
                odataObject.LineId;
              
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
            this.Quantity = 
                (odataObject.Quantity != null) ? parseFloat(odataObject.Quantity) : undefined;
              
            this.UnitOfMeasure = 
                odataObject.UnitOfMeasure;
              
            this.CreatedDateTime = 
                (odataObject.CreatedDateTime instanceof Date) ? odataObject.CreatedDateTime
                : (odataObject.CreatedDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.CreatedDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.LastUpdated = 
                (odataObject.LastUpdated instanceof Date) ? odataObject.LastUpdated
                : (odataObject.LastUpdated != null) ? jsonLightReadStringPropertyValue(odataObject.LastUpdated, "Edm.DateTimeOffset", false) : undefined;
              
            this.DeletedDateTime = 
                (odataObject.DeletedDateTime instanceof Date) ? odataObject.DeletedDateTime
                : (odataObject.DeletedDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.DeletedDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.DataAreaId = 
                odataObject.DataAreaId;
              
            this.CatalogId = 
                (odataObject.CatalogId != null) ? parseInt(odataObject.CatalogId, 10) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ProductListLineClass'] = ProductListLineClass;
    
      /**
      * CommerceListLine entity class.
      */
      export class CommerceListLineClass implements Entities.CommerceListLine {
      public CommerceListId: number;
      public LineId: number;
      public CustomerId: string;
      public CustomerName: string;
      public ProductId: number;
      public Quantity: number;
      public UnitOfMeasure: string;
      public IsFavorite: boolean;
      public IsRecurring: boolean;
      public IsPrivate: boolean;
      public CreatedDateTime: Date;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.CommerceListId = 
                (odataObject.CommerceListId != null) ? parseInt(odataObject.CommerceListId, 10) : undefined;
              
            this.LineId = 
                (odataObject.LineId != null) ? parseInt(odataObject.LineId, 10) : undefined;
              
            this.CustomerId = 
                odataObject.CustomerId;
              
            this.CustomerName = 
                odataObject.CustomerName;
              
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
            this.Quantity = 
                (odataObject.Quantity != null) ? parseFloat(odataObject.Quantity) : undefined;
              
            this.UnitOfMeasure = 
                odataObject.UnitOfMeasure;
              
            this.IsFavorite = 
                odataObject.IsFavorite;
              
            this.IsRecurring = 
                odataObject.IsRecurring;
              
            this.IsPrivate = 
                odataObject.IsPrivate;
              
            this.CreatedDateTime = 
                (odataObject.CreatedDateTime instanceof Date) ? odataObject.CreatedDateTime
                : (odataObject.CreatedDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.CreatedDateTime, "Edm.DateTimeOffset", false) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CommerceListLineClass'] = CommerceListLineClass;
    
      /**
      * CommerceListContributor entity class.
      */
      export class CommerceListContributorClass implements Entities.CommerceListContributor {
      public RecordId: number;
      public CustomerId: string;
      public CustomerName: string;
      public InvitationId: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.CustomerId = 
                odataObject.CustomerId;
              
            this.CustomerName = 
                odataObject.CustomerName;
              
            this.InvitationId = 
                (odataObject.InvitationId != null) ? parseInt(odataObject.InvitationId, 10) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CommerceListContributorClass'] = CommerceListContributorClass;
    
      /**
      * CommerceListInvitation entity class.
      */
      export class CommerceListInvitationClass implements Entities.CommerceListInvitation {
      public RecordId: number;
      public Invitee: string;
      public IsSent: boolean;
      public LastRequestDateTime: Date;
      public LastSentDateTime: Date;
      public StatusValue: number;
      public Token: string;
      public InvitationTypeValue: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.Invitee = 
                odataObject.Invitee;
              
            this.IsSent = 
                odataObject.IsSent;
              
            this.LastRequestDateTime = 
                (odataObject.LastRequestDateTime instanceof Date) ? odataObject.LastRequestDateTime
                : (odataObject.LastRequestDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.LastRequestDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.LastSentDateTime = 
                (odataObject.LastSentDateTime instanceof Date) ? odataObject.LastSentDateTime
                : (odataObject.LastSentDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.LastSentDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.StatusValue = 
                odataObject.StatusValue;
              
            this.Token = 
                odataObject.Token;
              
            this.InvitationTypeValue = 
                odataObject.InvitationTypeValue;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CommerceListInvitationClass'] = CommerceListInvitationClass;
    
      /**
      * SearchFilter entity class.
      */
      export class SearchFilterClass implements Entities.SearchFilter {
      public Key: string;
      public FilterTypeValue: number;
      public SearchValues: Entities.SearchFilterValue[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Key = 
                odataObject.Key;
              
            this.FilterTypeValue = 
                odataObject.FilterTypeValue;
              
        this.SearchValues = undefined;
        if (odataObject.SearchValues) {
        this.SearchValues = [];
        for (var i = 0; i < odataObject.SearchValues.length; i++) {
        if (odataObject.SearchValues[i] != null) {
        if (odataObject.SearchValues[i]['@odata.type'] != null) {
          var className: string = odataObject.SearchValues[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.SearchValues[i] = new entityDictionary[className](odataObject.SearchValues[i])
          }
        } else {
            this.SearchValues[i] = new SearchFilterValueClass(odataObject.SearchValues[i]);
        }
                    } else {
        this.SearchValues[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['SearchFilterClass'] = SearchFilterClass;
    
      /**
      * SearchFilterValue entity class.
      */
      export class SearchFilterValueClass implements Entities.SearchFilterValue {
      public Value: Entities.CommercePropertyValue;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        if (odataObject.Value == null) {
            this.Value = undefined;
        } else if (odataObject.Value['@odata.type'] == null) {
            this.Value = new CommercePropertyValueClass(odataObject.Value);
        } else {
            var className: string = odataObject.Value['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.Value = new entityDictionary[className](odataObject.Value)
            }
        }

      
      }
      }
      entityDictionary['SearchFilterValueClass'] = SearchFilterValueClass;
    
      /**
      * TransactionSearchCriteria entity class.
      */
      export class TransactionSearchCriteriaClass implements Entities.TransactionSearchCriteria {
      public TransactionIds: string[];
      public SalesId: string;
      public ReceiptId: string;
      public ChannelReferenceId: string;
      public CustomerAccountNumber: string;
      public CustomerFirstName: string;
      public CustomerLastName: string;
      public StoreId: string;
      public TerminalId: string;
      public ItemId: string;
      public Barcode: string;
      public SerialNumber: string;
      public StaffId: string;
      public StartDateTime: Date;
      public EndDateTime: Date;
      public ReceiptEmailAddress: string;
      public SearchIdentifiers: string;
      public SearchLocationTypeValue: number;
      public CustomFilters: Entities.SearchFilter[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.TransactionIds = undefined;
        if (odataObject.TransactionIds) {
        this.TransactionIds = [];
        for (var i = 0; i < odataObject.TransactionIds.length; i++) {
        if (odataObject.TransactionIds[i] != null) {
            this.TransactionIds[i] = 
                odataObject.TransactionIds[i];
                            } else {
        this.TransactionIds[i] = undefined;
        }
        }
        }
      
            this.SalesId = 
                odataObject.SalesId;
              
            this.ReceiptId = 
                odataObject.ReceiptId;
              
            this.ChannelReferenceId = 
                odataObject.ChannelReferenceId;
              
            this.CustomerAccountNumber = 
                odataObject.CustomerAccountNumber;
              
            this.CustomerFirstName = 
                odataObject.CustomerFirstName;
              
            this.CustomerLastName = 
                odataObject.CustomerLastName;
              
            this.StoreId = 
                odataObject.StoreId;
              
            this.TerminalId = 
                odataObject.TerminalId;
              
            this.ItemId = 
                odataObject.ItemId;
              
            this.Barcode = 
                odataObject.Barcode;
              
            this.SerialNumber = 
                odataObject.SerialNumber;
              
            this.StaffId = 
                odataObject.StaffId;
              
            this.StartDateTime = 
                (odataObject.StartDateTime instanceof Date) ? odataObject.StartDateTime
                : (odataObject.StartDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.StartDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.EndDateTime = 
                (odataObject.EndDateTime instanceof Date) ? odataObject.EndDateTime
                : (odataObject.EndDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.EndDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.ReceiptEmailAddress = 
                odataObject.ReceiptEmailAddress;
              
            this.SearchIdentifiers = 
                odataObject.SearchIdentifiers;
              
            this.SearchLocationTypeValue = 
                odataObject.SearchLocationTypeValue;
              
        this.CustomFilters = undefined;
        if (odataObject.CustomFilters) {
        this.CustomFilters = [];
        for (var i = 0; i < odataObject.CustomFilters.length; i++) {
        if (odataObject.CustomFilters[i] != null) {
        if (odataObject.CustomFilters[i]['@odata.type'] != null) {
          var className: string = odataObject.CustomFilters[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.CustomFilters[i] = new entityDictionary[className](odataObject.CustomFilters[i])
          }
        } else {
            this.CustomFilters[i] = new SearchFilterClass(odataObject.CustomFilters[i]);
        }
                    } else {
        this.CustomFilters[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['TransactionSearchCriteriaClass'] = TransactionSearchCriteriaClass;
    
      /**
      * SalesOrderSearchCriteria entity class.
      */
      export class SalesOrderSearchCriteriaClass implements Entities.SalesOrderSearchCriteria {
      public TransactionIds: string[];
      public SalesId: string;
      public ReceiptId: string;
      public ChannelReferenceId: string;
      public CustomerAccountNumber: string;
      public CustomerFirstName: string;
      public CustomerLastName: string;
      public StoreId: string;
      public TerminalId: string;
      public ItemId: string;
      public Barcode: string;
      public SerialNumber: string;
      public StaffId: string;
      public SearchTypeValue: number;
      public StartDateTime: Date;
      public EndDateTime: Date;
      public IncludeDetails: boolean;
      public ReceiptEmailAddress: string;
      public SearchIdentifiers: string;
      public SalesTransactionTypeValues: number[];
      public SearchLocationTypeValue: number;
      public TransactionStatusTypeValues: number[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.TransactionIds = undefined;
        if (odataObject.TransactionIds) {
        this.TransactionIds = [];
        for (var i = 0; i < odataObject.TransactionIds.length; i++) {
        if (odataObject.TransactionIds[i] != null) {
            this.TransactionIds[i] = 
                odataObject.TransactionIds[i];
                            } else {
        this.TransactionIds[i] = undefined;
        }
        }
        }
      
            this.SalesId = 
                odataObject.SalesId;
              
            this.ReceiptId = 
                odataObject.ReceiptId;
              
            this.ChannelReferenceId = 
                odataObject.ChannelReferenceId;
              
            this.CustomerAccountNumber = 
                odataObject.CustomerAccountNumber;
              
            this.CustomerFirstName = 
                odataObject.CustomerFirstName;
              
            this.CustomerLastName = 
                odataObject.CustomerLastName;
              
            this.StoreId = 
                odataObject.StoreId;
              
            this.TerminalId = 
                odataObject.TerminalId;
              
            this.ItemId = 
                odataObject.ItemId;
              
            this.Barcode = 
                odataObject.Barcode;
              
            this.SerialNumber = 
                odataObject.SerialNumber;
              
            this.StaffId = 
                odataObject.StaffId;
              
            this.SearchTypeValue = 
                odataObject.SearchTypeValue;
              
            this.StartDateTime = 
                (odataObject.StartDateTime instanceof Date) ? odataObject.StartDateTime
                : (odataObject.StartDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.StartDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.EndDateTime = 
                (odataObject.EndDateTime instanceof Date) ? odataObject.EndDateTime
                : (odataObject.EndDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.EndDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.IncludeDetails = 
                odataObject.IncludeDetails;
              
            this.ReceiptEmailAddress = 
                odataObject.ReceiptEmailAddress;
              
            this.SearchIdentifiers = 
                odataObject.SearchIdentifiers;
              
        this.SalesTransactionTypeValues = undefined;
        if (odataObject.SalesTransactionTypeValues) {
        this.SalesTransactionTypeValues = [];
        for (var i = 0; i < odataObject.SalesTransactionTypeValues.length; i++) {
        if (odataObject.SalesTransactionTypeValues[i] != null) {
            this.SalesTransactionTypeValues[i] = 
                odataObject.SalesTransactionTypeValues[i];
                            } else {
        this.SalesTransactionTypeValues[i] = undefined;
        }
        }
        }
      
            this.SearchLocationTypeValue = 
                odataObject.SearchLocationTypeValue;
              
        this.TransactionStatusTypeValues = undefined;
        if (odataObject.TransactionStatusTypeValues) {
        this.TransactionStatusTypeValues = [];
        for (var i = 0; i < odataObject.TransactionStatusTypeValues.length; i++) {
        if (odataObject.TransactionStatusTypeValues[i] != null) {
            this.TransactionStatusTypeValues[i] = 
                odataObject.TransactionStatusTypeValues[i];
                            } else {
        this.TransactionStatusTypeValues[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['SalesOrderSearchCriteriaClass'] = SalesOrderSearchCriteriaClass;
    
      /**
      * SearchStoreCriteria entity class.
      */
      export class SearchStoreCriteriaClass implements Entities.SearchStoreCriteria {
      public ChannelId: number;
      public StoreNumber: string;
      public InventoryLocationId: string;
      public ChannelTypeValue: number;
      public OnlineStoreCustomerTypeValue: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ChannelId = 
                (odataObject.ChannelId != null) ? parseInt(odataObject.ChannelId, 10) : undefined;
              
            this.StoreNumber = 
                odataObject.StoreNumber;
              
            this.InventoryLocationId = 
                odataObject.InventoryLocationId;
              
            this.ChannelTypeValue = 
                odataObject.ChannelTypeValue;
              
            this.OnlineStoreCustomerTypeValue = 
                odataObject.OnlineStoreCustomerTypeValue;
              
      }
      }
      entityDictionary['SearchStoreCriteriaClass'] = SearchStoreCriteriaClass;
    
      /**
      * CustomerSearchCriteria entity class.
      */
      export class CustomerSearchCriteriaClass implements Entities.CustomerSearchCriteria {
      public Keyword: string;
      public SearchOnlyCurrentCompany: boolean;
      public SearchLocationValue: number;
      public Refinements: Entities.CustomerRefinerValue[];
      public DataLevelValue: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Keyword = 
                odataObject.Keyword;
              
            this.SearchOnlyCurrentCompany = 
                odataObject.SearchOnlyCurrentCompany;
              
            this.SearchLocationValue = 
                odataObject.SearchLocationValue;
              
        this.Refinements = undefined;
        if (odataObject.Refinements) {
        this.Refinements = [];
        for (var i = 0; i < odataObject.Refinements.length; i++) {
        if (odataObject.Refinements[i] != null) {
        if (odataObject.Refinements[i]['@odata.type'] != null) {
          var className: string = odataObject.Refinements[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Refinements[i] = new entityDictionary[className](odataObject.Refinements[i])
          }
        } else {
            this.Refinements[i] = new CustomerRefinerValueClass(odataObject.Refinements[i]);
        }
                    } else {
        this.Refinements[i] = undefined;
        }
        }
        }
      
            this.DataLevelValue = 
                odataObject.DataLevelValue;
              
      }
      }
      entityDictionary['CustomerSearchCriteriaClass'] = CustomerSearchCriteriaClass;
    
      /**
      * ProductPrice entity class.
      */
      export class ProductPriceClass implements Entities.ProductPrice {
      public ProductId: number;
      public ListingId: number;
      public BasePrice: number;
      public TradeAgreementPrice: number;
      public AdjustedPrice: number;
      public MaxVariantPrice: number;
      public MinVariantPrice: number;
      public CustomerContextualPrice: number;
      public DiscountAmount: number;
      public CurrencyCode: string;
      public ItemId: string;
      public InventoryDimensionId: string;
      public UnitOfMeasure: string;
      public ValidFrom: Date;
      public ProductLookupId: number;
      public ChannelId: number;
      public CatalogId: number;
      public DiscountLines: Entities.DiscountLine[];
      public SalesAgreementPrice: number;
      public PriceSourceTypeValue: number;
      public AttainablePriceLines: Entities.PriceLine[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
            this.ListingId = 
                (odataObject.ListingId != null) ? parseInt(odataObject.ListingId, 10) : undefined;
              
            this.BasePrice = 
                (odataObject.BasePrice != null) ? parseFloat(odataObject.BasePrice) : undefined;
              
            this.TradeAgreementPrice = 
                (odataObject.TradeAgreementPrice != null) ? parseFloat(odataObject.TradeAgreementPrice) : undefined;
              
            this.AdjustedPrice = 
                (odataObject.AdjustedPrice != null) ? parseFloat(odataObject.AdjustedPrice) : undefined;
              
            this.MaxVariantPrice = 
                (odataObject.MaxVariantPrice != null) ? parseFloat(odataObject.MaxVariantPrice) : undefined;
              
            this.MinVariantPrice = 
                (odataObject.MinVariantPrice != null) ? parseFloat(odataObject.MinVariantPrice) : undefined;
              
            this.CustomerContextualPrice = 
                (odataObject.CustomerContextualPrice != null) ? parseFloat(odataObject.CustomerContextualPrice) : undefined;
              
            this.DiscountAmount = 
                (odataObject.DiscountAmount != null) ? parseFloat(odataObject.DiscountAmount) : undefined;
              
            this.CurrencyCode = 
                odataObject.CurrencyCode;
              
            this.ItemId = 
                odataObject.ItemId;
              
            this.InventoryDimensionId = 
                odataObject.InventoryDimensionId;
              
            this.UnitOfMeasure = 
                odataObject.UnitOfMeasure;
              
            this.ValidFrom = 
                (odataObject.ValidFrom instanceof Date) ? odataObject.ValidFrom
                : (odataObject.ValidFrom != null) ? jsonLightReadStringPropertyValue(odataObject.ValidFrom, "Edm.DateTimeOffset", false) : undefined;
              
            this.ProductLookupId = 
                (odataObject.ProductLookupId != null) ? parseInt(odataObject.ProductLookupId, 10) : undefined;
              
            this.ChannelId = 
                (odataObject.ChannelId != null) ? parseInt(odataObject.ChannelId, 10) : undefined;
              
            this.CatalogId = 
                (odataObject.CatalogId != null) ? parseInt(odataObject.CatalogId, 10) : undefined;
              
        this.DiscountLines = undefined;
        if (odataObject.DiscountLines) {
        this.DiscountLines = [];
        for (var i = 0; i < odataObject.DiscountLines.length; i++) {
        if (odataObject.DiscountLines[i] != null) {
        if (odataObject.DiscountLines[i]['@odata.type'] != null) {
          var className: string = odataObject.DiscountLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.DiscountLines[i] = new entityDictionary[className](odataObject.DiscountLines[i])
          }
        } else {
            this.DiscountLines[i] = new DiscountLineClass(odataObject.DiscountLines[i]);
        }
                    } else {
        this.DiscountLines[i] = undefined;
        }
        }
        }
      
            this.SalesAgreementPrice = 
                (odataObject.SalesAgreementPrice != null) ? parseFloat(odataObject.SalesAgreementPrice) : undefined;
              
            this.PriceSourceTypeValue = 
                odataObject.PriceSourceTypeValue;
              
        this.AttainablePriceLines = undefined;
        if (odataObject.AttainablePriceLines) {
        this.AttainablePriceLines = [];
        for (var i = 0; i < odataObject.AttainablePriceLines.length; i++) {
        if (odataObject.AttainablePriceLines[i] != null) {
        if (odataObject.AttainablePriceLines[i]['@odata.type'] != null) {
          var className: string = odataObject.AttainablePriceLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.AttainablePriceLines[i] = new entityDictionary[className](odataObject.AttainablePriceLines[i])
          }
        } else {
            this.AttainablePriceLines[i] = new PriceLineClass(odataObject.AttainablePriceLines[i]);
        }
                    } else {
        this.AttainablePriceLines[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ProductPriceClass'] = ProductPriceClass;
    
      /**
      * OrgUnitAvailability entity class.
      */
      export class OrgUnitAvailabilityClass implements Entities.OrgUnitAvailability {
      public OrgUnitLocation: Entities.OrgUnitLocation;
      public ItemAvailabilities: Entities.ItemAvailability[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        if (odataObject.OrgUnitLocation == null) {
            this.OrgUnitLocation = undefined;
        } else if (odataObject.OrgUnitLocation['@odata.type'] == null) {
            this.OrgUnitLocation = new OrgUnitLocationClass(odataObject.OrgUnitLocation);
        } else {
            var className: string = odataObject.OrgUnitLocation['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.OrgUnitLocation = new entityDictionary[className](odataObject.OrgUnitLocation)
            }
        }

      
        this.ItemAvailabilities = undefined;
        if (odataObject.ItemAvailabilities) {
        this.ItemAvailabilities = [];
        for (var i = 0; i < odataObject.ItemAvailabilities.length; i++) {
        if (odataObject.ItemAvailabilities[i] != null) {
        if (odataObject.ItemAvailabilities[i]['@odata.type'] != null) {
          var className: string = odataObject.ItemAvailabilities[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ItemAvailabilities[i] = new entityDictionary[className](odataObject.ItemAvailabilities[i])
          }
        } else {
            this.ItemAvailabilities[i] = new ItemAvailabilityClass(odataObject.ItemAvailabilities[i]);
        }
                    } else {
        this.ItemAvailabilities[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['OrgUnitAvailabilityClass'] = OrgUnitAvailabilityClass;
    
      /**
      * ProductVariantPropertySet entity class.
      */
      export class ProductVariantPropertySetClass implements Entities.ProductVariantPropertySet {
      public VariantId: number;
      public ProductVariantPropertiesAsList: Entities.ProductPropertyTranslation[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.VariantId = 
                (odataObject.VariantId != null) ? parseInt(odataObject.VariantId, 10) : undefined;
              
        this.ProductVariantPropertiesAsList = undefined;
        if (odataObject.ProductVariantPropertiesAsList) {
        this.ProductVariantPropertiesAsList = [];
        for (var i = 0; i < odataObject.ProductVariantPropertiesAsList.length; i++) {
        if (odataObject.ProductVariantPropertiesAsList[i] != null) {
        if (odataObject.ProductVariantPropertiesAsList[i]['@odata.type'] != null) {
          var className: string = odataObject.ProductVariantPropertiesAsList[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ProductVariantPropertiesAsList[i] = new entityDictionary[className](odataObject.ProductVariantPropertiesAsList[i])
          }
        } else {
            this.ProductVariantPropertiesAsList[i] = new ProductPropertyTranslationClass(odataObject.ProductVariantPropertiesAsList[i]);
        }
                    } else {
        this.ProductVariantPropertiesAsList[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ProductVariantPropertySetClass'] = ProductVariantPropertySetClass;
    
      /**
      * PurchaseOrderLine entity class.
      */
      export class PurchaseOrderLineClass implements Entities.PurchaseOrderLine {
      public RecordId: number;
      public OrderId: string;
      public ProductRecordId: number;
      public ItemId: string;
      public ItemName: string;
      public InventDimId: string;
      public QuantityOrdered: number;
      public PurchaseQuantity: number;
      public PurchaseUnit: string;
      public PurchaseReceived: number;
      public PurchaseReceivedNow: number;
      public InventBatchId: string;
      public WMSLocationId: string;
      public WMSPalletId: string;
      public InventoryStatus: string;
      public LicensePlate: string;
      public InventSiteId: string;
      public InventLocationId: string;
      public ConfigId: string;
      public InventSerialId: string;
      public InventSizeId: string;
      public InventColorId: string;
      public InventStyleId: string;
      public IsCommitted: boolean;
      public Message: string;
      public UniqueIdentifier: string;
      public DeliveryMethod: string;
      public DataAreaId: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.OrderId = 
                odataObject.OrderId;
              
            this.ProductRecordId = 
                (odataObject.ProductRecordId != null) ? parseInt(odataObject.ProductRecordId, 10) : undefined;
              
            this.ItemId = 
                odataObject.ItemId;
              
            this.ItemName = 
                odataObject.ItemName;
              
            this.InventDimId = 
                odataObject.InventDimId;
              
            this.QuantityOrdered = 
                (odataObject.QuantityOrdered != null) ? parseFloat(odataObject.QuantityOrdered) : undefined;
              
            this.PurchaseQuantity = 
                (odataObject.PurchaseQuantity != null) ? parseFloat(odataObject.PurchaseQuantity) : undefined;
              
            this.PurchaseUnit = 
                odataObject.PurchaseUnit;
              
            this.PurchaseReceived = 
                (odataObject.PurchaseReceived != null) ? parseFloat(odataObject.PurchaseReceived) : undefined;
              
            this.PurchaseReceivedNow = 
                (odataObject.PurchaseReceivedNow != null) ? parseFloat(odataObject.PurchaseReceivedNow) : undefined;
              
            this.InventBatchId = 
                odataObject.InventBatchId;
              
            this.WMSLocationId = 
                odataObject.WMSLocationId;
              
            this.WMSPalletId = 
                odataObject.WMSPalletId;
              
            this.InventoryStatus = 
                odataObject.InventoryStatus;
              
            this.LicensePlate = 
                odataObject.LicensePlate;
              
            this.InventSiteId = 
                odataObject.InventSiteId;
              
            this.InventLocationId = 
                odataObject.InventLocationId;
              
            this.ConfigId = 
                odataObject.ConfigId;
              
            this.InventSerialId = 
                odataObject.InventSerialId;
              
            this.InventSizeId = 
                odataObject.InventSizeId;
              
            this.InventColorId = 
                odataObject.InventColorId;
              
            this.InventStyleId = 
                odataObject.InventStyleId;
              
            this.IsCommitted = 
                odataObject.IsCommitted;
              
            this.Message = 
                odataObject.Message;
              
            this.UniqueIdentifier = 
                odataObject.UniqueIdentifier;
              
            this.DeliveryMethod = 
                odataObject.DeliveryMethod;
              
            this.DataAreaId = 
                odataObject.DataAreaId;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['PurchaseOrderLineClass'] = PurchaseOrderLineClass;
    
      /**
      * TransferOrderLine entity class.
      */
      export class TransferOrderLineClass implements Entities.TransferOrderLine {
      public RecordId: number;
      public OrderId: string;
      public ProductRecordId: number;
      public ItemId: string;
      public ItemName: string;
      public InventDimId: string;
      public QuantityTransferred: number;
      public QuantityShipped: number;
      public QuantityReceived: number;
      public QuantityShipNow: number;
      public QuantityReceiveNow: number;
      public QuantityRemainShip: number;
      public QuantityRemainReceive: number;
      public PurchaseUnit: string;
      public InventBatchId: string;
      public WMSLocationId: string;
      public WMSPalletId: string;
      public InventoryStatus: string;
      public LicensePlate: string;
      public InventSiteId: string;
      public InventLocationId: string;
      public ConfigId: string;
      public InventSerialId: string;
      public InventSizeId: string;
      public InventColorId: string;
      public InventStyleId: string;
      public IsCommitted: boolean;
      public Message: string;
      public Guid: string;
      public DeliveryMethod: string;
      public DataAreaId: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.OrderId = 
                odataObject.OrderId;
              
            this.ProductRecordId = 
                (odataObject.ProductRecordId != null) ? parseInt(odataObject.ProductRecordId, 10) : undefined;
              
            this.ItemId = 
                odataObject.ItemId;
              
            this.ItemName = 
                odataObject.ItemName;
              
            this.InventDimId = 
                odataObject.InventDimId;
              
            this.QuantityTransferred = 
                (odataObject.QuantityTransferred != null) ? parseFloat(odataObject.QuantityTransferred) : undefined;
              
            this.QuantityShipped = 
                (odataObject.QuantityShipped != null) ? parseFloat(odataObject.QuantityShipped) : undefined;
              
            this.QuantityReceived = 
                (odataObject.QuantityReceived != null) ? parseFloat(odataObject.QuantityReceived) : undefined;
              
            this.QuantityShipNow = 
                (odataObject.QuantityShipNow != null) ? parseFloat(odataObject.QuantityShipNow) : undefined;
              
            this.QuantityReceiveNow = 
                (odataObject.QuantityReceiveNow != null) ? parseFloat(odataObject.QuantityReceiveNow) : undefined;
              
            this.QuantityRemainShip = 
                (odataObject.QuantityRemainShip != null) ? parseFloat(odataObject.QuantityRemainShip) : undefined;
              
            this.QuantityRemainReceive = 
                (odataObject.QuantityRemainReceive != null) ? parseFloat(odataObject.QuantityRemainReceive) : undefined;
              
            this.PurchaseUnit = 
                odataObject.PurchaseUnit;
              
            this.InventBatchId = 
                odataObject.InventBatchId;
              
            this.WMSLocationId = 
                odataObject.WMSLocationId;
              
            this.WMSPalletId = 
                odataObject.WMSPalletId;
              
            this.InventoryStatus = 
                odataObject.InventoryStatus;
              
            this.LicensePlate = 
                odataObject.LicensePlate;
              
            this.InventSiteId = 
                odataObject.InventSiteId;
              
            this.InventLocationId = 
                odataObject.InventLocationId;
              
            this.ConfigId = 
                odataObject.ConfigId;
              
            this.InventSerialId = 
                odataObject.InventSerialId;
              
            this.InventSizeId = 
                odataObject.InventSizeId;
              
            this.InventColorId = 
                odataObject.InventColorId;
              
            this.InventStyleId = 
                odataObject.InventStyleId;
              
            this.IsCommitted = 
                odataObject.IsCommitted;
              
            this.Message = 
                odataObject.Message;
              
            this.Guid = 
                odataObject.Guid;
              
            this.DeliveryMethod = 
                odataObject.DeliveryMethod;
              
            this.DataAreaId = 
                odataObject.DataAreaId;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['TransferOrderLineClass'] = TransferOrderLineClass;
    
      /**
      * TransferOrderJournal entity class.
      */
      export class TransferOrderJournalClass implements Entities.TransferOrderJournal {
      public OrderId: string;
      public VoucherId: string;
      public UpdatedByWorker: string;
      public InventLocationIdFrom: string;
      public InventLocationIdTo: string;
      public QuantityShipped: number;
      public PostingDate: Date;
      public DeliveryMode: string;
      public Comments: string;
      public JournalLines: Entities.TransferOrderJournalLine[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.OrderId = 
                odataObject.OrderId;
              
            this.VoucherId = 
                odataObject.VoucherId;
              
            this.UpdatedByWorker = 
                odataObject.UpdatedByWorker;
              
            this.InventLocationIdFrom = 
                odataObject.InventLocationIdFrom;
              
            this.InventLocationIdTo = 
                odataObject.InventLocationIdTo;
              
            this.QuantityShipped = 
                (odataObject.QuantityShipped != null) ? parseFloat(odataObject.QuantityShipped) : undefined;
              
            this.PostingDate = 
                (odataObject.PostingDate instanceof Date) ? odataObject.PostingDate
                : (odataObject.PostingDate != null) ? jsonLightReadStringPropertyValue(odataObject.PostingDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.DeliveryMode = 
                odataObject.DeliveryMode;
              
            this.Comments = 
                odataObject.Comments;
              
        this.JournalLines = undefined;
        if (odataObject.JournalLines) {
        this.JournalLines = [];
        for (var i = 0; i < odataObject.JournalLines.length; i++) {
        if (odataObject.JournalLines[i] != null) {
        if (odataObject.JournalLines[i]['@odata.type'] != null) {
          var className: string = odataObject.JournalLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.JournalLines[i] = new entityDictionary[className](odataObject.JournalLines[i])
          }
        } else {
            this.JournalLines[i] = new TransferOrderJournalLineClass(odataObject.JournalLines[i]);
        }
                    } else {
        this.JournalLines[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['TransferOrderJournalClass'] = TransferOrderJournalClass;
    
      /**
      * PickingListLine entity class.
      */
      export class PickingListLineClass implements Entities.PickingListLine {
      public RecordId: number;
      public OrderId: string;
      public ProductRecordId: number;
      public ItemId: string;
      public ItemName: string;
      public InventDimId: string;
      public QuantityOrdered: number;
      public PurchaseReceivedNow: number;
      public InventBatchId: string;
      public WMSLocationId: string;
      public WMSPalletId: string;
      public InventoryStatus: string;
      public LicensePlate: string;
      public InventSiteId: string;
      public InventLocationId: string;
      public ConfigId: string;
      public InventSerialId: string;
      public InventSizeId: string;
      public InventColorId: string;
      public InventStyleId: string;
      public IsCommitted: boolean;
      public Message: string;
      public Guid: string;
      public DeliveryMethod: string;
      public DataAreaId: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.OrderId = 
                odataObject.OrderId;
              
            this.ProductRecordId = 
                (odataObject.ProductRecordId != null) ? parseInt(odataObject.ProductRecordId, 10) : undefined;
              
            this.ItemId = 
                odataObject.ItemId;
              
            this.ItemName = 
                odataObject.ItemName;
              
            this.InventDimId = 
                odataObject.InventDimId;
              
            this.QuantityOrdered = 
                (odataObject.QuantityOrdered != null) ? parseFloat(odataObject.QuantityOrdered) : undefined;
              
            this.PurchaseReceivedNow = 
                (odataObject.PurchaseReceivedNow != null) ? parseFloat(odataObject.PurchaseReceivedNow) : undefined;
              
            this.InventBatchId = 
                odataObject.InventBatchId;
              
            this.WMSLocationId = 
                odataObject.WMSLocationId;
              
            this.WMSPalletId = 
                odataObject.WMSPalletId;
              
            this.InventoryStatus = 
                odataObject.InventoryStatus;
              
            this.LicensePlate = 
                odataObject.LicensePlate;
              
            this.InventSiteId = 
                odataObject.InventSiteId;
              
            this.InventLocationId = 
                odataObject.InventLocationId;
              
            this.ConfigId = 
                odataObject.ConfigId;
              
            this.InventSerialId = 
                odataObject.InventSerialId;
              
            this.InventSizeId = 
                odataObject.InventSizeId;
              
            this.InventColorId = 
                odataObject.InventColorId;
              
            this.InventStyleId = 
                odataObject.InventStyleId;
              
            this.IsCommitted = 
                odataObject.IsCommitted;
              
            this.Message = 
                odataObject.Message;
              
            this.Guid = 
                odataObject.Guid;
              
            this.DeliveryMethod = 
                odataObject.DeliveryMethod;
              
            this.DataAreaId = 
                odataObject.DataAreaId;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['PickingListLineClass'] = PickingListLineClass;
    
      /**
      * Affiliation entity class.
      */
      export class AffiliationClass implements Entities.Affiliation {
      public RecordId: number;
      public AffiliationTypeValue: number;
      public Description: string;
      public Name: string;
      public IsHidden: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.AffiliationTypeValue = 
                odataObject.AffiliationTypeValue;
              
            this.Description = 
                odataObject.Description;
              
            this.Name = 
                odataObject.Name;
              
            this.IsHidden = 
                odataObject.IsHidden;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['AffiliationClass'] = AffiliationClass;
    
      /**
      * InventoryInboundOutboundDocumentLinesSummary entity class.
      */
      export class InventoryInboundOutboundDocumentLinesSummaryClass implements Entities.InventoryInboundOutboundDocumentLinesSummary {
      public InProgressQuantity: number;
      public TotalLineNumber: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.InProgressQuantity = 
                (odataObject.InProgressQuantity != null) ? parseFloat(odataObject.InProgressQuantity) : undefined;
              
            this.TotalLineNumber = 
                odataObject.TotalLineNumber;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['InventoryInboundOutboundDocumentLinesSummaryClass'] = InventoryInboundOutboundDocumentLinesSummaryClass;
    
      /**
      * InventoryDocumentSerialNumberLinesSummary entity class.
      */
      export class InventoryDocumentSerialNumberLinesSummaryClass implements Entities.InventoryDocumentSerialNumberLinesSummary {
      public RegisteringQuantity: number;
      public RegisteredQuantity: number;
      public TotalLineNumber: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RegisteringQuantity = 
                (odataObject.RegisteringQuantity != null) ? parseFloat(odataObject.RegisteringQuantity) : undefined;
              
            this.RegisteredQuantity = 
                (odataObject.RegisteredQuantity != null) ? parseFloat(odataObject.RegisteredQuantity) : undefined;
              
            this.TotalLineNumber = 
                odataObject.TotalLineNumber;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['InventoryDocumentSerialNumberLinesSummaryClass'] = InventoryDocumentSerialNumberLinesSummaryClass;
    
      /**
      * InventoryInboundOutboundDocumentLine entity class.
      */
      export class InventoryInboundOutboundDocumentLineClass implements Entities.InventoryInboundOutboundDocumentLine {
      public WorkDocumentId: string;
      public LineId: string;
      public SourceDocumentRecordId: number;
      public SourceDocumentLineRecordId: number;
      public SourceDocumentTypeValue: number;
      public QuantityOrdered: number;
      public QuantityShipped: number;
      public QuantityReceived: number;
      public QuantityCancelled: number;
      public QuantityToUpdate: number;
      public LocationId: string;
      public ItemId: string;
      public ProductId: number;
      public ItemName: string;
      public ItemPrimaryImageUrl: string;
      public IsLocationActive: boolean;
      public IsLocationAllowBlankReceiptEnabled: boolean;
      public IsLocationBlankIssueAllowed: boolean;
      public ProductDimensions: Entities.ProductDimension[];
      public Note: string;
      public NoteModifiedByStaffId: string;
      public NoteModifiedDateTime: Date;
      public OperationResultMessage: string;
      public IsCloseLine: boolean;
      public OriginTypeValue: number;
      public UnitOfMeasure: string;
      public SourceDocumentLine: Entities.InventoryInboundOutboundSourceDocumentLine;
      public SerialNumberLines: Entities.InventoryDocumentSerialNumberLine[];
      public TrackingDimensionLines: Entities.InventoryDocumentTrackingDimensionLine[];
      public LongVersion: number;
      public ToLocationId: string;
      public AdjustmentTypeValue: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.WorkDocumentId = 
                odataObject.WorkDocumentId;
              
            this.LineId = 
                odataObject.LineId;
              
            this.SourceDocumentRecordId = 
                (odataObject.SourceDocumentRecordId != null) ? parseInt(odataObject.SourceDocumentRecordId, 10) : undefined;
              
            this.SourceDocumentLineRecordId = 
                (odataObject.SourceDocumentLineRecordId != null) ? parseInt(odataObject.SourceDocumentLineRecordId, 10) : undefined;
              
            this.SourceDocumentTypeValue = 
                odataObject.SourceDocumentTypeValue;
              
            this.QuantityOrdered = 
                (odataObject.QuantityOrdered != null) ? parseFloat(odataObject.QuantityOrdered) : undefined;
              
            this.QuantityShipped = 
                (odataObject.QuantityShipped != null) ? parseFloat(odataObject.QuantityShipped) : undefined;
              
            this.QuantityReceived = 
                (odataObject.QuantityReceived != null) ? parseFloat(odataObject.QuantityReceived) : undefined;
              
            this.QuantityCancelled = 
                (odataObject.QuantityCancelled != null) ? parseFloat(odataObject.QuantityCancelled) : undefined;
              
            this.QuantityToUpdate = 
                (odataObject.QuantityToUpdate != null) ? parseFloat(odataObject.QuantityToUpdate) : undefined;
              
            this.LocationId = 
                odataObject.LocationId;
              
            this.ItemId = 
                odataObject.ItemId;
              
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
            this.ItemName = 
                odataObject.ItemName;
              
            this.ItemPrimaryImageUrl = 
                odataObject.ItemPrimaryImageUrl;
              
            this.IsLocationActive = 
                odataObject.IsLocationActive;
              
            this.IsLocationAllowBlankReceiptEnabled = 
                odataObject.IsLocationAllowBlankReceiptEnabled;
              
            this.IsLocationBlankIssueAllowed = 
                odataObject.IsLocationBlankIssueAllowed;
              
        this.ProductDimensions = undefined;
        if (odataObject.ProductDimensions) {
        this.ProductDimensions = [];
        for (var i = 0; i < odataObject.ProductDimensions.length; i++) {
        if (odataObject.ProductDimensions[i] != null) {
        if (odataObject.ProductDimensions[i]['@odata.type'] != null) {
          var className: string = odataObject.ProductDimensions[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ProductDimensions[i] = new entityDictionary[className](odataObject.ProductDimensions[i])
          }
        } else {
            this.ProductDimensions[i] = new ProductDimensionClass(odataObject.ProductDimensions[i]);
        }
                    } else {
        this.ProductDimensions[i] = undefined;
        }
        }
        }
      
            this.Note = 
                odataObject.Note;
              
            this.NoteModifiedByStaffId = 
                odataObject.NoteModifiedByStaffId;
              
            this.NoteModifiedDateTime = 
                (odataObject.NoteModifiedDateTime instanceof Date) ? odataObject.NoteModifiedDateTime
                : (odataObject.NoteModifiedDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.NoteModifiedDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.OperationResultMessage = 
                odataObject.OperationResultMessage;
              
            this.IsCloseLine = 
                odataObject.IsCloseLine;
              
            this.OriginTypeValue = 
                odataObject.OriginTypeValue;
              
            this.UnitOfMeasure = 
                odataObject.UnitOfMeasure;
              
        if (odataObject.SourceDocumentLine == null) {
            this.SourceDocumentLine = undefined;
        } else if (odataObject.SourceDocumentLine['@odata.type'] == null) {
            this.SourceDocumentLine = new InventoryInboundOutboundSourceDocumentLineClass(odataObject.SourceDocumentLine);
        } else {
            var className: string = odataObject.SourceDocumentLine['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.SourceDocumentLine = new entityDictionary[className](odataObject.SourceDocumentLine)
            }
        }

      
        this.SerialNumberLines = undefined;
        if (odataObject.SerialNumberLines) {
        this.SerialNumberLines = [];
        for (var i = 0; i < odataObject.SerialNumberLines.length; i++) {
        if (odataObject.SerialNumberLines[i] != null) {
        if (odataObject.SerialNumberLines[i]['@odata.type'] != null) {
          var className: string = odataObject.SerialNumberLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.SerialNumberLines[i] = new entityDictionary[className](odataObject.SerialNumberLines[i])
          }
        } else {
            this.SerialNumberLines[i] = new InventoryDocumentSerialNumberLineClass(odataObject.SerialNumberLines[i]);
        }
                    } else {
        this.SerialNumberLines[i] = undefined;
        }
        }
        }
      
        this.TrackingDimensionLines = undefined;
        if (odataObject.TrackingDimensionLines) {
        this.TrackingDimensionLines = [];
        for (var i = 0; i < odataObject.TrackingDimensionLines.length; i++) {
        if (odataObject.TrackingDimensionLines[i] != null) {
        if (odataObject.TrackingDimensionLines[i]['@odata.type'] != null) {
          var className: string = odataObject.TrackingDimensionLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.TrackingDimensionLines[i] = new entityDictionary[className](odataObject.TrackingDimensionLines[i])
          }
        } else {
            this.TrackingDimensionLines[i] = new InventoryDocumentTrackingDimensionLineClass(odataObject.TrackingDimensionLines[i]);
        }
                    } else {
        this.TrackingDimensionLines[i] = undefined;
        }
        }
        }
      
            this.LongVersion = 
                (odataObject.LongVersion != null) ? parseInt(odataObject.LongVersion, 10) : undefined;
              
            this.ToLocationId = 
                odataObject.ToLocationId;
              
            this.AdjustmentTypeValue = 
                odataObject.AdjustmentTypeValue;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['InventoryInboundOutboundDocumentLineClass'] = InventoryInboundOutboundDocumentLineClass;
    
      /**
      * InventoryInboundOutboundSourceDocument entity class.
      */
      export class InventoryInboundOutboundSourceDocumentClass implements Entities.InventoryInboundOutboundSourceDocument {
      public RecordId: number;
      public DocumentTypeValue: number;
      public DocumentId: string;
      public StatusValue: number;
      public ReceiveDate: Date;
      public ShipDate: Date;
      public Sender: string;
      public Receiver: string;
      public SenderDetails: string;
      public ReceiverDetails: string;
      public DeliveryMode: string;
      public TotalQuantityOrdered: number;
      public TotalQuantityShipped: number;
      public TotalQuantityReceived: number;
      public TotalQuantityCancelled: number;
      public DataAreaId: string;
      public Note: string;
      public TotalLines: number;
      public IsChangeAllowed: boolean;
      public NoteModifiedByStaffId: string;
      public NoteModifiedDateTime: Date;
      public DocumentTypeId: string;
      public Description: string;
      public StaffId: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.DocumentTypeValue = 
                odataObject.DocumentTypeValue;
              
            this.DocumentId = 
                odataObject.DocumentId;
              
            this.StatusValue = 
                odataObject.StatusValue;
              
            this.ReceiveDate = 
                (odataObject.ReceiveDate instanceof Date) ? odataObject.ReceiveDate
                : (odataObject.ReceiveDate != null) ? jsonLightReadStringPropertyValue(odataObject.ReceiveDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.ShipDate = 
                (odataObject.ShipDate instanceof Date) ? odataObject.ShipDate
                : (odataObject.ShipDate != null) ? jsonLightReadStringPropertyValue(odataObject.ShipDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.Sender = 
                odataObject.Sender;
              
            this.Receiver = 
                odataObject.Receiver;
              
            this.SenderDetails = 
                odataObject.SenderDetails;
              
            this.ReceiverDetails = 
                odataObject.ReceiverDetails;
              
            this.DeliveryMode = 
                odataObject.DeliveryMode;
              
            this.TotalQuantityOrdered = 
                (odataObject.TotalQuantityOrdered != null) ? parseFloat(odataObject.TotalQuantityOrdered) : undefined;
              
            this.TotalQuantityShipped = 
                (odataObject.TotalQuantityShipped != null) ? parseFloat(odataObject.TotalQuantityShipped) : undefined;
              
            this.TotalQuantityReceived = 
                (odataObject.TotalQuantityReceived != null) ? parseFloat(odataObject.TotalQuantityReceived) : undefined;
              
            this.TotalQuantityCancelled = 
                (odataObject.TotalQuantityCancelled != null) ? parseFloat(odataObject.TotalQuantityCancelled) : undefined;
              
            this.DataAreaId = 
                odataObject.DataAreaId;
              
            this.Note = 
                odataObject.Note;
              
            this.TotalLines = 
                (odataObject.TotalLines != null) ? parseInt(odataObject.TotalLines, 10) : undefined;
              
            this.IsChangeAllowed = 
                odataObject.IsChangeAllowed;
              
            this.NoteModifiedByStaffId = 
                odataObject.NoteModifiedByStaffId;
              
            this.NoteModifiedDateTime = 
                (odataObject.NoteModifiedDateTime instanceof Date) ? odataObject.NoteModifiedDateTime
                : (odataObject.NoteModifiedDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.NoteModifiedDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.DocumentTypeId = 
                odataObject.DocumentTypeId;
              
            this.Description = 
                odataObject.Description;
              
            this.StaffId = 
                odataObject.StaffId;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['InventoryInboundOutboundSourceDocumentClass'] = InventoryInboundOutboundSourceDocumentClass;
    
      /**
      * InventoryInboundOutboundSourceDocumentLine entity class.
      */
      export class InventoryInboundOutboundSourceDocumentLineClass implements Entities.InventoryInboundOutboundSourceDocumentLine {
      public DocumentRecordId: number;
      public DocumentTypeValue: number;
      public RecordId: number;
      public LineNumber: number;
      public ItemId: string;
      public ProductId: number;
      public QuantityOrdered: number;
      public QuantityShipped: number;
      public QuantityReceived: number;
      public QuantityCancelled: number;
      public LocationId: string;
      public OverDeliveryPercentage: number;
      public UnderDeliveryPercentage: number;
      public UnitId: string;
      public Receiver: string;
      public DataAreaId: string;
      public Note: string;
      public NoteModifiedByStaffId: string;
      public NoteModifiedDateTime: Date;
      public DocumentId: string;
      public ItemName: string;
      public ItemPrimaryImageUrl: string;
      public IsLocationActive: boolean;
      public IsLocationAllowBlankReceiptEnabled: boolean;
      public IsLocationBlankIssueAllowed: boolean;
      public HasSerialNumber: boolean;
      public IsSerialNumberBlankReceiptAllowed: boolean;
      public IsSerialNumberDuplicateCheckEnabled: boolean;
      public ProductDimensions: Entities.ProductDimension[];
      public SerialNumberLines: Entities.InventoryDocumentSourceSerialNumberLine[];
      public TrackingDimensionLines: Entities.InventoryDocumentSourceTrackingDimensionLine[];
      public StatusValue: number;
      public ToProductId: number;
      public ToLocationId: string;
      public InventoryDimensionId: string;
      public ToInventoryDimensionId: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.DocumentRecordId = 
                (odataObject.DocumentRecordId != null) ? parseInt(odataObject.DocumentRecordId, 10) : undefined;
              
            this.DocumentTypeValue = 
                odataObject.DocumentTypeValue;
              
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.LineNumber = 
                odataObject.LineNumber;
              
            this.ItemId = 
                odataObject.ItemId;
              
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
            this.QuantityOrdered = 
                (odataObject.QuantityOrdered != null) ? parseFloat(odataObject.QuantityOrdered) : undefined;
              
            this.QuantityShipped = 
                (odataObject.QuantityShipped != null) ? parseFloat(odataObject.QuantityShipped) : undefined;
              
            this.QuantityReceived = 
                (odataObject.QuantityReceived != null) ? parseFloat(odataObject.QuantityReceived) : undefined;
              
            this.QuantityCancelled = 
                (odataObject.QuantityCancelled != null) ? parseFloat(odataObject.QuantityCancelled) : undefined;
              
            this.LocationId = 
                odataObject.LocationId;
              
            this.OverDeliveryPercentage = 
                (odataObject.OverDeliveryPercentage != null) ? parseFloat(odataObject.OverDeliveryPercentage) : undefined;
              
            this.UnderDeliveryPercentage = 
                (odataObject.UnderDeliveryPercentage != null) ? parseFloat(odataObject.UnderDeliveryPercentage) : undefined;
              
            this.UnitId = 
                odataObject.UnitId;
              
            this.Receiver = 
                odataObject.Receiver;
              
            this.DataAreaId = 
                odataObject.DataAreaId;
              
            this.Note = 
                odataObject.Note;
              
            this.NoteModifiedByStaffId = 
                odataObject.NoteModifiedByStaffId;
              
            this.NoteModifiedDateTime = 
                (odataObject.NoteModifiedDateTime instanceof Date) ? odataObject.NoteModifiedDateTime
                : (odataObject.NoteModifiedDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.NoteModifiedDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.DocumentId = 
                odataObject.DocumentId;
              
            this.ItemName = 
                odataObject.ItemName;
              
            this.ItemPrimaryImageUrl = 
                odataObject.ItemPrimaryImageUrl;
              
            this.IsLocationActive = 
                odataObject.IsLocationActive;
              
            this.IsLocationAllowBlankReceiptEnabled = 
                odataObject.IsLocationAllowBlankReceiptEnabled;
              
            this.IsLocationBlankIssueAllowed = 
                odataObject.IsLocationBlankIssueAllowed;
              
            this.HasSerialNumber = 
                odataObject.HasSerialNumber;
              
            this.IsSerialNumberBlankReceiptAllowed = 
                odataObject.IsSerialNumberBlankReceiptAllowed;
              
            this.IsSerialNumberDuplicateCheckEnabled = 
                odataObject.IsSerialNumberDuplicateCheckEnabled;
              
        this.ProductDimensions = undefined;
        if (odataObject.ProductDimensions) {
        this.ProductDimensions = [];
        for (var i = 0; i < odataObject.ProductDimensions.length; i++) {
        if (odataObject.ProductDimensions[i] != null) {
        if (odataObject.ProductDimensions[i]['@odata.type'] != null) {
          var className: string = odataObject.ProductDimensions[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ProductDimensions[i] = new entityDictionary[className](odataObject.ProductDimensions[i])
          }
        } else {
            this.ProductDimensions[i] = new ProductDimensionClass(odataObject.ProductDimensions[i]);
        }
                    } else {
        this.ProductDimensions[i] = undefined;
        }
        }
        }
      
        this.SerialNumberLines = undefined;
        if (odataObject.SerialNumberLines) {
        this.SerialNumberLines = [];
        for (var i = 0; i < odataObject.SerialNumberLines.length; i++) {
        if (odataObject.SerialNumberLines[i] != null) {
        if (odataObject.SerialNumberLines[i]['@odata.type'] != null) {
          var className: string = odataObject.SerialNumberLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.SerialNumberLines[i] = new entityDictionary[className](odataObject.SerialNumberLines[i])
          }
        } else {
            this.SerialNumberLines[i] = new InventoryDocumentSourceSerialNumberLineClass(odataObject.SerialNumberLines[i]);
        }
                    } else {
        this.SerialNumberLines[i] = undefined;
        }
        }
        }
      
        this.TrackingDimensionLines = undefined;
        if (odataObject.TrackingDimensionLines) {
        this.TrackingDimensionLines = [];
        for (var i = 0; i < odataObject.TrackingDimensionLines.length; i++) {
        if (odataObject.TrackingDimensionLines[i] != null) {
        if (odataObject.TrackingDimensionLines[i]['@odata.type'] != null) {
          var className: string = odataObject.TrackingDimensionLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.TrackingDimensionLines[i] = new entityDictionary[className](odataObject.TrackingDimensionLines[i])
          }
        } else {
            this.TrackingDimensionLines[i] = new InventoryDocumentSourceTrackingDimensionLineClass(odataObject.TrackingDimensionLines[i]);
        }
                    } else {
        this.TrackingDimensionLines[i] = undefined;
        }
        }
        }
      
            this.StatusValue = 
                odataObject.StatusValue;
              
            this.ToProductId = 
                (odataObject.ToProductId != null) ? parseInt(odataObject.ToProductId, 10) : undefined;
              
            this.ToLocationId = 
                odataObject.ToLocationId;
              
            this.InventoryDimensionId = 
                odataObject.InventoryDimensionId;
              
            this.ToInventoryDimensionId = 
                odataObject.ToInventoryDimensionId;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['InventoryInboundOutboundSourceDocumentLineClass'] = InventoryInboundOutboundSourceDocumentLineClass;
    
      /**
      * InventoryDocumentLineFilterCriteria entity class.
      */
      export class InventoryDocumentLineFilterCriteriaClass implements Entities.InventoryDocumentLineFilterCriteria {
      public ProductId: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['InventoryDocumentLineFilterCriteriaClass'] = InventoryDocumentLineFilterCriteriaClass;
    
      /**
      * InventoryDocumentUpdateLinesAction entity class.
      */
      export class InventoryDocumentUpdateLinesActionClass implements Entities.InventoryDocumentUpdateLinesAction {
      public Quantity: number;
      public ActionTypeValue: number;
      public LocationId: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Quantity = 
                (odataObject.Quantity != null) ? parseFloat(odataObject.Quantity) : undefined;
              
            this.ActionTypeValue = 
                odataObject.ActionTypeValue;
              
            this.LocationId = 
                odataObject.LocationId;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['InventoryDocumentUpdateLinesActionClass'] = InventoryDocumentUpdateLinesActionClass;
    
      /**
      * InventoryDocumentLineOperationResultSettings entity class.
      */
      export class InventoryDocumentLineOperationResultSettingsClass implements Entities.InventoryDocumentLineOperationResultSettings {
      public ModeValue: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ModeValue = 
                odataObject.ModeValue;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['InventoryDocumentLineOperationResultSettingsClass'] = InventoryDocumentLineOperationResultSettingsClass;
    
      /**
      * InventoryDocumentLineSearchCriteria entity class.
      */
      export class InventoryDocumentLineSearchCriteriaClass implements Entities.InventoryDocumentLineSearchCriteria {
      public SourceDocumentId: string;
      public SourceDocumentTypeValue: number;
      public WorkDocumentId: string;
      public ProductId: number;
      public ItemIds: string[];
      public AvailableSerializedLineOnly: boolean;
      public DocumentLineIds: string[];
      public DocumentOperationTypeValue: number;
      public OperationTypeValue: number;
      public LocationId: string;
      public ToLocationId: string;
      public AdjustmentTypeValue: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.SourceDocumentId = 
                odataObject.SourceDocumentId;
              
            this.SourceDocumentTypeValue = 
                odataObject.SourceDocumentTypeValue;
              
            this.WorkDocumentId = 
                odataObject.WorkDocumentId;
              
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
        this.ItemIds = undefined;
        if (odataObject.ItemIds) {
        this.ItemIds = [];
        for (var i = 0; i < odataObject.ItemIds.length; i++) {
        if (odataObject.ItemIds[i] != null) {
            this.ItemIds[i] = 
                odataObject.ItemIds[i];
                            } else {
        this.ItemIds[i] = undefined;
        }
        }
        }
      
            this.AvailableSerializedLineOnly = 
                odataObject.AvailableSerializedLineOnly;
              
        this.DocumentLineIds = undefined;
        if (odataObject.DocumentLineIds) {
        this.DocumentLineIds = [];
        for (var i = 0; i < odataObject.DocumentLineIds.length; i++) {
        if (odataObject.DocumentLineIds[i] != null) {
            this.DocumentLineIds[i] = 
                odataObject.DocumentLineIds[i];
                            } else {
        this.DocumentLineIds[i] = undefined;
        }
        }
        }
      
            this.DocumentOperationTypeValue = 
                odataObject.DocumentOperationTypeValue;
              
            this.OperationTypeValue = 
                odataObject.OperationTypeValue;
              
            this.LocationId = 
                odataObject.LocationId;
              
            this.ToLocationId = 
                odataObject.ToLocationId;
              
            this.AdjustmentTypeValue = 
                odataObject.AdjustmentTypeValue;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['InventoryDocumentLineSearchCriteriaClass'] = InventoryDocumentLineSearchCriteriaClass;
    
      /**
      * InventoryDocumentSearchCriteria entity class.
      */
      export class InventoryDocumentSearchCriteriaClass implements Entities.InventoryDocumentSearchCriteria {
      public DocumentId: string;
      public TransferFromWarehouse: string;
      public ShipFromWarehouse: string;
      public TransferToWarehouse: string;
      public Description: string;
      public DocumentTypeIds: string[];
      public DocumentStateValue: number;
      public DocumentTypeValues: number[];
      public CustomFilters: Entities.SearchFilter[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.DocumentId = 
                odataObject.DocumentId;
              
            this.TransferFromWarehouse = 
                odataObject.TransferFromWarehouse;
              
            this.ShipFromWarehouse = 
                odataObject.ShipFromWarehouse;
              
            this.TransferToWarehouse = 
                odataObject.TransferToWarehouse;
              
            this.Description = 
                odataObject.Description;
              
        this.DocumentTypeIds = undefined;
        if (odataObject.DocumentTypeIds) {
        this.DocumentTypeIds = [];
        for (var i = 0; i < odataObject.DocumentTypeIds.length; i++) {
        if (odataObject.DocumentTypeIds[i] != null) {
            this.DocumentTypeIds[i] = 
                odataObject.DocumentTypeIds[i];
                            } else {
        this.DocumentTypeIds[i] = undefined;
        }
        }
        }
      
            this.DocumentStateValue = 
                odataObject.DocumentStateValue;
              
        this.DocumentTypeValues = undefined;
        if (odataObject.DocumentTypeValues) {
        this.DocumentTypeValues = [];
        for (var i = 0; i < odataObject.DocumentTypeValues.length; i++) {
        if (odataObject.DocumentTypeValues[i] != null) {
            this.DocumentTypeValues[i] = 
                odataObject.DocumentTypeValues[i];
                            } else {
        this.DocumentTypeValues[i] = undefined;
        }
        }
        }
      
        this.CustomFilters = undefined;
        if (odataObject.CustomFilters) {
        this.CustomFilters = [];
        for (var i = 0; i < odataObject.CustomFilters.length; i++) {
        if (odataObject.CustomFilters[i] != null) {
        if (odataObject.CustomFilters[i]['@odata.type'] != null) {
          var className: string = odataObject.CustomFilters[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.CustomFilters[i] = new entityDictionary[className](odataObject.CustomFilters[i])
          }
        } else {
            this.CustomFilters[i] = new SearchFilterClass(odataObject.CustomFilters[i]);
        }
                    } else {
        this.CustomFilters[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['InventoryDocumentSearchCriteriaClass'] = InventoryDocumentSearchCriteriaClass;
    
      /**
      * InventoryDocumentSerialNumberLineSearchCriteria entity class.
      */
      export class InventoryDocumentSerialNumberLineSearchCriteriaClass implements Entities.InventoryDocumentSerialNumberLineSearchCriteria {
      public DocumentLineId: string;
      public SerialNumber: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.DocumentLineId = 
                odataObject.DocumentLineId;
              
            this.SerialNumber = 
                odataObject.SerialNumber;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['InventoryDocumentSerialNumberLineSearchCriteriaClass'] = InventoryDocumentSerialNumberLineSearchCriteriaClass;
    
      /**
      * InventoryDocumentSerialNumberLineUpdateAction entity class.
      */
      export class InventoryDocumentSerialNumberLineUpdateActionClass implements Entities.InventoryDocumentSerialNumberLineUpdateAction {
      public DocumentLineId: string;
      public LineId: string;
      public SerialNumber: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.DocumentLineId = 
                odataObject.DocumentLineId;
              
            this.LineId = 
                odataObject.LineId;
              
            this.SerialNumber = 
                odataObject.SerialNumber;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['InventoryDocumentSerialNumberLineUpdateActionClass'] = InventoryDocumentSerialNumberLineUpdateActionClass;
    
      /**
      * InventoryDocumentSerialNumberLineResetAction entity class.
      */
      export class InventoryDocumentSerialNumberLineResetActionClass implements Entities.InventoryDocumentSerialNumberLineResetAction {
      public DocumentLineId: string;
      public LineId: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.DocumentLineId = 
                odataObject.DocumentLineId;
              
            this.LineId = 
                odataObject.LineId;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['InventoryDocumentSerialNumberLineResetActionClass'] = InventoryDocumentSerialNumberLineResetActionClass;
    
      /**
      * InventoryInboundOutboundDocumentUpdateLinesByCriteriaResult entity class.
      */
      export class InventoryInboundOutboundDocumentUpdateLinesByCriteriaResultClass implements Entities.InventoryInboundOutboundDocumentUpdateLinesByCriteriaResult {
      public UpdatedLines: Entities.InventoryInboundOutboundDocumentLine[];
      public TotalLinesUpdated: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.UpdatedLines = undefined;
        if (odataObject.UpdatedLines) {
        this.UpdatedLines = [];
        for (var i = 0; i < odataObject.UpdatedLines.length; i++) {
        if (odataObject.UpdatedLines[i] != null) {
        if (odataObject.UpdatedLines[i]['@odata.type'] != null) {
          var className: string = odataObject.UpdatedLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.UpdatedLines[i] = new entityDictionary[className](odataObject.UpdatedLines[i])
          }
        } else {
            this.UpdatedLines[i] = new InventoryInboundOutboundDocumentLineClass(odataObject.UpdatedLines[i]);
        }
                    } else {
        this.UpdatedLines[i] = undefined;
        }
        }
        }
      
            this.TotalLinesUpdated = 
                odataObject.TotalLinesUpdated;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['InventoryInboundOutboundDocumentUpdateLinesByCriteriaResultClass'] = InventoryInboundOutboundDocumentUpdateLinesByCriteriaResultClass;
    
      /**
      * WarehouseLocationSearchCriteria entity class.
      */
      export class WarehouseLocationSearchCriteriaClass implements Entities.WarehouseLocationSearchCriteria {
      public WarehouseIds: string[];
      public IsLocationProfileRequired: boolean;
      public SearchText: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.WarehouseIds = undefined;
        if (odataObject.WarehouseIds) {
        this.WarehouseIds = [];
        for (var i = 0; i < odataObject.WarehouseIds.length; i++) {
        if (odataObject.WarehouseIds[i] != null) {
            this.WarehouseIds[i] = 
                odataObject.WarehouseIds[i];
                            } else {
        this.WarehouseIds[i] = undefined;
        }
        }
        }
      
            this.IsLocationProfileRequired = 
                odataObject.IsLocationProfileRequired;
              
            this.SearchText = 
                odataObject.SearchText;
              
      }
      }
      entityDictionary['WarehouseLocationSearchCriteriaClass'] = WarehouseLocationSearchCriteriaClass;
    
      /**
      * InventoryDocumentSerialNumberLine entity class.
      */
      export class InventoryDocumentSerialNumberLineClass implements Entities.InventoryDocumentSerialNumberLine {
      public WorkDocumentId: string;
      public WorkDocumentLineId: string;
      public SourceLineId: string;
      public LineId: string;
      public Quantity: number;
      public SerialNumber: string;
      public StatusValue: number;
      public ProductId: number;
      public ModifiedDateTime: Date;
      public SourceDocumentRecordId: number;
      public SourceDocumentTypeValue: number;
      public SourceDocumentLineRecordId: number;
      public SourceLine: Entities.InventoryDocumentSourceSerialNumberLine;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.WorkDocumentId = 
                odataObject.WorkDocumentId;
              
            this.WorkDocumentLineId = 
                odataObject.WorkDocumentLineId;
              
            this.SourceLineId = 
                odataObject.SourceLineId;
              
            this.LineId = 
                odataObject.LineId;
              
            this.Quantity = 
                (odataObject.Quantity != null) ? parseFloat(odataObject.Quantity) : undefined;
              
            this.SerialNumber = 
                odataObject.SerialNumber;
              
            this.StatusValue = 
                odataObject.StatusValue;
              
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
            this.ModifiedDateTime = 
                (odataObject.ModifiedDateTime instanceof Date) ? odataObject.ModifiedDateTime
                : (odataObject.ModifiedDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.ModifiedDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.SourceDocumentRecordId = 
                (odataObject.SourceDocumentRecordId != null) ? parseInt(odataObject.SourceDocumentRecordId, 10) : undefined;
              
            this.SourceDocumentTypeValue = 
                odataObject.SourceDocumentTypeValue;
              
            this.SourceDocumentLineRecordId = 
                (odataObject.SourceDocumentLineRecordId != null) ? parseInt(odataObject.SourceDocumentLineRecordId, 10) : undefined;
              
        if (odataObject.SourceLine == null) {
            this.SourceLine = undefined;
        } else if (odataObject.SourceLine['@odata.type'] == null) {
            this.SourceLine = new InventoryDocumentSourceSerialNumberLineClass(odataObject.SourceLine);
        } else {
            var className: string = odataObject.SourceLine['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.SourceLine = new entityDictionary[className](odataObject.SourceLine)
            }
        }

      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['InventoryDocumentSerialNumberLineClass'] = InventoryDocumentSerialNumberLineClass;
    
      /**
      * InventoryDocumentValidationCategoryResultsSummary entity class.
      */
      export class InventoryDocumentValidationCategoryResultsSummaryClass implements Entities.InventoryDocumentValidationCategoryResultsSummary {
      public CategoryName: string;
      public SeverityValue: number;
      public TotalValidationResultCount: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.CategoryName = 
                odataObject.CategoryName;
              
            this.SeverityValue = 
                odataObject.SeverityValue;
              
            this.TotalValidationResultCount = 
                odataObject.TotalValidationResultCount;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['InventoryDocumentValidationCategoryResultsSummaryClass'] = InventoryDocumentValidationCategoryResultsSummaryClass;
    
      /**
      * InventoryDocumentValidationResultSummary entity class.
      */
      export class InventoryDocumentValidationResultSummaryClass implements Entities.InventoryDocumentValidationResultSummary {
      public DocumentId: string;
      public DocumentVersion: number;
      public CategorySummaries: Entities.InventoryDocumentValidationCategoryResultsSummary[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.DocumentId = 
                odataObject.DocumentId;
              
            this.DocumentVersion = 
                (odataObject.DocumentVersion != null) ? parseInt(odataObject.DocumentVersion, 10) : undefined;
              
        this.CategorySummaries = undefined;
        if (odataObject.CategorySummaries) {
        this.CategorySummaries = [];
        for (var i = 0; i < odataObject.CategorySummaries.length; i++) {
        if (odataObject.CategorySummaries[i] != null) {
        if (odataObject.CategorySummaries[i]['@odata.type'] != null) {
          var className: string = odataObject.CategorySummaries[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.CategorySummaries[i] = new entityDictionary[className](odataObject.CategorySummaries[i])
          }
        } else {
            this.CategorySummaries[i] = new InventoryDocumentValidationCategoryResultsSummaryClass(odataObject.CategorySummaries[i]);
        }
                    } else {
        this.CategorySummaries[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['InventoryDocumentValidationResultSummaryClass'] = InventoryDocumentValidationResultSummaryClass;
    
      /**
      * InventoryDocumentValidationResultLine entity class.
      */
      export class InventoryDocumentValidationResultLineClass implements Entities.InventoryDocumentValidationResultLine {
      public DocumentValidationResultLineId: string;
      public CategoryName: string;
      public SeverityValue: number;
      public ErrorCode: number;
      public ErrorMessage: string;
      public DocumentId: string;
      public DocumentLongVersion: number;
      public DocumentLineId: string;
      public CreatedDateTime: Date;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.DocumentValidationResultLineId = 
                odataObject.DocumentValidationResultLineId;
              
            this.CategoryName = 
                odataObject.CategoryName;
              
            this.SeverityValue = 
                odataObject.SeverityValue;
              
            this.ErrorCode = 
                odataObject.ErrorCode;
              
            this.ErrorMessage = 
                odataObject.ErrorMessage;
              
            this.DocumentId = 
                odataObject.DocumentId;
              
            this.DocumentLongVersion = 
                (odataObject.DocumentLongVersion != null) ? parseInt(odataObject.DocumentLongVersion, 10) : undefined;
              
            this.DocumentLineId = 
                odataObject.DocumentLineId;
              
            this.CreatedDateTime = 
                (odataObject.CreatedDateTime instanceof Date) ? odataObject.CreatedDateTime
                : (odataObject.CreatedDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.CreatedDateTime, "Edm.DateTimeOffset", false) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['InventoryDocumentValidationResultLineClass'] = InventoryDocumentValidationResultLineClass;
    
      /**
      * InventoryDocumentValidationResultSearchCriteria entity class.
      */
      export class InventoryDocumentValidationResultSearchCriteriaClass implements Entities.InventoryDocumentValidationResultSearchCriteria {
      public DocumentId: string;
      public DocumentVersion: number;
      public SeverityValue: number;
      public Categories: string[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.DocumentId = 
                odataObject.DocumentId;
              
            this.DocumentVersion = 
                (odataObject.DocumentVersion != null) ? parseInt(odataObject.DocumentVersion, 10) : undefined;
              
            this.SeverityValue = 
                odataObject.SeverityValue;
              
        this.Categories = undefined;
        if (odataObject.Categories) {
        this.Categories = [];
        for (var i = 0; i < odataObject.Categories.length; i++) {
        if (odataObject.Categories[i] != null) {
            this.Categories[i] = 
                odataObject.Categories[i];
                            } else {
        this.Categories[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['InventoryDocumentValidationResultSearchCriteriaClass'] = InventoryDocumentValidationResultSearchCriteriaClass;
    
      /**
      * ProductDimensionValueInventoryAvailability entity class.
      */
      export class ProductDimensionValueInventoryAvailabilityClass implements Entities.ProductDimensionValueInventoryAvailability {
      public DimensionTypeValue: number;
      public DimensionValue: Entities.ProductDimensionValue;
      public ProductIds: number[];
      public TotalAvailableInventoryLevelLabel: string;
      public TotalAvailableInventoryLevelCode: string;
      public PhysicalAvailableInventoryLevelLabel: string;
      public PhysicalAvailableInventoryLevelCode: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.DimensionTypeValue = 
                odataObject.DimensionTypeValue;
              
        if (odataObject.DimensionValue == null) {
            this.DimensionValue = undefined;
        } else if (odataObject.DimensionValue['@odata.type'] == null) {
            this.DimensionValue = new ProductDimensionValueClass(odataObject.DimensionValue);
        } else {
            var className: string = odataObject.DimensionValue['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.DimensionValue = new entityDictionary[className](odataObject.DimensionValue)
            }
        }

      
        this.ProductIds = undefined;
        if (odataObject.ProductIds) {
        this.ProductIds = [];
        for (var i = 0; i < odataObject.ProductIds.length; i++) {
        if (odataObject.ProductIds[i] != null) {
            this.ProductIds[i] = 
                parseInt(odataObject.ProductIds[i], 10);
                            } else {
        this.ProductIds[i] = undefined;
        }
        }
        }
      
            this.TotalAvailableInventoryLevelLabel = 
                odataObject.TotalAvailableInventoryLevelLabel;
              
            this.TotalAvailableInventoryLevelCode = 
                odataObject.TotalAvailableInventoryLevelCode;
              
            this.PhysicalAvailableInventoryLevelLabel = 
                odataObject.PhysicalAvailableInventoryLevelLabel;
              
            this.PhysicalAvailableInventoryLevelCode = 
                odataObject.PhysicalAvailableInventoryLevelCode;
              
      }
      }
      entityDictionary['ProductDimensionValueInventoryAvailabilityClass'] = ProductDimensionValueInventoryAvailabilityClass;
    
      /**
      * UnitOfMeasureSearchCriteria entity class.
      */
      export class UnitOfMeasureSearchCriteriaClass implements Entities.UnitOfMeasureSearchCriteria {
      public IncludeQuantityLimitRules: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.IncludeQuantityLimitRules = 
                odataObject.IncludeQuantityLimitRules;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['UnitOfMeasureSearchCriteriaClass'] = UnitOfMeasureSearchCriteriaClass;
    
      /**
      * ProductDimension entity class.
      */
      export class ProductDimensionClass implements Entities.ProductDimension {
      public DimensionTypeValue: number;
      public DimensionValue: Entities.ProductDimensionValue;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.DimensionTypeValue = 
                odataObject.DimensionTypeValue;
              
        if (odataObject.DimensionValue == null) {
            this.DimensionValue = undefined;
        } else if (odataObject.DimensionValue['@odata.type'] == null) {
            this.DimensionValue = new ProductDimensionValueClass(odataObject.DimensionValue);
        } else {
            var className: string = odataObject.DimensionValue['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.DimensionValue = new entityDictionary[className](odataObject.DimensionValue)
            }
        }

      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ProductDimensionClass'] = ProductDimensionClass;
    
      /**
      * ProductDimensionValue entity class.
      */
      export class ProductDimensionValueClass implements Entities.ProductDimensionValue {
      public RecordId: number;
      public Value: string;
      public DimensionId: string;
      public ColorHexCode: string;
      public ImageUrl: string;
      public RefinerGroup: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.Value = 
                odataObject.Value;
              
            this.DimensionId = 
                odataObject.DimensionId;
              
            this.ColorHexCode = 
                odataObject.ColorHexCode;
              
            this.ImageUrl = 
                odataObject.ImageUrl;
              
            this.RefinerGroup = 
                odataObject.RefinerGroup;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ProductDimensionValueClass'] = ProductDimensionValueClass;
    
      /**
      * ProductDimensionCombination entity class.
      */
      export class ProductDimensionCombinationClass implements Entities.ProductDimensionCombination {
      public ProductDimensions: Entities.ProductDimension[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.ProductDimensions = undefined;
        if (odataObject.ProductDimensions) {
        this.ProductDimensions = [];
        for (var i = 0; i < odataObject.ProductDimensions.length; i++) {
        if (odataObject.ProductDimensions[i] != null) {
        if (odataObject.ProductDimensions[i]['@odata.type'] != null) {
          var className: string = odataObject.ProductDimensions[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ProductDimensions[i] = new entityDictionary[className](odataObject.ProductDimensions[i])
          }
        } else {
            this.ProductDimensions[i] = new ProductDimensionClass(odataObject.ProductDimensions[i]);
        }
                    } else {
        this.ProductDimensions[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ProductDimensionCombinationClass'] = ProductDimensionCombinationClass;
    
      /**
      * SearchRefiner entity class.
      */
      export class SearchRefinerClass implements Entities.SearchRefiner {
      public RecordId: number;
      public KeyName: string;
      public DataTypeValue: number;
      public RefinerTypeValue: number;
      public DisplayTemplateValue: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.KeyName = 
                odataObject.KeyName;
              
            this.DataTypeValue = 
                odataObject.DataTypeValue;
              
            this.RefinerTypeValue = 
                odataObject.RefinerTypeValue;
              
            this.DisplayTemplateValue = 
                odataObject.DisplayTemplateValue;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['SearchRefinerClass'] = SearchRefinerClass;
    
      /**
      * SearchRefinerValue entity class.
      */
      export class SearchRefinerValueClass implements Entities.SearchRefinerValue {
      public RefinerRecordId: number;
      public DataTypeValue: number;
      public LeftValueBoundString: string;
      public RightValueBoundString: string;
      public UnitText: string;
      public RowNumber: number;
      public Count: number;
      public LeftValueBoundLocalizedString: string;
      public RightValueBoundLocalizedString: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RefinerRecordId = 
                (odataObject.RefinerRecordId != null) ? parseInt(odataObject.RefinerRecordId, 10) : undefined;
              
            this.DataTypeValue = 
                odataObject.DataTypeValue;
              
            this.LeftValueBoundString = 
                odataObject.LeftValueBoundString;
              
            this.RightValueBoundString = 
                odataObject.RightValueBoundString;
              
            this.UnitText = 
                odataObject.UnitText;
              
            this.RowNumber = 
                odataObject.RowNumber;
              
            this.Count = 
                odataObject.Count;
              
            this.LeftValueBoundLocalizedString = 
                odataObject.LeftValueBoundLocalizedString;
              
            this.RightValueBoundLocalizedString = 
                odataObject.RightValueBoundLocalizedString;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['SearchRefinerValueClass'] = SearchRefinerValueClass;
    
      /**
      * ProductRefiner entity class.
      */
      export class ProductRefinerClass
        extends SearchRefinerClass
       implements Entities.ProductRefiner {
      public SourceValue: number;
      public Values: Entities.ProductRefinerValue[];
      public IsDimension: boolean;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.SourceValue = 
                odataObject.SourceValue;
              
        this.Values = undefined;
        if (odataObject.Values) {
        this.Values = [];
        for (var i = 0; i < odataObject.Values.length; i++) {
        if (odataObject.Values[i] != null) {
        if (odataObject.Values[i]['@odata.type'] != null) {
          var className: string = odataObject.Values[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Values[i] = new entityDictionary[className](odataObject.Values[i])
          }
        } else {
            this.Values[i] = new ProductRefinerValueClass(odataObject.Values[i]);
        }
                    } else {
        this.Values[i] = undefined;
        }
        }
        }
      
            this.IsDimension = 
                odataObject.IsDimension;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.ProductRefiner";
      
      }
      }
      entityDictionary['ProductRefinerClass'] = ProductRefinerClass;
    
      /**
      * ProductRefinerValue entity class.
      */
      export class ProductRefinerValueClass
        extends SearchRefinerValueClass
       implements Entities.ProductRefinerValue {
      public RefinerSourceValue: number;
      public SwatchImageUrl: string;
      public SwatchColorHexCode: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.RefinerSourceValue = 
                odataObject.RefinerSourceValue;
              
            this.SwatchImageUrl = 
                odataObject.SwatchImageUrl;
              
            this.SwatchColorHexCode = 
                odataObject.SwatchColorHexCode;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.ProductRefinerValue";
      
      }
      }
      entityDictionary['ProductRefinerValueClass'] = ProductRefinerValueClass;
    
      /**
      * ClientBookRefiner entity class.
      */
      export class ClientBookRefinerClass
        extends SearchRefinerClass
       implements Entities.ClientBookRefiner {
      public SourceValue: number;
      public Values: Entities.ClientBookRefinerValue[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.SourceValue = 
                odataObject.SourceValue;
              
        this.Values = undefined;
        if (odataObject.Values) {
        this.Values = [];
        for (var i = 0; i < odataObject.Values.length; i++) {
        if (odataObject.Values[i] != null) {
        if (odataObject.Values[i]['@odata.type'] != null) {
          var className: string = odataObject.Values[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Values[i] = new entityDictionary[className](odataObject.Values[i])
          }
        } else {
            this.Values[i] = new ClientBookRefinerValueClass(odataObject.Values[i]);
        }
                    } else {
        this.Values[i] = undefined;
        }
        }
        }
      
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.ClientBookRefiner";
      
      }
      }
      entityDictionary['ClientBookRefinerClass'] = ClientBookRefinerClass;
    
      /**
      * ClientBookRefinerValue entity class.
      */
      export class ClientBookRefinerValueClass
        extends SearchRefinerValueClass
       implements Entities.ClientBookRefinerValue {
      public RefinerSourceValue: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.RefinerSourceValue = 
                odataObject.RefinerSourceValue;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.ClientBookRefinerValue";
      
      }
      }
      entityDictionary['ClientBookRefinerValueClass'] = ClientBookRefinerValueClass;
    
      /**
      * ProductRelationType entity class.
      */
      export class ProductRelationTypeClass implements Entities.ProductRelationType {
      public Name: string;
      public RecordId: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Name = 
                odataObject.Name;
              
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ProductRelationTypeClass'] = ProductRelationTypeClass;
    
      /**
      * ComponentInSlotRelation entity class.
      */
      export class ComponentInSlotRelationClass implements Entities.ComponentInSlotRelation {
      public ComponentId: number;
      public SlotId: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ComponentId = 
                (odataObject.ComponentId != null) ? parseInt(odataObject.ComponentId, 10) : undefined;
              
            this.SlotId = 
                (odataObject.SlotId != null) ? parseInt(odataObject.SlotId, 10) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ComponentInSlotRelationClass'] = ComponentInSlotRelationClass;
    
      /**
      * ProductRules entity class.
      */
      export class ProductRulesClass implements Entities.ProductRules {
      public HasLinkedProducts: boolean;
      public IsSerialized: boolean;
      public IsActiveInSalesProcess: boolean;
      public IsBlocked: boolean;
      public DateOfBlocking: Date;
      public DateToActivate: Date;
      public DateToBlock: Date;
      public DefaultUnitOfMeasure: string;
      public PriceKeyingRequirementValue: number;
      public QuantityKeyingRequirementValue: number;
      public MustKeyInComment: boolean;
      public CanQuantityBecomeNegative: boolean;
      public MustScaleItem: boolean;
      public CanPriceBeZero: boolean;
      public ProductId: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.HasLinkedProducts = 
                odataObject.HasLinkedProducts;
              
            this.IsSerialized = 
                odataObject.IsSerialized;
              
            this.IsActiveInSalesProcess = 
                odataObject.IsActiveInSalesProcess;
              
            this.IsBlocked = 
                odataObject.IsBlocked;
              
            this.DateOfBlocking = 
                (odataObject.DateOfBlocking instanceof Date) ? odataObject.DateOfBlocking
                : (odataObject.DateOfBlocking != null) ? jsonLightReadStringPropertyValue(odataObject.DateOfBlocking, "Edm.DateTimeOffset", false) : undefined;
              
            this.DateToActivate = 
                (odataObject.DateToActivate instanceof Date) ? odataObject.DateToActivate
                : (odataObject.DateToActivate != null) ? jsonLightReadStringPropertyValue(odataObject.DateToActivate, "Edm.DateTimeOffset", false) : undefined;
              
            this.DateToBlock = 
                (odataObject.DateToBlock instanceof Date) ? odataObject.DateToBlock
                : (odataObject.DateToBlock != null) ? jsonLightReadStringPropertyValue(odataObject.DateToBlock, "Edm.DateTimeOffset", false) : undefined;
              
            this.DefaultUnitOfMeasure = 
                odataObject.DefaultUnitOfMeasure;
              
            this.PriceKeyingRequirementValue = 
                odataObject.PriceKeyingRequirementValue;
              
            this.QuantityKeyingRequirementValue = 
                odataObject.QuantityKeyingRequirementValue;
              
            this.MustKeyInComment = 
                odataObject.MustKeyInComment;
              
            this.CanQuantityBecomeNegative = 
                odataObject.CanQuantityBecomeNegative;
              
            this.MustScaleItem = 
                odataObject.MustScaleItem;
              
            this.CanPriceBeZero = 
                odataObject.CanPriceBeZero;
              
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ProductRulesClass'] = ProductRulesClass;
    
      /**
      * LocalizedString entity class.
      */
      export class LocalizedStringClass implements Entities.LocalizedString {
      public RecordId: number;
      public LanguageId: string;
      public TextId: number;
      public Text: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.LanguageId = 
                odataObject.LanguageId;
              
            this.TextId = 
                odataObject.TextId;
              
            this.Text = 
                odataObject.Text;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['LocalizedStringClass'] = LocalizedStringClass;
    
      /**
      * LoyaltyCard entity class.
      */
      export class LoyaltyCardClass implements Entities.LoyaltyCard {
      public CardNumber: string;
      public CardTenderTypeValue: number;
      public CustomerAccount: string;
      public LoyaltyGroups: Entities.LoyaltyGroup[];
      public RewardPoints: Entities.LoyaltyRewardPoint[];
      public LoyaltyEnrollmentDate: Date;
      public LoyaltyEnrollmentDateLocal: Date;
      public LoyaltyEnrollmentTime: number;
      public LoyaltyEnrollmentTimeLocal: number;
      public OmOperatingUnitId: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.CardNumber = 
                odataObject.CardNumber;
              
            this.CardTenderTypeValue = 
                odataObject.CardTenderTypeValue;
              
            this.CustomerAccount = 
                odataObject.CustomerAccount;
              
        this.LoyaltyGroups = undefined;
        if (odataObject.LoyaltyGroups) {
        this.LoyaltyGroups = [];
        for (var i = 0; i < odataObject.LoyaltyGroups.length; i++) {
        if (odataObject.LoyaltyGroups[i] != null) {
        if (odataObject.LoyaltyGroups[i]['@odata.type'] != null) {
          var className: string = odataObject.LoyaltyGroups[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.LoyaltyGroups[i] = new entityDictionary[className](odataObject.LoyaltyGroups[i])
          }
        } else {
            this.LoyaltyGroups[i] = new LoyaltyGroupClass(odataObject.LoyaltyGroups[i]);
        }
                    } else {
        this.LoyaltyGroups[i] = undefined;
        }
        }
        }
      
        this.RewardPoints = undefined;
        if (odataObject.RewardPoints) {
        this.RewardPoints = [];
        for (var i = 0; i < odataObject.RewardPoints.length; i++) {
        if (odataObject.RewardPoints[i] != null) {
        if (odataObject.RewardPoints[i]['@odata.type'] != null) {
          var className: string = odataObject.RewardPoints[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.RewardPoints[i] = new entityDictionary[className](odataObject.RewardPoints[i])
          }
        } else {
            this.RewardPoints[i] = new LoyaltyRewardPointClass(odataObject.RewardPoints[i]);
        }
                    } else {
        this.RewardPoints[i] = undefined;
        }
        }
        }
      
            this.LoyaltyEnrollmentDate = 
                (odataObject.LoyaltyEnrollmentDate instanceof Date) ? odataObject.LoyaltyEnrollmentDate
                : (odataObject.LoyaltyEnrollmentDate != null) ? jsonLightReadStringPropertyValue(odataObject.LoyaltyEnrollmentDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.LoyaltyEnrollmentDateLocal = 
                (odataObject.LoyaltyEnrollmentDateLocal instanceof Date) ? odataObject.LoyaltyEnrollmentDateLocal
                : (odataObject.LoyaltyEnrollmentDateLocal != null) ? jsonLightReadStringPropertyValue(odataObject.LoyaltyEnrollmentDateLocal, "Edm.DateTimeOffset", false) : undefined;
              
            this.LoyaltyEnrollmentTime = 
                odataObject.LoyaltyEnrollmentTime;
              
            this.LoyaltyEnrollmentTimeLocal = 
                odataObject.LoyaltyEnrollmentTimeLocal;
              
            this.OmOperatingUnitId = 
                (odataObject.OmOperatingUnitId != null) ? parseInt(odataObject.OmOperatingUnitId, 10) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['LoyaltyCardClass'] = LoyaltyCardClass;
    
      /**
      * ProductComponent entity class.
      */
      export class ProductComponentClass implements Entities.ProductComponent {
      public ProductId: number;
      public SlotId: number;
      public Product: Entities.SimpleProduct;
      public AdditionalChargeForComponent: number;
      public Behavior: Entities.ProductBehavior;
      public Description: string;
      public Dimensions: Entities.ProductDimension[];
      public ItemId: string;
      public Name: string;
      public ProductTypeValue: number;
      public Quantity: number;
      public UnitOfMeasure: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
            this.SlotId = 
                (odataObject.SlotId != null) ? parseInt(odataObject.SlotId, 10) : undefined;
              
        if (odataObject.Product == null) {
            this.Product = undefined;
        } else if (odataObject.Product['@odata.type'] == null) {
            this.Product = new SimpleProductClass(odataObject.Product);
        } else {
            var className: string = odataObject.Product['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.Product = new entityDictionary[className](odataObject.Product)
            }
        }

      
            this.AdditionalChargeForComponent = 
                (odataObject.AdditionalChargeForComponent != null) ? parseFloat(odataObject.AdditionalChargeForComponent) : undefined;
              
        if (odataObject.Behavior == null) {
            this.Behavior = undefined;
        } else if (odataObject.Behavior['@odata.type'] == null) {
            this.Behavior = new ProductBehaviorClass(odataObject.Behavior);
        } else {
            var className: string = odataObject.Behavior['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.Behavior = new entityDictionary[className](odataObject.Behavior)
            }
        }

      
            this.Description = 
                odataObject.Description;
              
        this.Dimensions = undefined;
        if (odataObject.Dimensions) {
        this.Dimensions = [];
        for (var i = 0; i < odataObject.Dimensions.length; i++) {
        if (odataObject.Dimensions[i] != null) {
        if (odataObject.Dimensions[i]['@odata.type'] != null) {
          var className: string = odataObject.Dimensions[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Dimensions[i] = new entityDictionary[className](odataObject.Dimensions[i])
          }
        } else {
            this.Dimensions[i] = new ProductDimensionClass(odataObject.Dimensions[i]);
        }
                    } else {
        this.Dimensions[i] = undefined;
        }
        }
        }
      
            this.ItemId = 
                odataObject.ItemId;
              
            this.Name = 
                odataObject.Name;
              
            this.ProductTypeValue = 
                odataObject.ProductTypeValue;
              
            this.Quantity = 
                (odataObject.Quantity != null) ? parseFloat(odataObject.Quantity) : undefined;
              
            this.UnitOfMeasure = 
                odataObject.UnitOfMeasure;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ProductComponentClass'] = ProductComponentClass;
    
      /**
      * SimpleProduct entity class.
      */
      export class SimpleProductClass implements Entities.SimpleProduct {
      public RecordId: number;
      public ItemId: string;
      public Name: string;
      public Description: string;
      public ProductTypeValue: number;
      public DefaultUnitOfMeasure: string;
      public BasePrice: number;
      public Price: number;
      public AdjustedPrice: number;
      public MasterProductId: number;
      public Components: Entities.ProductComponent[];
      public IsGiftCard: boolean;
      public ProductNumber: string;
      public Dimensions: Entities.ProductDimension[];
      public Behavior: Entities.ProductBehavior;
      public LinkedProducts: Entities.SimpleLinkedProduct[];
      public PrimaryImageUrl: string;
      public ItemTypeValue: number;
      public ItemServiceTypeValue: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.ItemId = 
                odataObject.ItemId;
              
            this.Name = 
                odataObject.Name;
              
            this.Description = 
                odataObject.Description;
              
            this.ProductTypeValue = 
                odataObject.ProductTypeValue;
              
            this.DefaultUnitOfMeasure = 
                odataObject.DefaultUnitOfMeasure;
              
            this.BasePrice = 
                (odataObject.BasePrice != null) ? parseFloat(odataObject.BasePrice) : undefined;
              
            this.Price = 
                (odataObject.Price != null) ? parseFloat(odataObject.Price) : undefined;
              
            this.AdjustedPrice = 
                (odataObject.AdjustedPrice != null) ? parseFloat(odataObject.AdjustedPrice) : undefined;
              
            this.MasterProductId = 
                (odataObject.MasterProductId != null) ? parseInt(odataObject.MasterProductId, 10) : undefined;
              
        this.Components = undefined;
        if (odataObject.Components) {
        this.Components = [];
        for (var i = 0; i < odataObject.Components.length; i++) {
        if (odataObject.Components[i] != null) {
        if (odataObject.Components[i]['@odata.type'] != null) {
          var className: string = odataObject.Components[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Components[i] = new entityDictionary[className](odataObject.Components[i])
          }
        } else {
            this.Components[i] = new ProductComponentClass(odataObject.Components[i]);
        }
                    } else {
        this.Components[i] = undefined;
        }
        }
        }
      
            this.IsGiftCard = 
                odataObject.IsGiftCard;
              
            this.ProductNumber = 
                odataObject.ProductNumber;
              
        this.Dimensions = undefined;
        if (odataObject.Dimensions) {
        this.Dimensions = [];
        for (var i = 0; i < odataObject.Dimensions.length; i++) {
        if (odataObject.Dimensions[i] != null) {
        if (odataObject.Dimensions[i]['@odata.type'] != null) {
          var className: string = odataObject.Dimensions[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Dimensions[i] = new entityDictionary[className](odataObject.Dimensions[i])
          }
        } else {
            this.Dimensions[i] = new ProductDimensionClass(odataObject.Dimensions[i]);
        }
                    } else {
        this.Dimensions[i] = undefined;
        }
        }
        }
      
        if (odataObject.Behavior == null) {
            this.Behavior = undefined;
        } else if (odataObject.Behavior['@odata.type'] == null) {
            this.Behavior = new ProductBehaviorClass(odataObject.Behavior);
        } else {
            var className: string = odataObject.Behavior['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.Behavior = new entityDictionary[className](odataObject.Behavior)
            }
        }

      
        this.LinkedProducts = undefined;
        if (odataObject.LinkedProducts) {
        this.LinkedProducts = [];
        for (var i = 0; i < odataObject.LinkedProducts.length; i++) {
        if (odataObject.LinkedProducts[i] != null) {
        if (odataObject.LinkedProducts[i]['@odata.type'] != null) {
          var className: string = odataObject.LinkedProducts[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.LinkedProducts[i] = new entityDictionary[className](odataObject.LinkedProducts[i])
          }
        } else {
            this.LinkedProducts[i] = new SimpleLinkedProductClass(odataObject.LinkedProducts[i]);
        }
                    } else {
        this.LinkedProducts[i] = undefined;
        }
        }
        }
      
            this.PrimaryImageUrl = 
                odataObject.PrimaryImageUrl;
              
            this.ItemTypeValue = 
                odataObject.ItemTypeValue;
              
            this.ItemServiceTypeValue = 
                odataObject.ItemServiceTypeValue;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['SimpleProductClass'] = SimpleProductClass;
    
      /**
      * ProductBehavior entity class.
      */
      export class ProductBehaviorClass implements Entities.ProductBehavior {
      public HasSerialNumber: boolean;
      public IsDiscountAllowed: boolean;
      public IsManualDiscountAllowed: boolean;
      public IsKitDisassemblyAllowed: boolean;
      public IsNegativeQuantityAllowed: boolean;
      public IsReturnAllowed: boolean;
      public IsSaleAtPhysicalStoresAllowed: boolean;
      public IsZeroSalePriceAllowed: boolean;
      public KeyInPriceValue: number;
      public KeyInQuantityValue: number;
      public MustKeyInComment: boolean;
      public MustPrintIndividualShelfLabelsForVariants: boolean;
      public MustPromptForSerialNumberOnlyAtSale: boolean;
      public MustWeighProductAtSale: boolean;
      public ValidFromDateForSaleAtPhysicalStores: Date;
      public ValidToDateForSaleAtPhysicalStores: Date;
      public IsStorageDimensionGroupLocationActive: boolean;
      public IsStorageDimensionGroupLocationAllowBlankReceiptEnabled: boolean;
      public AllowNegativePhysicalInventory: boolean;
      public IsStockedProduct: boolean;
      public IsBlankSerialNumberAllowed: boolean;
      public IsBlankSerialNumberReceiptAllowed: boolean;
      public IsSerialNumberControlEnabled: boolean;
      public IsStorageDimensionGroupLocationBlankIssueAllowed: boolean;
      public IsSerialNumberRequired: boolean;
      public DefaultQuantity: number;
      public MaximumQuantity: number;
      public MinimumQuantity: number;
      public MultipleOfQuantity: number;
      public InventoryLocationId: string;
      public IsSaleAtSelfCheckoutRegistersAllowed: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.HasSerialNumber = 
                odataObject.HasSerialNumber;
              
            this.IsDiscountAllowed = 
                odataObject.IsDiscountAllowed;
              
            this.IsManualDiscountAllowed = 
                odataObject.IsManualDiscountAllowed;
              
            this.IsKitDisassemblyAllowed = 
                odataObject.IsKitDisassemblyAllowed;
              
            this.IsNegativeQuantityAllowed = 
                odataObject.IsNegativeQuantityAllowed;
              
            this.IsReturnAllowed = 
                odataObject.IsReturnAllowed;
              
            this.IsSaleAtPhysicalStoresAllowed = 
                odataObject.IsSaleAtPhysicalStoresAllowed;
              
            this.IsZeroSalePriceAllowed = 
                odataObject.IsZeroSalePriceAllowed;
              
            this.KeyInPriceValue = 
                odataObject.KeyInPriceValue;
              
            this.KeyInQuantityValue = 
                odataObject.KeyInQuantityValue;
              
            this.MustKeyInComment = 
                odataObject.MustKeyInComment;
              
            this.MustPrintIndividualShelfLabelsForVariants = 
                odataObject.MustPrintIndividualShelfLabelsForVariants;
              
            this.MustPromptForSerialNumberOnlyAtSale = 
                odataObject.MustPromptForSerialNumberOnlyAtSale;
              
            this.MustWeighProductAtSale = 
                odataObject.MustWeighProductAtSale;
              
            this.ValidFromDateForSaleAtPhysicalStores = 
                (odataObject.ValidFromDateForSaleAtPhysicalStores instanceof Date) ? odataObject.ValidFromDateForSaleAtPhysicalStores
                : (odataObject.ValidFromDateForSaleAtPhysicalStores != null) ? jsonLightReadStringPropertyValue(odataObject.ValidFromDateForSaleAtPhysicalStores, "Edm.DateTimeOffset", false) : undefined;
              
            this.ValidToDateForSaleAtPhysicalStores = 
                (odataObject.ValidToDateForSaleAtPhysicalStores instanceof Date) ? odataObject.ValidToDateForSaleAtPhysicalStores
                : (odataObject.ValidToDateForSaleAtPhysicalStores != null) ? jsonLightReadStringPropertyValue(odataObject.ValidToDateForSaleAtPhysicalStores, "Edm.DateTimeOffset", false) : undefined;
              
            this.IsStorageDimensionGroupLocationActive = 
                odataObject.IsStorageDimensionGroupLocationActive;
              
            this.IsStorageDimensionGroupLocationAllowBlankReceiptEnabled = 
                odataObject.IsStorageDimensionGroupLocationAllowBlankReceiptEnabled;
              
            this.AllowNegativePhysicalInventory = 
                odataObject.AllowNegativePhysicalInventory;
              
            this.IsStockedProduct = 
                odataObject.IsStockedProduct;
              
            this.IsBlankSerialNumberAllowed = 
                odataObject.IsBlankSerialNumberAllowed;
              
            this.IsBlankSerialNumberReceiptAllowed = 
                odataObject.IsBlankSerialNumberReceiptAllowed;
              
            this.IsSerialNumberControlEnabled = 
                odataObject.IsSerialNumberControlEnabled;
              
            this.IsStorageDimensionGroupLocationBlankIssueAllowed = 
                odataObject.IsStorageDimensionGroupLocationBlankIssueAllowed;
              
            this.IsSerialNumberRequired = 
                odataObject.IsSerialNumberRequired;
              
            this.DefaultQuantity = 
                (odataObject.DefaultQuantity != null) ? parseFloat(odataObject.DefaultQuantity) : undefined;
              
            this.MaximumQuantity = 
                (odataObject.MaximumQuantity != null) ? parseFloat(odataObject.MaximumQuantity) : undefined;
              
            this.MinimumQuantity = 
                (odataObject.MinimumQuantity != null) ? parseFloat(odataObject.MinimumQuantity) : undefined;
              
            this.MultipleOfQuantity = 
                (odataObject.MultipleOfQuantity != null) ? parseFloat(odataObject.MultipleOfQuantity) : undefined;
              
            this.InventoryLocationId = 
                odataObject.InventoryLocationId;
              
            this.IsSaleAtSelfCheckoutRegistersAllowed = 
                odataObject.IsSaleAtSelfCheckoutRegistersAllowed;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ProductBehaviorClass'] = ProductBehaviorClass;
    
      /**
      * SimpleLinkedProduct entity class.
      */
      export class SimpleLinkedProductClass implements Entities.SimpleLinkedProduct {
      public RecordId: number;
      public ItemId: string;
      public Name: string;
      public Description: string;
      public ProductTypeValue: number;
      public DefaultUnitOfMeasure: string;
      public BasePrice: number;
      public Price: number;
      public AdjustedPrice: number;
      public Quantity: number;
      public Behavior: Entities.ProductBehavior;
      public Dimensions: Entities.ProductDimension[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.ItemId = 
                odataObject.ItemId;
              
            this.Name = 
                odataObject.Name;
              
            this.Description = 
                odataObject.Description;
              
            this.ProductTypeValue = 
                odataObject.ProductTypeValue;
              
            this.DefaultUnitOfMeasure = 
                odataObject.DefaultUnitOfMeasure;
              
            this.BasePrice = 
                (odataObject.BasePrice != null) ? parseFloat(odataObject.BasePrice) : undefined;
              
            this.Price = 
                (odataObject.Price != null) ? parseFloat(odataObject.Price) : undefined;
              
            this.AdjustedPrice = 
                (odataObject.AdjustedPrice != null) ? parseFloat(odataObject.AdjustedPrice) : undefined;
              
            this.Quantity = 
                (odataObject.Quantity != null) ? parseFloat(odataObject.Quantity) : undefined;
              
        if (odataObject.Behavior == null) {
            this.Behavior = undefined;
        } else if (odataObject.Behavior['@odata.type'] == null) {
            this.Behavior = new ProductBehaviorClass(odataObject.Behavior);
        } else {
            var className: string = odataObject.Behavior['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.Behavior = new entityDictionary[className](odataObject.Behavior)
            }
        }

      
        this.Dimensions = undefined;
        if (odataObject.Dimensions) {
        this.Dimensions = [];
        for (var i = 0; i < odataObject.Dimensions.length; i++) {
        if (odataObject.Dimensions[i] != null) {
        if (odataObject.Dimensions[i]['@odata.type'] != null) {
          var className: string = odataObject.Dimensions[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Dimensions[i] = new entityDictionary[className](odataObject.Dimensions[i])
          }
        } else {
            this.Dimensions[i] = new ProductDimensionClass(odataObject.Dimensions[i]);
        }
                    } else {
        this.Dimensions[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['SimpleLinkedProductClass'] = SimpleLinkedProductClass;
    
      /**
      * ProductSearchResult entity class.
      */
      export class ProductSearchResultClass implements Entities.ProductSearchResult {
      public ItemId: string;
      public Name: string;
      public Price: number;
      public PrimaryImageUrl: string;
      public RecordId: number;
      public ProductNumber: string;
      public TrackingId: string;
      public AverageRating: number;
      public TotalRatings: number;
      public Description: string;
      public BasePrice: number;
      public MinVariantPrice: number;
      public MaxVariantPrice: number;
      public DisplayOrder: number;
      public AttributeValues: Entities.AttributeValue[];
      public IsMasterProduct: boolean;
      public MasterProductId: number;
      public DefaultUnitOfMeasure: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ItemId = 
                odataObject.ItemId;
              
            this.Name = 
                odataObject.Name;
              
            this.Price = 
                (odataObject.Price != null) ? parseFloat(odataObject.Price) : undefined;
              
            this.PrimaryImageUrl = 
                odataObject.PrimaryImageUrl;
              
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.ProductNumber = 
                odataObject.ProductNumber;
              
            this.TrackingId = 
                odataObject.TrackingId;
              
            this.AverageRating = 
                (odataObject.AverageRating != null) ? parseFloat(odataObject.AverageRating) : undefined;
              
            this.TotalRatings = 
                odataObject.TotalRatings;
              
            this.Description = 
                odataObject.Description;
              
            this.BasePrice = 
                (odataObject.BasePrice != null) ? parseFloat(odataObject.BasePrice) : undefined;
              
            this.MinVariantPrice = 
                (odataObject.MinVariantPrice != null) ? parseFloat(odataObject.MinVariantPrice) : undefined;
              
            this.MaxVariantPrice = 
                (odataObject.MaxVariantPrice != null) ? parseFloat(odataObject.MaxVariantPrice) : undefined;
              
            this.DisplayOrder = 
                (odataObject.DisplayOrder != null) ? parseFloat(odataObject.DisplayOrder) : undefined;
              
        this.AttributeValues = undefined;
        if (odataObject.AttributeValues) {
        this.AttributeValues = [];
        for (var i = 0; i < odataObject.AttributeValues.length; i++) {
        if (odataObject.AttributeValues[i] != null) {
        if (odataObject.AttributeValues[i]['@odata.type'] != null) {
          var className: string = odataObject.AttributeValues[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.AttributeValues[i] = new entityDictionary[className](odataObject.AttributeValues[i])
          }
        } else {
            this.AttributeValues[i] = new AttributeValueClass(odataObject.AttributeValues[i]);
        }
                    } else {
        this.AttributeValues[i] = undefined;
        }
        }
        }
      
            this.IsMasterProduct = 
                odataObject.IsMasterProduct;
              
            this.MasterProductId = 
                (odataObject.MasterProductId != null) ? parseInt(odataObject.MasterProductId, 10) : undefined;
              
            this.DefaultUnitOfMeasure = 
                odataObject.DefaultUnitOfMeasure;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ProductSearchResultClass'] = ProductSearchResultClass;
    
      /**
      * SearchSuggestion entity class.
      */
      export class SearchSuggestionClass implements Entities.SearchSuggestion {
      public Value: string;
      public Value2: Entities.CommerceProperty;
      public Value3: Entities.CommerceProperty;
      public Value4: Entities.CommerceProperty;
      public SuggestionType: string;
      public Id: string;
      public ImageUrl: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Value = 
                odataObject.Value;
              
        if (odataObject.Value2 == null) {
            this.Value2 = undefined;
        } else if (odataObject.Value2['@odata.type'] == null) {
            this.Value2 = new CommercePropertyClass(odataObject.Value2);
        } else {
            var className: string = odataObject.Value2['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.Value2 = new entityDictionary[className](odataObject.Value2)
            }
        }

      
        if (odataObject.Value3 == null) {
            this.Value3 = undefined;
        } else if (odataObject.Value3['@odata.type'] == null) {
            this.Value3 = new CommercePropertyClass(odataObject.Value3);
        } else {
            var className: string = odataObject.Value3['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.Value3 = new entityDictionary[className](odataObject.Value3)
            }
        }

      
        if (odataObject.Value4 == null) {
            this.Value4 = undefined;
        } else if (odataObject.Value4['@odata.type'] == null) {
            this.Value4 = new CommercePropertyClass(odataObject.Value4);
        } else {
            var className: string = odataObject.Value4['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.Value4 = new entityDictionary[className](odataObject.Value4)
            }
        }

      
            this.SuggestionType = 
                odataObject.SuggestionType;
              
            this.Id = 
                odataObject.Id;
              
            this.ImageUrl = 
                odataObject.ImageUrl;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['SearchSuggestionClass'] = SearchSuggestionClass;
    
      /**
      * LoyaltyPointRedemptionEstimate entity class.
      */
      export class LoyaltyPointRedemptionEstimateClass implements Entities.LoyaltyPointRedemptionEstimate {
      public MaxCurrencyValueOfLoyaltyPoints: number;
      public RewardPointToRedeemList: Entities.LoyaltyRewardPointLine[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.MaxCurrencyValueOfLoyaltyPoints = 
                (odataObject.MaxCurrencyValueOfLoyaltyPoints != null) ? parseFloat(odataObject.MaxCurrencyValueOfLoyaltyPoints) : undefined;
              
        this.RewardPointToRedeemList = undefined;
        if (odataObject.RewardPointToRedeemList) {
        this.RewardPointToRedeemList = [];
        for (var i = 0; i < odataObject.RewardPointToRedeemList.length; i++) {
        if (odataObject.RewardPointToRedeemList[i] != null) {
        if (odataObject.RewardPointToRedeemList[i]['@odata.type'] != null) {
          var className: string = odataObject.RewardPointToRedeemList[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.RewardPointToRedeemList[i] = new entityDictionary[className](odataObject.RewardPointToRedeemList[i])
          }
        } else {
            this.RewardPointToRedeemList[i] = new LoyaltyRewardPointLineClass(odataObject.RewardPointToRedeemList[i]);
        }
                    } else {
        this.RewardPointToRedeemList[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['LoyaltyPointRedemptionEstimateClass'] = LoyaltyPointRedemptionEstimateClass;
    
      /**
      * LoyaltyGroup entity class.
      */
      export class LoyaltyGroupClass implements Entities.LoyaltyGroup {
      public Description: string;
      public Name: string;
      public RecordId: number;
      public LoyaltyTiers: Entities.LoyaltyTier[];
      public LoyaltyCardTiers: Entities.LoyaltyCardTier[];
      public HighestActiveLoyaltyCardTier: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Description = 
                odataObject.Description;
              
            this.Name = 
                odataObject.Name;
              
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
        this.LoyaltyTiers = undefined;
        if (odataObject.LoyaltyTiers) {
        this.LoyaltyTiers = [];
        for (var i = 0; i < odataObject.LoyaltyTiers.length; i++) {
        if (odataObject.LoyaltyTiers[i] != null) {
        if (odataObject.LoyaltyTiers[i]['@odata.type'] != null) {
          var className: string = odataObject.LoyaltyTiers[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.LoyaltyTiers[i] = new entityDictionary[className](odataObject.LoyaltyTiers[i])
          }
        } else {
            this.LoyaltyTiers[i] = new LoyaltyTierClass(odataObject.LoyaltyTiers[i]);
        }
                    } else {
        this.LoyaltyTiers[i] = undefined;
        }
        }
        }
      
        this.LoyaltyCardTiers = undefined;
        if (odataObject.LoyaltyCardTiers) {
        this.LoyaltyCardTiers = [];
        for (var i = 0; i < odataObject.LoyaltyCardTiers.length; i++) {
        if (odataObject.LoyaltyCardTiers[i] != null) {
        if (odataObject.LoyaltyCardTiers[i]['@odata.type'] != null) {
          var className: string = odataObject.LoyaltyCardTiers[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.LoyaltyCardTiers[i] = new entityDictionary[className](odataObject.LoyaltyCardTiers[i])
          }
        } else {
            this.LoyaltyCardTiers[i] = new LoyaltyCardTierClass(odataObject.LoyaltyCardTiers[i]);
        }
                    } else {
        this.LoyaltyCardTiers[i] = undefined;
        }
        }
        }
      
            this.HighestActiveLoyaltyCardTier = 
                (odataObject.HighestActiveLoyaltyCardTier != null) ? parseInt(odataObject.HighestActiveLoyaltyCardTier, 10) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['LoyaltyGroupClass'] = LoyaltyGroupClass;
    
      /**
      * LoyaltyTier entity class.
      */
      export class LoyaltyTierClass implements Entities.LoyaltyTier {
      public Description: string;
      public RecordId: number;
      public TierId: string;
      public TierLevel: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Description = 
                odataObject.Description;
              
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.TierId = 
                odataObject.TierId;
              
            this.TierLevel = 
                (odataObject.TierLevel != null) ? parseFloat(odataObject.TierLevel) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['LoyaltyTierClass'] = LoyaltyTierClass;
    
      /**
      * LoyaltyCardTier entity class.
      */
      export class LoyaltyCardTierClass implements Entities.LoyaltyCardTier {
      public RecordId: number;
      public TierId: string;
      public LoyaltyTierRecordId: number;
      public ValidFrom: Date;
      public ValidTo: Date;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.TierId = 
                odataObject.TierId;
              
            this.LoyaltyTierRecordId = 
                (odataObject.LoyaltyTierRecordId != null) ? parseInt(odataObject.LoyaltyTierRecordId, 10) : undefined;
              
            this.ValidFrom = 
                (odataObject.ValidFrom instanceof Date) ? odataObject.ValidFrom
                : (odataObject.ValidFrom != null) ? jsonLightReadStringPropertyValue(odataObject.ValidFrom, "Edm.DateTimeOffset", false) : undefined;
              
            this.ValidTo = 
                (odataObject.ValidTo instanceof Date) ? odataObject.ValidTo
                : (odataObject.ValidTo != null) ? jsonLightReadStringPropertyValue(odataObject.ValidTo, "Edm.DateTimeOffset", false) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['LoyaltyCardTierClass'] = LoyaltyCardTierClass;
    
      /**
      * LoyaltyRewardPoint entity class.
      */
      export class LoyaltyRewardPointClass implements Entities.LoyaltyRewardPoint {
      public IsRedeemable: boolean;
      public RewardPointCurrency: string;
      public RewardPointTypeValue: number;
      public Description: string;
      public RewardPointId: string;
      public IssuedPoints: number;
      public UsedPoints: number;
      public ExpiredPoints: number;
      public ActivePoints: number;
      public PointsExpiringSoon: number;
      public UnvestedPoints: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.IsRedeemable = 
                odataObject.IsRedeemable;
              
            this.RewardPointCurrency = 
                odataObject.RewardPointCurrency;
              
            this.RewardPointTypeValue = 
                odataObject.RewardPointTypeValue;
              
            this.Description = 
                odataObject.Description;
              
            this.RewardPointId = 
                odataObject.RewardPointId;
              
            this.IssuedPoints = 
                (odataObject.IssuedPoints != null) ? parseFloat(odataObject.IssuedPoints) : undefined;
              
            this.UsedPoints = 
                (odataObject.UsedPoints != null) ? parseFloat(odataObject.UsedPoints) : undefined;
              
            this.ExpiredPoints = 
                (odataObject.ExpiredPoints != null) ? parseFloat(odataObject.ExpiredPoints) : undefined;
              
            this.ActivePoints = 
                (odataObject.ActivePoints != null) ? parseFloat(odataObject.ActivePoints) : undefined;
              
            this.PointsExpiringSoon = 
                (odataObject.PointsExpiringSoon != null) ? parseFloat(odataObject.PointsExpiringSoon) : undefined;
              
            this.UnvestedPoints = 
                (odataObject.UnvestedPoints != null) ? parseFloat(odataObject.UnvestedPoints) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['LoyaltyRewardPointClass'] = LoyaltyRewardPointClass;
    
      /**
      * LoyaltyCardTransaction entity class.
      */
      export class LoyaltyCardTransactionClass implements Entities.LoyaltyCardTransaction {
      public ChannelName: string;
      public EntryDateTime: Date;
      public EntryTypeValue: number;
      public ExpirationDate: Date;
      public RewardPointAmountQuantity: number;
      public TransactionId: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ChannelName = 
                odataObject.ChannelName;
              
            this.EntryDateTime = 
                (odataObject.EntryDateTime instanceof Date) ? odataObject.EntryDateTime
                : (odataObject.EntryDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.EntryDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.EntryTypeValue = 
                odataObject.EntryTypeValue;
              
            this.ExpirationDate = 
                (odataObject.ExpirationDate instanceof Date) ? odataObject.ExpirationDate
                : (odataObject.ExpirationDate != null) ? jsonLightReadStringPropertyValue(odataObject.ExpirationDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.RewardPointAmountQuantity = 
                (odataObject.RewardPointAmountQuantity != null) ? parseFloat(odataObject.RewardPointAmountQuantity) : undefined;
              
            this.TransactionId = 
                odataObject.TransactionId;
              
      }
      }
      entityDictionary['LoyaltyCardTransactionClass'] = LoyaltyCardTransactionClass;
    
      /**
      * GiftCard entity class.
      */
      export class GiftCardClass implements Entities.GiftCard {
      public Id: string;
      public Balance: number;
      public BalanceCurrencyCode: string;
      public BalanceInCardCurrency: number;
      public CardCurrencyCode: string;
      public GiftCardIssueAmount: number;
      public GiftCardActiveFrom: Date;
      public GiftCardExpireDate: Date;
      public GiftCardHistoryDetails: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Id = 
                odataObject.Id;
              
            this.Balance = 
                (odataObject.Balance != null) ? parseFloat(odataObject.Balance) : undefined;
              
            this.BalanceCurrencyCode = 
                odataObject.BalanceCurrencyCode;
              
            this.BalanceInCardCurrency = 
                (odataObject.BalanceInCardCurrency != null) ? parseFloat(odataObject.BalanceInCardCurrency) : undefined;
              
            this.CardCurrencyCode = 
                odataObject.CardCurrencyCode;
              
            this.GiftCardIssueAmount = 
                (odataObject.GiftCardIssueAmount != null) ? parseFloat(odataObject.GiftCardIssueAmount) : undefined;
              
            this.GiftCardActiveFrom = 
                (odataObject.GiftCardActiveFrom instanceof Date) ? odataObject.GiftCardActiveFrom
                : (odataObject.GiftCardActiveFrom != null) ? jsonLightReadStringPropertyValue(odataObject.GiftCardActiveFrom, "Edm.DateTimeOffset", false) : undefined;
              
            this.GiftCardExpireDate = 
                (odataObject.GiftCardExpireDate instanceof Date) ? odataObject.GiftCardExpireDate
                : (odataObject.GiftCardExpireDate != null) ? jsonLightReadStringPropertyValue(odataObject.GiftCardExpireDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.GiftCardHistoryDetails = 
                odataObject.GiftCardHistoryDetails;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['GiftCardClass'] = GiftCardClass;
    
      /**
      * OperationPermission entity class.
      */
      export class OperationPermissionClass implements Entities.OperationPermission {
      public OperationName: string;
      public OperationId: number;
      public CheckUserAccess: boolean;
      public AllowAnonymousAccess: boolean;
      public AllowCustomerAccess: boolean;
      public AllowApplicationAccess: boolean;
      public IsEnabledForTaskManagement: boolean;
      public Permissions: string[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.OperationName = 
                odataObject.OperationName;
              
            this.OperationId = 
                odataObject.OperationId;
              
            this.CheckUserAccess = 
                odataObject.CheckUserAccess;
              
            this.AllowAnonymousAccess = 
                odataObject.AllowAnonymousAccess;
              
            this.AllowCustomerAccess = 
                odataObject.AllowCustomerAccess;
              
            this.AllowApplicationAccess = 
                odataObject.AllowApplicationAccess;
              
            this.IsEnabledForTaskManagement = 
                odataObject.IsEnabledForTaskManagement;
              
        this.Permissions = undefined;
        if (odataObject.Permissions) {
        this.Permissions = [];
        for (var i = 0; i < odataObject.Permissions.length; i++) {
        if (odataObject.Permissions[i] != null) {
            this.Permissions[i] = 
                odataObject.Permissions[i];
                            } else {
        this.Permissions[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['OperationPermissionClass'] = OperationPermissionClass;
    
      /**
      * StateProvinceInfo entity class.
      */
      export class StateProvinceInfoClass implements Entities.StateProvinceInfo {
      public CountryRegionId: string;
      public StateId: string;
      public StateName: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.CountryRegionId = 
                odataObject.CountryRegionId;
              
            this.StateId = 
                odataObject.StateId;
              
            this.StateName = 
                odataObject.StateName;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['StateProvinceInfoClass'] = StateProvinceInfoClass;
    
      /**
      * TransactionLog entity class.
      */
      export class TransactionLogClass
        extends TransactionClass
       implements Entities.TransactionLog {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.TransactionLog";
      
      }
      }
      entityDictionary['TransactionLogClass'] = TransactionLogClass;
    
      /**
      * KitTransaction entity class.
      */
      export class KitTransactionClass
        extends TransactionLogClass
       implements Entities.KitTransaction {
      public KitTransactionLines: Entities.KitTransactionLine[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this.KitTransactionLines = undefined;
        if (odataObject.KitTransactionLines) {
        this.KitTransactionLines = [];
        for (var i = 0; i < odataObject.KitTransactionLines.length; i++) {
        if (odataObject.KitTransactionLines[i] != null) {
        if (odataObject.KitTransactionLines[i]['@odata.type'] != null) {
          var className: string = odataObject.KitTransactionLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.KitTransactionLines[i] = new entityDictionary[className](odataObject.KitTransactionLines[i])
          }
        } else {
            this.KitTransactionLines[i] = new KitTransactionLineClass(odataObject.KitTransactionLines[i]);
        }
                    } else {
        this.KitTransactionLines[i] = undefined;
        }
        }
        }
      
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.KitTransaction";
      
      }
      }
      entityDictionary['KitTransactionClass'] = KitTransactionClass;
    
      /**
      * SalesTaxGroup entity class.
      */
      export class SalesTaxGroupClass implements Entities.SalesTaxGroup {
      public RecordId: number;
      public TaxGroup: string;
      public TaxGroupName: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.TaxGroup = 
                odataObject.TaxGroup;
              
            this.TaxGroupName = 
                odataObject.TaxGroupName;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['SalesTaxGroupClass'] = SalesTaxGroupClass;
    
      /**
      * ProductProperty entity class.
      */
      export class ProductPropertyClass implements Entities.ProductProperty {
      public ValueString: string;
      public UnitText: string;
      public PropertyTypeValue: number;
      public KeyName: string;
      public FriendlyName: string;
      public RecordId: number;
      public IsDimensionProperty: boolean;
      public AttributeValueId: number;
      public SwatchImageUrl: string;
      public SwatchColorHexCode: string;
      public GroupId: number;
      public GroupTypeValue: number;
      public GroupName: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      public ProductId: number;
      public CatalogId: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ValueString = 
                odataObject.ValueString;
              
            this.UnitText = 
                odataObject.UnitText;
              
            this.PropertyTypeValue = 
                odataObject.PropertyTypeValue;
              
            this.KeyName = 
                odataObject.KeyName;
              
            this.FriendlyName = 
                odataObject.FriendlyName;
              
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.IsDimensionProperty = 
                odataObject.IsDimensionProperty;
              
            this.AttributeValueId = 
                (odataObject.AttributeValueId != null) ? parseInt(odataObject.AttributeValueId, 10) : undefined;
              
            this.SwatchImageUrl = 
                odataObject.SwatchImageUrl;
              
            this.SwatchColorHexCode = 
                odataObject.SwatchColorHexCode;
              
            this.GroupId = 
                (odataObject.GroupId != null) ? parseInt(odataObject.GroupId, 10) : undefined;
              
            this.GroupTypeValue = 
                odataObject.GroupTypeValue;
              
            this.GroupName = 
                odataObject.GroupName;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
            this.CatalogId = 
                (odataObject.CatalogId != null) ? parseInt(odataObject.CatalogId, 10) : undefined;
              
      }
      }
      entityDictionary['ProductPropertyClass'] = ProductPropertyClass;
    
      /**
      * ProductCompositionInformation entity class.
      */
      export class ProductCompositionInformationClass implements Entities.ProductCompositionInformation {
      public VariantInformation: Entities.ProductVariantInformation;
      public KitDefinition: Entities.KitDefinition;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        if (odataObject.VariantInformation == null) {
            this.VariantInformation = undefined;
        } else if (odataObject.VariantInformation['@odata.type'] == null) {
            this.VariantInformation = new ProductVariantInformationClass(odataObject.VariantInformation);
        } else {
            var className: string = odataObject.VariantInformation['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.VariantInformation = new entityDictionary[className](odataObject.VariantInformation)
            }
        }

      
        if (odataObject.KitDefinition == null) {
            this.KitDefinition = undefined;
        } else if (odataObject.KitDefinition['@odata.type'] == null) {
            this.KitDefinition = new KitDefinitionClass(odataObject.KitDefinition);
        } else {
            var className: string = odataObject.KitDefinition['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.KitDefinition = new entityDictionary[className](odataObject.KitDefinition)
            }
        }

      
      }
      }
      entityDictionary['ProductCompositionInformationClass'] = ProductCompositionInformationClass;
    
      /**
      * ProductVariantInformation entity class.
      */
      export class ProductVariantInformationClass implements Entities.ProductVariantInformation {
      public ActiveVariantProductId: number;
      public Variants: Entities.ProductVariant[];
      public Dimensions: Entities.ProductDimensionSet[];
      public ItemId: string;
      public MasterProductId: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ActiveVariantProductId = 
                (odataObject.ActiveVariantProductId != null) ? parseInt(odataObject.ActiveVariantProductId, 10) : undefined;
              
        this.Variants = undefined;
        if (odataObject.Variants) {
        this.Variants = [];
        for (var i = 0; i < odataObject.Variants.length; i++) {
        if (odataObject.Variants[i] != null) {
        if (odataObject.Variants[i]['@odata.type'] != null) {
          var className: string = odataObject.Variants[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Variants[i] = new entityDictionary[className](odataObject.Variants[i])
          }
        } else {
            this.Variants[i] = new ProductVariantClass(odataObject.Variants[i]);
        }
                    } else {
        this.Variants[i] = undefined;
        }
        }
        }
      
        this.Dimensions = undefined;
        if (odataObject.Dimensions) {
        this.Dimensions = [];
        for (var i = 0; i < odataObject.Dimensions.length; i++) {
        if (odataObject.Dimensions[i] != null) {
        if (odataObject.Dimensions[i]['@odata.type'] != null) {
          var className: string = odataObject.Dimensions[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Dimensions[i] = new entityDictionary[className](odataObject.Dimensions[i])
          }
        } else {
            this.Dimensions[i] = new ProductDimensionSetClass(odataObject.Dimensions[i]);
        }
                    } else {
        this.Dimensions[i] = undefined;
        }
        }
        }
      
            this.ItemId = 
                odataObject.ItemId;
              
            this.MasterProductId = 
                (odataObject.MasterProductId != null) ? parseInt(odataObject.MasterProductId, 10) : undefined;
              
      }
      }
      entityDictionary['ProductVariantInformationClass'] = ProductVariantInformationClass;
    
      /**
      * ProductChangeTrackingInformation entity class.
      */
      export class ProductChangeTrackingInformationClass implements Entities.ProductChangeTrackingInformation {
      public ModifiedDateTime: Date;
      public ChangeActionValue: number;
      public RequestedActionValue: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ModifiedDateTime = 
                (odataObject.ModifiedDateTime instanceof Date) ? odataObject.ModifiedDateTime
                : (odataObject.ModifiedDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.ModifiedDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.ChangeActionValue = 
                odataObject.ChangeActionValue;
              
            this.RequestedActionValue = 
                odataObject.RequestedActionValue;
              
      }
      }
      entityDictionary['ProductChangeTrackingInformationClass'] = ProductChangeTrackingInformationClass;
    
      /**
      * ProductDimensionSet entity class.
      */
      export class ProductDimensionSetClass implements Entities.ProductDimensionSet {
      public DimensionKey: string;
      public DimensionValues: Entities.ProductDimensionValueSet[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.DimensionKey = 
                odataObject.DimensionKey;
              
        this.DimensionValues = undefined;
        if (odataObject.DimensionValues) {
        this.DimensionValues = [];
        for (var i = 0; i < odataObject.DimensionValues.length; i++) {
        if (odataObject.DimensionValues[i] != null) {
        if (odataObject.DimensionValues[i]['@odata.type'] != null) {
          var className: string = odataObject.DimensionValues[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.DimensionValues[i] = new entityDictionary[className](odataObject.DimensionValues[i])
          }
        } else {
            this.DimensionValues[i] = new ProductDimensionValueSetClass(odataObject.DimensionValues[i]);
        }
                    } else {
        this.DimensionValues[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ProductDimensionSetClass'] = ProductDimensionSetClass;
    
      /**
      * ProductDimensionValueSet entity class.
      */
      export class ProductDimensionValueSetClass implements Entities.ProductDimensionValueSet {
      public DimensionId: string;
      public DimensionValue: string;
      public VariantSet: number[];
      public Language: string;
      public SwatchImageUrl: string;
      public SwatchColorHexCode: string;
      public SwatchRefinerGroupValue: string;
      public SwatchRefinerGroupTranslation: string;
      public SwatchRefinerGroupImageUrl: string;
      public SwatchRefinerGroupColorHexCode: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.DimensionId = 
                odataObject.DimensionId;
              
            this.DimensionValue = 
                odataObject.DimensionValue;
              
        this.VariantSet = undefined;
        if (odataObject.VariantSet) {
        this.VariantSet = [];
        for (var i = 0; i < odataObject.VariantSet.length; i++) {
        if (odataObject.VariantSet[i] != null) {
            this.VariantSet[i] = 
                parseInt(odataObject.VariantSet[i], 10);
                            } else {
        this.VariantSet[i] = undefined;
        }
        }
        }
      
            this.Language = 
                odataObject.Language;
              
            this.SwatchImageUrl = 
                odataObject.SwatchImageUrl;
              
            this.SwatchColorHexCode = 
                odataObject.SwatchColorHexCode;
              
            this.SwatchRefinerGroupValue = 
                odataObject.SwatchRefinerGroupValue;
              
            this.SwatchRefinerGroupTranslation = 
                odataObject.SwatchRefinerGroupTranslation;
              
            this.SwatchRefinerGroupImageUrl = 
                odataObject.SwatchRefinerGroupImageUrl;
              
            this.SwatchRefinerGroupColorHexCode = 
                odataObject.SwatchRefinerGroupColorHexCode;
              
      }
      }
      entityDictionary['ProductDimensionValueSetClass'] = ProductDimensionValueSetClass;
    
      /**
      * RichMediaLocations entity class.
      */
      export class RichMediaLocationsClass implements Entities.RichMediaLocations {
      public Items: Entities.RichMediaLocationsRichMediaLocation[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.Items = undefined;
        if (odataObject.Items) {
        this.Items = [];
        for (var i = 0; i < odataObject.Items.length; i++) {
        if (odataObject.Items[i] != null) {
        if (odataObject.Items[i]['@odata.type'] != null) {
          var className: string = odataObject.Items[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Items[i] = new entityDictionary[className](odataObject.Items[i])
          }
        } else {
            this.Items[i] = new RichMediaLocationsRichMediaLocationClass(odataObject.Items[i]);
        }
                    } else {
        this.Items[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['RichMediaLocationsClass'] = RichMediaLocationsClass;
    
      /**
      * RichMediaLocationsRichMediaLocation entity class.
      */
      export class RichMediaLocationsRichMediaLocationClass implements Entities.RichMediaLocationsRichMediaLocation {
      public Url: string;
      public AltText: string;
      public IsSelfHosted: boolean;
      public IsDefault: boolean;
      public Priority: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Url = 
                odataObject.Url;
              
            this.AltText = 
                odataObject.AltText;
              
            this.IsSelfHosted = 
                odataObject.IsSelfHosted;
              
            this.IsDefault = 
                odataObject.IsDefault;
              
            this.Priority = 
                odataObject.Priority;
              
      }
      }
      entityDictionary['RichMediaLocationsRichMediaLocationClass'] = RichMediaLocationsRichMediaLocationClass;
    
      /**
      * KitDefinition entity class.
      */
      export class KitDefinitionClass implements Entities.KitDefinition {
      public DisassembleAtRegister: boolean;
      public KitLineDefinitions: Entities.KitLineDefinition[];
      public KitLineProductToVariantMap: Entities.ComponentKitVariantSet[];
      public KitVariantToComponentMap: Entities.KitVariantContent[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.DisassembleAtRegister = 
                odataObject.DisassembleAtRegister;
              
        this.KitLineDefinitions = undefined;
        if (odataObject.KitLineDefinitions) {
        this.KitLineDefinitions = [];
        for (var i = 0; i < odataObject.KitLineDefinitions.length; i++) {
        if (odataObject.KitLineDefinitions[i] != null) {
        if (odataObject.KitLineDefinitions[i]['@odata.type'] != null) {
          var className: string = odataObject.KitLineDefinitions[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.KitLineDefinitions[i] = new entityDictionary[className](odataObject.KitLineDefinitions[i])
          }
        } else {
            this.KitLineDefinitions[i] = new KitLineDefinitionClass(odataObject.KitLineDefinitions[i]);
        }
                    } else {
        this.KitLineDefinitions[i] = undefined;
        }
        }
        }
      
        this.KitLineProductToVariantMap = undefined;
        if (odataObject.KitLineProductToVariantMap) {
        this.KitLineProductToVariantMap = [];
        for (var i = 0; i < odataObject.KitLineProductToVariantMap.length; i++) {
        if (odataObject.KitLineProductToVariantMap[i] != null) {
        if (odataObject.KitLineProductToVariantMap[i]['@odata.type'] != null) {
          var className: string = odataObject.KitLineProductToVariantMap[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.KitLineProductToVariantMap[i] = new entityDictionary[className](odataObject.KitLineProductToVariantMap[i])
          }
        } else {
            this.KitLineProductToVariantMap[i] = new ComponentKitVariantSetClass(odataObject.KitLineProductToVariantMap[i]);
        }
                    } else {
        this.KitLineProductToVariantMap[i] = undefined;
        }
        }
        }
      
        this.KitVariantToComponentMap = undefined;
        if (odataObject.KitVariantToComponentMap) {
        this.KitVariantToComponentMap = [];
        for (var i = 0; i < odataObject.KitVariantToComponentMap.length; i++) {
        if (odataObject.KitVariantToComponentMap[i] != null) {
        if (odataObject.KitVariantToComponentMap[i]['@odata.type'] != null) {
          var className: string = odataObject.KitVariantToComponentMap[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.KitVariantToComponentMap[i] = new entityDictionary[className](odataObject.KitVariantToComponentMap[i])
          }
        } else {
            this.KitVariantToComponentMap[i] = new KitVariantContentClass(odataObject.KitVariantToComponentMap[i]);
        }
                    } else {
        this.KitVariantToComponentMap[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['KitDefinitionClass'] = KitDefinitionClass;
    
      /**
      * ComponentKitVariantSet entity class.
      */
      export class ComponentKitVariantSetClass implements Entities.ComponentKitVariantSet {
      public KitLineProductId: number;
      public KitVariantIdList: number[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.KitLineProductId = 
                (odataObject.KitLineProductId != null) ? parseInt(odataObject.KitLineProductId, 10) : undefined;
              
        this.KitVariantIdList = undefined;
        if (odataObject.KitVariantIdList) {
        this.KitVariantIdList = [];
        for (var i = 0; i < odataObject.KitVariantIdList.length; i++) {
        if (odataObject.KitVariantIdList[i] != null) {
            this.KitVariantIdList[i] = 
                parseInt(odataObject.KitVariantIdList[i], 10);
                            } else {
        this.KitVariantIdList[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ComponentKitVariantSetClass'] = ComponentKitVariantSetClass;
    
      /**
      * KitLineDefinition entity class.
      */
      export class KitLineDefinitionClass implements Entities.KitLineDefinition {
      public ComponentProductId: number;
      public KitLineIdentifier: number;
      public SubstituteProductIds: number[];
      public ComponentProperties: Entities.KitLineProductProperty[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ComponentProductId = 
                (odataObject.ComponentProductId != null) ? parseInt(odataObject.ComponentProductId, 10) : undefined;
              
            this.KitLineIdentifier = 
                (odataObject.KitLineIdentifier != null) ? parseInt(odataObject.KitLineIdentifier, 10) : undefined;
              
        this.SubstituteProductIds = undefined;
        if (odataObject.SubstituteProductIds) {
        this.SubstituteProductIds = [];
        for (var i = 0; i < odataObject.SubstituteProductIds.length; i++) {
        if (odataObject.SubstituteProductIds[i] != null) {
            this.SubstituteProductIds[i] = 
                parseInt(odataObject.SubstituteProductIds[i], 10);
                            } else {
        this.SubstituteProductIds[i] = undefined;
        }
        }
        }
      
        this.ComponentProperties = undefined;
        if (odataObject.ComponentProperties) {
        this.ComponentProperties = [];
        for (var i = 0; i < odataObject.ComponentProperties.length; i++) {
        if (odataObject.ComponentProperties[i] != null) {
        if (odataObject.ComponentProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ComponentProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ComponentProperties[i] = new entityDictionary[className](odataObject.ComponentProperties[i])
          }
        } else {
            this.ComponentProperties[i] = new KitLineProductPropertyClass(odataObject.ComponentProperties[i]);
        }
                    } else {
        this.ComponentProperties[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['KitLineDefinitionClass'] = KitLineDefinitionClass;
    
      /**
      * KitLineProductProperty entity class.
      */
      export class KitLineProductPropertyClass implements Entities.KitLineProductProperty {
      public KitLineIdentifier: number;
      public ProductId: number;
      public Unit: string;
      public Quantity: number;
      public Charge: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.KitLineIdentifier = 
                (odataObject.KitLineIdentifier != null) ? parseInt(odataObject.KitLineIdentifier, 10) : undefined;
              
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
            this.Unit = 
                odataObject.Unit;
              
            this.Quantity = 
                (odataObject.Quantity != null) ? parseFloat(odataObject.Quantity) : undefined;
              
            this.Charge = 
                (odataObject.Charge != null) ? parseFloat(odataObject.Charge) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['KitLineProductPropertyClass'] = KitLineProductPropertyClass;
    
      /**
      * KitComponentKey entity class.
      */
      export class KitComponentKeyClass implements Entities.KitComponentKey {
      public KitLineIdentifier: number;
      public DistinctProductId: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.KitLineIdentifier = 
                (odataObject.KitLineIdentifier != null) ? parseInt(odataObject.KitLineIdentifier, 10) : undefined;
              
            this.DistinctProductId = 
                (odataObject.DistinctProductId != null) ? parseInt(odataObject.DistinctProductId, 10) : undefined;
              
      }
      }
      entityDictionary['KitComponentKeyClass'] = KitComponentKeyClass;
    
      /**
      * KitVariantContent entity class.
      */
      export class KitVariantContentClass implements Entities.KitVariantContent {
      public KitVariantId: number;
      public KitComponentKeyList: Entities.KitComponentKey[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.KitVariantId = 
                (odataObject.KitVariantId != null) ? parseInt(odataObject.KitVariantId, 10) : undefined;
              
        this.KitComponentKeyList = undefined;
        if (odataObject.KitComponentKeyList) {
        this.KitComponentKeyList = [];
        for (var i = 0; i < odataObject.KitComponentKeyList.length; i++) {
        if (odataObject.KitComponentKeyList[i] != null) {
        if (odataObject.KitComponentKeyList[i]['@odata.type'] != null) {
          var className: string = odataObject.KitComponentKeyList[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.KitComponentKeyList[i] = new entityDictionary[className](odataObject.KitComponentKeyList[i])
          }
        } else {
            this.KitComponentKeyList[i] = new KitComponentKeyClass(odataObject.KitComponentKeyList[i]);
        }
                    } else {
        this.KitComponentKeyList[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['KitVariantContentClass'] = KitVariantContentClass;
    
      /**
      * ProductLookupClause entity class.
      */
      export class ProductLookupClauseClass implements Entities.ProductLookupClause {
      public ItemId: string;
      public InventDimensionId: string;
      public Barcode: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ItemId = 
                odataObject.ItemId;
              
            this.InventDimensionId = 
                odataObject.InventDimensionId;
              
            this.Barcode = 
                odataObject.Barcode;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ProductLookupClauseClass'] = ProductLookupClauseClass;
    
      /**
      * ProductAssortmentInterval entity class.
      */
      export class ProductAssortmentIntervalClass implements Entities.ProductAssortmentInterval {
      public ProductId: number;
      public ValidFrom: Date;
      public ValidTo: Date;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
            this.ValidFrom = 
                (odataObject.ValidFrom instanceof Date) ? odataObject.ValidFrom
                : (odataObject.ValidFrom != null) ? jsonLightReadStringPropertyValue(odataObject.ValidFrom, "Edm.DateTimeOffset", false) : undefined;
              
            this.ValidTo = 
                (odataObject.ValidTo instanceof Date) ? odataObject.ValidTo
                : (odataObject.ValidTo != null) ? jsonLightReadStringPropertyValue(odataObject.ValidTo, "Edm.DateTimeOffset", false) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ProductAssortmentIntervalClass'] = ProductAssortmentIntervalClass;
    
      /**
      * OrgUnitLocation entity class.
      */
      export class OrgUnitLocationClass implements Entities.OrgUnitLocation {
      public Contacts: Entities.OrgUnitContact[];
      public ChannelId: number;
      public Latitude: number;
      public Longitude: number;
      public OrgUnitName: string;
      public OrgUnitNumber: string;
      public Address: string;
      public Street: string;
      public StreetNumber: string;
      public City: string;
      public DistrictName: string;
      public BuildingCompliment: string;
      public Postbox: string;
      public Zip: string;
      public County: string;
      public CountyName: string;
      public State: string;
      public StateName: string;
      public Country: string;
      public Distance: number;
      public InventoryLocationId: string;
      public InventorySiteId: string;
      public IsPickupLocation: boolean;
      public IsShippingLocation: boolean;
      public PostalAddressId: number;
      public OpenFrom: number;
      public OpenTo: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.Contacts = undefined;
        if (odataObject.Contacts) {
        this.Contacts = [];
        for (var i = 0; i < odataObject.Contacts.length; i++) {
        if (odataObject.Contacts[i] != null) {
        if (odataObject.Contacts[i]['@odata.type'] != null) {
          var className: string = odataObject.Contacts[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Contacts[i] = new entityDictionary[className](odataObject.Contacts[i])
          }
        } else {
            this.Contacts[i] = new OrgUnitContactClass(odataObject.Contacts[i]);
        }
                    } else {
        this.Contacts[i] = undefined;
        }
        }
        }
      
            this.ChannelId = 
                (odataObject.ChannelId != null) ? parseInt(odataObject.ChannelId, 10) : undefined;
              
            this.Latitude = 
                (odataObject.Latitude != null) ? parseFloat(odataObject.Latitude) : undefined;
              
            this.Longitude = 
                (odataObject.Longitude != null) ? parseFloat(odataObject.Longitude) : undefined;
              
            this.OrgUnitName = 
                odataObject.OrgUnitName;
              
            this.OrgUnitNumber = 
                odataObject.OrgUnitNumber;
              
            this.Address = 
                odataObject.Address;
              
            this.Street = 
                odataObject.Street;
              
            this.StreetNumber = 
                odataObject.StreetNumber;
              
            this.City = 
                odataObject.City;
              
            this.DistrictName = 
                odataObject.DistrictName;
              
            this.BuildingCompliment = 
                odataObject.BuildingCompliment;
              
            this.Postbox = 
                odataObject.Postbox;
              
            this.Zip = 
                odataObject.Zip;
              
            this.County = 
                odataObject.County;
              
            this.CountyName = 
                odataObject.CountyName;
              
            this.State = 
                odataObject.State;
              
            this.StateName = 
                odataObject.StateName;
              
            this.Country = 
                odataObject.Country;
              
            this.Distance = 
                (odataObject.Distance != null) ? parseFloat(odataObject.Distance) : undefined;
              
            this.InventoryLocationId = 
                odataObject.InventoryLocationId;
              
            this.InventorySiteId = 
                odataObject.InventorySiteId;
              
            this.IsPickupLocation = 
                odataObject.IsPickupLocation;
              
            this.IsShippingLocation = 
                odataObject.IsShippingLocation;
              
            this.PostalAddressId = 
                (odataObject.PostalAddressId != null) ? parseInt(odataObject.PostalAddressId, 10) : undefined;
              
            this.OpenFrom = 
                odataObject.OpenFrom;
              
            this.OpenTo = 
                odataObject.OpenTo;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['OrgUnitLocationClass'] = OrgUnitLocationClass;
    
      /**
      * OrgUnitContact entity class.
      */
      export class OrgUnitContactClass implements Entities.OrgUnitContact {
      public ChannelId: number;
      public Locator: string;
      public LocatorExtension: string;
      public ContactTypeValue: number;
      public IsPrimary: boolean;
      public IsPrivate: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ChannelId = 
                (odataObject.ChannelId != null) ? parseInt(odataObject.ChannelId, 10) : undefined;
              
            this.Locator = 
                odataObject.Locator;
              
            this.LocatorExtension = 
                odataObject.LocatorExtension;
              
            this.ContactTypeValue = 
                odataObject.ContactTypeValue;
              
            this.IsPrimary = 
                odataObject.IsPrimary;
              
            this.IsPrivate = 
                odataObject.IsPrivate;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['OrgUnitContactClass'] = OrgUnitContactClass;
    
      /**
      * SalesLineDeliveryOption entity class.
      */
      export class SalesLineDeliveryOptionClass implements Entities.SalesLineDeliveryOption {
      public SalesLineId: string;
      public DeliveryOptions: Entities.DeliveryOption[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.SalesLineId = 
                odataObject.SalesLineId;
              
        this.DeliveryOptions = undefined;
        if (odataObject.DeliveryOptions) {
        this.DeliveryOptions = [];
        for (var i = 0; i < odataObject.DeliveryOptions.length; i++) {
        if (odataObject.DeliveryOptions[i] != null) {
        if (odataObject.DeliveryOptions[i]['@odata.type'] != null) {
          var className: string = odataObject.DeliveryOptions[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.DeliveryOptions[i] = new entityDictionary[className](odataObject.DeliveryOptions[i])
          }
        } else {
            this.DeliveryOptions[i] = new DeliveryOptionClass(odataObject.DeliveryOptions[i]);
        }
                    } else {
        this.DeliveryOptions[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['SalesLineDeliveryOptionClass'] = SalesLineDeliveryOptionClass;
    
      /**
      * DeliveryOption entity class.
      */
      export class DeliveryOptionClass implements Entities.DeliveryOption {
      public ChargeGroup: string;
      public Code: string;
      public Description: string;
      public RecordId: number;
      public DeliveryChargeLines: Entities.DeliveryChargeLine[];
      public TotalChargeAmount: number;
      public ShippingChargeAmount: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ChargeGroup = 
                odataObject.ChargeGroup;
              
            this.Code = 
                odataObject.Code;
              
            this.Description = 
                odataObject.Description;
              
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
        this.DeliveryChargeLines = undefined;
        if (odataObject.DeliveryChargeLines) {
        this.DeliveryChargeLines = [];
        for (var i = 0; i < odataObject.DeliveryChargeLines.length; i++) {
        if (odataObject.DeliveryChargeLines[i] != null) {
        if (odataObject.DeliveryChargeLines[i]['@odata.type'] != null) {
          var className: string = odataObject.DeliveryChargeLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.DeliveryChargeLines[i] = new entityDictionary[className](odataObject.DeliveryChargeLines[i])
          }
        } else {
            this.DeliveryChargeLines[i] = new DeliveryChargeLineClass(odataObject.DeliveryChargeLines[i]);
        }
                    } else {
        this.DeliveryChargeLines[i] = undefined;
        }
        }
        }
      
            this.TotalChargeAmount = 
                (odataObject.TotalChargeAmount != null) ? parseFloat(odataObject.TotalChargeAmount) : undefined;
              
            this.ShippingChargeAmount = 
                (odataObject.ShippingChargeAmount != null) ? parseFloat(odataObject.ShippingChargeAmount) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['DeliveryOptionClass'] = DeliveryOptionClass;
    
      /**
      * ChannelDeliveryOption entity class.
      */
      export class ChannelDeliveryOptionClass implements Entities.ChannelDeliveryOption {
      public ChannelId: number;
      public DeliveryOptions: Entities.DeliveryOption[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ChannelId = 
                (odataObject.ChannelId != null) ? parseInt(odataObject.ChannelId, 10) : undefined;
              
        this.DeliveryOptions = undefined;
        if (odataObject.DeliveryOptions) {
        this.DeliveryOptions = [];
        for (var i = 0; i < odataObject.DeliveryOptions.length; i++) {
        if (odataObject.DeliveryOptions[i] != null) {
        if (odataObject.DeliveryOptions[i]['@odata.type'] != null) {
          var className: string = odataObject.DeliveryOptions[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.DeliveryOptions[i] = new entityDictionary[className](odataObject.DeliveryOptions[i])
          }
        } else {
            this.DeliveryOptions[i] = new DeliveryOptionClass(odataObject.DeliveryOptions[i]);
        }
                    } else {
        this.DeliveryOptions[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ChannelDeliveryOptionClass'] = ChannelDeliveryOptionClass;
    
      /**
      * ChannelIdentity entity class.
      */
      export class ChannelIdentityClass implements Entities.ChannelIdentity {
      public ChannelType: Entities.ExtensibleRetailChannelType;
      public Name: string;
      public OperatingUnitNumber: string;
      public DataAreaId: string;
      public RecordId: number;
      public InventoryLocationId: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        if (odataObject.ChannelType == null) {
            this.ChannelType = undefined;
        } else if (odataObject.ChannelType['@odata.type'] == null) {
            this.ChannelType = new ExtensibleRetailChannelTypeClass(odataObject.ChannelType);
        } else {
            var className: string = odataObject.ChannelType['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.ChannelType = new entityDictionary[className](odataObject.ChannelType)
            }
        }

      
            this.Name = 
                odataObject.Name;
              
            this.OperatingUnitNumber = 
                odataObject.OperatingUnitNumber;
              
            this.DataAreaId = 
                odataObject.DataAreaId;
              
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.InventoryLocationId = 
                odataObject.InventoryLocationId;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ChannelIdentityClass'] = ChannelIdentityClass;
    
      /**
      * ExtensibleRetailChannelType entity class.
      */
      export class ExtensibleRetailChannelTypeClass
        extends ExtensibleEnumerationClass
       implements Entities.ExtensibleRetailChannelType {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.ExtensibleRetailChannelType";
      
      }
      }
      entityDictionary['ExtensibleRetailChannelTypeClass'] = ExtensibleRetailChannelTypeClass;
    
      /**
      * ChannelProfileProperty entity class.
      */
      export class ChannelProfilePropertyClass implements Entities.ChannelProfileProperty {
      public Key: number;
      public Value: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Key = 
                odataObject.Key;
              
            this.Value = 
                odataObject.Value;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ChannelProfilePropertyClass'] = ChannelProfilePropertyClass;
    
      /**
      * ChannelConfiguration entity class.
      */
      export class ChannelConfigurationClass implements Entities.ChannelConfiguration {
      public RecordId: number;
      public ChannelNaturalId: string;
      public MinimumDepositPercentage: number;
      public QuoteExpirationDays: number;
      public PickupDeliveryModeCode: string;
      public CarryoutDeliveryModeCode: string;
      public CancellationChargePercentage: number;
      public InventLocation: string;
      public InventLocationDataAreaId: string;
      public BingMapsApiKey: string;
      public BingMapsEnabled: boolean;
      public Currency: string;
      public CatalogDefaultImageTemplate: string;
      public CompanyCurrency: string;
      public PriceIncludesSalesTax: boolean;
      public CountryRegionId: string;
      public ChannelCountryRegionISOCode: string;
      public DefaultLanguageId: string;
      public TimeZoneInfoId: string;
      public EmailDeliveryModeCode: string;
      public GiftCardItemId: string;
      public EnableProductRecommendations: boolean;
      public RefundableShippingAmountWithoutApproval: number;
      public RefundShippingCharges: boolean;
      public ReceiptSettingsValue: number;
      public CustomerAttributeGroupId: number;
      public ClientBookAttributeGroupId: number;
      public NotificationRefreshInterval: number;
      public AllowExchangeOnReturnOrders: boolean;
      public FiscalRegistrationProcessId: string;
      public IsInventoryAutomaticValidationEnabled: boolean;
      public HideSystemInventoryInStockCount: boolean;
      public EnablePriceChangeTracking: boolean;
      public ProfileProperties: Entities.ChannelProfileProperty[];
      public Properties: Entities.ChannelProperty[];
      public Languages: Entities.ChannelLanguage[];
      public UseAdvancedAutoCharges: boolean;
      public UseRTSForOnlineOrderCreation: boolean;
      public IsWarrantyAutoPromptEnabled: boolean;
      public EnableProductRatingsForRetailStores: boolean;
      public EnableFetchingPickingListsForPickingAndReceiving: boolean;
      public EnableReturnsForMultipleOrderInvoices: boolean;
      public EnableAnonymousOrderLookupByChannelReferenceId: boolean;
      public VoidSuspendedTransactionsOnCloseShift: number;
      public EnableOmniChannelPayments: boolean;
      public UseAdvancedCashManagement: boolean;
      public EnableFiscalRegistrationStorageBackup: boolean;
      public UseSalesReceiptAsDefaultReceiptTypeInJournal: boolean;
      public SalesOrderHeaderAttributeGroups: number[];
      public SalesOrderLinesAttributeGroups: number[];
      public CustomerAddressBooks: Entities.StoreAddressBook[];
      public EnableReturnPolicies: boolean;
      public InventAvailabilityCalculationMode: string;
      public IsPriceInclusiveTaxExemptCalculationEnabled: boolean;
      public EnableValidationOfSerialNumbersForInStoreReturns: boolean;
      public DisplayNonRetailOrdersInEcommerceOrderHistory: boolean;
      public EnableCopilotCustomerInsights: boolean;
      public EnableCopilotProductInsights: boolean;
      public EnableCopilotReportInsights: boolean;
      public EnableStoreCommerceCopilot: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.ChannelNaturalId = 
                odataObject.ChannelNaturalId;
              
            this.MinimumDepositPercentage = 
                (odataObject.MinimumDepositPercentage != null) ? parseFloat(odataObject.MinimumDepositPercentage) : undefined;
              
            this.QuoteExpirationDays = 
                odataObject.QuoteExpirationDays;
              
            this.PickupDeliveryModeCode = 
                odataObject.PickupDeliveryModeCode;
              
            this.CarryoutDeliveryModeCode = 
                odataObject.CarryoutDeliveryModeCode;
              
            this.CancellationChargePercentage = 
                (odataObject.CancellationChargePercentage != null) ? parseFloat(odataObject.CancellationChargePercentage) : undefined;
              
            this.InventLocation = 
                odataObject.InventLocation;
              
            this.InventLocationDataAreaId = 
                odataObject.InventLocationDataAreaId;
              
            this.BingMapsApiKey = 
                odataObject.BingMapsApiKey;
              
            this.BingMapsEnabled = 
                odataObject.BingMapsEnabled;
              
            this.Currency = 
                odataObject.Currency;
              
            this.CatalogDefaultImageTemplate = 
                odataObject.CatalogDefaultImageTemplate;
              
            this.CompanyCurrency = 
                odataObject.CompanyCurrency;
              
            this.PriceIncludesSalesTax = 
                odataObject.PriceIncludesSalesTax;
              
            this.CountryRegionId = 
                odataObject.CountryRegionId;
              
            this.ChannelCountryRegionISOCode = 
                odataObject.ChannelCountryRegionISOCode;
              
            this.DefaultLanguageId = 
                odataObject.DefaultLanguageId;
              
            this.TimeZoneInfoId = 
                odataObject.TimeZoneInfoId;
              
            this.EmailDeliveryModeCode = 
                odataObject.EmailDeliveryModeCode;
              
            this.GiftCardItemId = 
                odataObject.GiftCardItemId;
              
            this.EnableProductRecommendations = 
                odataObject.EnableProductRecommendations;
              
            this.RefundableShippingAmountWithoutApproval = 
                (odataObject.RefundableShippingAmountWithoutApproval != null) ? parseFloat(odataObject.RefundableShippingAmountWithoutApproval) : undefined;
              
            this.RefundShippingCharges = 
                odataObject.RefundShippingCharges;
              
            this.ReceiptSettingsValue = 
                odataObject.ReceiptSettingsValue;
              
            this.CustomerAttributeGroupId = 
                (odataObject.CustomerAttributeGroupId != null) ? parseInt(odataObject.CustomerAttributeGroupId, 10) : undefined;
              
            this.ClientBookAttributeGroupId = 
                (odataObject.ClientBookAttributeGroupId != null) ? parseInt(odataObject.ClientBookAttributeGroupId, 10) : undefined;
              
            this.NotificationRefreshInterval = 
                odataObject.NotificationRefreshInterval;
              
            this.AllowExchangeOnReturnOrders = 
                odataObject.AllowExchangeOnReturnOrders;
              
            this.FiscalRegistrationProcessId = 
                odataObject.FiscalRegistrationProcessId;
              
            this.IsInventoryAutomaticValidationEnabled = 
                odataObject.IsInventoryAutomaticValidationEnabled;
              
            this.HideSystemInventoryInStockCount = 
                odataObject.HideSystemInventoryInStockCount;
              
            this.EnablePriceChangeTracking = 
                odataObject.EnablePriceChangeTracking;
              
        this.ProfileProperties = undefined;
        if (odataObject.ProfileProperties) {
        this.ProfileProperties = [];
        for (var i = 0; i < odataObject.ProfileProperties.length; i++) {
        if (odataObject.ProfileProperties[i] != null) {
        if (odataObject.ProfileProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ProfileProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ProfileProperties[i] = new entityDictionary[className](odataObject.ProfileProperties[i])
          }
        } else {
            this.ProfileProperties[i] = new ChannelProfilePropertyClass(odataObject.ProfileProperties[i]);
        }
                    } else {
        this.ProfileProperties[i] = undefined;
        }
        }
        }
      
        this.Properties = undefined;
        if (odataObject.Properties) {
        this.Properties = [];
        for (var i = 0; i < odataObject.Properties.length; i++) {
        if (odataObject.Properties[i] != null) {
        if (odataObject.Properties[i]['@odata.type'] != null) {
          var className: string = odataObject.Properties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Properties[i] = new entityDictionary[className](odataObject.Properties[i])
          }
        } else {
            this.Properties[i] = new ChannelPropertyClass(odataObject.Properties[i]);
        }
                    } else {
        this.Properties[i] = undefined;
        }
        }
        }
      
        this.Languages = undefined;
        if (odataObject.Languages) {
        this.Languages = [];
        for (var i = 0; i < odataObject.Languages.length; i++) {
        if (odataObject.Languages[i] != null) {
        if (odataObject.Languages[i]['@odata.type'] != null) {
          var className: string = odataObject.Languages[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Languages[i] = new entityDictionary[className](odataObject.Languages[i])
          }
        } else {
            this.Languages[i] = new ChannelLanguageClass(odataObject.Languages[i]);
        }
                    } else {
        this.Languages[i] = undefined;
        }
        }
        }
      
            this.UseAdvancedAutoCharges = 
                odataObject.UseAdvancedAutoCharges;
              
            this.UseRTSForOnlineOrderCreation = 
                odataObject.UseRTSForOnlineOrderCreation;
              
            this.IsWarrantyAutoPromptEnabled = 
                odataObject.IsWarrantyAutoPromptEnabled;
              
            this.EnableProductRatingsForRetailStores = 
                odataObject.EnableProductRatingsForRetailStores;
              
            this.EnableFetchingPickingListsForPickingAndReceiving = 
                odataObject.EnableFetchingPickingListsForPickingAndReceiving;
              
            this.EnableReturnsForMultipleOrderInvoices = 
                odataObject.EnableReturnsForMultipleOrderInvoices;
              
            this.EnableAnonymousOrderLookupByChannelReferenceId = 
                odataObject.EnableAnonymousOrderLookupByChannelReferenceId;
              
            this.VoidSuspendedTransactionsOnCloseShift = 
                odataObject.VoidSuspendedTransactionsOnCloseShift;
              
            this.EnableOmniChannelPayments = 
                odataObject.EnableOmniChannelPayments;
              
            this.UseAdvancedCashManagement = 
                odataObject.UseAdvancedCashManagement;
              
            this.EnableFiscalRegistrationStorageBackup = 
                odataObject.EnableFiscalRegistrationStorageBackup;
              
            this.UseSalesReceiptAsDefaultReceiptTypeInJournal = 
                odataObject.UseSalesReceiptAsDefaultReceiptTypeInJournal;
              
        this.SalesOrderHeaderAttributeGroups = undefined;
        if (odataObject.SalesOrderHeaderAttributeGroups) {
        this.SalesOrderHeaderAttributeGroups = [];
        for (var i = 0; i < odataObject.SalesOrderHeaderAttributeGroups.length; i++) {
        if (odataObject.SalesOrderHeaderAttributeGroups[i] != null) {
            this.SalesOrderHeaderAttributeGroups[i] = 
                parseInt(odataObject.SalesOrderHeaderAttributeGroups[i], 10);
                            } else {
        this.SalesOrderHeaderAttributeGroups[i] = undefined;
        }
        }
        }
      
        this.SalesOrderLinesAttributeGroups = undefined;
        if (odataObject.SalesOrderLinesAttributeGroups) {
        this.SalesOrderLinesAttributeGroups = [];
        for (var i = 0; i < odataObject.SalesOrderLinesAttributeGroups.length; i++) {
        if (odataObject.SalesOrderLinesAttributeGroups[i] != null) {
            this.SalesOrderLinesAttributeGroups[i] = 
                parseInt(odataObject.SalesOrderLinesAttributeGroups[i], 10);
                            } else {
        this.SalesOrderLinesAttributeGroups[i] = undefined;
        }
        }
        }
      
        this.CustomerAddressBooks = undefined;
        if (odataObject.CustomerAddressBooks) {
        this.CustomerAddressBooks = [];
        for (var i = 0; i < odataObject.CustomerAddressBooks.length; i++) {
        if (odataObject.CustomerAddressBooks[i] != null) {
        if (odataObject.CustomerAddressBooks[i]['@odata.type'] != null) {
          var className: string = odataObject.CustomerAddressBooks[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.CustomerAddressBooks[i] = new entityDictionary[className](odataObject.CustomerAddressBooks[i])
          }
        } else {
            this.CustomerAddressBooks[i] = new StoreAddressBookClass(odataObject.CustomerAddressBooks[i]);
        }
                    } else {
        this.CustomerAddressBooks[i] = undefined;
        }
        }
        }
      
            this.EnableReturnPolicies = 
                odataObject.EnableReturnPolicies;
              
            this.InventAvailabilityCalculationMode = 
                odataObject.InventAvailabilityCalculationMode;
              
            this.IsPriceInclusiveTaxExemptCalculationEnabled = 
                odataObject.IsPriceInclusiveTaxExemptCalculationEnabled;
              
            this.EnableValidationOfSerialNumbersForInStoreReturns = 
                odataObject.EnableValidationOfSerialNumbersForInStoreReturns;
              
            this.DisplayNonRetailOrdersInEcommerceOrderHistory = 
                odataObject.DisplayNonRetailOrdersInEcommerceOrderHistory;
              
            this.EnableCopilotCustomerInsights = 
                odataObject.EnableCopilotCustomerInsights;
              
            this.EnableCopilotProductInsights = 
                odataObject.EnableCopilotProductInsights;
              
            this.EnableCopilotReportInsights = 
                odataObject.EnableCopilotReportInsights;
              
            this.EnableStoreCommerceCopilot = 
                odataObject.EnableStoreCommerceCopilot;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ChannelConfigurationClass'] = ChannelConfigurationClass;
    
      /**
      * ChannelProperty entity class.
      */
      export class ChannelPropertyClass implements Entities.ChannelProperty {
      public Name: string;
      public Value: string;
      public Channel: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Name = 
                odataObject.Name;
              
            this.Value = 
                odataObject.Value;
              
            this.Channel = 
                (odataObject.Channel != null) ? parseInt(odataObject.Channel, 10) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ChannelPropertyClass'] = ChannelPropertyClass;
    
      /**
      * ChannelLanguage entity class.
      */
      export class ChannelLanguageClass implements Entities.ChannelLanguage {
      public LanguageId: string;
      public IsDefault: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.LanguageId = 
                odataObject.LanguageId;
              
            this.IsDefault = 
                odataObject.IsDefault;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ChannelLanguageClass'] = ChannelLanguageClass;
    
      /**
      * ChannelDeliveryOptionConfiguration entity class.
      */
      export class ChannelDeliveryOptionConfigurationClass implements Entities.ChannelDeliveryOptionConfiguration {
      public PickupDeliveryModeCodes: string[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.PickupDeliveryModeCodes = undefined;
        if (odataObject.PickupDeliveryModeCodes) {
        this.PickupDeliveryModeCodes = [];
        for (var i = 0; i < odataObject.PickupDeliveryModeCodes.length; i++) {
        if (odataObject.PickupDeliveryModeCodes[i] != null) {
            this.PickupDeliveryModeCodes[i] = 
                odataObject.PickupDeliveryModeCodes[i];
                            } else {
        this.PickupDeliveryModeCodes[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ChannelDeliveryOptionConfigurationClass'] = ChannelDeliveryOptionConfigurationClass;
    
      /**
      * NonSalesTransaction entity class.
      */
      export class NonSalesTransactionClass
        extends TransactionClass
       implements Entities.NonSalesTransaction {
      public ReasonCodeLines: Entities.ReasonCodeLine[];
      public Amount: number;
      public ForeignCurrency: string;
      public TenderTypeId: string;
      public OpenDrawer: boolean;
      public DenominationDetails: Entities.DenominationDetail[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this.ReasonCodeLines = undefined;
        if (odataObject.ReasonCodeLines) {
        this.ReasonCodeLines = [];
        for (var i = 0; i < odataObject.ReasonCodeLines.length; i++) {
        if (odataObject.ReasonCodeLines[i] != null) {
        if (odataObject.ReasonCodeLines[i]['@odata.type'] != null) {
          var className: string = odataObject.ReasonCodeLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ReasonCodeLines[i] = new entityDictionary[className](odataObject.ReasonCodeLines[i])
          }
        } else {
            this.ReasonCodeLines[i] = new ReasonCodeLineClass(odataObject.ReasonCodeLines[i]);
        }
                    } else {
        this.ReasonCodeLines[i] = undefined;
        }
        }
        }
      
            this.Amount = 
                (odataObject.Amount != null) ? parseFloat(odataObject.Amount) : undefined;
              
            this.ForeignCurrency = 
                odataObject.ForeignCurrency;
              
            this.TenderTypeId = 
                odataObject.TenderTypeId;
              
            this.OpenDrawer = 
                odataObject.OpenDrawer;
              
        this.DenominationDetails = undefined;
        if (odataObject.DenominationDetails) {
        this.DenominationDetails = [];
        for (var i = 0; i < odataObject.DenominationDetails.length; i++) {
        if (odataObject.DenominationDetails[i] != null) {
        if (odataObject.DenominationDetails[i]['@odata.type'] != null) {
          var className: string = odataObject.DenominationDetails[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.DenominationDetails[i] = new entityDictionary[className](odataObject.DenominationDetails[i])
          }
        } else {
            this.DenominationDetails[i] = new DenominationDetailClass(odataObject.DenominationDetails[i]);
        }
                    } else {
        this.DenominationDetails[i] = undefined;
        }
        }
        }
      
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.NonSalesTransaction";
      
      }
      }
      entityDictionary['NonSalesTransactionClass'] = NonSalesTransactionClass;
    
      /**
      * DropAndDeclareTransaction entity class.
      */
      export class DropAndDeclareTransactionClass
        extends TransactionClass
       implements Entities.DropAndDeclareTransaction {
      public TenderDetails: Entities.TenderDetail[];
      public ReasonCodeLines: Entities.ReasonCodeLine[];
      public OpenDrawer: boolean;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this.TenderDetails = undefined;
        if (odataObject.TenderDetails) {
        this.TenderDetails = [];
        for (var i = 0; i < odataObject.TenderDetails.length; i++) {
        if (odataObject.TenderDetails[i] != null) {
        if (odataObject.TenderDetails[i]['@odata.type'] != null) {
          var className: string = odataObject.TenderDetails[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.TenderDetails[i] = new entityDictionary[className](odataObject.TenderDetails[i])
          }
        } else {
            this.TenderDetails[i] = new TenderDetailClass(odataObject.TenderDetails[i]);
        }
                    } else {
        this.TenderDetails[i] = undefined;
        }
        }
        }
      
        this.ReasonCodeLines = undefined;
        if (odataObject.ReasonCodeLines) {
        this.ReasonCodeLines = [];
        for (var i = 0; i < odataObject.ReasonCodeLines.length; i++) {
        if (odataObject.ReasonCodeLines[i] != null) {
        if (odataObject.ReasonCodeLines[i]['@odata.type'] != null) {
          var className: string = odataObject.ReasonCodeLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ReasonCodeLines[i] = new entityDictionary[className](odataObject.ReasonCodeLines[i])
          }
        } else {
            this.ReasonCodeLines[i] = new ReasonCodeLineClass(odataObject.ReasonCodeLines[i]);
        }
                    } else {
        this.ReasonCodeLines[i] = undefined;
        }
        }
        }
      
            this.OpenDrawer = 
                odataObject.OpenDrawer;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.DropAndDeclareTransaction";
      
      }
      }
      entityDictionary['DropAndDeclareTransactionClass'] = DropAndDeclareTransactionClass;
    
      /**
      * ProductSearchCriteria entity class.
      */
      export class ProductSearchCriteriaClass implements Entities.ProductSearchCriteria {
      public Ids: number[];
      public CategoryIds: number[];
      public IncludeProductsFromDescendantCategories: boolean;
      public SearchCondition: string;
      public SkipVariantExpansion: boolean;
      public IncludeAttributes: boolean;
      public Barcodes: string[];
      public ItemIds: Entities.ProductLookupClause[];
      public Refinement: Entities.ProductRefinerValue[];
      public CustomerAccountNumber: string;
      public RecommendationListId: string;
      public DownloadProductData: boolean;
      public Context: Entities.ProjectionDomain;
      public Language: string;
      public IsOnline: boolean;
      public DataLevelValue: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.Ids = undefined;
        if (odataObject.Ids) {
        this.Ids = [];
        for (var i = 0; i < odataObject.Ids.length; i++) {
        if (odataObject.Ids[i] != null) {
            this.Ids[i] = 
                parseInt(odataObject.Ids[i], 10);
                            } else {
        this.Ids[i] = undefined;
        }
        }
        }
      
        this.CategoryIds = undefined;
        if (odataObject.CategoryIds) {
        this.CategoryIds = [];
        for (var i = 0; i < odataObject.CategoryIds.length; i++) {
        if (odataObject.CategoryIds[i] != null) {
            this.CategoryIds[i] = 
                parseInt(odataObject.CategoryIds[i], 10);
                            } else {
        this.CategoryIds[i] = undefined;
        }
        }
        }
      
            this.IncludeProductsFromDescendantCategories = 
                odataObject.IncludeProductsFromDescendantCategories;
              
            this.SearchCondition = 
                odataObject.SearchCondition;
              
            this.SkipVariantExpansion = 
                odataObject.SkipVariantExpansion;
              
            this.IncludeAttributes = 
                odataObject.IncludeAttributes;
              
        this.Barcodes = undefined;
        if (odataObject.Barcodes) {
        this.Barcodes = [];
        for (var i = 0; i < odataObject.Barcodes.length; i++) {
        if (odataObject.Barcodes[i] != null) {
            this.Barcodes[i] = 
                odataObject.Barcodes[i];
                            } else {
        this.Barcodes[i] = undefined;
        }
        }
        }
      
        this.ItemIds = undefined;
        if (odataObject.ItemIds) {
        this.ItemIds = [];
        for (var i = 0; i < odataObject.ItemIds.length; i++) {
        if (odataObject.ItemIds[i] != null) {
        if (odataObject.ItemIds[i]['@odata.type'] != null) {
          var className: string = odataObject.ItemIds[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ItemIds[i] = new entityDictionary[className](odataObject.ItemIds[i])
          }
        } else {
            this.ItemIds[i] = new ProductLookupClauseClass(odataObject.ItemIds[i]);
        }
                    } else {
        this.ItemIds[i] = undefined;
        }
        }
        }
      
        this.Refinement = undefined;
        if (odataObject.Refinement) {
        this.Refinement = [];
        for (var i = 0; i < odataObject.Refinement.length; i++) {
        if (odataObject.Refinement[i] != null) {
        if (odataObject.Refinement[i]['@odata.type'] != null) {
          var className: string = odataObject.Refinement[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Refinement[i] = new entityDictionary[className](odataObject.Refinement[i])
          }
        } else {
            this.Refinement[i] = new ProductRefinerValueClass(odataObject.Refinement[i]);
        }
                    } else {
        this.Refinement[i] = undefined;
        }
        }
        }
      
            this.CustomerAccountNumber = 
                odataObject.CustomerAccountNumber;
              
            this.RecommendationListId = 
                odataObject.RecommendationListId;
              
            this.DownloadProductData = 
                odataObject.DownloadProductData;
              
        if (odataObject.Context == null) {
            this.Context = undefined;
        } else if (odataObject.Context['@odata.type'] == null) {
            this.Context = new ProjectionDomainClass(odataObject.Context);
        } else {
            var className: string = odataObject.Context['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.Context = new entityDictionary[className](odataObject.Context)
            }
        }

      
            this.Language = 
                odataObject.Language;
              
            this.IsOnline = 
                odataObject.IsOnline;
              
            this.DataLevelValue = 
                odataObject.DataLevelValue;
              
      }
      }
      entityDictionary['ProductSearchCriteriaClass'] = ProductSearchCriteriaClass;
    
      /**
      * ChangedProductsSearchCriteria entity class.
      */
      export class ChangedProductsSearchCriteriaClass implements Entities.ChangedProductsSearchCriteria {
      public SynchronizationToken: string;
      public Context: Entities.ProjectionDomain;
      public AsListings: boolean;
      public Session: Entities.ReadChangedProductsSession;
      public DataLevelValue: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.SynchronizationToken = 
                odataObject.SynchronizationToken;
              
        if (odataObject.Context == null) {
            this.Context = undefined;
        } else if (odataObject.Context['@odata.type'] == null) {
            this.Context = new ProjectionDomainClass(odataObject.Context);
        } else {
            var className: string = odataObject.Context['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.Context = new entityDictionary[className](odataObject.Context)
            }
        }

      
            this.AsListings = 
                odataObject.AsListings;
              
        if (odataObject.Session == null) {
            this.Session = undefined;
        } else if (odataObject.Session['@odata.type'] == null) {
            this.Session = new ReadChangedProductsSessionClass(odataObject.Session);
        } else {
            var className: string = odataObject.Session['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.Session = new entityDictionary[className](odataObject.Session)
            }
        }

      
            this.DataLevelValue = 
                odataObject.DataLevelValue;
              
      }
      }
      entityDictionary['ChangedProductsSearchCriteriaClass'] = ChangedProductsSearchCriteriaClass;
    
      /**
      * EmployeeActivitySearchCriteria entity class.
      */
      export class EmployeeActivitySearchCriteriaClass implements Entities.EmployeeActivitySearchCriteria {
      public FromDateTimeOffset: Date;
      public ToDateTimeOffset: Date;
      public StoreNumber: string;
      public EmployeeActivityTypeValues: number[];
      public DataLevelValue: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.FromDateTimeOffset = 
                (odataObject.FromDateTimeOffset instanceof Date) ? odataObject.FromDateTimeOffset
                : (odataObject.FromDateTimeOffset != null) ? jsonLightReadStringPropertyValue(odataObject.FromDateTimeOffset, "Edm.DateTimeOffset", false) : undefined;
              
            this.ToDateTimeOffset = 
                (odataObject.ToDateTimeOffset instanceof Date) ? odataObject.ToDateTimeOffset
                : (odataObject.ToDateTimeOffset != null) ? jsonLightReadStringPropertyValue(odataObject.ToDateTimeOffset, "Edm.DateTimeOffset", false) : undefined;
              
            this.StoreNumber = 
                odataObject.StoreNumber;
              
        this.EmployeeActivityTypeValues = undefined;
        if (odataObject.EmployeeActivityTypeValues) {
        this.EmployeeActivityTypeValues = [];
        for (var i = 0; i < odataObject.EmployeeActivityTypeValues.length; i++) {
        if (odataObject.EmployeeActivityTypeValues[i] != null) {
            this.EmployeeActivityTypeValues[i] = 
                odataObject.EmployeeActivityTypeValues[i];
                            } else {
        this.EmployeeActivityTypeValues[i] = undefined;
        }
        }
        }
      
            this.DataLevelValue = 
                odataObject.DataLevelValue;
              
      }
      }
      entityDictionary['EmployeeActivitySearchCriteriaClass'] = EmployeeActivitySearchCriteriaClass;
    
      /**
      * SearchSuggestionCriteria entity class.
      */
      export class SearchSuggestionCriteriaClass implements Entities.SearchSuggestionCriteria {
      public ProductSearchCriteria: Entities.ProductSearchCriteria;
      public CustomerSearchCriteria: Entities.CustomerSearchCriteria;
      public SuggestionType: string;
      public HitPrefix: string;
      public HitSuffix: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        if (odataObject.ProductSearchCriteria == null) {
            this.ProductSearchCriteria = undefined;
        } else if (odataObject.ProductSearchCriteria['@odata.type'] == null) {
            this.ProductSearchCriteria = new ProductSearchCriteriaClass(odataObject.ProductSearchCriteria);
        } else {
            var className: string = odataObject.ProductSearchCriteria['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.ProductSearchCriteria = new entityDictionary[className](odataObject.ProductSearchCriteria)
            }
        }

      
        if (odataObject.CustomerSearchCriteria == null) {
            this.CustomerSearchCriteria = undefined;
        } else if (odataObject.CustomerSearchCriteria['@odata.type'] == null) {
            this.CustomerSearchCriteria = new CustomerSearchCriteriaClass(odataObject.CustomerSearchCriteria);
        } else {
            var className: string = odataObject.CustomerSearchCriteria['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.CustomerSearchCriteria = new entityDictionary[className](odataObject.CustomerSearchCriteria)
            }
        }

      
            this.SuggestionType = 
                odataObject.SuggestionType;
              
            this.HitPrefix = 
                odataObject.HitPrefix;
              
            this.HitSuffix = 
                odataObject.HitSuffix;
              
      }
      }
      entityDictionary['SearchSuggestionCriteriaClass'] = SearchSuggestionCriteriaClass;
    
      /**
      * OrderOriginator entity class.
      */
      export class OrderOriginatorClass implements Entities.OrderOriginator {
      public TransactionId: string;
      public SalesId: string;
      public StaffId: string;
      public StaffName: string;
      public CustomerId: string;
      public CustomerName: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.TransactionId = 
                odataObject.TransactionId;
              
            this.SalesId = 
                odataObject.SalesId;
              
            this.StaffId = 
                odataObject.StaffId;
              
            this.StaffName = 
                odataObject.StaffName;
              
            this.CustomerId = 
                odataObject.CustomerId;
              
            this.CustomerName = 
                odataObject.CustomerName;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['OrderOriginatorClass'] = OrderOriginatorClass;
    
      /**
      * OrderOriginatorSearchCriteria entity class.
      */
      export class OrderOriginatorSearchCriteriaClass implements Entities.OrderOriginatorSearchCriteria {
      public TransactionIds: string[];
      public SalesIds: string[];
      public SearchLocationValue: number;
      public DataLevelValue: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.TransactionIds = undefined;
        if (odataObject.TransactionIds) {
        this.TransactionIds = [];
        for (var i = 0; i < odataObject.TransactionIds.length; i++) {
        if (odataObject.TransactionIds[i] != null) {
            this.TransactionIds[i] = 
                odataObject.TransactionIds[i];
                            } else {
        this.TransactionIds[i] = undefined;
        }
        }
        }
      
        this.SalesIds = undefined;
        if (odataObject.SalesIds) {
        this.SalesIds = [];
        for (var i = 0; i < odataObject.SalesIds.length; i++) {
        if (odataObject.SalesIds[i] != null) {
            this.SalesIds[i] = 
                odataObject.SalesIds[i];
                            } else {
        this.SalesIds[i] = undefined;
        }
        }
        }
      
            this.SearchLocationValue = 
                odataObject.SearchLocationValue;
              
            this.DataLevelValue = 
                odataObject.DataLevelValue;
              
      }
      }
      entityDictionary['OrderOriginatorSearchCriteriaClass'] = OrderOriginatorSearchCriteriaClass;
    
      /**
      * SalesOrdersLookupCriteria entity class.
      */
      export class SalesOrdersLookupCriteriaClass implements Entities.SalesOrdersLookupCriteria {
      public SalesOrderLookups: Entities.SalesOrderLookup[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.SalesOrderLookups = undefined;
        if (odataObject.SalesOrderLookups) {
        this.SalesOrderLookups = [];
        for (var i = 0; i < odataObject.SalesOrderLookups.length; i++) {
        if (odataObject.SalesOrderLookups[i] != null) {
        if (odataObject.SalesOrderLookups[i]['@odata.type'] != null) {
          var className: string = odataObject.SalesOrderLookups[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.SalesOrderLookups[i] = new entityDictionary[className](odataObject.SalesOrderLookups[i])
          }
        } else {
            this.SalesOrderLookups[i] = new SalesOrderLookupClass(odataObject.SalesOrderLookups[i]);
        }
                    } else {
        this.SalesOrderLookups[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['SalesOrdersLookupCriteriaClass'] = SalesOrdersLookupCriteriaClass;
    
      /**
      * CartLineDeliveryPreference entity class.
      */
      export class CartLineDeliveryPreferenceClass implements Entities.CartLineDeliveryPreference {
      public LineId: string;
      public DeliveryPreferenceTypeValues: number[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.LineId = 
                odataObject.LineId;
              
        this.DeliveryPreferenceTypeValues = undefined;
        if (odataObject.DeliveryPreferenceTypeValues) {
        this.DeliveryPreferenceTypeValues = [];
        for (var i = 0; i < odataObject.DeliveryPreferenceTypeValues.length; i++) {
        if (odataObject.DeliveryPreferenceTypeValues[i] != null) {
            this.DeliveryPreferenceTypeValues[i] = 
                odataObject.DeliveryPreferenceTypeValues[i];
                            } else {
        this.DeliveryPreferenceTypeValues[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CartLineDeliveryPreferenceClass'] = CartLineDeliveryPreferenceClass;
    
      /**
      * CartLinePromotion entity class.
      */
      export class CartLinePromotionClass implements Entities.CartLinePromotion {
      public LineId: string;
      public Promotions: string[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.LineId = 
                odataObject.LineId;
              
        this.Promotions = undefined;
        if (odataObject.Promotions) {
        this.Promotions = [];
        for (var i = 0; i < odataObject.Promotions.length; i++) {
        if (odataObject.Promotions[i] != null) {
            this.Promotions[i] = 
                odataObject.Promotions[i];
                            } else {
        this.Promotions[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CartLinePromotionClass'] = CartLinePromotionClass;
    
      /**
      * ProductVariant entity class.
      */
      export class ProductVariantClass implements Entities.ProductVariant {
      public ItemId: string;
      public MasterProductId: number;
      public InventoryDimensionId: string;
      public AdjustedPrice: number;
      public BasePrice: number;
      public Price: number;
      public Color: string;
      public Style: string;
      public Size: string;
      public Configuration: string;
      public ColorId: string;
      public StyleId: string;
      public SizeId: string;
      public ConfigId: string;
      public VariantId: string;
      public Images: Entities.RichMediaLocations[];
      public DistinctProductVariantId: number;
      public ProductNumber: string;
      public PropertiesAsList: Entities.ProductPropertyTranslation[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ItemId = 
                odataObject.ItemId;
              
            this.MasterProductId = 
                (odataObject.MasterProductId != null) ? parseInt(odataObject.MasterProductId, 10) : undefined;
              
            this.InventoryDimensionId = 
                odataObject.InventoryDimensionId;
              
            this.AdjustedPrice = 
                (odataObject.AdjustedPrice != null) ? parseFloat(odataObject.AdjustedPrice) : undefined;
              
            this.BasePrice = 
                (odataObject.BasePrice != null) ? parseFloat(odataObject.BasePrice) : undefined;
              
            this.Price = 
                (odataObject.Price != null) ? parseFloat(odataObject.Price) : undefined;
              
            this.Color = 
                odataObject.Color;
              
            this.Style = 
                odataObject.Style;
              
            this.Size = 
                odataObject.Size;
              
            this.Configuration = 
                odataObject.Configuration;
              
            this.ColorId = 
                odataObject.ColorId;
              
            this.StyleId = 
                odataObject.StyleId;
              
            this.SizeId = 
                odataObject.SizeId;
              
            this.ConfigId = 
                odataObject.ConfigId;
              
            this.VariantId = 
                odataObject.VariantId;
              
        this.Images = undefined;
        if (odataObject.Images) {
        this.Images = [];
        for (var i = 0; i < odataObject.Images.length; i++) {
        if (odataObject.Images[i] != null) {
        if (odataObject.Images[i]['@odata.type'] != null) {
          var className: string = odataObject.Images[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Images[i] = new entityDictionary[className](odataObject.Images[i])
          }
        } else {
            this.Images[i] = new RichMediaLocationsClass(odataObject.Images[i]);
        }
                    } else {
        this.Images[i] = undefined;
        }
        }
        }
      
            this.DistinctProductVariantId = 
                (odataObject.DistinctProductVariantId != null) ? parseInt(odataObject.DistinctProductVariantId, 10) : undefined;
              
            this.ProductNumber = 
                odataObject.ProductNumber;
              
        this.PropertiesAsList = undefined;
        if (odataObject.PropertiesAsList) {
        this.PropertiesAsList = [];
        for (var i = 0; i < odataObject.PropertiesAsList.length; i++) {
        if (odataObject.PropertiesAsList[i] != null) {
        if (odataObject.PropertiesAsList[i]['@odata.type'] != null) {
          var className: string = odataObject.PropertiesAsList[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.PropertiesAsList[i] = new entityDictionary[className](odataObject.PropertiesAsList[i])
          }
        } else {
            this.PropertiesAsList[i] = new ProductPropertyTranslationClass(odataObject.PropertiesAsList[i]);
        }
                    } else {
        this.PropertiesAsList[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ProductVariantClass'] = ProductVariantClass;
    
      /**
      * HardwareProfilePrinter entity class.
      */
      export class HardwareProfilePrinterClass implements Entities.HardwareProfilePrinter {
      public ProfileId: string;
      public DeviceTypeValue: number;
      public DeviceName: string;
      public DeviceMake: string;
      public DeviceModel: string;
      public DeviceDescription: string;
      public CharacterSet: number;
      public ReceiptProfileId: string;
      public BinaryConversion: boolean;
      public DocInsertRemovalTimeout: number;
      public Logo: string;
      public LogoAlignment: string;
      public KeepConnectionOpen: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ProfileId = 
                odataObject.ProfileId;
              
            this.DeviceTypeValue = 
                odataObject.DeviceTypeValue;
              
            this.DeviceName = 
                odataObject.DeviceName;
              
            this.DeviceMake = 
                odataObject.DeviceMake;
              
            this.DeviceModel = 
                odataObject.DeviceModel;
              
            this.DeviceDescription = 
                odataObject.DeviceDescription;
              
            this.CharacterSet = 
                odataObject.CharacterSet;
              
            this.ReceiptProfileId = 
                odataObject.ReceiptProfileId;
              
            this.BinaryConversion = 
                odataObject.BinaryConversion;
              
            this.DocInsertRemovalTimeout = 
                odataObject.DocInsertRemovalTimeout;
              
            this.Logo = 
                odataObject.Logo;
              
            this.LogoAlignment = 
                odataObject.LogoAlignment;
              
            this.KeepConnectionOpen = 
                odataObject.KeepConnectionOpen;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['HardwareProfilePrinterClass'] = HardwareProfilePrinterClass;
    
      /**
      * HardwareProfileScanner entity class.
      */
      export class HardwareProfileScannerClass implements Entities.HardwareProfileScanner {
      public ProfileId: string;
      public DeviceTypeValue: number;
      public DeviceName: string;
      public DeviceDescription: string;
      public DecodeData: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ProfileId = 
                odataObject.ProfileId;
              
            this.DeviceTypeValue = 
                odataObject.DeviceTypeValue;
              
            this.DeviceName = 
                odataObject.DeviceName;
              
            this.DeviceDescription = 
                odataObject.DeviceDescription;
              
            this.DecodeData = 
                odataObject.DecodeData;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['HardwareProfileScannerClass'] = HardwareProfileScannerClass;
    
      /**
      * HardwareProfileCashDrawer entity class.
      */
      export class HardwareProfileCashDrawerClass implements Entities.HardwareProfileCashDrawer {
      public ProfileId: string;
      public DeviceTypeValue: number;
      public DeviceName: string;
      public DeviceDescription: string;
      public DeviceMake: string;
      public DeviceModel: string;
      public IsSharedShiftDrawer: boolean;
      public DrawerKeepConnectionOpen: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ProfileId = 
                odataObject.ProfileId;
              
            this.DeviceTypeValue = 
                odataObject.DeviceTypeValue;
              
            this.DeviceName = 
                odataObject.DeviceName;
              
            this.DeviceDescription = 
                odataObject.DeviceDescription;
              
            this.DeviceMake = 
                odataObject.DeviceMake;
              
            this.DeviceModel = 
                odataObject.DeviceModel;
              
            this.IsSharedShiftDrawer = 
                odataObject.IsSharedShiftDrawer;
              
            this.DrawerKeepConnectionOpen = 
                odataObject.DrawerKeepConnectionOpen;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['HardwareProfileCashDrawerClass'] = HardwareProfileCashDrawerClass;
    
      /**
      * TillLayout entity class.
      */
      export class TillLayoutClass implements Entities.TillLayout {
      public CashChangerLayoutXml: string;
      public CustomerLayoutId: string;
      public CustomerLayoutXml: string;
      public Height: number;
      public LayoutId: string;
      public LayoutTypeValue: number;
      public LayoutXml: string;
      public LayoutXmlPortrait: string;
      public Name: string;
      public ReceiptId: string;
      public ReceiptItemsLayoutXml: string;
      public ReceiptPaymentLayoutXml: string;
      public TotalId: string;
      public TotalsLayoutXml: string;
      public Width: number;
      public RecordId: number;
      public StartScreen: number;
      public ButtonGridZones: Entities.ButtonGridZone[];
      public ImageZones: Entities.ImageZone[];
      public ReportZones: Entities.ReportZone[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.CashChangerLayoutXml = 
                odataObject.CashChangerLayoutXml;
              
            this.CustomerLayoutId = 
                odataObject.CustomerLayoutId;
              
            this.CustomerLayoutXml = 
                odataObject.CustomerLayoutXml;
              
            this.Height = 
                odataObject.Height;
              
            this.LayoutId = 
                odataObject.LayoutId;
              
            this.LayoutTypeValue = 
                odataObject.LayoutTypeValue;
              
            this.LayoutXml = 
                odataObject.LayoutXml;
              
            this.LayoutXmlPortrait = 
                odataObject.LayoutXmlPortrait;
              
            this.Name = 
                odataObject.Name;
              
            this.ReceiptId = 
                odataObject.ReceiptId;
              
            this.ReceiptItemsLayoutXml = 
                odataObject.ReceiptItemsLayoutXml;
              
            this.ReceiptPaymentLayoutXml = 
                odataObject.ReceiptPaymentLayoutXml;
              
            this.TotalId = 
                odataObject.TotalId;
              
            this.TotalsLayoutXml = 
                odataObject.TotalsLayoutXml;
              
            this.Width = 
                odataObject.Width;
              
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.StartScreen = 
                odataObject.StartScreen;
              
        this.ButtonGridZones = undefined;
        if (odataObject.ButtonGridZones) {
        this.ButtonGridZones = [];
        for (var i = 0; i < odataObject.ButtonGridZones.length; i++) {
        if (odataObject.ButtonGridZones[i] != null) {
        if (odataObject.ButtonGridZones[i]['@odata.type'] != null) {
          var className: string = odataObject.ButtonGridZones[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ButtonGridZones[i] = new entityDictionary[className](odataObject.ButtonGridZones[i])
          }
        } else {
            this.ButtonGridZones[i] = new ButtonGridZoneClass(odataObject.ButtonGridZones[i]);
        }
                    } else {
        this.ButtonGridZones[i] = undefined;
        }
        }
        }
      
        this.ImageZones = undefined;
        if (odataObject.ImageZones) {
        this.ImageZones = [];
        for (var i = 0; i < odataObject.ImageZones.length; i++) {
        if (odataObject.ImageZones[i] != null) {
        if (odataObject.ImageZones[i]['@odata.type'] != null) {
          var className: string = odataObject.ImageZones[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ImageZones[i] = new entityDictionary[className](odataObject.ImageZones[i])
          }
        } else {
            this.ImageZones[i] = new ImageZoneClass(odataObject.ImageZones[i]);
        }
                    } else {
        this.ImageZones[i] = undefined;
        }
        }
        }
      
        this.ReportZones = undefined;
        if (odataObject.ReportZones) {
        this.ReportZones = [];
        for (var i = 0; i < odataObject.ReportZones.length; i++) {
        if (odataObject.ReportZones[i] != null) {
        if (odataObject.ReportZones[i]['@odata.type'] != null) {
          var className: string = odataObject.ReportZones[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ReportZones[i] = new entityDictionary[className](odataObject.ReportZones[i])
          }
        } else {
            this.ReportZones[i] = new ReportZoneClass(odataObject.ReportZones[i]);
        }
                    } else {
        this.ReportZones[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['TillLayoutClass'] = TillLayoutClass;
    
      /**
      * SalesInvoice entity class.
      */
      export class SalesInvoiceClass implements Entities.SalesInvoice {
      public RecordId: number;
      public ChannelId: number;
      public Id: string;
      public SalesId: string;
      public SalesType: string;
      public InvoiceDate: Date;
      public DueDate: Date;
      public CurrencyCode: string;
      public InvoiceSubtotal: number;
      public TotalCharges: number;
      public TotalTaxAmount: number;
      public TotalLineDiscount: number;
      public TotalDiscount: number;
      public Amount: number;
      public AmountPaid: number;
      public AmountBalance: number;
      public Account: string;
      public Name: string;
      public TotalManualDiscountPercentage: number;
      public TotalManualDiscountAmount: number;
      public SalesInvoiceLine: Entities.SalesInvoiceLine[];
      public ChargeLines: Entities.ChargeLine[];
      public TenderLines: Entities.TenderLine[];
      public DeliveryModeChargeAmount: number;
      public OrderAccount: string;
      public OrderedByCustomerName: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.ChannelId = 
                (odataObject.ChannelId != null) ? parseInt(odataObject.ChannelId, 10) : undefined;
              
            this.Id = 
                odataObject.Id;
              
            this.SalesId = 
                odataObject.SalesId;
              
            this.SalesType = 
                odataObject.SalesType;
              
            this.InvoiceDate = 
                (odataObject.InvoiceDate instanceof Date) ? odataObject.InvoiceDate
                : (odataObject.InvoiceDate != null) ? jsonLightReadStringPropertyValue(odataObject.InvoiceDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.DueDate = 
                (odataObject.DueDate instanceof Date) ? odataObject.DueDate
                : (odataObject.DueDate != null) ? jsonLightReadStringPropertyValue(odataObject.DueDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.CurrencyCode = 
                odataObject.CurrencyCode;
              
            this.InvoiceSubtotal = 
                (odataObject.InvoiceSubtotal != null) ? parseFloat(odataObject.InvoiceSubtotal) : undefined;
              
            this.TotalCharges = 
                (odataObject.TotalCharges != null) ? parseFloat(odataObject.TotalCharges) : undefined;
              
            this.TotalTaxAmount = 
                (odataObject.TotalTaxAmount != null) ? parseFloat(odataObject.TotalTaxAmount) : undefined;
              
            this.TotalLineDiscount = 
                (odataObject.TotalLineDiscount != null) ? parseFloat(odataObject.TotalLineDiscount) : undefined;
              
            this.TotalDiscount = 
                (odataObject.TotalDiscount != null) ? parseFloat(odataObject.TotalDiscount) : undefined;
              
            this.Amount = 
                (odataObject.Amount != null) ? parseFloat(odataObject.Amount) : undefined;
              
            this.AmountPaid = 
                (odataObject.AmountPaid != null) ? parseFloat(odataObject.AmountPaid) : undefined;
              
            this.AmountBalance = 
                (odataObject.AmountBalance != null) ? parseFloat(odataObject.AmountBalance) : undefined;
              
            this.Account = 
                odataObject.Account;
              
            this.Name = 
                odataObject.Name;
              
            this.TotalManualDiscountPercentage = 
                (odataObject.TotalManualDiscountPercentage != null) ? parseFloat(odataObject.TotalManualDiscountPercentage) : undefined;
              
            this.TotalManualDiscountAmount = 
                (odataObject.TotalManualDiscountAmount != null) ? parseFloat(odataObject.TotalManualDiscountAmount) : undefined;
              
        this.SalesInvoiceLine = undefined;
        if (odataObject.SalesInvoiceLine) {
        this.SalesInvoiceLine = [];
        for (var i = 0; i < odataObject.SalesInvoiceLine.length; i++) {
        if (odataObject.SalesInvoiceLine[i] != null) {
        if (odataObject.SalesInvoiceLine[i]['@odata.type'] != null) {
          var className: string = odataObject.SalesInvoiceLine[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.SalesInvoiceLine[i] = new entityDictionary[className](odataObject.SalesInvoiceLine[i])
          }
        } else {
            this.SalesInvoiceLine[i] = new SalesInvoiceLineClass(odataObject.SalesInvoiceLine[i]);
        }
                    } else {
        this.SalesInvoiceLine[i] = undefined;
        }
        }
        }
      
        this.ChargeLines = undefined;
        if (odataObject.ChargeLines) {
        this.ChargeLines = [];
        for (var i = 0; i < odataObject.ChargeLines.length; i++) {
        if (odataObject.ChargeLines[i] != null) {
        if (odataObject.ChargeLines[i]['@odata.type'] != null) {
          var className: string = odataObject.ChargeLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ChargeLines[i] = new entityDictionary[className](odataObject.ChargeLines[i])
          }
        } else {
            this.ChargeLines[i] = new ChargeLineClass(odataObject.ChargeLines[i]);
        }
                    } else {
        this.ChargeLines[i] = undefined;
        }
        }
        }
      
        this.TenderLines = undefined;
        if (odataObject.TenderLines) {
        this.TenderLines = [];
        for (var i = 0; i < odataObject.TenderLines.length; i++) {
        if (odataObject.TenderLines[i] != null) {
        if (odataObject.TenderLines[i]['@odata.type'] != null) {
          var className: string = odataObject.TenderLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.TenderLines[i] = new entityDictionary[className](odataObject.TenderLines[i])
          }
        } else {
            this.TenderLines[i] = new TenderLineClass(odataObject.TenderLines[i]);
        }
                    } else {
        this.TenderLines[i] = undefined;
        }
        }
        }
      
            this.DeliveryModeChargeAmount = 
                (odataObject.DeliveryModeChargeAmount != null) ? parseFloat(odataObject.DeliveryModeChargeAmount) : undefined;
              
            this.OrderAccount = 
                odataObject.OrderAccount;
              
            this.OrderedByCustomerName = 
                odataObject.OrderedByCustomerName;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['SalesInvoiceClass'] = SalesInvoiceClass;
    
      /**
      * OrderInvoice entity class.
      */
      export class OrderInvoiceClass implements Entities.OrderInvoice {
      public Id: string;
      public CustomerAccountNumber: string;
      public CustomerName: string;
      public InvoiceDate: Date;
      public TotalAmount: number;
      public AmountPaid: number;
      public AmountBalance: number;
      public InvoiceTypeValue: number;
      public CurrencyCode: string;
      public InvoiceDueDate: Date;
      public InvoicePaidStatusValue: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Id = 
                odataObject.Id;
              
            this.CustomerAccountNumber = 
                odataObject.CustomerAccountNumber;
              
            this.CustomerName = 
                odataObject.CustomerName;
              
            this.InvoiceDate = 
                (odataObject.InvoiceDate instanceof Date) ? odataObject.InvoiceDate
                : (odataObject.InvoiceDate != null) ? jsonLightReadStringPropertyValue(odataObject.InvoiceDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.TotalAmount = 
                (odataObject.TotalAmount != null) ? parseFloat(odataObject.TotalAmount) : undefined;
              
            this.AmountPaid = 
                (odataObject.AmountPaid != null) ? parseFloat(odataObject.AmountPaid) : undefined;
              
            this.AmountBalance = 
                (odataObject.AmountBalance != null) ? parseFloat(odataObject.AmountBalance) : undefined;
              
            this.InvoiceTypeValue = 
                odataObject.InvoiceTypeValue;
              
            this.CurrencyCode = 
                odataObject.CurrencyCode;
              
            this.InvoiceDueDate = 
                (odataObject.InvoiceDueDate instanceof Date) ? odataObject.InvoiceDueDate
                : (odataObject.InvoiceDueDate != null) ? jsonLightReadStringPropertyValue(odataObject.InvoiceDueDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.InvoicePaidStatusValue = 
                odataObject.InvoicePaidStatusValue;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['OrderInvoiceClass'] = OrderInvoiceClass;
    
      /**
      * TaxOverride entity class.
      */
      export class TaxOverrideClass implements Entities.TaxOverride {
      public Code: string;
      public SourceTaxGroup: string;
      public DestinationTaxGroup: string;
      public SourceItemTaxGroup: string;
      public DestinationItemTaxGroup: string;
      public ChannelId: number;
      public AdditionalDescription: string;
      public Status: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Code = 
                odataObject.Code;
              
            this.SourceTaxGroup = 
                odataObject.SourceTaxGroup;
              
            this.DestinationTaxGroup = 
                odataObject.DestinationTaxGroup;
              
            this.SourceItemTaxGroup = 
                odataObject.SourceItemTaxGroup;
              
            this.DestinationItemTaxGroup = 
                odataObject.DestinationItemTaxGroup;
              
            this.ChannelId = 
                (odataObject.ChannelId != null) ? parseInt(odataObject.ChannelId, 10) : undefined;
              
            this.AdditionalDescription = 
                odataObject.AdditionalDescription;
              
            this.Status = 
                odataObject.Status;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['TaxOverrideClass'] = TaxOverrideClass;
    
      /**
      * TenderType entity class.
      */
      export class TenderTypeClass implements Entities.TenderType {
      public Function: number;
      public TenderTypeId: string;
      public Name: string;
      public OperationId: number;
      public OperationName: string;
      public ChangeTenderTypeId: string;
      public AboveMinimumChangeAmount: number;
      public AboveMinimumChangeTenderTypeId: string;
      public OpenDrawer: boolean;
      public UseSignatureCaptureDevice: boolean;
      public MinimumSignatureCaptureAmount: number;
      public IsOvertenderAllowed: boolean;
      public IsUndertenderAllowed: boolean;
      public MaximumOvertenderAmount: number;
      public MaximumUndertenderAmount: number;
      public MaximumAmountPerTransaction: number;
      public MaximumAmountPerLine: number;
      public MinimumAmountPerTransaction: number;
      public MinimumAmountPerLine: number;
      public RoundOff: number;
      public CountingRequired: number;
      public TakenToBank: number;
      public TakenToSafe: number;
      public ConnectorId: string;
      public GiftCardItem: string;
      public GiftCardCashoutOutThreshold: number;
      public ChangeLineOnReceipt: string;
      public HideCardInputDetails: boolean;
      public CashDrawerLimitEnabled: boolean;
      public CashDrawerLimit: number;
      public RestrictReturnsWithoutReceipt: boolean;
      public HasTenderDiscount: boolean;
      public DefaultCardTypeId: string;
      public UseForDeclareStartAmount: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Function = 
                odataObject.Function;
              
            this.TenderTypeId = 
                odataObject.TenderTypeId;
              
            this.Name = 
                odataObject.Name;
              
            this.OperationId = 
                odataObject.OperationId;
              
            this.OperationName = 
                odataObject.OperationName;
              
            this.ChangeTenderTypeId = 
                odataObject.ChangeTenderTypeId;
              
            this.AboveMinimumChangeAmount = 
                (odataObject.AboveMinimumChangeAmount != null) ? parseFloat(odataObject.AboveMinimumChangeAmount) : undefined;
              
            this.AboveMinimumChangeTenderTypeId = 
                odataObject.AboveMinimumChangeTenderTypeId;
              
            this.OpenDrawer = 
                odataObject.OpenDrawer;
              
            this.UseSignatureCaptureDevice = 
                odataObject.UseSignatureCaptureDevice;
              
            this.MinimumSignatureCaptureAmount = 
                (odataObject.MinimumSignatureCaptureAmount != null) ? parseFloat(odataObject.MinimumSignatureCaptureAmount) : undefined;
              
            this.IsOvertenderAllowed = 
                odataObject.IsOvertenderAllowed;
              
            this.IsUndertenderAllowed = 
                odataObject.IsUndertenderAllowed;
              
            this.MaximumOvertenderAmount = 
                (odataObject.MaximumOvertenderAmount != null) ? parseFloat(odataObject.MaximumOvertenderAmount) : undefined;
              
            this.MaximumUndertenderAmount = 
                (odataObject.MaximumUndertenderAmount != null) ? parseFloat(odataObject.MaximumUndertenderAmount) : undefined;
              
            this.MaximumAmountPerTransaction = 
                (odataObject.MaximumAmountPerTransaction != null) ? parseFloat(odataObject.MaximumAmountPerTransaction) : undefined;
              
            this.MaximumAmountPerLine = 
                (odataObject.MaximumAmountPerLine != null) ? parseFloat(odataObject.MaximumAmountPerLine) : undefined;
              
            this.MinimumAmountPerTransaction = 
                (odataObject.MinimumAmountPerTransaction != null) ? parseFloat(odataObject.MinimumAmountPerTransaction) : undefined;
              
            this.MinimumAmountPerLine = 
                (odataObject.MinimumAmountPerLine != null) ? parseFloat(odataObject.MinimumAmountPerLine) : undefined;
              
            this.RoundOff = 
                (odataObject.RoundOff != null) ? parseFloat(odataObject.RoundOff) : undefined;
              
            this.CountingRequired = 
                odataObject.CountingRequired;
              
            this.TakenToBank = 
                odataObject.TakenToBank;
              
            this.TakenToSafe = 
                odataObject.TakenToSafe;
              
            this.ConnectorId = 
                odataObject.ConnectorId;
              
            this.GiftCardItem = 
                odataObject.GiftCardItem;
              
            this.GiftCardCashoutOutThreshold = 
                (odataObject.GiftCardCashoutOutThreshold != null) ? parseFloat(odataObject.GiftCardCashoutOutThreshold) : undefined;
              
            this.ChangeLineOnReceipt = 
                odataObject.ChangeLineOnReceipt;
              
            this.HideCardInputDetails = 
                odataObject.HideCardInputDetails;
              
            this.CashDrawerLimitEnabled = 
                odataObject.CashDrawerLimitEnabled;
              
            this.CashDrawerLimit = 
                (odataObject.CashDrawerLimit != null) ? parseFloat(odataObject.CashDrawerLimit) : undefined;
              
            this.RestrictReturnsWithoutReceipt = 
                odataObject.RestrictReturnsWithoutReceipt;
              
            this.HasTenderDiscount = 
                odataObject.HasTenderDiscount;
              
            this.DefaultCardTypeId = 
                odataObject.DefaultCardTypeId;
              
            this.UseForDeclareStartAmount = 
                odataObject.UseForDeclareStartAmount;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['TenderTypeClass'] = TenderTypeClass;
    
      /**
      * ReasonCode entity class.
      */
      export class ReasonCodeClass implements Entities.ReasonCode {
      public InputRequired: boolean;
      public LinkedReasonCodeId: string;
      public MaximumLength: number;
      public MaximumValue: number;
      public MinimumLength: number;
      public MinimumValue: number;
      public OncePerTransaction: boolean;
      public PrintInputNameOnReceipt: boolean;
      public PrintInputToReceipt: boolean;
      public PrintPromptToReceipt: boolean;
      public Prompt: string;
      public RandomFactor: number;
      public RetailUseReasonCode: boolean;
      public ReasonCodeId: string;
      public Description: string;
      public InputTypeValue: number;
      public ActivityValue: number;
      public IsMultiLineText: boolean;
      public ReasonSubCodes: Entities.ReasonSubCode[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.InputRequired = 
                odataObject.InputRequired;
              
            this.LinkedReasonCodeId = 
                odataObject.LinkedReasonCodeId;
              
            this.MaximumLength = 
                odataObject.MaximumLength;
              
            this.MaximumValue = 
                (odataObject.MaximumValue != null) ? parseFloat(odataObject.MaximumValue) : undefined;
              
            this.MinimumLength = 
                odataObject.MinimumLength;
              
            this.MinimumValue = 
                (odataObject.MinimumValue != null) ? parseFloat(odataObject.MinimumValue) : undefined;
              
            this.OncePerTransaction = 
                odataObject.OncePerTransaction;
              
            this.PrintInputNameOnReceipt = 
                odataObject.PrintInputNameOnReceipt;
              
            this.PrintInputToReceipt = 
                odataObject.PrintInputToReceipt;
              
            this.PrintPromptToReceipt = 
                odataObject.PrintPromptToReceipt;
              
            this.Prompt = 
                odataObject.Prompt;
              
            this.RandomFactor = 
                (odataObject.RandomFactor != null) ? parseFloat(odataObject.RandomFactor) : undefined;
              
            this.RetailUseReasonCode = 
                odataObject.RetailUseReasonCode;
              
            this.ReasonCodeId = 
                odataObject.ReasonCodeId;
              
            this.Description = 
                odataObject.Description;
              
            this.InputTypeValue = 
                odataObject.InputTypeValue;
              
            this.ActivityValue = 
                odataObject.ActivityValue;
              
            this.IsMultiLineText = 
                odataObject.IsMultiLineText;
              
        this.ReasonSubCodes = undefined;
        if (odataObject.ReasonSubCodes) {
        this.ReasonSubCodes = [];
        for (var i = 0; i < odataObject.ReasonSubCodes.length; i++) {
        if (odataObject.ReasonSubCodes[i] != null) {
        if (odataObject.ReasonSubCodes[i]['@odata.type'] != null) {
          var className: string = odataObject.ReasonSubCodes[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ReasonSubCodes[i] = new entityDictionary[className](odataObject.ReasonSubCodes[i])
          }
        } else {
            this.ReasonSubCodes[i] = new ReasonSubCodeClass(odataObject.ReasonSubCodes[i]);
        }
                    } else {
        this.ReasonSubCodes[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ReasonCodeClass'] = ReasonCodeClass;
    
      /**
      * ReportDataSet entity class.
      */
      export class ReportDataSetClass implements Entities.ReportDataSet {
      public ReportId: string;
      public Parameters: Entities.CommerceProperty[];
      public Locale: string;
      public Output: Entities.ReportRow[];
      public HasTotalRow: boolean;
      public HasDisclaimer: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ReportId = 
                odataObject.ReportId;
              
        this.Parameters = undefined;
        if (odataObject.Parameters) {
        this.Parameters = [];
        for (var i = 0; i < odataObject.Parameters.length; i++) {
        if (odataObject.Parameters[i] != null) {
        if (odataObject.Parameters[i]['@odata.type'] != null) {
          var className: string = odataObject.Parameters[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Parameters[i] = new entityDictionary[className](odataObject.Parameters[i])
          }
        } else {
            this.Parameters[i] = new CommercePropertyClass(odataObject.Parameters[i]);
        }
                    } else {
        this.Parameters[i] = undefined;
        }
        }
        }
      
            this.Locale = 
                odataObject.Locale;
              
        this.Output = undefined;
        if (odataObject.Output) {
        this.Output = [];
        for (var i = 0; i < odataObject.Output.length; i++) {
        if (odataObject.Output[i] != null) {
        if (odataObject.Output[i]['@odata.type'] != null) {
          var className: string = odataObject.Output[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Output[i] = new entityDictionary[className](odataObject.Output[i])
          }
        } else {
            this.Output[i] = new ReportRowClass(odataObject.Output[i]);
        }
                    } else {
        this.Output[i] = undefined;
        }
        }
        }
      
            this.HasTotalRow = 
                odataObject.HasTotalRow;
              
            this.HasDisclaimer = 
                odataObject.HasDisclaimer;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ReportDataSetClass'] = ReportDataSetClass;
    
      /**
      * ZipCodeInfo entity class.
      */
      export class ZipCodeInfoClass implements Entities.ZipCodeInfo {
      public ZipPostalCode: string;
      public StreetName: string;
      public TimeZone: number;
      public CountryRegionId: string;
      public StateId: string;
      public CountyId: string;
      public CityName: string;
      public CityAlias: string;
      public District: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ZipPostalCode = 
                odataObject.ZipPostalCode;
              
            this.StreetName = 
                odataObject.StreetName;
              
            this.TimeZone = 
                odataObject.TimeZone;
              
            this.CountryRegionId = 
                odataObject.CountryRegionId;
              
            this.StateId = 
                odataObject.StateId;
              
            this.CountyId = 
                odataObject.CountyId;
              
            this.CityName = 
                odataObject.CityName;
              
            this.CityAlias = 
                odataObject.CityAlias;
              
            this.District = 
                odataObject.District;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ZipCodeInfoClass'] = ZipCodeInfoClass;
    
      /**
      * ReasonCodeRequirement entity class.
      */
      export class ReasonCodeRequirementClass implements Entities.ReasonCodeRequirement {
      public ReasonCodeId: string;
      public SourceId: string;
      public TableRefTypeValue: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ReasonCodeId = 
                odataObject.ReasonCodeId;
              
            this.SourceId = 
                odataObject.SourceId;
              
            this.TableRefTypeValue = 
                odataObject.TableRefTypeValue;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ReasonCodeRequirementClass'] = ReasonCodeRequirementClass;
    
      /**
      * QueryResultSettings entity class.
      */
      export class QueryResultSettingsClass implements Entities.QueryResultSettings {
      public Paging: Entities.PagingInfo;
      public Sorting: Entities.SortingInfo;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        if (odataObject.Paging == null) {
            this.Paging = undefined;
        } else if (odataObject.Paging['@odata.type'] == null) {
            this.Paging = new PagingInfoClass(odataObject.Paging);
        } else {
            var className: string = odataObject.Paging['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.Paging = new entityDictionary[className](odataObject.Paging)
            }
        }

      
        if (odataObject.Sorting == null) {
            this.Sorting = undefined;
        } else if (odataObject.Sorting['@odata.type'] == null) {
            this.Sorting = new SortingInfoClass(odataObject.Sorting);
        } else {
            var className: string = odataObject.Sorting['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.Sorting = new entityDictionary[className](odataObject.Sorting)
            }
        }

      
      }
      }
      entityDictionary['QueryResultSettingsClass'] = QueryResultSettingsClass;
    
      /**
      * SortingInfo entity class.
      */
      export class SortingInfoClass implements Entities.SortingInfo {
      public Columns: Entities.SortColumn[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.Columns = undefined;
        if (odataObject.Columns) {
        this.Columns = [];
        for (var i = 0; i < odataObject.Columns.length; i++) {
        if (odataObject.Columns[i] != null) {
        if (odataObject.Columns[i]['@odata.type'] != null) {
          var className: string = odataObject.Columns[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Columns[i] = new entityDictionary[className](odataObject.Columns[i])
          }
        } else {
            this.Columns[i] = new SortColumnClass(odataObject.Columns[i]);
        }
                    } else {
        this.Columns[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['SortingInfoClass'] = SortingInfoClass;
    
      /**
      * SortColumn entity class.
      */
      export class SortColumnClass implements Entities.SortColumn {
      public ColumnName: string;
      public IsDescending: boolean;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ColumnName = 
                odataObject.ColumnName;
              
            this.IsDescending = 
                odataObject.IsDescending;
              
      }
      }
      entityDictionary['SortColumnClass'] = SortColumnClass;
    
      /**
      * PagingInfo entity class.
      */
      export class PagingInfoClass implements Entities.PagingInfo {
      public NextPageToken: string;
      public Top: number;
      public Skip: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.NextPageToken = 
                odataObject.NextPageToken;
              
            this.Top = 
                (odataObject.Top != null) ? parseInt(odataObject.Top, 10) : undefined;
              
            this.Skip = 
                (odataObject.Skip != null) ? parseInt(odataObject.Skip, 10) : undefined;
              
      }
      }
      entityDictionary['PagingInfoClass'] = PagingInfoClass;
    
      /**
      * ProductPropertyTranslation entity class.
      */
      export class ProductPropertyTranslationClass implements Entities.ProductPropertyTranslation {
      public TranslationLanguage: string;
      public TranslatedProperties: Entities.ProductProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.TranslationLanguage = 
                odataObject.TranslationLanguage;
              
        this.TranslatedProperties = undefined;
        if (odataObject.TranslatedProperties) {
        this.TranslatedProperties = [];
        for (var i = 0; i < odataObject.TranslatedProperties.length; i++) {
        if (odataObject.TranslatedProperties[i] != null) {
        if (odataObject.TranslatedProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.TranslatedProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.TranslatedProperties[i] = new entityDictionary[className](odataObject.TranslatedProperties[i])
          }
        } else {
            this.TranslatedProperties[i] = new ProductPropertyClass(odataObject.TranslatedProperties[i]);
        }
                    } else {
        this.TranslatedProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ProductPropertyTranslationClass'] = ProductPropertyTranslationClass;
    
      /**
      * NumberSequenceSeedData entity class.
      */
      export class NumberSequenceSeedDataClass implements Entities.NumberSequenceSeedData {
      public DataTypeValue: number;
      public DataValue: number;
      public LastTransactionDataValue: number;
      public LastTransactionCreatedDateTime: Date;
      public LastTransactionId: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.DataTypeValue = 
                odataObject.DataTypeValue;
              
            this.DataValue = 
                (odataObject.DataValue != null) ? parseInt(odataObject.DataValue, 10) : undefined;
              
            this.LastTransactionDataValue = 
                (odataObject.LastTransactionDataValue != null) ? parseInt(odataObject.LastTransactionDataValue, 10) : undefined;
              
            this.LastTransactionCreatedDateTime = 
                (odataObject.LastTransactionCreatedDateTime instanceof Date) ? odataObject.LastTransactionCreatedDateTime
                : (odataObject.LastTransactionCreatedDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.LastTransactionCreatedDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.LastTransactionId = 
                odataObject.LastTransactionId;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['NumberSequenceSeedDataClass'] = NumberSequenceSeedDataClass;
    
      /**
      * ReceiptNumberResetInfo entity class.
      */
      export class ReceiptNumberResetInfoClass implements Entities.ReceiptNumberResetInfo {
      public ValidFrom: Date;
      public ValidTo: Date;
      public ResetDate: Date;
      public TypeValue: number;
      public Enabled: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ValidFrom = 
                (odataObject.ValidFrom instanceof Date) ? odataObject.ValidFrom
                : (odataObject.ValidFrom != null) ? jsonLightReadStringPropertyValue(odataObject.ValidFrom, "Edm.DateTimeOffset", false) : undefined;
              
            this.ValidTo = 
                (odataObject.ValidTo instanceof Date) ? odataObject.ValidTo
                : (odataObject.ValidTo != null) ? jsonLightReadStringPropertyValue(odataObject.ValidTo, "Edm.DateTimeOffset", false) : undefined;
              
            this.ResetDate = 
                (odataObject.ResetDate instanceof Date) ? odataObject.ResetDate
                : (odataObject.ResetDate != null) ? jsonLightReadStringPropertyValue(odataObject.ResetDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.TypeValue = 
                odataObject.TypeValue;
              
            this.Enabled = 
                odataObject.Enabled;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ReceiptNumberResetInfoClass'] = ReceiptNumberResetInfoClass;
    
      /**
      * DeviceActivationResult entity class.
      */
      export class DeviceActivationResultClass implements Entities.DeviceActivationResult {
      public Device: Entities.Device;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        if (odataObject.Device == null) {
            this.Device = undefined;
        } else if (odataObject.Device['@odata.type'] == null) {
            this.Device = new DeviceClass(odataObject.Device);
        } else {
            var className: string = odataObject.Device['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.Device = new entityDictionary[className](odataObject.Device)
            }
        }

      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['DeviceActivationResultClass'] = DeviceActivationResultClass;
    
      /**
      * PurchaseHistory entity class.
      */
      export class PurchaseHistoryClass implements Entities.PurchaseHistory {
      public DatePurchased: Date;
      public ImageUrl: string;
      public ItemId: string;
      public ProductId: number;
      public ProductName: string;
      public ReceiptId: string;
      public SalesId: string;
      public ChannelId: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.DatePurchased = 
                (odataObject.DatePurchased instanceof Date) ? odataObject.DatePurchased
                : (odataObject.DatePurchased != null) ? jsonLightReadStringPropertyValue(odataObject.DatePurchased, "Edm.DateTimeOffset", false) : undefined;
              
            this.ImageUrl = 
                odataObject.ImageUrl;
              
            this.ItemId = 
                odataObject.ItemId;
              
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
            this.ProductName = 
                odataObject.ProductName;
              
            this.ReceiptId = 
                odataObject.ReceiptId;
              
            this.SalesId = 
                odataObject.SalesId;
              
            this.ChannelId = 
                (odataObject.ChannelId != null) ? parseInt(odataObject.ChannelId, 10) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['PurchaseHistoryClass'] = PurchaseHistoryClass;
    
      /**
      * PurchaseHistorySearchCriteria entity class.
      */
      export class PurchaseHistorySearchCriteriaClass implements Entities.PurchaseHistorySearchCriteria {
      public StartDate: Date;
      public EndDate: Date;
      public CustomerAccountNumber: string;
      public ItemId: string;
      public ProductNameFilter: string;
      public ReceiptId: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.StartDate = 
                (odataObject.StartDate instanceof Date) ? odataObject.StartDate
                : (odataObject.StartDate != null) ? jsonLightReadStringPropertyValue(odataObject.StartDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.EndDate = 
                (odataObject.EndDate instanceof Date) ? odataObject.EndDate
                : (odataObject.EndDate != null) ? jsonLightReadStringPropertyValue(odataObject.EndDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.CustomerAccountNumber = 
                odataObject.CustomerAccountNumber;
              
            this.ItemId = 
                odataObject.ItemId;
              
            this.ProductNameFilter = 
                odataObject.ProductNameFilter;
              
            this.ReceiptId = 
                odataObject.ReceiptId;
              
      }
      }
      entityDictionary['PurchaseHistorySearchCriteriaClass'] = PurchaseHistorySearchCriteriaClass;
    
      /**
      * Node entity class.
      */
      export class NodeClass implements Entities.Node {
      public ParentScopeId: string;
      public ParentSequence: number;
      public Id: string;
      public Sequence: number;
      public Description: string;
      public ScreenshotUri: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ParentScopeId = 
                odataObject.ParentScopeId;
              
            this.ParentSequence = 
                odataObject.ParentSequence;
              
            this.Id = 
                odataObject.Id;
              
            this.Sequence = 
                odataObject.Sequence;
              
            this.Description = 
                odataObject.Description;
              
            this.ScreenshotUri = 
                odataObject.ScreenshotUri;
              
      }
      }
      entityDictionary['NodeClass'] = NodeClass;
    
      /**
      * Line entity class.
      */
      export class LineClass implements Entities.Line {
      public ChildrenIds: number[];
      public Id: number;
      public Name: string;
      public Description: string;
      public IsLinked: boolean;
      public LineMetadataId: number;
      public Artifacts: Entities.ArtifactSummary[];
      public MetadataPropertyValues: Entities.MetadataPropertyValue[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.ChildrenIds = undefined;
        if (odataObject.ChildrenIds) {
        this.ChildrenIds = [];
        for (var i = 0; i < odataObject.ChildrenIds.length; i++) {
        if (odataObject.ChildrenIds[i] != null) {
            this.ChildrenIds[i] = 
                parseInt(odataObject.ChildrenIds[i], 10);
                            } else {
        this.ChildrenIds[i] = undefined;
        }
        }
        }
      
            this.Id = 
                (odataObject.Id != null) ? parseInt(odataObject.Id, 10) : undefined;
              
            this.Name = 
                odataObject.Name;
              
            this.Description = 
                odataObject.Description;
              
            this.IsLinked = 
                odataObject.IsLinked;
              
            this.LineMetadataId = 
                (odataObject.LineMetadataId != null) ? parseInt(odataObject.LineMetadataId, 10) : undefined;
              
        this.Artifacts = undefined;
        if (odataObject.Artifacts) {
        this.Artifacts = [];
        for (var i = 0; i < odataObject.Artifacts.length; i++) {
        if (odataObject.Artifacts[i] != null) {
        if (odataObject.Artifacts[i]['@odata.type'] != null) {
          var className: string = odataObject.Artifacts[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Artifacts[i] = new entityDictionary[className](odataObject.Artifacts[i])
          }
        } else {
            this.Artifacts[i] = new ArtifactSummaryClass(odataObject.Artifacts[i]);
        }
                    } else {
        this.Artifacts[i] = undefined;
        }
        }
        }
      
        this.MetadataPropertyValues = undefined;
        if (odataObject.MetadataPropertyValues) {
        this.MetadataPropertyValues = [];
        for (var i = 0; i < odataObject.MetadataPropertyValues.length; i++) {
        if (odataObject.MetadataPropertyValues[i] != null) {
        if (odataObject.MetadataPropertyValues[i]['@odata.type'] != null) {
          var className: string = odataObject.MetadataPropertyValues[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.MetadataPropertyValues[i] = new entityDictionary[className](odataObject.MetadataPropertyValues[i])
          }
        } else {
            this.MetadataPropertyValues[i] = new MetadataPropertyValueClass(odataObject.MetadataPropertyValues[i]);
        }
                    } else {
        this.MetadataPropertyValues[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['LineClass'] = LineClass;
    
      /**
      * MetadataText entity class.
      */
      export class MetadataTextClass implements Entities.MetadataText {
      public Id: string;
      public Text: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Id = 
                odataObject.Id;
              
            this.Text = 
                odataObject.Text;
              
      }
      }
      entityDictionary['MetadataTextClass'] = MetadataTextClass;
    
      /**
      * MetadataHypertextLink entity class.
      */
      export class MetadataHypertextLinkClass implements Entities.MetadataHypertextLink {
      public Id: string;
      public DisplayName: string;
      public Url: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Id = 
                odataObject.Id;
              
            this.DisplayName = 
                odataObject.DisplayName;
              
            this.Url = 
                odataObject.Url;
              
      }
      }
      entityDictionary['MetadataHypertextLinkClass'] = MetadataHypertextLinkClass;
    
      /**
      * Scope entity class.
      */
      export class ScopeClass
        extends NodeClass
       implements Entities.Scope {
      public Name: string;
      public ScopeTypeValue: number;
      public Children: Entities.Node[];
      public ActiveCount: number;
      public IsForm: boolean;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.Name = 
                odataObject.Name;
              
            this.ScopeTypeValue = 
                odataObject.ScopeTypeValue;
              
        this.Children = undefined;
        if (odataObject.Children) {
        this.Children = [];
        for (var i = 0; i < odataObject.Children.length; i++) {
        if (odataObject.Children[i] != null) {
        if (odataObject.Children[i]['@odata.type'] != null) {
          var className: string = odataObject.Children[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Children[i] = new entityDictionary[className](odataObject.Children[i])
          }
        } else {
            this.Children[i] = new NodeClass(odataObject.Children[i]);
        }
                    } else {
        this.Children[i] = undefined;
        }
        }
        }
      
            this.ActiveCount = 
                odataObject.ActiveCount;
              
            this.IsForm = 
                odataObject.IsForm;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.Scope";
      
      }
      }
      entityDictionary['ScopeClass'] = ScopeClass;
    
      /**
      * Framework entity class.
      */
      export class FrameworkClass implements Entities.Framework {
      public Lines: Entities.Line[];
      public Id: number;
      public Name: string;
      public Access: string;
      public Hierarchy: Entities.Line[];
      public Publisher: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.Lines = undefined;
        if (odataObject.Lines) {
        this.Lines = [];
        for (var i = 0; i < odataObject.Lines.length; i++) {
        if (odataObject.Lines[i] != null) {
        if (odataObject.Lines[i]['@odata.type'] != null) {
          var className: string = odataObject.Lines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Lines[i] = new entityDictionary[className](odataObject.Lines[i])
          }
        } else {
            this.Lines[i] = new LineClass(odataObject.Lines[i]);
        }
                    } else {
        this.Lines[i] = undefined;
        }
        }
        }
      
            this.Id = 
                (odataObject.Id != null) ? parseInt(odataObject.Id, 10) : undefined;
              
            this.Name = 
                odataObject.Name;
              
            this.Access = 
                odataObject.Access;
              
        this.Hierarchy = undefined;
        if (odataObject.Hierarchy) {
        this.Hierarchy = [];
        for (var i = 0; i < odataObject.Hierarchy.length; i++) {
        if (odataObject.Hierarchy[i] != null) {
        if (odataObject.Hierarchy[i]['@odata.type'] != null) {
          var className: string = odataObject.Hierarchy[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Hierarchy[i] = new entityDictionary[className](odataObject.Hierarchy[i])
          }
        } else {
            this.Hierarchy[i] = new LineClass(odataObject.Hierarchy[i]);
        }
                    } else {
        this.Hierarchy[i] = undefined;
        }
        }
        }
      
            this.Publisher = 
                odataObject.Publisher;
              
      }
      }
      entityDictionary['FrameworkClass'] = FrameworkClass;
    
      /**
      * UserAction entity class.
      */
      export class UserActionClass
        extends NodeClass
       implements Entities.UserAction {
      public SystemGenerated: boolean;
      public GlobalId: string;
      public Annotations: Entities.Annotation[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.SystemGenerated = 
                odataObject.SystemGenerated;
              
            this.GlobalId = 
                odataObject.GlobalId;
              
        this.Annotations = undefined;
        if (odataObject.Annotations) {
        this.Annotations = [];
        for (var i = 0; i < odataObject.Annotations.length; i++) {
        if (odataObject.Annotations[i] != null) {
        if (odataObject.Annotations[i]['@odata.type'] != null) {
          var className: string = odataObject.Annotations[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Annotations[i] = new entityDictionary[className](odataObject.Annotations[i])
          }
        } else {
            this.Annotations[i] = new AnnotationClass(odataObject.Annotations[i]);
        }
                    } else {
        this.Annotations[i] = undefined;
        }
        }
        }
      
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.UserAction";
      
      }
      }
      entityDictionary['UserActionClass'] = UserActionClass;
    
      /**
      * TaskUserAction entity class.
      */
      export class TaskUserActionClass
        extends UserActionClass
       implements Entities.TaskUserAction {
      public UserActionTypeValue: number;
      public Name: string;
      public Comment: string;
      public TaskId: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.UserActionTypeValue = 
                odataObject.UserActionTypeValue;
              
            this.Name = 
                odataObject.Name;
              
            this.Comment = 
                odataObject.Comment;
              
            this.TaskId = 
                odataObject.TaskId;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.TaskUserAction";
      
      }
      }
      entityDictionary['TaskUserActionClass'] = TaskUserActionClass;
    
      /**
      * FormUserAction entity class.
      */
      export class FormUserActionClass
        extends UserActionClass
       implements Entities.FormUserAction {
      public FormId: string;
      public ControlLabel: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.FormId = 
                odataObject.FormId;
              
            this.ControlLabel = 
                odataObject.ControlLabel;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.FormUserAction";
      
      }
      }
      entityDictionary['FormUserActionClass'] = FormUserActionClass;
    
      /**
      * CommandUserAction entity class.
      */
      export class CommandUserActionClass
        extends FormUserActionClass
       implements Entities.CommandUserAction {
      public ReturnTypeValue: number;
      public CommandName: string;
      public ControlName: string;
      public ControlType: string;
      public Arguments: Entities.CommandArgument[];
      public LabelId: string;
      public ReturnValue: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.ReturnTypeValue = 
                odataObject.ReturnTypeValue;
              
            this.CommandName = 
                odataObject.CommandName;
              
            this.ControlName = 
                odataObject.ControlName;
              
            this.ControlType = 
                odataObject.ControlType;
              
        this.Arguments = undefined;
        if (odataObject.Arguments) {
        this.Arguments = [];
        for (var i = 0; i < odataObject.Arguments.length; i++) {
        if (odataObject.Arguments[i] != null) {
        if (odataObject.Arguments[i]['@odata.type'] != null) {
          var className: string = odataObject.Arguments[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Arguments[i] = new entityDictionary[className](odataObject.Arguments[i])
          }
        } else {
            this.Arguments[i] = new CommandArgumentClass(odataObject.Arguments[i]);
        }
                    } else {
        this.Arguments[i] = undefined;
        }
        }
        }
      
            this.LabelId = 
                odataObject.LabelId;
              
            this.ReturnValue = 
                odataObject.ReturnValue;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.CommandUserAction";
      
      }
      }
      entityDictionary['CommandUserActionClass'] = CommandUserActionClass;
    
      /**
      * Annotation entity class.
      */
      export class AnnotationClass implements Entities.Annotation {
      public Description: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Description = 
                odataObject.Description;
              
      }
      }
      entityDictionary['AnnotationClass'] = AnnotationClass;
    
      /**
      * Recording entity class.
      */
      export class RecordingClass implements Entities.Recording {
      public Scopes: Entities.Scope[];
      public FormContextEntries: Entities.FormContextDictionaryEntry[];
      public RootScope: Entities.Scope;
      public Name: string;
      public Description: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.Scopes = undefined;
        if (odataObject.Scopes) {
        this.Scopes = [];
        for (var i = 0; i < odataObject.Scopes.length; i++) {
        if (odataObject.Scopes[i] != null) {
        if (odataObject.Scopes[i]['@odata.type'] != null) {
          var className: string = odataObject.Scopes[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Scopes[i] = new entityDictionary[className](odataObject.Scopes[i])
          }
        } else {
            this.Scopes[i] = new ScopeClass(odataObject.Scopes[i]);
        }
                    } else {
        this.Scopes[i] = undefined;
        }
        }
        }
      
        this.FormContextEntries = undefined;
        if (odataObject.FormContextEntries) {
        this.FormContextEntries = [];
        for (var i = 0; i < odataObject.FormContextEntries.length; i++) {
        if (odataObject.FormContextEntries[i] != null) {
        if (odataObject.FormContextEntries[i]['@odata.type'] != null) {
          var className: string = odataObject.FormContextEntries[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.FormContextEntries[i] = new entityDictionary[className](odataObject.FormContextEntries[i])
          }
        } else {
            this.FormContextEntries[i] = new FormContextDictionaryEntryClass(odataObject.FormContextEntries[i]);
        }
                    } else {
        this.FormContextEntries[i] = undefined;
        }
        }
        }
      
        if (odataObject.RootScope == null) {
            this.RootScope = undefined;
        } else if (odataObject.RootScope['@odata.type'] == null) {
            this.RootScope = new ScopeClass(odataObject.RootScope);
        } else {
            var className: string = odataObject.RootScope['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.RootScope = new entityDictionary[className](odataObject.RootScope)
            }
        }

      
            this.Name = 
                odataObject.Name;
              
            this.Description = 
                odataObject.Description;
              
      }
      }
      entityDictionary['RecordingClass'] = RecordingClass;
    
      /**
      * FormContextDictionaryEntry entity class.
      */
      export class FormContextDictionaryEntryClass implements Entities.FormContextDictionaryEntry {
      public FormId: string;
      public FormContext: Entities.FormContext;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.FormId = 
                odataObject.FormId;
              
        if (odataObject.FormContext == null) {
            this.FormContext = undefined;
        } else if (odataObject.FormContext['@odata.type'] == null) {
            this.FormContext = new FormContextClass(odataObject.FormContext);
        } else {
            var className: string = odataObject.FormContext['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.FormContext = new entityDictionary[className](odataObject.FormContext)
            }
        }

      
      }
      }
      entityDictionary['FormContextDictionaryEntryClass'] = FormContextDictionaryEntryClass;
    
      /**
      * ProductVariantResolutionContext entity class.
      */
      export class ProductVariantResolutionContextClass implements Entities.ProductVariantResolutionContext {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
      }
      }
      entityDictionary['ProductVariantResolutionContextClass'] = ProductVariantResolutionContextClass;
    
      /**
      * ProductVariantResolutionOnKitSelectionContext entity class.
      */
      export class ProductVariantResolutionOnKitSelectionContextClass
        extends ProductVariantResolutionContextClass
       implements Entities.ProductVariantResolutionOnKitSelectionContext {
      public SelectedComponents: Entities.ComponentInSlotRelation[];
      public SlotId: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this.SelectedComponents = undefined;
        if (odataObject.SelectedComponents) {
        this.SelectedComponents = [];
        for (var i = 0; i < odataObject.SelectedComponents.length; i++) {
        if (odataObject.SelectedComponents[i] != null) {
        if (odataObject.SelectedComponents[i]['@odata.type'] != null) {
          var className: string = odataObject.SelectedComponents[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.SelectedComponents[i] = new entityDictionary[className](odataObject.SelectedComponents[i])
          }
        } else {
            this.SelectedComponents[i] = new ComponentInSlotRelationClass(odataObject.SelectedComponents[i]);
        }
                    } else {
        this.SelectedComponents[i] = undefined;
        }
        }
        }
      
            this.SlotId = 
                (odataObject.SlotId != null) ? parseInt(odataObject.SlotId, 10) : undefined;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.ProductVariantResolutionOnKitSelectionContext";
      
      }
      }
      entityDictionary['ProductVariantResolutionOnKitSelectionContextClass'] = ProductVariantResolutionOnKitSelectionContextClass;
    
      /**
      * WarehouseLocation entity class.
      */
      export class WarehouseLocationClass implements Entities.WarehouseLocation {
      public LocationId: string;
      public IsLicensePlateControlled: boolean;
      public ProfileId: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.LocationId = 
                odataObject.LocationId;
              
            this.IsLicensePlateControlled = 
                odataObject.IsLicensePlateControlled;
              
            this.ProfileId = 
                odataObject.ProfileId;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['WarehouseLocationClass'] = WarehouseLocationClass;
    
      /**
      * FiscalTransaction entity class.
      */
      export class FiscalTransactionClass implements Entities.FiscalTransaction {
      public CreatedDateTime: Date;
      public StoreId: string;
      public TerminalId: string;
      public TransactionId: string;
      public TransDateTime: Date;
      public LineNumber: number;
      public RegisterResponse: string;
      public ReceiptCopy: boolean;
      public RecordGUID: string;
      public RegisterStoreId: string;
      public RegisterTerminalId: string;
      public StaffId: string;
      public RegistrationStatusValue: number;
      public RegistrationProcessId: string;
      public ConnectorGroup: string;
      public ConnectorName: string;
      public ConnectorFunctionalityProfileId: string;
      public RegisterInfo: string;
      public DocumentNumber: string;
      public RegistrationTypeValue: number;
      public ServiceName: string;
      public CountryRegionIsoCode: string;
      public TenderLineAdjustments: Entities.FiscalTransactionTenderLineAdjustment[];
      public SalesLineAdjustments: Entities.FiscalTransactionSalesLineAdjustment[];
      public ReasonCodeLines: Entities.ReasonCodeLine[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.CreatedDateTime = 
                (odataObject.CreatedDateTime instanceof Date) ? odataObject.CreatedDateTime
                : (odataObject.CreatedDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.CreatedDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.StoreId = 
                odataObject.StoreId;
              
            this.TerminalId = 
                odataObject.TerminalId;
              
            this.TransactionId = 
                odataObject.TransactionId;
              
            this.TransDateTime = 
                (odataObject.TransDateTime instanceof Date) ? odataObject.TransDateTime
                : (odataObject.TransDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.TransDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.LineNumber = 
                (odataObject.LineNumber != null) ? parseFloat(odataObject.LineNumber) : undefined;
              
            this.RegisterResponse = 
                odataObject.RegisterResponse;
              
            this.ReceiptCopy = 
                odataObject.ReceiptCopy;
              
            this.RecordGUID = 
                odataObject.RecordGUID;
              
            this.RegisterStoreId = 
                odataObject.RegisterStoreId;
              
            this.RegisterTerminalId = 
                odataObject.RegisterTerminalId;
              
            this.StaffId = 
                odataObject.StaffId;
              
            this.RegistrationStatusValue = 
                odataObject.RegistrationStatusValue;
              
            this.RegistrationProcessId = 
                odataObject.RegistrationProcessId;
              
            this.ConnectorGroup = 
                odataObject.ConnectorGroup;
              
            this.ConnectorName = 
                odataObject.ConnectorName;
              
            this.ConnectorFunctionalityProfileId = 
                odataObject.ConnectorFunctionalityProfileId;
              
            this.RegisterInfo = 
                odataObject.RegisterInfo;
              
            this.DocumentNumber = 
                odataObject.DocumentNumber;
              
            this.RegistrationTypeValue = 
                odataObject.RegistrationTypeValue;
              
            this.ServiceName = 
                odataObject.ServiceName;
              
            this.CountryRegionIsoCode = 
                odataObject.CountryRegionIsoCode;
              
        this.TenderLineAdjustments = undefined;
        if (odataObject.TenderLineAdjustments) {
        this.TenderLineAdjustments = [];
        for (var i = 0; i < odataObject.TenderLineAdjustments.length; i++) {
        if (odataObject.TenderLineAdjustments[i] != null) {
        if (odataObject.TenderLineAdjustments[i]['@odata.type'] != null) {
          var className: string = odataObject.TenderLineAdjustments[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.TenderLineAdjustments[i] = new entityDictionary[className](odataObject.TenderLineAdjustments[i])
          }
        } else {
            this.TenderLineAdjustments[i] = new FiscalTransactionTenderLineAdjustmentClass(odataObject.TenderLineAdjustments[i]);
        }
                    } else {
        this.TenderLineAdjustments[i] = undefined;
        }
        }
        }
      
        this.SalesLineAdjustments = undefined;
        if (odataObject.SalesLineAdjustments) {
        this.SalesLineAdjustments = [];
        for (var i = 0; i < odataObject.SalesLineAdjustments.length; i++) {
        if (odataObject.SalesLineAdjustments[i] != null) {
        if (odataObject.SalesLineAdjustments[i]['@odata.type'] != null) {
          var className: string = odataObject.SalesLineAdjustments[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.SalesLineAdjustments[i] = new entityDictionary[className](odataObject.SalesLineAdjustments[i])
          }
        } else {
            this.SalesLineAdjustments[i] = new FiscalTransactionSalesLineAdjustmentClass(odataObject.SalesLineAdjustments[i]);
        }
                    } else {
        this.SalesLineAdjustments[i] = undefined;
        }
        }
        }
      
        this.ReasonCodeLines = undefined;
        if (odataObject.ReasonCodeLines) {
        this.ReasonCodeLines = [];
        for (var i = 0; i < odataObject.ReasonCodeLines.length; i++) {
        if (odataObject.ReasonCodeLines[i] != null) {
        if (odataObject.ReasonCodeLines[i]['@odata.type'] != null) {
          var className: string = odataObject.ReasonCodeLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ReasonCodeLines[i] = new entityDictionary[className](odataObject.ReasonCodeLines[i])
          }
        } else {
            this.ReasonCodeLines[i] = new ReasonCodeLineClass(odataObject.ReasonCodeLines[i]);
        }
                    } else {
        this.ReasonCodeLines[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['FiscalTransactionClass'] = FiscalTransactionClass;
    
      /**
      * FulfillmentLine entity class.
      */
      export class FulfillmentLineClass implements Entities.FulfillmentLine {
      public SalesId: string;
      public SalesPersonStoreId: string;
      public SalesPersonId: string;
      public SalesPersonFirstName: string;
      public SalesPersonLastName: string;
      public SalesLineNumber: number;
      public ReceiptId: string;
      public ChannelReferenceId: string;
      public CustomerId: string;
      public CustomerName: string;
      public EmailAddress: string;
      public DeliveryModeCode: string;
      public DocumentStatusValue: number;
      public FulfillmentDeliveryType: Entities.FulfillmentLineDeliveryType;
      public DeliveryTypeValue: number;
      public QuantityOrdered: number;
      public QuantityNotProcessed: number;
      public QuantityReserved: number;
      public QuantityPicked: number;
      public QuantityPacked: number;
      public QuantityInvoiced: number;
      public QuantityNotInvoiced: number;
      public QuantityNotPacked: number;
      public QuantityNotPicked: number;
      public StoreInventoryTotalQuantity: number;
      public StoreInventoryReservedQuantity: number;
      public StoreInventoryOrderedQuantity: number;
      public ItemId: string;
      public ProductId: number;
      public ProductVariant: string;
      public ItemName: string;
      public SerialNumber: string;
      public FulfillmentStatus: Entities.FulfillmentLineStatus;
      public FulfillmentStatusValue: number;
      public UnitOfMeasureSymbol: string;
      public RequestedShipDate: Date;
      public RequestedDeliveryDate: Date;
      public RequestedReceiptDate: Date;
      public PickupTimeslotStartDateTime: Date;
      public PickupTimeslotEndDateTime: Date;
      public CreatedDate: Date;
      public TotalAmount: number;
      public Balance: number;
      public ShippingAddress: Entities.Address;
      public DetailedOrderStatusValue: number;
      public DetailedLineStatusValue: number;
      public ChannelTypeValue: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.SalesId = 
                odataObject.SalesId;
              
            this.SalesPersonStoreId = 
                odataObject.SalesPersonStoreId;
              
            this.SalesPersonId = 
                odataObject.SalesPersonId;
              
            this.SalesPersonFirstName = 
                odataObject.SalesPersonFirstName;
              
            this.SalesPersonLastName = 
                odataObject.SalesPersonLastName;
              
            this.SalesLineNumber = 
                (odataObject.SalesLineNumber != null) ? parseFloat(odataObject.SalesLineNumber) : undefined;
              
            this.ReceiptId = 
                odataObject.ReceiptId;
              
            this.ChannelReferenceId = 
                odataObject.ChannelReferenceId;
              
            this.CustomerId = 
                odataObject.CustomerId;
              
            this.CustomerName = 
                odataObject.CustomerName;
              
            this.EmailAddress = 
                odataObject.EmailAddress;
              
            this.DeliveryModeCode = 
                odataObject.DeliveryModeCode;
              
            this.DocumentStatusValue = 
                odataObject.DocumentStatusValue;
              
        if (odataObject.FulfillmentDeliveryType == null) {
            this.FulfillmentDeliveryType = undefined;
        } else if (odataObject.FulfillmentDeliveryType['@odata.type'] == null) {
            this.FulfillmentDeliveryType = new FulfillmentLineDeliveryTypeClass(odataObject.FulfillmentDeliveryType);
        } else {
            var className: string = odataObject.FulfillmentDeliveryType['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.FulfillmentDeliveryType = new entityDictionary[className](odataObject.FulfillmentDeliveryType)
            }
        }

      
            this.DeliveryTypeValue = 
                odataObject.DeliveryTypeValue;
              
            this.QuantityOrdered = 
                (odataObject.QuantityOrdered != null) ? parseFloat(odataObject.QuantityOrdered) : undefined;
              
            this.QuantityNotProcessed = 
                (odataObject.QuantityNotProcessed != null) ? parseFloat(odataObject.QuantityNotProcessed) : undefined;
              
            this.QuantityReserved = 
                (odataObject.QuantityReserved != null) ? parseFloat(odataObject.QuantityReserved) : undefined;
              
            this.QuantityPicked = 
                (odataObject.QuantityPicked != null) ? parseFloat(odataObject.QuantityPicked) : undefined;
              
            this.QuantityPacked = 
                (odataObject.QuantityPacked != null) ? parseFloat(odataObject.QuantityPacked) : undefined;
              
            this.QuantityInvoiced = 
                (odataObject.QuantityInvoiced != null) ? parseFloat(odataObject.QuantityInvoiced) : undefined;
              
            this.QuantityNotInvoiced = 
                (odataObject.QuantityNotInvoiced != null) ? parseFloat(odataObject.QuantityNotInvoiced) : undefined;
              
            this.QuantityNotPacked = 
                (odataObject.QuantityNotPacked != null) ? parseFloat(odataObject.QuantityNotPacked) : undefined;
              
            this.QuantityNotPicked = 
                (odataObject.QuantityNotPicked != null) ? parseFloat(odataObject.QuantityNotPicked) : undefined;
              
            this.StoreInventoryTotalQuantity = 
                (odataObject.StoreInventoryTotalQuantity != null) ? parseFloat(odataObject.StoreInventoryTotalQuantity) : undefined;
              
            this.StoreInventoryReservedQuantity = 
                (odataObject.StoreInventoryReservedQuantity != null) ? parseFloat(odataObject.StoreInventoryReservedQuantity) : undefined;
              
            this.StoreInventoryOrderedQuantity = 
                (odataObject.StoreInventoryOrderedQuantity != null) ? parseFloat(odataObject.StoreInventoryOrderedQuantity) : undefined;
              
            this.ItemId = 
                odataObject.ItemId;
              
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
            this.ProductVariant = 
                odataObject.ProductVariant;
              
            this.ItemName = 
                odataObject.ItemName;
              
            this.SerialNumber = 
                odataObject.SerialNumber;
              
        if (odataObject.FulfillmentStatus == null) {
            this.FulfillmentStatus = undefined;
        } else if (odataObject.FulfillmentStatus['@odata.type'] == null) {
            this.FulfillmentStatus = new FulfillmentLineStatusClass(odataObject.FulfillmentStatus);
        } else {
            var className: string = odataObject.FulfillmentStatus['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.FulfillmentStatus = new entityDictionary[className](odataObject.FulfillmentStatus)
            }
        }

      
            this.FulfillmentStatusValue = 
                odataObject.FulfillmentStatusValue;
              
            this.UnitOfMeasureSymbol = 
                odataObject.UnitOfMeasureSymbol;
              
            this.RequestedShipDate = 
                (odataObject.RequestedShipDate instanceof Date) ? odataObject.RequestedShipDate
                : (odataObject.RequestedShipDate != null) ? jsonLightReadStringPropertyValue(odataObject.RequestedShipDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.RequestedDeliveryDate = 
                (odataObject.RequestedDeliveryDate instanceof Date) ? odataObject.RequestedDeliveryDate
                : (odataObject.RequestedDeliveryDate != null) ? jsonLightReadStringPropertyValue(odataObject.RequestedDeliveryDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.RequestedReceiptDate = 
                (odataObject.RequestedReceiptDate instanceof Date) ? odataObject.RequestedReceiptDate
                : (odataObject.RequestedReceiptDate != null) ? jsonLightReadStringPropertyValue(odataObject.RequestedReceiptDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.PickupTimeslotStartDateTime = 
                (odataObject.PickupTimeslotStartDateTime instanceof Date) ? odataObject.PickupTimeslotStartDateTime
                : (odataObject.PickupTimeslotStartDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.PickupTimeslotStartDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.PickupTimeslotEndDateTime = 
                (odataObject.PickupTimeslotEndDateTime instanceof Date) ? odataObject.PickupTimeslotEndDateTime
                : (odataObject.PickupTimeslotEndDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.PickupTimeslotEndDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.CreatedDate = 
                (odataObject.CreatedDate instanceof Date) ? odataObject.CreatedDate
                : (odataObject.CreatedDate != null) ? jsonLightReadStringPropertyValue(odataObject.CreatedDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.TotalAmount = 
                (odataObject.TotalAmount != null) ? parseFloat(odataObject.TotalAmount) : undefined;
              
            this.Balance = 
                (odataObject.Balance != null) ? parseFloat(odataObject.Balance) : undefined;
              
        if (odataObject.ShippingAddress == null) {
            this.ShippingAddress = undefined;
        } else if (odataObject.ShippingAddress['@odata.type'] == null) {
            this.ShippingAddress = new AddressClass(odataObject.ShippingAddress);
        } else {
            var className: string = odataObject.ShippingAddress['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.ShippingAddress = new entityDictionary[className](odataObject.ShippingAddress)
            }
        }

      
            this.DetailedOrderStatusValue = 
                odataObject.DetailedOrderStatusValue;
              
            this.DetailedLineStatusValue = 
                odataObject.DetailedLineStatusValue;
              
            this.ChannelTypeValue = 
                odataObject.ChannelTypeValue;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['FulfillmentLineClass'] = FulfillmentLineClass;
    
      /**
      * FulfillmentLineSearchCriteria entity class.
      */
      export class FulfillmentLineSearchCriteriaClass implements Entities.FulfillmentLineSearchCriteria {
      public CustomerId: string;
      public CustomerName: string;
      public EmailAddress: string;
      public SalesId: string;
      public OrderCreatedStartDate: Date;
      public OrderCreatedEndDate: Date;
      public ReceiptId: string;
      public StoreId: string;
      public ChannelReferenceId: string;
      public RequestedReceiptStartDate: Date;
      public RequestedReceiptEndDate: Date;
      public RequestedDeliveryStartDate: Date;
      public RequestedDeliveryEndDate: Date;
      public FulfillmentStatusValues: number[];
      public DeliveryModeCode: string;
      public WarehouseId: string;
      public LoyaltyCardNumber: string;
      public CustomerPhoneNumber: string;
      public DeliveryTypeValue: number;
      public CustomFilters: Entities.SearchFilter[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.CustomerId = 
                odataObject.CustomerId;
              
            this.CustomerName = 
                odataObject.CustomerName;
              
            this.EmailAddress = 
                odataObject.EmailAddress;
              
            this.SalesId = 
                odataObject.SalesId;
              
            this.OrderCreatedStartDate = 
                (odataObject.OrderCreatedStartDate instanceof Date) ? odataObject.OrderCreatedStartDate
                : (odataObject.OrderCreatedStartDate != null) ? jsonLightReadStringPropertyValue(odataObject.OrderCreatedStartDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.OrderCreatedEndDate = 
                (odataObject.OrderCreatedEndDate instanceof Date) ? odataObject.OrderCreatedEndDate
                : (odataObject.OrderCreatedEndDate != null) ? jsonLightReadStringPropertyValue(odataObject.OrderCreatedEndDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.ReceiptId = 
                odataObject.ReceiptId;
              
            this.StoreId = 
                odataObject.StoreId;
              
            this.ChannelReferenceId = 
                odataObject.ChannelReferenceId;
              
            this.RequestedReceiptStartDate = 
                (odataObject.RequestedReceiptStartDate instanceof Date) ? odataObject.RequestedReceiptStartDate
                : (odataObject.RequestedReceiptStartDate != null) ? jsonLightReadStringPropertyValue(odataObject.RequestedReceiptStartDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.RequestedReceiptEndDate = 
                (odataObject.RequestedReceiptEndDate instanceof Date) ? odataObject.RequestedReceiptEndDate
                : (odataObject.RequestedReceiptEndDate != null) ? jsonLightReadStringPropertyValue(odataObject.RequestedReceiptEndDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.RequestedDeliveryStartDate = 
                (odataObject.RequestedDeliveryStartDate instanceof Date) ? odataObject.RequestedDeliveryStartDate
                : (odataObject.RequestedDeliveryStartDate != null) ? jsonLightReadStringPropertyValue(odataObject.RequestedDeliveryStartDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.RequestedDeliveryEndDate = 
                (odataObject.RequestedDeliveryEndDate instanceof Date) ? odataObject.RequestedDeliveryEndDate
                : (odataObject.RequestedDeliveryEndDate != null) ? jsonLightReadStringPropertyValue(odataObject.RequestedDeliveryEndDate, "Edm.DateTimeOffset", false) : undefined;
              
        this.FulfillmentStatusValues = undefined;
        if (odataObject.FulfillmentStatusValues) {
        this.FulfillmentStatusValues = [];
        for (var i = 0; i < odataObject.FulfillmentStatusValues.length; i++) {
        if (odataObject.FulfillmentStatusValues[i] != null) {
            this.FulfillmentStatusValues[i] = 
                odataObject.FulfillmentStatusValues[i];
                            } else {
        this.FulfillmentStatusValues[i] = undefined;
        }
        }
        }
      
            this.DeliveryModeCode = 
                odataObject.DeliveryModeCode;
              
            this.WarehouseId = 
                odataObject.WarehouseId;
              
            this.LoyaltyCardNumber = 
                odataObject.LoyaltyCardNumber;
              
            this.CustomerPhoneNumber = 
                odataObject.CustomerPhoneNumber;
              
            this.DeliveryTypeValue = 
                odataObject.DeliveryTypeValue;
              
        this.CustomFilters = undefined;
        if (odataObject.CustomFilters) {
        this.CustomFilters = [];
        for (var i = 0; i < odataObject.CustomFilters.length; i++) {
        if (odataObject.CustomFilters[i] != null) {
        if (odataObject.CustomFilters[i]['@odata.type'] != null) {
          var className: string = odataObject.CustomFilters[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.CustomFilters[i] = new entityDictionary[className](odataObject.CustomFilters[i])
          }
        } else {
            this.CustomFilters[i] = new SearchFilterClass(odataObject.CustomFilters[i]);
        }
                    } else {
        this.CustomFilters[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['FulfillmentLineSearchCriteriaClass'] = FulfillmentLineSearchCriteriaClass;
    
      /**
      * Comment entity class.
      */
      export class CommentClass implements Entities.Comment {
      public RecordId: number;
      public AuthorStaffId: string;
      public AuthorName: string;
      public Text: string;
      public CreatedDateTime: Date;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.AuthorStaffId = 
                odataObject.AuthorStaffId;
              
            this.AuthorName = 
                odataObject.AuthorName;
              
            this.Text = 
                odataObject.Text;
              
            this.CreatedDateTime = 
                (odataObject.CreatedDateTime instanceof Date) ? odataObject.CreatedDateTime
                : (odataObject.CreatedDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.CreatedDateTime, "Edm.DateTimeOffset", false) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CommentClass'] = CommentClass;
    
      /**
      * AddressPurpose entity class.
      */
      export class AddressPurposeClass implements Entities.AddressPurpose {
      public RecordId: number;
      public AddressType: number;
      public Name: string;
      public Description: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.AddressType = 
                odataObject.AddressType;
              
            this.Name = 
                odataObject.Name;
              
            this.Description = 
                odataObject.Description;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['AddressPurposeClass'] = AddressPurposeClass;
    
      /**
      * DeliveryChargeLine entity class.
      */
      export class DeliveryChargeLineClass implements Entities.DeliveryChargeLine {
      public ChargeCode: string;
      public CurrencyCode: string;
      public CalculatedAmount: number;
      public Description: string;
      public IsRefundable: boolean;
      public IsShipping: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ChargeCode = 
                odataObject.ChargeCode;
              
            this.CurrencyCode = 
                odataObject.CurrencyCode;
              
            this.CalculatedAmount = 
                (odataObject.CalculatedAmount != null) ? parseFloat(odataObject.CalculatedAmount) : undefined;
              
            this.Description = 
                odataObject.Description;
              
            this.IsRefundable = 
                odataObject.IsRefundable;
              
            this.IsShipping = 
                odataObject.IsShipping;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['DeliveryChargeLineClass'] = DeliveryChargeLineClass;
    
      /**
      * ExtensionPackageDefinition entity class.
      */
      export class ExtensionPackageDefinitionClass implements Entities.ExtensionPackageDefinition {
      public Name: string;
      public Publisher: string;
      public IsEnabled: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Name = 
                odataObject.Name;
              
            this.Publisher = 
                odataObject.Publisher;
              
            this.IsEnabled = 
                odataObject.IsEnabled;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ExtensionPackageDefinitionClass'] = ExtensionPackageDefinitionClass;
    
      /**
      * ReturnPolicy entity class.
      */
      export class ReturnPolicyClass implements Entities.ReturnPolicy {
      public RecordId: number;
      public TenderTypePolicies: Entities.TenderTypePolicy[];
      public IsManagerOverrideEnabled: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
        this.TenderTypePolicies = undefined;
        if (odataObject.TenderTypePolicies) {
        this.TenderTypePolicies = [];
        for (var i = 0; i < odataObject.TenderTypePolicies.length; i++) {
        if (odataObject.TenderTypePolicies[i] != null) {
        if (odataObject.TenderTypePolicies[i]['@odata.type'] != null) {
          var className: string = odataObject.TenderTypePolicies[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.TenderTypePolicies[i] = new entityDictionary[className](odataObject.TenderTypePolicies[i])
          }
        } else {
            this.TenderTypePolicies[i] = new TenderTypePolicyClass(odataObject.TenderTypePolicies[i]);
        }
                    } else {
        this.TenderTypePolicies[i] = undefined;
        }
        }
        }
      
            this.IsManagerOverrideEnabled = 
                odataObject.IsManagerOverrideEnabled;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ReturnPolicyClass'] = ReturnPolicyClass;
    
      /**
      * TenderTypeAmount entity class.
      */
      export class TenderTypeAmountClass implements Entities.TenderTypeAmount {
      public TenderTypeId: string;
      public Amount: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.TenderTypeId = 
                odataObject.TenderTypeId;
              
            this.Amount = 
                (odataObject.Amount != null) ? parseFloat(odataObject.Amount) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['TenderTypeAmountClass'] = TenderTypeAmountClass;
    
      /**
      * FeatureState entity class.
      */
      export class FeatureStateClass implements Entities.FeatureState {
      public Name: string;
      public IsEnabled: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Name = 
                odataObject.Name;
              
            this.IsEnabled = 
                odataObject.IsEnabled;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['FeatureStateClass'] = FeatureStateClass;
    
      /**
      * Warranty entity class.
      */
      export class WarrantyClass
        extends SimpleProductClass
       implements Entities.Warranty {
      public DurationUnitValue: number;
      public Duration: number;
      public ApplicablePriceRangeBaseTypeValue: number;
      public ApplicablePriceRangeMin: number;
      public ApplicablePriceRangeMax: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.DurationUnitValue = 
                odataObject.DurationUnitValue;
              
            this.Duration = 
                odataObject.Duration;
              
            this.ApplicablePriceRangeBaseTypeValue = 
                odataObject.ApplicablePriceRangeBaseTypeValue;
              
            this.ApplicablePriceRangeMin = 
                (odataObject.ApplicablePriceRangeMin != null) ? parseFloat(odataObject.ApplicablePriceRangeMin) : undefined;
              
            this.ApplicablePriceRangeMax = 
                (odataObject.ApplicablePriceRangeMax != null) ? parseFloat(odataObject.ApplicablePriceRangeMax) : undefined;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.Warranty";
      
      }
      }
      entityDictionary['WarrantyClass'] = WarrantyClass;
    
      /**
      * RecallInvoicedSalesLineLookup entity class.
      */
      export class RecallInvoicedSalesLineLookupClass implements Entities.RecallInvoicedSalesLineLookup {
      public InvoicedLineId: number;
      public Quantity: number;
      public SerialNumber: string;
      public IgnoreSerialNumberMismatch: boolean;
      public ReasonCodeLines: Entities.ReasonCodeLine[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.InvoicedLineId = 
                (odataObject.InvoicedLineId != null) ? parseInt(odataObject.InvoicedLineId, 10) : undefined;
              
            this.Quantity = 
                (odataObject.Quantity != null) ? parseFloat(odataObject.Quantity) : undefined;
              
            this.SerialNumber = 
                odataObject.SerialNumber;
              
            this.IgnoreSerialNumberMismatch = 
                odataObject.IgnoreSerialNumberMismatch;
              
        this.ReasonCodeLines = undefined;
        if (odataObject.ReasonCodeLines) {
        this.ReasonCodeLines = [];
        for (var i = 0; i < odataObject.ReasonCodeLines.length; i++) {
        if (odataObject.ReasonCodeLines[i] != null) {
        if (odataObject.ReasonCodeLines[i]['@odata.type'] != null) {
          var className: string = odataObject.ReasonCodeLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ReasonCodeLines[i] = new entityDictionary[className](odataObject.ReasonCodeLines[i])
          }
        } else {
            this.ReasonCodeLines[i] = new ReasonCodeLineClass(odataObject.ReasonCodeLines[i]);
        }
                    } else {
        this.ReasonCodeLines[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['RecallInvoicedSalesLineLookupClass'] = RecallInvoicedSalesLineLookupClass;
    
      /**
      * WarrantyGroup entity class.
      */
      export class WarrantyGroupClass implements Entities.WarrantyGroup {
      public Warranties: Entities.Warranty[];
      public RecordId: number;
      public GroupName: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.Warranties = undefined;
        if (odataObject.Warranties) {
        this.Warranties = [];
        for (var i = 0; i < odataObject.Warranties.length; i++) {
        if (odataObject.Warranties[i] != null) {
        if (odataObject.Warranties[i]['@odata.type'] != null) {
          var className: string = odataObject.Warranties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Warranties[i] = new entityDictionary[className](odataObject.Warranties[i])
          }
        } else {
            this.Warranties[i] = new WarrantyClass(odataObject.Warranties[i]);
        }
                    } else {
        this.Warranties[i] = undefined;
        }
        }
        }
      
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.GroupName = 
                odataObject.GroupName;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['WarrantyGroupClass'] = WarrantyGroupClass;
    
      /**
      * ProductWarrantiesMapping entity class.
      */
      export class ProductWarrantiesMappingClass implements Entities.ProductWarrantiesMapping {
      public WarrantyGroups: Entities.WarrantyGroup[];
      public WarrantableProductId: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.WarrantyGroups = undefined;
        if (odataObject.WarrantyGroups) {
        this.WarrantyGroups = [];
        for (var i = 0; i < odataObject.WarrantyGroups.length; i++) {
        if (odataObject.WarrantyGroups[i] != null) {
        if (odataObject.WarrantyGroups[i]['@odata.type'] != null) {
          var className: string = odataObject.WarrantyGroups[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.WarrantyGroups[i] = new entityDictionary[className](odataObject.WarrantyGroups[i])
          }
        } else {
            this.WarrantyGroups[i] = new WarrantyGroupClass(odataObject.WarrantyGroups[i]);
        }
                    } else {
        this.WarrantyGroups[i] = undefined;
        }
        }
        }
      
            this.WarrantableProductId = 
                (odataObject.WarrantableProductId != null) ? parseInt(odataObject.WarrantableProductId, 10) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ProductWarrantiesMappingClass'] = ProductWarrantiesMappingClass;
    
      /**
      * FulfillmentLineContextualOperationsMap entity class.
      */
      export class FulfillmentLineContextualOperationsMapClass implements Entities.FulfillmentLineContextualOperationsMap {
      public FulfillmentLine: Entities.FulfillmentLine;
      public ApplicableOperationsValues: number[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        if (odataObject.FulfillmentLine == null) {
            this.FulfillmentLine = undefined;
        } else if (odataObject.FulfillmentLine['@odata.type'] == null) {
            this.FulfillmentLine = new FulfillmentLineClass(odataObject.FulfillmentLine);
        } else {
            var className: string = odataObject.FulfillmentLine['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.FulfillmentLine = new entityDictionary[className](odataObject.FulfillmentLine)
            }
        }

      
        this.ApplicableOperationsValues = undefined;
        if (odataObject.ApplicableOperationsValues) {
        this.ApplicableOperationsValues = [];
        for (var i = 0; i < odataObject.ApplicableOperationsValues.length; i++) {
        if (odataObject.ApplicableOperationsValues[i] != null) {
            this.ApplicableOperationsValues[i] = 
                odataObject.ApplicableOperationsValues[i];
                            } else {
        this.ApplicableOperationsValues[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['FulfillmentLineContextualOperationsMapClass'] = FulfillmentLineContextualOperationsMapClass;
    
      /**
      * UserAlert entity class.
      */
      export class UserAlertClass implements Entities.UserAlert {
      public AlertSourceIdValue: number;
      public LocalizedMessage: string;
      public IsBlocking: boolean;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.AlertSourceIdValue = 
                odataObject.AlertSourceIdValue;
              
            this.LocalizedMessage = 
                odataObject.LocalizedMessage;
              
            this.IsBlocking = 
                odataObject.IsBlocking;
              
      }
      }
      entityDictionary['UserAlertClass'] = UserAlertClass;
    
      /**
      * CartLineUserAlerts entity class.
      */
      export class CartLineUserAlertsClass implements Entities.CartLineUserAlerts {
      public LineIndex: number;
      public LineNumber: number;
      public UserAlerts: Entities.UserAlert[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.LineIndex = 
                odataObject.LineIndex;
              
            this.LineNumber = 
                (odataObject.LineNumber != null) ? parseFloat(odataObject.LineNumber) : undefined;
              
        this.UserAlerts = undefined;
        if (odataObject.UserAlerts) {
        this.UserAlerts = [];
        for (var i = 0; i < odataObject.UserAlerts.length; i++) {
        if (odataObject.UserAlerts[i] != null) {
        if (odataObject.UserAlerts[i]['@odata.type'] != null) {
          var className: string = odataObject.UserAlerts[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.UserAlerts[i] = new entityDictionary[className](odataObject.UserAlerts[i])
          }
        } else {
            this.UserAlerts[i] = new UserAlertClass(odataObject.UserAlerts[i]);
        }
                    } else {
        this.UserAlerts[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CartLineUserAlertsClass'] = CartLineUserAlertsClass;
    
      /**
      * FulfillmentLineUserAlerts entity class.
      */
      export class FulfillmentLineUserAlertsClass implements Entities.FulfillmentLineUserAlerts {
      public SalesId: string;
      public SalesLineNumber: number;
      public UserAlerts: Entities.UserAlert[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.SalesId = 
                odataObject.SalesId;
              
            this.SalesLineNumber = 
                (odataObject.SalesLineNumber != null) ? parseFloat(odataObject.SalesLineNumber) : undefined;
              
        this.UserAlerts = undefined;
        if (odataObject.UserAlerts) {
        this.UserAlerts = [];
        for (var i = 0; i < odataObject.UserAlerts.length; i++) {
        if (odataObject.UserAlerts[i] != null) {
        if (odataObject.UserAlerts[i]['@odata.type'] != null) {
          var className: string = odataObject.UserAlerts[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.UserAlerts[i] = new entityDictionary[className](odataObject.UserAlerts[i])
          }
        } else {
            this.UserAlerts[i] = new UserAlertClass(odataObject.UserAlerts[i]);
        }
                    } else {
        this.UserAlerts[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['FulfillmentLineUserAlertsClass'] = FulfillmentLineUserAlertsClass;
    
      /**
      * BusinessPartnerProspect entity class.
      */
      export class BusinessPartnerProspectClass implements Entities.BusinessPartnerProspect {
      public FirstName: string;
      public LastName: string;
      public CompanyName: string;
      public Address: Entities.Address;
      public JobTitle: string;
      public Department: string;
      public CompanySize: number;
      public Comments: string;
      public LastOperationId: string;
      public ProspectTypeValue: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.FirstName = 
                odataObject.FirstName;
              
            this.LastName = 
                odataObject.LastName;
              
            this.CompanyName = 
                odataObject.CompanyName;
              
        if (odataObject.Address == null) {
            this.Address = undefined;
        } else if (odataObject.Address['@odata.type'] == null) {
            this.Address = new AddressClass(odataObject.Address);
        } else {
            var className: string = odataObject.Address['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.Address = new entityDictionary[className](odataObject.Address)
            }
        }

      
            this.JobTitle = 
                odataObject.JobTitle;
              
            this.Department = 
                odataObject.Department;
              
            this.CompanySize = 
                odataObject.CompanySize;
              
            this.Comments = 
                odataObject.Comments;
              
            this.LastOperationId = 
                odataObject.LastOperationId;
              
            this.ProspectTypeValue = 
                odataObject.ProspectTypeValue;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['BusinessPartnerProspectClass'] = BusinessPartnerProspectClass;
    
      /**
      * ChannelSearchCriteria entity class.
      */
      export class ChannelSearchCriteriaClass implements Entities.ChannelSearchCriteria {
      public SearchLocationValue: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.SearchLocationValue = 
                odataObject.SearchLocationValue;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ChannelSearchCriteriaClass'] = ChannelSearchCriteriaClass;
    
      /**
      * BusinessPartnerOperation entity class.
      */
      export class BusinessPartnerOperationClass implements Entities.BusinessPartnerOperation {
      public TypeValue: number;
      public StatusValue: number;
      public Value: string;
      public Description: string;
      public CreatedDateTime: Date;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.TypeValue = 
                odataObject.TypeValue;
              
            this.StatusValue = 
                odataObject.StatusValue;
              
            this.Value = 
                odataObject.Value;
              
            this.Description = 
                odataObject.Description;
              
            this.CreatedDateTime = 
                (odataObject.CreatedDateTime instanceof Date) ? odataObject.CreatedDateTime
                : (odataObject.CreatedDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.CreatedDateTime, "Edm.DateTimeOffset", false) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['BusinessPartnerOperationClass'] = BusinessPartnerOperationClass;
    
      /**
      * BusinessPartnerOperationRequest entity class.
      */
      export class BusinessPartnerOperationRequestClass implements Entities.BusinessPartnerOperationRequest {
      public TypeValue: number;
      public StatusValue: number;
      public CreatedDateTime: Date;
      public Details: Entities.BusinessPartnerOperationRequestDetails;
      public UserId: string;
      public UserFirstName: string;
      public UserLastName: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.TypeValue = 
                odataObject.TypeValue;
              
            this.StatusValue = 
                odataObject.StatusValue;
              
            this.CreatedDateTime = 
                (odataObject.CreatedDateTime instanceof Date) ? odataObject.CreatedDateTime
                : (odataObject.CreatedDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.CreatedDateTime, "Edm.DateTimeOffset", false) : undefined;
              
        if (odataObject.Details == null) {
            this.Details = undefined;
        } else if (odataObject.Details['@odata.type'] == null) {
            this.Details = new BusinessPartnerOperationRequestDetailsClass(odataObject.Details);
        } else {
            var className: string = odataObject.Details['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.Details = new entityDictionary[className](odataObject.Details)
            }
        }

      
            this.UserId = 
                odataObject.UserId;
              
            this.UserFirstName = 
                odataObject.UserFirstName;
              
            this.UserLastName = 
                odataObject.UserLastName;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['BusinessPartnerOperationRequestClass'] = BusinessPartnerOperationRequestClass;
    
      /**
      * ReceiptTypesByFormatType entity class.
      */
      export class ReceiptTypesByFormatTypeClass implements Entities.ReceiptTypesByFormatType {
      public ReceiptOutputFormatType: Entities.ReceiptOutputFormatType;
      public ReceiptTypesValues: number[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        if (odataObject.ReceiptOutputFormatType == null) {
            this.ReceiptOutputFormatType = undefined;
        } else if (odataObject.ReceiptOutputFormatType['@odata.type'] == null) {
            this.ReceiptOutputFormatType = new ReceiptOutputFormatTypeClass(odataObject.ReceiptOutputFormatType);
        } else {
            var className: string = odataObject.ReceiptOutputFormatType['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.ReceiptOutputFormatType = new entityDictionary[className](odataObject.ReceiptOutputFormatType)
            }
        }

      
        this.ReceiptTypesValues = undefined;
        if (odataObject.ReceiptTypesValues) {
        this.ReceiptTypesValues = [];
        for (var i = 0; i < odataObject.ReceiptTypesValues.length; i++) {
        if (odataObject.ReceiptTypesValues[i] != null) {
            this.ReceiptTypesValues[i] = 
                odataObject.ReceiptTypesValues[i];
                            } else {
        this.ReceiptTypesValues[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ReceiptTypesByFormatTypeClass'] = ReceiptTypesByFormatTypeClass;
    
      /**
      * InventoryJournalNameSearchCriteria entity class.
      */
      export class InventoryJournalNameSearchCriteriaClass implements Entities.InventoryJournalNameSearchCriteria {
      public JournalNameIds: string[];
      public DocumentTypeValues: number[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.JournalNameIds = undefined;
        if (odataObject.JournalNameIds) {
        this.JournalNameIds = [];
        for (var i = 0; i < odataObject.JournalNameIds.length; i++) {
        if (odataObject.JournalNameIds[i] != null) {
            this.JournalNameIds[i] = 
                odataObject.JournalNameIds[i];
                            } else {
        this.JournalNameIds[i] = undefined;
        }
        }
        }
      
        this.DocumentTypeValues = undefined;
        if (odataObject.DocumentTypeValues) {
        this.DocumentTypeValues = [];
        for (var i = 0; i < odataObject.DocumentTypeValues.length; i++) {
        if (odataObject.DocumentTypeValues[i] != null) {
            this.DocumentTypeValues[i] = 
                odataObject.DocumentTypeValues[i];
                            } else {
        this.DocumentTypeValues[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['InventoryJournalNameSearchCriteriaClass'] = InventoryJournalNameSearchCriteriaClass;
    
      /**
      * AssortedProductIdentity entity class.
      */
      export class AssortedProductIdentityClass implements Entities.AssortedProductIdentity {
      public ProductId: number;
      public IsRemoteProduct: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
            this.IsRemoteProduct = 
                odataObject.IsRemoteProduct;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['AssortedProductIdentityClass'] = AssortedProductIdentityClass;
    
      /**
      * ProductExistenceId entity class.
      */
      export class ProductExistenceIdClass implements Entities.ProductExistenceId {
      public ProductId: number;
      public LanguageId: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
            this.LanguageId = 
                odataObject.LanguageId;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ProductExistenceIdClass'] = ProductExistenceIdClass;
    
      /**
      * ProductExistenceCriteria entity class.
      */
      export class ProductExistenceCriteriaClass implements Entities.ProductExistenceCriteria {
      public ChannelId: number;
      public CatalogId: number;
      public Ids: Entities.ProductExistenceId[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ChannelId = 
                (odataObject.ChannelId != null) ? parseInt(odataObject.ChannelId, 10) : undefined;
              
            this.CatalogId = 
                (odataObject.CatalogId != null) ? parseInt(odataObject.CatalogId, 10) : undefined;
              
        this.Ids = undefined;
        if (odataObject.Ids) {
        this.Ids = [];
        for (var i = 0; i < odataObject.Ids.length; i++) {
        if (odataObject.Ids[i] != null) {
        if (odataObject.Ids[i]['@odata.type'] != null) {
          var className: string = odataObject.Ids[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Ids[i] = new entityDictionary[className](odataObject.Ids[i])
          }
        } else {
            this.Ids[i] = new ProductExistenceIdClass(odataObject.Ids[i]);
        }
                    } else {
        this.Ids[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ProductExistenceCriteriaClass'] = ProductExistenceCriteriaClass;
    
      /**
      * CountryRegion entity class.
      */
      export class CountryRegionClass implements Entities.CountryRegion {
      public CountryRegionId: string;
      public IsoCode: string;
      public ShortName: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.CountryRegionId = 
                odataObject.CountryRegionId;
              
            this.IsoCode = 
                odataObject.IsoCode;
              
            this.ShortName = 
                odataObject.ShortName;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CountryRegionClass'] = CountryRegionClass;
    
      /**
      * BusinessVerticalsIndia entity class.
      */
      export class BusinessVerticalsIndiaClass implements Entities.BusinessVerticalsIndia {
      public RecId: number;
      public Name: string;
      public EntityCode: string;
      public Description: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecId = 
                (odataObject.RecId != null) ? parseInt(odataObject.RecId, 10) : undefined;
              
            this.Name = 
                odataObject.Name;
              
            this.EntityCode = 
                odataObject.EntityCode;
              
            this.Description = 
                odataObject.Description;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['BusinessVerticalsIndiaClass'] = BusinessVerticalsIndiaClass;
    
      /**
      * AddressTaxInformationIndia entity class.
      */
      export class AddressTaxInformationIndiaClass implements Entities.AddressTaxInformationIndia {
      public RecId: number;
      public Name: string;
      public RegistrationLocation: number;
      public Tin: number;
      public Gstin: number;
      public TinRegistrationNumber: Entities.TaxRegistrationNumberIndia;
      public GstinRegistrationNumber: Entities.TaxRegistrationNumberIndia;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecId = 
                (odataObject.RecId != null) ? parseInt(odataObject.RecId, 10) : undefined;
              
            this.Name = 
                odataObject.Name;
              
            this.RegistrationLocation = 
                (odataObject.RegistrationLocation != null) ? parseInt(odataObject.RegistrationLocation, 10) : undefined;
              
            this.Tin = 
                (odataObject.Tin != null) ? parseInt(odataObject.Tin, 10) : undefined;
              
            this.Gstin = 
                (odataObject.Gstin != null) ? parseInt(odataObject.Gstin, 10) : undefined;
              
        if (odataObject.TinRegistrationNumber == null) {
            this.TinRegistrationNumber = undefined;
        } else if (odataObject.TinRegistrationNumber['@odata.type'] == null) {
            this.TinRegistrationNumber = new TaxRegistrationNumberIndiaClass(odataObject.TinRegistrationNumber);
        } else {
            var className: string = odataObject.TinRegistrationNumber['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.TinRegistrationNumber = new entityDictionary[className](odataObject.TinRegistrationNumber)
            }
        }

      
        if (odataObject.GstinRegistrationNumber == null) {
            this.GstinRegistrationNumber = undefined;
        } else if (odataObject.GstinRegistrationNumber['@odata.type'] == null) {
            this.GstinRegistrationNumber = new TaxRegistrationNumberIndiaClass(odataObject.GstinRegistrationNumber);
        } else {
            var className: string = odataObject.GstinRegistrationNumber['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.GstinRegistrationNumber = new entityDictionary[className](odataObject.GstinRegistrationNumber)
            }
        }

      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['AddressTaxInformationIndiaClass'] = AddressTaxInformationIndiaClass;
    
      /**
      * CustomerTaxInformation entity class.
      */
      export class CustomerTaxInformationClass implements Entities.CustomerTaxInformation {
      public CustomerId: string;
      public DataAreaId: string;
      public IsForeign: boolean;
      public IsPreferential: boolean;
      public NatureOfAssessee: string;
      public PanNumber: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.CustomerId = 
                odataObject.CustomerId;
              
            this.DataAreaId = 
                odataObject.DataAreaId;
              
            this.IsForeign = 
                odataObject.IsForeign;
              
            this.IsPreferential = 
                odataObject.IsPreferential;
              
            this.NatureOfAssessee = 
                odataObject.NatureOfAssessee;
              
            this.PanNumber = 
                odataObject.PanNumber;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CustomerTaxInformationClass'] = CustomerTaxInformationClass;
    
      /**
      * TaxRegistrationNumberIndia entity class.
      */
      export class TaxRegistrationNumberIndiaClass implements Entities.TaxRegistrationNumberIndia {
      public RecId: number;
      public RegistrationNumber: string;
      public RegistrationType: number;
      public TaxType: number;
      public Type: number;
      public BusinessVerticals: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecId = 
                (odataObject.RecId != null) ? parseInt(odataObject.RecId, 10) : undefined;
              
            this.RegistrationNumber = 
                odataObject.RegistrationNumber;
              
            this.RegistrationType = 
                odataObject.RegistrationType;
              
            this.TaxType = 
                odataObject.TaxType;
              
            this.Type = 
                odataObject.Type;
              
            this.BusinessVerticals = 
                (odataObject.BusinessVerticals != null) ? parseInt(odataObject.BusinessVerticals, 10) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['TaxRegistrationNumberIndiaClass'] = TaxRegistrationNumberIndiaClass;
    
      /**
      * ChecklistTask entity class.
      */
      export class ChecklistTaskClass implements Entities.ChecklistTask {
      public Version: string;
      public InstructionsVersion: string;
      public AssignedToPersonnelNumbers: string[];
      public AssignedToExternalSubId: string;
      public Id: string;
      public Name: string;
      public Description: string;
      public DueDateTime: Date;
      public DeepLinkTypeValue: number;
      public DeepLink: string;
      public DeepLinkPosOperationName: string;
      public ContactPersonPersonnelNumber: string;
      public Instructions: string;
      public StatusValue: number;
      public CompletionDateTime: Date;
      public ResolvedByPersonnelNumber: string;
      public ChecklistId: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Version = 
                odataObject.Version;
              
            this.InstructionsVersion = 
                odataObject.InstructionsVersion;
              
        this.AssignedToPersonnelNumbers = undefined;
        if (odataObject.AssignedToPersonnelNumbers) {
        this.AssignedToPersonnelNumbers = [];
        for (var i = 0; i < odataObject.AssignedToPersonnelNumbers.length; i++) {
        if (odataObject.AssignedToPersonnelNumbers[i] != null) {
            this.AssignedToPersonnelNumbers[i] = 
                odataObject.AssignedToPersonnelNumbers[i];
                            } else {
        this.AssignedToPersonnelNumbers[i] = undefined;
        }
        }
        }
      
            this.AssignedToExternalSubId = 
                odataObject.AssignedToExternalSubId;
              
            this.Id = 
                odataObject.Id;
              
            this.Name = 
                odataObject.Name;
              
            this.Description = 
                odataObject.Description;
              
            this.DueDateTime = 
                (odataObject.DueDateTime instanceof Date) ? odataObject.DueDateTime
                : (odataObject.DueDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.DueDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.DeepLinkTypeValue = 
                odataObject.DeepLinkTypeValue;
              
            this.DeepLink = 
                odataObject.DeepLink;
              
            this.DeepLinkPosOperationName = 
                odataObject.DeepLinkPosOperationName;
              
            this.ContactPersonPersonnelNumber = 
                odataObject.ContactPersonPersonnelNumber;
              
            this.Instructions = 
                odataObject.Instructions;
              
            this.StatusValue = 
                odataObject.StatusValue;
              
            this.CompletionDateTime = 
                (odataObject.CompletionDateTime instanceof Date) ? odataObject.CompletionDateTime
                : (odataObject.CompletionDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.CompletionDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.ResolvedByPersonnelNumber = 
                odataObject.ResolvedByPersonnelNumber;
              
            this.ChecklistId = 
                odataObject.ChecklistId;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ChecklistTaskClass'] = ChecklistTaskClass;
    
      /**
      * MediaLocation entity class.
      */
      export class MediaLocationClass implements Entities.MediaLocation {
      public Uri: string;
      public AltText: string;
      public IsDefault: boolean;
      public IsSelfHosted: boolean;
      public Priority: number;
      public IsAdditionalMedia: boolean;
      public MediaTypeValue: number;
      public DisplayOrder: number;
      public MediaPurpose: string;
      public IsApplicableForChildEntities: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Uri = 
                odataObject.Uri;
              
            this.AltText = 
                odataObject.AltText;
              
            this.IsDefault = 
                odataObject.IsDefault;
              
            this.IsSelfHosted = 
                odataObject.IsSelfHosted;
              
            this.Priority = 
                odataObject.Priority;
              
            this.IsAdditionalMedia = 
                odataObject.IsAdditionalMedia;
              
            this.MediaTypeValue = 
                odataObject.MediaTypeValue;
              
            this.DisplayOrder = 
                (odataObject.DisplayOrder != null) ? parseFloat(odataObject.DisplayOrder) : undefined;
              
            this.MediaPurpose = 
                odataObject.MediaPurpose;
              
            this.IsApplicableForChildEntities = 
                odataObject.IsApplicableForChildEntities;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['MediaLocationClass'] = MediaLocationClass;
    
      /**
      * EmployeeActivity entity class.
      */
      export class EmployeeActivityClass implements Entities.EmployeeActivity {
      public StaffId: string;
      public StaffName: string;
      public Activity: string;
      public ActivityDateTimeOffset: Date;
      public StoreNumber: string;
      public EmployeeActivityTypeValue: number;
      public BreakCategory: string;
      public RecordId: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.StaffId = 
                odataObject.StaffId;
              
            this.StaffName = 
                odataObject.StaffName;
              
            this.Activity = 
                odataObject.Activity;
              
            this.ActivityDateTimeOffset = 
                (odataObject.ActivityDateTimeOffset instanceof Date) ? odataObject.ActivityDateTimeOffset
                : (odataObject.ActivityDateTimeOffset != null) ? jsonLightReadStringPropertyValue(odataObject.ActivityDateTimeOffset, "Edm.DateTimeOffset", false) : undefined;
              
            this.StoreNumber = 
                odataObject.StoreNumber;
              
            this.EmployeeActivityTypeValue = 
                odataObject.EmployeeActivityTypeValue;
              
            this.BreakCategory = 
                odataObject.BreakCategory;
              
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['EmployeeActivityClass'] = EmployeeActivityClass;
    
      /**
      * CustomerRefiner entity class.
      */
      export class CustomerRefinerClass
        extends SearchRefinerClass
       implements Entities.CustomerRefiner {
      public SourceValue: number;
      public Values: Entities.CustomerRefinerValue[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.SourceValue = 
                odataObject.SourceValue;
              
        this.Values = undefined;
        if (odataObject.Values) {
        this.Values = [];
        for (var i = 0; i < odataObject.Values.length; i++) {
        if (odataObject.Values[i] != null) {
        if (odataObject.Values[i]['@odata.type'] != null) {
          var className: string = odataObject.Values[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Values[i] = new entityDictionary[className](odataObject.Values[i])
          }
        } else {
            this.Values[i] = new CustomerRefinerValueClass(odataObject.Values[i]);
        }
                    } else {
        this.Values[i] = undefined;
        }
        }
        }
      
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.CustomerRefiner";
      
      }
      }
      entityDictionary['CustomerRefinerClass'] = CustomerRefinerClass;
    
      /**
      * TokenizedPaymentCard entity class.
      */
      export class TokenizedPaymentCardClass implements Entities.TokenizedPaymentCard {
      public IsSwipe: boolean;
      public TenderType: string;
      public CardTokenInfo: Entities.CardTokenInfo;
      public Phone: string;
      public Country: string;
      public House: string;
      public Address1: string;
      public Address2: string;
      public City: string;
      public State: string;
      public Zip: string;
      public NameOnCard: string;
      public CardTypeId: string;
      public ExpirationMonth: number;
      public ExpirationYear: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.IsSwipe = 
                odataObject.IsSwipe;
              
            this.TenderType = 
                odataObject.TenderType;
              
        if (odataObject.CardTokenInfo == null) {
            this.CardTokenInfo = undefined;
        } else if (odataObject.CardTokenInfo['@odata.type'] == null) {
            this.CardTokenInfo = new CardTokenInfoClass(odataObject.CardTokenInfo);
        } else {
            var className: string = odataObject.CardTokenInfo['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.CardTokenInfo = new entityDictionary[className](odataObject.CardTokenInfo)
            }
        }

      
            this.Phone = 
                odataObject.Phone;
              
            this.Country = 
                odataObject.Country;
              
            this.House = 
                odataObject.House;
              
            this.Address1 = 
                odataObject.Address1;
              
            this.Address2 = 
                odataObject.Address2;
              
            this.City = 
                odataObject.City;
              
            this.State = 
                odataObject.State;
              
            this.Zip = 
                odataObject.Zip;
              
            this.NameOnCard = 
                odataObject.NameOnCard;
              
            this.CardTypeId = 
                odataObject.CardTypeId;
              
            this.ExpirationMonth = 
                odataObject.ExpirationMonth;
              
            this.ExpirationYear = 
                odataObject.ExpirationYear;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['TokenizedPaymentCardClass'] = TokenizedPaymentCardClass;
    
      /**
      * ProductDimensionAvailabilitySearchCriteria entity class.
      */
      export class ProductDimensionAvailabilitySearchCriteriaClass implements Entities.ProductDimensionAvailabilitySearchCriteria {
      public RequestedDimensionTypeValue: number;
      public RequestedDimensionTypeValues: number[];
      public MatchingDimensionValues: Entities.ProductDimension[];
      public DefaultWarehouseOnly: boolean;
      public FilterByChannelFulfillmentGroup: boolean;
      public DeliveryModeTypeFilterValue: number;
      public CatalogId: number;
      public InventoryAccuracyValue: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RequestedDimensionTypeValue = 
                odataObject.RequestedDimensionTypeValue;
              
        this.RequestedDimensionTypeValues = undefined;
        if (odataObject.RequestedDimensionTypeValues) {
        this.RequestedDimensionTypeValues = [];
        for (var i = 0; i < odataObject.RequestedDimensionTypeValues.length; i++) {
        if (odataObject.RequestedDimensionTypeValues[i] != null) {
            this.RequestedDimensionTypeValues[i] = 
                odataObject.RequestedDimensionTypeValues[i];
                            } else {
        this.RequestedDimensionTypeValues[i] = undefined;
        }
        }
        }
      
        this.MatchingDimensionValues = undefined;
        if (odataObject.MatchingDimensionValues) {
        this.MatchingDimensionValues = [];
        for (var i = 0; i < odataObject.MatchingDimensionValues.length; i++) {
        if (odataObject.MatchingDimensionValues[i] != null) {
        if (odataObject.MatchingDimensionValues[i]['@odata.type'] != null) {
          var className: string = odataObject.MatchingDimensionValues[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.MatchingDimensionValues[i] = new entityDictionary[className](odataObject.MatchingDimensionValues[i])
          }
        } else {
            this.MatchingDimensionValues[i] = new ProductDimensionClass(odataObject.MatchingDimensionValues[i]);
        }
                    } else {
        this.MatchingDimensionValues[i] = undefined;
        }
        }
        }
      
            this.DefaultWarehouseOnly = 
                odataObject.DefaultWarehouseOnly;
              
            this.FilterByChannelFulfillmentGroup = 
                odataObject.FilterByChannelFulfillmentGroup;
              
            this.DeliveryModeTypeFilterValue = 
                odataObject.DeliveryModeTypeFilterValue;
              
            this.CatalogId = 
                (odataObject.CatalogId != null) ? parseInt(odataObject.CatalogId, 10) : undefined;
              
            this.InventoryAccuracyValue = 
                odataObject.InventoryAccuracyValue;
              
      }
      }
      entityDictionary['ProductDimensionAvailabilitySearchCriteriaClass'] = ProductDimensionAvailabilitySearchCriteriaClass;
    
      /**
      * LineShippingAddress entity class.
      */
      export class LineShippingAddressClass implements Entities.LineShippingAddress {
      public LineId: string;
      public ShippingAddress: Entities.Address;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.LineId = 
                odataObject.LineId;
              
        if (odataObject.ShippingAddress == null) {
            this.ShippingAddress = undefined;
        } else if (odataObject.ShippingAddress['@odata.type'] == null) {
            this.ShippingAddress = new AddressClass(odataObject.ShippingAddress);
        } else {
            var className: string = odataObject.ShippingAddress['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.ShippingAddress = new entityDictionary[className](odataObject.ShippingAddress)
            }
        }

      
      }
      }
      entityDictionary['LineShippingAddressClass'] = LineShippingAddressClass;
    
      /**
      * CartDeliveryPreferences entity class.
      */
      export class CartDeliveryPreferencesClass implements Entities.CartDeliveryPreferences {
      public HeaderDeliveryPreferenceTypeValues: number[];
      public CartLineDeliveryPreferences: Entities.CartLineDeliveryPreference[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.HeaderDeliveryPreferenceTypeValues = undefined;
        if (odataObject.HeaderDeliveryPreferenceTypeValues) {
        this.HeaderDeliveryPreferenceTypeValues = [];
        for (var i = 0; i < odataObject.HeaderDeliveryPreferenceTypeValues.length; i++) {
        if (odataObject.HeaderDeliveryPreferenceTypeValues[i] != null) {
            this.HeaderDeliveryPreferenceTypeValues[i] = 
                odataObject.HeaderDeliveryPreferenceTypeValues[i];
                            } else {
        this.HeaderDeliveryPreferenceTypeValues[i] = undefined;
        }
        }
        }
      
        this.CartLineDeliveryPreferences = undefined;
        if (odataObject.CartLineDeliveryPreferences) {
        this.CartLineDeliveryPreferences = [];
        for (var i = 0; i < odataObject.CartLineDeliveryPreferences.length; i++) {
        if (odataObject.CartLineDeliveryPreferences[i] != null) {
        if (odataObject.CartLineDeliveryPreferences[i]['@odata.type'] != null) {
          var className: string = odataObject.CartLineDeliveryPreferences[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.CartLineDeliveryPreferences[i] = new entityDictionary[className](odataObject.CartLineDeliveryPreferences[i])
          }
        } else {
            this.CartLineDeliveryPreferences[i] = new CartLineDeliveryPreferenceClass(odataObject.CartLineDeliveryPreferences[i]);
        }
                    } else {
        this.CartLineDeliveryPreferences[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CartDeliveryPreferencesClass'] = CartDeliveryPreferencesClass;
    
      /**
      * LineDeliverySpecification entity class.
      */
      export class LineDeliverySpecificationClass implements Entities.LineDeliverySpecification {
      public LineId: string;
      public DeliverySpecification: Entities.DeliverySpecification;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.LineId = 
                odataObject.LineId;
              
        if (odataObject.DeliverySpecification == null) {
            this.DeliverySpecification = undefined;
        } else if (odataObject.DeliverySpecification['@odata.type'] == null) {
            this.DeliverySpecification = new DeliverySpecificationClass(odataObject.DeliverySpecification);
        } else {
            var className: string = odataObject.DeliverySpecification['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.DeliverySpecification = new entityDictionary[className](odataObject.DeliverySpecification)
            }
        }

      
      }
      }
      entityDictionary['LineDeliverySpecificationClass'] = LineDeliverySpecificationClass;
    
      /**
      * DeliverySpecification entity class.
      */
      export class DeliverySpecificationClass implements Entities.DeliverySpecification {
      public DeliveryModeId: string;
      public DeliveryPreferenceTypeValue: number;
      public DeliveryAddress: Entities.Address;
      public RequestedDeliveryDate: Date;
      public PickUpStoreId: string;
      public PickupTimeslotStartDateTime: Date;
      public PickupTimeslotEndDateTime: Date;
      public ElectronicDeliveryEmailAddress: string;
      public ElectronicDeliveryEmailContent: string;
      public ShipFromStoreId: string;
      public ShipFromWarehouseId: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.DeliveryModeId = 
                odataObject.DeliveryModeId;
              
            this.DeliveryPreferenceTypeValue = 
                odataObject.DeliveryPreferenceTypeValue;
              
        if (odataObject.DeliveryAddress == null) {
            this.DeliveryAddress = undefined;
        } else if (odataObject.DeliveryAddress['@odata.type'] == null) {
            this.DeliveryAddress = new AddressClass(odataObject.DeliveryAddress);
        } else {
            var className: string = odataObject.DeliveryAddress['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.DeliveryAddress = new entityDictionary[className](odataObject.DeliveryAddress)
            }
        }

      
            this.RequestedDeliveryDate = 
                (odataObject.RequestedDeliveryDate instanceof Date) ? odataObject.RequestedDeliveryDate
                : (odataObject.RequestedDeliveryDate != null) ? jsonLightReadStringPropertyValue(odataObject.RequestedDeliveryDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.PickUpStoreId = 
                odataObject.PickUpStoreId;
              
            this.PickupTimeslotStartDateTime = 
                (odataObject.PickupTimeslotStartDateTime instanceof Date) ? odataObject.PickupTimeslotStartDateTime
                : (odataObject.PickupTimeslotStartDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.PickupTimeslotStartDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.PickupTimeslotEndDateTime = 
                (odataObject.PickupTimeslotEndDateTime instanceof Date) ? odataObject.PickupTimeslotEndDateTime
                : (odataObject.PickupTimeslotEndDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.PickupTimeslotEndDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.ElectronicDeliveryEmailAddress = 
                odataObject.ElectronicDeliveryEmailAddress;
              
            this.ElectronicDeliveryEmailContent = 
                odataObject.ElectronicDeliveryEmailContent;
              
            this.ShipFromStoreId = 
                odataObject.ShipFromStoreId;
              
            this.ShipFromWarehouseId = 
                odataObject.ShipFromWarehouseId;
              
      }
      }
      entityDictionary['DeliverySpecificationClass'] = DeliverySpecificationClass;
    
      /**
      * OrderSearchCriteria entity class.
      */
      export class OrderSearchCriteriaClass implements Entities.OrderSearchCriteria {
      public CustomerAccountNumber: string;
      public CustomerName: string;
      public EmailAddress: string;
      public LoyaltyCardNumber: string;
      public CustomerPhoneNumber: string;
      public SalesId: string;
      public ReceiptId: string;
      public OrderStatusValues: number[];
      public DocumentStatusValues: number[];
      public StartDateTime: Date;
      public EndDateTime: Date;
      public ChannelReferenceId: string;
      public StoreId: string;
      public OrderType: number;
      public CustomFilters: Entities.SearchFilter[];
      public FulfillmentTypeValues: number[];
      public DeliveryMode: string;
      public PickupStartDate: Date;
      public PickupEndDate: Date;
      public PickupTimeslotStartTime: number;
      public PickupTimeslotEndTime: number;
      public BusinessPartnerId: string;
      public ChannelIds: number[];
      public CustomerRequisition: string;
      public SearchOnlyRetailOrders: boolean;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.CustomerAccountNumber = 
                odataObject.CustomerAccountNumber;
              
            this.CustomerName = 
                odataObject.CustomerName;
              
            this.EmailAddress = 
                odataObject.EmailAddress;
              
            this.LoyaltyCardNumber = 
                odataObject.LoyaltyCardNumber;
              
            this.CustomerPhoneNumber = 
                odataObject.CustomerPhoneNumber;
              
            this.SalesId = 
                odataObject.SalesId;
              
            this.ReceiptId = 
                odataObject.ReceiptId;
              
        this.OrderStatusValues = undefined;
        if (odataObject.OrderStatusValues) {
        this.OrderStatusValues = [];
        for (var i = 0; i < odataObject.OrderStatusValues.length; i++) {
        if (odataObject.OrderStatusValues[i] != null) {
            this.OrderStatusValues[i] = 
                odataObject.OrderStatusValues[i];
                            } else {
        this.OrderStatusValues[i] = undefined;
        }
        }
        }
      
        this.DocumentStatusValues = undefined;
        if (odataObject.DocumentStatusValues) {
        this.DocumentStatusValues = [];
        for (var i = 0; i < odataObject.DocumentStatusValues.length; i++) {
        if (odataObject.DocumentStatusValues[i] != null) {
            this.DocumentStatusValues[i] = 
                odataObject.DocumentStatusValues[i];
                            } else {
        this.DocumentStatusValues[i] = undefined;
        }
        }
        }
      
            this.StartDateTime = 
                (odataObject.StartDateTime instanceof Date) ? odataObject.StartDateTime
                : (odataObject.StartDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.StartDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.EndDateTime = 
                (odataObject.EndDateTime instanceof Date) ? odataObject.EndDateTime
                : (odataObject.EndDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.EndDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.ChannelReferenceId = 
                odataObject.ChannelReferenceId;
              
            this.StoreId = 
                odataObject.StoreId;
              
            this.OrderType = 
                odataObject.OrderType;
              
        this.CustomFilters = undefined;
        if (odataObject.CustomFilters) {
        this.CustomFilters = [];
        for (var i = 0; i < odataObject.CustomFilters.length; i++) {
        if (odataObject.CustomFilters[i] != null) {
        if (odataObject.CustomFilters[i]['@odata.type'] != null) {
          var className: string = odataObject.CustomFilters[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.CustomFilters[i] = new entityDictionary[className](odataObject.CustomFilters[i])
          }
        } else {
            this.CustomFilters[i] = new SearchFilterClass(odataObject.CustomFilters[i]);
        }
                    } else {
        this.CustomFilters[i] = undefined;
        }
        }
        }
      
        this.FulfillmentTypeValues = undefined;
        if (odataObject.FulfillmentTypeValues) {
        this.FulfillmentTypeValues = [];
        for (var i = 0; i < odataObject.FulfillmentTypeValues.length; i++) {
        if (odataObject.FulfillmentTypeValues[i] != null) {
            this.FulfillmentTypeValues[i] = 
                odataObject.FulfillmentTypeValues[i];
                            } else {
        this.FulfillmentTypeValues[i] = undefined;
        }
        }
        }
      
            this.DeliveryMode = 
                odataObject.DeliveryMode;
              
            this.PickupStartDate = 
                (odataObject.PickupStartDate instanceof Date) ? odataObject.PickupStartDate
                : (odataObject.PickupStartDate != null) ? jsonLightReadStringPropertyValue(odataObject.PickupStartDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.PickupEndDate = 
                (odataObject.PickupEndDate instanceof Date) ? odataObject.PickupEndDate
                : (odataObject.PickupEndDate != null) ? jsonLightReadStringPropertyValue(odataObject.PickupEndDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.PickupTimeslotStartTime = 
                odataObject.PickupTimeslotStartTime;
              
            this.PickupTimeslotEndTime = 
                odataObject.PickupTimeslotEndTime;
              
            this.BusinessPartnerId = 
                odataObject.BusinessPartnerId;
              
        this.ChannelIds = undefined;
        if (odataObject.ChannelIds) {
        this.ChannelIds = [];
        for (var i = 0; i < odataObject.ChannelIds.length; i++) {
        if (odataObject.ChannelIds[i] != null) {
            this.ChannelIds[i] = 
                parseInt(odataObject.ChannelIds[i], 10);
                            } else {
        this.ChannelIds[i] = undefined;
        }
        }
        }
      
            this.CustomerRequisition = 
                odataObject.CustomerRequisition;
              
            this.SearchOnlyRetailOrders = 
                odataObject.SearchOnlyRetailOrders;
              
      }
      }
      entityDictionary['OrderSearchCriteriaClass'] = OrderSearchCriteriaClass;
    
      /**
      * ChecklistSearchCriteria entity class.
      */
      export class ChecklistSearchCriteriaClass implements Entities.ChecklistSearchCriteria {
      public ChannelId: number;
      public PersonnelNumber: string;
      public ChecklistIds: string[];
      public StatusValues: number[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ChannelId = 
                (odataObject.ChannelId != null) ? parseInt(odataObject.ChannelId, 10) : undefined;
              
            this.PersonnelNumber = 
                odataObject.PersonnelNumber;
              
        this.ChecklistIds = undefined;
        if (odataObject.ChecklistIds) {
        this.ChecklistIds = [];
        for (var i = 0; i < odataObject.ChecklistIds.length; i++) {
        if (odataObject.ChecklistIds[i] != null) {
            this.ChecklistIds[i] = 
                odataObject.ChecklistIds[i];
                            } else {
        this.ChecklistIds[i] = undefined;
        }
        }
        }
      
        this.StatusValues = undefined;
        if (odataObject.StatusValues) {
        this.StatusValues = [];
        for (var i = 0; i < odataObject.StatusValues.length; i++) {
        if (odataObject.StatusValues[i] != null) {
            this.StatusValues[i] = 
                odataObject.StatusValues[i];
                            } else {
        this.StatusValues[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ChecklistSearchCriteriaClass'] = ChecklistSearchCriteriaClass;
    
      /**
      * TaskSearchCriteria entity class.
      */
      export class TaskSearchCriteriaClass implements Entities.TaskSearchCriteria {
      public ChannelId: number;
      public PersonnelNumber: string;
      public TaskIds: string[];
      public StatusValues: number[];
      public FilterMyTasks: boolean;
      public FilterOverdueTasks: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ChannelId = 
                (odataObject.ChannelId != null) ? parseInt(odataObject.ChannelId, 10) : undefined;
              
            this.PersonnelNumber = 
                odataObject.PersonnelNumber;
              
        this.TaskIds = undefined;
        if (odataObject.TaskIds) {
        this.TaskIds = [];
        for (var i = 0; i < odataObject.TaskIds.length; i++) {
        if (odataObject.TaskIds[i] != null) {
            this.TaskIds[i] = 
                odataObject.TaskIds[i];
                            } else {
        this.TaskIds[i] = undefined;
        }
        }
        }
      
        this.StatusValues = undefined;
        if (odataObject.StatusValues) {
        this.StatusValues = [];
        for (var i = 0; i < odataObject.StatusValues.length; i++) {
        if (odataObject.StatusValues[i] != null) {
            this.StatusValues[i] = 
                odataObject.StatusValues[i];
                            } else {
        this.StatusValues[i] = undefined;
        }
        }
        }
      
            this.FilterMyTasks = 
                odataObject.FilterMyTasks;
              
            this.FilterOverdueTasks = 
                odataObject.FilterOverdueTasks;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['TaskSearchCriteriaClass'] = TaskSearchCriteriaClass;
    
      /**
      * ChecklistTaskTeamsIntegrationConfiguration entity class.
      */
      export class ChecklistTaskTeamsIntegrationConfigurationClass implements Entities.ChecklistTaskTeamsIntegrationConfiguration {
      public IsEnabled: boolean;
      public MicrosoftGraphResource: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.IsEnabled = 
                odataObject.IsEnabled;
              
            this.MicrosoftGraphResource = 
                odataObject.MicrosoftGraphResource;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ChecklistTaskTeamsIntegrationConfigurationClass'] = ChecklistTaskTeamsIntegrationConfigurationClass;
    
      /**
      * ReturnCartLine entity class.
      */
      export class ReturnCartLineClass implements Entities.ReturnCartLine {
      public Description: string;
      public ItemId: string;
      public ProductId: number;
      public Quantity: number;
      public ReturnLineNumber: number;
      public ReturnTransactionId: string;
      public SerialNumber: string;
      public IgnoreSerialNumberMismatch: boolean;
      public ReasonCodeLines: Entities.ReasonCodeLine[];
      public Price: number;
      public IsPriceKeyedIn: boolean;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Description = 
                odataObject.Description;
              
            this.ItemId = 
                odataObject.ItemId;
              
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
            this.Quantity = 
                (odataObject.Quantity != null) ? parseFloat(odataObject.Quantity) : undefined;
              
            this.ReturnLineNumber = 
                (odataObject.ReturnLineNumber != null) ? parseFloat(odataObject.ReturnLineNumber) : undefined;
              
            this.ReturnTransactionId = 
                odataObject.ReturnTransactionId;
              
            this.SerialNumber = 
                odataObject.SerialNumber;
              
            this.IgnoreSerialNumberMismatch = 
                odataObject.IgnoreSerialNumberMismatch;
              
        this.ReasonCodeLines = undefined;
        if (odataObject.ReasonCodeLines) {
        this.ReasonCodeLines = [];
        for (var i = 0; i < odataObject.ReasonCodeLines.length; i++) {
        if (odataObject.ReasonCodeLines[i] != null) {
        if (odataObject.ReasonCodeLines[i]['@odata.type'] != null) {
          var className: string = odataObject.ReasonCodeLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ReasonCodeLines[i] = new entityDictionary[className](odataObject.ReasonCodeLines[i])
          }
        } else {
            this.ReasonCodeLines[i] = new ReasonCodeLineClass(odataObject.ReasonCodeLines[i]);
        }
                    } else {
        this.ReasonCodeLines[i] = undefined;
        }
        }
        }
      
            this.Price = 
                (odataObject.Price != null) ? parseFloat(odataObject.Price) : undefined;
              
            this.IsPriceKeyedIn = 
                odataObject.IsPriceKeyedIn;
              
      }
      }
      entityDictionary['ReturnCartLineClass'] = ReturnCartLineClass;
    
      /**
      * TenderDiscountLine entity class.
      */
      export class TenderDiscountLineClass implements Entities.TenderDiscountLine {
      public DiscountAmount: number;
      public PaymentAmount: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.DiscountAmount = 
                (odataObject.DiscountAmount != null) ? parseFloat(odataObject.DiscountAmount) : undefined;
              
            this.PaymentAmount = 
                (odataObject.PaymentAmount != null) ? parseFloat(odataObject.PaymentAmount) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['TenderDiscountLineClass'] = TenderDiscountLineClass;
    
      /**
      * ReceiptRetrievalCriteria entity class.
      */
      export class ReceiptRetrievalCriteriaClass implements Entities.ReceiptRetrievalCriteria {
      public IsCopy: boolean;
      public IsRemoteTransaction: boolean;
      public IsPreview: boolean;
      public QueryBySalesId: boolean;
      public ReceiptTypeValue: number;
      public ShiftId: number;
      public ShiftTerminalId: string;
      public HardwareProfileId: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.IsCopy = 
                odataObject.IsCopy;
              
            this.IsRemoteTransaction = 
                odataObject.IsRemoteTransaction;
              
            this.IsPreview = 
                odataObject.IsPreview;
              
            this.QueryBySalesId = 
                odataObject.QueryBySalesId;
              
            this.ReceiptTypeValue = 
                odataObject.ReceiptTypeValue;
              
            this.ShiftId = 
                (odataObject.ShiftId != null) ? parseInt(odataObject.ShiftId, 10) : undefined;
              
            this.ShiftTerminalId = 
                odataObject.ShiftTerminalId;
              
            this.HardwareProfileId = 
                odataObject.HardwareProfileId;
              
      }
      }
      entityDictionary['ReceiptRetrievalCriteriaClass'] = ReceiptRetrievalCriteriaClass;
    
      /**
      * CartSearchCriteria entity class.
      */
      export class CartSearchCriteriaClass implements Entities.CartSearchCriteria {
      public CartId: string;
      public ExpectedCartVersion: number;
      public CustomerAccountNumber: string;
      public IncludeAnonymous: boolean;
      public SuspendedOnly: boolean;
      public CartTypeValue: number;
      public LastModifiedDateTimeFrom: Date;
      public LastModifiedDateTimeTo: Date;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.CartId = 
                odataObject.CartId;
              
            this.ExpectedCartVersion = 
                (odataObject.ExpectedCartVersion != null) ? parseInt(odataObject.ExpectedCartVersion, 10) : undefined;
              
            this.CustomerAccountNumber = 
                odataObject.CustomerAccountNumber;
              
            this.IncludeAnonymous = 
                odataObject.IncludeAnonymous;
              
            this.SuspendedOnly = 
                odataObject.SuspendedOnly;
              
            this.CartTypeValue = 
                odataObject.CartTypeValue;
              
            this.LastModifiedDateTimeFrom = 
                (odataObject.LastModifiedDateTimeFrom instanceof Date) ? odataObject.LastModifiedDateTimeFrom
                : (odataObject.LastModifiedDateTimeFrom != null) ? jsonLightReadStringPropertyValue(odataObject.LastModifiedDateTimeFrom, "Edm.DateTimeOffset", false) : undefined;
              
            this.LastModifiedDateTimeTo = 
                (odataObject.LastModifiedDateTimeTo instanceof Date) ? odataObject.LastModifiedDateTimeTo
                : (odataObject.LastModifiedDateTimeTo != null) ? jsonLightReadStringPropertyValue(odataObject.LastModifiedDateTimeTo, "Edm.DateTimeOffset", false) : undefined;
              
      }
      }
      entityDictionary['CartSearchCriteriaClass'] = CartSearchCriteriaClass;
    
      /**
      * RetrievePaymentResultSettings entity class.
      */
      export class RetrievePaymentResultSettingsClass implements Entities.RetrievePaymentResultSettings {
      public ReturnUrl: string;
      public PaymentConnectorId: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ReturnUrl = 
                odataObject.ReturnUrl;
              
            this.PaymentConnectorId = 
                odataObject.PaymentConnectorId;
              
      }
      }
      entityDictionary['RetrievePaymentResultSettingsClass'] = RetrievePaymentResultSettingsClass;
    
      /**
      * SearchReceiptCriteria entity class.
      */
      export class SearchReceiptCriteriaClass implements Entities.SearchReceiptCriteria {
      public TransactionId: string;
      public ReceiptTypeValue: number;
      public ReceiptTypesByFormatTypeCollection: Entities.ReceiptTypesByFormatType[];
      public IsCopy: boolean;
      public GiftReceiptLineNumbers: number[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.TransactionId = 
                odataObject.TransactionId;
              
            this.ReceiptTypeValue = 
                odataObject.ReceiptTypeValue;
              
        this.ReceiptTypesByFormatTypeCollection = undefined;
        if (odataObject.ReceiptTypesByFormatTypeCollection) {
        this.ReceiptTypesByFormatTypeCollection = [];
        for (var i = 0; i < odataObject.ReceiptTypesByFormatTypeCollection.length; i++) {
        if (odataObject.ReceiptTypesByFormatTypeCollection[i] != null) {
        if (odataObject.ReceiptTypesByFormatTypeCollection[i]['@odata.type'] != null) {
          var className: string = odataObject.ReceiptTypesByFormatTypeCollection[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ReceiptTypesByFormatTypeCollection[i] = new entityDictionary[className](odataObject.ReceiptTypesByFormatTypeCollection[i])
          }
        } else {
            this.ReceiptTypesByFormatTypeCollection[i] = new ReceiptTypesByFormatTypeClass(odataObject.ReceiptTypesByFormatTypeCollection[i]);
        }
                    } else {
        this.ReceiptTypesByFormatTypeCollection[i] = undefined;
        }
        }
        }
      
            this.IsCopy = 
                odataObject.IsCopy;
              
        this.GiftReceiptLineNumbers = undefined;
        if (odataObject.GiftReceiptLineNumbers) {
        this.GiftReceiptLineNumbers = [];
        for (var i = 0; i < odataObject.GiftReceiptLineNumbers.length; i++) {
        if (odataObject.GiftReceiptLineNumbers[i] != null) {
            this.GiftReceiptLineNumbers[i] = 
                parseFloat(odataObject.GiftReceiptLineNumbers[i]);
                            } else {
        this.GiftReceiptLineNumbers[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['SearchReceiptCriteriaClass'] = SearchReceiptCriteriaClass;
    
      /**
      * ElectronicAddress entity class.
      */
      export class ElectronicAddressClass implements Entities.ElectronicAddress {
      public AddressTypeValue: number;
      public ExtensibleEAddressType: Entities.ExtensibleElectronicAddressType;
      public Address: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.AddressTypeValue = 
                odataObject.AddressTypeValue;
              
        if (odataObject.ExtensibleEAddressType == null) {
            this.ExtensibleEAddressType = undefined;
        } else if (odataObject.ExtensibleEAddressType['@odata.type'] == null) {
            this.ExtensibleEAddressType = new ExtensibleElectronicAddressTypeClass(odataObject.ExtensibleEAddressType);
        } else {
            var className: string = odataObject.ExtensibleEAddressType['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.ExtensibleEAddressType = new entityDictionary[className](odataObject.ExtensibleEAddressType)
            }
        }

      
            this.Address = 
                odataObject.Address;
              
      }
      }
      entityDictionary['ElectronicAddressClass'] = ElectronicAddressClass;
    
      /**
      * ChannelReferenceLookupCriteria entity class.
      */
      export class ChannelReferenceLookupCriteriaClass implements Entities.ChannelReferenceLookupCriteria {
      public ChannelReferenceId: string;
      public SearchLocationValue: number;
      public ChannelReferenceAdditionalLookupCriteria: Entities.ChannelReferenceAdditionalLookupCriteria[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ChannelReferenceId = 
                odataObject.ChannelReferenceId;
              
            this.SearchLocationValue = 
                odataObject.SearchLocationValue;
              
        this.ChannelReferenceAdditionalLookupCriteria = undefined;
        if (odataObject.ChannelReferenceAdditionalLookupCriteria) {
        this.ChannelReferenceAdditionalLookupCriteria = [];
        for (var i = 0; i < odataObject.ChannelReferenceAdditionalLookupCriteria.length; i++) {
        if (odataObject.ChannelReferenceAdditionalLookupCriteria[i] != null) {
        if (odataObject.ChannelReferenceAdditionalLookupCriteria[i]['@odata.type'] != null) {
          var className: string = odataObject.ChannelReferenceAdditionalLookupCriteria[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ChannelReferenceAdditionalLookupCriteria[i] = new entityDictionary[className](odataObject.ChannelReferenceAdditionalLookupCriteria[i])
          }
        } else {
            this.ChannelReferenceAdditionalLookupCriteria[i] = new ChannelReferenceAdditionalLookupCriteriaClass(odataObject.ChannelReferenceAdditionalLookupCriteria[i]);
        }
                    } else {
        this.ChannelReferenceAdditionalLookupCriteria[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ChannelReferenceLookupCriteriaClass'] = ChannelReferenceLookupCriteriaClass;
    
      /**
      * FulfillmentLineParameter entity class.
      */
      export class FulfillmentLineParameterClass implements Entities.FulfillmentLineParameter {
      public SalesId: string;
      public SalesLineNumber: number;
      public Quantity: number;
      public SerialNumber: string;
      public InvoiceDate: Date;
      public PackingDate: Date;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.SalesId = 
                odataObject.SalesId;
              
            this.SalesLineNumber = 
                (odataObject.SalesLineNumber != null) ? parseFloat(odataObject.SalesLineNumber) : undefined;
              
            this.Quantity = 
                (odataObject.Quantity != null) ? parseFloat(odataObject.Quantity) : undefined;
              
            this.SerialNumber = 
                odataObject.SerialNumber;
              
            this.InvoiceDate = 
                (odataObject.InvoiceDate instanceof Date) ? odataObject.InvoiceDate
                : (odataObject.InvoiceDate != null) ? jsonLightReadStringPropertyValue(odataObject.InvoiceDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.PackingDate = 
                (odataObject.PackingDate instanceof Date) ? odataObject.PackingDate
                : (odataObject.PackingDate != null) ? jsonLightReadStringPropertyValue(odataObject.PackingDate, "Edm.DateTimeOffset", false) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['FulfillmentLineParameterClass'] = FulfillmentLineParameterClass;
    
      /**
      * ShipFulfillmentLine entity class.
      */
      export class ShipFulfillmentLineClass
        extends FulfillmentLineParameterClass
       implements Entities.ShipFulfillmentLine {
      public ProductId: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.ShipFulfillmentLine";
      
      }
      }
      entityDictionary['ShipFulfillmentLineClass'] = ShipFulfillmentLineClass;
    
      /**
      * RejectFulfillmentLine entity class.
      */
      export class RejectFulfillmentLineClass
        extends FulfillmentLineParameterClass
       implements Entities.RejectFulfillmentLine {
      public RejectedQuantity: number;
      public InfoCodeId: string;
      public SubInfoCodeId: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.RejectedQuantity = 
                (odataObject.RejectedQuantity != null) ? parseFloat(odataObject.RejectedQuantity) : undefined;
              
            this.InfoCodeId = 
                odataObject.InfoCodeId;
              
            this.SubInfoCodeId = 
                odataObject.SubInfoCodeId;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.RejectFulfillmentLine";
      
      }
      }
      entityDictionary['RejectFulfillmentLineClass'] = RejectFulfillmentLineClass;
    
      /**
      * ReadChangedProductsSession entity class.
      */
      export class ReadChangedProductsSessionClass implements Entities.ReadChangedProductsSession {
      public Id: string;
      public TotalNumberOfProducts: number;
      public NumberOfProductsRead: number;
      public NumberOfProductsReadInCurrentPage: number;
      public NextSynchronizationToken: string;
      public IsInitialSync: boolean;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Id = 
                odataObject.Id;
              
            this.TotalNumberOfProducts = 
                odataObject.TotalNumberOfProducts;
              
            this.NumberOfProductsRead = 
                odataObject.NumberOfProductsRead;
              
            this.NumberOfProductsReadInCurrentPage = 
                odataObject.NumberOfProductsReadInCurrentPage;
              
            this.NextSynchronizationToken = 
                odataObject.NextSynchronizationToken;
              
            this.IsInitialSync = 
                odataObject.IsInitialSync;
              
      }
      }
      entityDictionary['ReadChangedProductsSessionClass'] = ReadChangedProductsSessionClass;
    
      /**
      * DeletedListingsResult entity class.
      */
      export class DeletedListingsResultClass implements Entities.DeletedListingsResult {
      public HasMorePublishedListings: boolean;
      public DeletedListings: Entities.ListingIdentity[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.HasMorePublishedListings = 
                odataObject.HasMorePublishedListings;
              
        this.DeletedListings = undefined;
        if (odataObject.DeletedListings) {
        this.DeletedListings = [];
        for (var i = 0; i < odataObject.DeletedListings.length; i++) {
        if (odataObject.DeletedListings[i] != null) {
        if (odataObject.DeletedListings[i]['@odata.type'] != null) {
          var className: string = odataObject.DeletedListings[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.DeletedListings[i] = new entityDictionary[className](odataObject.DeletedListings[i])
          }
        } else {
            this.DeletedListings[i] = new ListingIdentityClass(odataObject.DeletedListings[i]);
        }
                    } else {
        this.DeletedListings[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['DeletedListingsResultClass'] = DeletedListingsResultClass;
    
      /**
      * ProductRating entity class.
      */
      export class ProductRatingClass implements Entities.ProductRating {
      public ProductId: number;
      public AverageRating: number;
      public TotalRatings: number;
      public RatingDistribution: Entities.ProductRatingCount[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
            this.AverageRating = 
                (odataObject.AverageRating != null) ? parseFloat(odataObject.AverageRating) : undefined;
              
            this.TotalRatings = 
                odataObject.TotalRatings;
              
        this.RatingDistribution = undefined;
        if (odataObject.RatingDistribution) {
        this.RatingDistribution = [];
        for (var i = 0; i < odataObject.RatingDistribution.length; i++) {
        if (odataObject.RatingDistribution[i] != null) {
        if (odataObject.RatingDistribution[i]['@odata.type'] != null) {
          var className: string = odataObject.RatingDistribution[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.RatingDistribution[i] = new entityDictionary[className](odataObject.RatingDistribution[i])
          }
        } else {
            this.RatingDistribution[i] = new ProductRatingCountClass(odataObject.RatingDistribution[i]);
        }
                    } else {
        this.RatingDistribution[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ProductRatingClass'] = ProductRatingClass;
    
      /**
      * ProductDeliveryOptions entity class.
      */
      export class ProductDeliveryOptionsClass implements Entities.ProductDeliveryOptions {
      public ProductId: number;
      public DeliveryOptions: Entities.DeliveryOption[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
        this.DeliveryOptions = undefined;
        if (odataObject.DeliveryOptions) {
        this.DeliveryOptions = [];
        for (var i = 0; i < odataObject.DeliveryOptions.length; i++) {
        if (odataObject.DeliveryOptions[i] != null) {
        if (odataObject.DeliveryOptions[i]['@odata.type'] != null) {
          var className: string = odataObject.DeliveryOptions[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.DeliveryOptions[i] = new entityDictionary[className](odataObject.DeliveryOptions[i])
          }
        } else {
            this.DeliveryOptions[i] = new DeliveryOptionClass(odataObject.DeliveryOptions[i]);
        }
                    } else {
        this.DeliveryOptions[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ProductDeliveryOptionsClass'] = ProductDeliveryOptionsClass;
    
      /**
      * ListingPublishStatus entity class.
      */
      export class ListingPublishStatusClass implements Entities.ListingPublishStatus {
      public PublishStatusValue: number;
      public ListingModifiedDateTime: Date;
      public ChannelListingId: string;
      public ChannelId: number;
      public CatalogId: number;
      public ProductId: number;
      public LanguageId: string;
      public AppliedActionValue: number;
      public StatusMessage: string;
      public Tag: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.PublishStatusValue = 
                odataObject.PublishStatusValue;
              
            this.ListingModifiedDateTime = 
                (odataObject.ListingModifiedDateTime instanceof Date) ? odataObject.ListingModifiedDateTime
                : (odataObject.ListingModifiedDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.ListingModifiedDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.ChannelListingId = 
                odataObject.ChannelListingId;
              
            this.ChannelId = 
                (odataObject.ChannelId != null) ? parseInt(odataObject.ChannelId, 10) : undefined;
              
            this.CatalogId = 
                (odataObject.CatalogId != null) ? parseInt(odataObject.CatalogId, 10) : undefined;
              
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
            this.LanguageId = 
                odataObject.LanguageId;
              
            this.AppliedActionValue = 
                odataObject.AppliedActionValue;
              
            this.StatusMessage = 
                odataObject.StatusMessage;
              
            this.Tag = 
                odataObject.Tag;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ListingPublishStatusClass'] = ListingPublishStatusClass;
    
      /**
      * MediaLocationSearchCriteria entity class.
      */
      export class MediaLocationSearchCriteriaClass implements Entities.MediaLocationSearchCriteria {
      public ProductId: number;
      public ChannelId: number;
      public CatalogId: number;
      public MediaUsageTypeValue: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
            this.ChannelId = 
                (odataObject.ChannelId != null) ? parseInt(odataObject.ChannelId, 10) : undefined;
              
            this.CatalogId = 
                (odataObject.CatalogId != null) ? parseInt(odataObject.CatalogId, 10) : undefined;
              
            this.MediaUsageTypeValue = 
                odataObject.MediaUsageTypeValue;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['MediaLocationSearchCriteriaClass'] = MediaLocationSearchCriteriaClass;
    
      /**
      * OrgUnitLocationSearchCriteria entity class.
      */
      export class OrgUnitLocationSearchCriteriaClass implements Entities.OrgUnitLocationSearchCriteria {
      public OrgUnitNumber: string;
      public OrgUnitName: string;
      public InventoryLocationIds: string[];
      public DeliveryModeTypeFilterValue: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.OrgUnitNumber = 
                odataObject.OrgUnitNumber;
              
            this.OrgUnitName = 
                odataObject.OrgUnitName;
              
        this.InventoryLocationIds = undefined;
        if (odataObject.InventoryLocationIds) {
        this.InventoryLocationIds = [];
        for (var i = 0; i < odataObject.InventoryLocationIds.length; i++) {
        if (odataObject.InventoryLocationIds[i] != null) {
            this.InventoryLocationIds[i] = 
                odataObject.InventoryLocationIds[i];
                            } else {
        this.InventoryLocationIds[i] = undefined;
        }
        }
        }
      
            this.DeliveryModeTypeFilterValue = 
                odataObject.DeliveryModeTypeFilterValue;
              
      }
      }
      entityDictionary['OrgUnitLocationSearchCriteriaClass'] = OrgUnitLocationSearchCriteriaClass;
    
      /**
      * StoreHours entity class.
      */
      export class StoreHoursClass implements Entities.StoreHours {
      public RegularStoreHours: Entities.RegularStoreHours;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        if (odataObject.RegularStoreHours == null) {
            this.RegularStoreHours = undefined;
        } else if (odataObject.RegularStoreHours['@odata.type'] == null) {
            this.RegularStoreHours = new RegularStoreHoursClass(odataObject.RegularStoreHours);
        } else {
            var className: string = odataObject.RegularStoreHours['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.RegularStoreHours = new entityDictionary[className](odataObject.RegularStoreHours)
            }
        }

      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['StoreHoursClass'] = StoreHoursClass;
    
      /**
      * RecallOrderSearchCriteria entity class.
      */
      export class RecallOrderSearchCriteriaClass implements Entities.RecallOrderSearchCriteria {
      public TransactionId: string;
      public Id: string;
      public SearchLocationValue: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.TransactionId = 
                odataObject.TransactionId;
              
            this.Id = 
                odataObject.Id;
              
            this.SearchLocationValue = 
                odataObject.SearchLocationValue;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['RecallOrderSearchCriteriaClass'] = RecallOrderSearchCriteriaClass;
    
      /**
      * CartPromotions entity class.
      */
      export class CartPromotionsClass implements Entities.CartPromotions {
      public HeaderPromotions: string[];
      public CartLinePromotions: Entities.CartLinePromotion[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.HeaderPromotions = undefined;
        if (odataObject.HeaderPromotions) {
        this.HeaderPromotions = [];
        for (var i = 0; i < odataObject.HeaderPromotions.length; i++) {
        if (odataObject.HeaderPromotions[i] != null) {
            this.HeaderPromotions[i] = 
                odataObject.HeaderPromotions[i];
                            } else {
        this.HeaderPromotions[i] = undefined;
        }
        }
        }
      
        this.CartLinePromotions = undefined;
        if (odataObject.CartLinePromotions) {
        this.CartLinePromotions = [];
        for (var i = 0; i < odataObject.CartLinePromotions.length; i++) {
        if (odataObject.CartLinePromotions[i] != null) {
        if (odataObject.CartLinePromotions[i]['@odata.type'] != null) {
          var className: string = odataObject.CartLinePromotions[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.CartLinePromotions[i] = new entityDictionary[className](odataObject.CartLinePromotions[i])
          }
        } else {
            this.CartLinePromotions[i] = new CartLinePromotionClass(odataObject.CartLinePromotions[i]);
        }
                    } else {
        this.CartLinePromotions[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CartPromotionsClass'] = CartPromotionsClass;
    
      /**
      * CartPromotionLine entity class.
      */
      export class CartPromotionLineClass implements Entities.CartPromotionLine {
      public LineId: string;
      public Promotion: Entities.Promotion;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.LineId = 
                odataObject.LineId;
              
        if (odataObject.Promotion == null) {
            this.Promotion = undefined;
        } else if (odataObject.Promotion['@odata.type'] == null) {
            this.Promotion = new PromotionClass(odataObject.Promotion);
        } else {
            var className: string = odataObject.Promotion['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.Promotion = new entityDictionary[className](odataObject.Promotion)
            }
        }

      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CartPromotionLineClass'] = CartPromotionLineClass;
    
      /**
      * ItemUnit entity class.
      */
      export class ItemUnitClass implements Entities.ItemUnit {
      public ItemId: string;
      public VariantInventoryDimensionId: string;
      public Product: number;
      public DistinctProductVariant: number;
      public UnitOfMeasure: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ItemId = 
                odataObject.ItemId;
              
            this.VariantInventoryDimensionId = 
                odataObject.VariantInventoryDimensionId;
              
            this.Product = 
                (odataObject.Product != null) ? parseInt(odataObject.Product, 10) : undefined;
              
            this.DistinctProductVariant = 
                (odataObject.DistinctProductVariant != null) ? parseInt(odataObject.DistinctProductVariant, 10) : undefined;
              
            this.UnitOfMeasure = 
                odataObject.UnitOfMeasure;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ItemUnitClass'] = ItemUnitClass;
    
      /**
      * ProductAvailableQuantity entity class.
      */
      export class ProductAvailableQuantityClass implements Entities.ProductAvailableQuantity {
      public ProductId: number;
      public AvailableQuantity: number;
      public UnitOfMeasure: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
            this.AvailableQuantity = 
                (odataObject.AvailableQuantity != null) ? parseFloat(odataObject.AvailableQuantity) : undefined;
              
            this.UnitOfMeasure = 
                odataObject.UnitOfMeasure;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ProductAvailableQuantityClass'] = ProductAvailableQuantityClass;
    
      /**
      * OrgUnitAvailabilitySearchCriteria entity class.
      */
      export class OrgUnitAvailabilitySearchCriteriaClass implements Entities.OrgUnitAvailabilitySearchCriteria {
      public OrgUnitName: string;
      public OrgUnitNumber: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.OrgUnitName = 
                odataObject.OrgUnitName;
              
            this.OrgUnitNumber = 
                odataObject.OrgUnitNumber;
              
      }
      }
      entityDictionary['OrgUnitAvailabilitySearchCriteriaClass'] = OrgUnitAvailabilitySearchCriteriaClass;
    
      /**
      * AffiliationSearchCriteria entity class.
      */
      export class AffiliationSearchCriteriaClass implements Entities.AffiliationSearchCriteria {
      public FilterTypeValue: number;
      public AffiliationTypeValue: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.FilterTypeValue = 
                odataObject.FilterTypeValue;
              
            this.AffiliationTypeValue = 
                odataObject.AffiliationTypeValue;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['AffiliationSearchCriteriaClass'] = AffiliationSearchCriteriaClass;
    
      /**
      * InvoiceSearchCriteria entity class.
      */
      export class InvoiceSearchCriteriaClass implements Entities.InvoiceSearchCriteria {
      public BusinessPartnerId: string;
      public CustomerId: string;
      public InvoiceIds: string[];
      public InvoiceTypeValues: number[];
      public InvoicePaidStatusValues: number[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.BusinessPartnerId = 
                odataObject.BusinessPartnerId;
              
            this.CustomerId = 
                odataObject.CustomerId;
              
        this.InvoiceIds = undefined;
        if (odataObject.InvoiceIds) {
        this.InvoiceIds = [];
        for (var i = 0; i < odataObject.InvoiceIds.length; i++) {
        if (odataObject.InvoiceIds[i] != null) {
            this.InvoiceIds[i] = 
                odataObject.InvoiceIds[i];
                            } else {
        this.InvoiceIds[i] = undefined;
        }
        }
        }
      
        this.InvoiceTypeValues = undefined;
        if (odataObject.InvoiceTypeValues) {
        this.InvoiceTypeValues = [];
        for (var i = 0; i < odataObject.InvoiceTypeValues.length; i++) {
        if (odataObject.InvoiceTypeValues[i] != null) {
            this.InvoiceTypeValues[i] = 
                odataObject.InvoiceTypeValues[i];
                            } else {
        this.InvoiceTypeValues[i] = undefined;
        }
        }
        }
      
        this.InvoicePaidStatusValues = undefined;
        if (odataObject.InvoicePaidStatusValues) {
        this.InvoicePaidStatusValues = [];
        for (var i = 0; i < odataObject.InvoicePaidStatusValues.length; i++) {
        if (odataObject.InvoicePaidStatusValues[i] != null) {
            this.InvoicePaidStatusValues[i] = 
                odataObject.InvoicePaidStatusValues[i];
                            } else {
        this.InvoicePaidStatusValues[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['InvoiceSearchCriteriaClass'] = InvoiceSearchCriteriaClass;
    
      /**
      * InvoiceDetailsSearchCriteria entity class.
      */
      export class InvoiceDetailsSearchCriteriaClass implements Entities.InvoiceDetailsSearchCriteria {
      public InvoiceId: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.InvoiceId = 
                odataObject.InvoiceId;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['InvoiceDetailsSearchCriteriaClass'] = InvoiceDetailsSearchCriteriaClass;
    
      /**
      * InvoicedSalesLinesSearchCriteria entity class.
      */
      export class InvoicedSalesLinesSearchCriteriaClass implements Entities.InvoicedSalesLinesSearchCriteria {
      public SalesIds: string[];
      public InvoiceIds: string[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.SalesIds = undefined;
        if (odataObject.SalesIds) {
        this.SalesIds = [];
        for (var i = 0; i < odataObject.SalesIds.length; i++) {
        if (odataObject.SalesIds[i] != null) {
            this.SalesIds[i] = 
                odataObject.SalesIds[i];
                            } else {
        this.SalesIds[i] = undefined;
        }
        }
        }
      
        this.InvoiceIds = undefined;
        if (odataObject.InvoiceIds) {
        this.InvoiceIds = [];
        for (var i = 0; i < odataObject.InvoiceIds.length; i++) {
        if (odataObject.InvoiceIds[i] != null) {
            this.InvoiceIds[i] = 
                odataObject.InvoiceIds[i];
                            } else {
        this.InvoiceIds[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['InvoicedSalesLinesSearchCriteriaClass'] = InvoicedSalesLinesSearchCriteriaClass;
    
      /**
      * PickAndPackSalesLineParameter entity class.
      */
      export class PickAndPackSalesLineParameterClass implements Entities.PickAndPackSalesLineParameter {
      public SalesLineNumber: number;
      public Quantity: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.SalesLineNumber = 
                (odataObject.SalesLineNumber != null) ? parseFloat(odataObject.SalesLineNumber) : undefined;
              
            this.Quantity = 
                (odataObject.Quantity != null) ? parseFloat(odataObject.Quantity) : undefined;
              
      }
      }
      entityDictionary['PickAndPackSalesLineParameterClass'] = PickAndPackSalesLineParameterClass;
    
      /**
      * ProductListSearchCriteria entity class.
      */
      export class ProductListSearchCriteriaClass implements Entities.ProductListSearchCriteria {
      public CustomerId: string;
      public ProductListId: string;
      public ProductListType: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.CustomerId = 
                odataObject.CustomerId;
              
            this.ProductListId = 
                odataObject.ProductListId;
              
            this.ProductListType = 
                odataObject.ProductListType;
              
      }
      }
      entityDictionary['ProductListSearchCriteriaClass'] = ProductListSearchCriteriaClass;
    
      /**
      * ProjectionDomain entity class.
      */
      export class ProjectionDomainClass implements Entities.ProjectionDomain {
      public ChannelId: number;
      public CatalogId: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ChannelId = 
                (odataObject.ChannelId != null) ? parseInt(odataObject.ChannelId, 10) : undefined;
              
            this.CatalogId = 
                (odataObject.CatalogId != null) ? parseInt(odataObject.CatalogId, 10) : undefined;
              
      }
      }
      entityDictionary['ProjectionDomainClass'] = ProjectionDomainClass;
    
      /**
      * AffiliationLoyaltyTier entity class.
      */
      export class AffiliationLoyaltyTierClass implements Entities.AffiliationLoyaltyTier {
      public AffiliationId: number;
      public LoyaltyTierId: number;
      public AffiliationTypeValue: number;
      public ReasonCodeLines: Entities.ReasonCodeLine[];
      public CustomerId: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.AffiliationId = 
                (odataObject.AffiliationId != null) ? parseInt(odataObject.AffiliationId, 10) : undefined;
              
            this.LoyaltyTierId = 
                (odataObject.LoyaltyTierId != null) ? parseInt(odataObject.LoyaltyTierId, 10) : undefined;
              
            this.AffiliationTypeValue = 
                odataObject.AffiliationTypeValue;
              
        this.ReasonCodeLines = undefined;
        if (odataObject.ReasonCodeLines) {
        this.ReasonCodeLines = [];
        for (var i = 0; i < odataObject.ReasonCodeLines.length; i++) {
        if (odataObject.ReasonCodeLines[i] != null) {
        if (odataObject.ReasonCodeLines[i]['@odata.type'] != null) {
          var className: string = odataObject.ReasonCodeLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ReasonCodeLines[i] = new entityDictionary[className](odataObject.ReasonCodeLines[i])
          }
        } else {
            this.ReasonCodeLines[i] = new ReasonCodeLineClass(odataObject.ReasonCodeLines[i]);
        }
                    } else {
        this.ReasonCodeLines[i] = undefined;
        }
        }
        }
      
            this.CustomerId = 
                odataObject.CustomerId;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['AffiliationLoyaltyTierClass'] = AffiliationLoyaltyTierClass;
    
      /**
      * PriceLookupContext entity class.
      */
      export class PriceLookupContextClass implements Entities.PriceLookupContext {
      public HeaderContext: Entities.PriceLookupHeaderContext;
      public LineContexts: Entities.PriceLookupLineContext[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        if (odataObject.HeaderContext == null) {
            this.HeaderContext = undefined;
        } else if (odataObject.HeaderContext['@odata.type'] == null) {
            this.HeaderContext = new PriceLookupHeaderContextClass(odataObject.HeaderContext);
        } else {
            var className: string = odataObject.HeaderContext['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.HeaderContext = new entityDictionary[className](odataObject.HeaderContext)
            }
        }

      
        this.LineContexts = undefined;
        if (odataObject.LineContexts) {
        this.LineContexts = [];
        for (var i = 0; i < odataObject.LineContexts.length; i++) {
        if (odataObject.LineContexts[i] != null) {
        if (odataObject.LineContexts[i]['@odata.type'] != null) {
          var className: string = odataObject.LineContexts[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.LineContexts[i] = new entityDictionary[className](odataObject.LineContexts[i])
          }
        } else {
            this.LineContexts[i] = new PriceLookupLineContextClass(odataObject.LineContexts[i]);
        }
                    } else {
        this.LineContexts[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['PriceLookupContextClass'] = PriceLookupContextClass;
    
      /**
      * ProductPromotion entity class.
      */
      export class ProductPromotionClass implements Entities.ProductPromotion {
      public ProductId: number;
      public ProductDiscounts: Entities.Promotion[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
        this.ProductDiscounts = undefined;
        if (odataObject.ProductDiscounts) {
        this.ProductDiscounts = [];
        for (var i = 0; i < odataObject.ProductDiscounts.length; i++) {
        if (odataObject.ProductDiscounts[i] != null) {
        if (odataObject.ProductDiscounts[i]['@odata.type'] != null) {
          var className: string = odataObject.ProductDiscounts[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ProductDiscounts[i] = new entityDictionary[className](odataObject.ProductDiscounts[i])
          }
        } else {
            this.ProductDiscounts[i] = new PromotionClass(odataObject.ProductDiscounts[i]);
        }
                    } else {
        this.ProductDiscounts[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ProductPromotionClass'] = ProductPromotionClass;
    
      /**
      * ClientBookCustomerSearchCriteria entity class.
      */
      export class ClientBookCustomerSearchCriteriaClass implements Entities.ClientBookCustomerSearchCriteria {
      public FilterByCurrentEmployee: boolean;
      public Refinement: Entities.ClientBookRefinerValue[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.FilterByCurrentEmployee = 
                odataObject.FilterByCurrentEmployee;
              
        this.Refinement = undefined;
        if (odataObject.Refinement) {
        this.Refinement = [];
        for (var i = 0; i < odataObject.Refinement.length; i++) {
        if (odataObject.Refinement[i] != null) {
        if (odataObject.Refinement[i]['@odata.type'] != null) {
          var className: string = odataObject.Refinement[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Refinement[i] = new entityDictionary[className](odataObject.Refinement[i])
          }
        } else {
            this.Refinement[i] = new ClientBookRefinerValueClass(odataObject.Refinement[i]);
        }
                    } else {
        this.Refinement[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ClientBookCustomerSearchCriteriaClass'] = ClientBookCustomerSearchCriteriaClass;
    
      /**
      * ClientBookCustomer entity class.
      */
      export class ClientBookCustomerClass implements Entities.ClientBookCustomer {
      public CustomerId: string;
      public Name: string;
      public Phone: string;
      public Email: string;
      public StaffId: string;
      public ImageUris: Entities.MediaLocation[];
      public Attributes: Entities.CustomerAttribute[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.CustomerId = 
                odataObject.CustomerId;
              
            this.Name = 
                odataObject.Name;
              
            this.Phone = 
                odataObject.Phone;
              
            this.Email = 
                odataObject.Email;
              
            this.StaffId = 
                odataObject.StaffId;
              
        this.ImageUris = undefined;
        if (odataObject.ImageUris) {
        this.ImageUris = [];
        for (var i = 0; i < odataObject.ImageUris.length; i++) {
        if (odataObject.ImageUris[i] != null) {
        if (odataObject.ImageUris[i]['@odata.type'] != null) {
          var className: string = odataObject.ImageUris[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ImageUris[i] = new entityDictionary[className](odataObject.ImageUris[i])
          }
        } else {
            this.ImageUris[i] = new MediaLocationClass(odataObject.ImageUris[i]);
        }
                    } else {
        this.ImageUris[i] = undefined;
        }
        }
        }
      
        this.Attributes = undefined;
        if (odataObject.Attributes) {
        this.Attributes = [];
        for (var i = 0; i < odataObject.Attributes.length; i++) {
        if (odataObject.Attributes[i] != null) {
        if (odataObject.Attributes[i]['@odata.type'] != null) {
          var className: string = odataObject.Attributes[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Attributes[i] = new entityDictionary[className](odataObject.Attributes[i])
          }
        } else {
            this.Attributes[i] = new CustomerAttributeClass(odataObject.Attributes[i]);
        }
                    } else {
        this.Attributes[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ClientBookCustomerClass'] = ClientBookCustomerClass;
    
      /**
      * ClientBookChanges entity class.
      */
      export class ClientBookChangesClass implements Entities.ClientBookChanges {
      public Changes: Entities.ClientBookChange[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.Changes = undefined;
        if (odataObject.Changes) {
        this.Changes = [];
        for (var i = 0; i < odataObject.Changes.length; i++) {
        if (odataObject.Changes[i] != null) {
        if (odataObject.Changes[i]['@odata.type'] != null) {
          var className: string = odataObject.Changes[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Changes[i] = new entityDictionary[className](odataObject.Changes[i])
          }
        } else {
            this.Changes[i] = new ClientBookChangeClass(odataObject.Changes[i]);
        }
                    } else {
        this.Changes[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ClientBookChangesClass'] = ClientBookChangesClass;
    
      /**
      * ChargeCode entity class.
      */
      export class ChargeCodeClass implements Entities.ChargeCode {
      public Code: string;
      public ModuleTypeValue: number;
      public Description: string;
      public ItemTaxGroupId: string;
      public IsRefundable: boolean;
      public IsShipping: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Code = 
                odataObject.Code;
              
            this.ModuleTypeValue = 
                odataObject.ModuleTypeValue;
              
            this.Description = 
                odataObject.Description;
              
            this.ItemTaxGroupId = 
                odataObject.ItemTaxGroupId;
              
            this.IsRefundable = 
                odataObject.IsRefundable;
              
            this.IsShipping = 
                odataObject.IsShipping;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ChargeCodeClass'] = ChargeCodeClass;
    
      /**
      * PaymentMerchantInformation entity class.
      */
      export class PaymentMerchantInformationClass implements Entities.PaymentMerchantInformation {
      public PaymentConnectorPropertiesXml: string;
      public MerchantPropertiesHashValue: string;
      public ServiceAccountId: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.PaymentConnectorPropertiesXml = 
                odataObject.PaymentConnectorPropertiesXml;
              
            this.MerchantPropertiesHashValue = 
                odataObject.MerchantPropertiesHashValue;
              
            this.ServiceAccountId = 
                odataObject.ServiceAccountId;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['PaymentMerchantInformationClass'] = PaymentMerchantInformationClass;
    
      /**
      * StoreSafe entity class.
      */
      export class StoreSafeClass implements Entities.StoreSafe {
      public SafeNumber: string;
      public Name: string;
      public StoreNumber: string;
      public SafeBalance: number;
      public LastUpdatedTime: Date;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.SafeNumber = 
                odataObject.SafeNumber;
              
            this.Name = 
                odataObject.Name;
              
            this.StoreNumber = 
                odataObject.StoreNumber;
              
            this.SafeBalance = 
                (odataObject.SafeBalance != null) ? parseFloat(odataObject.SafeBalance) : undefined;
              
            this.LastUpdatedTime = 
                (odataObject.LastUpdatedTime instanceof Date) ? odataObject.LastUpdatedTime
                : (odataObject.LastUpdatedTime != null) ? jsonLightReadStringPropertyValue(odataObject.LastUpdatedTime, "Edm.DateTimeOffset", false) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['StoreSafeClass'] = StoreSafeClass;
    
      /**
      * InventoryDocumentReceiptSearchCriteria entity class.
      */
      export class InventoryDocumentReceiptSearchCriteriaClass implements Entities.InventoryDocumentReceiptSearchCriteria {
      public SourceDocumentId: string;
      public SourceDocumentTypeValue: number;
      public WorkDocumentId: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.SourceDocumentId = 
                odataObject.SourceDocumentId;
              
            this.SourceDocumentTypeValue = 
                odataObject.SourceDocumentTypeValue;
              
            this.WorkDocumentId = 
                odataObject.WorkDocumentId;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['InventoryDocumentReceiptSearchCriteriaClass'] = InventoryDocumentReceiptSearchCriteriaClass;
    
      /**
      * InventoryDocumentSourceDocumentLineCommitRecordSearchCriteria entity class.
      */
      export class InventoryDocumentSourceDocumentLineCommitRecordSearchCriteriaClass implements Entities.InventoryDocumentSourceDocumentLineCommitRecordSearchCriteria {
      public SourceDocumentRecordId: number;
      public SourceDocumentTypeValue: number;
      public SourceDocumentLineRecordId: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.SourceDocumentRecordId = 
                (odataObject.SourceDocumentRecordId != null) ? parseInt(odataObject.SourceDocumentRecordId, 10) : undefined;
              
            this.SourceDocumentTypeValue = 
                odataObject.SourceDocumentTypeValue;
              
            this.SourceDocumentLineRecordId = 
                (odataObject.SourceDocumentLineRecordId != null) ? parseInt(odataObject.SourceDocumentLineRecordId, 10) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['InventoryDocumentSourceDocumentLineCommitRecordSearchCriteriaClass'] = InventoryDocumentSourceDocumentLineCommitRecordSearchCriteriaClass;
    
      /**
      * InventoryDocumentSourceDocumentLineCommitRecord entity class.
      */
      export class InventoryDocumentSourceDocumentLineCommitRecordClass implements Entities.InventoryDocumentSourceDocumentLineCommitRecord {
      public WorkDocumentId: string;
      public LineId: string;
      public SourceDocumentRecordId: number;
      public SourceDocumentTypeValue: number;
      public SourceDocumentLineRecordId: number;
      public StaffId: string;
      public UpdateNowQuantity: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.WorkDocumentId = 
                odataObject.WorkDocumentId;
              
            this.LineId = 
                odataObject.LineId;
              
            this.SourceDocumentRecordId = 
                (odataObject.SourceDocumentRecordId != null) ? parseInt(odataObject.SourceDocumentRecordId, 10) : undefined;
              
            this.SourceDocumentTypeValue = 
                odataObject.SourceDocumentTypeValue;
              
            this.SourceDocumentLineRecordId = 
                (odataObject.SourceDocumentLineRecordId != null) ? parseInt(odataObject.SourceDocumentLineRecordId, 10) : undefined;
              
            this.StaffId = 
                odataObject.StaffId;
              
            this.UpdateNowQuantity = 
                (odataObject.UpdateNowQuantity != null) ? parseFloat(odataObject.UpdateNowQuantity) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['InventoryDocumentSourceDocumentLineCommitRecordClass'] = InventoryDocumentSourceDocumentLineCommitRecordClass;
    
      /**
      * CreateHardwareStationTokenResult entity class.
      */
      export class CreateHardwareStationTokenResultClass implements Entities.CreateHardwareStationTokenResult {
      public HardwareStationToken: string;
      public PairingKey: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.HardwareStationToken = 
                odataObject.HardwareStationToken;
              
            this.PairingKey = 
                odataObject.PairingKey;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CreateHardwareStationTokenResultClass'] = CreateHardwareStationTokenResultClass;
    
      /**
      * ValidateHardwareStationTokenResult entity class.
      */
      export class ValidateHardwareStationTokenResultClass implements Entities.ValidateHardwareStationTokenResult {
      public PairingKey: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.PairingKey = 
                odataObject.PairingKey;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ValidateHardwareStationTokenResultClass'] = ValidateHardwareStationTokenResultClass;
    
      /**
      * MediaBlob entity class.
      */
      export class MediaBlobClass implements Entities.MediaBlob {
      public MediaType: string;
      public Content: string;
      public IsDefault: boolean;
      public AltText: string;
      public PictureId: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.MediaType = 
                odataObject.MediaType;
              
            this.Content = 
                odataObject.Content;
              
            this.IsDefault = 
                odataObject.IsDefault;
              
            this.AltText = 
                odataObject.AltText;
              
            this.PictureId = 
                odataObject.PictureId;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['MediaBlobClass'] = MediaBlobClass;
    
      /**
      * OnlineChannel entity class.
      */
      export class OnlineChannelClass implements Entities.OnlineChannel {
      public OnlineStoreCustomerTypeValue: number;
      public OnlineChannelName: string;
      public CurrencyCode: string;
      public OnlineStoreAzureB2CProfile: number;
      public CountryRegions: Entities.CountryRegion[];
      public EventNotificationProfileId: string;
      public RecordId: number;
      public OMOperatingUnitNumber: string;
      public OrgUnitTypeValue: number;
      public Name: string;
      public DefaultCustomerAccount: string;
      public CategoryHierarchyId: number;
      public InventoryLocationId: string;
      public ChannelProfile: Entities.ChannelProfile;
      public ChannelProperties: Entities.ChannelProperty[];
      public ChannelLanguages: Entities.ChannelLanguage[];
      public Contacts: Entities.OrgUnitContact[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.OnlineStoreCustomerTypeValue = 
                odataObject.OnlineStoreCustomerTypeValue;
              
            this.OnlineChannelName = 
                odataObject.OnlineChannelName;
              
            this.CurrencyCode = 
                odataObject.CurrencyCode;
              
            this.OnlineStoreAzureB2CProfile = 
                (odataObject.OnlineStoreAzureB2CProfile != null) ? parseInt(odataObject.OnlineStoreAzureB2CProfile, 10) : undefined;
              
        this.CountryRegions = undefined;
        if (odataObject.CountryRegions) {
        this.CountryRegions = [];
        for (var i = 0; i < odataObject.CountryRegions.length; i++) {
        if (odataObject.CountryRegions[i] != null) {
        if (odataObject.CountryRegions[i]['@odata.type'] != null) {
          var className: string = odataObject.CountryRegions[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.CountryRegions[i] = new entityDictionary[className](odataObject.CountryRegions[i])
          }
        } else {
            this.CountryRegions[i] = new CountryRegionClass(odataObject.CountryRegions[i]);
        }
                    } else {
        this.CountryRegions[i] = undefined;
        }
        }
        }
      
            this.EventNotificationProfileId = 
                odataObject.EventNotificationProfileId;
              
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.OMOperatingUnitNumber = 
                odataObject.OMOperatingUnitNumber;
              
            this.OrgUnitTypeValue = 
                odataObject.OrgUnitTypeValue;
              
            this.Name = 
                odataObject.Name;
              
            this.DefaultCustomerAccount = 
                odataObject.DefaultCustomerAccount;
              
            this.CategoryHierarchyId = 
                (odataObject.CategoryHierarchyId != null) ? parseInt(odataObject.CategoryHierarchyId, 10) : undefined;
              
            this.InventoryLocationId = 
                odataObject.InventoryLocationId;
              
        if (odataObject.ChannelProfile == null) {
            this.ChannelProfile = undefined;
        } else if (odataObject.ChannelProfile['@odata.type'] == null) {
            this.ChannelProfile = new ChannelProfileClass(odataObject.ChannelProfile);
        } else {
            var className: string = odataObject.ChannelProfile['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.ChannelProfile = new entityDictionary[className](odataObject.ChannelProfile)
            }
        }

      
        this.ChannelProperties = undefined;
        if (odataObject.ChannelProperties) {
        this.ChannelProperties = [];
        for (var i = 0; i < odataObject.ChannelProperties.length; i++) {
        if (odataObject.ChannelProperties[i] != null) {
        if (odataObject.ChannelProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ChannelProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ChannelProperties[i] = new entityDictionary[className](odataObject.ChannelProperties[i])
          }
        } else {
            this.ChannelProperties[i] = new ChannelPropertyClass(odataObject.ChannelProperties[i]);
        }
                    } else {
        this.ChannelProperties[i] = undefined;
        }
        }
        }
      
        this.ChannelLanguages = undefined;
        if (odataObject.ChannelLanguages) {
        this.ChannelLanguages = [];
        for (var i = 0; i < odataObject.ChannelLanguages.length; i++) {
        if (odataObject.ChannelLanguages[i] != null) {
        if (odataObject.ChannelLanguages[i]['@odata.type'] != null) {
          var className: string = odataObject.ChannelLanguages[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ChannelLanguages[i] = new entityDictionary[className](odataObject.ChannelLanguages[i])
          }
        } else {
            this.ChannelLanguages[i] = new ChannelLanguageClass(odataObject.ChannelLanguages[i]);
        }
                    } else {
        this.ChannelLanguages[i] = undefined;
        }
        }
        }
      
        this.Contacts = undefined;
        if (odataObject.Contacts) {
        this.Contacts = [];
        for (var i = 0; i < odataObject.Contacts.length; i++) {
        if (odataObject.Contacts[i] != null) {
        if (odataObject.Contacts[i]['@odata.type'] != null) {
          var className: string = odataObject.Contacts[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Contacts[i] = new entityDictionary[className](odataObject.Contacts[i])
          }
        } else {
            this.Contacts[i] = new OrgUnitContactClass(odataObject.Contacts[i]);
        }
                    } else {
        this.Contacts[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['OnlineChannelClass'] = OnlineChannelClass;
    
      /**
      * TerminalDataDownloadRequestInfo entity class.
      */
      export class TerminalDataDownloadRequestInfoClass implements Entities.TerminalDataDownloadRequestInfo {
      public TerminalId: string;
      public RequiresDataInitialization: boolean;
      public HasBeenInitializedBefore: boolean;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.TerminalId = 
                odataObject.TerminalId;
              
            this.RequiresDataInitialization = 
                odataObject.RequiresDataInitialization;
              
            this.HasBeenInitializedBefore = 
                odataObject.HasBeenInitializedBefore;
              
      }
      }
      entityDictionary['TerminalDataDownloadRequestInfoClass'] = TerminalDataDownloadRequestInfoClass;
    
      /**
      * DataDownloadParameters entity class.
      */
      export class DataDownloadParametersClass implements Entities.DataDownloadParameters {
      public DownloadInterval: number;
      public DownloadSessions: Entities.DownloadSession[];
      public FeatureControlList: Entities.RetailSharedConfigurationParameter[];
      public DataStore: Entities.CDXDataStore;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.DownloadInterval = 
                odataObject.DownloadInterval;
              
        this.DownloadSessions = undefined;
        if (odataObject.DownloadSessions) {
        this.DownloadSessions = [];
        for (var i = 0; i < odataObject.DownloadSessions.length; i++) {
        if (odataObject.DownloadSessions[i] != null) {
        if (odataObject.DownloadSessions[i]['@odata.type'] != null) {
          var className: string = odataObject.DownloadSessions[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.DownloadSessions[i] = new entityDictionary[className](odataObject.DownloadSessions[i])
          }
        } else {
            this.DownloadSessions[i] = new DownloadSessionClass(odataObject.DownloadSessions[i]);
        }
                    } else {
        this.DownloadSessions[i] = undefined;
        }
        }
        }
      
        this.FeatureControlList = undefined;
        if (odataObject.FeatureControlList) {
        this.FeatureControlList = [];
        for (var i = 0; i < odataObject.FeatureControlList.length; i++) {
        if (odataObject.FeatureControlList[i] != null) {
        if (odataObject.FeatureControlList[i]['@odata.type'] != null) {
          var className: string = odataObject.FeatureControlList[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.FeatureControlList[i] = new entityDictionary[className](odataObject.FeatureControlList[i])
          }
        } else {
            this.FeatureControlList[i] = new RetailSharedConfigurationParameterClass(odataObject.FeatureControlList[i]);
        }
                    } else {
        this.FeatureControlList[i] = undefined;
        }
        }
        }
      
        if (odataObject.DataStore == null) {
            this.DataStore = undefined;
        } else if (odataObject.DataStore['@odata.type'] == null) {
            this.DataStore = new CDXDataStoreClass(odataObject.DataStore);
        } else {
            var className: string = odataObject.DataStore['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.DataStore = new entityDictionary[className](odataObject.DataStore)
            }
        }

      
      }
      }
      entityDictionary['DataDownloadParametersClass'] = DataDownloadParametersClass;
    
      /**
      * TerminalDataUploadRequestInfo entity class.
      */
      export class TerminalDataUploadRequestInfoClass implements Entities.TerminalDataUploadRequestInfo {
      public TerminalId: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.TerminalId = 
                odataObject.TerminalId;
              
      }
      }
      entityDictionary['TerminalDataUploadRequestInfoClass'] = TerminalDataUploadRequestInfoClass;
    
      /**
      * DataUploadParameters entity class.
      */
      export class DataUploadParametersClass implements Entities.DataUploadParameters {
      public UploadInterval: number;
      public UploadJobPackageRequests: Entities.UploadJobPackageRequest[];
      public FeatureControlList: Entities.RetailSharedConfigurationParameter[];
      public DataStore: Entities.CDXDataStore;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.UploadInterval = 
                odataObject.UploadInterval;
              
        this.UploadJobPackageRequests = undefined;
        if (odataObject.UploadJobPackageRequests) {
        this.UploadJobPackageRequests = [];
        for (var i = 0; i < odataObject.UploadJobPackageRequests.length; i++) {
        if (odataObject.UploadJobPackageRequests[i] != null) {
        if (odataObject.UploadJobPackageRequests[i]['@odata.type'] != null) {
          var className: string = odataObject.UploadJobPackageRequests[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.UploadJobPackageRequests[i] = new entityDictionary[className](odataObject.UploadJobPackageRequests[i])
          }
        } else {
            this.UploadJobPackageRequests[i] = new UploadJobPackageRequestClass(odataObject.UploadJobPackageRequests[i]);
        }
                    } else {
        this.UploadJobPackageRequests[i] = undefined;
        }
        }
        }
      
        this.FeatureControlList = undefined;
        if (odataObject.FeatureControlList) {
        this.FeatureControlList = [];
        for (var i = 0; i < odataObject.FeatureControlList.length; i++) {
        if (odataObject.FeatureControlList[i] != null) {
        if (odataObject.FeatureControlList[i]['@odata.type'] != null) {
          var className: string = odataObject.FeatureControlList[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.FeatureControlList[i] = new entityDictionary[className](odataObject.FeatureControlList[i])
          }
        } else {
            this.FeatureControlList[i] = new RetailSharedConfigurationParameterClass(odataObject.FeatureControlList[i]);
        }
                    } else {
        this.FeatureControlList[i] = undefined;
        }
        }
        }
      
        if (odataObject.DataStore == null) {
            this.DataStore = undefined;
        } else if (odataObject.DataStore['@odata.type'] == null) {
            this.DataStore = new CDXDataStoreClass(odataObject.DataStore);
        } else {
            var className: string = odataObject.DataStore['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.DataStore = new entityDictionary[className](odataObject.DataStore)
            }
        }

      
      }
      }
      entityDictionary['DataUploadParametersClass'] = DataUploadParametersClass;
    
      /**
      * DownloadSession entity class.
      */
      export class DownloadSessionClass implements Entities.DownloadSession {
      public Id: number;
      public JobId: string;
      public JobDescription: string;
      public FileSize: number;
      public RowsAffected: number;
      public Checksum: string;
      public DateDownloaded: string;
      public DateRequested: string;
      public DataStoreName: string;
      public StatusValue: number;
      public Message: string;
      public DownloadLink: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Id = 
                (odataObject.Id != null) ? parseInt(odataObject.Id, 10) : undefined;
              
            this.JobId = 
                odataObject.JobId;
              
            this.JobDescription = 
                odataObject.JobDescription;
              
            this.FileSize = 
                (odataObject.FileSize != null) ? parseInt(odataObject.FileSize, 10) : undefined;
              
            this.RowsAffected = 
                odataObject.RowsAffected;
              
            this.Checksum = 
                odataObject.Checksum;
              
            this.DateDownloaded = 
                odataObject.DateDownloaded;
              
            this.DateRequested = 
                odataObject.DateRequested;
              
            this.DataStoreName = 
                odataObject.DataStoreName;
              
            this.StatusValue = 
                odataObject.StatusValue;
              
            this.Message = 
                odataObject.Message;
              
            this.DownloadLink = 
                odataObject.DownloadLink;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['DownloadSessionClass'] = DownloadSessionClass;
    
      /**
      * OfflineTerminalState entity class.
      */
      export class OfflineTerminalStateClass implements Entities.OfflineTerminalState {
      public TerminalId: string;
      public State: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.TerminalId = 
                odataObject.TerminalId;
              
            this.State = 
                odataObject.State;
              
      }
      }
      entityDictionary['OfflineTerminalStateClass'] = OfflineTerminalStateClass;
    
      /**
      * LoyaltyRewardPointActivity entity class.
      */
      export class LoyaltyRewardPointActivityClass implements Entities.LoyaltyRewardPointActivity {
      public ActivityDate: Date;
      public LoyaltyRewardPointTimelineEntryType: string;
      public ExtensibleLoyaltyRewardPointActivityEntryTypeValue: number;
      public LoyaltyPoints: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ActivityDate = 
                (odataObject.ActivityDate instanceof Date) ? odataObject.ActivityDate
                : (odataObject.ActivityDate != null) ? jsonLightReadStringPropertyValue(odataObject.ActivityDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.LoyaltyRewardPointTimelineEntryType = 
                odataObject.LoyaltyRewardPointTimelineEntryType;
              
            this.ExtensibleLoyaltyRewardPointActivityEntryTypeValue = 
                odataObject.ExtensibleLoyaltyRewardPointActivityEntryTypeValue;
              
            this.LoyaltyPoints = 
                (odataObject.LoyaltyPoints != null) ? parseFloat(odataObject.LoyaltyPoints) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['LoyaltyRewardPointActivityClass'] = LoyaltyRewardPointActivityClass;
    
      /**
      * CurrencyRequest entity class.
      */
      export class CurrencyRequestClass implements Entities.CurrencyRequest {
      public AmountToConvert: number;
      public CurrencyCode: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.AmountToConvert = 
                (odataObject.AmountToConvert != null) ? parseFloat(odataObject.AmountToConvert) : undefined;
              
            this.CurrencyCode = 
                odataObject.CurrencyCode;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CurrencyRequestClass'] = CurrencyRequestClass;
    
      /**
      * HardwareStationProfile entity class.
      */
      export class HardwareStationProfileClass implements Entities.HardwareStationProfile {
      public RecordId: number;
      public HardwareStationId: string;
      public HardwareStationTypeValue: number;
      public HardwareProfileId: string;
      public HardwareStationName: string;
      public HardwareStationDescription: string;
      public HardwareStationUrl: string;
      public HardwareStationHostName: string;
      public HardwareStationEftTerminalId: string;
      public HardwareConfigurations: Entities.HardwareConfigurations;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.HardwareStationId = 
                odataObject.HardwareStationId;
              
            this.HardwareStationTypeValue = 
                odataObject.HardwareStationTypeValue;
              
            this.HardwareProfileId = 
                odataObject.HardwareProfileId;
              
            this.HardwareStationName = 
                odataObject.HardwareStationName;
              
            this.HardwareStationDescription = 
                odataObject.HardwareStationDescription;
              
            this.HardwareStationUrl = 
                odataObject.HardwareStationUrl;
              
            this.HardwareStationHostName = 
                odataObject.HardwareStationHostName;
              
            this.HardwareStationEftTerminalId = 
                odataObject.HardwareStationEftTerminalId;
              
        if (odataObject.HardwareConfigurations == null) {
            this.HardwareConfigurations = undefined;
        } else if (odataObject.HardwareConfigurations['@odata.type'] == null) {
            this.HardwareConfigurations = new HardwareConfigurationsClass(odataObject.HardwareConfigurations);
        } else {
            var className: string = odataObject.HardwareConfigurations['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.HardwareConfigurations = new entityDictionary[className](odataObject.HardwareConfigurations)
            }
        }

      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['HardwareStationProfileClass'] = HardwareStationProfileClass;
    
      /**
      * LoyaltyCardSearchCriteria entity class.
      */
      export class LoyaltyCardSearchCriteriaClass implements Entities.LoyaltyCardSearchCriteria {
      public ChannelId: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ChannelId = 
                (odataObject.ChannelId != null) ? parseInt(odataObject.ChannelId, 10) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['LoyaltyCardSearchCriteriaClass'] = LoyaltyCardSearchCriteriaClass;
    
      /**
      * NameValuePair entity class.
      */
      export class NameValuePairClass implements Entities.NameValuePair {
      public Name: string;
      public Value: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Name = 
                odataObject.Name;
              
            this.Value = 
                odataObject.Value;
              
      }
      }
      entityDictionary['NameValuePairClass'] = NameValuePairClass;
    
      /**
      * LinkToExistingCustomerResult entity class.
      */
      export class LinkToExistingCustomerResultClass implements Entities.LinkToExistingCustomerResult {
      public EmailAddress: string;
      public ExternalIdentityProvider: string;
      public ExternalIdentityLinkRecordId: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.EmailAddress = 
                odataObject.EmailAddress;
              
            this.ExternalIdentityProvider = 
                odataObject.ExternalIdentityProvider;
              
            this.ExternalIdentityLinkRecordId = 
                (odataObject.ExternalIdentityLinkRecordId != null) ? parseInt(odataObject.ExternalIdentityLinkRecordId, 10) : undefined;
              
      }
      }
      entityDictionary['LinkToExistingCustomerResultClass'] = LinkToExistingCustomerResultClass;
    
      /**
      * OfflineSyncStatsLine entity class.
      */
      export class OfflineSyncStatsLineClass implements Entities.OfflineSyncStatsLine {
      public IsUploadJob: boolean;
      public JobDescription: string;
      public LastSyncDateTime: Date;
      public Status: string;
      public FileSize: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.IsUploadJob = 
                odataObject.IsUploadJob;
              
            this.JobDescription = 
                odataObject.JobDescription;
              
            this.LastSyncDateTime = 
                (odataObject.LastSyncDateTime instanceof Date) ? odataObject.LastSyncDateTime
                : (odataObject.LastSyncDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.LastSyncDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.Status = 
                odataObject.Status;
              
            this.FileSize = 
                odataObject.FileSize;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['OfflineSyncStatsLineClass'] = OfflineSyncStatsLineClass;
    
      /**
      * StorageAccessToken entity class.
      */
      export class StorageAccessTokenClass implements Entities.StorageAccessToken {
      public Url: string;
      public SasKey: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Url = 
                odataObject.Url;
              
            this.SasKey = 
                odataObject.SasKey;
              
      }
      }
      entityDictionary['StorageAccessTokenClass'] = StorageAccessTokenClass;
    
      /**
      * TaskGuidesSearchResult entity class.
      */
      export class TaskGuidesSearchResultClass implements Entities.TaskGuidesSearchResult {
      public Lines: Entities.Line[];
      public FrameworkPublisherName: string;
      public FrameworkName: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.Lines = undefined;
        if (odataObject.Lines) {
        this.Lines = [];
        for (var i = 0; i < odataObject.Lines.length; i++) {
        if (odataObject.Lines[i] != null) {
        if (odataObject.Lines[i]['@odata.type'] != null) {
          var className: string = odataObject.Lines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Lines[i] = new entityDictionary[className](odataObject.Lines[i])
          }
        } else {
            this.Lines[i] = new LineClass(odataObject.Lines[i]);
        }
                    } else {
        this.Lines[i] = undefined;
        }
        }
        }
      
            this.FrameworkPublisherName = 
                odataObject.FrameworkPublisherName;
              
            this.FrameworkName = 
                odataObject.FrameworkName;
              
      }
      }
      entityDictionary['TaskGuidesSearchResultClass'] = TaskGuidesSearchResultClass;
    
      /**
      * AuditEvent entity class.
      */
      export class AuditEventClass implements Entities.AuditEvent {
      public EventId: number;
      public EventIdString: string;
      public Channel: number;
      public Store: string;
      public Terminal: string;
      public UploadType: string;
      public EventType: string;
      public EventDateTime: Date;
      public DurationInMilliseconds: number;
      public Source: string;
      public EventMessage: string;
      public LogLevel: string;
      public Staff: string;
      public ShiftId: number;
      public ClosedShiftId: number;
      public ReferenceId: number;
      public RefChannel: number;
      public RefStore: string;
      public RefTerminal: string;
      public RefTransactionId: string;
      public AuditEventTypeValue: number;
      public FiscalTransactions: Entities.AuditEventFiscalTransaction[];
      public FiscalRegistrationLines: Entities.AuditEventFiscalRegistrationLine[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.EventId = 
                (odataObject.EventId != null) ? parseInt(odataObject.EventId, 10) : undefined;
              
            this.EventIdString = 
                odataObject.EventIdString;
              
            this.Channel = 
                (odataObject.Channel != null) ? parseInt(odataObject.Channel, 10) : undefined;
              
            this.Store = 
                odataObject.Store;
              
            this.Terminal = 
                odataObject.Terminal;
              
            this.UploadType = 
                odataObject.UploadType;
              
            this.EventType = 
                odataObject.EventType;
              
            this.EventDateTime = 
                (odataObject.EventDateTime instanceof Date) ? odataObject.EventDateTime
                : (odataObject.EventDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.EventDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.DurationInMilliseconds = 
                odataObject.DurationInMilliseconds;
              
            this.Source = 
                odataObject.Source;
              
            this.EventMessage = 
                odataObject.EventMessage;
              
            this.LogLevel = 
                odataObject.LogLevel;
              
            this.Staff = 
                odataObject.Staff;
              
            this.ShiftId = 
                (odataObject.ShiftId != null) ? parseInt(odataObject.ShiftId, 10) : undefined;
              
            this.ClosedShiftId = 
                (odataObject.ClosedShiftId != null) ? parseInt(odataObject.ClosedShiftId, 10) : undefined;
              
            this.ReferenceId = 
                (odataObject.ReferenceId != null) ? parseInt(odataObject.ReferenceId, 10) : undefined;
              
            this.RefChannel = 
                (odataObject.RefChannel != null) ? parseInt(odataObject.RefChannel, 10) : undefined;
              
            this.RefStore = 
                odataObject.RefStore;
              
            this.RefTerminal = 
                odataObject.RefTerminal;
              
            this.RefTransactionId = 
                odataObject.RefTransactionId;
              
            this.AuditEventTypeValue = 
                odataObject.AuditEventTypeValue;
              
        this.FiscalTransactions = undefined;
        if (odataObject.FiscalTransactions) {
        this.FiscalTransactions = [];
        for (var i = 0; i < odataObject.FiscalTransactions.length; i++) {
        if (odataObject.FiscalTransactions[i] != null) {
        if (odataObject.FiscalTransactions[i]['@odata.type'] != null) {
          var className: string = odataObject.FiscalTransactions[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.FiscalTransactions[i] = new entityDictionary[className](odataObject.FiscalTransactions[i])
          }
        } else {
            this.FiscalTransactions[i] = new AuditEventFiscalTransactionClass(odataObject.FiscalTransactions[i]);
        }
                    } else {
        this.FiscalTransactions[i] = undefined;
        }
        }
        }
      
        this.FiscalRegistrationLines = undefined;
        if (odataObject.FiscalRegistrationLines) {
        this.FiscalRegistrationLines = [];
        for (var i = 0; i < odataObject.FiscalRegistrationLines.length; i++) {
        if (odataObject.FiscalRegistrationLines[i] != null) {
        if (odataObject.FiscalRegistrationLines[i]['@odata.type'] != null) {
          var className: string = odataObject.FiscalRegistrationLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.FiscalRegistrationLines[i] = new entityDictionary[className](odataObject.FiscalRegistrationLines[i])
          }
        } else {
            this.FiscalRegistrationLines[i] = new AuditEventFiscalRegistrationLineClass(odataObject.FiscalRegistrationLines[i]);
        }
                    } else {
        this.FiscalRegistrationLines[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['AuditEventClass'] = AuditEventClass;
    
      /**
      * ItemAvailability entity class.
      */
      export class ItemAvailabilityClass implements Entities.ItemAvailability {
      public ItemId: string;
      public VariantInventoryDimensionId: string;
      public InventoryLocationId: string;
      public ProductId: number;
      public AvailableQuantity: number;
      public OrderedSum: number;
      public PhysicalReserved: number;
      public UnitOfMeasure: string;
      public InventoryUnitOfMeasure: string;
      public InventoryUnitOfMeasureDescription: string;
      public ProductDimensions: Entities.ProductDimension[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ItemId = 
                odataObject.ItemId;
              
            this.VariantInventoryDimensionId = 
                odataObject.VariantInventoryDimensionId;
              
            this.InventoryLocationId = 
                odataObject.InventoryLocationId;
              
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
            this.AvailableQuantity = 
                (odataObject.AvailableQuantity != null) ? parseFloat(odataObject.AvailableQuantity) : undefined;
              
            this.OrderedSum = 
                (odataObject.OrderedSum != null) ? parseFloat(odataObject.OrderedSum) : undefined;
              
            this.PhysicalReserved = 
                (odataObject.PhysicalReserved != null) ? parseFloat(odataObject.PhysicalReserved) : undefined;
              
            this.UnitOfMeasure = 
                odataObject.UnitOfMeasure;
              
            this.InventoryUnitOfMeasure = 
                odataObject.InventoryUnitOfMeasure;
              
            this.InventoryUnitOfMeasureDescription = 
                odataObject.InventoryUnitOfMeasureDescription;
              
        this.ProductDimensions = undefined;
        if (odataObject.ProductDimensions) {
        this.ProductDimensions = [];
        for (var i = 0; i < odataObject.ProductDimensions.length; i++) {
        if (odataObject.ProductDimensions[i] != null) {
        if (odataObject.ProductDimensions[i]['@odata.type'] != null) {
          var className: string = odataObject.ProductDimensions[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ProductDimensions[i] = new entityDictionary[className](odataObject.ProductDimensions[i])
          }
        } else {
            this.ProductDimensions[i] = new ProductDimensionClass(odataObject.ProductDimensions[i]);
        }
                    } else {
        this.ProductDimensions[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ItemAvailabilityClass'] = ItemAvailabilityClass;
    
      /**
      * InventoryAvailableToPromise entity class.
      */
      export class InventoryAvailableToPromiseClass
        extends ItemAvailabilityClass
       implements Entities.InventoryAvailableToPromise {
      public Date: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.Date = 
                odataObject.Date;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.InventoryAvailableToPromise";
      
      }
      }
      entityDictionary['InventoryAvailableToPromiseClass'] = InventoryAvailableToPromiseClass;
    
      /**
      * NotificationItem entity class.
      */
      export class NotificationItemClass implements Entities.NotificationItem {
      public Action: number;
      public ActionName: string;
      public NotificationDetails: Entities.NotificationDetail[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Action = 
                odataObject.Action;
              
            this.ActionName = 
                odataObject.ActionName;
              
        this.NotificationDetails = undefined;
        if (odataObject.NotificationDetails) {
        this.NotificationDetails = [];
        for (var i = 0; i < odataObject.NotificationDetails.length; i++) {
        if (odataObject.NotificationDetails[i] != null) {
        if (odataObject.NotificationDetails[i]['@odata.type'] != null) {
          var className: string = odataObject.NotificationDetails[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.NotificationDetails[i] = new entityDictionary[className](odataObject.NotificationDetails[i])
          }
        } else {
            this.NotificationDetails[i] = new NotificationDetailClass(odataObject.NotificationDetails[i]);
        }
                    } else {
        this.NotificationDetails[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['NotificationItemClass'] = NotificationItemClass;
    
      /**
      * FiscalIntegrationDocumentRetrievalCriteria entity class.
      */
      export class FiscalIntegrationDocumentRetrievalCriteriaClass implements Entities.FiscalIntegrationDocumentRetrievalCriteria {
      public TransactionId: string;
      public IsRemoteTransaction: boolean;
      public QueryBySalesId: boolean;
      public FiscalRegistrationEventTypeValue: number;
      public ShiftId: number;
      public ShiftTerminalId: string;
      public DocumentContext: Entities.FiscalIntegrationDocumentContext;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.TransactionId = 
                odataObject.TransactionId;
              
            this.IsRemoteTransaction = 
                odataObject.IsRemoteTransaction;
              
            this.QueryBySalesId = 
                odataObject.QueryBySalesId;
              
            this.FiscalRegistrationEventTypeValue = 
                odataObject.FiscalRegistrationEventTypeValue;
              
            this.ShiftId = 
                (odataObject.ShiftId != null) ? parseInt(odataObject.ShiftId, 10) : undefined;
              
            this.ShiftTerminalId = 
                odataObject.ShiftTerminalId;
              
        if (odataObject.DocumentContext == null) {
            this.DocumentContext = undefined;
        } else if (odataObject.DocumentContext['@odata.type'] == null) {
            this.DocumentContext = new FiscalIntegrationDocumentContextClass(odataObject.DocumentContext);
        } else {
            var className: string = odataObject.DocumentContext['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.DocumentContext = new entityDictionary[className](odataObject.DocumentContext)
            }
        }

      
      }
      }
      entityDictionary['FiscalIntegrationDocumentRetrievalCriteriaClass'] = FiscalIntegrationDocumentRetrievalCriteriaClass;
    
      /**
      * FiscalIntegrationDocument entity class.
      */
      export class FiscalIntegrationDocumentClass implements Entities.FiscalIntegrationDocument {
      public Document: string;
      public DocumentGenerationResultTypeValue: number;
      public FiscalConnectorTechnicalProfile: Entities.FiscalIntegrationTechnicalProfile;
      public DocumentAdjustment: Entities.FiscalIntegrationDocumentAdjustment;
      public SequentialSignatureDataContext: Entities.FiscalIntegrationSignatureDataContext;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Document = 
                odataObject.Document;
              
            this.DocumentGenerationResultTypeValue = 
                odataObject.DocumentGenerationResultTypeValue;
              
        if (odataObject.FiscalConnectorTechnicalProfile == null) {
            this.FiscalConnectorTechnicalProfile = undefined;
        } else if (odataObject.FiscalConnectorTechnicalProfile['@odata.type'] == null) {
            this.FiscalConnectorTechnicalProfile = new FiscalIntegrationTechnicalProfileClass(odataObject.FiscalConnectorTechnicalProfile);
        } else {
            var className: string = odataObject.FiscalConnectorTechnicalProfile['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.FiscalConnectorTechnicalProfile = new entityDictionary[className](odataObject.FiscalConnectorTechnicalProfile)
            }
        }

      
        if (odataObject.DocumentAdjustment == null) {
            this.DocumentAdjustment = undefined;
        } else if (odataObject.DocumentAdjustment['@odata.type'] == null) {
            this.DocumentAdjustment = new FiscalIntegrationDocumentAdjustmentClass(odataObject.DocumentAdjustment);
        } else {
            var className: string = odataObject.DocumentAdjustment['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.DocumentAdjustment = new entityDictionary[className](odataObject.DocumentAdjustment)
            }
        }

      
        if (odataObject.SequentialSignatureDataContext == null) {
            this.SequentialSignatureDataContext = undefined;
        } else if (odataObject.SequentialSignatureDataContext['@odata.type'] == null) {
            this.SequentialSignatureDataContext = new FiscalIntegrationSignatureDataContextClass(odataObject.SequentialSignatureDataContext);
        } else {
            var className: string = odataObject.SequentialSignatureDataContext['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.SequentialSignatureDataContext = new entityDictionary[className](odataObject.SequentialSignatureDataContext)
            }
        }

      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['FiscalIntegrationDocumentClass'] = FiscalIntegrationDocumentClass;
    
      /**
      * FiscalIntegrationRegistrationResult entity class.
      */
      export class FiscalIntegrationRegistrationResultClass implements Entities.FiscalIntegrationRegistrationResult {
      public TransactionID: string;
      public AuditEventId: string;
      public Response: string;
      public SubmittedDocument: string;
      public RegisterInfo: string;
      public RegistrationStatusValue: number;
      public FiscalRegistrationEventTypeValue: number;
      public DocumentAdjustment: Entities.FiscalIntegrationDocumentAdjustment;
      public ReasonCodeLines: Entities.ReasonCodeLine[];
      public SequentialSignatureData: Entities.FiscalIntegrationSequentialSignatureData;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.TransactionID = 
                odataObject.TransactionID;
              
            this.AuditEventId = 
                odataObject.AuditEventId;
              
            this.Response = 
                odataObject.Response;
              
            this.SubmittedDocument = 
                odataObject.SubmittedDocument;
              
            this.RegisterInfo = 
                odataObject.RegisterInfo;
              
            this.RegistrationStatusValue = 
                odataObject.RegistrationStatusValue;
              
            this.FiscalRegistrationEventTypeValue = 
                odataObject.FiscalRegistrationEventTypeValue;
              
        if (odataObject.DocumentAdjustment == null) {
            this.DocumentAdjustment = undefined;
        } else if (odataObject.DocumentAdjustment['@odata.type'] == null) {
            this.DocumentAdjustment = new FiscalIntegrationDocumentAdjustmentClass(odataObject.DocumentAdjustment);
        } else {
            var className: string = odataObject.DocumentAdjustment['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.DocumentAdjustment = new entityDictionary[className](odataObject.DocumentAdjustment)
            }
        }

      
        this.ReasonCodeLines = undefined;
        if (odataObject.ReasonCodeLines) {
        this.ReasonCodeLines = [];
        for (var i = 0; i < odataObject.ReasonCodeLines.length; i++) {
        if (odataObject.ReasonCodeLines[i] != null) {
        if (odataObject.ReasonCodeLines[i]['@odata.type'] != null) {
          var className: string = odataObject.ReasonCodeLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ReasonCodeLines[i] = new entityDictionary[className](odataObject.ReasonCodeLines[i])
          }
        } else {
            this.ReasonCodeLines[i] = new ReasonCodeLineClass(odataObject.ReasonCodeLines[i]);
        }
                    } else {
        this.ReasonCodeLines[i] = undefined;
        }
        }
        }
      
        if (odataObject.SequentialSignatureData == null) {
            this.SequentialSignatureData = undefined;
        } else if (odataObject.SequentialSignatureData['@odata.type'] == null) {
            this.SequentialSignatureData = new FiscalIntegrationSequentialSignatureDataClass(odataObject.SequentialSignatureData);
        } else {
            var className: string = odataObject.SequentialSignatureData['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.SequentialSignatureData = new entityDictionary[className](odataObject.SequentialSignatureData)
            }
        }

      
      }
      }
      entityDictionary['FiscalIntegrationRegistrationResultClass'] = FiscalIntegrationRegistrationResultClass;
    
      /**
      * FiscalIntegrationRegistrationProcess entity class.
      */
      export class FiscalIntegrationRegistrationProcessClass implements Entities.FiscalIntegrationRegistrationProcess {
      public RecordId: number;
      public RegistrationProcessId: string;
      public Description: string;
      public ModeValue: number;
      public RegistrationProcessLines: Entities.FiscalIntegrationRegistrationProcessLine[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.RegistrationProcessId = 
                odataObject.RegistrationProcessId;
              
            this.Description = 
                odataObject.Description;
              
            this.ModeValue = 
                odataObject.ModeValue;
              
        this.RegistrationProcessLines = undefined;
        if (odataObject.RegistrationProcessLines) {
        this.RegistrationProcessLines = [];
        for (var i = 0; i < odataObject.RegistrationProcessLines.length; i++) {
        if (odataObject.RegistrationProcessLines[i] != null) {
        if (odataObject.RegistrationProcessLines[i]['@odata.type'] != null) {
          var className: string = odataObject.RegistrationProcessLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.RegistrationProcessLines[i] = new entityDictionary[className](odataObject.RegistrationProcessLines[i])
          }
        } else {
            this.RegistrationProcessLines[i] = new FiscalIntegrationRegistrationProcessLineClass(odataObject.RegistrationProcessLines[i]);
        }
                    } else {
        this.RegistrationProcessLines[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['FiscalIntegrationRegistrationProcessClass'] = FiscalIntegrationRegistrationProcessClass;
    
      /**
      * FiscalIntegrationSupportedEvents entity class.
      */
      export class FiscalIntegrationSupportedEventsClass implements Entities.FiscalIntegrationSupportedEvents {
      public FunctionalityProfileGroupId: string;
      public SupportedFiscalEventTypes: number[];
      public SupportedNonFiscalEventTypes: number[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.FunctionalityProfileGroupId = 
                odataObject.FunctionalityProfileGroupId;
              
        this.SupportedFiscalEventTypes = undefined;
        if (odataObject.SupportedFiscalEventTypes) {
        this.SupportedFiscalEventTypes = [];
        for (var i = 0; i < odataObject.SupportedFiscalEventTypes.length; i++) {
        if (odataObject.SupportedFiscalEventTypes[i] != null) {
            this.SupportedFiscalEventTypes[i] = 
                odataObject.SupportedFiscalEventTypes[i];
                            } else {
        this.SupportedFiscalEventTypes[i] = undefined;
        }
        }
        }
      
        this.SupportedNonFiscalEventTypes = undefined;
        if (odataObject.SupportedNonFiscalEventTypes) {
        this.SupportedNonFiscalEventTypes = [];
        for (var i = 0; i < odataObject.SupportedNonFiscalEventTypes.length; i++) {
        if (odataObject.SupportedNonFiscalEventTypes[i] != null) {
            this.SupportedNonFiscalEventTypes[i] = 
                odataObject.SupportedNonFiscalEventTypes[i];
                            } else {
        this.SupportedNonFiscalEventTypes[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['FiscalIntegrationSupportedEventsClass'] = FiscalIntegrationSupportedEventsClass;
    
      /**
      * FiscalIntegrationTechnicalProfile entity class.
      */
      export class FiscalIntegrationTechnicalProfileClass implements Entities.FiscalIntegrationTechnicalProfile {
      public ProfileId: string;
      public Description: string;
      public ConnectorName: string;
      public ConnectorTypeValue: number;
      public ConnectorLocationValue: number;
      public TechnicalProfile: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ProfileId = 
                odataObject.ProfileId;
              
            this.Description = 
                odataObject.Description;
              
            this.ConnectorName = 
                odataObject.ConnectorName;
              
            this.ConnectorTypeValue = 
                odataObject.ConnectorTypeValue;
              
            this.ConnectorLocationValue = 
                odataObject.ConnectorLocationValue;
              
            this.TechnicalProfile = 
                odataObject.TechnicalProfile;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['FiscalIntegrationTechnicalProfileClass'] = FiscalIntegrationTechnicalProfileClass;
    
      /**
      * FiscalIntegrationFiscalRegistrationProcessSummary entity class.
      */
      export class FiscalIntegrationFiscalRegistrationProcessSummaryClass implements Entities.FiscalIntegrationFiscalRegistrationProcessSummary {
      public RegistrationProcessId: string;
      public FiscalRegistrationProcessSummaryLines: Entities.FiscalIntegrationFiscalRegistrationProcessSummaryLine[];
      public ErrorCode: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RegistrationProcessId = 
                odataObject.RegistrationProcessId;
              
        this.FiscalRegistrationProcessSummaryLines = undefined;
        if (odataObject.FiscalRegistrationProcessSummaryLines) {
        this.FiscalRegistrationProcessSummaryLines = [];
        for (var i = 0; i < odataObject.FiscalRegistrationProcessSummaryLines.length; i++) {
        if (odataObject.FiscalRegistrationProcessSummaryLines[i] != null) {
        if (odataObject.FiscalRegistrationProcessSummaryLines[i]['@odata.type'] != null) {
          var className: string = odataObject.FiscalRegistrationProcessSummaryLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.FiscalRegistrationProcessSummaryLines[i] = new entityDictionary[className](odataObject.FiscalRegistrationProcessSummaryLines[i])
          }
        } else {
            this.FiscalRegistrationProcessSummaryLines[i] = new FiscalIntegrationFiscalRegistrationProcessSummaryLineClass(odataObject.FiscalRegistrationProcessSummaryLines[i]);
        }
                    } else {
        this.FiscalRegistrationProcessSummaryLines[i] = undefined;
        }
        }
        }
      
            this.ErrorCode = 
                odataObject.ErrorCode;
              
      }
      }
      entityDictionary['FiscalIntegrationFiscalRegistrationProcessSummaryClass'] = FiscalIntegrationFiscalRegistrationProcessSummaryClass;
    
      /**
      * FiscalIntegrationTechnicalProfileSearchCriteria entity class.
      */
      export class FiscalIntegrationTechnicalProfileSearchCriteriaClass implements Entities.FiscalIntegrationTechnicalProfileSearchCriteria {
      public ExcludeOptionalProcessLines: boolean;
      public FunctionalityProfileGroupIds: string[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ExcludeOptionalProcessLines = 
                odataObject.ExcludeOptionalProcessLines;
              
        this.FunctionalityProfileGroupIds = undefined;
        if (odataObject.FunctionalityProfileGroupIds) {
        this.FunctionalityProfileGroupIds = [];
        for (var i = 0; i < odataObject.FunctionalityProfileGroupIds.length; i++) {
        if (odataObject.FunctionalityProfileGroupIds[i] != null) {
            this.FunctionalityProfileGroupIds[i] = 
                odataObject.FunctionalityProfileGroupIds[i];
                            } else {
        this.FunctionalityProfileGroupIds[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['FiscalIntegrationTechnicalProfileSearchCriteriaClass'] = FiscalIntegrationTechnicalProfileSearchCriteriaClass;
    
      /**
      * FiscalIntegrationServiceInfo entity class.
      */
      export class FiscalIntegrationServiceInfoClass implements Entities.FiscalIntegrationServiceInfo {
      public ServiceName: string;
      public ServiceProperties: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ServiceName = 
                odataObject.ServiceName;
              
            this.ServiceProperties = 
                odataObject.ServiceProperties;
              
      }
      }
      entityDictionary['FiscalIntegrationServiceInfoClass'] = FiscalIntegrationServiceInfoClass;
    
      /**
      * FiscalIntegrationServiceStatus entity class.
      */
      export class FiscalIntegrationServiceStatusClass implements Entities.FiscalIntegrationServiceStatus {
      public IsReady: boolean;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.IsReady = 
                odataObject.IsReady;
              
      }
      }
      entityDictionary['FiscalIntegrationServiceStatusClass'] = FiscalIntegrationServiceStatusClass;
    
      /**
      * FiscalIntegrationServiceCommunicationResult entity class.
      */
      export class FiscalIntegrationServiceCommunicationResultClass implements Entities.FiscalIntegrationServiceCommunicationResult {
      public Response: string;
      public CommunicationResultTypeValue: number;
      public FailureDetails: Entities.FiscalIntegrationServiceFailureDetails;
      public FiscalIntegrationServiceInfo: string;
      public UserNotificationMessage: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Response = 
                odataObject.Response;
              
            this.CommunicationResultTypeValue = 
                odataObject.CommunicationResultTypeValue;
              
        if (odataObject.FailureDetails == null) {
            this.FailureDetails = undefined;
        } else if (odataObject.FailureDetails['@odata.type'] == null) {
            this.FailureDetails = new FiscalIntegrationServiceFailureDetailsClass(odataObject.FailureDetails);
        } else {
            var className: string = odataObject.FailureDetails['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.FailureDetails = new entityDictionary[className](odataObject.FailureDetails)
            }
        }

      
            this.FiscalIntegrationServiceInfo = 
                odataObject.FiscalIntegrationServiceInfo;
              
            this.UserNotificationMessage = 
                odataObject.UserNotificationMessage;
              
      }
      }
      entityDictionary['FiscalIntegrationServiceCommunicationResultClass'] = FiscalIntegrationServiceCommunicationResultClass;
    
      /**
      * FiscalIntegrationSequentialSignatureData entity class.
      */
      export class FiscalIntegrationSequentialSignatureDataClass implements Entities.FiscalIntegrationSequentialSignatureData {
      public LastRegisterResponse: string;
      public SequenceKey: string;
      public IsOffline: boolean;
      public SequentialNumber: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.LastRegisterResponse = 
                odataObject.LastRegisterResponse;
              
            this.SequenceKey = 
                odataObject.SequenceKey;
              
            this.IsOffline = 
                odataObject.IsOffline;
              
            this.SequentialNumber = 
                (odataObject.SequentialNumber != null) ? parseInt(odataObject.SequentialNumber, 10) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['FiscalIntegrationSequentialSignatureDataClass'] = FiscalIntegrationSequentialSignatureDataClass;
    
      /**
      * DiscountsSearchCriteria entity class.
      */
      export class DiscountsSearchCriteriaClass implements Entities.DiscountsSearchCriteria {
      public ChannelId: number;
      public Keyword: string;
      public IsDiscountCodeRequired: boolean;
      public StartDate: Date;
      public EndDate: Date;
      public IncludeDisabledDiscount: boolean;
      public ExcludeTenderDiscount: boolean;
      public DiscountOfferTypeValue: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ChannelId = 
                (odataObject.ChannelId != null) ? parseInt(odataObject.ChannelId, 10) : undefined;
              
            this.Keyword = 
                odataObject.Keyword;
              
            this.IsDiscountCodeRequired = 
                odataObject.IsDiscountCodeRequired;
              
            this.StartDate = 
                (odataObject.StartDate instanceof Date) ? odataObject.StartDate
                : (odataObject.StartDate != null) ? jsonLightReadStringPropertyValue(odataObject.StartDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.EndDate = 
                (odataObject.EndDate instanceof Date) ? odataObject.EndDate
                : (odataObject.EndDate != null) ? jsonLightReadStringPropertyValue(odataObject.EndDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.IncludeDisabledDiscount = 
                odataObject.IncludeDisabledDiscount;
              
            this.ExcludeTenderDiscount = 
                odataObject.ExcludeTenderDiscount;
              
            this.DiscountOfferTypeValue = 
                odataObject.DiscountOfferTypeValue;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['DiscountsSearchCriteriaClass'] = DiscountsSearchCriteriaClass;
    
      /**
      * Promotion entity class.
      */
      export class PromotionClass implements Entities.Promotion {
      public OfferId: string;
      public OfferName: string;
      public PeriodicDiscountTypeValue: number;
      public IsDiscountCodeRequired: boolean;
      public ValidationPeriodId: string;
      public AdditionalRestrictions: string;
      public Description: string;
      public ValidFromDate: Date;
      public ValidToDate: Date;
      public ValidationPeriod: Entities.ValidationPeriod;
      public CouponCodes: string[];
      public DateValidationTypeValue: number;
      public AreCouponsActiveOnSameDate: boolean;
      public AreCouponsExpiredOnSameDate: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.OfferId = 
                odataObject.OfferId;
              
            this.OfferName = 
                odataObject.OfferName;
              
            this.PeriodicDiscountTypeValue = 
                odataObject.PeriodicDiscountTypeValue;
              
            this.IsDiscountCodeRequired = 
                odataObject.IsDiscountCodeRequired;
              
            this.ValidationPeriodId = 
                odataObject.ValidationPeriodId;
              
            this.AdditionalRestrictions = 
                odataObject.AdditionalRestrictions;
              
            this.Description = 
                odataObject.Description;
              
            this.ValidFromDate = 
                (odataObject.ValidFromDate instanceof Date) ? odataObject.ValidFromDate
                : (odataObject.ValidFromDate != null) ? jsonLightReadStringPropertyValue(odataObject.ValidFromDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.ValidToDate = 
                (odataObject.ValidToDate instanceof Date) ? odataObject.ValidToDate
                : (odataObject.ValidToDate != null) ? jsonLightReadStringPropertyValue(odataObject.ValidToDate, "Edm.DateTimeOffset", false) : undefined;
              
        if (odataObject.ValidationPeriod == null) {
            this.ValidationPeriod = undefined;
        } else if (odataObject.ValidationPeriod['@odata.type'] == null) {
            this.ValidationPeriod = new ValidationPeriodClass(odataObject.ValidationPeriod);
        } else {
            var className: string = odataObject.ValidationPeriod['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.ValidationPeriod = new entityDictionary[className](odataObject.ValidationPeriod)
            }
        }

      
        this.CouponCodes = undefined;
        if (odataObject.CouponCodes) {
        this.CouponCodes = [];
        for (var i = 0; i < odataObject.CouponCodes.length; i++) {
        if (odataObject.CouponCodes[i] != null) {
            this.CouponCodes[i] = 
                odataObject.CouponCodes[i];
                            } else {
        this.CouponCodes[i] = undefined;
        }
        }
        }
      
            this.DateValidationTypeValue = 
                odataObject.DateValidationTypeValue;
              
            this.AreCouponsActiveOnSameDate = 
                odataObject.AreCouponsActiveOnSameDate;
              
            this.AreCouponsExpiredOnSameDate = 
                odataObject.AreCouponsExpiredOnSameDate;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['PromotionClass'] = PromotionClass;
    
      /**
      * CategoryPathLookup entity class.
      */
      export class CategoryPathLookupClass implements Entities.CategoryPathLookup {
      public ProductId: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CategoryPathLookupClass'] = CategoryPathLookupClass;
    
      /**
      * CategoryPathResult entity class.
      */
      export class CategoryPathResultClass implements Entities.CategoryPathResult {
      public ProductId: number;
      public CategoryPath: Entities.CategoryPath[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
        this.CategoryPath = undefined;
        if (odataObject.CategoryPath) {
        this.CategoryPath = [];
        for (var i = 0; i < odataObject.CategoryPath.length; i++) {
        if (odataObject.CategoryPath[i] != null) {
        if (odataObject.CategoryPath[i]['@odata.type'] != null) {
          var className: string = odataObject.CategoryPath[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.CategoryPath[i] = new entityDictionary[className](odataObject.CategoryPath[i])
          }
        } else {
            this.CategoryPath[i] = new CategoryPathClass(odataObject.CategoryPath[i]);
        }
                    } else {
        this.CategoryPath[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CategoryPathResultClass'] = CategoryPathResultClass;
    
      /**
      * PickupTimeslotAvailabilitySearchCriteria entity class.
      */
      export class PickupTimeslotAvailabilitySearchCriteriaClass implements Entities.PickupTimeslotAvailabilitySearchCriteria {
      public DeliveryModeCode: string;
      public StoreId: string;
      public StartDate: Date;
      public EndDate: Date;
      public SearchOptionValue: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.DeliveryModeCode = 
                odataObject.DeliveryModeCode;
              
            this.StoreId = 
                odataObject.StoreId;
              
            this.StartDate = 
                (odataObject.StartDate instanceof Date) ? odataObject.StartDate
                : (odataObject.StartDate != null) ? jsonLightReadStringPropertyValue(odataObject.StartDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.EndDate = 
                (odataObject.EndDate instanceof Date) ? odataObject.EndDate
                : (odataObject.EndDate != null) ? jsonLightReadStringPropertyValue(odataObject.EndDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.SearchOptionValue = 
                odataObject.SearchOptionValue;
              
      }
      }
      entityDictionary['PickupTimeslotAvailabilitySearchCriteriaClass'] = PickupTimeslotAvailabilitySearchCriteriaClass;
    
      /**
      * PickupTimeslotAvailability entity class.
      */
      export class PickupTimeslotAvailabilityClass implements Entities.PickupTimeslotAvailability {
      public AvailableCapacity: number;
      public StartDateTime: Date;
      public EndDateTime: Date;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.AvailableCapacity = 
                odataObject.AvailableCapacity;
              
            this.StartDateTime = 
                (odataObject.StartDateTime instanceof Date) ? odataObject.StartDateTime
                : (odataObject.StartDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.StartDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.EndDateTime = 
                (odataObject.EndDateTime instanceof Date) ? odataObject.EndDateTime
                : (odataObject.EndDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.EndDateTime, "Edm.DateTimeOffset", false) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['PickupTimeslotAvailabilityClass'] = PickupTimeslotAvailabilityClass;
    
      /**
      * CheckInForOrderPickupConfirmation entity class.
      */
      export class CheckInForOrderPickupConfirmationClass implements Entities.CheckInForOrderPickupConfirmation {
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CheckInForOrderPickupConfirmationClass'] = CheckInForOrderPickupConfirmationClass;
    
      /**
      * SalesOrderConfirmedShipDateChange entity class.
      */
      export class SalesOrderConfirmedShipDateChangeClass implements Entities.SalesOrderConfirmedShipDateChange {
      public SalesId: string;
      public ConfirmedShipDate: Date;
      public LineChanges: Entities.SalesLineConfirmedShipDateChange[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.SalesId = 
                odataObject.SalesId;
              
            this.ConfirmedShipDate = 
                (odataObject.ConfirmedShipDate instanceof Date) ? odataObject.ConfirmedShipDate
                : (odataObject.ConfirmedShipDate != null) ? jsonLightReadStringPropertyValue(odataObject.ConfirmedShipDate, "Edm.DateTimeOffset", false) : undefined;
              
        this.LineChanges = undefined;
        if (odataObject.LineChanges) {
        this.LineChanges = [];
        for (var i = 0; i < odataObject.LineChanges.length; i++) {
        if (odataObject.LineChanges[i] != null) {
        if (odataObject.LineChanges[i]['@odata.type'] != null) {
          var className: string = odataObject.LineChanges[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.LineChanges[i] = new entityDictionary[className](odataObject.LineChanges[i])
          }
        } else {
            this.LineChanges[i] = new SalesLineConfirmedShipDateChangeClass(odataObject.LineChanges[i]);
        }
                    } else {
        this.LineChanges[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['SalesOrderConfirmedShipDateChangeClass'] = SalesOrderConfirmedShipDateChangeClass;
    
      /**
      * OrderCancellationResult entity class.
      */
      export class OrderCancellationResultClass implements Entities.OrderCancellationResult {
      public DocumentOperationCommitResultValue: number;
      public DocumentOperationCommitResultCodeValue: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.DocumentOperationCommitResultValue = 
                odataObject.DocumentOperationCommitResultValue;
              
            this.DocumentOperationCommitResultCodeValue = 
                odataObject.DocumentOperationCommitResultCodeValue;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['OrderCancellationResultClass'] = OrderCancellationResultClass;
    
      /**
      * VendorSearchCriteria entity class.
      */
      export class VendorSearchCriteriaClass implements Entities.VendorSearchCriteria {
      public SearchText: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.SearchText = 
                odataObject.SearchText;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['VendorSearchCriteriaClass'] = VendorSearchCriteriaClass;
    
      /**
      * Vendor entity class.
      */
      export class VendorClass implements Entities.Vendor {
      public RecordId: number;
      public AccountNumber: string;
      public PartyId: number;
      public DataAreaId: string;
      public Name: string;
      public NameAlias: string;
      public PartyNumber: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.AccountNumber = 
                odataObject.AccountNumber;
              
            this.PartyId = 
                (odataObject.PartyId != null) ? parseInt(odataObject.PartyId, 10) : undefined;
              
            this.DataAreaId = 
                odataObject.DataAreaId;
              
            this.Name = 
                odataObject.Name;
              
            this.NameAlias = 
                odataObject.NameAlias;
              
            this.PartyNumber = 
                odataObject.PartyNumber;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['VendorClass'] = VendorClass;
    
      /**
      * VendorValidationResult entity class.
      */
      export class VendorValidationResultClass implements Entities.VendorValidationResult {
      public Message: string;
      public VendorValidationResultLevelValue: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Message = 
                odataObject.Message;
              
            this.VendorValidationResultLevelValue = 
                odataObject.VendorValidationResultLevelValue;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['VendorValidationResultClass'] = VendorValidationResultClass;
    
      /**
      * CopilotResponse entity class.
      */
      export class CopilotResponseClass implements Entities.CopilotResponse {
      public Content: string;
      public Disclaimer: string;
      public LastRefreshDatetime: Date;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Content = 
                odataObject.Content;
              
            this.Disclaimer = 
                odataObject.Disclaimer;
              
            this.LastRefreshDatetime = 
                (odataObject.LastRefreshDatetime instanceof Date) ? odataObject.LastRefreshDatetime
                : (odataObject.LastRefreshDatetime != null) ? jsonLightReadStringPropertyValue(odataObject.LastRefreshDatetime, "Edm.DateTimeOffset", false) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CopilotResponseClass'] = CopilotResponseClass;
    
      /**
      * OnlineChannelAzureB2CConfiguration entity class.
      */
      export class OnlineChannelAzureB2CConfigurationClass implements Entities.OnlineChannelAzureB2CConfiguration {
      public Name: string;
      public Description: string;
      public TenantName: string;
      public SignInSignupPolicyId: string;
      public PasswordResetPolicyId: string;
      public SignUpPolicyId: string;
      public EditProfilePolicyId: string;
      public LoginDomainName: string;
      public Scope: string;
      public ApplicationId: string;
      public ApplicationSecret: string;
      public IsDefault: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Name = 
                odataObject.Name;
              
            this.Description = 
                odataObject.Description;
              
            this.TenantName = 
                odataObject.TenantName;
              
            this.SignInSignupPolicyId = 
                odataObject.SignInSignupPolicyId;
              
            this.PasswordResetPolicyId = 
                odataObject.PasswordResetPolicyId;
              
            this.SignUpPolicyId = 
                odataObject.SignUpPolicyId;
              
            this.EditProfilePolicyId = 
                odataObject.EditProfilePolicyId;
              
            this.LoginDomainName = 
                odataObject.LoginDomainName;
              
            this.Scope = 
                odataObject.Scope;
              
            this.ApplicationId = 
                odataObject.ApplicationId;
              
            this.ApplicationSecret = 
                odataObject.ApplicationSecret;
              
            this.IsDefault = 
                odataObject.IsDefault;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['OnlineChannelAzureB2CConfigurationClass'] = OnlineChannelAzureB2CConfigurationClass;
    
      /**
      * ExtensionProfile entity class.
      */
      export class ExtensionProfileClass implements Entities.ExtensionProfile {
      public ExtensionServiceUrl: string;
      public AttachedServices: Entities.AttachedService[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ExtensionServiceUrl = 
                odataObject.ExtensionServiceUrl;
              
        this.AttachedServices = undefined;
        if (odataObject.AttachedServices) {
        this.AttachedServices = [];
        for (var i = 0; i < odataObject.AttachedServices.length; i++) {
        if (odataObject.AttachedServices[i] != null) {
        if (odataObject.AttachedServices[i]['@odata.type'] != null) {
          var className: string = odataObject.AttachedServices[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.AttachedServices[i] = new entityDictionary[className](odataObject.AttachedServices[i])
          }
        } else {
            this.AttachedServices[i] = new AttachedServiceClass(odataObject.AttachedServices[i]);
        }
                    } else {
        this.AttachedServices[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ExtensionProfileClass'] = ExtensionProfileClass;
    
      /**
      * ChannelInventoryConfiguration entity class.
      */
      export class ChannelInventoryConfigurationClass implements Entities.ChannelInventoryConfiguration {
      public RecordId: number;
      public ChannelId: string;
      public AllowTransferOrderOverDelivery: boolean;
      public AllowTransferOrderUnderDelivery: boolean;
      public UserLocationProfileId: string;
      public InventoryProductAttributeRecordId: number;
      public InventoryOutOfStockAttributeValueText: string;
      public ProductAvailabilitySortableAttributeRecordId: number;
      public SkipQuantityInputWhenScanBarcode: boolean;
      public UseInventoryVisibilityServiceAsDataSource: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.ChannelId = 
                odataObject.ChannelId;
              
            this.AllowTransferOrderOverDelivery = 
                odataObject.AllowTransferOrderOverDelivery;
              
            this.AllowTransferOrderUnderDelivery = 
                odataObject.AllowTransferOrderUnderDelivery;
              
            this.UserLocationProfileId = 
                odataObject.UserLocationProfileId;
              
            this.InventoryProductAttributeRecordId = 
                (odataObject.InventoryProductAttributeRecordId != null) ? parseInt(odataObject.InventoryProductAttributeRecordId, 10) : undefined;
              
            this.InventoryOutOfStockAttributeValueText = 
                odataObject.InventoryOutOfStockAttributeValueText;
              
            this.ProductAvailabilitySortableAttributeRecordId = 
                (odataObject.ProductAvailabilitySortableAttributeRecordId != null) ? parseInt(odataObject.ProductAvailabilitySortableAttributeRecordId, 10) : undefined;
              
            this.SkipQuantityInputWhenScanBarcode = 
                odataObject.SkipQuantityInputWhenScanBarcode;
              
            this.UseInventoryVisibilityServiceAsDataSource = 
                odataObject.UseInventoryVisibilityServiceAsDataSource;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ChannelInventoryConfigurationClass'] = ChannelInventoryConfigurationClass;
    
      /**
      * ChannelMerchandisingConfiguration entity class.
      */
      export class ChannelMerchandisingConfigurationClass implements Entities.ChannelMerchandisingConfiguration {
      public RecordId: number;
      public ChannelId: string;
      public EnableMultipleUnitsOfMeasure: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.ChannelId = 
                odataObject.ChannelId;
              
            this.EnableMultipleUnitsOfMeasure = 
                odataObject.EnableMultipleUnitsOfMeasure;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ChannelMerchandisingConfigurationClass'] = ChannelMerchandisingConfigurationClass;
    
      /**
      * ChannelPurchaseConfiguration entity class.
      */
      export class ChannelPurchaseConfigurationClass implements Entities.ChannelPurchaseConfiguration {
      public RecordId: number;
      public ChannelId: string;
      public AllowOverDelivery: boolean;
      public AllowSubDelivery: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.ChannelId = 
                odataObject.ChannelId;
              
            this.AllowOverDelivery = 
                odataObject.AllowOverDelivery;
              
            this.AllowSubDelivery = 
                odataObject.AllowSubDelivery;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ChannelPurchaseConfigurationClass'] = ChannelPurchaseConfigurationClass;
    
      /**
      * InventoryJournalName entity class.
      */
      export class InventoryJournalNameClass implements Entities.InventoryJournalName {
      public RecordId: number;
      public JournalNameId: string;
      public InventoryJournalTypeValue: number;
      public Description: string;
      public DefaultAdjustmentTypeValue: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.JournalNameId = 
                odataObject.JournalNameId;
              
            this.InventoryJournalTypeValue = 
                odataObject.InventoryJournalTypeValue;
              
            this.Description = 
                odataObject.Description;
              
            this.DefaultAdjustmentTypeValue = 
                odataObject.DefaultAdjustmentTypeValue;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['InventoryJournalNameClass'] = InventoryJournalNameClass;
    
      /**
      * ShiftRetrievalCriteria entity class.
      */
      export class ShiftRetrievalCriteriaClass implements Entities.ShiftRetrievalCriteria {
      public ShiftStatusValue: number;
      public FilterByUserRole: boolean;
      public IncludeAllShiftsForStore: boolean;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ShiftStatusValue = 
                odataObject.ShiftStatusValue;
              
            this.FilterByUserRole = 
                odataObject.FilterByUserRole;
              
            this.IncludeAllShiftsForStore = 
                odataObject.IncludeAllShiftsForStore;
              
      }
      }
      entityDictionary['ShiftRetrievalCriteriaClass'] = ShiftRetrievalCriteriaClass;
    
      /**
      * UserDefinedSecretCertificateResult entity class.
      */
      export class UserDefinedSecretCertificateResultClass implements Entities.UserDefinedSecretCertificateResult {
      public CertificateData: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.CertificateData = 
                odataObject.CertificateData;
              
      }
      }
      entityDictionary['UserDefinedSecretCertificateResultClass'] = UserDefinedSecretCertificateResultClass;
    
      /**
      * TerminalInfo entity class.
      */
      export class TerminalInfoClass implements Entities.TerminalInfo {
      public TerminalId: string;
      public DeviceNumber: string;
      public Name: string;
      public DeviceType: number;
      public ActivationStatusValue: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.TerminalId = 
                odataObject.TerminalId;
              
            this.DeviceNumber = 
                odataObject.DeviceNumber;
              
            this.Name = 
                odataObject.Name;
              
            this.DeviceType = 
                odataObject.DeviceType;
              
            this.ActivationStatusValue = 
                odataObject.ActivationStatusValue;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['TerminalInfoClass'] = TerminalInfoClass;
    
      /**
      * CustomerTimelineItemTypeMap entity class.
      */
      export class CustomerTimelineItemTypeMapClass implements Entities.CustomerTimelineItemTypeMap {
      public EntityType: string;
      public Types: Entities.CustomerTimelineItemType[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.EntityType = 
                odataObject.EntityType;
              
        this.Types = undefined;
        if (odataObject.Types) {
        this.Types = [];
        for (var i = 0; i < odataObject.Types.length; i++) {
        if (odataObject.Types[i] != null) {
        if (odataObject.Types[i]['@odata.type'] != null) {
          var className: string = odataObject.Types[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Types[i] = new entityDictionary[className](odataObject.Types[i])
          }
        } else {
            this.Types[i] = new CustomerTimelineItemTypeClass(odataObject.Types[i]);
        }
                    } else {
        this.Types[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CustomerTimelineItemTypeMapClass'] = CustomerTimelineItemTypeMapClass;
    
      /**
      * CustomerTimelineRefiner entity class.
      */
      export class CustomerTimelineRefinerClass implements Entities.CustomerTimelineRefiner {
      public RefinedResultsCount: number;
      public EntityType: string;
      public TypeId: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RefinedResultsCount = 
                odataObject.RefinedResultsCount;
              
            this.EntityType = 
                odataObject.EntityType;
              
            this.TypeId = 
                odataObject.TypeId;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CustomerTimelineRefinerClass'] = CustomerTimelineRefinerClass;
    
      /**
      * CustomerTitle entity class.
      */
      export class CustomerTitleClass implements Entities.CustomerTitle {
      public Text: string;
      public RecordId: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Text = 
                odataObject.Text;
              
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CustomerTitleClass'] = CustomerTitleClass;
    
      /**
      * ProductWarehouse entity class.
      */
      export class ProductWarehouseClass implements Entities.ProductWarehouse {
      public ProductId: number;
      public InventLocationId: string;
      public DataAreaId: string;
      public UnitOfMeasure: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
            this.InventLocationId = 
                odataObject.InventLocationId;
              
            this.DataAreaId = 
                odataObject.DataAreaId;
              
            this.UnitOfMeasure = 
                odataObject.UnitOfMeasure;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ProductWarehouseClass'] = ProductWarehouseClass;
    
      /**
      * ProductWarehouseInventoryInformation entity class.
      */
      export class ProductWarehouseInventoryInformationClass implements Entities.ProductWarehouseInventoryInformation {
      public ProductWarehouseInventoryAvailabilities: Entities.ProductWarehouseInventoryAvailability[];
      public AggregatedProductInventoryAvailabilities: Entities.ProductInventoryAvailability[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.ProductWarehouseInventoryAvailabilities = undefined;
        if (odataObject.ProductWarehouseInventoryAvailabilities) {
        this.ProductWarehouseInventoryAvailabilities = [];
        for (var i = 0; i < odataObject.ProductWarehouseInventoryAvailabilities.length; i++) {
        if (odataObject.ProductWarehouseInventoryAvailabilities[i] != null) {
        if (odataObject.ProductWarehouseInventoryAvailabilities[i]['@odata.type'] != null) {
          var className: string = odataObject.ProductWarehouseInventoryAvailabilities[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ProductWarehouseInventoryAvailabilities[i] = new entityDictionary[className](odataObject.ProductWarehouseInventoryAvailabilities[i])
          }
        } else {
            this.ProductWarehouseInventoryAvailabilities[i] = new ProductWarehouseInventoryAvailabilityClass(odataObject.ProductWarehouseInventoryAvailabilities[i]);
        }
                    } else {
        this.ProductWarehouseInventoryAvailabilities[i] = undefined;
        }
        }
        }
      
        this.AggregatedProductInventoryAvailabilities = undefined;
        if (odataObject.AggregatedProductInventoryAvailabilities) {
        this.AggregatedProductInventoryAvailabilities = [];
        for (var i = 0; i < odataObject.AggregatedProductInventoryAvailabilities.length; i++) {
        if (odataObject.AggregatedProductInventoryAvailabilities[i] != null) {
        if (odataObject.AggregatedProductInventoryAvailabilities[i]['@odata.type'] != null) {
          var className: string = odataObject.AggregatedProductInventoryAvailabilities[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.AggregatedProductInventoryAvailabilities[i] = new entityDictionary[className](odataObject.AggregatedProductInventoryAvailabilities[i])
          }
        } else {
            this.AggregatedProductInventoryAvailabilities[i] = new ProductInventoryAvailabilityClass(odataObject.AggregatedProductInventoryAvailabilities[i]);
        }
                    } else {
        this.AggregatedProductInventoryAvailabilities[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ProductWarehouseInventoryInformationClass'] = ProductWarehouseInventoryInformationClass;
    
      /**
      * ProductWarehouseQuantity entity class.
      */
      export class ProductWarehouseQuantityClass implements Entities.ProductWarehouseQuantity {
      public ProductId: number;
      public InventoryLocationId: string;
      public PhysicalInventory: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
            this.InventoryLocationId = 
                odataObject.InventoryLocationId;
              
            this.PhysicalInventory = 
                (odataObject.PhysicalInventory != null) ? parseFloat(odataObject.PhysicalInventory) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ProductWarehouseQuantityClass'] = ProductWarehouseQuantityClass;
    
      /**
      * UpdateProductWarehouseAvailabilityResult entity class.
      */
      export class UpdateProductWarehouseAvailabilityResultClass implements Entities.UpdateProductWarehouseAvailabilityResult {
      public SuccessfulUpdates: Entities.InventoryQuantity[];
      public FailedUpdates: Entities.InventoryQuantity[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.SuccessfulUpdates = undefined;
        if (odataObject.SuccessfulUpdates) {
        this.SuccessfulUpdates = [];
        for (var i = 0; i < odataObject.SuccessfulUpdates.length; i++) {
        if (odataObject.SuccessfulUpdates[i] != null) {
        if (odataObject.SuccessfulUpdates[i]['@odata.type'] != null) {
          var className: string = odataObject.SuccessfulUpdates[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.SuccessfulUpdates[i] = new entityDictionary[className](odataObject.SuccessfulUpdates[i])
          }
        } else {
            this.SuccessfulUpdates[i] = new InventoryQuantityClass(odataObject.SuccessfulUpdates[i]);
        }
                    } else {
        this.SuccessfulUpdates[i] = undefined;
        }
        }
        }
      
        this.FailedUpdates = undefined;
        if (odataObject.FailedUpdates) {
        this.FailedUpdates = [];
        for (var i = 0; i < odataObject.FailedUpdates.length; i++) {
        if (odataObject.FailedUpdates[i] != null) {
        if (odataObject.FailedUpdates[i]['@odata.type'] != null) {
          var className: string = odataObject.FailedUpdates[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.FailedUpdates[i] = new entityDictionary[className](odataObject.FailedUpdates[i])
          }
        } else {
            this.FailedUpdates[i] = new InventoryQuantityClass(odataObject.FailedUpdates[i]);
        }
                    } else {
        this.FailedUpdates[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['UpdateProductWarehouseAvailabilityResultClass'] = UpdateProductWarehouseAvailabilityResultClass;
    
      /**
      * InventoryAvailabilitySearchCriteria entity class.
      */
      export class InventoryAvailabilitySearchCriteriaClass implements Entities.InventoryAvailabilitySearchCriteria {
      public ProductIds: number[];
      public DefaultWarehouseOnly: boolean;
      public SearchArea: Entities.SearchArea;
      public FilterByChannelFulfillmentGroup: boolean;
      public DeliveryModeTypeFilterValue: number;
      public QuantityUnitTypeValue: number;
      public ProductUnits: Entities.ProductUnit[];
      public DataLevelValue: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.ProductIds = undefined;
        if (odataObject.ProductIds) {
        this.ProductIds = [];
        for (var i = 0; i < odataObject.ProductIds.length; i++) {
        if (odataObject.ProductIds[i] != null) {
            this.ProductIds[i] = 
                parseInt(odataObject.ProductIds[i], 10);
                            } else {
        this.ProductIds[i] = undefined;
        }
        }
        }
      
            this.DefaultWarehouseOnly = 
                odataObject.DefaultWarehouseOnly;
              
        if (odataObject.SearchArea == null) {
            this.SearchArea = undefined;
        } else if (odataObject.SearchArea['@odata.type'] == null) {
            this.SearchArea = new SearchAreaClass(odataObject.SearchArea);
        } else {
            var className: string = odataObject.SearchArea['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.SearchArea = new entityDictionary[className](odataObject.SearchArea)
            }
        }

      
            this.FilterByChannelFulfillmentGroup = 
                odataObject.FilterByChannelFulfillmentGroup;
              
            this.DeliveryModeTypeFilterValue = 
                odataObject.DeliveryModeTypeFilterValue;
              
            this.QuantityUnitTypeValue = 
                odataObject.QuantityUnitTypeValue;
              
        this.ProductUnits = undefined;
        if (odataObject.ProductUnits) {
        this.ProductUnits = [];
        for (var i = 0; i < odataObject.ProductUnits.length; i++) {
        if (odataObject.ProductUnits[i] != null) {
        if (odataObject.ProductUnits[i]['@odata.type'] != null) {
          var className: string = odataObject.ProductUnits[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ProductUnits[i] = new entityDictionary[className](odataObject.ProductUnits[i])
          }
        } else {
            this.ProductUnits[i] = new ProductUnitClass(odataObject.ProductUnits[i]);
        }
                    } else {
        this.ProductUnits[i] = undefined;
        }
        }
        }
      
            this.DataLevelValue = 
                odataObject.DataLevelValue;
              
      }
      }
      entityDictionary['InventoryAvailabilitySearchCriteriaClass'] = InventoryAvailabilitySearchCriteriaClass;
    
      /**
      * ExtensibleEnumerationContainer entity class.
      */
      export class ExtensibleEnumerationContainerClass implements Entities.ExtensibleEnumerationContainer {
      public TypeName: string;
      public ExtensibleEnumerations: Entities.ExtensibleEnumeration[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.TypeName = 
                odataObject.TypeName;
              
        this.ExtensibleEnumerations = undefined;
        if (odataObject.ExtensibleEnumerations) {
        this.ExtensibleEnumerations = [];
        for (var i = 0; i < odataObject.ExtensibleEnumerations.length; i++) {
        if (odataObject.ExtensibleEnumerations[i] != null) {
        if (odataObject.ExtensibleEnumerations[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensibleEnumerations[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensibleEnumerations[i] = new entityDictionary[className](odataObject.ExtensibleEnumerations[i])
          }
        } else {
            this.ExtensibleEnumerations[i] = new ExtensibleEnumerationClass(odataObject.ExtensibleEnumerations[i]);
        }
                    } else {
        this.ExtensibleEnumerations[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ExtensibleEnumerationContainerClass'] = ExtensibleEnumerationContainerClass;
    
      /**
      * PackingSlipData entity class.
      */
      export class PackingSlipDataClass implements Entities.PackingSlipData {
      public PackingSlipId: string;
      public CreatedDateTime: Date;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.PackingSlipId = 
                odataObject.PackingSlipId;
              
            this.CreatedDateTime = 
                (odataObject.CreatedDateTime instanceof Date) ? odataObject.CreatedDateTime
                : (odataObject.CreatedDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.CreatedDateTime, "Edm.DateTimeOffset", false) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['PackingSlipDataClass'] = PackingSlipDataClass;
    
      /**
      * CartConfiguration entity class.
      */
      export class CartConfigurationClass implements Entities.CartConfiguration {
      public ValidateDefaultOrderSettingsPerLine: boolean;
      public IgnoreOrderSettings: boolean;
      public MaxQuantityPerItem: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ValidateDefaultOrderSettingsPerLine = 
                odataObject.ValidateDefaultOrderSettingsPerLine;
              
            this.IgnoreOrderSettings = 
                odataObject.IgnoreOrderSettings;
              
            this.MaxQuantityPerItem = 
                (odataObject.MaxQuantityPerItem != null) ? parseFloat(odataObject.MaxQuantityPerItem) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CartConfigurationClass'] = CartConfigurationClass;
    
      /**
      * EmailReceiptSettings entity class.
      */
      export class EmailReceiptSettingsClass implements Entities.EmailReceiptSettings {
      public ReceiptTypeValue: number;
      public EmailBehaviorValue: number;
      public ReceiptTitle: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ReceiptTypeValue = 
                odataObject.ReceiptTypeValue;
              
            this.EmailBehaviorValue = 
                odataObject.EmailBehaviorValue;
              
            this.ReceiptTitle = 
                odataObject.ReceiptTitle;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['EmailReceiptSettingsClass'] = EmailReceiptSettingsClass;
    
      /**
      * FiscalIntegrationComplianceResult entity class.
      */
      export class FiscalIntegrationComplianceResultClass implements Entities.FiscalIntegrationComplianceResult {
      public ErrorMessages: string[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.ErrorMessages = undefined;
        if (odataObject.ErrorMessages) {
        this.ErrorMessages = [];
        for (var i = 0; i < odataObject.ErrorMessages.length; i++) {
        if (odataObject.ErrorMessages[i] != null) {
            this.ErrorMessages[i] = 
                odataObject.ErrorMessages[i];
                            } else {
        this.ErrorMessages[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['FiscalIntegrationComplianceResultClass'] = FiscalIntegrationComplianceResultClass;
    
      /**
      * OrgUnitAddress entity class.
      */
      export class OrgUnitAddressClass
        extends AddressClass
       implements Entities.OrgUnitAddress {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.OrgUnitAddress";
      
      }
      }
      entityDictionary['OrgUnitAddressClass'] = OrgUnitAddressClass;
    
      /**
      * WarehouseDetails entity class.
      */
      export class WarehouseDetailsClass
        extends AddressClass
       implements Entities.WarehouseDetails {
      public InventoryLocationId: string;
      public InventorySiteId: string;
      public Latitude: number;
      public Longitude: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.InventoryLocationId = 
                odataObject.InventoryLocationId;
              
            this.InventorySiteId = 
                odataObject.InventorySiteId;
              
            this.Latitude = 
                (odataObject.Latitude != null) ? parseFloat(odataObject.Latitude) : undefined;
              
            this.Longitude = 
                (odataObject.Longitude != null) ? parseFloat(odataObject.Longitude) : undefined;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.WarehouseDetails";
      
      }
      }
      entityDictionary['WarehouseDetailsClass'] = WarehouseDetailsClass;
    
      /**
      * AttributeSwatch entity class.
      */
      export class AttributeSwatchClass implements Entities.AttributeSwatch {
      public SwatchValue: string;
      public SwatchImageUrl: string;
      public SwatchColorHexCode: string;
      public IsDefault: boolean;
      public ProductImageUrls: string[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.SwatchValue = 
                odataObject.SwatchValue;
              
            this.SwatchImageUrl = 
                odataObject.SwatchImageUrl;
              
            this.SwatchColorHexCode = 
                odataObject.SwatchColorHexCode;
              
            this.IsDefault = 
                odataObject.IsDefault;
              
        this.ProductImageUrls = undefined;
        if (odataObject.ProductImageUrls) {
        this.ProductImageUrls = [];
        for (var i = 0; i < odataObject.ProductImageUrls.length; i++) {
        if (odataObject.ProductImageUrls[i] != null) {
            this.ProductImageUrls[i] = 
                odataObject.ProductImageUrls[i];
                            } else {
        this.ProductImageUrls[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['AttributeSwatchClass'] = AttributeSwatchClass;
    
      /**
      * AttributeBooleanValue entity class.
      */
      export class AttributeBooleanValueClass
        extends AttributeValueBaseClass
       implements Entities.AttributeBooleanValue {
      public Value: boolean;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.Value = 
                odataObject.Value;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.AttributeBooleanValue";
      
      }
      }
      entityDictionary['AttributeBooleanValueClass'] = AttributeBooleanValueClass;
    
      /**
      * AttributeCurrencyValue entity class.
      */
      export class AttributeCurrencyValueClass
        extends AttributeValueBaseClass
       implements Entities.AttributeCurrencyValue {
      public CurrencyValue: number;
      public CurrencyCode: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.CurrencyValue = 
                (odataObject.CurrencyValue != null) ? parseFloat(odataObject.CurrencyValue) : undefined;
              
            this.CurrencyCode = 
                odataObject.CurrencyCode;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.AttributeCurrencyValue";
      
      }
      }
      entityDictionary['AttributeCurrencyValueClass'] = AttributeCurrencyValueClass;
    
      /**
      * AttributeDateTimeOffsetValue entity class.
      */
      export class AttributeDateTimeOffsetValueClass
        extends AttributeValueBaseClass
       implements Entities.AttributeDateTimeOffsetValue {
      public Value: Date;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.Value = 
                (odataObject.Value instanceof Date) ? odataObject.Value
                : (odataObject.Value != null) ? jsonLightReadStringPropertyValue(odataObject.Value, "Edm.DateTimeOffset", false) : undefined;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.AttributeDateTimeOffsetValue";
      
      }
      }
      entityDictionary['AttributeDateTimeOffsetValueClass'] = AttributeDateTimeOffsetValueClass;
    
      /**
      * AttributeFloatValue entity class.
      */
      export class AttributeFloatValueClass
        extends AttributeValueBaseClass
       implements Entities.AttributeFloatValue {
      public Value: number;
      public UnitSymbol: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.Value = 
                (odataObject.Value != null) ? parseFloat(odataObject.Value) : undefined;
              
            this.UnitSymbol = 
                odataObject.UnitSymbol;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.AttributeFloatValue";
      
      }
      }
      entityDictionary['AttributeFloatValueClass'] = AttributeFloatValueClass;
    
      /**
      * AttributeIntValue entity class.
      */
      export class AttributeIntValueClass
        extends AttributeValueBaseClass
       implements Entities.AttributeIntValue {
      public Value: number;
      public UnitSymbol: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.Value = 
                odataObject.Value;
              
            this.UnitSymbol = 
                odataObject.UnitSymbol;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.AttributeIntValue";
      
      }
      }
      entityDictionary['AttributeIntValueClass'] = AttributeIntValueClass;
    
      /**
      * AttributeMediaLocationValue entity class.
      */
      export class AttributeMediaLocationValueClass
        extends AttributeValueBaseClass
       implements Entities.AttributeMediaLocationValue {
      public Value: Entities.MediaLocation[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this.Value = undefined;
        if (odataObject.Value) {
        this.Value = [];
        for (var i = 0; i < odataObject.Value.length; i++) {
        if (odataObject.Value[i] != null) {
        if (odataObject.Value[i]['@odata.type'] != null) {
          var className: string = odataObject.Value[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Value[i] = new entityDictionary[className](odataObject.Value[i])
          }
        } else {
            this.Value[i] = new MediaLocationClass(odataObject.Value[i]);
        }
                    } else {
        this.Value[i] = undefined;
        }
        }
        }
      
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.AttributeMediaLocationValue";
      
      }
      }
      entityDictionary['AttributeMediaLocationValueClass'] = AttributeMediaLocationValueClass;
    
      /**
      * AttributeTextValue entity class.
      */
      export class AttributeTextValueClass
        extends AttributeValueBaseClass
       implements Entities.AttributeTextValue {
      public RecId: number;
      public TextValue: string;
      public TextValueTranslations: Entities.TextValueTranslation[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.RecId = 
                (odataObject.RecId != null) ? parseInt(odataObject.RecId, 10) : undefined;
              
            this.TextValue = 
                odataObject.TextValue;
              
        this.TextValueTranslations = undefined;
        if (odataObject.TextValueTranslations) {
        this.TextValueTranslations = [];
        for (var i = 0; i < odataObject.TextValueTranslations.length; i++) {
        if (odataObject.TextValueTranslations[i] != null) {
        if (odataObject.TextValueTranslations[i]['@odata.type'] != null) {
          var className: string = odataObject.TextValueTranslations[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.TextValueTranslations[i] = new entityDictionary[className](odataObject.TextValueTranslations[i])
          }
        } else {
            this.TextValueTranslations[i] = new TextValueTranslationClass(odataObject.TextValueTranslations[i]);
        }
                    } else {
        this.TextValueTranslations[i] = undefined;
        }
        }
        }
      
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.AttributeTextValue";
      
      }
      }
      entityDictionary['AttributeTextValueClass'] = AttributeTextValueClass;
    
      /**
      * ButtonGridButton entity class.
      */
      export class ButtonGridButtonClass implements Entities.ButtonGridButton {
      public Action: number;
      public ActionProperty: string;
      public BackColorAsARGB: Entities.ARGBColor;
      public BorderColorAsARGB: Entities.ARGBColor;
      public Column: number;
      public ColumnSpan: number;
      public DisplayText: string;
      public FontColorAsARGB: Entities.ARGBColor;
      public ButtonId: number;
      public Row: number;
      public RowSpan: number;
      public UseCustomLookAndFeel: boolean;
      public PictureId: number;
      public PictureAsBase64: string;
      public FontStyle: number;
      public FontSize: number;
      public EnableCustomFontForPOS: boolean;
      public HideButtonText: boolean;
      public Tooltip: string;
      public EnableLiveContent: boolean;
      public NotificationContentAlignment: number;
      public ImageAlignment: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Action = 
                odataObject.Action;
              
            this.ActionProperty = 
                odataObject.ActionProperty;
              
        if (odataObject.BackColorAsARGB == null) {
            this.BackColorAsARGB = undefined;
        } else if (odataObject.BackColorAsARGB['@odata.type'] == null) {
            this.BackColorAsARGB = new ARGBColorClass(odataObject.BackColorAsARGB);
        } else {
            var className: string = odataObject.BackColorAsARGB['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.BackColorAsARGB = new entityDictionary[className](odataObject.BackColorAsARGB)
            }
        }

      
        if (odataObject.BorderColorAsARGB == null) {
            this.BorderColorAsARGB = undefined;
        } else if (odataObject.BorderColorAsARGB['@odata.type'] == null) {
            this.BorderColorAsARGB = new ARGBColorClass(odataObject.BorderColorAsARGB);
        } else {
            var className: string = odataObject.BorderColorAsARGB['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.BorderColorAsARGB = new entityDictionary[className](odataObject.BorderColorAsARGB)
            }
        }

      
            this.Column = 
                odataObject.Column;
              
            this.ColumnSpan = 
                odataObject.ColumnSpan;
              
            this.DisplayText = 
                odataObject.DisplayText;
              
        if (odataObject.FontColorAsARGB == null) {
            this.FontColorAsARGB = undefined;
        } else if (odataObject.FontColorAsARGB['@odata.type'] == null) {
            this.FontColorAsARGB = new ARGBColorClass(odataObject.FontColorAsARGB);
        } else {
            var className: string = odataObject.FontColorAsARGB['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.FontColorAsARGB = new entityDictionary[className](odataObject.FontColorAsARGB)
            }
        }

      
            this.ButtonId = 
                odataObject.ButtonId;
              
            this.Row = 
                odataObject.Row;
              
            this.RowSpan = 
                odataObject.RowSpan;
              
            this.UseCustomLookAndFeel = 
                odataObject.UseCustomLookAndFeel;
              
            this.PictureId = 
                odataObject.PictureId;
              
            this.PictureAsBase64 = 
                odataObject.PictureAsBase64;
              
            this.FontStyle = 
                odataObject.FontStyle;
              
            this.FontSize = 
                odataObject.FontSize;
              
            this.EnableCustomFontForPOS = 
                odataObject.EnableCustomFontForPOS;
              
            this.HideButtonText = 
                odataObject.HideButtonText;
              
            this.Tooltip = 
                odataObject.Tooltip;
              
            this.EnableLiveContent = 
                odataObject.EnableLiveContent;
              
            this.NotificationContentAlignment = 
                odataObject.NotificationContentAlignment;
              
            this.ImageAlignment = 
                odataObject.ImageAlignment;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ButtonGridButtonClass'] = ButtonGridButtonClass;
    
      /**
      * ARGBColor entity class.
      */
      export class ARGBColorClass implements Entities.ARGBColor {
      public A: number;
      public R: number;
      public G: number;
      public B: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.A = 
                (odataObject.A != null) ? parseInt(odataObject.A, 10) : undefined;
              
            this.R = 
                (odataObject.R != null) ? parseInt(odataObject.R, 10) : undefined;
              
            this.G = 
                (odataObject.G != null) ? parseInt(odataObject.G, 10) : undefined;
              
            this.B = 
                (odataObject.B != null) ? parseInt(odataObject.B, 10) : undefined;
              
      }
      }
      entityDictionary['ARGBColorClass'] = ARGBColorClass;
    
      /**
      * PriceLine entity class.
      */
      export class PriceLineClass implements Entities.PriceLine {
      public RecordId: number;
      public Value: number;
      public PriceMethod: string;
      public OriginId: string;
      public PriceChangedByExtensions: boolean;
      public SaleLineNumber: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.Value = 
                (odataObject.Value != null) ? parseFloat(odataObject.Value) : undefined;
              
            this.PriceMethod = 
                odataObject.PriceMethod;
              
            this.OriginId = 
                odataObject.OriginId;
              
            this.PriceChangedByExtensions = 
                odataObject.PriceChangedByExtensions;
              
            this.SaleLineNumber = 
                (odataObject.SaleLineNumber != null) ? parseFloat(odataObject.SaleLineNumber) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['PriceLineClass'] = PriceLineClass;
    
      /**
      * TradeAgreementPriceLine entity class.
      */
      export class TradeAgreementPriceLineClass
        extends PriceLineClass
       implements Entities.TradeAgreementPriceLine {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.TradeAgreementPriceLine";
      
      }
      }
      entityDictionary['TradeAgreementPriceLineClass'] = TradeAgreementPriceLineClass;
    
      /**
      * AttributePricingTradeAgreementPriceLine entity class.
      */
      export class AttributePricingTradeAgreementPriceLineClass
        extends TradeAgreementPriceLineClass
       implements Entities.AttributePricingTradeAgreementPriceLine {
      public CanApplyPriceAdjustments: boolean;
      public PricingPriorityNumber: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.CanApplyPriceAdjustments = 
                odataObject.CanApplyPriceAdjustments;
              
            this.PricingPriorityNumber = 
                odataObject.PricingPriorityNumber;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.AttributePricingTradeAgreementPriceLine";
      
      }
      }
      entityDictionary['AttributePricingTradeAgreementPriceLineClass'] = AttributePricingTradeAgreementPriceLineClass;
    
      /**
      * SalesAgreementPriceLine entity class.
      */
      export class SalesAgreementPriceLineClass
        extends PriceLineClass
       implements Entities.SalesAgreementPriceLine {
      public SalesAgreementName: string;
      public UnitPrice: number;
      public DiscountAmount: number;
      public DiscountPercent: number;
      public SalesAgreementLineTypeValue: number;
      public PriceUnit: number;
      public EffectiveDate: Date;
      public ExpirationDate: Date;
      public CommittedQuantity: number;
      public RemainingQuantity: number;
      public CommittedAmount: number;
      public RemainingAmount: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.SalesAgreementName = 
                odataObject.SalesAgreementName;
              
            this.UnitPrice = 
                (odataObject.UnitPrice != null) ? parseFloat(odataObject.UnitPrice) : undefined;
              
            this.DiscountAmount = 
                (odataObject.DiscountAmount != null) ? parseFloat(odataObject.DiscountAmount) : undefined;
              
            this.DiscountPercent = 
                (odataObject.DiscountPercent != null) ? parseFloat(odataObject.DiscountPercent) : undefined;
              
            this.SalesAgreementLineTypeValue = 
                odataObject.SalesAgreementLineTypeValue;
              
            this.PriceUnit = 
                (odataObject.PriceUnit != null) ? parseFloat(odataObject.PriceUnit) : undefined;
              
            this.EffectiveDate = 
                (odataObject.EffectiveDate instanceof Date) ? odataObject.EffectiveDate
                : (odataObject.EffectiveDate != null) ? jsonLightReadStringPropertyValue(odataObject.EffectiveDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.ExpirationDate = 
                (odataObject.ExpirationDate instanceof Date) ? odataObject.ExpirationDate
                : (odataObject.ExpirationDate != null) ? jsonLightReadStringPropertyValue(odataObject.ExpirationDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.CommittedQuantity = 
                (odataObject.CommittedQuantity != null) ? parseFloat(odataObject.CommittedQuantity) : undefined;
              
            this.RemainingQuantity = 
                (odataObject.RemainingQuantity != null) ? parseFloat(odataObject.RemainingQuantity) : undefined;
              
            this.CommittedAmount = 
                (odataObject.CommittedAmount != null) ? parseFloat(odataObject.CommittedAmount) : undefined;
              
            this.RemainingAmount = 
                (odataObject.RemainingAmount != null) ? parseFloat(odataObject.RemainingAmount) : undefined;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.SalesAgreementPriceLine";
      
      }
      }
      entityDictionary['SalesAgreementPriceLineClass'] = SalesAgreementPriceLineClass;
    
      /**
      * PriceAdjustmentPriceLine entity class.
      */
      export class PriceAdjustmentPriceLineClass
        extends PriceLineClass
       implements Entities.PriceAdjustmentPriceLine {
      public IsCompoundable: boolean;
      public Concurrency: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.IsCompoundable = 
                odataObject.IsCompoundable;
              
            this.Concurrency = 
                odataObject.Concurrency;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.PriceAdjustmentPriceLine";
      
      }
      }
      entityDictionary['PriceAdjustmentPriceLineClass'] = PriceAdjustmentPriceLineClass;
    
      /**
      * AttributePricingAdjustmentPriceLine entity class.
      */
      export class AttributePricingAdjustmentPriceLineClass
        extends PriceAdjustmentPriceLineClass
       implements Entities.AttributePricingAdjustmentPriceLine {
      public CanCompoundOnAdjustedPrice: boolean;
      public PricingPriorityNumber: number;
      public AdjustedAmount: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.CanCompoundOnAdjustedPrice = 
                odataObject.CanCompoundOnAdjustedPrice;
              
            this.PricingPriorityNumber = 
                odataObject.PricingPriorityNumber;
              
            this.AdjustedAmount = 
                (odataObject.AdjustedAmount != null) ? parseFloat(odataObject.AdjustedAmount) : undefined;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.AttributePricingAdjustmentPriceLine";
      
      }
      }
      entityDictionary['AttributePricingAdjustmentPriceLineClass'] = AttributePricingAdjustmentPriceLineClass;
    
      /**
      * ManualPriceLine entity class.
      */
      export class ManualPriceLineClass
        extends PriceLineClass
       implements Entities.ManualPriceLine {
      public ManualPrice: number;
      public UnitOfMeasureSymbol: string;
      public ManualPriceEntryTypeValue: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.ManualPrice = 
                (odataObject.ManualPrice != null) ? parseFloat(odataObject.ManualPrice) : undefined;
              
            this.UnitOfMeasureSymbol = 
                odataObject.UnitOfMeasureSymbol;
              
            this.ManualPriceEntryTypeValue = 
                odataObject.ManualPriceEntryTypeValue;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.ManualPriceLine";
      
      }
      }
      entityDictionary['ManualPriceLineClass'] = ManualPriceLineClass;
    
      /**
      * BasePriceLine entity class.
      */
      export class BasePriceLineClass
        extends PriceLineClass
       implements Entities.BasePriceLine {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.BasePriceLine";
      
      }
      }
      entityDictionary['BasePriceLineClass'] = BasePriceLineClass;
    
      /**
      * ExtensiblePeriodicDiscountOfferType entity class.
      */
      export class ExtensiblePeriodicDiscountOfferTypeClass
        extends ExtensibleEnumerationClass
       implements Entities.ExtensiblePeriodicDiscountOfferType {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.ExtensiblePeriodicDiscountOfferType";
      
      }
      }
      entityDictionary['ExtensiblePeriodicDiscountOfferTypeClass'] = ExtensiblePeriodicDiscountOfferTypeClass;
    
      /**
      * ChargeLineOverride entity class.
      */
      export class ChargeLineOverrideClass implements Entities.ChargeLineOverride {
      public OriginalAmount: number;
      public OverrideAmount: number;
      public OverrideReasonDescription: string;
      public OverrideDateTime: Date;
      public UserId: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.OriginalAmount = 
                (odataObject.OriginalAmount != null) ? parseFloat(odataObject.OriginalAmount) : undefined;
              
            this.OverrideAmount = 
                (odataObject.OverrideAmount != null) ? parseFloat(odataObject.OverrideAmount) : undefined;
              
            this.OverrideReasonDescription = 
                odataObject.OverrideReasonDescription;
              
            this.OverrideDateTime = 
                (odataObject.OverrideDateTime instanceof Date) ? odataObject.OverrideDateTime
                : (odataObject.OverrideDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.OverrideDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.UserId = 
                odataObject.UserId;
              
      }
      }
      entityDictionary['ChargeLineOverrideClass'] = ChargeLineOverrideClass;
    
      /**
      * TaxLineGTE entity class.
      */
      export class TaxLineGTEClass
        extends TaxLineClass
       implements Entities.TaxLineGTE {
      public TaxComponent: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.TaxComponent = 
                odataObject.TaxComponent;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.TaxLineGTE";
      
      }
      }
      entityDictionary['TaxLineGTEClass'] = TaxLineGTEClass;
    
      /**
      * TaxMeasure entity class.
      */
      export class TaxMeasureClass implements Entities.TaxMeasure {
      public Path: string;
      public Value: number;
      public DataAreaId: string;
      public SaleLineNumber: number;
      public StoreId: string;
      public TerminalId: string;
      public TransactionId: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Path = 
                odataObject.Path;
              
            this.Value = 
                (odataObject.Value != null) ? parseFloat(odataObject.Value) : undefined;
              
            this.DataAreaId = 
                odataObject.DataAreaId;
              
            this.SaleLineNumber = 
                (odataObject.SaleLineNumber != null) ? parseFloat(odataObject.SaleLineNumber) : undefined;
              
            this.StoreId = 
                odataObject.StoreId;
              
            this.TerminalId = 
                odataObject.TerminalId;
              
            this.TransactionId = 
                odataObject.TransactionId;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['TaxMeasureClass'] = TaxMeasureClass;
    
      /**
      * DetailedLineStatus entity class.
      */
      export class DetailedLineStatusClass
        extends ExtensibleEnumerationClass
       implements Entities.DetailedLineStatus {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.DetailedLineStatus";
      
      }
      }
      entityDictionary['DetailedLineStatusClass'] = DetailedLineStatusClass;
    
      /**
      * CardTokenInfo entity class.
      */
      export class CardTokenInfoClass implements Entities.CardTokenInfo {
      public CardToken: string;
      public UniqueCardId: string;
      public ServiceAccountId: string;
      public MaskedCardNumber: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.CardToken = 
                odataObject.CardToken;
              
            this.UniqueCardId = 
                odataObject.UniqueCardId;
              
            this.ServiceAccountId = 
                odataObject.ServiceAccountId;
              
            this.MaskedCardNumber = 
                odataObject.MaskedCardNumber;
              
      }
      }
      entityDictionary['CardTokenInfoClass'] = CardTokenInfoClass;
    
      /**
      * DataverseTenderLine entity class.
      */
      export class DataverseTenderLineClass
        extends TenderLineClass
       implements Entities.DataverseTenderLine {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.DataverseTenderLine";
      
      }
      }
      entityDictionary['DataverseTenderLineClass'] = DataverseTenderLineClass;
    
      /**
      * PaymentError entity class.
      */
      export class PaymentErrorClass implements Entities.PaymentError {
      public Code: string;
      public Message: string;
      public IsLocalized: boolean;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Code = 
                odataObject.Code;
              
            this.Message = 
                odataObject.Message;
              
            this.IsLocalized = 
                odataObject.IsLocalized;
              
      }
      }
      entityDictionary['PaymentErrorClass'] = PaymentErrorClass;
    
      /**
      * ProductAttributeMetadataFlags entity class.
      */
      export class ProductAttributeMetadataFlagsClass implements Entities.ProductAttributeMetadataFlags {
      public IsSearchable: boolean;
      public IsRetrievable: boolean;
      public IsQueryable: boolean;
      public IsRefinable: boolean;
      public IsSortable: boolean;
      public IsMultiValue: boolean;
      public IsTokenNormalization: boolean;
      public IsCompleteMatch: boolean;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.IsSearchable = 
                odataObject.IsSearchable;
              
            this.IsRetrievable = 
                odataObject.IsRetrievable;
              
            this.IsQueryable = 
                odataObject.IsQueryable;
              
            this.IsRefinable = 
                odataObject.IsRefinable;
              
            this.IsSortable = 
                odataObject.IsSortable;
              
            this.IsMultiValue = 
                odataObject.IsMultiValue;
              
            this.IsTokenNormalization = 
                odataObject.IsTokenNormalization;
              
            this.IsCompleteMatch = 
                odataObject.IsCompleteMatch;
              
      }
      }
      entityDictionary['ProductAttributeMetadataFlagsClass'] = ProductAttributeMetadataFlagsClass;
    
      /**
      * UnitOfMeasureConversion entity class.
      */
      export class UnitOfMeasureConversionClass implements Entities.UnitOfMeasureConversion {
      public ItemId: string;
      public FromUnitOfMeasureId: string;
      public ToUnitOfMeasureId: string;
      public FromUnitOfMeasureSymbol: string;
      public ToUnitOfMeasureSymbol: string;
      public IsBackward: boolean;
      public RecordId: number;
      public ProductRecordId: number;
      public Factor: number;
      public Numerator: number;
      public Denominator: number;
      public InnerOffset: number;
      public OuterOffset: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ItemId = 
                odataObject.ItemId;
              
            this.FromUnitOfMeasureId = 
                odataObject.FromUnitOfMeasureId;
              
            this.ToUnitOfMeasureId = 
                odataObject.ToUnitOfMeasureId;
              
            this.FromUnitOfMeasureSymbol = 
                odataObject.FromUnitOfMeasureSymbol;
              
            this.ToUnitOfMeasureSymbol = 
                odataObject.ToUnitOfMeasureSymbol;
              
            this.IsBackward = 
                odataObject.IsBackward;
              
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.ProductRecordId = 
                (odataObject.ProductRecordId != null) ? parseInt(odataObject.ProductRecordId, 10) : undefined;
              
            this.Factor = 
                (odataObject.Factor != null) ? parseFloat(odataObject.Factor) : undefined;
              
            this.Numerator = 
                odataObject.Numerator;
              
            this.Denominator = 
                odataObject.Denominator;
              
            this.InnerOffset = 
                (odataObject.InnerOffset != null) ? parseFloat(odataObject.InnerOffset) : undefined;
              
            this.OuterOffset = 
                (odataObject.OuterOffset != null) ? parseFloat(odataObject.OuterOffset) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['UnitOfMeasureConversionClass'] = UnitOfMeasureConversionClass;
    
      /**
      * ReturnLabelContent entity class.
      */
      export class ReturnLabelContentClass implements Entities.ReturnLabelContent {
      public ReturnLocationText: string;
      public ReturnWarehouseText: string;
      public ReturnReasonText: string;
      public ReturnPalleteText: string;
      public ItemId: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ReturnLocationText = 
                odataObject.ReturnLocationText;
              
            this.ReturnWarehouseText = 
                odataObject.ReturnWarehouseText;
              
            this.ReturnReasonText = 
                odataObject.ReturnReasonText;
              
            this.ReturnPalleteText = 
                odataObject.ReturnPalleteText;
              
            this.ItemId = 
                odataObject.ItemId;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ReturnLabelContentClass'] = ReturnLabelContentClass;
    
      /**
      * LoyaltyRewardPointLine entity class.
      */
      export class LoyaltyRewardPointLineClass implements Entities.LoyaltyRewardPointLine {
      public TransactionId: string;
      public SaleLineNumber: number;
      public LoyaltyGroupRecordId: number;
      public LoyaltyCardNumber: string;
      public CustomerAccount: string;
      public EntryDate: Date;
      public EntryTime: number;
      public EntryTypeValue: number;
      public ExpirationDate: Date;
      public LineNumber: number;
      public LoyaltyTierRecordId: number;
      public RewardPointRecordId: number;
      public RewardPointAmountQuantity: number;
      public RewardPointId: string;
      public RewardPointIsRedeemable: boolean;
      public RewardPointType: string;
      public RewardPointCurrency: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.TransactionId = 
                odataObject.TransactionId;
              
            this.SaleLineNumber = 
                (odataObject.SaleLineNumber != null) ? parseFloat(odataObject.SaleLineNumber) : undefined;
              
            this.LoyaltyGroupRecordId = 
                (odataObject.LoyaltyGroupRecordId != null) ? parseInt(odataObject.LoyaltyGroupRecordId, 10) : undefined;
              
            this.LoyaltyCardNumber = 
                odataObject.LoyaltyCardNumber;
              
            this.CustomerAccount = 
                odataObject.CustomerAccount;
              
            this.EntryDate = 
                (odataObject.EntryDate instanceof Date) ? odataObject.EntryDate
                : (odataObject.EntryDate != null) ? jsonLightReadStringPropertyValue(odataObject.EntryDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.EntryTime = 
                odataObject.EntryTime;
              
            this.EntryTypeValue = 
                odataObject.EntryTypeValue;
              
            this.ExpirationDate = 
                (odataObject.ExpirationDate instanceof Date) ? odataObject.ExpirationDate
                : (odataObject.ExpirationDate != null) ? jsonLightReadStringPropertyValue(odataObject.ExpirationDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.LineNumber = 
                (odataObject.LineNumber != null) ? parseFloat(odataObject.LineNumber) : undefined;
              
            this.LoyaltyTierRecordId = 
                (odataObject.LoyaltyTierRecordId != null) ? parseInt(odataObject.LoyaltyTierRecordId, 10) : undefined;
              
            this.RewardPointRecordId = 
                (odataObject.RewardPointRecordId != null) ? parseInt(odataObject.RewardPointRecordId, 10) : undefined;
              
            this.RewardPointAmountQuantity = 
                (odataObject.RewardPointAmountQuantity != null) ? parseFloat(odataObject.RewardPointAmountQuantity) : undefined;
              
            this.RewardPointId = 
                odataObject.RewardPointId;
              
            this.RewardPointIsRedeemable = 
                odataObject.RewardPointIsRedeemable;
              
            this.RewardPointType = 
                odataObject.RewardPointType;
              
            this.RewardPointCurrency = 
                odataObject.RewardPointCurrency;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['LoyaltyRewardPointLineClass'] = LoyaltyRewardPointLineClass;
    
      /**
      * TrackingInfo entity class.
      */
      export class TrackingInfoClass implements Entities.TrackingInfo {
      public TrackingNumber: string;
      public ServiceType: string;
      public PackageWeight: Entities.Weight;
      public ShippedOnDate: Date;
      public Status: string;
      public DeliveredOnDate: Date;
      public EstimatedDeliveryDate: Date;
      public TrackingUrl: string;
      public PackagingType: string;
      public DestinationAddress: Entities.Address;
      public OriginAddress: Entities.Address;
      public ShipmentProgress: Entities.ShipmentProgress[];
      public ShippingCharge: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.TrackingNumber = 
                odataObject.TrackingNumber;
              
            this.ServiceType = 
                odataObject.ServiceType;
              
        if (odataObject.PackageWeight == null) {
            this.PackageWeight = undefined;
        } else if (odataObject.PackageWeight['@odata.type'] == null) {
            this.PackageWeight = new WeightClass(odataObject.PackageWeight);
        } else {
            var className: string = odataObject.PackageWeight['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.PackageWeight = new entityDictionary[className](odataObject.PackageWeight)
            }
        }

      
            this.ShippedOnDate = 
                (odataObject.ShippedOnDate instanceof Date) ? odataObject.ShippedOnDate
                : (odataObject.ShippedOnDate != null) ? jsonLightReadStringPropertyValue(odataObject.ShippedOnDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.Status = 
                odataObject.Status;
              
            this.DeliveredOnDate = 
                (odataObject.DeliveredOnDate instanceof Date) ? odataObject.DeliveredOnDate
                : (odataObject.DeliveredOnDate != null) ? jsonLightReadStringPropertyValue(odataObject.DeliveredOnDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.EstimatedDeliveryDate = 
                (odataObject.EstimatedDeliveryDate instanceof Date) ? odataObject.EstimatedDeliveryDate
                : (odataObject.EstimatedDeliveryDate != null) ? jsonLightReadStringPropertyValue(odataObject.EstimatedDeliveryDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.TrackingUrl = 
                odataObject.TrackingUrl;
              
            this.PackagingType = 
                odataObject.PackagingType;
              
        if (odataObject.DestinationAddress == null) {
            this.DestinationAddress = undefined;
        } else if (odataObject.DestinationAddress['@odata.type'] == null) {
            this.DestinationAddress = new AddressClass(odataObject.DestinationAddress);
        } else {
            var className: string = odataObject.DestinationAddress['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.DestinationAddress = new entityDictionary[className](odataObject.DestinationAddress)
            }
        }

      
        if (odataObject.OriginAddress == null) {
            this.OriginAddress = undefined;
        } else if (odataObject.OriginAddress['@odata.type'] == null) {
            this.OriginAddress = new AddressClass(odataObject.OriginAddress);
        } else {
            var className: string = odataObject.OriginAddress['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.OriginAddress = new entityDictionary[className](odataObject.OriginAddress)
            }
        }

      
        this.ShipmentProgress = undefined;
        if (odataObject.ShipmentProgress) {
        this.ShipmentProgress = [];
        for (var i = 0; i < odataObject.ShipmentProgress.length; i++) {
        if (odataObject.ShipmentProgress[i] != null) {
        if (odataObject.ShipmentProgress[i]['@odata.type'] != null) {
          var className: string = odataObject.ShipmentProgress[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ShipmentProgress[i] = new entityDictionary[className](odataObject.ShipmentProgress[i])
          }
        } else {
            this.ShipmentProgress[i] = new ShipmentProgressClass(odataObject.ShipmentProgress[i]);
        }
                    } else {
        this.ShipmentProgress[i] = undefined;
        }
        }
        }
      
            this.ShippingCharge = 
                (odataObject.ShippingCharge != null) ? parseFloat(odataObject.ShippingCharge) : undefined;
              
      }
      }
      entityDictionary['TrackingInfoClass'] = TrackingInfoClass;
    
      /**
      * Weight entity class.
      */
      export class WeightClass implements Entities.Weight {
      public Value: number;
      public Unit: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Value = 
                (odataObject.Value != null) ? parseFloat(odataObject.Value) : undefined;
              
            this.Unit = 
                odataObject.Unit;
              
      }
      }
      entityDictionary['WeightClass'] = WeightClass;
    
      /**
      * ShipmentProgress entity class.
      */
      export class ShipmentProgressClass implements Entities.ShipmentProgress {
      public Location: Entities.Address;
      public DateTime: Date;
      public Activity: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        if (odataObject.Location == null) {
            this.Location = undefined;
        } else if (odataObject.Location['@odata.type'] == null) {
            this.Location = new AddressClass(odataObject.Location);
        } else {
            var className: string = odataObject.Location['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.Location = new entityDictionary[className](odataObject.Location)
            }
        }

      
            this.DateTime = 
                (odataObject.DateTime instanceof Date) ? odataObject.DateTime
                : (odataObject.DateTime != null) ? jsonLightReadStringPropertyValue(odataObject.DateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.Activity = 
                odataObject.Activity;
              
      }
      }
      entityDictionary['ShipmentProgressClass'] = ShipmentProgressClass;
    
      /**
      * ShiftKey entity class.
      */
      export class ShiftKeyClass implements Entities.ShiftKey {
      public ShiftTerminalId: string;
      public ShiftId: number;
      public Store: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ShiftTerminalId = 
                odataObject.ShiftTerminalId;
              
            this.ShiftId = 
                (odataObject.ShiftId != null) ? parseInt(odataObject.ShiftId, 10) : undefined;
              
            this.Store = 
                odataObject.Store;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ShiftKeyClass'] = ShiftKeyClass;
    
      /**
      * LocalizedText entity class.
      */
      export class LocalizedTextClass implements Entities.LocalizedText {
      public RecordId: number;
      public LanguageId: string;
      public TextId: string;
      public Text: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.LanguageId = 
                odataObject.LanguageId;
              
            this.TextId = 
                odataObject.TextId;
              
            this.Text = 
                odataObject.Text;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['LocalizedTextClass'] = LocalizedTextClass;
    
      /**
      * AddressFormattingInfo entity class.
      */
      export class AddressFormattingInfoClass implements Entities.AddressFormattingInfo {
      public AddressComponentNameValue: number;
      public LineNumber: number;
      public IsDataEntryOnly: boolean;
      public NewLine: boolean;
      public Expand: boolean;
      public Inactive: boolean;
      public Special: boolean;
      public NumberOfSpaces: number;
      public Separator: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.AddressComponentNameValue = 
                odataObject.AddressComponentNameValue;
              
            this.LineNumber = 
                odataObject.LineNumber;
              
            this.IsDataEntryOnly = 
                odataObject.IsDataEntryOnly;
              
            this.NewLine = 
                odataObject.NewLine;
              
            this.Expand = 
                odataObject.Expand;
              
            this.Inactive = 
                odataObject.Inactive;
              
            this.Special = 
                odataObject.Special;
              
            this.NumberOfSpaces = 
                odataObject.NumberOfSpaces;
              
            this.Separator = 
                odataObject.Separator;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['AddressFormattingInfoClass'] = AddressFormattingInfoClass;
    
      /**
      * HardwareConfigurations entity class.
      */
      export class HardwareConfigurationsClass implements Entities.HardwareConfigurations {
      public CashDrawerConfigurations: Entities.HardwareConfiguration[];
      public PrinterConfigurations: Entities.HardwareConfiguration[];
      public PinPadConfiguration: Entities.HardwareConfiguration;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.CashDrawerConfigurations = undefined;
        if (odataObject.CashDrawerConfigurations) {
        this.CashDrawerConfigurations = [];
        for (var i = 0; i < odataObject.CashDrawerConfigurations.length; i++) {
        if (odataObject.CashDrawerConfigurations[i] != null) {
        if (odataObject.CashDrawerConfigurations[i]['@odata.type'] != null) {
          var className: string = odataObject.CashDrawerConfigurations[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.CashDrawerConfigurations[i] = new entityDictionary[className](odataObject.CashDrawerConfigurations[i])
          }
        } else {
            this.CashDrawerConfigurations[i] = new HardwareConfigurationClass(odataObject.CashDrawerConfigurations[i]);
        }
                    } else {
        this.CashDrawerConfigurations[i] = undefined;
        }
        }
        }
      
        this.PrinterConfigurations = undefined;
        if (odataObject.PrinterConfigurations) {
        this.PrinterConfigurations = [];
        for (var i = 0; i < odataObject.PrinterConfigurations.length; i++) {
        if (odataObject.PrinterConfigurations[i] != null) {
        if (odataObject.PrinterConfigurations[i]['@odata.type'] != null) {
          var className: string = odataObject.PrinterConfigurations[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.PrinterConfigurations[i] = new entityDictionary[className](odataObject.PrinterConfigurations[i])
          }
        } else {
            this.PrinterConfigurations[i] = new HardwareConfigurationClass(odataObject.PrinterConfigurations[i]);
        }
                    } else {
        this.PrinterConfigurations[i] = undefined;
        }
        }
        }
      
        if (odataObject.PinPadConfiguration == null) {
            this.PinPadConfiguration = undefined;
        } else if (odataObject.PinPadConfiguration['@odata.type'] == null) {
            this.PinPadConfiguration = new HardwareConfigurationClass(odataObject.PinPadConfiguration);
        } else {
            var className: string = odataObject.PinPadConfiguration['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.PinPadConfiguration = new entityDictionary[className](odataObject.PinPadConfiguration)
            }
        }

      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['HardwareConfigurationsClass'] = HardwareConfigurationsClass;
    
      /**
      * HardwareConfiguration entity class.
      */
      export class HardwareConfigurationClass implements Entities.HardwareConfiguration {
      public RecordId: number;
      public HardwareStationRecordId: number;
      public TerminalId: string;
      public DeviceName: string;
      public IPAddress: string;
      public Port: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.HardwareStationRecordId = 
                (odataObject.HardwareStationRecordId != null) ? parseInt(odataObject.HardwareStationRecordId, 10) : undefined;
              
            this.TerminalId = 
                odataObject.TerminalId;
              
            this.DeviceName = 
                odataObject.DeviceName;
              
            this.IPAddress = 
                odataObject.IPAddress;
              
            this.Port = 
                odataObject.Port;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['HardwareConfigurationClass'] = HardwareConfigurationClass;
    
      /**
      * Printer entity class.
      */
      export class PrinterClass implements Entities.Printer {
      public Name: string;
      public PrinterType: number;
      public Terminal: number;
      public PrintBehaviorValue: number;
      public ReceiptLayoutId: string;
      public HardwareProfileId: string;
      public ReceiptType: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Name = 
                odataObject.Name;
              
            this.PrinterType = 
                odataObject.PrinterType;
              
            this.Terminal = 
                (odataObject.Terminal != null) ? parseInt(odataObject.Terminal, 10) : undefined;
              
            this.PrintBehaviorValue = 
                odataObject.PrintBehaviorValue;
              
            this.ReceiptLayoutId = 
                odataObject.ReceiptLayoutId;
              
            this.HardwareProfileId = 
                odataObject.HardwareProfileId;
              
            this.ReceiptType = 
                odataObject.ReceiptType;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['PrinterClass'] = PrinterClass;
    
      /**
      * ProductQuantityLimitRule entity class.
      */
      export class ProductQuantityLimitRuleClass implements Entities.ProductQuantityLimitRule {
      public DefaultQuantity: number;
      public MaximumQuantity: number;
      public MinimumQuantity: number;
      public MultipleOfQuantity: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.DefaultQuantity = 
                (odataObject.DefaultQuantity != null) ? parseFloat(odataObject.DefaultQuantity) : undefined;
              
            this.MaximumQuantity = 
                (odataObject.MaximumQuantity != null) ? parseFloat(odataObject.MaximumQuantity) : undefined;
              
            this.MinimumQuantity = 
                (odataObject.MinimumQuantity != null) ? parseFloat(odataObject.MinimumQuantity) : undefined;
              
            this.MultipleOfQuantity = 
                (odataObject.MultipleOfQuantity != null) ? parseFloat(odataObject.MultipleOfQuantity) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ProductQuantityLimitRuleClass'] = ProductQuantityLimitRuleClass;
    
      /**
      * CustomerRefinerValue entity class.
      */
      export class CustomerRefinerValueClass
        extends SearchRefinerValueClass
       implements Entities.CustomerRefinerValue {
      public RefinerSourceValue: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.RefinerSourceValue = 
                odataObject.RefinerSourceValue;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.CustomerRefinerValue";
      
      }
      }
      entityDictionary['CustomerRefinerValueClass'] = CustomerRefinerValueClass;
    
      /**
      * TransferOrderJournalLine entity class.
      */
      export class TransferOrderJournalLineClass implements Entities.TransferOrderJournalLine {
      public ItemId: string;
      public ItemName: string;
      public InventDimId: string;
      public TotalQuantityShipped: number;
      public QuantityShipped: number;
      public TransferQuantity: number;
      public UnitId: string;
      public ConfigId: string;
      public InventSizeId: string;
      public InventColorId: string;
      public InventStyleId: string;
      public ConfigValue: string;
      public SizeValue: string;
      public ColorValue: string;
      public StyleValue: string;
      public DeliveryMethod: string;
      public Barcode: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ItemId = 
                odataObject.ItemId;
              
            this.ItemName = 
                odataObject.ItemName;
              
            this.InventDimId = 
                odataObject.InventDimId;
              
            this.TotalQuantityShipped = 
                (odataObject.TotalQuantityShipped != null) ? parseFloat(odataObject.TotalQuantityShipped) : undefined;
              
            this.QuantityShipped = 
                (odataObject.QuantityShipped != null) ? parseFloat(odataObject.QuantityShipped) : undefined;
              
            this.TransferQuantity = 
                (odataObject.TransferQuantity != null) ? parseFloat(odataObject.TransferQuantity) : undefined;
              
            this.UnitId = 
                odataObject.UnitId;
              
            this.ConfigId = 
                odataObject.ConfigId;
              
            this.InventSizeId = 
                odataObject.InventSizeId;
              
            this.InventColorId = 
                odataObject.InventColorId;
              
            this.InventStyleId = 
                odataObject.InventStyleId;
              
            this.ConfigValue = 
                odataObject.ConfigValue;
              
            this.SizeValue = 
                odataObject.SizeValue;
              
            this.ColorValue = 
                odataObject.ColorValue;
              
            this.StyleValue = 
                odataObject.StyleValue;
              
            this.DeliveryMethod = 
                odataObject.DeliveryMethod;
              
            this.Barcode = 
                odataObject.Barcode;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['TransferOrderJournalLineClass'] = TransferOrderJournalLineClass;
    
      /**
      * InventoryDocumentSourceSerialNumberLine entity class.
      */
      export class InventoryDocumentSourceSerialNumberLineClass implements Entities.InventoryDocumentSourceSerialNumberLine {
      public SourceDocumentTypeValue: number;
      public SourceDocumentId: string;
      public DocumentLineRecordId: number;
      public InventTransRecordId: number;
      public LineId: string;
      public Quantity: number;
      public SerialNumber: string;
      public StatusValue: number;
      public ProductId: number;
      public DataAreaId: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.SourceDocumentTypeValue = 
                odataObject.SourceDocumentTypeValue;
              
            this.SourceDocumentId = 
                odataObject.SourceDocumentId;
              
            this.DocumentLineRecordId = 
                (odataObject.DocumentLineRecordId != null) ? parseInt(odataObject.DocumentLineRecordId, 10) : undefined;
              
            this.InventTransRecordId = 
                (odataObject.InventTransRecordId != null) ? parseInt(odataObject.InventTransRecordId, 10) : undefined;
              
            this.LineId = 
                odataObject.LineId;
              
            this.Quantity = 
                (odataObject.Quantity != null) ? parseFloat(odataObject.Quantity) : undefined;
              
            this.SerialNumber = 
                odataObject.SerialNumber;
              
            this.StatusValue = 
                odataObject.StatusValue;
              
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
            this.DataAreaId = 
                odataObject.DataAreaId;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['InventoryDocumentSourceSerialNumberLineClass'] = InventoryDocumentSourceSerialNumberLineClass;
    
      /**
      * InventoryDocumentSourceTrackingDimensionLine entity class.
      */
      export class InventoryDocumentSourceTrackingDimensionLineClass implements Entities.InventoryDocumentSourceTrackingDimensionLine {
      public SourceDocumentTypeValue: number;
      public SourceDocumentId: string;
      public DocumentLineRecordId: number;
      public InventTransRecordId: number;
      public LineId: string;
      public Quantity: number;
      public SerialNumber: string;
      public BatchNumber: string;
      public StatusValue: number;
      public ProductId: number;
      public DataAreaId: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.SourceDocumentTypeValue = 
                odataObject.SourceDocumentTypeValue;
              
            this.SourceDocumentId = 
                odataObject.SourceDocumentId;
              
            this.DocumentLineRecordId = 
                (odataObject.DocumentLineRecordId != null) ? parseInt(odataObject.DocumentLineRecordId, 10) : undefined;
              
            this.InventTransRecordId = 
                (odataObject.InventTransRecordId != null) ? parseInt(odataObject.InventTransRecordId, 10) : undefined;
              
            this.LineId = 
                odataObject.LineId;
              
            this.Quantity = 
                (odataObject.Quantity != null) ? parseFloat(odataObject.Quantity) : undefined;
              
            this.SerialNumber = 
                odataObject.SerialNumber;
              
            this.BatchNumber = 
                odataObject.BatchNumber;
              
            this.StatusValue = 
                odataObject.StatusValue;
              
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
            this.DataAreaId = 
                odataObject.DataAreaId;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['InventoryDocumentSourceTrackingDimensionLineClass'] = InventoryDocumentSourceTrackingDimensionLineClass;
    
      /**
      * InventoryDocumentTrackingDimensionLine entity class.
      */
      export class InventoryDocumentTrackingDimensionLineClass implements Entities.InventoryDocumentTrackingDimensionLine {
      public WorkDocumentId: string;
      public WorkDocumentLineId: string;
      public SourceLineId: string;
      public LineId: string;
      public Quantity: number;
      public SerialNumber: string;
      public BatchNumber: string;
      public StatusValue: number;
      public ProductId: number;
      public ModifiedDateTime: Date;
      public SourceDocumentRecordId: number;
      public SourceDocumentTypeValue: number;
      public SourceDocumentLineRecordId: number;
      public SourceLine: Entities.InventoryDocumentSourceTrackingDimensionLine;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.WorkDocumentId = 
                odataObject.WorkDocumentId;
              
            this.WorkDocumentLineId = 
                odataObject.WorkDocumentLineId;
              
            this.SourceLineId = 
                odataObject.SourceLineId;
              
            this.LineId = 
                odataObject.LineId;
              
            this.Quantity = 
                (odataObject.Quantity != null) ? parseFloat(odataObject.Quantity) : undefined;
              
            this.SerialNumber = 
                odataObject.SerialNumber;
              
            this.BatchNumber = 
                odataObject.BatchNumber;
              
            this.StatusValue = 
                odataObject.StatusValue;
              
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
            this.ModifiedDateTime = 
                (odataObject.ModifiedDateTime instanceof Date) ? odataObject.ModifiedDateTime
                : (odataObject.ModifiedDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.ModifiedDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.SourceDocumentRecordId = 
                (odataObject.SourceDocumentRecordId != null) ? parseInt(odataObject.SourceDocumentRecordId, 10) : undefined;
              
            this.SourceDocumentTypeValue = 
                odataObject.SourceDocumentTypeValue;
              
            this.SourceDocumentLineRecordId = 
                (odataObject.SourceDocumentLineRecordId != null) ? parseInt(odataObject.SourceDocumentLineRecordId, 10) : undefined;
              
        if (odataObject.SourceLine == null) {
            this.SourceLine = undefined;
        } else if (odataObject.SourceLine['@odata.type'] == null) {
            this.SourceLine = new InventoryDocumentSourceTrackingDimensionLineClass(odataObject.SourceLine);
        } else {
            var className: string = odataObject.SourceLine['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.SourceLine = new entityDictionary[className](odataObject.SourceLine)
            }
        }

      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['InventoryDocumentTrackingDimensionLineClass'] = InventoryDocumentTrackingDimensionLineClass;
    
      /**
      * KitTransactionLine entity class.
      */
      export class KitTransactionLineClass implements Entities.KitTransactionLine {
      public ItemId: string;
      public Quantity: number;
      public InventoryDimensionId: string;
      public ProductId: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ItemId = 
                odataObject.ItemId;
              
            this.Quantity = 
                (odataObject.Quantity != null) ? parseFloat(odataObject.Quantity) : undefined;
              
            this.InventoryDimensionId = 
                odataObject.InventoryDimensionId;
              
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['KitTransactionLineClass'] = KitTransactionLineClass;
    
      /**
      * StoreAddressBook entity class.
      */
      export class StoreAddressBookClass implements Entities.StoreAddressBook {
      public AddressBookId: number;
      public StoreId: number;
      public AddressBookTypeValue: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.AddressBookId = 
                (odataObject.AddressBookId != null) ? parseInt(odataObject.AddressBookId, 10) : undefined;
              
            this.StoreId = 
                (odataObject.StoreId != null) ? parseInt(odataObject.StoreId, 10) : undefined;
              
            this.AddressBookTypeValue = 
                odataObject.AddressBookTypeValue;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['StoreAddressBookClass'] = StoreAddressBookClass;
    
      /**
      * TenderDetail entity class.
      */
      export class TenderDetailClass implements Entities.TenderDetail {
      public LineNumber: number;
      public Amount: number;
      public AmountInForeignCurrency: number;
      public ForeignCurrencyExchangeRate: number;
      public ForeignCurrency: string;
      public BankBagNumber: string;
      public TenderTypeId: string;
      public TenderRecount: number;
      public DenominationDetails: Entities.DenominationDetail[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.LineNumber = 
                (odataObject.LineNumber != null) ? parseFloat(odataObject.LineNumber) : undefined;
              
            this.Amount = 
                (odataObject.Amount != null) ? parseFloat(odataObject.Amount) : undefined;
              
            this.AmountInForeignCurrency = 
                (odataObject.AmountInForeignCurrency != null) ? parseFloat(odataObject.AmountInForeignCurrency) : undefined;
              
            this.ForeignCurrencyExchangeRate = 
                (odataObject.ForeignCurrencyExchangeRate != null) ? parseFloat(odataObject.ForeignCurrencyExchangeRate) : undefined;
              
            this.ForeignCurrency = 
                odataObject.ForeignCurrency;
              
            this.BankBagNumber = 
                odataObject.BankBagNumber;
              
            this.TenderTypeId = 
                odataObject.TenderTypeId;
              
            this.TenderRecount = 
                odataObject.TenderRecount;
              
        this.DenominationDetails = undefined;
        if (odataObject.DenominationDetails) {
        this.DenominationDetails = [];
        for (var i = 0; i < odataObject.DenominationDetails.length; i++) {
        if (odataObject.DenominationDetails[i] != null) {
        if (odataObject.DenominationDetails[i]['@odata.type'] != null) {
          var className: string = odataObject.DenominationDetails[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.DenominationDetails[i] = new entityDictionary[className](odataObject.DenominationDetails[i])
          }
        } else {
            this.DenominationDetails[i] = new DenominationDetailClass(odataObject.DenominationDetails[i]);
        }
                    } else {
        this.DenominationDetails[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['TenderDetailClass'] = TenderDetailClass;
    
      /**
      * DenominationDetail entity class.
      */
      export class DenominationDetailClass implements Entities.DenominationDetail {
      public Type: number;
      public Currency: string;
      public DenominationAmount: number;
      public QuantityDeclared: number;
      public AmountDeclared: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Type = 
                odataObject.Type;
              
            this.Currency = 
                odataObject.Currency;
              
            this.DenominationAmount = 
                (odataObject.DenominationAmount != null) ? parseFloat(odataObject.DenominationAmount) : undefined;
              
            this.QuantityDeclared = 
                odataObject.QuantityDeclared;
              
            this.AmountDeclared = 
                (odataObject.AmountDeclared != null) ? parseFloat(odataObject.AmountDeclared) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['DenominationDetailClass'] = DenominationDetailClass;
    
      /**
      * SalesOrderLookup entity class.
      */
      export class SalesOrderLookupClass implements Entities.SalesOrderLookup {
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['SalesOrderLookupClass'] = SalesOrderLookupClass;
    
      /**
      * SalesOrderLookupByTransactionId entity class.
      */
      export class SalesOrderLookupByTransactionIdClass
        extends SalesOrderLookupClass
       implements Entities.SalesOrderLookupByTransactionId {
      public TransactionId: string;
      public ChannelId: number;
      public StoreId: string;
      public TerminalId: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.TransactionId = 
                odataObject.TransactionId;
              
            this.ChannelId = 
                (odataObject.ChannelId != null) ? parseInt(odataObject.ChannelId, 10) : undefined;
              
            this.StoreId = 
                odataObject.StoreId;
              
            this.TerminalId = 
                odataObject.TerminalId;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.SalesOrderLookupByTransactionId";
      
      }
      }
      entityDictionary['SalesOrderLookupByTransactionIdClass'] = SalesOrderLookupByTransactionIdClass;
    
      /**
      * SalesOrderLookupBySalesId entity class.
      */
      export class SalesOrderLookupBySalesIdClass
        extends SalesOrderLookupClass
       implements Entities.SalesOrderLookupBySalesId {
      public SalesId: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.SalesId = 
                odataObject.SalesId;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.SalesOrderLookupBySalesId";
      
      }
      }
      entityDictionary['SalesOrderLookupBySalesIdClass'] = SalesOrderLookupBySalesIdClass;
    
      /**
      * ButtonGridZone entity class.
      */
      export class ButtonGridZoneClass implements Entities.ButtonGridZone {
      public ButtonGridId: string;
      public ZoneId: string;
      public ZoneName: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ButtonGridId = 
                odataObject.ButtonGridId;
              
            this.ZoneId = 
                odataObject.ZoneId;
              
            this.ZoneName = 
                odataObject.ZoneName;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ButtonGridZoneClass'] = ButtonGridZoneClass;
    
      /**
      * ImageZone entity class.
      */
      export class ImageZoneClass implements Entities.ImageZone {
      public PictureId: number;
      public PictureAsBase64: string;
      public ZoneId: string;
      public ZoneName: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.PictureId = 
                odataObject.PictureId;
              
            this.PictureAsBase64 = 
                odataObject.PictureAsBase64;
              
            this.ZoneId = 
                odataObject.ZoneId;
              
            this.ZoneName = 
                odataObject.ZoneName;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ImageZoneClass'] = ImageZoneClass;
    
      /**
      * ReportZone entity class.
      */
      export class ReportZoneClass implements Entities.ReportZone {
      public ReportRecordId: number;
      public ZoneId: string;
      public ZoneName: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ReportRecordId = 
                (odataObject.ReportRecordId != null) ? parseInt(odataObject.ReportRecordId, 10) : undefined;
              
            this.ZoneId = 
                odataObject.ZoneId;
              
            this.ZoneName = 
                odataObject.ZoneName;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ReportZoneClass'] = ReportZoneClass;
    
      /**
      * SalesInvoiceLine entity class.
      */
      export class SalesInvoiceLineClass implements Entities.SalesInvoiceLine {
      public RecordId: number;
      public ItemId: string;
      public ProductId: number;
      public ProductName: string;
      public InventDimensionId: string;
      public InventTransactionId: string;
      public Quantity: number;
      public Price: number;
      public DiscountPercent: number;
      public DiscountAmount: number;
      public BatchId: string;
      public NetAmount: number;
      public Site: string;
      public Warehouse: string;
      public SerialId: string;
      public ColorId: string;
      public SizeId: string;
      public StyleId: string;
      public ConfigId: string;
      public ColorName: string;
      public SizeName: string;
      public StyleName: string;
      public ConfigName: string;
      public SalesTaxGroup: string;
      public ItemTaxGroup: string;
      public SalesMarkup: number;
      public TotalDiscount: number;
      public TotalPercentageDiscount: number;
      public LineDiscount: number;
      public PeriodicDiscount: number;
      public PeriodicPercentageDiscount: number;
      public LineManualDiscountPercentage: number;
      public LineManualDiscountAmount: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.ItemId = 
                odataObject.ItemId;
              
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
            this.ProductName = 
                odataObject.ProductName;
              
            this.InventDimensionId = 
                odataObject.InventDimensionId;
              
            this.InventTransactionId = 
                odataObject.InventTransactionId;
              
            this.Quantity = 
                (odataObject.Quantity != null) ? parseFloat(odataObject.Quantity) : undefined;
              
            this.Price = 
                (odataObject.Price != null) ? parseFloat(odataObject.Price) : undefined;
              
            this.DiscountPercent = 
                (odataObject.DiscountPercent != null) ? parseFloat(odataObject.DiscountPercent) : undefined;
              
            this.DiscountAmount = 
                (odataObject.DiscountAmount != null) ? parseFloat(odataObject.DiscountAmount) : undefined;
              
            this.BatchId = 
                odataObject.BatchId;
              
            this.NetAmount = 
                (odataObject.NetAmount != null) ? parseFloat(odataObject.NetAmount) : undefined;
              
            this.Site = 
                odataObject.Site;
              
            this.Warehouse = 
                odataObject.Warehouse;
              
            this.SerialId = 
                odataObject.SerialId;
              
            this.ColorId = 
                odataObject.ColorId;
              
            this.SizeId = 
                odataObject.SizeId;
              
            this.StyleId = 
                odataObject.StyleId;
              
            this.ConfigId = 
                odataObject.ConfigId;
              
            this.ColorName = 
                odataObject.ColorName;
              
            this.SizeName = 
                odataObject.SizeName;
              
            this.StyleName = 
                odataObject.StyleName;
              
            this.ConfigName = 
                odataObject.ConfigName;
              
            this.SalesTaxGroup = 
                odataObject.SalesTaxGroup;
              
            this.ItemTaxGroup = 
                odataObject.ItemTaxGroup;
              
            this.SalesMarkup = 
                (odataObject.SalesMarkup != null) ? parseFloat(odataObject.SalesMarkup) : undefined;
              
            this.TotalDiscount = 
                (odataObject.TotalDiscount != null) ? parseFloat(odataObject.TotalDiscount) : undefined;
              
            this.TotalPercentageDiscount = 
                (odataObject.TotalPercentageDiscount != null) ? parseFloat(odataObject.TotalPercentageDiscount) : undefined;
              
            this.LineDiscount = 
                (odataObject.LineDiscount != null) ? parseFloat(odataObject.LineDiscount) : undefined;
              
            this.PeriodicDiscount = 
                (odataObject.PeriodicDiscount != null) ? parseFloat(odataObject.PeriodicDiscount) : undefined;
              
            this.PeriodicPercentageDiscount = 
                (odataObject.PeriodicPercentageDiscount != null) ? parseFloat(odataObject.PeriodicPercentageDiscount) : undefined;
              
            this.LineManualDiscountPercentage = 
                (odataObject.LineManualDiscountPercentage != null) ? parseFloat(odataObject.LineManualDiscountPercentage) : undefined;
              
            this.LineManualDiscountAmount = 
                (odataObject.LineManualDiscountAmount != null) ? parseFloat(odataObject.LineManualDiscountAmount) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['SalesInvoiceLineClass'] = SalesInvoiceLineClass;
    
      /**
      * ReportRow entity class.
      */
      export class ReportRowClass implements Entities.ReportRow {
      public RowData: Entities.CommerceProperty[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.RowData = undefined;
        if (odataObject.RowData) {
        this.RowData = [];
        for (var i = 0; i < odataObject.RowData.length; i++) {
        if (odataObject.RowData[i] != null) {
        if (odataObject.RowData[i]['@odata.type'] != null) {
          var className: string = odataObject.RowData[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.RowData[i] = new entityDictionary[className](odataObject.RowData[i])
          }
        } else {
            this.RowData[i] = new CommercePropertyClass(odataObject.RowData[i]);
        }
                    } else {
        this.RowData[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ReportRowClass'] = ReportRowClass;
    
      /**
      * FormAnnotation entity class.
      */
      export class FormAnnotationClass
        extends AnnotationClass
       implements Entities.FormAnnotation {
      public MenuItemLabel: string;
      public MenuItemName: string;
      public MenuItemType: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.MenuItemLabel = 
                odataObject.MenuItemLabel;
              
            this.MenuItemName = 
                odataObject.MenuItemName;
              
            this.MenuItemType = 
                odataObject.MenuItemType;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.FormAnnotation";
      
      }
      }
      entityDictionary['FormAnnotationClass'] = FormAnnotationClass;
    
      /**
      * TaskGuideAnnotation entity class.
      */
      export class TaskGuideAnnotationClass
        extends AnnotationClass
       implements Entities.TaskGuideAnnotation {
      public RecordingValueType: string;
      public Title: string;
      public ExampleText: string;
      public Hidden: boolean;
      public GlobalId: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.RecordingValueType = 
                odataObject.RecordingValueType;
              
            this.Title = 
                odataObject.Title;
              
            this.ExampleText = 
                odataObject.ExampleText;
              
            this.Hidden = 
                odataObject.Hidden;
              
            this.GlobalId = 
                odataObject.GlobalId;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.TaskGuideAnnotation";
      
      }
      }
      entityDictionary['TaskGuideAnnotationClass'] = TaskGuideAnnotationClass;
    
      /**
      * CommandArgument entity class.
      */
      export class CommandArgumentClass implements Entities.CommandArgument {
      public Value: string;
      public IsReference: boolean;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Value = 
                odataObject.Value;
              
            this.IsReference = 
                odataObject.IsReference;
              
      }
      }
      entityDictionary['CommandArgumentClass'] = CommandArgumentClass;
    
      /**
      * PropertyUserAction entity class.
      */
      export class PropertyUserActionClass
        extends FormUserActionClass
       implements Entities.PropertyUserAction {
      public Value: string;
      public ValueLabel: string;
      public PropertyName: string;
      public ControlName: string;
      public ControlType: string;
      public UserActionType: string;
      public IsReference: boolean;
      public LabelId: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.Value = 
                odataObject.Value;
              
            this.ValueLabel = 
                odataObject.ValueLabel;
              
            this.PropertyName = 
                odataObject.PropertyName;
              
            this.ControlName = 
                odataObject.ControlName;
              
            this.ControlType = 
                odataObject.ControlType;
              
            this.UserActionType = 
                odataObject.UserActionType;
              
            this.IsReference = 
                odataObject.IsReference;
              
            this.LabelId = 
                odataObject.LabelId;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.PropertyUserAction";
      
      }
      }
      entityDictionary['PropertyUserActionClass'] = PropertyUserActionClass;
    
      /**
      * InfoUserAction entity class.
      */
      export class InfoUserActionClass
        extends UserActionClass
       implements Entities.InfoUserAction {
      public FormId: string;
      public ControlLabel: string;
      public ControlLabelId: string;
      public ControlName: string;
      public Text: string;
      public Notes: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.FormId = 
                odataObject.FormId;
              
            this.ControlLabel = 
                odataObject.ControlLabel;
              
            this.ControlLabelId = 
                odataObject.ControlLabelId;
              
            this.ControlName = 
                odataObject.ControlName;
              
            this.Text = 
                odataObject.Text;
              
            this.Notes = 
                odataObject.Notes;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.InfoUserAction";
      
      }
      }
      entityDictionary['InfoUserActionClass'] = InfoUserActionClass;
    
      /**
      * MenuItemUserAction entity class.
      */
      export class MenuItemUserActionClass
        extends UserActionClass
       implements Entities.MenuItemUserAction {
      public MenuItemName: string;
      public MenuItemType: string;
      public NavigationPath: string[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.MenuItemName = 
                odataObject.MenuItemName;
              
            this.MenuItemType = 
                odataObject.MenuItemType;
              
        this.NavigationPath = undefined;
        if (odataObject.NavigationPath) {
        this.NavigationPath = [];
        for (var i = 0; i < odataObject.NavigationPath.length; i++) {
        if (odataObject.NavigationPath[i] != null) {
            this.NavigationPath[i] = 
                odataObject.NavigationPath[i];
                            } else {
        this.NavigationPath[i] = undefined;
        }
        }
        }
      
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.MenuItemUserAction";
      
      }
      }
      entityDictionary['MenuItemUserActionClass'] = MenuItemUserActionClass;
    
      /**
      * ValidationUserAction entity class.
      */
      export class ValidationUserActionClass
        extends UserActionClass
       implements Entities.ValidationUserAction {
      public Name: string;
      public Comment: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.Name = 
                odataObject.Name;
              
            this.Comment = 
                odataObject.Comment;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.ValidationUserAction";
      
      }
      }
      entityDictionary['ValidationUserActionClass'] = ValidationUserActionClass;
    
      /**
      * ArtifactSummary entity class.
      */
      export class ArtifactSummaryClass implements Entities.ArtifactSummary {
      public ArtifactType: string;
      public LanguageTags: string[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ArtifactType = 
                odataObject.ArtifactType;
              
        this.LanguageTags = undefined;
        if (odataObject.LanguageTags) {
        this.LanguageTags = [];
        for (var i = 0; i < odataObject.LanguageTags.length; i++) {
        if (odataObject.LanguageTags[i] != null) {
            this.LanguageTags[i] = 
                odataObject.LanguageTags[i];
                            } else {
        this.LanguageTags[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ArtifactSummaryClass'] = ArtifactSummaryClass;
    
      /**
      * MetadataPropertyValue entity class.
      */
      export class MetadataPropertyValueClass implements Entities.MetadataPropertyValue {
      public PropertyId: string;
      public SingleValue: string;
      public MultiValue: Entities.MetadataText[];
      public Tags: string[];
      public ComboBox: string;
      public MultiSelect: string[];
      public Links: Entities.MetadataHypertextLink[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.PropertyId = 
                odataObject.PropertyId;
              
            this.SingleValue = 
                odataObject.SingleValue;
              
        this.MultiValue = undefined;
        if (odataObject.MultiValue) {
        this.MultiValue = [];
        for (var i = 0; i < odataObject.MultiValue.length; i++) {
        if (odataObject.MultiValue[i] != null) {
        if (odataObject.MultiValue[i]['@odata.type'] != null) {
          var className: string = odataObject.MultiValue[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.MultiValue[i] = new entityDictionary[className](odataObject.MultiValue[i])
          }
        } else {
            this.MultiValue[i] = new MetadataTextClass(odataObject.MultiValue[i]);
        }
                    } else {
        this.MultiValue[i] = undefined;
        }
        }
        }
      
        this.Tags = undefined;
        if (odataObject.Tags) {
        this.Tags = [];
        for (var i = 0; i < odataObject.Tags.length; i++) {
        if (odataObject.Tags[i] != null) {
            this.Tags[i] = 
                odataObject.Tags[i];
                            } else {
        this.Tags[i] = undefined;
        }
        }
        }
      
            this.ComboBox = 
                odataObject.ComboBox;
              
        this.MultiSelect = undefined;
        if (odataObject.MultiSelect) {
        this.MultiSelect = [];
        for (var i = 0; i < odataObject.MultiSelect.length; i++) {
        if (odataObject.MultiSelect[i] != null) {
            this.MultiSelect[i] = 
                odataObject.MultiSelect[i];
                            } else {
        this.MultiSelect[i] = undefined;
        }
        }
        }
      
        this.Links = undefined;
        if (odataObject.Links) {
        this.Links = [];
        for (var i = 0; i < odataObject.Links.length; i++) {
        if (odataObject.Links[i] != null) {
        if (odataObject.Links[i]['@odata.type'] != null) {
          var className: string = odataObject.Links[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Links[i] = new entityDictionary[className](odataObject.Links[i])
          }
        } else {
            this.Links[i] = new MetadataHypertextLinkClass(odataObject.Links[i]);
        }
                    } else {
        this.Links[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['MetadataPropertyValueClass'] = MetadataPropertyValueClass;
    
      /**
      * FormContext entity class.
      */
      export class FormContextClass implements Entities.FormContext {
      public FormID: string;
      public FormName: string;
      public RecordingName: string;
      public HasMetadata: boolean;
      public Sequence: number;
      public Annotations: Entities.Annotation[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.FormID = 
                odataObject.FormID;
              
            this.FormName = 
                odataObject.FormName;
              
            this.RecordingName = 
                odataObject.RecordingName;
              
            this.HasMetadata = 
                odataObject.HasMetadata;
              
            this.Sequence = 
                odataObject.Sequence;
              
        this.Annotations = undefined;
        if (odataObject.Annotations) {
        this.Annotations = [];
        for (var i = 0; i < odataObject.Annotations.length; i++) {
        if (odataObject.Annotations[i] != null) {
        if (odataObject.Annotations[i]['@odata.type'] != null) {
          var className: string = odataObject.Annotations[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Annotations[i] = new entityDictionary[className](odataObject.Annotations[i])
          }
        } else {
            this.Annotations[i] = new AnnotationClass(odataObject.Annotations[i]);
        }
                    } else {
        this.Annotations[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['FormContextClass'] = FormContextClass;
    
      /**
      * ExtensibleFiscalRegistrationType entity class.
      */
      export class ExtensibleFiscalRegistrationTypeClass
        extends ExtensibleEnumerationClass
       implements Entities.ExtensibleFiscalRegistrationType {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.ExtensibleFiscalRegistrationType";
      
      }
      }
      entityDictionary['ExtensibleFiscalRegistrationTypeClass'] = ExtensibleFiscalRegistrationTypeClass;
    
      /**
      * FiscalTransactionTenderLineAdjustment entity class.
      */
      export class FiscalTransactionTenderLineAdjustmentClass implements Entities.FiscalTransactionTenderLineAdjustment {
      public TenderLineNumber: number;
      public AdjustmentAmount: number;
      public RecordGuid: string;
      public TransactionId: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.TenderLineNumber = 
                (odataObject.TenderLineNumber != null) ? parseFloat(odataObject.TenderLineNumber) : undefined;
              
            this.AdjustmentAmount = 
                (odataObject.AdjustmentAmount != null) ? parseFloat(odataObject.AdjustmentAmount) : undefined;
              
            this.RecordGuid = 
                odataObject.RecordGuid;
              
            this.TransactionId = 
                odataObject.TransactionId;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['FiscalTransactionTenderLineAdjustmentClass'] = FiscalTransactionTenderLineAdjustmentClass;
    
      /**
      * FiscalTransactionSalesLineAdjustment entity class.
      */
      export class FiscalTransactionSalesLineAdjustmentClass implements Entities.FiscalTransactionSalesLineAdjustment {
      public SalesLineNumber: number;
      public Exclude: boolean;
      public RecordGuid: string;
      public TransactionId: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.SalesLineNumber = 
                (odataObject.SalesLineNumber != null) ? parseFloat(odataObject.SalesLineNumber) : undefined;
              
            this.Exclude = 
                odataObject.Exclude;
              
            this.RecordGuid = 
                odataObject.RecordGuid;
              
            this.TransactionId = 
                odataObject.TransactionId;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['FiscalTransactionSalesLineAdjustmentClass'] = FiscalTransactionSalesLineAdjustmentClass;
    
      /**
      * DetailedOrderStatus entity class.
      */
      export class DetailedOrderStatusClass
        extends ExtensibleEnumerationClass
       implements Entities.DetailedOrderStatus {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.DetailedOrderStatus";
      
      }
      }
      entityDictionary['DetailedOrderStatusClass'] = DetailedOrderStatusClass;
    
      /**
      * LineValidationResult entity class.
      */
      export class LineValidationResultClass implements Entities.LineValidationResult {
      public LineId: string;
      public ValidationFailures: Entities.DataValidationFailure[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.LineId = 
                odataObject.LineId;
              
        this.ValidationFailures = undefined;
        if (odataObject.ValidationFailures) {
        this.ValidationFailures = [];
        for (var i = 0; i < odataObject.ValidationFailures.length; i++) {
        if (odataObject.ValidationFailures[i] != null) {
        if (odataObject.ValidationFailures[i]['@odata.type'] != null) {
          var className: string = odataObject.ValidationFailures[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ValidationFailures[i] = new entityDictionary[className](odataObject.ValidationFailures[i])
          }
        } else {
            this.ValidationFailures[i] = new DataValidationFailureClass(odataObject.ValidationFailures[i]);
        }
                    } else {
        this.ValidationFailures[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['LineValidationResultClass'] = LineValidationResultClass;
    
      /**
      * SalesAffiliationLoyaltyTier entity class.
      */
      export class SalesAffiliationLoyaltyTierClass
        extends AffiliationLoyaltyTierClass
       implements Entities.SalesAffiliationLoyaltyTier {
      public TransactionId: string;
      public ChannelId: number;
      public TerminalId: string;
      public ReceiptId: string;
      public StaffId: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.TransactionId = 
                odataObject.TransactionId;
              
            this.ChannelId = 
                (odataObject.ChannelId != null) ? parseInt(odataObject.ChannelId, 10) : undefined;
              
            this.TerminalId = 
                odataObject.TerminalId;
              
            this.ReceiptId = 
                odataObject.ReceiptId;
              
            this.StaffId = 
                odataObject.StaffId;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.SalesAffiliationLoyaltyTier";
      
      }
      }
      entityDictionary['SalesAffiliationLoyaltyTierClass'] = SalesAffiliationLoyaltyTierClass;
    
      /**
      * TaxViewLine entity class.
      */
      export class TaxViewLineClass implements Entities.TaxViewLine {
      public TaxId: string;
      public TaxAmount: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.TaxId = 
                odataObject.TaxId;
              
            this.TaxAmount = 
                (odataObject.TaxAmount != null) ? parseFloat(odataObject.TaxAmount) : undefined;
              
      }
      }
      entityDictionary['TaxViewLineClass'] = TaxViewLineClass;
    
      /**
      * Coupon entity class.
      */
      export class CouponClass implements Entities.Coupon {
      public CodeId: string;
      public Code: string;
      public DiscountOfferId: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.CodeId = 
                odataObject.CodeId;
              
            this.Code = 
                odataObject.Code;
              
            this.DiscountOfferId = 
                odataObject.DiscountOfferId;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CouponClass'] = CouponClass;
    
      /**
      * CustomerAccountDepositLine entity class.
      */
      export class CustomerAccountDepositLineClass implements Entities.CustomerAccountDepositLine {
      public Amount: number;
      public CustomerAccount: string;
      public Comment: string;
      public StoreNumber: string;
      public Terminal: string;
      public Shift: string;
      public TransactionStatusValue: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Amount = 
                (odataObject.Amount != null) ? parseFloat(odataObject.Amount) : undefined;
              
            this.CustomerAccount = 
                odataObject.CustomerAccount;
              
            this.Comment = 
                odataObject.Comment;
              
            this.StoreNumber = 
                odataObject.StoreNumber;
              
            this.Terminal = 
                odataObject.Terminal;
              
            this.Shift = 
                odataObject.Shift;
              
            this.TransactionStatusValue = 
                odataObject.TransactionStatusValue;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CustomerAccountDepositLineClass'] = CustomerAccountDepositLineClass;
    
      /**
      * Note entity class.
      */
      export class NoteClass implements Entities.Note {
      public Title: string;
      public Description: string;
      public StaffId: string;
      public RestrictionValue: number;
      public DocuRefRecId: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Title = 
                odataObject.Title;
              
            this.Description = 
                odataObject.Description;
              
            this.StaffId = 
                odataObject.StaffId;
              
            this.RestrictionValue = 
                odataObject.RestrictionValue;
              
            this.DocuRefRecId = 
                (odataObject.DocuRefRecId != null) ? parseInt(odataObject.DocuRefRecId, 10) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['NoteClass'] = NoteClass;
    
      /**
      * CustomerAttribute entity class.
      */
      export class CustomerAttributeClass implements Entities.CustomerAttribute {
      public AttributeValue: Entities.CommercePropertyValue;
      public Units: string;
      public RecordId: number;
      public Name: string;
      public KeyName: string;
      public DataTypeValue: number;
      public Attribute: number;
      public NameTranslations: Entities.TextValueTranslation[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        if (odataObject.AttributeValue == null) {
            this.AttributeValue = undefined;
        } else if (odataObject.AttributeValue['@odata.type'] == null) {
            this.AttributeValue = new CommercePropertyValueClass(odataObject.AttributeValue);
        } else {
            var className: string = odataObject.AttributeValue['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.AttributeValue = new entityDictionary[className](odataObject.AttributeValue)
            }
        }

      
            this.Units = 
                odataObject.Units;
              
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.Name = 
                odataObject.Name;
              
            this.KeyName = 
                odataObject.KeyName;
              
            this.DataTypeValue = 
                odataObject.DataTypeValue;
              
            this.Attribute = 
                (odataObject.Attribute != null) ? parseInt(odataObject.Attribute, 10) : undefined;
              
        this.NameTranslations = undefined;
        if (odataObject.NameTranslations) {
        this.NameTranslations = [];
        for (var i = 0; i < odataObject.NameTranslations.length; i++) {
        if (odataObject.NameTranslations[i] != null) {
        if (odataObject.NameTranslations[i]['@odata.type'] != null) {
          var className: string = odataObject.NameTranslations[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.NameTranslations[i] = new entityDictionary[className](odataObject.NameTranslations[i])
          }
        } else {
            this.NameTranslations[i] = new TextValueTranslationClass(odataObject.NameTranslations[i]);
        }
                    } else {
        this.NameTranslations[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CustomerAttributeClass'] = CustomerAttributeClass;
    
      /**
      * ContactInfo entity class.
      */
      export class ContactInfoClass implements Entities.ContactInfo {
      public AddressTypeValue: number;
      public RecordId: number;
      public PartyLocationRecordId: number;
      public PartyRecordId: number;
      public PartyNumber: string;
      public Description: string;
      public Value: string;
      public ValueExtension: string;
      public LogisticsLocationId: string;
      public LogisticsLocationRecordId: number;
      public ParentLocation: number;
      public IsPrimary: boolean;
      public IsMobilePhone: boolean;
      public IsPrivate: boolean;
      public IsOptedInMarketing: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.AddressTypeValue = 
                odataObject.AddressTypeValue;
              
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.PartyLocationRecordId = 
                (odataObject.PartyLocationRecordId != null) ? parseInt(odataObject.PartyLocationRecordId, 10) : undefined;
              
            this.PartyRecordId = 
                (odataObject.PartyRecordId != null) ? parseInt(odataObject.PartyRecordId, 10) : undefined;
              
            this.PartyNumber = 
                odataObject.PartyNumber;
              
            this.Description = 
                odataObject.Description;
              
            this.Value = 
                odataObject.Value;
              
            this.ValueExtension = 
                odataObject.ValueExtension;
              
            this.LogisticsLocationId = 
                odataObject.LogisticsLocationId;
              
            this.LogisticsLocationRecordId = 
                (odataObject.LogisticsLocationRecordId != null) ? parseInt(odataObject.LogisticsLocationRecordId, 10) : undefined;
              
            this.ParentLocation = 
                (odataObject.ParentLocation != null) ? parseInt(odataObject.ParentLocation, 10) : undefined;
              
            this.IsPrimary = 
                odataObject.IsPrimary;
              
            this.IsMobilePhone = 
                odataObject.IsMobilePhone;
              
            this.IsPrivate = 
                odataObject.IsPrivate;
              
            this.IsOptedInMarketing = 
                odataObject.IsOptedInMarketing;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ContactInfoClass'] = ContactInfoClass;
    
      /**
      * InventoryQuantities entity class.
      */
      export class InventoryQuantitiesClass implements Entities.InventoryQuantities {
      public UnitOfMeasure: string;
      public QuantityNotProcessed: number;
      public QuantityReserved: number;
      public QuantityPicked: number;
      public QuantityPacked: number;
      public QuantityInvoiced: number;
      public QuantityOrdered: number;
      public QuantityCanceled: number;
      public QuantityReturned: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.UnitOfMeasure = 
                odataObject.UnitOfMeasure;
              
            this.QuantityNotProcessed = 
                (odataObject.QuantityNotProcessed != null) ? parseFloat(odataObject.QuantityNotProcessed) : undefined;
              
            this.QuantityReserved = 
                (odataObject.QuantityReserved != null) ? parseFloat(odataObject.QuantityReserved) : undefined;
              
            this.QuantityPicked = 
                (odataObject.QuantityPicked != null) ? parseFloat(odataObject.QuantityPicked) : undefined;
              
            this.QuantityPacked = 
                (odataObject.QuantityPacked != null) ? parseFloat(odataObject.QuantityPacked) : undefined;
              
            this.QuantityInvoiced = 
                (odataObject.QuantityInvoiced != null) ? parseFloat(odataObject.QuantityInvoiced) : undefined;
              
            this.QuantityOrdered = 
                (odataObject.QuantityOrdered != null) ? parseFloat(odataObject.QuantityOrdered) : undefined;
              
            this.QuantityCanceled = 
                (odataObject.QuantityCanceled != null) ? parseFloat(odataObject.QuantityCanceled) : undefined;
              
            this.QuantityReturned = 
                (odataObject.QuantityReturned != null) ? parseFloat(odataObject.QuantityReturned) : undefined;
              
      }
      }
      entityDictionary['InventoryQuantitiesClass'] = InventoryQuantitiesClass;
    
      /**
      * DeliveryInformation entity class.
      */
      export class DeliveryInformationClass implements Entities.DeliveryInformation {
      public DeliveryMode: string;
      public DeliveryAddress: Entities.Address;
      public DeliveryDate: Date;
      public PickupTimeslotStartDateTime: Date;
      public PickupTimeslotEndDateTime: Date;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.DeliveryMode = 
                odataObject.DeliveryMode;
              
        if (odataObject.DeliveryAddress == null) {
            this.DeliveryAddress = undefined;
        } else if (odataObject.DeliveryAddress['@odata.type'] == null) {
            this.DeliveryAddress = new AddressClass(odataObject.DeliveryAddress);
        } else {
            var className: string = odataObject.DeliveryAddress['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.DeliveryAddress = new entityDictionary[className](odataObject.DeliveryAddress)
            }
        }

      
            this.DeliveryDate = 
                (odataObject.DeliveryDate instanceof Date) ? odataObject.DeliveryDate
                : (odataObject.DeliveryDate != null) ? jsonLightReadStringPropertyValue(odataObject.DeliveryDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.PickupTimeslotStartDateTime = 
                (odataObject.PickupTimeslotStartDateTime instanceof Date) ? odataObject.PickupTimeslotStartDateTime
                : (odataObject.PickupTimeslotStartDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.PickupTimeslotStartDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.PickupTimeslotEndDateTime = 
                (odataObject.PickupTimeslotEndDateTime instanceof Date) ? odataObject.PickupTimeslotEndDateTime
                : (odataObject.PickupTimeslotEndDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.PickupTimeslotEndDateTime, "Edm.DateTimeOffset", false) : undefined;
              
      }
      }
      entityDictionary['DeliveryInformationClass'] = DeliveryInformationClass;
    
      /**
      * ShiftTaxLine entity class.
      */
      export class ShiftTaxLineClass implements Entities.ShiftTaxLine {
      public TaxCode: string;
      public TaxAmount: number;
      public TaxRate: number;
      public NetAmount: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.TaxCode = 
                odataObject.TaxCode;
              
            this.TaxAmount = 
                (odataObject.TaxAmount != null) ? parseFloat(odataObject.TaxAmount) : undefined;
              
            this.TaxRate = 
                (odataObject.TaxRate != null) ? parseFloat(odataObject.TaxRate) : undefined;
              
            this.NetAmount = 
                (odataObject.NetAmount != null) ? parseFloat(odataObject.NetAmount) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ShiftTaxLineClass'] = ShiftTaxLineClass;
    
      /**
      * TaxRegistration entity class.
      */
      export class TaxRegistrationClass implements Entities.TaxRegistration {
      public RecordId: number;
      public Name: string;
      public CountryRegionId: string;
      public IsRegistrationNumberUnique: boolean;
      public IsPrimaryAddressRestricted: boolean;
      public TypeApplicabilityRuleRecordId: number;
      public TypeValue: number;
      public Description: string;
      public DirPartyLocationRecordId: number;
      public RegistrationNumber: string;
      public ValidFrom: Date;
      public ValidTo: Date;
      public Issuer: string;
      public IssueDate: Date;
      public Section: string;
      public DirectoryPartyRecordId: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.Name = 
                odataObject.Name;
              
            this.CountryRegionId = 
                odataObject.CountryRegionId;
              
            this.IsRegistrationNumberUnique = 
                odataObject.IsRegistrationNumberUnique;
              
            this.IsPrimaryAddressRestricted = 
                odataObject.IsPrimaryAddressRestricted;
              
            this.TypeApplicabilityRuleRecordId = 
                (odataObject.TypeApplicabilityRuleRecordId != null) ? parseInt(odataObject.TypeApplicabilityRuleRecordId, 10) : undefined;
              
            this.TypeValue = 
                odataObject.TypeValue;
              
            this.Description = 
                odataObject.Description;
              
            this.DirPartyLocationRecordId = 
                (odataObject.DirPartyLocationRecordId != null) ? parseInt(odataObject.DirPartyLocationRecordId, 10) : undefined;
              
            this.RegistrationNumber = 
                odataObject.RegistrationNumber;
              
            this.ValidFrom = 
                (odataObject.ValidFrom instanceof Date) ? odataObject.ValidFrom
                : (odataObject.ValidFrom != null) ? jsonLightReadStringPropertyValue(odataObject.ValidFrom, "Edm.DateTimeOffset", false) : undefined;
              
            this.ValidTo = 
                (odataObject.ValidTo instanceof Date) ? odataObject.ValidTo
                : (odataObject.ValidTo != null) ? jsonLightReadStringPropertyValue(odataObject.ValidTo, "Edm.DateTimeOffset", false) : undefined;
              
            this.Issuer = 
                odataObject.Issuer;
              
            this.IssueDate = 
                (odataObject.IssueDate instanceof Date) ? odataObject.IssueDate
                : (odataObject.IssueDate != null) ? jsonLightReadStringPropertyValue(odataObject.IssueDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.Section = 
                odataObject.Section;
              
            this.DirectoryPartyRecordId = 
                (odataObject.DirectoryPartyRecordId != null) ? parseInt(odataObject.DirectoryPartyRecordId, 10) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['TaxRegistrationClass'] = TaxRegistrationClass;
    
      /**
      * ChannelTaxRegistration entity class.
      */
      export class ChannelTaxRegistrationClass
        extends TaxRegistrationClass
       implements Entities.ChannelTaxRegistration {
      public ChannelId: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.ChannelId = 
                (odataObject.ChannelId != null) ? parseInt(odataObject.ChannelId, 10) : undefined;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.ChannelTaxRegistration";
      
      }
      }
      entityDictionary['ChannelTaxRegistrationClass'] = ChannelTaxRegistrationClass;
    
      /**
      * LinkedProduct entity class.
      */
      export class LinkedProductClass implements Entities.LinkedProduct {
      public ProductRecordId: number;
      public LinkedProductRecordId: number;
      public Quantity: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ProductRecordId = 
                (odataObject.ProductRecordId != null) ? parseInt(odataObject.ProductRecordId, 10) : undefined;
              
            this.LinkedProductRecordId = 
                (odataObject.LinkedProductRecordId != null) ? parseInt(odataObject.LinkedProductRecordId, 10) : undefined;
              
            this.Quantity = 
                (odataObject.Quantity != null) ? parseFloat(odataObject.Quantity) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['LinkedProductClass'] = LinkedProductClass;
    
      /**
      * DisplayOrderInCategory entity class.
      */
      export class DisplayOrderInCategoryClass implements Entities.DisplayOrderInCategory {
      public CategoryRecordId: number;
      public DisplayOrder: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.CategoryRecordId = 
                (odataObject.CategoryRecordId != null) ? parseInt(odataObject.CategoryRecordId, 10) : undefined;
              
            this.DisplayOrder = 
                (odataObject.DisplayOrder != null) ? parseFloat(odataObject.DisplayOrder) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['DisplayOrderInCategoryClass'] = DisplayOrderInCategoryClass;
    
      /**
      * RelatedProduct entity class.
      */
      export class RelatedProductClass implements Entities.RelatedProduct {
      public ProductRecordId: number;
      public RelatedProductRecordId: number;
      public CatalogId: number;
      public RelationName: string;
      public IsExcludedRelation: boolean;
      public IsDirectRelation: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ProductRecordId = 
                (odataObject.ProductRecordId != null) ? parseInt(odataObject.ProductRecordId, 10) : undefined;
              
            this.RelatedProductRecordId = 
                (odataObject.RelatedProductRecordId != null) ? parseInt(odataObject.RelatedProductRecordId, 10) : undefined;
              
            this.CatalogId = 
                (odataObject.CatalogId != null) ? parseInt(odataObject.CatalogId, 10) : undefined;
              
            this.RelationName = 
                odataObject.RelationName;
              
            this.IsExcludedRelation = 
                odataObject.IsExcludedRelation;
              
            this.IsDirectRelation = 
                odataObject.IsDirectRelation;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['RelatedProductClass'] = RelatedProductClass;
    
      /**
      * KitComponent entity class.
      */
      export class KitComponentClass implements Entities.KitComponent {
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['KitComponentClass'] = KitComponentClass;
    
      /**
      * ChannelReferenceAdditionalLookupCriteria entity class.
      */
      export class ChannelReferenceAdditionalLookupCriteriaClass implements Entities.ChannelReferenceAdditionalLookupCriteria {
      public OrderLookupAdditionalCriteriaTypeValue: number;
      public OrderLookupAdditionalCriteriaTypeParameterValue: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.OrderLookupAdditionalCriteriaTypeValue = 
                odataObject.OrderLookupAdditionalCriteriaTypeValue;
              
            this.OrderLookupAdditionalCriteriaTypeParameterValue = 
                odataObject.OrderLookupAdditionalCriteriaTypeParameterValue;
              
      }
      }
      entityDictionary['ChannelReferenceAdditionalLookupCriteriaClass'] = ChannelReferenceAdditionalLookupCriteriaClass;
    
      /**
      * UserAlertFulfillmentLineParameter entity class.
      */
      export class UserAlertFulfillmentLineParameterClass
        extends FulfillmentLineParameterClass
       implements Entities.UserAlertFulfillmentLineParameter {
      public ItemId: string;
      public ProductId: number;
      public UnitOfMeasure: string;
      public SerialNumberInLatestFulfillmentTransaction: string;
      public LatestFulfillmentTransactionOperationTypeValue: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.ItemId = 
                odataObject.ItemId;
              
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
            this.UnitOfMeasure = 
                odataObject.UnitOfMeasure;
              
            this.SerialNumberInLatestFulfillmentTransaction = 
                odataObject.SerialNumberInLatestFulfillmentTransaction;
              
            this.LatestFulfillmentTransactionOperationTypeValue = 
                odataObject.LatestFulfillmentTransactionOperationTypeValue;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.UserAlertFulfillmentLineParameter";
      
      }
      }
      entityDictionary['UserAlertFulfillmentLineParameterClass'] = UserAlertFulfillmentLineParameterClass;
    
      /**
      * ListingIdentity entity class.
      */
      export class ListingIdentityClass implements Entities.ListingIdentity {
      public ProductId: number;
      public LanguageId: string;
      public CatalogId: number;
      public Tag: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
            this.LanguageId = 
                odataObject.LanguageId;
              
            this.CatalogId = 
                (odataObject.CatalogId != null) ? parseInt(odataObject.CatalogId, 10) : undefined;
              
            this.Tag = 
                odataObject.Tag;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ListingIdentityClass'] = ListingIdentityClass;
    
      /**
      * ProductRatingCount entity class.
      */
      export class ProductRatingCountClass implements Entities.ProductRatingCount {
      public Rating: number;
      public Count: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Rating = 
                (odataObject.Rating != null) ? parseFloat(odataObject.Rating) : undefined;
              
            this.Count = 
                odataObject.Count;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ProductRatingCountClass'] = ProductRatingCountClass;
    
      /**
      * RegularStoreHours entity class.
      */
      export class RegularStoreHoursClass implements Entities.RegularStoreHours {
      public MondayOpenTime: number;
      public MondayCloseTime: number;
      public IsClosedOnMonday: boolean;
      public TuesdayOpenTime: number;
      public TuesdayCloseTime: number;
      public IsClosedOnTuesday: boolean;
      public WednesdayOpenTime: number;
      public WednesdayCloseTime: number;
      public IsClosedOnWednesday: boolean;
      public ThursdayOpenTime: number;
      public ThursdayCloseTime: number;
      public IsClosedOnThursday: boolean;
      public FridayOpenTime: number;
      public FridayCloseTime: number;
      public IsClosedOnFriday: boolean;
      public SaturdayOpenTime: number;
      public SaturdayCloseTime: number;
      public IsClosedOnSaturday: boolean;
      public SundayOpenTime: number;
      public SundayCloseTime: number;
      public IsClosedOnSunday: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.MondayOpenTime = 
                odataObject.MondayOpenTime;
              
            this.MondayCloseTime = 
                odataObject.MondayCloseTime;
              
            this.IsClosedOnMonday = 
                odataObject.IsClosedOnMonday;
              
            this.TuesdayOpenTime = 
                odataObject.TuesdayOpenTime;
              
            this.TuesdayCloseTime = 
                odataObject.TuesdayCloseTime;
              
            this.IsClosedOnTuesday = 
                odataObject.IsClosedOnTuesday;
              
            this.WednesdayOpenTime = 
                odataObject.WednesdayOpenTime;
              
            this.WednesdayCloseTime = 
                odataObject.WednesdayCloseTime;
              
            this.IsClosedOnWednesday = 
                odataObject.IsClosedOnWednesday;
              
            this.ThursdayOpenTime = 
                odataObject.ThursdayOpenTime;
              
            this.ThursdayCloseTime = 
                odataObject.ThursdayCloseTime;
              
            this.IsClosedOnThursday = 
                odataObject.IsClosedOnThursday;
              
            this.FridayOpenTime = 
                odataObject.FridayOpenTime;
              
            this.FridayCloseTime = 
                odataObject.FridayCloseTime;
              
            this.IsClosedOnFriday = 
                odataObject.IsClosedOnFriday;
              
            this.SaturdayOpenTime = 
                odataObject.SaturdayOpenTime;
              
            this.SaturdayCloseTime = 
                odataObject.SaturdayCloseTime;
              
            this.IsClosedOnSaturday = 
                odataObject.IsClosedOnSaturday;
              
            this.SundayOpenTime = 
                odataObject.SundayOpenTime;
              
            this.SundayCloseTime = 
                odataObject.SundayCloseTime;
              
            this.IsClosedOnSunday = 
                odataObject.IsClosedOnSunday;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['RegularStoreHoursClass'] = RegularStoreHoursClass;
    
      /**
      * ValidationPeriod entity class.
      */
      export class ValidationPeriodClass implements Entities.ValidationPeriod {
      public RecordId: number;
      public PeriodId: string;
      public Description: string;
      public ValidFrom: Date;
      public ValidTo: Date;
      public StartingTime: number;
      public EndingTime: number;
      public IsTimeBounded: number;
      public MondayStartingTime: number;
      public MondayEndingTime: number;
      public IsMondayTimeBounded: number;
      public TuesdayStartingTime: number;
      public TuesdayEndingTime: number;
      public IsTuesdayTimeBounded: number;
      public WednesdayStartingTime: number;
      public WednesdayEndingTime: number;
      public IsWednesdayTimeBounded: number;
      public ThursdayStartingTime: number;
      public ThursdayEndingTime: number;
      public IsThursdayTimeBounded: number;
      public FridayStartingTime: number;
      public FridayEndingTime: number;
      public IsFridayTimeBounded: number;
      public SaturdayStartingTime: number;
      public SaturdayEndingTime: number;
      public IsSaturdayTimeBounded: number;
      public SundayStartingTime: number;
      public SundayEndingTime: number;
      public IsSundayTimeBounded: number;
      public IsEndTimeAfterMidnight: number;
      public IsMondayEndTimeAfterMidnight: number;
      public IsTuesdayEndTimeAfterMidnight: number;
      public IsWednesdayEndTimeAfterMidnight: number;
      public IsThursdayEndTimeAfterMidnight: number;
      public IsFridayEndTimeAfterMidnight: number;
      public IsSaturdayEndTimeAfterMidnight: number;
      public IsSundayEndTimeAfterMidnight: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.PeriodId = 
                odataObject.PeriodId;
              
            this.Description = 
                odataObject.Description;
              
            this.ValidFrom = 
                (odataObject.ValidFrom instanceof Date) ? odataObject.ValidFrom
                : (odataObject.ValidFrom != null) ? jsonLightReadStringPropertyValue(odataObject.ValidFrom, "Edm.DateTimeOffset", false) : undefined;
              
            this.ValidTo = 
                (odataObject.ValidTo instanceof Date) ? odataObject.ValidTo
                : (odataObject.ValidTo != null) ? jsonLightReadStringPropertyValue(odataObject.ValidTo, "Edm.DateTimeOffset", false) : undefined;
              
            this.StartingTime = 
                odataObject.StartingTime;
              
            this.EndingTime = 
                odataObject.EndingTime;
              
            this.IsTimeBounded = 
                odataObject.IsTimeBounded;
              
            this.MondayStartingTime = 
                odataObject.MondayStartingTime;
              
            this.MondayEndingTime = 
                odataObject.MondayEndingTime;
              
            this.IsMondayTimeBounded = 
                odataObject.IsMondayTimeBounded;
              
            this.TuesdayStartingTime = 
                odataObject.TuesdayStartingTime;
              
            this.TuesdayEndingTime = 
                odataObject.TuesdayEndingTime;
              
            this.IsTuesdayTimeBounded = 
                odataObject.IsTuesdayTimeBounded;
              
            this.WednesdayStartingTime = 
                odataObject.WednesdayStartingTime;
              
            this.WednesdayEndingTime = 
                odataObject.WednesdayEndingTime;
              
            this.IsWednesdayTimeBounded = 
                odataObject.IsWednesdayTimeBounded;
              
            this.ThursdayStartingTime = 
                odataObject.ThursdayStartingTime;
              
            this.ThursdayEndingTime = 
                odataObject.ThursdayEndingTime;
              
            this.IsThursdayTimeBounded = 
                odataObject.IsThursdayTimeBounded;
              
            this.FridayStartingTime = 
                odataObject.FridayStartingTime;
              
            this.FridayEndingTime = 
                odataObject.FridayEndingTime;
              
            this.IsFridayTimeBounded = 
                odataObject.IsFridayTimeBounded;
              
            this.SaturdayStartingTime = 
                odataObject.SaturdayStartingTime;
              
            this.SaturdayEndingTime = 
                odataObject.SaturdayEndingTime;
              
            this.IsSaturdayTimeBounded = 
                odataObject.IsSaturdayTimeBounded;
              
            this.SundayStartingTime = 
                odataObject.SundayStartingTime;
              
            this.SundayEndingTime = 
                odataObject.SundayEndingTime;
              
            this.IsSundayTimeBounded = 
                odataObject.IsSundayTimeBounded;
              
            this.IsEndTimeAfterMidnight = 
                odataObject.IsEndTimeAfterMidnight;
              
            this.IsMondayEndTimeAfterMidnight = 
                odataObject.IsMondayEndTimeAfterMidnight;
              
            this.IsTuesdayEndTimeAfterMidnight = 
                odataObject.IsTuesdayEndTimeAfterMidnight;
              
            this.IsWednesdayEndTimeAfterMidnight = 
                odataObject.IsWednesdayEndTimeAfterMidnight;
              
            this.IsThursdayEndTimeAfterMidnight = 
                odataObject.IsThursdayEndTimeAfterMidnight;
              
            this.IsFridayEndTimeAfterMidnight = 
                odataObject.IsFridayEndTimeAfterMidnight;
              
            this.IsSaturdayEndTimeAfterMidnight = 
                odataObject.IsSaturdayEndTimeAfterMidnight;
              
            this.IsSundayEndTimeAfterMidnight = 
                odataObject.IsSundayEndTimeAfterMidnight;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ValidationPeriodClass'] = ValidationPeriodClass;
    
      /**
      * PriceLookupHeaderContext entity class.
      */
      export class PriceLookupHeaderContextClass implements Entities.PriceLookupHeaderContext {
      public CustomerAccountNumber: string;
      public AffiliationLoyaltyTierLines: Entities.AffiliationLoyaltyTier[];
      public SalesOrderProperties: Entities.AttributeValueBase[];
      public ChannelId: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.CustomerAccountNumber = 
                odataObject.CustomerAccountNumber;
              
        this.AffiliationLoyaltyTierLines = undefined;
        if (odataObject.AffiliationLoyaltyTierLines) {
        this.AffiliationLoyaltyTierLines = [];
        for (var i = 0; i < odataObject.AffiliationLoyaltyTierLines.length; i++) {
        if (odataObject.AffiliationLoyaltyTierLines[i] != null) {
        if (odataObject.AffiliationLoyaltyTierLines[i]['@odata.type'] != null) {
          var className: string = odataObject.AffiliationLoyaltyTierLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.AffiliationLoyaltyTierLines[i] = new entityDictionary[className](odataObject.AffiliationLoyaltyTierLines[i])
          }
        } else {
            this.AffiliationLoyaltyTierLines[i] = new AffiliationLoyaltyTierClass(odataObject.AffiliationLoyaltyTierLines[i]);
        }
                    } else {
        this.AffiliationLoyaltyTierLines[i] = undefined;
        }
        }
        }
      
        this.SalesOrderProperties = undefined;
        if (odataObject.SalesOrderProperties) {
        this.SalesOrderProperties = [];
        for (var i = 0; i < odataObject.SalesOrderProperties.length; i++) {
        if (odataObject.SalesOrderProperties[i] != null) {
        if (odataObject.SalesOrderProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.SalesOrderProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.SalesOrderProperties[i] = new entityDictionary[className](odataObject.SalesOrderProperties[i])
          }
        } else {
            this.SalesOrderProperties[i] = new AttributeValueBaseClass(odataObject.SalesOrderProperties[i]);
        }
                    } else {
        this.SalesOrderProperties[i] = undefined;
        }
        }
        }
      
            this.ChannelId = 
                (odataObject.ChannelId != null) ? parseInt(odataObject.ChannelId, 10) : undefined;
              
      }
      }
      entityDictionary['PriceLookupHeaderContextClass'] = PriceLookupHeaderContextClass;
    
      /**
      * PriceLookupLineContext entity class.
      */
      export class PriceLookupLineContextClass implements Entities.PriceLookupLineContext {
      public ProductRecordId: number;
      public UnitOfMeasureSymbol: string;
      public InventorySiteId: string;
      public InventoryLocationId: string;
      public DeliveryMode: string;
      public CatalogId: number;
      public SalesLineProperties: Entities.AttributeValueBase[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ProductRecordId = 
                (odataObject.ProductRecordId != null) ? parseInt(odataObject.ProductRecordId, 10) : undefined;
              
            this.UnitOfMeasureSymbol = 
                odataObject.UnitOfMeasureSymbol;
              
            this.InventorySiteId = 
                odataObject.InventorySiteId;
              
            this.InventoryLocationId = 
                odataObject.InventoryLocationId;
              
            this.DeliveryMode = 
                odataObject.DeliveryMode;
              
            this.CatalogId = 
                (odataObject.CatalogId != null) ? parseInt(odataObject.CatalogId, 10) : undefined;
              
        this.SalesLineProperties = undefined;
        if (odataObject.SalesLineProperties) {
        this.SalesLineProperties = [];
        for (var i = 0; i < odataObject.SalesLineProperties.length; i++) {
        if (odataObject.SalesLineProperties[i] != null) {
        if (odataObject.SalesLineProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.SalesLineProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.SalesLineProperties[i] = new entityDictionary[className](odataObject.SalesLineProperties[i])
          }
        } else {
            this.SalesLineProperties[i] = new AttributeValueBaseClass(odataObject.SalesLineProperties[i]);
        }
                    } else {
        this.SalesLineProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['PriceLookupLineContextClass'] = PriceLookupLineContextClass;
    
      /**
      * ClientBookChange entity class.
      */
      export class ClientBookChangeClass implements Entities.ClientBookChange {
      public FromStaffId: string;
      public ToStaffId: string;
      public CustomerId: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.FromStaffId = 
                odataObject.FromStaffId;
              
            this.ToStaffId = 
                odataObject.ToStaffId;
              
            this.CustomerId = 
                odataObject.CustomerId;
              
      }
      }
      entityDictionary['ClientBookChangeClass'] = ClientBookChangeClass;
    
      /**
      * ChannelProfile entity class.
      */
      export class ChannelProfileClass implements Entities.ChannelProfile {
      public ProfileProperties: Entities.ChannelProfileProperty[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.ProfileProperties = undefined;
        if (odataObject.ProfileProperties) {
        this.ProfileProperties = [];
        for (var i = 0; i < odataObject.ProfileProperties.length; i++) {
        if (odataObject.ProfileProperties[i] != null) {
        if (odataObject.ProfileProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ProfileProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ProfileProperties[i] = new entityDictionary[className](odataObject.ProfileProperties[i])
          }
        } else {
            this.ProfileProperties[i] = new ChannelProfilePropertyClass(odataObject.ProfileProperties[i]);
        }
                    } else {
        this.ProfileProperties[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ChannelProfileClass'] = ChannelProfileClass;
    
      /**
      * RetailSharedConfigurationParameter entity class.
      */
      export class RetailSharedConfigurationParameterClass implements Entities.RetailSharedConfigurationParameter {
      public RecordId: number;
      public Name: string;
      public Value: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RecordId = 
                (odataObject.RecordId != null) ? parseInt(odataObject.RecordId, 10) : undefined;
              
            this.Name = 
                odataObject.Name;
              
            this.Value = 
                odataObject.Value;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['RetailSharedConfigurationParameterClass'] = RetailSharedConfigurationParameterClass;
    
      /**
      * CDXDataStore entity class.
      */
      export class CDXDataStoreClass implements Entities.CDXDataStore {
      public Name: string;
      public RecId: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Name = 
                odataObject.Name;
              
            this.RecId = 
                (odataObject.RecId != null) ? parseInt(odataObject.RecId, 10) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CDXDataStoreClass'] = CDXDataStoreClass;
    
      /**
      * UploadJobPackageRequest entity class.
      */
      export class UploadJobPackageRequestClass implements Entities.UploadJobPackageRequest {
      public JobId: string;
      public RerunUploadSessionId: number;
      public TargetStorage: string;
      public UploadJobDefintion: string;
      public UploadUrl: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.JobId = 
                odataObject.JobId;
              
            this.RerunUploadSessionId = 
                (odataObject.RerunUploadSessionId != null) ? parseInt(odataObject.RerunUploadSessionId, 10) : undefined;
              
            this.TargetStorage = 
                odataObject.TargetStorage;
              
            this.UploadJobDefintion = 
                odataObject.UploadJobDefintion;
              
            this.UploadUrl = 
                odataObject.UploadUrl;
              
      }
      }
      entityDictionary['UploadJobPackageRequestClass'] = UploadJobPackageRequestClass;
    
      /**
      * ExtensibleAuditEventType entity class.
      */
      export class ExtensibleAuditEventTypeClass
        extends ExtensibleEnumerationClass
       implements Entities.ExtensibleAuditEventType {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.ExtensibleAuditEventType";
      
      }
      }
      entityDictionary['ExtensibleAuditEventTypeClass'] = ExtensibleAuditEventTypeClass;
    
      /**
      * AuditEventFiscalTransaction entity class.
      */
      export class AuditEventFiscalTransactionClass implements Entities.AuditEventFiscalTransaction {
      public EventId: number;
      public LineNumber: number;
      public RegisterResponse: string;
      public Store: string;
      public TransDateTime: Date;
      public Terminal: string;
      public UploadType: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.EventId = 
                (odataObject.EventId != null) ? parseInt(odataObject.EventId, 10) : undefined;
              
            this.LineNumber = 
                (odataObject.LineNumber != null) ? parseFloat(odataObject.LineNumber) : undefined;
              
            this.RegisterResponse = 
                odataObject.RegisterResponse;
              
            this.Store = 
                odataObject.Store;
              
            this.TransDateTime = 
                (odataObject.TransDateTime instanceof Date) ? odataObject.TransDateTime
                : (odataObject.TransDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.TransDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.Terminal = 
                odataObject.Terminal;
              
            this.UploadType = 
                odataObject.UploadType;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['AuditEventFiscalTransactionClass'] = AuditEventFiscalTransactionClass;
    
      /**
      * AuditEventFiscalRegistrationLine entity class.
      */
      export class AuditEventFiscalRegistrationLineClass implements Entities.AuditEventFiscalRegistrationLine {
      public EventId: number;
      public RegisterResponse: string;
      public StoreId: string;
      public TransactionDateTime: Date;
      public TerminalId: string;
      public UploadType: string;
      public UploadTypeValue: number;
      public StaffId: string;
      public RecordGuid: string;
      public RegistrationStatusValue: number;
      public RegistrationProcessId: string;
      public ConnectorGroup: string;
      public ConnectorName: string;
      public ConnectorFunctionalityProfileId: string;
      public RegisterInfo: string;
      public CreatedDateTime: Date;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.EventId = 
                (odataObject.EventId != null) ? parseInt(odataObject.EventId, 10) : undefined;
              
            this.RegisterResponse = 
                odataObject.RegisterResponse;
              
            this.StoreId = 
                odataObject.StoreId;
              
            this.TransactionDateTime = 
                (odataObject.TransactionDateTime instanceof Date) ? odataObject.TransactionDateTime
                : (odataObject.TransactionDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.TransactionDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.TerminalId = 
                odataObject.TerminalId;
              
            this.UploadType = 
                odataObject.UploadType;
              
            this.UploadTypeValue = 
                odataObject.UploadTypeValue;
              
            this.StaffId = 
                odataObject.StaffId;
              
            this.RecordGuid = 
                odataObject.RecordGuid;
              
            this.RegistrationStatusValue = 
                odataObject.RegistrationStatusValue;
              
            this.RegistrationProcessId = 
                odataObject.RegistrationProcessId;
              
            this.ConnectorGroup = 
                odataObject.ConnectorGroup;
              
            this.ConnectorName = 
                odataObject.ConnectorName;
              
            this.ConnectorFunctionalityProfileId = 
                odataObject.ConnectorFunctionalityProfileId;
              
            this.RegisterInfo = 
                odataObject.RegisterInfo;
              
            this.CreatedDateTime = 
                (odataObject.CreatedDateTime instanceof Date) ? odataObject.CreatedDateTime
                : (odataObject.CreatedDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.CreatedDateTime, "Edm.DateTimeOffset", false) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['AuditEventFiscalRegistrationLineClass'] = AuditEventFiscalRegistrationLineClass;
    
      /**
      * NotificationDetail entity class.
      */
      export class NotificationDetailClass implements Entities.NotificationDetail {
      public ActionProperty: string;
      public ItemCount: number;
      public DisplayText: string;
      public IsNew: boolean;
      public LastUpdatedDateTimeStr: string;
      public IsSuccess: boolean;
      public IsLiveContentOnly: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ActionProperty = 
                odataObject.ActionProperty;
              
            this.ItemCount = 
                (odataObject.ItemCount != null) ? parseInt(odataObject.ItemCount, 10) : undefined;
              
            this.DisplayText = 
                odataObject.DisplayText;
              
            this.IsNew = 
                odataObject.IsNew;
              
            this.LastUpdatedDateTimeStr = 
                odataObject.LastUpdatedDateTimeStr;
              
            this.IsSuccess = 
                odataObject.IsSuccess;
              
            this.IsLiveContentOnly = 
                odataObject.IsLiveContentOnly;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['NotificationDetailClass'] = NotificationDetailClass;
    
      /**
      * FiscalIntegrationDocumentContext entity class.
      */
      export class FiscalIntegrationDocumentContextClass implements Entities.FiscalIntegrationDocumentContext {
      public AuditEvent: Entities.AuditEvent;
      public SignatureData: Entities.FiscalIntegrationSequentialSignatureData[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        if (odataObject.AuditEvent == null) {
            this.AuditEvent = undefined;
        } else if (odataObject.AuditEvent['@odata.type'] == null) {
            this.AuditEvent = new AuditEventClass(odataObject.AuditEvent);
        } else {
            var className: string = odataObject.AuditEvent['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.AuditEvent = new entityDictionary[className](odataObject.AuditEvent)
            }
        }

      
        this.SignatureData = undefined;
        if (odataObject.SignatureData) {
        this.SignatureData = [];
        for (var i = 0; i < odataObject.SignatureData.length; i++) {
        if (odataObject.SignatureData[i] != null) {
        if (odataObject.SignatureData[i]['@odata.type'] != null) {
          var className: string = odataObject.SignatureData[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.SignatureData[i] = new entityDictionary[className](odataObject.SignatureData[i])
          }
        } else {
            this.SignatureData[i] = new FiscalIntegrationSequentialSignatureDataClass(odataObject.SignatureData[i]);
        }
                    } else {
        this.SignatureData[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['FiscalIntegrationDocumentContextClass'] = FiscalIntegrationDocumentContextClass;
    
      /**
      * FiscalIntegrationDocumentAdjustment entity class.
      */
      export class FiscalIntegrationDocumentAdjustmentClass implements Entities.FiscalIntegrationDocumentAdjustment {
      public TenderLineAdjustments: Entities.FiscalTransactionTenderLineAdjustment[];
      public SalesLineAdjustments: Entities.FiscalTransactionSalesLineAdjustment[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.TenderLineAdjustments = undefined;
        if (odataObject.TenderLineAdjustments) {
        this.TenderLineAdjustments = [];
        for (var i = 0; i < odataObject.TenderLineAdjustments.length; i++) {
        if (odataObject.TenderLineAdjustments[i] != null) {
        if (odataObject.TenderLineAdjustments[i]['@odata.type'] != null) {
          var className: string = odataObject.TenderLineAdjustments[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.TenderLineAdjustments[i] = new entityDictionary[className](odataObject.TenderLineAdjustments[i])
          }
        } else {
            this.TenderLineAdjustments[i] = new FiscalTransactionTenderLineAdjustmentClass(odataObject.TenderLineAdjustments[i]);
        }
                    } else {
        this.TenderLineAdjustments[i] = undefined;
        }
        }
        }
      
        this.SalesLineAdjustments = undefined;
        if (odataObject.SalesLineAdjustments) {
        this.SalesLineAdjustments = [];
        for (var i = 0; i < odataObject.SalesLineAdjustments.length; i++) {
        if (odataObject.SalesLineAdjustments[i] != null) {
        if (odataObject.SalesLineAdjustments[i]['@odata.type'] != null) {
          var className: string = odataObject.SalesLineAdjustments[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.SalesLineAdjustments[i] = new entityDictionary[className](odataObject.SalesLineAdjustments[i])
          }
        } else {
            this.SalesLineAdjustments[i] = new FiscalTransactionSalesLineAdjustmentClass(odataObject.SalesLineAdjustments[i]);
        }
                    } else {
        this.SalesLineAdjustments[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['FiscalIntegrationDocumentAdjustmentClass'] = FiscalIntegrationDocumentAdjustmentClass;
    
      /**
      * FiscalIntegrationSignatureDataContext entity class.
      */
      export class FiscalIntegrationSignatureDataContextClass implements Entities.FiscalIntegrationSignatureDataContext {
      public SequentialSignatureKey: string;
      public SequentialNumber: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.SequentialSignatureKey = 
                odataObject.SequentialSignatureKey;
              
            this.SequentialNumber = 
                (odataObject.SequentialNumber != null) ? parseInt(odataObject.SequentialNumber, 10) : undefined;
              
      }
      }
      entityDictionary['FiscalIntegrationSignatureDataContextClass'] = FiscalIntegrationSignatureDataContextClass;
    
      /**
      * FiscalIntegrationRegistrationProcessLine entity class.
      */
      export class FiscalIntegrationRegistrationProcessLineClass implements Entities.FiscalIntegrationRegistrationProcessLine {
      public RegistrationProcessId: string;
      public SequenceNumber: number;
      public Priority: number;
      public ConnectorTypeValue: number;
      public FunctionalityProfileGroupId: string;
      public AllowSkip: boolean;
      public AllowMarkAsRegistered: boolean;
      public AllowPostpone: boolean;
      public Optional: boolean;
      public RegistrationSettings: Entities.FiscalIntegrationRegistrationSettings;
      public SupportedFiscalEventTypes: number[];
      public SupportedNonFiscalEventTypes: number[];
      public SequentialKeys: string[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RegistrationProcessId = 
                odataObject.RegistrationProcessId;
              
            this.SequenceNumber = 
                odataObject.SequenceNumber;
              
            this.Priority = 
                odataObject.Priority;
              
            this.ConnectorTypeValue = 
                odataObject.ConnectorTypeValue;
              
            this.FunctionalityProfileGroupId = 
                odataObject.FunctionalityProfileGroupId;
              
            this.AllowSkip = 
                odataObject.AllowSkip;
              
            this.AllowMarkAsRegistered = 
                odataObject.AllowMarkAsRegistered;
              
            this.AllowPostpone = 
                odataObject.AllowPostpone;
              
            this.Optional = 
                odataObject.Optional;
              
        if (odataObject.RegistrationSettings == null) {
            this.RegistrationSettings = undefined;
        } else if (odataObject.RegistrationSettings['@odata.type'] == null) {
            this.RegistrationSettings = new FiscalIntegrationRegistrationSettingsClass(odataObject.RegistrationSettings);
        } else {
            var className: string = odataObject.RegistrationSettings['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.RegistrationSettings = new entityDictionary[className](odataObject.RegistrationSettings)
            }
        }

      
        this.SupportedFiscalEventTypes = undefined;
        if (odataObject.SupportedFiscalEventTypes) {
        this.SupportedFiscalEventTypes = [];
        for (var i = 0; i < odataObject.SupportedFiscalEventTypes.length; i++) {
        if (odataObject.SupportedFiscalEventTypes[i] != null) {
            this.SupportedFiscalEventTypes[i] = 
                odataObject.SupportedFiscalEventTypes[i];
                            } else {
        this.SupportedFiscalEventTypes[i] = undefined;
        }
        }
        }
      
        this.SupportedNonFiscalEventTypes = undefined;
        if (odataObject.SupportedNonFiscalEventTypes) {
        this.SupportedNonFiscalEventTypes = [];
        for (var i = 0; i < odataObject.SupportedNonFiscalEventTypes.length; i++) {
        if (odataObject.SupportedNonFiscalEventTypes[i] != null) {
            this.SupportedNonFiscalEventTypes[i] = 
                odataObject.SupportedNonFiscalEventTypes[i];
                            } else {
        this.SupportedNonFiscalEventTypes[i] = undefined;
        }
        }
        }
      
        this.SequentialKeys = undefined;
        if (odataObject.SequentialKeys) {
        this.SequentialKeys = [];
        for (var i = 0; i < odataObject.SequentialKeys.length; i++) {
        if (odataObject.SequentialKeys[i] != null) {
            this.SequentialKeys[i] = 
                odataObject.SequentialKeys[i];
                            } else {
        this.SequentialKeys[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['FiscalIntegrationRegistrationProcessLineClass'] = FiscalIntegrationRegistrationProcessLineClass;
    
      /**
      * FiscalIntegrationRegistrationSettings entity class.
      */
      export class FiscalIntegrationRegistrationSettingsClass implements Entities.FiscalIntegrationRegistrationSettings {
      public SkipReasonCode: string;
      public MarkAsRegisteredReasonCode: string;
      public PostponeReasonCode: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.SkipReasonCode = 
                odataObject.SkipReasonCode;
              
            this.MarkAsRegisteredReasonCode = 
                odataObject.MarkAsRegisteredReasonCode;
              
            this.PostponeReasonCode = 
                odataObject.PostponeReasonCode;
              
      }
      }
      entityDictionary['FiscalIntegrationRegistrationSettingsClass'] = FiscalIntegrationRegistrationSettingsClass;
    
      /**
      * FiscalIntegrationFiscalRegistrationProcessSummaryLine entity class.
      */
      export class FiscalIntegrationFiscalRegistrationProcessSummaryLineClass implements Entities.FiscalIntegrationFiscalRegistrationProcessSummaryLine {
      public RegistrationProcessLine: Entities.FiscalIntegrationRegistrationProcessLine;
      public ConnectorName: string;
      public ConnectorTypeValue: number;
      public ConnectorLocationValue: number;
      public ConnectionProperties: Entities.FiscalIntegrationConfigurationProperty[];
      public ErrorCode: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        if (odataObject.RegistrationProcessLine == null) {
            this.RegistrationProcessLine = undefined;
        } else if (odataObject.RegistrationProcessLine['@odata.type'] == null) {
            this.RegistrationProcessLine = new FiscalIntegrationRegistrationProcessLineClass(odataObject.RegistrationProcessLine);
        } else {
            var className: string = odataObject.RegistrationProcessLine['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.RegistrationProcessLine = new entityDictionary[className](odataObject.RegistrationProcessLine)
            }
        }

      
            this.ConnectorName = 
                odataObject.ConnectorName;
              
            this.ConnectorTypeValue = 
                odataObject.ConnectorTypeValue;
              
            this.ConnectorLocationValue = 
                odataObject.ConnectorLocationValue;
              
        this.ConnectionProperties = undefined;
        if (odataObject.ConnectionProperties) {
        this.ConnectionProperties = [];
        for (var i = 0; i < odataObject.ConnectionProperties.length; i++) {
        if (odataObject.ConnectionProperties[i] != null) {
        if (odataObject.ConnectionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ConnectionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ConnectionProperties[i] = new entityDictionary[className](odataObject.ConnectionProperties[i])
          }
        } else {
            this.ConnectionProperties[i] = new FiscalIntegrationConfigurationPropertyClass(odataObject.ConnectionProperties[i]);
        }
                    } else {
        this.ConnectionProperties[i] = undefined;
        }
        }
        }
      
            this.ErrorCode = 
                odataObject.ErrorCode;
              
      }
      }
      entityDictionary['FiscalIntegrationFiscalRegistrationProcessSummaryLineClass'] = FiscalIntegrationFiscalRegistrationProcessSummaryLineClass;
    
      /**
      * FiscalIntegrationConfigurationProperty entity class.
      */
      export class FiscalIntegrationConfigurationPropertyClass implements Entities.FiscalIntegrationConfigurationProperty {
      public LabelName: string;
      public Value: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.LabelName = 
                odataObject.LabelName;
              
            this.Value = 
                odataObject.Value;
              
      }
      }
      entityDictionary['FiscalIntegrationConfigurationPropertyClass'] = FiscalIntegrationConfigurationPropertyClass;
    
      /**
      * FiscalIntegrationServiceFailureDetails entity class.
      */
      export class FiscalIntegrationServiceFailureDetailsClass implements Entities.FiscalIntegrationServiceFailureDetails {
      public FailureTypeValue: number;
      public IsRetryAllowed: boolean;
      public ErrorCode: string;
      public ErrorMessage: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.FailureTypeValue = 
                odataObject.FailureTypeValue;
              
            this.IsRetryAllowed = 
                odataObject.IsRetryAllowed;
              
            this.ErrorCode = 
                odataObject.ErrorCode;
              
            this.ErrorMessage = 
                odataObject.ErrorMessage;
              
      }
      }
      entityDictionary['FiscalIntegrationServiceFailureDetailsClass'] = FiscalIntegrationServiceFailureDetailsClass;
    
      /**
      * CategoryPath entity class.
      */
      export class CategoryPathClass implements Entities.CategoryPath {
      public Segments: Entities.CategoryPathSegment[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.Segments = undefined;
        if (odataObject.Segments) {
        this.Segments = [];
        for (var i = 0; i < odataObject.Segments.length; i++) {
        if (odataObject.Segments[i] != null) {
        if (odataObject.Segments[i]['@odata.type'] != null) {
          var className: string = odataObject.Segments[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.Segments[i] = new entityDictionary[className](odataObject.Segments[i])
          }
        } else {
            this.Segments[i] = new CategoryPathSegmentClass(odataObject.Segments[i]);
        }
                    } else {
        this.Segments[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CategoryPathClass'] = CategoryPathClass;
    
      /**
      * CategoryPathSegment entity class.
      */
      export class CategoryPathSegmentClass implements Entities.CategoryPathSegment {
      public CategoryName: string;
      public CategoryId: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.CategoryName = 
                odataObject.CategoryName;
              
            this.CategoryId = 
                (odataObject.CategoryId != null) ? parseInt(odataObject.CategoryId, 10) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CategoryPathSegmentClass'] = CategoryPathSegmentClass;
    
      /**
      * SalesLineConfirmedShipDateChange entity class.
      */
      export class SalesLineConfirmedShipDateChangeClass implements Entities.SalesLineConfirmedShipDateChange {
      public LineNumber: number;
      public ConfirmedShipDate: Date;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.LineNumber = 
                (odataObject.LineNumber != null) ? parseFloat(odataObject.LineNumber) : undefined;
              
            this.ConfirmedShipDate = 
                (odataObject.ConfirmedShipDate instanceof Date) ? odataObject.ConfirmedShipDate
                : (odataObject.ConfirmedShipDate != null) ? jsonLightReadStringPropertyValue(odataObject.ConfirmedShipDate, "Edm.DateTimeOffset", false) : undefined;
              
      }
      }
      entityDictionary['SalesLineConfirmedShipDateChangeClass'] = SalesLineConfirmedShipDateChangeClass;
    
      /**
      * AttachedService entity class.
      */
      export class AttachedServiceClass implements Entities.AttachedService {
      public BaseUrl: string;
      public ServiceId: string;
      public StatusValue: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.BaseUrl = 
                odataObject.BaseUrl;
              
            this.ServiceId = 
                odataObject.ServiceId;
              
            this.StatusValue = 
                odataObject.StatusValue;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['AttachedServiceClass'] = AttachedServiceClass;
    
      /**
      * ExtensibleInventoryJournalType entity class.
      */
      export class ExtensibleInventoryJournalTypeClass
        extends ExtensibleEnumerationClass
       implements Entities.ExtensibleInventoryJournalType {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.ExtensibleInventoryJournalType";
      
      }
      }
      entityDictionary['ExtensibleInventoryJournalTypeClass'] = ExtensibleInventoryJournalTypeClass;
    
      /**
      * CustomerTimelineItemType entity class.
      */
      export class CustomerTimelineItemTypeClass implements Entities.CustomerTimelineItemType {
      public Description: string;
      public TypeId: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.Description = 
                odataObject.Description;
              
            this.TypeId = 
                odataObject.TypeId;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CustomerTimelineItemTypeClass'] = CustomerTimelineItemTypeClass;
    
      /**
      * ProductWarehouseInventoryAvailability entity class.
      */
      export class ProductWarehouseInventoryAvailabilityClass implements Entities.ProductWarehouseInventoryAvailability {
      public ProductId: number;
      public InventLocationId: string;
      public DataAreaId: string;
      public PhysicalInventory: number;
      public PhysicalReserved: number;
      public TotalAvailable: number;
      public TotalAvailableInventoryLevelLabel: string;
      public TotalAvailableInventoryLevelCode: string;
      public OrderedInTotal: number;
      public PhysicalAvailable: number;
      public PhysicalAvailableInventoryLevelLabel: string;
      public PhysicalAvailableInventoryLevelCode: string;
      public LastInventoryTransactionId: number;
      public UnpostedOnlineOrderedQuantity: number;
      public UnpostedFulfilledQuantity: number;
      public IsInventoryAvailabilityQuantityReturned: boolean;
      public UnprocessedQuantity: number;
      public QuantityUnitTypeValue: number;
      public UnitOfMeasure: string;
      public MaximumPurchasablePhysicalAvailableQuantity: number;
      public MaximumPurchasableTotalAvailableQuantity: number;
      public SumUncountedTransactions: number;
      public IgnoreQuantityUnitType: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
            this.InventLocationId = 
                odataObject.InventLocationId;
              
            this.DataAreaId = 
                odataObject.DataAreaId;
              
            this.PhysicalInventory = 
                (odataObject.PhysicalInventory != null) ? parseFloat(odataObject.PhysicalInventory) : undefined;
              
            this.PhysicalReserved = 
                (odataObject.PhysicalReserved != null) ? parseFloat(odataObject.PhysicalReserved) : undefined;
              
            this.TotalAvailable = 
                (odataObject.TotalAvailable != null) ? parseFloat(odataObject.TotalAvailable) : undefined;
              
            this.TotalAvailableInventoryLevelLabel = 
                odataObject.TotalAvailableInventoryLevelLabel;
              
            this.TotalAvailableInventoryLevelCode = 
                odataObject.TotalAvailableInventoryLevelCode;
              
            this.OrderedInTotal = 
                (odataObject.OrderedInTotal != null) ? parseFloat(odataObject.OrderedInTotal) : undefined;
              
            this.PhysicalAvailable = 
                (odataObject.PhysicalAvailable != null) ? parseFloat(odataObject.PhysicalAvailable) : undefined;
              
            this.PhysicalAvailableInventoryLevelLabel = 
                odataObject.PhysicalAvailableInventoryLevelLabel;
              
            this.PhysicalAvailableInventoryLevelCode = 
                odataObject.PhysicalAvailableInventoryLevelCode;
              
            this.LastInventoryTransactionId = 
                (odataObject.LastInventoryTransactionId != null) ? parseInt(odataObject.LastInventoryTransactionId, 10) : undefined;
              
            this.UnpostedOnlineOrderedQuantity = 
                (odataObject.UnpostedOnlineOrderedQuantity != null) ? parseFloat(odataObject.UnpostedOnlineOrderedQuantity) : undefined;
              
            this.UnpostedFulfilledQuantity = 
                (odataObject.UnpostedFulfilledQuantity != null) ? parseFloat(odataObject.UnpostedFulfilledQuantity) : undefined;
              
            this.IsInventoryAvailabilityQuantityReturned = 
                odataObject.IsInventoryAvailabilityQuantityReturned;
              
            this.UnprocessedQuantity = 
                (odataObject.UnprocessedQuantity != null) ? parseFloat(odataObject.UnprocessedQuantity) : undefined;
              
            this.QuantityUnitTypeValue = 
                odataObject.QuantityUnitTypeValue;
              
            this.UnitOfMeasure = 
                odataObject.UnitOfMeasure;
              
            this.MaximumPurchasablePhysicalAvailableQuantity = 
                (odataObject.MaximumPurchasablePhysicalAvailableQuantity != null) ? parseFloat(odataObject.MaximumPurchasablePhysicalAvailableQuantity) : undefined;
              
            this.MaximumPurchasableTotalAvailableQuantity = 
                (odataObject.MaximumPurchasableTotalAvailableQuantity != null) ? parseFloat(odataObject.MaximumPurchasableTotalAvailableQuantity) : undefined;
              
            this.SumUncountedTransactions = 
                (odataObject.SumUncountedTransactions != null) ? parseFloat(odataObject.SumUncountedTransactions) : undefined;
              
            this.IgnoreQuantityUnitType = 
                odataObject.IgnoreQuantityUnitType;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ProductWarehouseInventoryAvailabilityClass'] = ProductWarehouseInventoryAvailabilityClass;
    
      /**
      * QuantityUnitType entity class.
      */
      export class QuantityUnitTypeClass
        extends ExtensibleEnumerationClass
       implements Entities.QuantityUnitType {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.QuantityUnitType";
      
      }
      }
      entityDictionary['QuantityUnitTypeClass'] = QuantityUnitTypeClass;
    
      /**
      * ProductInventoryAvailability entity class.
      */
      export class ProductInventoryAvailabilityClass implements Entities.ProductInventoryAvailability {
      public ProductId: number;
      public DataAreaId: string;
      public TotalAvailableQuantity: number;
      public TotalAvailableInventoryLevelLabel: string;
      public TotalAvailableInventoryLevelCode: string;
      public PhysicalAvailableQuantity: number;
      public PhysicalAvailableInventoryLevelLabel: string;
      public PhysicalAvailableInventoryLevelCode: string;
      public QuantityUnitTypeValue: number;
      public UnitOfMeasure: string;
      public MaximumPurchasablePhysicalAvailableQuantity: number;
      public MaximumPurchasableTotalAvailableQuantity: number;
      public IgnoreQuantityUnitType: boolean;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
            this.DataAreaId = 
                odataObject.DataAreaId;
              
            this.TotalAvailableQuantity = 
                (odataObject.TotalAvailableQuantity != null) ? parseFloat(odataObject.TotalAvailableQuantity) : undefined;
              
            this.TotalAvailableInventoryLevelLabel = 
                odataObject.TotalAvailableInventoryLevelLabel;
              
            this.TotalAvailableInventoryLevelCode = 
                odataObject.TotalAvailableInventoryLevelCode;
              
            this.PhysicalAvailableQuantity = 
                (odataObject.PhysicalAvailableQuantity != null) ? parseFloat(odataObject.PhysicalAvailableQuantity) : undefined;
              
            this.PhysicalAvailableInventoryLevelLabel = 
                odataObject.PhysicalAvailableInventoryLevelLabel;
              
            this.PhysicalAvailableInventoryLevelCode = 
                odataObject.PhysicalAvailableInventoryLevelCode;
              
            this.QuantityUnitTypeValue = 
                odataObject.QuantityUnitTypeValue;
              
            this.UnitOfMeasure = 
                odataObject.UnitOfMeasure;
              
            this.MaximumPurchasablePhysicalAvailableQuantity = 
                (odataObject.MaximumPurchasablePhysicalAvailableQuantity != null) ? parseFloat(odataObject.MaximumPurchasablePhysicalAvailableQuantity) : undefined;
              
            this.MaximumPurchasableTotalAvailableQuantity = 
                (odataObject.MaximumPurchasableTotalAvailableQuantity != null) ? parseFloat(odataObject.MaximumPurchasableTotalAvailableQuantity) : undefined;
              
            this.IgnoreQuantityUnitType = 
                odataObject.IgnoreQuantityUnitType;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ProductInventoryAvailabilityClass'] = ProductInventoryAvailabilityClass;
    
      /**
      * InventoryQuantity entity class.
      */
      export class InventoryQuantityClass implements Entities.InventoryQuantity {
      public ProductId: number;
      public ItemId: string;
      public DataAreaId: string;
      public ColorId: string;
      public SizeId: string;
      public StyleId: string;
      public ConfigurationId: string;
      public InventoryLocationId: string;
      public InventorySiteId: string;
      public TotalAvailable: number;
      public PhysicalAvailable: number;
      public PhysicalInventory: number;
      public PhysicalReserved: number;
      public OrderedInTotal: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
            this.ItemId = 
                odataObject.ItemId;
              
            this.DataAreaId = 
                odataObject.DataAreaId;
              
            this.ColorId = 
                odataObject.ColorId;
              
            this.SizeId = 
                odataObject.SizeId;
              
            this.StyleId = 
                odataObject.StyleId;
              
            this.ConfigurationId = 
                odataObject.ConfigurationId;
              
            this.InventoryLocationId = 
                odataObject.InventoryLocationId;
              
            this.InventorySiteId = 
                odataObject.InventorySiteId;
              
            this.TotalAvailable = 
                (odataObject.TotalAvailable != null) ? parseFloat(odataObject.TotalAvailable) : undefined;
              
            this.PhysicalAvailable = 
                (odataObject.PhysicalAvailable != null) ? parseFloat(odataObject.PhysicalAvailable) : undefined;
              
            this.PhysicalInventory = 
                (odataObject.PhysicalInventory != null) ? parseFloat(odataObject.PhysicalInventory) : undefined;
              
            this.PhysicalReserved = 
                (odataObject.PhysicalReserved != null) ? parseFloat(odataObject.PhysicalReserved) : undefined;
              
            this.OrderedInTotal = 
                (odataObject.OrderedInTotal != null) ? parseFloat(odataObject.OrderedInTotal) : undefined;
              
      }
      }
      entityDictionary['InventoryQuantityClass'] = InventoryQuantityClass;
    
      /**
      * ProductUnit entity class.
      */
      export class ProductUnitClass implements Entities.ProductUnit {
      public ProductId: number;
      public UnitOfMeasure: string;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ProductId = 
                (odataObject.ProductId != null) ? parseInt(odataObject.ProductId, 10) : undefined;
              
            this.UnitOfMeasure = 
                odataObject.UnitOfMeasure;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['ProductUnitClass'] = ProductUnitClass;
    
      /**
      * ParameterSet entity class.
      */
      export class ParameterSetClass implements Entities.ParameterSet {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
      }
      }
      entityDictionary['ParameterSetClass'] = ParameterSetClass;
    
      /**
      * CartLineValidationResults entity class.
      */
      export class CartLineValidationResultsClass implements Entities.CartLineValidationResults {
      public ValidationResultsByLineId: Entities.CartLineValidationResultsByLineId;
      public ValidationFailuresByCartLines: Entities.LineDataValidationFailure[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        if (odataObject.ValidationResultsByLineId == null) {
            this.ValidationResultsByLineId = undefined;
        } else if (odataObject.ValidationResultsByLineId['@odata.type'] == null) {
            this.ValidationResultsByLineId = new CartLineValidationResultsByLineIdClass(odataObject.ValidationResultsByLineId);
        } else {
            var className: string = odataObject.ValidationResultsByLineId['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.ValidationResultsByLineId = new entityDictionary[className](odataObject.ValidationResultsByLineId)
            }
        }

      
        this.ValidationFailuresByCartLines = undefined;
        if (odataObject.ValidationFailuresByCartLines) {
        this.ValidationFailuresByCartLines = [];
        for (var i = 0; i < odataObject.ValidationFailuresByCartLines.length; i++) {
        if (odataObject.ValidationFailuresByCartLines[i] != null) {
        if (odataObject.ValidationFailuresByCartLines[i]['@odata.type'] != null) {
          var className: string = odataObject.ValidationFailuresByCartLines[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ValidationFailuresByCartLines[i] = new entityDictionary[className](odataObject.ValidationFailuresByCartLines[i])
          }
        } else {
            this.ValidationFailuresByCartLines[i] = new LineDataValidationFailureClass(odataObject.ValidationFailuresByCartLines[i]);
        }
                    } else {
        this.ValidationFailuresByCartLines[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CartLineValidationResultsClass'] = CartLineValidationResultsClass;
    
      /**
      * CommerceException entity class.
      */
      export class CommerceExceptionClass implements Entities.CommerceException {
      public LocalizedMessage: string;
      public ErrorResourceId: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.LocalizedMessage = 
                odataObject.LocalizedMessage;
              
            this.ErrorResourceId = 
                odataObject.ErrorResourceId;
              
      }
      }
      entityDictionary['CommerceExceptionClass'] = CommerceExceptionClass;
    
      /**
      * DataValidationException entity class.
      */
      export class DataValidationExceptionClass
        extends CommerceExceptionClass
       implements Entities.DataValidationException {
      public ValidationResults: Entities.DataValidationFailure[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this.ValidationResults = undefined;
        if (odataObject.ValidationResults) {
        this.ValidationResults = [];
        for (var i = 0; i < odataObject.ValidationResults.length; i++) {
        if (odataObject.ValidationResults[i] != null) {
        if (odataObject.ValidationResults[i]['@odata.type'] != null) {
          var className: string = odataObject.ValidationResults[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ValidationResults[i] = new entityDictionary[className](odataObject.ValidationResults[i])
          }
        } else {
            this.ValidationResults[i] = new DataValidationFailureClass(odataObject.ValidationResults[i]);
        }
                    } else {
        this.ValidationResults[i] = undefined;
        }
        }
        }
      
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DataValidationException";
      
      }
      }
      entityDictionary['DataValidationExceptionClass'] = DataValidationExceptionClass;
    
      /**
      * MissingRequiredReasonCodeException entity class.
      */
      export class MissingRequiredReasonCodeExceptionClass
        extends DataValidationExceptionClass
       implements Entities.MissingRequiredReasonCodeException {
      public RequiredReasonCodes: Entities.ReasonCode[];
      public TransactionRequiredReasonCodeIds: string[];
      public ReasonCodeRequirements: Entities.ReasonCodeRequirement[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this.RequiredReasonCodes = undefined;
        if (odataObject.RequiredReasonCodes) {
        this.RequiredReasonCodes = [];
        for (var i = 0; i < odataObject.RequiredReasonCodes.length; i++) {
        if (odataObject.RequiredReasonCodes[i] != null) {
        if (odataObject.RequiredReasonCodes[i]['@odata.type'] != null) {
          var className: string = odataObject.RequiredReasonCodes[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.RequiredReasonCodes[i] = new entityDictionary[className](odataObject.RequiredReasonCodes[i])
          }
        } else {
            this.RequiredReasonCodes[i] = new ReasonCodeClass(odataObject.RequiredReasonCodes[i]);
        }
                    } else {
        this.RequiredReasonCodes[i] = undefined;
        }
        }
        }
      
        this.TransactionRequiredReasonCodeIds = undefined;
        if (odataObject.TransactionRequiredReasonCodeIds) {
        this.TransactionRequiredReasonCodeIds = [];
        for (var i = 0; i < odataObject.TransactionRequiredReasonCodeIds.length; i++) {
        if (odataObject.TransactionRequiredReasonCodeIds[i] != null) {
            this.TransactionRequiredReasonCodeIds[i] = 
                odataObject.TransactionRequiredReasonCodeIds[i];
                            } else {
        this.TransactionRequiredReasonCodeIds[i] = undefined;
        }
        }
        }
      
        this.ReasonCodeRequirements = undefined;
        if (odataObject.ReasonCodeRequirements) {
        this.ReasonCodeRequirements = [];
        for (var i = 0; i < odataObject.ReasonCodeRequirements.length; i++) {
        if (odataObject.ReasonCodeRequirements[i] != null) {
        if (odataObject.ReasonCodeRequirements[i]['@odata.type'] != null) {
          var className: string = odataObject.ReasonCodeRequirements[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ReasonCodeRequirements[i] = new entityDictionary[className](odataObject.ReasonCodeRequirements[i])
          }
        } else {
            this.ReasonCodeRequirements[i] = new ReasonCodeRequirementClass(odataObject.ReasonCodeRequirements[i]);
        }
                    } else {
        this.ReasonCodeRequirements[i] = undefined;
        }
        }
        }
      
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.MissingRequiredReasonCodeException";
      
      }
      }
      entityDictionary['MissingRequiredReasonCodeExceptionClass'] = MissingRequiredReasonCodeExceptionClass;
    
      /**
      * SecurityException entity class.
      */
      export class SecurityExceptionClass
        extends CommerceExceptionClass
       implements Entities.SecurityException {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.SecurityException";
      
      }
      }
      entityDictionary['SecurityExceptionClass'] = SecurityExceptionClass;
    
      /**
      * DeviceAuthenticationException entity class.
      */
      export class DeviceAuthenticationExceptionClass
        extends SecurityExceptionClass
       implements Entities.DeviceAuthenticationException {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.DeviceAuthenticationException";
      
      }
      }
      entityDictionary['DeviceAuthenticationExceptionClass'] = DeviceAuthenticationExceptionClass;
    
      /**
      * HeadquarterTransactionServiceException entity class.
      */
      export class HeadquarterTransactionServiceExceptionClass
        extends CommerceExceptionClass
       implements Entities.HeadquarterTransactionServiceException {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.HeadquarterTransactionServiceException";
      
      }
      }
      entityDictionary['HeadquarterTransactionServiceExceptionClass'] = HeadquarterTransactionServiceExceptionClass;
    
      /**
      * UserAuthenticationException entity class.
      */
      export class UserAuthenticationExceptionClass
        extends SecurityExceptionClass
       implements Entities.UserAuthenticationException {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.UserAuthenticationException";
      
      }
      }
      entityDictionary['UserAuthenticationExceptionClass'] = UserAuthenticationExceptionClass;
    
      /**
      * UserAuthorizationException entity class.
      */
      export class UserAuthorizationExceptionClass
        extends SecurityExceptionClass
       implements Entities.UserAuthorizationException {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.UserAuthorizationException";
      
      }
      }
      entityDictionary['UserAuthorizationExceptionClass'] = UserAuthorizationExceptionClass;
    
      /**
      * FeatureNotSupportedException entity class.
      */
      export class FeatureNotSupportedExceptionClass
        extends CommerceExceptionClass
       implements Entities.FeatureNotSupportedException {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.FeatureNotSupportedException";
      
      }
      }
      entityDictionary['FeatureNotSupportedExceptionClass'] = FeatureNotSupportedExceptionClass;
    
      /**
      * CartValidationException entity class.
      */
      export class CartValidationExceptionClass
        extends DataValidationExceptionClass
       implements Entities.CartValidationException {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.CartValidationException";
      
      }
      }
      entityDictionary['CartValidationExceptionClass'] = CartValidationExceptionClass;
    
      /**
      * TenderValidationException entity class.
      */
      export class TenderValidationExceptionClass
        extends DataValidationExceptionClass
       implements Entities.TenderValidationException {
      public TenderTypeId: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.TenderTypeId = 
                odataObject.TenderTypeId;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.TenderValidationException";
      
      }
      }
      entityDictionary['TenderValidationExceptionClass'] = TenderValidationExceptionClass;
    
      /**
      * CommerceDataExchangeException entity class.
      */
      export class CommerceDataExchangeExceptionClass
        extends CommerceExceptionClass
       implements Entities.CommerceDataExchangeException {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.CommerceDataExchangeException";
      
      }
      }
      entityDictionary['CommerceDataExchangeExceptionClass'] = CommerceDataExchangeExceptionClass;
    
      /**
      * ExtensibleEnumerationException entity class.
      */
      export class ExtensibleEnumerationExceptionClass
        extends CommerceExceptionClass
       implements Entities.ExtensibleEnumerationException {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.ExtensibleEnumerationException";
      
      }
      }
      entityDictionary['ExtensibleEnumerationExceptionClass'] = ExtensibleEnumerationExceptionClass;
    
      /**
      * TaskManagementException entity class.
      */
      export class TaskManagementExceptionClass
        extends CommerceExceptionClass
       implements Entities.TaskManagementException {
      public TaskManagementErrors: Entities.TaskManagementError[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this.TaskManagementErrors = undefined;
        if (odataObject.TaskManagementErrors) {
        this.TaskManagementErrors = [];
        for (var i = 0; i < odataObject.TaskManagementErrors.length; i++) {
        if (odataObject.TaskManagementErrors[i] != null) {
        if (odataObject.TaskManagementErrors[i]['@odata.type'] != null) {
          var className: string = odataObject.TaskManagementErrors[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.TaskManagementErrors[i] = new entityDictionary[className](odataObject.TaskManagementErrors[i])
          }
        } else {
            this.TaskManagementErrors[i] = new TaskManagementErrorClass(odataObject.TaskManagementErrors[i]);
        }
                    } else {
        this.TaskManagementErrors[i] = undefined;
        }
        }
        }
      
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.TaskManagementException";
      
      }
      }
      entityDictionary['TaskManagementExceptionClass'] = TaskManagementExceptionClass;
    
      /**
      * TaskManagementServerException entity class.
      */
      export class TaskManagementServerExceptionClass
        extends TaskManagementExceptionClass
       implements Entities.TaskManagementServerException {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.TaskManagementServerException";
      
      }
      }
      entityDictionary['TaskManagementServerExceptionClass'] = TaskManagementServerExceptionClass;
    
      /**
      * TaskManagementClientException entity class.
      */
      export class TaskManagementClientExceptionClass
        extends TaskManagementExceptionClass
       implements Entities.TaskManagementClientException {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.TaskManagementClientException";
      
      }
      }
      entityDictionary['TaskManagementClientExceptionClass'] = TaskManagementClientExceptionClass;
    
      /**
      * RemoteServiceNotAvailableException entity class.
      */
      export class RemoteServiceNotAvailableExceptionClass
        extends CommerceExceptionClass
       implements Entities.RemoteServiceNotAvailableException {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.RemoteServiceNotAvailableException";
      
      }
      }
      entityDictionary['RemoteServiceNotAvailableExceptionClass'] = RemoteServiceNotAvailableExceptionClass;
    
      /**
      * PaymentException entity class.
      */
      export class PaymentExceptionClass
        extends CommerceExceptionClass
       implements Entities.PaymentException {
      public AdditionalContext: string;
      public PaymentSdkErrors: Entities.PaymentError[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.AdditionalContext = 
                odataObject.AdditionalContext;
              
        this.PaymentSdkErrors = undefined;
        if (odataObject.PaymentSdkErrors) {
        this.PaymentSdkErrors = [];
        for (var i = 0; i < odataObject.PaymentSdkErrors.length; i++) {
        if (odataObject.PaymentSdkErrors[i] != null) {
        if (odataObject.PaymentSdkErrors[i]['@odata.type'] != null) {
          var className: string = odataObject.PaymentSdkErrors[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.PaymentSdkErrors[i] = new entityDictionary[className](odataObject.PaymentSdkErrors[i])
          }
        } else {
            this.PaymentSdkErrors[i] = new PaymentErrorClass(odataObject.PaymentSdkErrors[i]);
        }
                    } else {
        this.PaymentSdkErrors[i] = undefined;
        }
        }
        }
      
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.PaymentException";
      
      }
      }
      entityDictionary['PaymentExceptionClass'] = PaymentExceptionClass;
    
      /**
      * DataValidationFailure entity class.
      */
      export class DataValidationFailureClass implements Entities.DataValidationFailure {
      public ErrorContext: string;
      public ErrorResourceId: string;
      public MemberNames: string[];
      public LocalizedMessage: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ErrorContext = 
                odataObject.ErrorContext;
              
            this.ErrorResourceId = 
                odataObject.ErrorResourceId;
              
        this.MemberNames = undefined;
        if (odataObject.MemberNames) {
        this.MemberNames = [];
        for (var i = 0; i < odataObject.MemberNames.length; i++) {
        if (odataObject.MemberNames[i] != null) {
            this.MemberNames[i] = 
                odataObject.MemberNames[i];
                            } else {
        this.MemberNames[i] = undefined;
        }
        }
        }
      
            this.LocalizedMessage = 
                odataObject.LocalizedMessage;
              
      }
      }
      entityDictionary['DataValidationFailureClass'] = DataValidationFailureClass;
    
      /**
      * ItemDiscontinuedException entity class.
      */
      export class ItemDiscontinuedExceptionClass
        extends CommerceExceptionClass
       implements Entities.ItemDiscontinuedException {
      public RemovedUnavailableItemIds: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.RemovedUnavailableItemIds = 
                odataObject.RemovedUnavailableItemIds;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.ItemDiscontinuedException";
      
      }
      }
      entityDictionary['ItemDiscontinuedExceptionClass'] = ItemDiscontinuedExceptionClass;
    
      /**
      * InventoryDocumentLockedByOtherTerminalException entity class.
      */
      export class InventoryDocumentLockedByOtherTerminalExceptionClass
        extends DataValidationExceptionClass
       implements Entities.InventoryDocumentLockedByOtherTerminalException {
      public WorkingTerminalId: string;
      public CurrentTerminalId: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.WorkingTerminalId = 
                odataObject.WorkingTerminalId;
              
            this.CurrentTerminalId = 
                odataObject.CurrentTerminalId;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.InventoryDocumentLockedByOtherTerminalException";
      
      }
      }
      entityDictionary['InventoryDocumentLockedByOtherTerminalExceptionClass'] = InventoryDocumentLockedByOtherTerminalExceptionClass;
    
      /**
      * InventoryDocumentExceedMaximumQuantityException entity class.
      */
      export class InventoryDocumentExceedMaximumQuantityExceptionClass
        extends DataValidationExceptionClass
       implements Entities.InventoryDocumentExceedMaximumQuantityException {
      public MaximumQuantity: number;
      public AllowOverDelivery: boolean;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.MaximumQuantity = 
                (odataObject.MaximumQuantity != null) ? parseFloat(odataObject.MaximumQuantity) : undefined;
              
            this.AllowOverDelivery = 
                odataObject.AllowOverDelivery;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.InventoryDocumentExceedMaximumQuantityException";
      
      }
      }
      entityDictionary['InventoryDocumentExceedMaximumQuantityExceptionClass'] = InventoryDocumentExceedMaximumQuantityExceptionClass;
    
      /**
      * ProductPricingConfigurationException entity class.
      */
      export class ProductPricingConfigurationExceptionClass
        extends CommerceExceptionClass
       implements Entities.ProductPricingConfigurationException {
      public ProductPricingConfigurationErrors: Entities.ProductPricingConfigurationError[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this.ProductPricingConfigurationErrors = undefined;
        if (odataObject.ProductPricingConfigurationErrors) {
        this.ProductPricingConfigurationErrors = [];
        for (var i = 0; i < odataObject.ProductPricingConfigurationErrors.length; i++) {
        if (odataObject.ProductPricingConfigurationErrors[i] != null) {
        if (odataObject.ProductPricingConfigurationErrors[i]['@odata.type'] != null) {
          var className: string = odataObject.ProductPricingConfigurationErrors[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ProductPricingConfigurationErrors[i] = new entityDictionary[className](odataObject.ProductPricingConfigurationErrors[i])
          }
        } else {
            this.ProductPricingConfigurationErrors[i] = new ProductPricingConfigurationErrorClass(odataObject.ProductPricingConfigurationErrors[i]);
        }
                    } else {
        this.ProductPricingConfigurationErrors[i] = undefined;
        }
        }
        }
      
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.ProductPricingConfigurationException";
      
      }
      }
      entityDictionary['ProductPricingConfigurationExceptionClass'] = ProductPricingConfigurationExceptionClass;
    
      /**
      * CartLineValidationResultsByLineId entity class.
      */
      export class CartLineValidationResultsByLineIdClass implements Entities.CartLineValidationResultsByLineId {
      public LineIdToProductBehaviorMap: Entities.KeyValuePair_2OfString_ProductBehavior[];
      public ValidationResultsPairs: Entities.LineValidationResult[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        this.LineIdToProductBehaviorMap = undefined;
        if (odataObject.LineIdToProductBehaviorMap) {
        this.LineIdToProductBehaviorMap = [];
        for (var i = 0; i < odataObject.LineIdToProductBehaviorMap.length; i++) {
        if (odataObject.LineIdToProductBehaviorMap[i] != null) {
        if (odataObject.LineIdToProductBehaviorMap[i]['@odata.type'] != null) {
          var className: string = odataObject.LineIdToProductBehaviorMap[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.LineIdToProductBehaviorMap[i] = new entityDictionary[className](odataObject.LineIdToProductBehaviorMap[i])
          }
        } else {
            this.LineIdToProductBehaviorMap[i] = new KeyValuePair_2OfString_ProductBehaviorClass(odataObject.LineIdToProductBehaviorMap[i]);
        }
                    } else {
        this.LineIdToProductBehaviorMap[i] = undefined;
        }
        }
        }
      
        this.ValidationResultsPairs = undefined;
        if (odataObject.ValidationResultsPairs) {
        this.ValidationResultsPairs = [];
        for (var i = 0; i < odataObject.ValidationResultsPairs.length; i++) {
        if (odataObject.ValidationResultsPairs[i] != null) {
        if (odataObject.ValidationResultsPairs[i]['@odata.type'] != null) {
          var className: string = odataObject.ValidationResultsPairs[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ValidationResultsPairs[i] = new entityDictionary[className](odataObject.ValidationResultsPairs[i])
          }
        } else {
            this.ValidationResultsPairs[i] = new LineValidationResultClass(odataObject.ValidationResultsPairs[i]);
        }
                    } else {
        this.ValidationResultsPairs[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['CartLineValidationResultsByLineIdClass'] = CartLineValidationResultsByLineIdClass;
    
      /**
      * CommerceRuntimeCompositionException entity class.
      */
      export class CommerceRuntimeCompositionExceptionClass
        extends CommerceExceptionClass
       implements Entities.CommerceRuntimeCompositionException {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.CommerceRuntimeCompositionException";
      
      }
      }
      entityDictionary['CommerceRuntimeCompositionExceptionClass'] = CommerceRuntimeCompositionExceptionClass;
    
      /**
      * ChecklistTaskValidationException entity class.
      */
      export class ChecklistTaskValidationExceptionClass
        extends DataValidationExceptionClass
       implements Entities.ChecklistTaskValidationException {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.ChecklistTaskValidationException";
      
      }
      }
      entityDictionary['ChecklistTaskValidationExceptionClass'] = ChecklistTaskValidationExceptionClass;
    
      /**
      * InsufficientCredentialLengthException entity class.
      */
      export class InsufficientCredentialLengthExceptionClass
        extends DataValidationExceptionClass
       implements Entities.InsufficientCredentialLengthException {
      public ActualLength: number;
      public MinLength: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.ActualLength = 
                odataObject.ActualLength;
              
            this.MinLength = 
                odataObject.MinLength;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.InsufficientCredentialLengthException";
      
      }
      }
      entityDictionary['InsufficientCredentialLengthExceptionClass'] = InsufficientCredentialLengthExceptionClass;
    
      /**
      * ExtensibleEnumerationCacheException entity class.
      */
      export class ExtensibleEnumerationCacheExceptionClass
        extends CommerceExceptionClass
       implements Entities.ExtensibleEnumerationCacheException {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.ExtensibleEnumerationCacheException";
      
      }
      }
      entityDictionary['ExtensibleEnumerationCacheExceptionClass'] = ExtensibleEnumerationCacheExceptionClass;
    
      /**
      * ExtensionConfigurationException entity class.
      */
      export class ExtensionConfigurationExceptionClass
        extends CommerceExceptionClass
       implements Entities.ExtensionConfigurationException {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.ExtensionConfigurationException";
      
      }
      }
      entityDictionary['ExtensionConfigurationExceptionClass'] = ExtensionConfigurationExceptionClass;
    
      /**
      * StaffPasswordExpiredException entity class.
      */
      export class StaffPasswordExpiredExceptionClass
        extends UserAuthenticationExceptionClass
       implements Entities.StaffPasswordExpiredException {
      public StaffId: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.StaffId = 
                odataObject.StaffId;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.StaffPasswordExpiredException";
      
      }
      }
      entityDictionary['StaffPasswordExpiredExceptionClass'] = StaffPasswordExpiredExceptionClass;
    
      /**
      * ChannelConfigurationNotFoundException entity class.
      */
      export class ChannelConfigurationNotFoundExceptionClass
        extends CommerceExceptionClass
       implements Entities.ChannelConfigurationNotFoundException {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.ChannelConfigurationNotFoundException";
      
      }
      }
      entityDictionary['ChannelConfigurationNotFoundExceptionClass'] = ChannelConfigurationNotFoundExceptionClass;
    
      /**
      * CopilotServiceException entity class.
      */
      export class CopilotServiceExceptionClass
        extends CommerceExceptionClass
       implements Entities.CopilotServiceException {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.CopilotServiceException";
      
      }
      }
      entityDictionary['CopilotServiceExceptionClass'] = CopilotServiceExceptionClass;
    
      /**
      * PaymentConfigurationException entity class.
      */
      export class PaymentConfigurationExceptionClass
        extends CommerceExceptionClass
       implements Entities.PaymentConfigurationException {
      public PaymentConfigurationSdkErrors: string[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this.PaymentConfigurationSdkErrors = undefined;
        if (odataObject.PaymentConfigurationSdkErrors) {
        this.PaymentConfigurationSdkErrors = [];
        for (var i = 0; i < odataObject.PaymentConfigurationSdkErrors.length; i++) {
        if (odataObject.PaymentConfigurationSdkErrors[i] != null) {
            this.PaymentConfigurationSdkErrors[i] = 
                odataObject.PaymentConfigurationSdkErrors[i];
                            } else {
        this.PaymentConfigurationSdkErrors[i] = undefined;
        }
        }
        }
      
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.PaymentConfigurationException";
      
      }
      }
      entityDictionary['PaymentConfigurationExceptionClass'] = PaymentConfigurationExceptionClass;
    
      /**
      * SecretConfigurationNotFoundException entity class.
      */
      export class SecretConfigurationNotFoundExceptionClass
        extends CommerceExceptionClass
       implements Entities.SecretConfigurationNotFoundException {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.SecretConfigurationNotFoundException";
      
      }
      }
      entityDictionary['SecretConfigurationNotFoundExceptionClass'] = SecretConfigurationNotFoundExceptionClass;
    
      /**
      * TenderTypePolicy entity class.
      */
      export class TenderTypePolicyClass implements Entities.TenderTypePolicy {
      public OriginalTenderType: string;
      public AllowedReturnTenderTypes: string[];
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.OriginalTenderType = 
                odataObject.OriginalTenderType;
              
        this.AllowedReturnTenderTypes = undefined;
        if (odataObject.AllowedReturnTenderTypes) {
        this.AllowedReturnTenderTypes = [];
        for (var i = 0; i < odataObject.AllowedReturnTenderTypes.length; i++) {
        if (odataObject.AllowedReturnTenderTypes[i] != null) {
            this.AllowedReturnTenderTypes[i] = 
                odataObject.AllowedReturnTenderTypes[i];
                            } else {
        this.AllowedReturnTenderTypes[i] = undefined;
        }
        }
        }
      
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['TenderTypePolicyClass'] = TenderTypePolicyClass;
    
      /**
      * AccountStatementRequest entity class.
      */
      export class AccountStatementRequestClass implements Entities.AccountStatementRequest {
      public RequestedUserId: string;
      public IsForFullOrganization: boolean;
      public FromDate: Date;
      public ToDate: Date;
      public RequestingUserEmail: string;
      public DeliveryTypeValue: number;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.RequestedUserId = 
                odataObject.RequestedUserId;
              
            this.IsForFullOrganization = 
                odataObject.IsForFullOrganization;
              
            this.FromDate = 
                (odataObject.FromDate instanceof Date) ? odataObject.FromDate
                : (odataObject.FromDate != null) ? jsonLightReadStringPropertyValue(odataObject.FromDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.ToDate = 
                (odataObject.ToDate instanceof Date) ? odataObject.ToDate
                : (odataObject.ToDate != null) ? jsonLightReadStringPropertyValue(odataObject.ToDate, "Edm.DateTimeOffset", false) : undefined;
              
            this.RequestingUserEmail = 
                odataObject.RequestingUserEmail;
              
            this.DeliveryTypeValue = 
                odataObject.DeliveryTypeValue;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['AccountStatementRequestClass'] = AccountStatementRequestClass;
    
      /**
      * BusinessPartnerOperationRequestDetails entity class.
      */
      export class BusinessPartnerOperationRequestDetailsClass implements Entities.BusinessPartnerOperationRequestDetails {
      public InvoiceId: string;
      public AccountStatementStartDateTime: Date;
      public AccountStatementEndDateTime: Date;
      public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.InvoiceId = 
                odataObject.InvoiceId;
              
            this.AccountStatementStartDateTime = 
                (odataObject.AccountStatementStartDateTime instanceof Date) ? odataObject.AccountStatementStartDateTime
                : (odataObject.AccountStatementStartDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.AccountStatementStartDateTime, "Edm.DateTimeOffset", false) : undefined;
              
            this.AccountStatementEndDateTime = 
                (odataObject.AccountStatementEndDateTime instanceof Date) ? odataObject.AccountStatementEndDateTime
                : (odataObject.AccountStatementEndDateTime != null) ? jsonLightReadStringPropertyValue(odataObject.AccountStatementEndDateTime, "Edm.DateTimeOffset", false) : undefined;
              
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
        this.ExtensionProperties = [];
        for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i] != null) {
        if (odataObject.ExtensionProperties[i]['@odata.type'] != null) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (entityDictionary[className]) {
              this.ExtensionProperties[i] = new entityDictionary[className](odataObject.ExtensionProperties[i])
          }
        } else {
            this.ExtensionProperties[i] = new CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
                    } else {
        this.ExtensionProperties[i] = undefined;
        }
        }
        }
      
      }
      }
      entityDictionary['BusinessPartnerOperationRequestDetailsClass'] = BusinessPartnerOperationRequestDetailsClass;
    
      /**
      * ProductPricingConfigurationError entity class.
      */
      export class ProductPricingConfigurationErrorClass implements Entities.ProductPricingConfigurationError {
      public ProductId: string;
      public ErrorResourceId: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ProductId = 
                odataObject.ProductId;
              
            this.ErrorResourceId = 
                odataObject.ErrorResourceId;
              
      }
      }
      entityDictionary['ProductPricingConfigurationErrorClass'] = ProductPricingConfigurationErrorClass;
    
      /**
      * TaskManagementError entity class.
      */
      export class TaskManagementErrorClass implements Entities.TaskManagementError {
      public ErrorResourceId: string;
      public LocalizedMessage: string;
      public TaskId: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            this.ErrorResourceId = 
                odataObject.ErrorResourceId;
              
            this.LocalizedMessage = 
                odataObject.LocalizedMessage;
              
            this.TaskId = 
                odataObject.TaskId;
              
      }
      }
      entityDictionary['TaskManagementErrorClass'] = TaskManagementErrorClass;
    
      /**
      * LineDataValidationFailure entity class.
      */
      export class LineDataValidationFailureClass implements Entities.LineDataValidationFailure {
      public DataValidationFailure: Entities.DataValidationFailure;
      public LineIndex: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        if (odataObject.DataValidationFailure == null) {
            this.DataValidationFailure = undefined;
        } else if (odataObject.DataValidationFailure['@odata.type'] == null) {
            this.DataValidationFailure = new DataValidationFailureClass(odataObject.DataValidationFailure);
        } else {
            var className: string = odataObject.DataValidationFailure['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (entityDictionary[className]) {
                this.DataValidationFailure = new entityDictionary[className](odataObject.DataValidationFailure)
            }
        }

      
            this.LineIndex = 
                odataObject.LineIndex;
              
      }
      }
      entityDictionary['LineDataValidationFailureClass'] = LineDataValidationFailureClass;
    
      /**
      * WrongEndpointException entity class.
      */
      export class WrongEndpointExceptionClass
        extends CommerceExceptionClass
       implements Entities.WrongEndpointException {
      public RedirectUri: string;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.RedirectUri = 
                odataObject.RedirectUri;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Retail.RetailServerLibrary.WrongEndpointException";
      
      }
      }
      entityDictionary['WrongEndpointExceptionClass'] = WrongEndpointExceptionClass;
    
      /**
      * BadRequestException entity class.
      */
      export class BadRequestExceptionClass
        extends CommerceExceptionClass
       implements Entities.BadRequestException {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Retail.RetailServerLibrary.BadRequestException";
      
      }
      }
      entityDictionary['BadRequestExceptionClass'] = BadRequestExceptionClass;
    
      /**
      * ChargeTaxMeasure entity class.
      */
      export class ChargeTaxMeasureClass
        extends TaxMeasureClass
       implements Entities.ChargeTaxMeasure {
      public MarkupLineNumber: number;
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
            this.MarkupLineNumber = 
                (odataObject.MarkupLineNumber != null) ? parseFloat(odataObject.MarkupLineNumber) : undefined;
              
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.Localization.Entities.India.DataModel.Tax.ChargeTaxMeasure";
      
      }
      }
      entityDictionary['ChargeTaxMeasureClass'] = ChargeTaxMeasureClass;
    
      /**
      * KeyValuePair_2OfString_ProductBehavior entity class.
      */
      export class KeyValuePair_2OfString_ProductBehaviorClass implements Entities.KeyValuePair_2OfString_ProductBehavior {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
      }
      }
      entityDictionary['KeyValuePair_2OfString_ProductBehaviorClass'] = KeyValuePair_2OfString_ProductBehaviorClass;
    
      /**
      * CompilationFailedException entity class.
      */
      export class CompilationFailedExceptionClass
        extends CommerceExceptionClass
       implements Entities.CompilationFailedException {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.Hosting.Framework.CompilationFailedException";
      
      }
      }
      entityDictionary['CompilationFailedExceptionClass'] = CompilationFailedExceptionClass;
    
      /**
      * FranceExtensibleAuditEventType entity class.
      */
      export class FranceExtensibleAuditEventTypeClass
        extends ExtensibleAuditEventTypeClass
       implements Entities.FranceExtensibleAuditEventType {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.Localization.Entities.France.Enums.FranceExtensibleAuditEventType";
      
      }
      }
      entityDictionary['FranceExtensibleAuditEventTypeClass'] = FranceExtensibleAuditEventTypeClass;
    
      /**
      * AustriaExtensibleAuditEventType entity class.
      */
      export class AustriaExtensibleAuditEventTypeClass
        extends ExtensibleAuditEventTypeClass
       implements Entities.AustriaExtensibleAuditEventType {
      
      // Navigation properties names
      
      /**
      * Construct an object from odata response.
      *
      * @param {any} odataObject The odata result object.
      */
      constructor(odataObject?: any) {
      super(odataObject);odataObject = odataObject || {};
        this["@odata.type"] = "#Microsoft.Dynamics.Commerce.Runtime.Localization.Entities.Austria.AustriaExtensibleAuditEventType";
      
      }
      }
      entityDictionary['AustriaExtensibleAuditEventTypeClass'] = AustriaExtensibleAuditEventTypeClass;
    