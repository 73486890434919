import { AttributeValue } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';

// importing enums have issues and this enum is not interfaced from CommerceTypes
const enum AttributeDataType {
    /**
     * The None member.
     */
    None = 0,
    /**
     * The Currency member.
     */
    Currency = 1,
    /**
     * The DateTime member.
     */
    DateTime = 2,
    /**
     * The Decimal member.
     */
    Decimal = 3,
    /**
     * The Integer member.
     */
    Integer = 4,
    /**
     * The Text member.
     */
    Text = 5,
    /**
     * The TrueFalse member.
     */
    TrueFalse = 6,
    /**
     * The Video member.
     */
    Video = 40,
    /**
     * The Image member.
     */
    Image = 41
}

const AttributeDataTextMap = {
    0: 'CURRENCY',
    2: 'DATE',
    3: 'NUMBER',
    4: 'NUMBER',
    5: 'STRING',
    6: 'BOOLEAN'
};

export type CommerceValueTypes = string | number | boolean | null;

export type ValueTypes<T = CommerceValueTypes> = {
    value: T;
    type: DataType;
};
export type DataType = 'CURRENCY' | 'DATE' | 'NUMBER' | 'STRING' | 'BOOLEAN';

export type ParsedValue<T> = {
    name: string;
    value: T | null;
    type: DataType;
};

type AttributeHash = { [name: string]: CommerceValueTypes };

/**
 * Parses Commerce Datasets to make it easier to access data from Commerce
 * Objects
 *
 * **Bulk parsing method:**
 * ```
 * const fields = getMyFields.fromSomewhere(); // attributes[]
 * const parsedFields = CommerceAttributesParser(fields); // parse multiple attributes in bulk
 * console.log(parsedFields) // { [name: string]: ValueTypes }
 * ```
 *
 * **Using constructor method:**
 * ```
 * const fields = getMyFields.fromSomewhere(); // attributes[]
 * const commerceParser = new CommerceAttributesParser(fields); // parse multiple attributes
 * const isSoldOut
 *      = commerceParser.get<boolean>('soldOut') // returns { type: 'BOOLEAN', valueType: true }
 * console.log(isSoldOut);
 * ```
 *
 * **Using static method:**
 * ```
 * const soldOut = getMyFields.getField('soldOut');
 * // return type declaration is optional
 * const isSoldOut = CommerceAttributesParser.parse(soldOut); // parse single attribute
 * console.log(isSoldOut);
 * ```
 */
export default class CommerceAttributesParser {

    // type map from commerce number values to the properties found in the object
    private static _dataTypeMap: { [numType: number]: string } = {
        [AttributeDataType.Currency]: 'CurrencyCode',
        [AttributeDataType.Decimal]: 'IntegerValue', // this one is a guess
        [AttributeDataType.Integer]: 'IntegerValue',
        [AttributeDataType.Text]: 'TextValue',
        [AttributeDataType.TrueFalse]: 'BooleanValue',
        [AttributeDataType.DateTime]: 'DateTimeOffsetValue'
    };

    // raw array thats given when constructed
    private _attributes: AttributeValue[] = [];

    // map of attributes
    private _attributesMap: { [name: string]: AttributeValue } = {};

    /**
     * retrieve the correct piece of data from the object
     */
    public static parse<T = CommerceValueTypes>(attribute: AttributeValue): ParsedValue<T> | undefined {
        const parsedAttribute = CommerceAttributesParser._parseGetAttribute<T>(attribute);
        if (!parsedAttribute) { return undefined; }
        return {
            name: attribute.Name!,
            ...parsedAttribute
        };
    }

    //----------------------------------------------------------
    // Convert an AttributeValue[] to a simple key:value hash
    //----------------------------------------------------------
    public static getParsedAttributes(attributes: AttributeValue[]): AttributeHash {
        const attributeValues = {};

        attributes.forEach(
            attr => attributeValues[attr.Name!] = CommerceAttributesParser._parseGetAttribute(attr)?.value
        );

        return attributeValues;
    }

    //----------------------------------------------------------
    // Convert a simplified attribute hash back to an unparsed
    // AttributeValue[]
    //----------------------------------------------------------
    public static unParseAttributes(hash: AttributeHash): AttributeValue[] {
        return Object.entries(hash).map(([name, value]) => ({
            Name: name,
            TextValue: value?.toString(),
            DataTypeValue: AttributeDataType.Text
        }));
    }

    private static _parseGetAttribute<T = CommerceValueTypes>(attribute: AttributeValue): ValueTypes<T> | null {
        const property = CommerceAttributesParser._dataTypeMap[attribute.DataTypeValue!];
        const value = attribute[property];
        const mappedTextValue = AttributeDataTextMap[attribute.DataTypeValue!];
        if (mappedTextValue) {
            return {
                value: value,
                type: mappedTextValue
            };
        }
        return null;
    }

    constructor(attributes?: {
        attributeValues?: AttributeValue[];
        psuedoAttributeValues?: AttributeValue[];
    }) {
        if (attributes) {
            this._attributes = [
                ...(attributes?.attributeValues || []),
                ...(attributes?.psuedoAttributeValues || [])
            ];
            this._attributes.forEach(attribute => {
                if (attribute.Name) {
                    this._attributesMap[attribute.Name] = attribute;
                }
            });
        }
    }

    /**
     * get a value from the collection of attributes given
     * @param name name of the attribute
     */
    public get<T = CommerceValueTypes>(name: string): ParsedValue<T> | undefined {
        const attribute = this._attributesMap[name];
        if (attribute) {
            const commerceAttr = CommerceAttributesParser._parseGetAttribute<T>(attribute);
            if (!commerceAttr) { return undefined; }
            return {
                name: attribute.Name!,
                ...commerceAttr
            };
        }
        return undefined;
    }
}