/* tslint:disable */
import { IReactComponent } from 'mobx-react/index';
import React, { Component, ComponentType } from 'react';

type subscriptionCallback<T> = (...args: T[]) => void;

type subscription<T> = { [uniqueId: string]: subscriptionCallback<T> };

/**
 * A loader that maintains a list of current namespaces. Meant to be later
 * singleton'd
 */
class EventBusLoader {

    // list of current buses that are in action
    private _buses: { [namespace: string]: BusLoader } = {};

    /**
     * get's an existing bus or creating a new bus of that namespace
     *
     * @param busNamespace the namespace of the bus to retrieve
     */
    public getBus(busNamespace: string): BusLoader {
        // fast return if the bus already exists
        if (this._buses[busNamespace]) {
            return this._buses[busNamespace];
        }

        // create a new bus and do the internal subscription for deleting the entire bus when bus
        // is empty
        this._buses[busNamespace] = new BusLoader();
        this._buses[busNamespace].subscribe('_readyForDeletion', this.deleteBus.bind(this));
        return this._buses[busNamespace];
    }

    /**
     * deletes and purges all events
     *
     * @param busNamespace the namespace of the bus to delete
     */
    public deleteBus(busNamespace: string): void {
        if (this._buses[busNamespace]) {
            this._buses[busNamespace].purge();
        }
        delete this._buses[busNamespace];
    }
}

/**
 * Contains a single namespace of events of the event bus
 */
export class BusLoader {

    // list of all subscriptions within this bus's namespace
    private _subscriptions: { [subName: string]: subscription<unknown> } = {};

    /**
     * subscribe to a subscription within this namespace
     *
     * @param subName event subscription name
     * @param onPublish callback that triggers when subscription gets published
     */
    public subscribe<T>(subName: string, onPublish: subscriptionCallback<T>): { unsubscribe(): void, id: number } {

        // generates an ever-counting up id generator
        const nextId = IdGenerator();

        // create a new list if the bus loader contains no subscription
        if (!this._subscriptions[subName]) {
            this._subscriptions[subName] = {
                _readyForDeletion: () => { /* event to notify loader that this is going to be deleted */ }
            };
        }

        // add the subscription to the list
        this._subscriptions[subName][nextId] = onPublish as subscriptionCallback<unknown>;

        // return an unsubscribe method for deleting this specific subscription
        return {
            id: nextId,
            unsubscribe: () => {
                this._purgeById(subName, nextId);
            }
        };
    }

    /**
     * remove a subscription from anywhere within the namespace
     *
     * @param id subscription id
     */
    public unsubscribe(id: number): boolean {
        for (const subName in this._subscriptions) {
            if (this._subscriptions[subName][id]) {
                this._purgeById(subName, id);
                return true;
            }
        }
        return false;
    }

    /**
     * publish data to all subscriptions
     *
     * @param subName subscription name to publish to
     * @param args data to pass into the subscribed functions
     */
    public publish(subName: string, ...args: any): void {
        // don't do anything if it doesnt exist and fail
        // gracefully
        if (!this._subscriptions[subName]) {
            return;
        }

        Object.keys(this._subscriptions[subName])
            .forEach(id => {
                this._subscriptions[subName] && this._subscriptions[subName][id] && this._subscriptions[subName][id](...args);
            });
    }

    /**
     * purge all data in the bus loader
     */
    public purge(): void {
        this._subscriptions = {};
    }

    /**
     * purges a single subscription id endpoint from a subscription
     * name
     *
     * @param subName subscription name
     * @param id subscription id
     */
    private _purgeById(subName: string, id: number) {
        delete this._subscriptions[subName][id];

        // not zero because there will always be the internal _readyForDeletion event
        if (Object.keys(this._subscriptions[subName]).length === 1) {
            this.publish('_readyForDeletion');
            delete this._subscriptions[subName];
        }
    }
}

/**
 * generates a singleton of ids
 */
const IdGenerator = (() => {
    let idValue = 0;

    return () => {
        idValue += 1;
        return idValue;
    };
})();

/**
 * generates a singleton of the bus loader
 */
const Loader = (() => {
    return new EventBusLoader();
})();

export function LoadBus<T extends IReactComponent>(eventNamespace: string): <T extends IReactComponent>(newComponent: T) => void {
    return (CHILD_COMP: ComponentType) => {
        return class extends Component {
            constructor(props: any) {
                super(props);

                // add to the component the bus
                CHILD_COMP.prototype.bus = this.bus;
            }

            // the bus's definition
            public get bus() { return Loader.getBus(eventNamespace); }

            // we arent decorating anything related to the UI so just return the child with
            // all it's props
            public render(): JSX.Element {
                return <CHILD_COMP {...this.props} />;
            }
        };
    };
}