import { ICartState } from '@msdyn365-commerce/global-state';
import debounce from 'lodash/debounce';
import { observable, reaction } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { CartUtilities } from '../Utilities/cart-utils';
import { BusLoader, LoadBus } from '../Utilities/event-bus';
import { filterCartLines } from '../Utilities/subscription-manager';

interface IEditSelectComponentProps {
    currentQuantity: number;
    cartLineId?: string;
    isSubLine?: boolean;
    cart?: ICartState;
    zeroAvailabilityError?: boolean;
    shouldDisableQty?: boolean;
    _updateQuantityRaw(quantity: number): void;
}

/**
 * edit component
 */
@LoadBus('elicit-amount-selector')
@observer
export class ElicitAmountSelector extends React.Component<IEditSelectComponentProps, { quantityState: number }> {

    private bus: BusLoader | undefined;
    @observable private _trackedTotal: number | undefined;

    public constructor(constProps: IEditSelectComponentProps) {
        super(constProps);
        this._setQuantity = debounce(this._setQuantity.bind(this), 500);
        this._upQuantity = this._upQuantity.bind(this);
        this._downQuantity = this._downQuantity.bind(this);
        this._onQuantityChange = this._onQuantityChange.bind(this);
        this._postValidateQuantity = this._postValidateQuantity.bind(this);

        if (this.props.isSubLine) {
            this._trackedTotal = filterCartLines(this.props.cart?.cart).subscriptionLines.length;
            reaction(
                () => [this.props.cart, this.props.cart?.cart, this.props.cart?.cart.CartLines],
                () => {
                    const unrelatedLines = filterCartLines(this.props.cart?.cart).subscriptionLines.filter(line => line.LineId !== this.props.cartLineId);
                    this._trackedTotal = CartUtilities.countCartLineAmount(unrelatedLines);
                }
            );

            this.bus?.subscribe('added-locally', () => {
                this._trackedTotal!++;
            });

            this.bus?.subscribe('removed-locally', () => {
                this._trackedTotal!--;
            });

            this.bus?.subscribe('delta-change-local', (delta: number) => {
                this._trackedTotal! -= delta;
            });
        }

        this.state = { quantityState: this.props.currentQuantity };
    }

    public render(): JSX.Element {
        return (
            <div className={`msc-quantity-selector ${(this.props.zeroAvailabilityError || this.props.shouldDisableQty) ? 'msc-quantity-selector__disabled' : ''}`}>
                <button className='msc-quantity-selector__button msc-quantity-selector__button-decrease' onClick={this._downQuantity}>-</button>
                { /* tslint:disable-next-line: react-a11y-role-has-required-aria-props react-this-binding-issue */}
                <input className='msc-quantity-selector__input' type='number' value={this.state.quantityState} onChange={this._onQuantityChange} onBlur={this._postValidateQuantity} />
                <button className='msc-quantity-selector__button msc-quantity-selector__button-increase' disabled={!this._canAddMore} onClick={this._upQuantity}>+</button>
            </div>
        );
    }

    private get _canAddMore(): boolean {
        if (!this.props.isSubLine) { return true; }
        return this.state.quantityState + this._trackedTotal! < 12;
    }

    private _upQuantity(): void {
        this.setState({ quantityState: this.state.quantityState + 1 });
        this.props.isSubLine && this.bus?.publish('added-locally');
        this._setQuantity();
    }

    private _downQuantity(): void {
        if (this.state.quantityState - 1 < 1) { return; }
        this.setState({ quantityState: this.state.quantityState - 1 });
        this.props.isSubLine && this.bus?.publish('removed-locally');
        this._setQuantity();
    }

    private _onQuantityChange({ target }: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({ quantityState: parseInt(target.value, 10) });
    }

    private _postValidateQuantity({ target }: React.ChangeEvent<HTMLInputElement>): void {
        if (this.props.isSubLine) {
            if ((this._trackedTotal! + parseInt(target.value, 10)) <= 12) {
                this.setState({ quantityState: parseInt(target.value, 10) });
                this.bus?.publish('delta-change-local', this._trackedTotal! - parseInt(target.value, 10));
            } else {
                this.setState({ quantityState: 1 });
            }
        } else {
            if (parseInt(target.value, 10) > 0) {
                this.setState({ quantityState: parseInt(target.value, 10) });
            } else {
                this.setState({ quantityState: 1 });
            }
        }
        this._setQuantity();
    }

    private _setQuantity(): void {
        this.props._updateQuantityRaw(this.state.quantityState);
    }
}
