import { SimpleText } from '@msdyn365-commerce-modules/data-types';
import { CurrentCategoryInput, getCurrentCategory, getSimpleProducts, ProductInput } from '@msdyn365-commerce-modules/retail-actions';
import { CommerceEntityInput, createObservableDataAction, IAction, IActionContext, IActionInput, ICreateActionContext, IUrlTokens } from '@msdyn365-commerce/core';
import { SimpleProduct } from '@msdyn365-commerce/retail-proxy';

/**
 * Calls the Retail API and returns the products
 */
const getProducts = async (productIds: number[] = [], ctx: IActionContext): Promise<SimpleProduct[]> => {
    const productInputs = productIds.map(productId => new ProductInput(productId,ctx.requestContext.apiSettings));
    return getSimpleProducts(productInputs, ctx);
};

/**
 * Creates the input required to make the retail api call
 */
export class GetInput extends CommerceEntityInput implements IActionInput {
    public itemId: number;
    public dataSource: string;
    constructor(Id: number, dataSource: string)  {
        super({
            dataCacheType: 'none',
            cacheObjectType: 'BreadCrumbData',
            cacheKey: 'BreadcrumbData'
        });
        this.itemId = Id;
        this.dataSource = dataSource;
        }
}

/**
 * Creates the input required to make the retail api call
 */
const createInput = (inputData: ICreateActionContext) => {
    if(!(inputData && inputData.requestContext && inputData.requestContext.urlTokens)) {
       throw new Error('No url tokens found');
    }
    const token: IUrlTokens=inputData.requestContext.urlTokens;
    return new GetInput(parseInt(token.itemId || '0',10), token.pageType || '');
};

/**
 * Get breadcrumb data by product id action
 */
export async function getbreadcrumbByProductIdAction(
    ctx: IActionContext,
    productId: number
): Promise<SimpleText> {
    if (!ctx) {
        throw new Error(`getbreadcrumbByProductIdAction - Action context cannot be null/undefined`);
    }
    let productName:SimpleText = {text:''};
    const prodIds: number[] = [productId];
    const response = await getProducts(prodIds, ctx);
    if (response && response.length) {
        productName = {text:response[0].Name || ''};
    }
    return productName;
}

/**
 * Get breadcrumb data by category id action
 */
export async function getbreadcrumbByCategoryIdAction(
    ctx: IActionContext,
    categoryId: number
): Promise<SimpleText> {
    if (!ctx) {
        throw new Error(`getbreadcrumbByCategoryIdAction - Action context cannot be null/undefined`);
    }
    let categoryName:SimpleText = {text:''};
    const input= new CurrentCategoryInput(ctx.requestContext);
    input.categoryId=categoryId;
    const response =  await getCurrentCategory(input,ctx);
    if (response) {
        categoryName = {text:response.Name || ''};
    }
    return categoryName;
}

/**
 * Get breadcrumb data action
 */
export async function getbreadcrumbDataAction(
    input: IActionInput,
    ctx: IActionContext,
): Promise<SimpleText> {
    if (!ctx) {
        throw new Error(`getbreadcrumbDataAction - Action context cannot be null/undefined`);
    }
    let breadcrumbData: SimpleText= {text:''};
    const dataSource = (<GetInput>input).dataSource;
    switch (dataSource.toUpperCase()) {
        case 'CATEGORY':
            breadcrumbData = await getbreadcrumbByCategoryIdAction(ctx, (<GetInput>input).itemId);
            return breadcrumbData;
        case 'PRODUCT':
            breadcrumbData = await getbreadcrumbByProductIdAction(ctx, (<GetInput>input).itemId);
            return breadcrumbData;
        default:
            return breadcrumbData;
    }
}

export default createObservableDataAction({
    action: <IAction<SimpleText>>getbreadcrumbDataAction,
    input: createInput
});