import { getSelectedVariant, SelectedVariantInput } from '@msdyn365-commerce-modules/retail-actions';
import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { AttributeValue, SimpleProduct } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { AttributesForSelectedVariantInput, getAttributesForSelectedVariantAction } from '../../../dataActions/get-attributes-for-selected-variant';

/**
 * Input class for the GetSingleProduct Data Action
 */
export class SingleProductInput implements IActionInput {
    public productId: number;
    public channelId: number;
    public singleProduct: SimpleProduct | undefined;

    constructor(productId: number, channelId: number, singleProduct?: SimpleProduct) {
        this.productId = productId;
        this.channelId = channelId;
        this.singleProduct = singleProduct;
    }

    public getCacheKey = () => `ProductForSinglePLP|${this.productId}`;
    public getCacheObjectType = () => 'Product';
    public dataCacheType = (): CacheType => 'application';
}

/**
 * The createInput method for the singleProductAction
 * @param inputData The input data for the createInput method
 */
export const createSingleProductInput = (inputData: ICreateActionContext<IGeneric<IAny>>): SingleProductInput => {
    if (inputData.config && inputData.config.productId) {
        const productId = inputData.config.productId;
        if (inputData.requestContext.query) {
            inputData.requestContext.query.productId = productId.toString();
        }
        inputData.requestContext.urlTokens.recordId = productId.toString();
        inputData.requestContext.urlTokens.pageType = 'product';
        return new SingleProductInput(
            +productId,
            +inputData.requestContext.apiSettings.channelId,
            undefined);
    }
    throw new Error('Unable to create SingleProductInput, no productId found on module config or query');
};

/**
 * The Action Method for the singleProductAction Data Action
 */
export async function singleProductAction(
    input: SingleProductInput,
    ctx: IActionContext
): Promise<AttributeValue[] | null> {
    try {
        const product = await getSimpleProductVariant(input, ctx);
        return await getAttributesFromSimpleProduct(input, ctx, product);
    } catch (e) {
        ctx.trace(e.message);
        throw new Error('[singleProductAction]Error executing action');
    }
}

async function getSimpleProductVariant(input: SingleProductInput, ctx: IActionContext): Promise<SimpleProduct | undefined | null> {
    const activeProduct: SimpleProduct | undefined = input.singleProduct;
    if (!activeProduct) {
        const selectedVariantInput = new SelectedVariantInput(input.productId, input.channelId);
        return getSelectedVariant(selectedVariantInput, ctx);
    }
    return activeProduct;
}

async function getAttributesFromSimpleProduct(input: SingleProductInput, ctx: IActionContext, productResult: SimpleProduct | undefined | null): Promise<AttributeValue[] | null> {
    if (productResult) {
        const attributesForSelectedVariantInput = new AttributesForSelectedVariantInput(input.productId, input.channelId, productResult);
        return getAttributesForSelectedVariantAction(attributesForSelectedVariantInput, ctx);
    }
    return null;
}

export default createObservableDataAction({
    action: <IAction<AttributeValue[] | null>>singleProductAction,
    input: createSingleProductInput
});