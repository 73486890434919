import { ICoreContext } from '@msdyn365-commerce/core-internal';
import { wineClubShippingLabels } from '../../settings/app.settings';

export type WineClubDataRef = {
    variantPreference: string | null;
    forMyself: boolean | null;
    deliveryType: DeliveryType;
    clubId: number | null;
    tosAgree: boolean;
    pickupLocationId?: string;
};

export type DeliveryType = 'STANDARD' | 'PREMIUM' | 'PICKUP';
export type DeletableKeys = 'variantPreference' | 'forMyself' | 'deliveryType' | 'clubId' | 'tosAgree' | 'pickupLocationId';

/**
 * singleton class responsible for the users selections
 */
export class WineClubDataStore {
    private static instance: WineClubDataStore;

    private readonly LOCAL_STORAGE_LOCATION: string = 'WINE-CLUB-SELECTIONS';

    private wineClubData!: WineClubDataRef;

    private changeEvents: ((newData: WineClubDataRef) => void)[] = [];

    /**
     * get the current pages data
     * @returns singleton data of data store
     */
    public static getInstance(onChange?: (newData: WineClubDataRef) => void): WineClubDataStore {
        if (!WineClubDataStore.instance) {
            WineClubDataStore.instance = new WineClubDataStore();
        }

        if (onChange) {
            WineClubDataStore.instance.changeEvents.push(onChange);
        }

        return WineClubDataStore.instance;
    }

    // tslint:disable-next-line: no-any
    public getDeliveryMapFromConfig(coreContext: ICoreContext<{ [x: string]: any }>): {
        standard: string; premium: string; pickup: string;
    } {
        const config = <wineClubShippingLabels>coreContext.app.config.wineClubShippingLabels || {};
        return {
            standard: config.standard,
            pickup: config.pickup,
            premium: config.premium
        };
    }

    public edit(newData: WineClubDataRef): void {
        this.wineClubData = newData;
        this.changeEvents.forEach(event => event(newData));
        this._save();
    }

    public get(): WineClubDataRef {
        return { ...this.wineClubData };
    }

    public deleteKeys(...keys: DeletableKeys[]): void {
        keys.forEach(key => delete this.wineClubData[key]);
        this.changeEvents.forEach(event => event(this.wineClubData));
        this._save();
    }

    public reset(): WineClubDataStore {
        this.wineClubData = {
            deliveryType: 'STANDARD',
            tosAgree: false,
            forMyself: null,
            clubId: null,
            variantPreference: null
        };
        this.changeEvents.forEach(event => event(this.wineClubData));
        this._save();
        return this;
    }

    public isInResetState(): boolean {
        const {
            deliveryType,
            tosAgree,
            forMyself,
            clubId,
            variantPreference
        } = this.wineClubData;

        return deliveryType === 'STANDARD' &&
            tosAgree === false &&
            forMyself === null &&
            clubId === null &&
            variantPreference === null;
    }

    private constructor() {
        // tslint:disable-next-line: no-typeof-undefined
        if (typeof window !== 'undefined') {
            const previousWineData = window && window.localStorage.getItem(this.LOCAL_STORAGE_LOCATION);

            if (!previousWineData) {
                this.reset();
                return;
            }

            this.wineClubData = JSON.parse(previousWineData);
        }
    }

    private _save(): void {
        // tslint:disable-next-line: no-typeof-undefined
        if (typeof window !== 'undefined') {
            window.localStorage.setItem(this.LOCAL_STORAGE_LOCATION, JSON.stringify(this.wineClubData));
        }
    }
}