import { IMediaGalleryThumbnailItemViewProps, IMediaGalleryThumbnailsViewProps, IMediaGalleryViewProps } from '@msdyn365-commerce-modules/media-gallery';
import { Module, Node } from '@msdyn365-commerce-modules/utilities';
import * as React from 'react';

const _renderThumbnailItem = (thumbnail: IMediaGalleryThumbnailItemViewProps): JSX.Element => {
    const { ThumbnailItemContainerProps, Picture } = thumbnail;

    return (
        <Node {...ThumbnailItemContainerProps}>
            {Picture}
        </Node>
    );
};

const _renderThumbnails = (thumbnails: IMediaGalleryThumbnailsViewProps): JSX.Element => {
    const { ThumbnailsContainerProps, SingleSlideCarouselComponentProps, items } = thumbnails;

    return (
        <Node {...ThumbnailsContainerProps}>
            <Node {...SingleSlideCarouselComponentProps}>
                {items && items.map(_renderThumbnailItem)}
            </Node>
        </Node>
    );
};

const MediaGalleryView: React.FC<IMediaGalleryViewProps> = props => {
    const {CarouselProps, Thumbnails, MediaGallery} = props;

    return (
        <Module {...MediaGallery}>
            <Node {...CarouselProps} />
            {_renderThumbnails(Thumbnails)}
            {props.slots && props.slots.productLink && props.slots.productLink.length > 0 ? props.slots.productLink : ''}
        </Module>
    );
};
export default MediaGalleryView;