import { ActiveCartProductsInput, ArrayExtensions, buildCacheKey, createGetFeatureStateInput, getActiveCartProductsAction, getFeatureState, mapProductInventoryInformation } from '@msdyn365-commerce-modules/retail-actions';
// tslint:disable-next-line: no-duplicate-imports
import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput,  ICommerceApiSettings, ICreateActionContext } from '@msdyn365-commerce/core';
import { getCartState } from '@msdyn365-commerce/global-state';
import { CartLine, ChannelDeliveryOptionConfiguration, CommerceProperty, FeatureState, ProductAvailableQuantity } from '@msdyn365-commerce/retail-proxy';
import { getOrgUnitConfigurationAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/OrgUnitsDataActions.g';
import { getEstimatedAvailabilityAsync, getEstimatedProductWarehouseAvailabilityAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { getChannelDeliveryOptionConfigurationAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/StoreOperationsDataActions.g';
/**
 * GetAvailabilitiesCartlines.overide Input Action
 */

export class ProductAvailabilitiesForCartLineItems implements IActionInput {
    public getCacheKey: () => string;
    public getCacheObjectType: () => string;
    public dataCacheType: () => CacheType;
    private apiSettings: ICommerceApiSettings;
    constructor(apiSettings: ICommerceApiSettings) {
        this.getCacheKey = () => buildCacheKey(`ActiveCartLineItemsAvailability`, this.apiSettings);
        this.getCacheObjectType = () => 'ActiveCartLineItemsAvailability';
        this.dataCacheType = () => 'request';
        this.apiSettings = apiSettings;
    }
}

//===========================================================
// JC: Addition here to support legacy inventory query method
//===========================================================
/**
 * Input class for the getProductAvailabilitiesForSelectedVariant Data Action
 */
export class ProductAvailableQuantityMerge {
    public ProductId?: number;
    public AvailableQuantity?: number;
    public UnitOfMeasure?: string;
    public ExtensionProperties?: CommerceProperty[];
    public ProductAvailableQuantity: ProductAvailableQuantity = {};
    public StockLevelCode?: string;
    public StockLevelLabel?: string;
    public IsProductAvailable: boolean = false;
    public InventLocationId?: string;
}
//===========================================================

const createInput = (inputData: ICreateActionContext): IActionInput => {
    return new ProductAvailabilitiesForCartLineItems(inputData.requestContext.apiSettings);
};

/**
 * Calls the Retail Feature State API and returns a list of feature with isEnabled flag.
 */
const getDeliveryMode = (cartLine: CartLine, featureSate = false, channelDeliveryOptionConfig: ChannelDeliveryOptionConfiguration | undefined, pickupDeliveryMode: string | undefined) => {
    if (!featureSate) {
        return cartLine.DeliveryMode === pickupDeliveryMode;
    } else {
        return cartLine.DeliveryMode === channelDeliveryOptionConfig?.PickupDeliveryModeCodes?.find(deliveryMode => deliveryMode === cartLine.DeliveryMode);
    }
};

/**
 * Calls the Retail API to get the product availabilites for items in the cart
 */
// tslint:disable-next-line:cyclomatic-complexity tslint:disable: max-func-body-length
export async function getAvailabilitiesForCartLineItems(input: ProductAvailabilitiesForCartLineItems, ctx: IActionContext): Promise<ProductAvailableQuantityMerge[]> {
    // If no input is provided fail out
    if (!input) {
        throw new Error('[getAvailabilitiesForCartLineItems]No valid Input was provided, failing');
    }
    const shippingItems = [];
    const bopisItems = [];
    let productAvailabilities:ProductAvailableQuantityMerge[] = [];
    const multiplePickupStoreSwitchName = 'Dynamics.AX.Application.RetailMultiplePickupDeliveryModeFeature';
    let channelDeliveryOptionConfig;
    const cartState = await getCartState(ctx);
    const cart = cartState.cart;
    const channelConfiguration = await getOrgUnitConfigurationAsync({ callerContext: ctx });
    const products = await getActiveCartProductsAction(new ActiveCartProductsInput(), ctx);
    /**
     * Calls the Retail Feature State API and returns a list of feature with isEnabled flag.
     */
    async function getFeature(context: IActionContext): Promise<FeatureState[]> {
        const featureStateInput = createGetFeatureStateInput(ctx);
        return getFeatureState(featureStateInput, context);
    }
    const featureState = await getFeature(ctx);
    const retailMultiplePickUpOptionEnabled = featureState?.find(item => item.Name === multiplePickupStoreSwitchName)?.IsEnabled;
    if (retailMultiplePickUpOptionEnabled) {
        channelDeliveryOptionConfig = await getChannelDeliveryOptionConfigurationAsync({ callerContext: ctx });
    }
    const PickupDeliveryModeCode = channelConfiguration.PickupDeliveryModeCode;
    const EmailDeliveryModeCode = channelConfiguration.EmailDeliveryModeCode;
    if (!cart || !channelConfiguration || !products || products.length === 0) {
        console.log('[getAvailabilitiesForCartLineItems] Not able to get cart OR channelConfiguration or no products in cart');
        ctx.trace('[getAvailabilitiesForCartLineItems] Not able to get cart OR channelConfiguration or no products in cart');
        return [];
    }
    if (cart && cart.Id && cart.CartLines && cart.CartLines.length > 0 && channelConfiguration) {
        for (const cartLine of cart.CartLines) {
            if (cartLine.DeliveryMode && cartLine.DeliveryMode !== '' && getDeliveryMode(cartLine, retailMultiplePickUpOptionEnabled, channelDeliveryOptionConfig, PickupDeliveryModeCode)) {
                bopisItems.push(cartLine);
            } else if (cartLine.DeliveryMode !== EmailDeliveryModeCode) {
                shippingItems.push(cartLine);
            }
        }
    }
    if (shippingItems && shippingItems.length > 0) {
        let productIds: number[] = shippingItems.map(x => x.ProductId ? x.ProductId : 0);
        productIds = ArrayExtensions.unique(productIds);
        const shippingProductAvailabilities = await getEstimatedAvailabilityAsync({ callerContext: ctx, bypassCache: 'get' }, { ProductIds: productIds, DefaultWarehouseOnly: true });
        if (shippingProductAvailabilities && shippingProductAvailabilities.ProductWarehouseInventoryAvailabilities && shippingProductAvailabilities.ProductWarehouseInventoryAvailabilities.length > 0) {
            //===========================================================
            // JC: Map below is using total available for product
            // availability. Need to change to physical before.
            //===========================================================
            shippingProductAvailabilities?.ProductWarehouseInventoryAvailabilities.forEach((product) => {
                product.TotalAvailable = product.PhysicalAvailable;
            });
            //===========================================================
            // JC: Addition here to support legacy inventory query method
            //===========================================================
            productAvailabilities = mapProductInventoryInformation(ctx, shippingProductAvailabilities?.ProductWarehouseInventoryAvailabilities);
            // translate data to support deprecated retail service call getProductAvailabilitiesAsync
            productAvailabilities = productAvailabilities?.map((product) => {
                return {...product, ProductId: product.ProductAvailableQuantity.ProductId, AvailableQuantity: product.ProductAvailableQuantity.AvailableQuantity, ExtensionProperties: product.ProductAvailableQuantity.ExtensionProperties};
            });
            //===========================================================
        }
    }
    if (bopisItems && bopisItems.length > 0) {
        for (const bopisItem of bopisItems) {
            const productWarehouse = {
                ProductId: bopisItem.ProductId,
                InventLocationId: bopisItem.WarehouseId,
                DataAreaId: ctx.requestContext.channel && ctx.requestContext.channel.InventLocationDataAreaId ? ctx.requestContext.channel.InventLocationDataAreaId : undefined
            };
            const getProductWarehouseAvail = await getEstimatedProductWarehouseAvailabilityAsync({ callerContext: ctx, bypassCache: 'get', queryResultSettings: {} }, [productWarehouse]);
            if (getProductWarehouseAvail && getProductWarehouseAvail.ProductWarehouseInventoryAvailabilities && getProductWarehouseAvail.ProductWarehouseInventoryAvailabilities.length > 0) {
                //===========================================================
                // JC: Map below is using total available for product
                // availability. Need to change to physical before.
                //===========================================================
                getProductWarehouseAvail?.ProductWarehouseInventoryAvailabilities.forEach((product) => {
                    product.TotalAvailable = product.PhysicalAvailable;
                });
                const productWarehouseMapping = mapProductInventoryInformation(ctx, getProductWarehouseAvail?.ProductWarehouseInventoryAvailabilities);
                if (productWarehouseMapping && productWarehouseMapping.length) {
                    for (const item of productWarehouseMapping) {
                        productAvailabilities.push(item);
                    }
                }
            }
        }
    }
    if (productAvailabilities && productAvailabilities.length > 0) {
        return productAvailabilities;
    } else {
        ctx.trace('[getAvailabilitiesForCartLineItems] unable to get availabilites for product');
        return [];
    }
}
//===========================================================
// JC: Changed type from ProductAvailableQuantity to ProductAvailableQuantityMerge
//===========================================================
export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-availabilities-cartlines',
    action: <IAction<ProductAvailableQuantityMerge[]>>getAvailabilitiesForCartLineItems,
    input: createInput
});
//===========================================================