//==============================================================================
// Analytics Event Dispatcher
//
// This module receives events and forwards them to the appropriate handlers
// Subscriptions are managed using a subscriber ID to aid in unsubscribing
//==============================================================================

// This totally destroys the plugin pattern, but it's difficult to use that pattern
// in a module system, particularly with server-side rendering-friendly webpack chunks.
import { init as GA_init } from './google-analytics';

//==============================================================================
//==============================================================================
// tslint:disable-next-line: no-any
type IEventHandler = (eventData: any) => void;

// List of subscribers to a single event
interface IEventSubscribers {
    [subscriber: string]: IEventHandler;
}

//==============================================================================
//==============================================================================

// List of subscriptions, organized by event name and then subscriber name
const subscriptions: { [eventName: string]: IEventSubscribers } = {};

//----------------------------------------------------------
//----------------------------------------------------------
// tslint:disable-next-line: no-any
export function publish(eventName: string, eventData: any): void {
    if (subscriptions[eventName]) {
        // Get the list of subscribers for this event
        const subscribers = Object.keys(subscriptions[eventName]);

        // Notify each subscriber
        subscribers.forEach(subscriber => subscriptions[eventName][subscriber](eventData));
    }
}

//----------------------------------------------------------
//----------------------------------------------------------
export function subscribe(subscriber: string, eventName: string, handler: IEventHandler): void {
    // If it's a new event, create an entry
    if (!subscriptions[eventName]) {
        subscriptions[eventName] = {};
    }

    // Ensure this subscriber isn't already subscribed to this event
    if (subscriptions[eventName][subscriber]) {
        throw new Error(`Duplicate subscription request for ${subscriber}: ${eventName}`);
    }

    // Add the subscription
    subscriptions[eventName][subscriber] = handler;
}

//----------------------------------------------------------
// Unsubscribe to a single event
//----------------------------------------------------------
export function unsubscribe(subscriber: string, eventName: string): void {
    if (subscriptions[eventName] && subscriptions[eventName][subscriber]) {
        delete subscriptions[eventName][subscriber];
    }
}

//----------------------------------------------------------
// Perform all init functions here (ugh!)
//----------------------------------------------------------
GA_init();