import { ICookieContext, ICookieSetOptions } from '@msdyn365-commerce/core-internal';
import { cartType } from './cartmanager.props.autogenerated';

type CookieExport = {
    cookieType: cartType;
    cartId: string;
};

/**
 * class to maintain cart <--> cookie interactions
 */
export class CartDataCookie {
    public cookieContext: ICookieContext;
    public cookieType: cartType | undefined;
    public cartId: string | undefined;
    private readonly _PREFIX: string = 'temp:cart-data-cookie';

    constructor(cookieContext: ICookieContext, cookie?: string) {
        this.cookieContext = cookieContext;

        if (cookie) {
            const parsedCookie: CookieExport = JSON.parse(cookie);
            this.cookieType = parsedCookie.cookieType;
            this.cartId = parsedCookie.cartId;
        }
    }

    public load(cartName: cartType): boolean {
        // @ts-ignore
        // i dont know why all these values aren't typed - true param is DNE
        const cookie = this.cookieContext.get<string>(`${this._PREFIX}-${cartName}`, true);
        if (cookie.status === 'FOUND' && cookie.value) {

            // more poor typing on the cookie API
            const parsedCookie: CookieExport = <CookieExport><unknown>cookie.value;
            this.cartId = parsedCookie.cartId;
            this.cookieType = parsedCookie.cookieType;
            return true;
        }

        return false;
    }

    public clear(cartName: cartType): void {
        // @ts-ignore
        this.cookieContext.remove(`${this._PREFIX}-${cartName}`, true);
    }

    public save(): void {
        this.cookieContext.set(`${this._PREFIX}-${this.cookieType}`, JSON.stringify({
            cartId: this.cartId,
            cookieType: this.cookieType
        }), <ICookieSetOptions>{
            path: '/',
            maxAge: 31556952000, /* 1 year */
            isEssential: true
        });
    }
}