import { IContentBlockViewProps } from '@msdyn365-commerce-modules/content-block/dist/types/modules/content-block/content-block';
import { Module, Node } from '@msdyn365-commerce-modules/utilities';
import * as React from 'react';
import { IClickableContainerLinkData, IContentBlockProps } from '../definition-extensions/content-block.ext.props.autogenerated';

const ContentBlockView: React.FC<IContentBlockViewProps & IContentBlockProps<{}>> = props => {
    const { contentBlockContainer, imageContainer, detailsContainer, title, text, links, image, config } = props;
    const clickableContainerLink = config.clickableContainerLink;
    const content = (
        <Module {...contentBlockContainer}>
            <Node {...imageContainer}>
                {image}
            </Node>
            <Node {...detailsContainer}>
                {title}
                {text}
                {links}
            </Node>
        </Module>
    );
    if (clickableContainerLink && clickableContainerLink.linkUrl.destinationUrl) {
        return (
            <a
                className='ms-content-block__clickable'
                href={clickableContainerLink.linkUrl.destinationUrl}
                target={_target(clickableContainerLink)}
                aria-label={clickableContainerLink.ariaLabel}
            >
                {content}
            </a>
        );
    } else {
        return content;
    }
};

function _target(link: IClickableContainerLinkData): string | undefined {
    return (link.openInNewTab) ? '_blank' : undefined;
}

export default ContentBlockView;
