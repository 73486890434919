import * as React from 'react';

import { Image } from '@msdyn365-commerce/core';
import { CartLine, SimpleProduct } from '@msdyn365-commerce/retail-proxy';
import { filterCartLines } from '../../../Utilities/subscription-manager';
import { ISubscriptionsMinicartData } from '../subscriptions-minicart.data';
import { ISubscriptionsMinicartProps } from '../subscriptions-minicart.props.autogenerated';

interface IBottleCountDisplay {
    subscriptionProps: ISubscriptionsMinicartProps<ISubscriptionsMinicartData>;
}

/**
 * declaration for component that renders a list that displays the percent off
 */
export class BottleCountDisplay extends React.Component<IBottleCountDisplay> {

    private readonly _maxBottleCount: number = 12;

    public render(): JSX.Element {
        return (
            <div className='subscription-minicart-bottle-display'>
                {this._fillBottleList(this._generateBottleListFromProducts().slice(0, this._maxBottleCount))}
            </div>
        );
    }

    private _fillBottleList(bottleList: JSX.Element[]): JSX.Element[] {
        for (let newBottleIndex = bottleList.length; newBottleIndex < this._maxBottleCount; newBottleIndex++) {
            bottleList.push(
                (
                    <div className='subscription-minicart-bottle-item'>
                        <div className='subscription-minicart-bottle-item-empty' />
                        {this._specializeIndex(newBottleIndex)}
                    </div>
                )
            );
        }
        return bottleList;
    }

    private _generateBottleListFromProducts(): JSX.Element[] {
        let rollingIndex = 0;
        const fragments: JSX.Element[] = [];
        this._cartFilteredLines.subscriptionLines?.forEach((line) => {

            const gridSettings = this.props.subscriptionProps.context.actionContext.requestContext.gridSettings;
            const product = this._getProductFromLine(line);
            for (let lineIdx = 0; lineIdx < line.Quantity!; lineIdx++) {
                fragments.push((
                    <div className='subscription-minicart-bottle-item'>
                        {gridSettings && product &&
                            <Image
                                src={product.PrimaryImageUrl || ''}
                                alt={product.Name || ''}
                                imageSettings={this.props.subscriptionProps.config.imageSettings}
                                gridSettings={gridSettings}
                            />
                        }
                        {this._specializeIndex(rollingIndex)}
                    </div>
                ));
                rollingIndex++;
            }
        });
        return fragments;
    }

    private _getProductFromLine(line: CartLine): SimpleProduct | undefined {
        const { products: { result: products } } = this.props.subscriptionProps.data;
        return products?.find(product => product.ItemId === line.ItemId);
    }

    private _specializeIndex(currentIndex: number): JSX.Element | null {
        const {
            subscriptionsMinicart__discount10Percent,
            subscriptionsMinicart__discount15Percent
        } = this.props.subscriptionProps.resources;
        if (currentIndex === 3) {
            return <div className='subscription-minicart-bottle-discount'>{subscriptionsMinicart__discount10Percent}</div>;
        } else if (currentIndex === 11) {
            return <div className='subscription-minicart-bottle-discount'>{subscriptionsMinicart__discount15Percent}</div>;
        }
        return null;
    }

    private get _cartFilteredLines(): {
        subscriptionLines: CartLine[];
        lines: CartLine[];
    } {
        return filterCartLines(this.props.subscriptionProps.data.cart.result?.cart);
    }
}