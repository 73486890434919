// ==============================================================================
// Extremely simple HTML5 video player that only handles MP4s
//
// This is a less powerful replacement for the ambient-video module.
// ambient-video resets videos to the first frame upon completion, which is
// usually undesirable. For videos that aren't looped, leaving them on the last
// frame is the standard.
//
// A better long-term solution would be to extend both the core Player component
// and the ambient-video module to make the reset to frame 0 optional.
// Given the number of files involved and their locations, that doesn't seem
// to be viable without an SSK update.
// ==============================================================================

import * as React from 'react';

import { IVideoBackgroundData } from './video-background.data';
import { IVideoBackgroundProps } from './video-background.props.autogenerated';

// ==============================================================================
// CLASS DEFINITION
// ==============================================================================
/**
 * VideoBackground component
 * @extends {React.PureComponent<IVideoBackgroundProps<IVideoBackgroundData>>}
 */
class VideoBackground extends React.PureComponent<IVideoBackgroundProps<IVideoBackgroundData>> {

    // =============================================================================
    // PUBLIC METHODS
    // =============================================================================

    // --------------------------------------------------------------
    // --------------------------------------------------------------
    public render(): JSX.Element | null {
        const { config } = this.props;
        const className = config.className || '';

        let videoUrl = '';
        try {
            videoUrl = config.video!._links!.binaryReferences![0].sourceHref || '';
        } catch(e) {
            console.warn('Background Video: No video defined');
        }

        // Display the video, if one has been defined
        if (videoUrl) {
            return (
                <video className={className} autoPlay={true} muted={true} loop={!!config.loop}>
                    <source src={videoUrl} type='video/mp4' />
                </video>
            );
        }

        return null;
    }
}

export default VideoBackground;