import { observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';

// ==============================================================================
// TYPE DEFINITIONS
// ==============================================================================

interface IMailingInputGroupProps {
    label?: string;
    name: string;
    isValid?: boolean;
    placeholder?:string;
    validationMessage?: string;
    initialValue: string;
    errorMessage: string;
    onValueChange(newValue: string): void;
    onLoseFirstFocus(labelBlurred: string): void;
}

// ==============================================================================
// CLASS DEFINITIONS
// ==============================================================================

/**
 * class definition for a control group for mailing
 */
@observer
export class MailingInputGroup extends React.Component<IMailingInputGroupProps> {

    // ==========================================================================
    // FIELDS
    // ==========================================================================

    @observable private _internalValue: string = '';

    // ==========================================================================
    // PUBLIC METHODS
    // ==========================================================================

    public constructor(props: IMailingInputGroupProps) {
        super(props);
        this._internalValue = props.initialValue;

        this._onInputChange = this._onInputChange.bind(this);
        this._onBlur = this._onBlur.bind(this);
    }

    public render(): JSX.Element {
        return (
            <div className='mailing-list-redirect-form-input-group'>
                <label className='mailing-list-redirect-form-label'>{this.props.label}</label>
                <input
                    className='mailing-list-redirect-form-input'
                    type='text'
                    value={this._internalValue}
                    onChange={this._onInputChange}
                    onBlur={this._onBlur}
                    placeholder={this.props.placeholder}
                />
                {this._renderValidation()}
            </div>
        );
    }

    // ==========================================================================
    // COMPONENT EVENTS
    // ==========================================================================

    private _onBlur(): void {
        this.props.onLoseFirstFocus(this.props.name);
    }

    private _onInputChange(event: React.ChangeEvent<HTMLInputElement>): void {
        this._internalValue = event.target.value;
        this.props.onValueChange(event.target.value);
    }

    // ==========================================================================
    // PRIVATE METHODS
    // ==========================================================================

    private _renderValidation(): JSX.Element | void {
        if (!this.props.isValid && this.props.isValid !== undefined) {
            return (
                <p className='mailing-list-redirect-form-error'>
                    {this._validationMessage}
                </p>
            );
        }
    }

    private get _validationMessage(): string {
        return (this.props.validationMessage) ? this.props.validationMessage : this.props.errorMessage;
    }

}