/*---------------------------------------------------------------------------------------------
  Adds products to the cart based on URL query parameters
 *--------------------------------------------------------------------------------------------*/
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';

import { Waiting } from '@msdyn365-commerce-modules/utilities';
import { RichTextComponent } from '@msdyn365-commerce/core';

import CallToAction from '../../components/call-to-action';
import { CartUtilities } from '../../Utilities/cart-utils';

import { IAddToCartByUrlData } from './add-to-cart-by-url.data';
import { IAddToCartByUrlProps } from './add-to-cart-by-url.props.autogenerated';

//==============================================================================
// INTERFACES
//==============================================================================
interface IProduct {
    id: number;
    quantity: number;
}

//==============================================================================
// CLASS DEFINITION
//==============================================================================
/**
 *
 * AddToCartByUrl component
 * @extends {React.Component<IAddToCartByUrlProps<IAddToCartByUrlData>>}
 */
@observer
class AddToCartByUrl extends React.Component<IAddToCartByUrlProps<IAddToCartByUrlData>> {

    private errors: number = 0;
    @observable private totalItems: number = 0;
    @observable private isInProgress: boolean = true;

    //==============================================================================
    // PUBLIC METHODS
    //==============================================================================

    //----------------------------------------------------------
    // Use componentDidMount to ensure execution on the client
    //----------------------------------------------------------
    public componentDidMount(): void {
        const productsToAdd = this._getProductsToAdd();

        // Calculate the total number of items by counting quantities
        this.totalItems = productsToAdd.reduce(
            (count, entry) => {
                return count + entry.quantity;
            },
            0
        );

        // Start adding (if there's anything to add)
        if (this.totalItems) {
            this._addItemsToCart(productsToAdd);
        }
    }

    //----------------------------------------------------------
    //----------------------------------------------------------
    public render(): JSX.Element | null {

        // No products? Don't do anything.
        if (!this.totalItems) {
            return null;
        }

        // Still adding? Display a status indicator
        if (this.isInProgress) {
            return this._inProgress();
        }

        return (
            <div className='add-by-url'>
                {this._renderCompletion()}
                {this._renderCTA()}

            </div>
        );
    }

    //==============================================================================
    // PRIVATE METHODS
    //==============================================================================

    //----------------------------------------------------------
    // Determine which products, if any, to add to the cart
    //----------------------------------------------------------
    private _getProductsToAdd(): IProduct[] {

        // Split the "add" query param into individual entries
        const query = this.props.context.request.query;
        const entries = (query && query.add) ?
            query.add.split(',').map(entry => entry.trim()) :
            [];

        // Break out the optional quantity from each entry
        return entries.map(entry => {
            const split = entry.split(':');

            return {
                id: +split[0],
                quantity: (+split[1] && !isNaN(+split[1])) ? +split[1] : 1
            };
        });
    }

    //----------------------------------------------------------
    // Display a means to jump to the cart page, since this
    // module doesn't work properly there.
    //----------------------------------------------------------
    private _renderCTA(): JSX.Element | null {
        const link = this.props.config.cartLink;
        if (!link) {
            return null;
        }

        return (
            <div className='add-by-url-cta'>
                <CallToAction
                    title={link.linkText}
                    href={link.linkUrl.destinationUrl}
                    openInNewTab={link.openInNewTab}
                    aria-label={link.ariaLabel ? link.ariaLabel : link.linkText}
                />
            </div>
        );
    }

    //----------------------------------------------------------
    // The operation was completed. Based on how successful it
    // was, display the appropriate content.
    //----------------------------------------------------------
    private _renderCompletion(): JSX.Element {

        // No errors
        if (!this.errors) {
            return this._completeSuccess();
        }

        // Some errors
        if (this.errors < this.totalItems) {
            return this._partialSuccess();
        }

        // All errors
        return this._completeFailure();
    }

    //----------------------------------------------------------
    //----------------------------------------------------------
    private _inProgress(): JSX.Element {
        return (
            <div className='add-by-url-working'>
                <Waiting className='msc-waiting-circular msc-waiting-lg' />
                <RichTextComponent text={this.props.config.inProgressText} />
            </div>
        );
    }

    //----------------------------------------------------------
    //----------------------------------------------------------
    private _completeSuccess(): JSX.Element {
        const bareContent = this.props.config.completedSuccessfullyText || '';
        const content = bareContent
            .replace(/\{total\}/g, `${this.totalItems}`)
            .replace(/\{successful\}/g, `${this.totalItems}`);

        return (
            <div className='add-by-url-success'>
                <RichTextComponent text={content} />
            </div>
        );
    }

    //----------------------------------------------------------
    //----------------------------------------------------------
    private _partialSuccess(): JSX.Element {
        const bareContent = this.props.config.completedWithErrorsText || '';
        const content = bareContent
            .replace(/\{total\}/g, `${this.totalItems}`)
            .replace(/\{successful\}/g, `${this.totalItems - this.errors}`)
            .replace(/\{errors\}/g, `${this.errors}`);

        return (
            <div className='add-by-url-partial-success'>
                <RichTextComponent text={content} />
            </div>
        );
    }

    //----------------------------------------------------------
    //----------------------------------------------------------
    private _completeFailure(): JSX.Element {
        const bareContent = this.props.config.completeFailureText || '';
        const content = bareContent
            .replace(/\{total\}/g, `${this.totalItems}`)
            .replace(/\{successful\}/g, `${this.totalItems - this.errors}`)
            .replace(/\{errors\}/g, `${this.errors}`);

        return (
            <div className='add-by-url-failure'>
                <RichTextComponent text={content} />
            </div>
        );
    }

    //----------------------------------------------------------
    // Adds items to cart in series, one item at a time
    //
    // @TODO/dg: Add better error handling and retries
    //----------------------------------------------------------
    private _addItemsToCart(itemList: IProduct[]): void {

        // Add each item separately (retail server accepts multiple lines, but the high level data actions don't)
        // We use reduce to create a chain of promises that will be executed serially
        const promiseChain = itemList.reduce(
            (promise, product) => {
                // Ensure there's a "valid" product ID (at least a number)
                if (product.id && !isNaN(product.id)) {

                    // Add on to the promise chain
                    // Use finally to ensure that a failed promise won't cause the chain to stop running
                    return promise.finally(() => {
                        return CartUtilities.elicitAddItemIdToCart(
                            {
                                recordId: product.id,
                                amount: product.quantity,
                                context: this.props.context
                            },
                            () => { throw new Error('Failed!'); }
                        ).catch(() => {
                            this.errors += product.quantity;
                        });
                    });
                }

                // Don't add on to the promise chain if there wasn't a valid product
                return promise;
            },
            Promise.resolve()
        );

        // tslint:disable-next-line: no-floating-promises
        promiseChain.finally(() => {
            this.isInProgress = false;
        });
    }
}

export default AddToCartByUrl;