import { GetCheckoutCartInput } from '@msdyn365-commerce-modules/retail-actions';
import { ICoreContext } from '@msdyn365-commerce/core';
import { ICartState } from '@msdyn365-commerce/global-state';
import { AsyncResult, AttributeValue, Cart, CartLine, CommerceProperty } from '@msdyn365-commerce/retail-proxy';
import { updateAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';
import { SubscriptionCommerceValues } from '../common/subscription-commerce.values';

export async function updateSubscription(cart: AsyncResult<ICartState>, commerceValues: CommerceProperty[], context: ICoreContext): Promise<void> {
    const cartState = cart && cart.status === 'SUCCESS' && cart.result;
    if (
        !cartState ||
        !cartState.cart
    ) {
        context.telemetry.error('Failed to add subscription properties to Cart. Unable to load Cart');
    } else {
        commerceValues.forEach(newValue => { findAndUpdateLine(newValue, cartState.cart); });

        const updatedCart = await updateAsync({ callerContext: context.actionContext }, cartState.cart);
        context.actionContext.update(new GetCheckoutCartInput(context.request.apiSettings), updatedCart);

        await (await cart).refreshCart({});
    }
}

export function isProductSubscribable(productAttributes?: AttributeValue[]): boolean {
    return !!productAttributes?.find(attr => attr.Name === SubscriptionCommerceValues.SUBSCRIPTION_PRODUCT)?.BooleanValue;
}

export function getSubscriptionValues(cart?: Cart): { frequency: string | undefined; name: string | undefined } {
    const freq = cart?.ExtensionProperties?.find(ext => ext.Key === SubscriptionCommerceValues.SUBSCRIPTION_FREQUENCY);
    const name = cart?.ExtensionProperties?.find(ext => ext.Key === SubscriptionCommerceValues.SUBSCRIPTION_NAME);

    return { frequency: freq?.Value?.StringValue, name: name?.Value?.StringValue };
}

export function isLineSubscriptionLine(line?: CartLine): boolean {
    return !!line?.ExtensionProperties?.find(ext => ext.Key === SubscriptionCommerceValues.SUBCRIPTION_LINE);
}

interface ICategorizedLines {
    subscriptionLines: CartLine[];
    lines: CartLine[];
}
export function filterCartLines(cart?: Cart): ICategorizedLines {
    const output: ICategorizedLines = {
        subscriptionLines: [],
        lines: [],
    };

    cart?.CartLines?.forEach(line => {
        const isSubscriptionLine = isLineSubscriptionLine(line);
        if (isSubscriptionLine) {
            output.subscriptionLines.push(line);
        } else {
            output.lines.push(line);
        }
    });

    return output;
}

/**
 * NOTE: WILL ONLY CLEAR TOP LEVEL NOT ITEMS
 */
export async function clearSubscription(cartState?: ICartState, context?: ICoreContext): Promise<void> {
    if (!cartState || !context) { return; }

    const cart: Cart = cartState.cart;

    // add keys here to erase their string values
    const editObj = {
        [SubscriptionCommerceValues.SUBSCRIPTION_NAME]: true,
        [SubscriptionCommerceValues.SUBSCRIPTION_FREQUENCY]: true
    };

    // ensure extprops exists
    cart.ExtensionProperties = cart.ExtensionProperties || [];
    cart.ExtensionProperties.forEach(extension => {
        if (editObj[extension.Key!] && extension.Value) {
            extension.Value.StringValue = '';
        }
    });
    const updatedCart = await updateAsync({ callerContext: context.actionContext }, cart);
    context.actionContext.update(new GetCheckoutCartInput(context.request.apiSettings), updatedCart);
}

// im expecting to eventually turn this into a namespace class with a bunch of commerce attribute transformations
// but its only one now so no point
function findAndUpdateLine(changedProperty: CommerceProperty, cart: Cart): void {
    cart.ExtensionProperties = cart.ExtensionProperties || [];
    const property = cart.ExtensionProperties.find(extension => extension.Key === changedProperty.Key);
    if (property) {
        property.Value = changedProperty.Value;
    } else {
        cart.ExtensionProperties.push(changedProperty);
    }
}
