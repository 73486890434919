
/**
 * internal subscription name values to be used
 *
 * authors should not be exposed to these values
 */
export enum SubscriptionCommerceValues {

    /**
     * commerce attribute name
     */
    SUBSCRIPTION_NAME = 'Subscription Name',

    /**
     * commerce attribute name
     */
    SUBSCRIPTION_FREQUENCY = 'Frequency',

    /**
     * commerce line attribute for identifying is subscription
     */
    SUBCRIPTION_LINE = 'IsSubscription',

    SUBSCRIPTION_PRODUCT = 'Add to Subscription',

    SUBSCRIPTION_DEFAULT_NAME = 'My Wine Box'
}