import { RichTextComponent } from '@msdyn365-commerce/core';
import * as React from 'react';
import { IWineClubContentData } from './wine-club-content.data';
import { IWineClubContentProps } from './wine-club-content.props.autogenerated';

/**
 *
 * WineClubContent component
 * @extends {React.Component<IWineClubContentProps<IWineClubContentData>>}
 */
class WineClubContent extends React.Component<IWineClubContentProps<IWineClubContentData>> {

    // ------------------------------------------------------
    // ------------------------------------------------------
    public render(): JSX.Element | null {
        const { config } = this.props;
        return (
            <div className={`wine-club-content ${config.className}`}>
                <div className='wine-club-content-left'>
                    <RichTextComponent text={config.leftRichText as string}/>
                </div>
                <div className='wine-club-content-right'>
                    <a className='wine-club-content-button' href={`tel:${config.wineClubPhoneNumber}`}>Call to Join: {config.wineClubPhoneNumber}</a>
                    <RichTextComponent text={config.rightRichText as string}/>
                </div>
            </div>
        );
    }
}

export default WineClubContent;
