import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import getAttributesForSelectedVariant, { AttributesForSelectedVariantInput, createAttributesForSelectedVariantInput } from './get-attributes-for-selected-variant';

const AttributeEventIntro: string = 'INTRODUCTION';
const AttributeEventBody: string = 'BODY';

export interface IEventDescription {
    intro: string;
    body: string;
}

/**
 * Event Description Action Input
 */
export class EventDescriptionInput implements IActionInput {
  public attributesInput: AttributesForSelectedVariantInput;
  constructor(attrib: AttributesForSelectedVariantInput) {
    this.attributesInput = attrib;
  }
  public getCacheKey = () =>
    `${this.attributesInput.productId}:${this.attributesInput.channelId}:${this.attributesInput.catalogId}:EventDescription`;
  public getCacheObjectType = () => 'ProductAttributeValue';
  public dataCacheType = (): CacheType => 'request';
}

export async function getEventDescription(
  input: EventDescriptionInput,
  ctx: IActionContext
): Promise<IEventDescription> {
  const attributes = await getAttributesForSelectedVariant(
    input.attributesInput,
    ctx
  );

  const eventDescription: IEventDescription = {
    intro: '',
    body: ''
  };

  // Event Intro
  const eventIntro = attributes.find(
    attribute =>
      attribute.Name!.toUpperCase() === AttributeEventIntro
  );

  if (eventIntro && eventIntro.TextValue) {
    eventDescription.intro = eventIntro.TextValue;
  }

  // Event Body
  const eventBody = attributes.find(
    attribute =>
      attribute.Name!.toUpperCase() === AttributeEventBody
  );

  if (eventBody && eventBody.TextValue) {
    eventDescription.body = eventBody.TextValue;
  }

  return eventDescription;
}

// ===========================================================================
// ===========================================================================
const createEventDescriptionInput = (
  inputData: ICreateActionContext<IGeneric<IAny>>
): IActionInput => {
  const selectedVariant = createAttributesForSelectedVariantInput(inputData);
  return new EventDescriptionInput(selectedVariant);
};

export default createObservableDataAction({
  action: <IAction<IEventDescription>>getEventDescription,
  input: createEventDescriptionInput
});
