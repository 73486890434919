/**
 * enum to define all message types used in INotificationMessage
 */
export const enum NotificationType {
    info = 'info',
    success = 'success',
    warning = 'warning',
    error = 'error',
    validation = 'validation',
    none = 'none'
}
