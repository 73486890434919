import { ICoreContext } from '@msdyn365-commerce/core';

/**
 * ProductType for checking product Type
 */
// tslint:disable-next-line: no-unnecessary-class
export default class ProductType {
    public static EventDeliveryMode:string = '40.5';
    private isWine:boolean = false;
    private isEvent:boolean = false;
    private isGiftCard:boolean = false;

    // TODO: Temporary solution till we get better solution in checking product type in Cart
    public check(DeliveryMode: string | undefined | null, context?: ICoreContext):void {
        if (DeliveryMode && DeliveryMode === (context?.request.channel?.EmailDeliveryModeCode || '40')) {
            this.isGiftCard = true;
        } else if (DeliveryMode && DeliveryMode === ProductType.EventDeliveryMode) {
            this.isEvent = true;
        } else {
            this.isWine = true;
        }
    }

    public isWineProduct(): boolean {
        return this.isWine && !this.isEvent && !this.isGiftCard;
    }

    public isMixedProduct(): boolean {
        return Number(this.isWine) + Number(this.isEvent) + Number(this.isGiftCard) >= 2;
    }
}
