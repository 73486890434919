//==============================================================================
//==============================================================================
import { IComponentProps } from '@msdyn365-commerce/core';
import { DiscountLine, IDictionary, SimpleProduct } from '@msdyn365-commerce/retail-proxy';

import { brandCodeParser } from '../brand-code-parser';

import { subscribe } from './analytics-dispatcher';
import * as GA from './ga-typings';

//==============================================================================
// CONSTANTS
//==============================================================================
const SUBSCRIBER_NAME = 'GOOGLE_ANALYTICS';
const CURRENCY_CODE = 'USD';

const UNKNOWN_VALUE = 'unknown';

//==============================================================================
// INTERFACES
//==============================================================================

// Data passed to events that deal with a single product
interface ISingleProductProps extends IComponentProps {
    product: SimpleProduct;
    quantity: number;
    price: number;
    attributes: IDictionary<string> | undefined;
    subscription: boolean;
    frequency?: string;
    discounts?: DiscountLine[];
    category?: string;
}

// Data passed to events that deal with multiple products
interface IMultiProductProps extends IComponentProps {
    confirmationId: string | undefined;

    // Transactions
    id: string;            // The transaction ID
    revenue: number;       // Specifies the total revenue or grand total associated with the transaction
    tax: number;           // The total tax associated with the transaction
    shipping: number;      // The shipping cost associated with the transaction
    affiliation?: string;   // The store or affiliation from which this transaction occurred
    coupon?: string;        // The transaction coupon redeemed with the transaction

    // Impressions
    list?: string;
    category?: string;

    // Checkout
    step?: number;

    // Common
    products: ISingleProductProps[];
}

interface IExtendedProduct extends GA.IEECProduct {
    subscribe?: boolean;    // Flag denoting that the item is a subscription rather than a one-time purchase
    frequency?: string;     // If an item is part of a subscription, include the frequency of the subscription
}

// tslint:disable-next-line: no-any
declare const dataLayer: any[] | undefined;

//==============================================================================
// Common Helpers
//==============================================================================
function formatProductData(data: ISingleProductProps): IExtendedProduct {

    // Convert internal event data to GA event data
    const product: IExtendedProduct = {
        id: data.product.ItemId || UNKNOWN_VALUE,
        name: data.product.Name || UNKNOWN_VALUE,
        brand: brandCodeParser({ description: data.product.Description, appContext: data.context }) || UNKNOWN_VALUE,
        variant: (data.attributes && data.attributes['Wine Bottle Size']) || UNKNOWN_VALUE,       // Size for wine
        price: (data.price !== undefined) ? data.price : data.product.Price,
        quantity: data.quantity,
    };

    // Add optional entries
    data.category && (product.category = data.category);
    data.discounts && (product.coupon = data.discounts.map(discount => discount.OfferName).join(','));

    if (data.subscription) {
        product.subscribe = true;
        data.frequency && (product.frequency = data.frequency);
    }

    return product;
}

//==============================================================================
// EVENT HANDLERS
//==============================================================================

//----------------------------------------------------------
// Add to Cart
//----------------------------------------------------------
function addToCart(data: ISingleProductProps): void {

    // Convert internal event data to GA event data
    const product: IExtendedProduct = formatProductData(data);

    // Create an event rather than directly pushing to the data layer to enforce typing
    const event: GA.IAddToCartEvent = {
        event: 'addToCart',
        ecommerce: {
            currencyCode: CURRENCY_CODE,
            add: {
                products: [product]
            },
        }
    };

    if (typeof dataLayer !== 'undefined') {
        dataLayer.push(event);
    }
}

//----------------------------------------------------------
// Remove from Cart
//----------------------------------------------------------
function removeFromCart(data: ISingleProductProps): void {

    // Convert internal event data to GA event data
    const product: IExtendedProduct = formatProductData(data);

    // Create an event rather than directly pushing to the data layer to enforce typing
    const event: GA.IRemoveFromCartEvent = {
        event: 'removeFromCart',
        ecommerce: {
            remove: {
                products: [product]
            },
        }
    };

    if (typeof dataLayer !== 'undefined') {
        dataLayer.push(event);
    }
}

//----------------------------------------------------------
// Product Detail View (PDP visited)
//----------------------------------------------------------
function productDetailView(data: ISingleProductProps): void {

    // Convert internal event data to GA event data
    const product: IExtendedProduct = formatProductData(data);

    // Quantity isn't valid for this event. Rather than sending "undefined", remove it completely.
    delete product.quantity;

    // Create an event rather than directly pushing to the data layer to enforce typing
    const event: GA.IProductDetailViewEvent = {
        event: 'detail',
        ecommerce: {
            detail: {
                // actionField: {
                //     list: ''        // We have category at the product level. This would probably always be something like "PDP".
                // },
                products: [product]
            },
        }
    };

    if (typeof dataLayer !== 'undefined') {
        dataLayer.push(event);
    }
}

//----------------------------------------------------------
// Purchase Complete
//----------------------------------------------------------
function purchase(data: IMultiProductProps): void {

    // Convert internal event data to GA event data
    const products: IExtendedProduct[] = data.products.map(formatProductData);

    // Create an event rather than directly pushing to the data layer to enforce typing
    const event: GA.IPurchaseEvent = {
        event: 'purchase',
        ecommerce: {
            purchase: {
                actionField: {
                    id: data.id,
                    revenue: data.revenue,
                    tax: data.tax,
                    shipping: data.shipping,
                },
                orderSummary: {
                    subTotal: data.revenue - (data.tax + data.shipping),
                    shipping: data.shipping,
                    tax: data.tax,
                    transactionId: data.confirmationId,
                    confirmationId: data.id
                },
                products: products,
            },
        }
    };

    // Only add optional fields if they are present in the source (instead of sending "undefined")
    data.coupon && (event.ecommerce.purchase.actionField.coupon = data.coupon);
    data.affiliation && (event.ecommerce.purchase.actionField.affiliation = data.affiliation);

    if (typeof dataLayer !== 'undefined') {
        dataLayer.push(event);
    }
}

//----------------------------------------------------------
// Product Impressions
//----------------------------------------------------------
function impression(data: IMultiProductProps): void {

    // Convert internal event data to GA event data
    const impressions: GA.IEECImpression[] = data.products.map((product, idx) => {

        return {
            id: product.product.ItemId || UNKNOWN_VALUE,
            name: product.product.Name || UNKNOWN_VALUE,
            brand: brandCodeParser({ description: product.product.Description, appContext: data.context }) || UNKNOWN_VALUE,
            variant: (product.attributes && product.attributes['Wine Bottle Size']) || UNKNOWN_VALUE,
            position: idx,
            price: product.product.Price,

            list: data.list,            // The list or collection to which the product belongs (e.g. Search Results)
            category: data.category || UNKNOWN_VALUE     // The category to which the product belongs (e.g. Apparel). Use / as a delimiter to specify up to 5-levels of hierarchy (e.g. Apparel/Men/T-Shirts)
        };
    });

    // Create an event rather than directly pushing to the data layer to enforce typing
    const event: GA.IImpressionEvent = {
        event: 'impression',
        ecommerce: {
            currencyCode: CURRENCY_CODE,
            impressions: impressions
        }
    };

    if (typeof dataLayer !== 'undefined') {
        dataLayer.push(event);
    }
}

//----------------------------------------------------------
// Purchase Complete
//----------------------------------------------------------
function checkout(data: IMultiProductProps): void {

    // Convert internal event data to GA event data
    const products: IExtendedProduct[] = data.products.map(formatProductData);

    // Create an event rather than directly pushing to the data layer to enforce typing
    const event: GA.ICheckoutStepEvent = {
        event: 'checkout',
        ecommerce: {
            checkout: {
                actionField: {
                    step: data.step,
                },
                products: products,
            },
        }
    };

    // Not entirely standard, but include it anyway
    if (data.coupon) {
        event.ecommerce.checkout.actionField.coupon = data.coupon;
    }

    if (typeof dataLayer !== 'undefined') {
        dataLayer.push(event);
    }
}

//==============================================================================
//==============================================================================

//----------------------------------------------------------
//----------------------------------------------------------
export function init(): void {
    subscribe(SUBSCRIBER_NAME, 'addToCart', addToCart);
    subscribe(SUBSCRIBER_NAME, 'removeFromCart', removeFromCart);
    subscribe(SUBSCRIBER_NAME, 'productDetailView', productDetailView);
    subscribe(SUBSCRIBER_NAME, 'purchase', purchase);
    subscribe(SUBSCRIBER_NAME, 'impression', impression);
    subscribe(SUBSCRIBER_NAME, 'checkout', checkout);
}
