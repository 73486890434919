import { GenericInput, getGenericAction } from '@msdyn365-commerce-modules/retail-actions';
import { createObservableDataAction, IAction, IActionInput, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';

export function createKeyedInPriceError(result: string): GenericInput<string> {
    return new GenericInput<string>('buyboxKeyedInPriceError', result, 'String');
}

const createKeyedInPriceErrorIternal = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    return createKeyedInPriceError('');
};

export default createObservableDataAction({
    action: <IAction<string>>getGenericAction,
    input: createKeyedInPriceErrorIternal
});
