import { Button, format } from '@msdyn365-commerce-modules/utilities';
import { getUrlSync } from '@msdyn365-commerce/core';
import { CartLine } from '@msdyn365-commerce/retail-proxy';
import * as React from 'react';
import { calculateSavingPercentAmount } from '../../../Utilities/elicit-savings-calculation';
import { orderSummaryShipmentCalculation } from '../../../Utilities/smwe-order-summary-shipment-calculation';
import { filterCartLines } from '../../../Utilities/subscription-manager';
import { ISubscriptionsMinicartData } from '../subscriptions-minicart.data';
import { ISubscriptionsMinicartProps } from '../subscriptions-minicart.props.autogenerated';
interface ICartDisplayComponentProps {
    subscriptionsProps: ISubscriptionsMinicartProps<ISubscriptionsMinicartData>;
}

/**
 * declaration for display the minicart summary info to the user
 */
export class CartDisplayComponent extends React.Component<ICartDisplayComponentProps> {
    public render(): JSX.Element | null {
        const {
            subscriptionsProps: {
                resources: { subscriptionsMinicart__boxPrice, subscriptionsMinicart__shipping, subscriptionsMinicart__savings, subscriptionsMinicart__goToCart },
                data: { cart: { result: cartState } },
                context: {
                    actionContext
                }
            }
        } = this.props;
        if (!cartState) { return null; }
        const { cart } = cartState;

        const savingPercent = calculateSavingPercentAmount(this._cartFilteredLines.subscriptionLines);

        const formatter = this.props.subscriptionsProps.context.cultureFormatter.formatCurrency;
        const cartUrl = getUrlSync('cart', actionContext) || '';
        const amount = savingPercent ? this._calculateDiscountedTotalAmount : this._calculateTotalAmount;
        return (
            <div className='subscription-minicart-cart-summary'>
                <div className='subscription-minicart-amount-summary'>
                    <span className='subscription-minicart-boxprice'>{subscriptionsMinicart__boxPrice}</span>
                    <div className='subscription-minicart-amount'>{formatter(amount || 0)}</div>
                    <div className='subscription-minicart-savings'>
                        <div className='subscription-minicart-savings-amount'>
                            {format(subscriptionsMinicart__savings, formatter(((this._calculateTotalAmount || 0) * (savingPercent / 100))), savingPercent)}
                        </div>
                        <div className='subscription-minicart-amount-savings'>{savingPercent}% off</div>
                    </div>
                </div>
                <div className='subscription-minicart-shipping-summary'>
                    <span className='subscription-minicart-shipping'>{subscriptionsMinicart__shipping}</span>
                    <div className='subscription-minicart-shipping-amount'>{formatter(orderSummaryShipmentCalculation({ cart }).price)}</div>
                </div>
                <div className='subscription-minicart-cart-button-container'>
                    <Button className='subscription-minicart-cart-button' href={cartUrl}>{subscriptionsMinicart__goToCart}</Button>
                </div>
            </div>
        );
    }

    private get _cartFilteredLines(): {
        subscriptionLines: CartLine[];
        lines: CartLine[];
    } {
        return filterCartLines(this.props.subscriptionsProps.data.cart.result?.cart);
    }

    private get _calculateTotalAmount(): number {
        let amount = 0;
        this._cartFilteredLines.subscriptionLines.forEach(line => amount += line.NetPrice || 0);
        return amount;
    }

    private get _calculateDiscountedTotalAmount(): number {
        let amount = 0;
        this._cartFilteredLines.subscriptionLines.forEach(line => amount += line.TotalAmount || 0);
        return amount;
    }
}