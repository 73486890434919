import * as React from 'react';
import { WineClubDataStore } from '../../wine-club-signup/wine-club-data';
import { IPageTosData } from '../page-tos.data';
import { IPageTosProps } from '../page-tos.props.autogenerated';

import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { BusLoader, LoadBus } from '../../../Utilities/event-bus';

export interface ITosCheckbox {
    props: IPageTosProps<IPageTosData>;
}

/**
 * renders the state of the TOS checkbox
 */
@LoadBus('tos')
@observer
export class TosCheckbox extends React.Component<ITosCheckbox> {

    private bus: BusLoader | undefined;

    // set of actions able to create persisted behaviour for the context of
    // where the module was placed
    private _modulePersist: { location: string; doPersistance(): void }[] = [
        {
            location: 'wine-club-signup',
            doPersistance: () => {
                const store = WineClubDataStore.getInstance().get();
                this._isChecked = store.tosAgree;
            }
        }
    ];

    @observable private _isChecked: boolean = false;

    constructor(props: ITosCheckbox) {
        super(props);

        this._onAgreementChange = this._onAgreementChange.bind(this);
        this._applyPersistableTOS();
    }

    public render(): JSX.Element {
        return (
            <div className='page-tos-checkbox'>
                <input
                    type='checkbox'
                    aria-checked={this._isChecked}
                    checked={this._isChecked}
                    className='page-tos-checkbox-checkbox'
                    onChange={this._onAgreementChange}
                    id='club-terms-conditions'
                />
                <label htmlFor='club-terms-conditions' className='page-tos-checkbox-text'>{this.props.props.resources.agreeText}</label>
            </div>
        );
    }

    private _onAgreementChange(): void {
        this._isChecked = !this._isChecked;
        this.bus!.publish('tos-agreement-changed', this._isChecked);
    }

    private _applyPersistableTOS(): void {
        // parent id is the parent that the module was slotted into, needs a bit
        // of cleanup, so we use startsWith as it ends with __XXXX
        const { parentId } = this.props.props;
        if (parentId) {
            const foundAPersist = this._modulePersist
                .find(module => parentId.startsWith(module.location));
            if (foundAPersist) {
                foundAPersist.doPersistance();
            }
        }
    }
}