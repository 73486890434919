import { getSelectedProductIdFromActionInput, getSelectedVariant, SelectedVariantInput } from '@msdyn365-commerce-modules/retail-actions';
import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, ICreateActionContext, } from '@msdyn365-commerce/core';
import { ProductAvailableQuantity } from '@msdyn365-commerce/retail-proxy';
import { getEstimatedAvailabilityAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
/**
 * Input class for the getProductAvailabilitiesForSelectedVariant Data Action
 */
// tslint:disable-next-line: no-unnecessary-class
export class ProductAvailabilitiesForSelectedVariantInput  implements IActionInput {
    public channelId: number;
    public productId: number;

    constructor(productId: string | number, channelId: number) {
        this.productId = typeof productId === 'string' ? +productId : productId;
        this.channelId = channelId;
    }

    public getCacheKey = () => `ProductAvailabilitiesForSelectedVariant`;
    public getCacheObjectType = () => 'ProductAvailabilities';
    public dataCacheType = ():CacheType => 'none';
}
/**
 * createInput method for the getProductAvailabilitiesForSelectedVariant data action.
 * @param inputData The input data passed to the createInput method
 */
export const createProductAvailabilitiesForSelectedVariantInput = (inputData: ICreateActionContext): IActionInput => {
    const productId = getSelectedProductIdFromActionInput(inputData);
    if (productId) {
        return new ProductAvailabilitiesForSelectedVariantInput(+productId, +inputData.requestContext.apiSettings.channelId);
    } else {
        throw new Error('Unable to create ProductAvailabilitiesForSelectedVariantInput, no productId found on module config or query');
    }
};
/**
 * The action method for the getProductAvailabilitiesForSelectedVariant data action
 */
export async function getProductAvailabilitiesForSelectedVariantAction(input: ProductAvailabilitiesForSelectedVariantInput, ctx: IActionContext): Promise<ProductAvailableQuantity | null> {
    const selectedVariantInput = new SelectedVariantInput(input.productId, input.channelId);
    return getSelectedVariant(selectedVariantInput, ctx)
        .then(productResult => {
        if (productResult) {
            return getEstimatedAvailabilityAsync({ callerContext: ctx, queryResultSettings: {} }, { ProductIds: [productResult.RecordId], DefaultWarehouseOnly: true}).then(response => {
                if (response && response.ProductWarehouseInventoryAvailabilities && response.ProductWarehouseInventoryAvailabilities.length) {
                    // translate data to support deprecated retail service call getProductAvailabilitiesAsync
                    const formattedResponse = response.ProductWarehouseInventoryAvailabilities?.map((product) => {
                        return {ProductId: product.ProductId, AvailableQuantity: product.PhysicalAvailable, ExtensionProperties: product.ExtensionProperties};
                    });
                    return formattedResponse[0];
                }
                return null;
            });
        }
        return null;
    })
        .catch((error) => {
        ctx.trace(error.message);
        throw new Error('[getPriceForSelectedVariantAction]Error executing action');
    });
}
/**
 * The complete getProductAvailabilitiesForSelectedVariant data action
 * Get the currently selected variant via the getSelectedVariant data action, and
 * then gets the availabilities for the variant via the getProductAvailabilities RetailServer API
 */
export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-product-availabilities-for-selected-variant',
    action: <IAction<ProductAvailableQuantity[]>>getProductAvailabilitiesForSelectedVariantAction,
    input: <(args: ICreateActionContext) => IActionInput>createProductAvailabilitiesForSelectedVariantInput
});