import { getSelectedProductIdFromActionInput, getSelectedVariant, SelectedVariantInput } from '@msdyn365-commerce-modules/retail-actions';
import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { readAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CustomersDataActions.g';
import { getActivePricesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { AffiliationLoyaltyTier, ProductPrice, ProjectionDomain, SimpleProduct } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';

/**
 * Input class for the GetPriceForSelectedVariant Data Action
 */
export class PriceForSelectedVariantInput implements IActionInput {
    public productId: number;
    public channelId: number;
    public selectedProduct: SimpleProduct | undefined;
    public customerId: string;
    public affiliationId?: number;

    constructor(productId: number, channelId: number, selectedProduct?: SimpleProduct, customerId?: string, affiliationId?: number) {
        this.productId = productId;
        this.channelId = channelId;
        this.selectedProduct = selectedProduct;
        this.customerId = customerId || '';
        this.affiliationId = affiliationId;
    }

    public getCacheKey = () => `PriceForSelectedVariant|${this.productId}|${this.affiliationId}`;
    public getCacheObjectType = () => 'Price';
    public dataCacheType = (): CacheType => 'none';
}

/**
 * The createInput method for the GetPriceForSelectedVariantDataAction
 * @param inputData The input data for the createInput method
 */
export const createActivePriceForSelectedVariantInput = (inputData: ICreateActionContext<IGeneric<IAny>>): PriceForSelectedVariantInput => {
    const productId = getSelectedProductIdFromActionInput(inputData);

    if (productId) {
        return new PriceForSelectedVariantInput(
            +productId,
            +inputData.requestContext.apiSettings.channelId,
            undefined,
            inputData.requestContext.user.customerAccountNumber,
            inputData.config && inputData.config.affiliationId);
    } else {
        throw new Error('Unable to create PriceForSelectedVariantInput, no productId found on module config or query');
    }
};

/**
 * The Action Method for the GetPriceForSelectedVariant Data Action
 * Pulls the currently selected variant from the cache using the getSelectedVariant data action, and gets it's current contextual price
 * via the getActivePrice RetailServer API
 */
export async function getPriceForSelectedVariantAction(
    input: PriceForSelectedVariantInput,
    ctx: IActionContext
): Promise<ProductPrice | null> {
    return Promise.resolve()
        // @ts-ignore: Promise vs. ObservablePromise typing conflict
        .then(() => {
            const activeProduct: SimpleProduct | undefined = input.selectedProduct;

            if (!activeProduct) {
                const selectedVariantInput = new SelectedVariantInput(input.productId, input.channelId);

                return getSelectedVariant(selectedVariantInput, ctx);
            }

            return activeProduct;
        })
        .then<ProductPrice | null>(async (productResult: SimpleProduct | null) => {
            const projectDomain: ProjectionDomain = { ChannelId: +ctx.requestContext.apiSettings.channelId, CatalogId: +ctx.requestContext.apiSettings.catalogId };
            const activeProduct: SimpleProduct | undefined = <SimpleProduct | undefined>productResult;

            if (activeProduct) {
                let customerAffiliations: AffiliationLoyaltyTier[] = [];
                if (ctx.requestContext.user &&
                    ctx.requestContext.user.isAuthenticated &&
                    ctx.requestContext.user.customerAccountNumber
                ) {
                    await readAsync({ callerContext: ctx }, ctx.requestContext.user.customerAccountNumber).then(response => {
                        if(response) {
                            if(response.CustomerAffiliations && response.CustomerAffiliations.length > 0) {
                                customerAffiliations = response.CustomerAffiliations.map(aff => {
                                    return {
                                        AffiliationId: aff.RetailAffiliationId
                                    };
                                });
                            }
                        }
                    });
                }

                let affiliations: AffiliationLoyaltyTier[];
                if(input.affiliationId) {
                    affiliations = [
                        {
                            AffiliationId: input.affiliationId
                        }
                    ];
                } else if(customerAffiliations.length > 0) {
                    affiliations = customerAffiliations;
                } else {
                    affiliations = [];
                }
                return getActivePricesAsync(
                  { callerContext: ctx, queryResultSettings: {} },
                  projectDomain,
                  [activeProduct.RecordId],
                  new Date(),
                  input.customerId,
                  affiliations,
                  true
                ).then(response => {
                  if (response && response.length > 0) {
                    return response[0];
                  }
                  throw new Error(
                    '[getPriceForSelectedVariantAction]Invalid response recieved from calculateProductPrice'
                  );
                });
            }

            return null;
        })
        .catch((error: Error) => {
            ctx.trace(error.message);
            throw new Error('[getPriceForSelectedVariantAction]Error executing action');
        });
}

export default createObservableDataAction({
    action: <IAction<ProductPrice | null>>getPriceForSelectedVariantAction,
    input: createActivePriceForSelectedVariantInput
});
