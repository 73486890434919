import { GenericInput, getGenericAction } from '@msdyn365-commerce-modules/retail-actions';
import { createObservableDataAction, IAction, IActionInput, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { INotificationMessage } from '../models/notification-message';

export function createNotificationMessage(result: INotificationMessage): GenericInput<INotificationMessage> {
    return new GenericInput<INotificationMessage>('notificationMessage', result, 'INotificationMessage');
}

const createNotificationMessageInternal = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    return createNotificationMessage(<INotificationMessage>{});
};

export default createObservableDataAction({
    action: <IAction<string>>getGenericAction,
    input: createNotificationMessageInternal
});
