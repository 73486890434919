/* tslint:disable: no-duplicate-imports */
import { ActiveCartProductsInput, getActiveCartProductsAction, getDeliveryOptionsForCartLineItems, ProductDeliveryOptionsForCartLineItems } from '@msdyn365-commerce-modules/retail-actions';
import { IActionContext } from '@msdyn365-commerce/core';
import { getCartState } from '@msdyn365-commerce/global-state';
import { ProductAvailableQuantity, ProductDeliveryOptions, SimpleProduct } from '@msdyn365-commerce/retail-proxy';
import { getOrgUnitConfigurationAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/OrgUnitsDataActions.g';
import { getEstimatedAvailabilityAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
/**
 * IMiniCartData entity interface.
 */
export interface IMiniCartData {
    products?: SimpleProduct[];
    productAvailabilites?: ProductAvailableQuantity[];
    deliveryOptions?: ProductDeliveryOptions[];
}

export const createInput = () => {
    return new ActiveCartProductsInput();
};

/**
 * Calls the Retail API and returns a mini cart data
 */
export async function getMiniCartData(ctx: IActionContext): Promise<IMiniCartData> {
    const miniCartData: IMiniCartData = {};

    const cartState = await getCartState(ctx);
    const cart = cartState.cart;
    const channelConfiguration = await getOrgUnitConfigurationAsync({ callerContext: ctx });
    const productItems = [];

    // If there are cart lines, make call to get products
    if (cart && cart.CartLines && cart.CartLines.length) {
        await getActiveCartProductsAction(
            new ActiveCartProductsInput(),
            ctx
        )
            .then(products => {
                if (products) {
                    miniCartData.products = products;
                }
            })
            .catch(error => {
                ctx.telemetry.exception(error);
                throw new Error('[getActiveCartWithProdcuts]Unable to hydrate cart with product information');
            });

        if (cart.Id && channelConfiguration) {
            for (const cartLine of cart.CartLines) {
                if (cartLine.DeliveryMode !== channelConfiguration.PickupDeliveryModeCode) {
                    productItems.push(cartLine);
                }
            }
        }
        if (productItems && productItems.length > 0) {
            const productAvailabilites = await getEstimatedAvailabilityAsync({ callerContext: ctx }, { ProductIds: productItems.map(x => x.ProductId || 0), DefaultWarehouseOnly: true });
            miniCartData.productAvailabilites = productAvailabilites.ProductWarehouseInventoryAvailabilities?.map((product) => { return { ProductId: product.ProductId, AvailableQuantity: product.PhysicalAvailable }; });
        } else {
            miniCartData.productAvailabilites = [];
        }

        await getDeliveryOptionsForCartLineItems(
            new ProductDeliveryOptionsForCartLineItems(ctx.requestContext.apiSettings),
            ctx
        )
            .then(deliveryOptions => {
                if (deliveryOptions) {
                    miniCartData.deliveryOptions = deliveryOptions;
                }
            })
            .catch(error => {
                ctx.telemetry.exception(error);
                throw new Error('[getActiveCartWithProdcuts]Unable to hydrate cart with product information');
            });
    }

    return miniCartData;
}