import {
  CurrentCategoryInput,
  FullProductInput,
  getFullProducts,
  getProductDetailsCriteriaFromActionInput,
  getProductsByCategory,
  ProductDetailsCriteria,
  QueryResultSettingsProxy
} from '@msdyn365-commerce-modules/retail-actions';
import { FullProduct } from '@msdyn365-commerce/commerce-entities';
import {
  CacheType,
  createObservableDataAction,
  IAction,
  IActionContext,
  IActionInput,
  IAny,
  ICommerceApiSettings,
  ICreateActionContext,
  IGeneric
} from '@msdyn365-commerce/core';
import { SimpleProduct } from '@msdyn365-commerce/retail-proxy';
import { getAttributeValuesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';

const buildCacheKey = (
  base: string,
  apiSettings: ICommerceApiSettings
): string => {
  return `${base}-chanId:${apiSettings.channelId}-catId:${apiSettings.catalogId}`;
};
/**
 * Chains getting the SelectedVariant by product id
 */
class ProductsByCategoryInput implements IActionInput {
  public categoryId?: number;
  public categorySlug?: string;
  public categoryName?: string;
  public catalogId: number;
  public currentCategory: CurrentCategoryInput;
  public readonly queryResultSettingsProxy: QueryResultSettingsProxy;
  private apiSettings: ICommerceApiSettings;

  constructor(
    category: CurrentCategoryInput,
    apiSettings: ICommerceApiSettings,
    queryResultSettingsProxy: QueryResultSettingsProxy
  ) {
    this.apiSettings = apiSettings;
    this.currentCategory = category;
    this.queryResultSettingsProxy = queryResultSettingsProxy;
    this.catalogId = apiSettings.catalogId;
    this.categoryId = category.categoryId;
    this.categorySlug = category.categorySlug;
  }

  public getCacheKey = () =>
    buildCacheKey(
      `${this.categoryId || this.categorySlug}|${this.catalogId}|${
        this.queryResultSettingsProxy.cacheKeyHint
      }`,
      this.apiSettings
    );
  public getCacheObjectType = () => 'Products-From-Search';
  public dataCacheType = (): CacheType => 'application';
}
/**
 * Product by category ID Input action
 */
export class ProductsWithAttributesByCategoryInput implements IActionInput {
  public categoryId: number;
  public channelId: number;
  public catalogId: number;
  public categoryInput: CurrentCategoryInput;
  public selectedProduct: FullProduct | undefined;
  public productsInput: ProductsByCategoryInput;
  public criteria: ProductDetailsCriteria;
  constructor(
    channelId: number,
    categoryId: number,
    categoryInput: CurrentCategoryInput,
    productsInput: ProductsByCategoryInput,
    criteria: ProductDetailsCriteria,
    catalogId?: number
  ) {
    this.channelId = channelId;
    this.productsInput = productsInput;
    this.categoryId = categoryId;
    this.categoryInput = categoryInput;
    this.criteria = criteria;
    this.catalogId = catalogId || 0;
  }
  public getCacheKey = () =>
    `${this.categoryId}:${this.channelId}:${this.catalogId}:ProductsByCategoryWithAttributes`;
  public getCacheObjectType = () => 'ProductsByCategoryWithAttributes';
  public dataCacheType = (): CacheType => 'request';
}
/**
 * Product by category ID Input action
 */
export const createAttributesForSelectedVariantInput = (
  inputData: ICreateActionContext<IGeneric<IAny>>
): ProductsWithAttributesByCategoryInput => {
  if (inputData && inputData.requestContext) {
    if (inputData.requestContext.query && !inputData.requestContext.query.top) {
      inputData.requestContext.query.top = '1000';
    }
    const currentCategory = new CurrentCategoryInput(inputData.requestContext);
    // uses eventCategoryId in config if set otherwise looks up categoryId from url
    if (inputData.config && inputData.config.eventCategoryId) {
      currentCategory.categoryId = inputData.config.eventCategoryId;
    }
    const queryResultSettingsProxy = QueryResultSettingsProxy.fromInputData(
      inputData
    );
    return new ProductsWithAttributesByCategoryInput(
      +inputData.requestContext.apiSettings.channelId,
      +(currentCategory.categoryId || 0),
      currentCategory,
      new ProductsByCategoryInput(
        currentCategory,
        inputData.requestContext.apiSettings,
        queryResultSettingsProxy
      ),
      getProductDetailsCriteriaFromActionInput(inputData)
    );
  }

  throw new Error('Please specify categoryId query string in request.');
};
/**
 * Product by category ID Input action
 */
export async function getAttributesForSelectedVariantAction(
  input: ProductsWithAttributesByCategoryInput,
  ctx: IActionContext
): Promise<FullProduct[]> {
  const productSearchResult = await getProductsByCategory(
    input.productsInput,
    ctx
  );
  const fullProductInputs: FullProductInput[] = [];
  productSearchResult.forEach((searchResult: SimpleProduct) => {
    if (searchResult.RecordId !== undefined) {
      fullProductInputs.push(
        new FullProductInput(
          searchResult.RecordId,
          ctx.requestContext.apiSettings,
          input.criteria
        )
      );
    }
  });
  if (!fullProductInputs) {
    return <FullProduct[]>{};
  }
  const products = await getFullProducts(fullProductInputs, ctx);
  // @ts-ignore
  ctx.isMaxDepthExceeded = () => false;

  return Promise.all(products.map(async (product: FullProduct) => {
    const attribute = await getAttributeValuesAsync(
      { callerContext: ctx, queryResultSettings: {} },
      product.ProductDetails.RecordId,
      input.channelId,
      input.catalogId
    );

    // @ts-ignore
    product.ProductAttributes = attribute;
    return product;
  }));
}

export default createObservableDataAction({
  action: <IAction<FullProduct[]>>getAttributesForSelectedVariantAction,
  input: createAttributesForSelectedVariantInput
});
