import { IProductRefinerHierarchy } from '@msdyn365-commerce/commerce-entities';
import { IActionContext } from '@msdyn365-commerce/core';
import { IDictionary, IQueryResultSettings, ProductRefiner, ProductSearchCriteria } from '@msdyn365-commerce/retail-proxy';
import { getProductSearchRefinersAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { IRemapper } from '../../Utilities/refiners';

/**
 * Uses Bulk refiner API to get refiners and child refiner values
 * @param searchCriteria product search criteria
 * @param maxItems max items
 * @param ctx action context
 */
export async function getProductRefinerHierarchy(searchCriteria: ProductSearchCriteria, queryResultSettings: IQueryResultSettings, ctx: IActionContext, configuration?: IDictionary<unknown>): Promise<IProductRefinerHierarchy[]> {
    return getProductSearchRefinersAsync({ callerContext: ctx, queryResultSettings: queryResultSettings }, searchCriteria).then((productRefiners: ProductRefiner[]) => {
        if (!productRefiners) {
            ctx.trace('[getRefinersByCriteriaAction] returned no refiners');
            return <IProductRefinerHierarchy[]>[];
        }

        // @FIXME/dg: This is a crime against nature
        const result = (productRefiners || []).map((productRefiner: ProductRefiner) => <IProductRefinerHierarchy>productRefiner);

        // Selectively remap refiner labels if any are defined in global config
        const remappers: IRemapper[] | undefined = configuration && <IRemapper[]>configuration.refinerRemappers;
        if (remappers) {
            result.forEach(entry => {
                // Are there any remap rules for this refiner?
                const remap = remappers.find(remapRecord => remapRecord.originalName.toLowerCase() === entry.KeyName?.toLowerCase());

                // If so, change the name
                if (remap) {
                    entry.KeyName = remap.newName;
                }
            });
        }

        if (!result.length) {
            ctx.trace('[getRefinersByCriteriaAction] returned no IProductRefinerHierarchy objects');
            return <IProductRefinerHierarchy[]>[];
        }

        return result;
    });
}