import { ICarouselViewProps } from '@msdyn365-commerce-modules/carousel/dist/types/modules/carousel/carousel';
import { Carousel } from '@msdyn365-commerce/components';
import classnames from 'classnames';
import * as React from 'react';
import { ICarouselProps } from '../definition-extensions/carousel.ext.props.autogenerated';

const CarouselView: React.FC<ICarouselViewProps & ICarouselProps<{}>> = props => (
    <React.Fragment>
        <Carousel
                className={classnames(props.closeAnimation)}
                activeIndex={props.activeIndex}
                next={props.next}
                previous={props.previous}
                pause={props.config.pauseOnHover ? 'hover' : false}
                ride={props.config.autoplay ? 'carousel' : undefined}
                autoplay={props.config.autoplay}
                interval={props.config.interval}
                transitionType={(props.config.transitionType) ? props.config.transitionType : undefined}
                items={props.slots && props.slots.content && props.slots.content.length ? props.slots.content : []}
                hideFlipper={props.config.hideFlipper}
                dismissEnabled={props.config.dismissEnabled}
                onIndicatorsClickHandler={props.onIndicatorsClickHandler}
                handleDismissCarousel={props.handleDismissCarousel}
                dismissCarouselAriaText={props.resources && props.resources.ariaLabelForDismissButton}
                indicatorAriaText={props.resources && props.resources.ariaLabelForSlide}
                directionTextPrev={props.resources && props.resources.flipperPrevious}
                directionTextNext={props.resources && props.resources.flipperNext}
                handleOnExited={props.handleOnExited}
                handleOnExiting={props.handleOnExiting}
        />
    </React.Fragment>
);

export default CarouselView;
