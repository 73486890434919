import { GetCheckoutCartInput } from '@msdyn365-commerce-modules/retail-actions';
import { AsyncResult, IActionContext, ICoreContext } from '@msdyn365-commerce/core';
import { ICartActionResult, ICartActionResultWithCart, ICartActionSubStatus, ICartState } from '@msdyn365-commerce/global-state';
import { ProductAvailableQuantity } from '@msdyn365-commerce/retail-proxy';
import { addCartLinesAsync, updateAsync, updateCartLinesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';
import { getByIdsAsync, getEstimatedAvailabilityAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { Address, Cart, CartLine, CommerceProperty, OrgUnitLocation, SimpleProduct } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import React from 'react';
import { SubscriptionCommerceValues } from '../common/subscription-commerce.values';
import { AddToCartAction, IAddToCartFailureResult } from '../components/addtocart.component';
import { AttributesForSelectedVariantInput, getAttributesForSelectedVariantAction } from '../dataActions/get-attributes-for-selected-variant';
import { getSubscriptionValues, isLineSubscriptionLine, updateSubscription } from './subscription-manager';

type itemInfo = {
    recordId: number;
    amount?: number;
    // tslint:disable-next-line: no-any
    context: ICoreContext<{ [x: string]: any }>;
};

/**
 * Util for adding items to cart via code
 */
// tslint:disable-next-line: no-unnecessary-class
export class CartUtilities {

    protected actionContext: IActionContext;
    protected cart: ICartState;

    public static countCartLineAmount(lines: CartLine[]): number {
        let amount = 0;
        lines.forEach(line => amount += line.Quantity || 0);
        return amount;
    }

    public static countCartLineAmountNoGiftCards(lines: CartLine[]): number {
        let amount = 0;
        lines.forEach(line => {
            const isGiftCard = line.ItemId?.toLowerCase() === 'giftcard';
            if (!isGiftCard) {
                amount += line.Quantity || 0;
            }
        });
        return amount;
    }

    public static countCartLineNetPrice(lines: CartLine[]): number {
        let amount = 0;
        lines.forEach(line => amount += line.NetPrice || 0);
        return amount;
    }

    public static countCartLineDiscountedPrice(lines: CartLine[]): number {
        let amount = 0;
        lines.forEach(line => amount += line.TotalAmount || 0);
        return amount;
    }

    public static async elicitAddItemIdToCart({ recordId, amount, context }: itemInfo, errorHandler?: () => void): Promise<void> {
        const cartAmount = amount || 1;
        return this._addItem({ recordId, amount: cartAmount, context }, false, errorHandler);
    }

    public static async elicitAddSubscriptionItemIdToCart({ recordId, context, amount }: itemInfo, cartState: AsyncResult<ICartState>): Promise<void> {
        const { frequency } = getSubscriptionValues(cartState.result?.cart);

        if (!frequency) {
            await updateSubscription(
                cartState,
                [
                    {
                        Key: SubscriptionCommerceValues.SUBSCRIPTION_FREQUENCY,
                        Value: {
                            StringValue: context.app.config.defaultSubscriptionFrequencyId
                        }
                    }
                ],
                context
            );
        }

        return this._addItem({ recordId, context, amount: amount || 1 }, true);
    }

    public static async addItem(item: itemInfo, isDigital?: boolean): Promise<void> {
        return this._addItem(item, false, (r) => { console.error('[CartUtilities.addItem] error occured', r); }, isDigital);
    }

    public static async editCartHeadExtensionProps(cartState: ICartState, context: ICoreContext, attributeUpdates: { [commerceKey: string]: string | undefined }): Promise<void> {
        if (!cartState || !cartState.cart) {
            context.telemetry.error('[CartUtilities.updateAttributeValues] Failed to load cart');
            return;
        }

        const extensionProps = cartState.cart.ExtensionProperties || [];
        Object.keys(attributeUpdates).forEach(commerceKey => {
            const value = attributeUpdates[commerceKey];
            const propToUpdate = extensionProps.find(commerceProp => commerceProp.Key === commerceKey);
            if (propToUpdate) {
                propToUpdate.Value = {
                    StringValue: value
                };
            } else {
                extensionProps.push({ Key: commerceKey, Value: { StringValue: value } });
            }
        });

        const updatedCartState = await updateAsync({ callerContext: context.actionContext }, cartState.cart);
        context.actionContext.update(new GetCheckoutCartInput(context.request.apiSettings), updatedCartState);

        await cartState.refreshCart({});
    }

    public static async removeCartHeadExtensionProps(cart: AsyncResult<ICartState>, context: ICoreContext, ...propsToDel: string[]): Promise<void> {
        const cartState = await cart;

        // cartState.cart.ExtensionProperties = extProps.filter(extProp => !propsToDel.find(propToDel => propToDel === extProp));
        cartState.cart.ExtensionProperties?.forEach(extProp => {
            const isToRemove = propsToDel.find(propToDel => propToDel === extProp.Key);
            if (isToRemove && extProp.Value) {
                extProp.Value.StringValue = '';
            }
        });

        const updatedCart = await updateAsync({ callerContext: context.actionContext }, cartState.cart);
        context.actionContext.update(new GetCheckoutCartInput(context.request.apiSettings), updatedCart);
    }

    private static async _addItem({ recordId, amount, context }: itemInfo, isSubscription: boolean, errorHandler?: (result: IAddToCartFailureResult) => void, disableOOS?: boolean): Promise<void> {
        // get simple products
        const simpleProducts = await getByIdsAsync({ callerContext: context.actionContext }, context.request.apiSettings.channelId, [+recordId]);
        const simpleProduct = simpleProducts.find(prod => !!prod);

        // get attributes of product
        const attributesInput = new AttributesForSelectedVariantInput(+recordId, context.request.apiSettings.channelId, simpleProduct);
        const attributes = await getAttributesForSelectedVariantAction(attributesInput, context.actionContext);

        // get availlability of product
        const availabilityAll = await getEstimatedAvailabilityAsync({ callerContext: context.actionContext }, { ProductIds: [recordId], DefaultWarehouseOnly: true});
        const formattedResponse = availabilityAll.ProductWarehouseInventoryAvailabilities?.map((product) => {
            return {ProductId: product.ProductId, AvailableQuantity: product.PhysicalAvailable, ExtensionProperties: product.ExtensionProperties};
        }) as ProductAvailableQuantity[];
        const productTypeAttribute = attributes && attributes.find(
            attribute => attribute.Name === 'Product Type'
        );
        const availability = formattedResponse.find(available => !!available);

        // add to cart
        await AddToCartAction(
            null as unknown as React.MouseEvent<HTMLElement, MouseEvent>,
            {
                addToCartText: '',
                outOfStockText: '',
                context: context,
                typeName: '',

                productTypeAttribute: productTypeAttribute,
                productAttributes: attributes,
                data: { product: simpleProduct! },
                id: '',
                quantity: amount,
                productAvailability: availability,
                isSubscriptionItem: isSubscription,
                useElicitAddToCart: true,
                overrideOutOfStock: disableOOS || false,
                onError: errorHandler,
            },
            () => false
        );
    }

    constructor(actionContext: IActionContext, cart: ICartState) {
        this.actionContext = actionContext;
        this.cart = cart;
    }

    public async elicitAddProductToCart(input: { product: SimpleProduct; count?: number; location?: OrgUnitLocation; additionalProperties?: { asSubscriptionItem: boolean } }): Promise<ICartActionResult> {
        const cartLine: CartLine = {
            CatalogId: this.actionContext.requestContext.apiSettings.catalogId,
            Description: input.product.Description,
            // TODO: Investigate this value and what it represents
            EntryMethodTypeValue: 3,
            ItemId: input.product.ItemId,
            ProductId: input.product.RecordId,
            Quantity: input.count || 1,
            TrackingId: '',
            UnitOfMeasureSymbol: input.product.DefaultUnitOfMeasure
        };

        if (input.location) {
            if (!this.actionContext.requestContext.channel) {
                return { status: 'FAILED' };
            }

            cartLine.DeliveryMode = this.actionContext.requestContext.channel.PickupDeliveryModeCode;
            cartLine.FulfillmentStoreId = input.location.OrgUnitNumber;
            cartLine.ShippingAddress = this._buildAddressFromOrgUnitLocation(input.location);
        }

        let callbackResult = await this._elicitAddProductToCartInternal(this.cart.cart, cartLine, this.actionContext, input.additionalProperties?.asSubscriptionItem!);
        if (callbackResult.status === 'SUCCESS' || !this._shouldRetrySubstatus(callbackResult.substatus)) {
            //
        } else {
            const refreshCartResult = await this.cart.refreshCart({});

            if (refreshCartResult.status === 'SUCCESS') {
                callbackResult = await this._elicitAddProductToCartInternal(this.cart.cart, cartLine, this.actionContext, input.additionalProperties?.asSubscriptionItem!);
            }
        }

        return { status: callbackResult.status, substatus: callbackResult.substatus };
    }

    // tslint:disable-next-line: cyclomatic-complexity tslint:disable-next-line: max-func-body-length
    private async _elicitAddProductToCartInternal(cart: Readonly<Cart>, cartLineToAdd: CartLine, actionContext: IActionContext, addAsSubscriptionItem: boolean): Promise<ICartActionResultWithCart> {
        if (!cart.CartLines) {
            return { cart: undefined, status: 'FAILED' };
        }

        const quantityLimit: number = actionContext.requestContext.app.config.maxQuantityForCartLineItem || 10;

        // let lineFound: number = -1;
        const lineSet: CartLine[] = [];
        const productIdToFind = cartLineToAdd.ProductId;

        for (let i = 0; i < cart.CartLines.length; i++) {
            if (cart.CartLines[i].ProductId === productIdToFind &&
                (cart.CartLines[i].DeliveryMode || '') === (cartLineToAdd.DeliveryMode || '') &&
                (cart.CartLines[i].FulfillmentStoreId || '') === (cartLineToAdd.FulfillmentStoreId || '')) {
                lineSet.push(cart.CartLines[i]);
            }
        }

        const curLine: CartLine | undefined = (addAsSubscriptionItem) ?
            lineSet.find(isLineSubscriptionLine) :
            lineSet.find(line => !isLineSubscriptionLine(line));

        const isCurLineSubscription = isLineSubscriptionLine(curLine);
        let addNewLine: boolean = !curLine;
        // if a line already exists, and you want to add it as a subscription item, but the current
        // line is NOT a subscription line
        if (curLine && addAsSubscriptionItem && !isCurLineSubscription) {
            addNewLine = true;
            // if a line exists, and you want to add it as a normal item, but the current line IS a
            // subscription line
        } else if (curLine && !addAsSubscriptionItem && isCurLineSubscription) {
            addNewLine = true;
        }

        if (!addNewLine) {
            const cartLineToUpdate = { ...curLine! };
            const curQuantity = cartLineToUpdate.Quantity || 0;

            if (curQuantity + (cartLineToAdd.Quantity || 1) > quantityLimit) {
                return { cart: { Id: cart.Id, ExtensionProperties: [{ Key: 'curQuantity', Value: { IntegerValue: curQuantity } }] }, status: 'FAILED', substatus: 'MAXQUANTITY' };
            }

            cartLineToUpdate.Quantity = Math.min((cartLineToUpdate.Quantity || 0) + (cartLineToAdd.Quantity || 0), quantityLimit);

            return updateCartLinesAsync({ callerContext: actionContext }, cart.Id, [cartLineToUpdate])
                .then(updatedCart => {
                    return { cart: updatedCart, status: 'SUCCESS' } as ICartActionResultWithCart;
                }).catch(error => {
                    actionContext.telemetry.warning(error);
                    actionContext.telemetry.debug('Unable to Update Cart Line');

                    return { cart: undefined, status: 'FAILED' } as ICartActionResultWithCart;
                });
        } else {
            const newCartLine = { ...cartLineToAdd };

            newCartLine.Quantity = Math.min(cartLineToAdd.Quantity || 1, quantityLimit);

            if (addAsSubscriptionItem) {
                const subscriptionAttribute: CommerceProperty = {
                    Key: 'IsSubscription',
                    Value: {
                        StringValue: 'True'
                    }
                };

                if (newCartLine.ExtensionProperties) {
                    newCartLine.ExtensionProperties.push(subscriptionAttribute);
                } else {
                    newCartLine.ExtensionProperties = [subscriptionAttribute];
                }
            }

            if (cart.Version) {
                return addCartLinesAsync({ callerContext: actionContext }, cart.Id, [newCartLine], cart.Version)
                    .then(async newCart => {
                        return { cart: newCart, status: 'SUCCESS' } as ICartActionResultWithCart;
                    }).catch(error => {
                        actionContext.telemetry.trace(error);
                        actionContext.telemetry.trace('Unable to add Cart Line');

                        return { cart: undefined, status: 'FAILED' } as ICartActionResultWithCart;
                    });
            } else {
                actionContext.telemetry.warning('Unable to update Cart Line, Cart Version could not be found');
            }
        }
        return { cart: undefined, status: 'FAILED' } as ICartActionResultWithCart;
    }

    private _shouldRetrySubstatus(subsatus?: ICartActionSubStatus): boolean {
        if (!subsatus) {
            return true;
        }

        // all substatus currently don't result in a retry
        return false;
    }

    private _buildAddressFromOrgUnitLocation(location: OrgUnitLocation): Address {
        return {
            RecordId: location.PostalAddressId,
            Name: location.OrgUnitName,
            FullAddress: location.Address,
            Street: location.Street,
            StreetNumber: location.StreetNumber,
            City: location.City,
            DistrictName: location.DistrictName,
            BuildingCompliment: location.BuildingCompliment,
            Postbox: location.Postbox,
            ThreeLetterISORegionName: location.Country,
            ZipCode: location.Zip,
            County: location.County,
            CountyName: location.CountyName,
            State: location.State,
            StateName: location.StateName
        };
    }
}