import * as React from 'react';

import { Image } from '@msdyn365-commerce/core';
import { IPdpDynamicImageData } from './pdp-dynamic-image.data';
import { IPdpDynamicImageProps } from './pdp-dynamic-image.props.autogenerated';

/**
 *
 * PdpDynamicImage component
 * @extends {React.Component<IPdpDynamicImageProps<IPdpDynamicImageData>>}
 */
class PdpDynamicImage extends React.Component<IPdpDynamicImageProps<IPdpDynamicImageData>> {
    public render(): JSX.Element | null {
        const content = this.props.data;
        const productAttributes = content && content.product && content.product.result;
        if (productAttributes && productAttributes.ItemId) {
            const imageUrl = `${this.props.config.imageSearchUrl}${productAttributes.ItemId}_000_sec.png`;
            const http = new XMLHttpRequest();
            http.open('HEAD', imageUrl, false);
            http.send();
            if (http.status !== 404) {
                return (
                    <div className='pdp-dynamic-image-wrapper'>
                        <Image
                            className='pdp-dynamic-image'
                            gridSettings={this.props.context.request.gridSettings!}
                            imageSettings={this.props.config.imageSettings}
                            src={imageUrl}
                            altText={productAttributes.Name}
                        />
                    </div>
                );
            }
        }
        return null;
    }
}

export default PdpDynamicImage;
