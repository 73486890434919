import { Heading } from '@msdyn365-commerce-modules/utilities';
import { RichTextComponent } from '@msdyn365-commerce/core';
import classnames from 'classnames';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { MailingInputGroup } from './mailing-list-redirect-form-input-group';

import { IMailingListRedirectFormProps } from './mailing-list-redirect-form.props.autogenerated';

export interface IMailingListRedirectFormViewProps extends IMailingListRedirectFormProps<{}> {

}

type validationField = {
    value: string;
    label: string;
    isValid?: boolean;
    errorMessage?: string;
    beenBlurred?: boolean;
};

/**
 *
 * MailingListRedirectForm component
 * @extends {React.Component<IMailingListRedirectFormProps>}
 */
@observer
class MailingListRedirectForm extends React.Component<IMailingListRedirectFormProps<{}>> {

    // ==========================================================================
    // FIELDS
    // ==========================================================================

    @observable private _email: validationField = {
        label: 'Email',
        value: ''
    };

    private _emailRegex: RegExp = /^[-a-z0-9!#$%&'*+/=?^_`{|}~]+(?:\.[-a-z0-9!#$%&'*+/=?^_`{|}~]+)*@(?:[a-z0-9]+(?:-+[a-z0-9]+)*\.)+(?:xn--[a-z0-9]+|[a-z]{2,16})$/i;

    constructor(props: IMailingListRedirectFormProps<{}>) {
        super(props);
        this._onSubmitForm = this._onSubmitForm.bind(this);
        this._onEmailChange = this._onEmailChange.bind(this);
        this._lostFocus = this._lostFocus.bind(this);
    }

    public render(): JSX.Element {
        const {
            title,
            description,
            className,
            formLabel,
            formPlaceholder,
            submitButtonText
        } = this.props.config;

        return (
            <div className={classnames('mailing-list-redirect-form', className)}>
                {(title || description) &&
                    <div className='mailing-list-redirect-form-content'>
                        {title && <Heading {...title} className='mailing-list-redirect-form-content-title' />}
                        {description && <RichTextComponent text={description} className='mailing-list-redirect-form-content-description' />}
                    </div>
                }
                <div className='mailing-list-redirect-form-form'>
                    <MailingInputGroup
                        initialValue={this._email.value}
                        name={this._email.label}
                        label={formLabel}
                        placeholder={formPlaceholder}
                        isValid={this._email.isValid}
                        validationMessage={this._email.errorMessage}
                        errorMessage={this.props.resources.mailingListRedirectForm__errorMessage}
                        onValueChange={this._onEmailChange}
                        onLoseFirstFocus={this._lostFocus}
                    />
                    <button
                        className='mailing-list-redirect-form-button'
                        onClick={this._onSubmitForm}
                        disabled={this._isButtonDisabled()}
                    >
                        {(submitButtonText) && submitButtonText}
                    </button>
                </div>
            </div>
        );
    }

    private _onEmailChange(email: string): void {
        this._email.value = email;

        if (!this._email.beenBlurred) { return; }
        this._testForRequirement(this._email, 'Please enter your email address.');
        if (this._email.isValid) {
            this._testForRegex(this._email, this._emailRegex, 'Please enter a valid email address.');
        }
    }

    private _lostFocus(label: string): unknown {

        // also run all change events related to the field so that the proper
        // errors propogate on blur
        switch (label) {
            case this._email.label:
                this._email.beenBlurred = true;
                return this._onEmailChange(this._email.value);
            default:
                return console.warn('[mailing-list-redirect-form:MailingFormComponent._lostFocus] failed to link a field');
        }
    }

    private _testForRequirement(field: validationField, errorMessage: string): void {
        if (field.value.length === 0) {
            field.isValid = false;
            field.errorMessage = errorMessage;
        } else {
            field.isValid = true;
        }
        this.setState({});
    }

    private _testForRegex(field: validationField, regex: RegExp, errorMessage: string): void {
        if (regex.test(field.value)) {
            field.isValid = true;
        } else {
            field.isValid = false;
            field.errorMessage = errorMessage;
        }
    }

    private _isButtonDisabled(): boolean {

        // button will only be disabled if all input fields are considered invalid
        // or undefined (meaning not filled out or touched)
        return !(this._email.value);
    }

    private _onSubmitForm(): void {
        if (!this._email.isValid) { return; }
        let redirectUrl = this.props.config.redirectUrl.linkUrl.destinationUrl;
        redirectUrl = redirectUrl.indexOf('?') === -1 ? `${redirectUrl}?mailFormEmail=${encodeURIComponent(this._email.value)}` : `${redirectUrl}&mailFormEmail=${encodeURIComponent(this._email.value)}`;
        window.location.assign(redirectUrl);
    }
}

export default MailingListRedirectForm;
