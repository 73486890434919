import * as React from 'react';

interface IYoutubeIFrameProps {
    youtubeId: string | undefined;
}

declare module 'react' {
    // tslint:disable-next-line:interface-name
    interface HTMLAttributes<T> extends DOMAttributes<T> {
        frameborder?: string;
        allowfullscreen?: unknown;
    }
}

/**
 * iframe renderer for youtube
 */
export default class YoutubeIFrame extends React.Component<IYoutubeIFrameProps> {
    public render(): JSX.Element | null {
        const {
            youtubeId
        } = this.props;
        const iframeId = `youtube-${youtubeId}`;

        if (!youtubeId) {
            console.warn('YouTube Video: Missing YouTube ID');
            return null;
        }

        return (
            <iframe
                // tslint:disable-next-line: react-iframe-missing-sandbox
                sandbox='allow-same-origin allow-scripts allow-popups allow-forms'
                src={`https://www.youtube.com/embed/${youtubeId}?autoplay=1`}
                frameBorder='0'
                allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
                title='Youtube Video'
                id={iframeId}
            />
        );
    }
}