import { IActionContext, IComponent, IComponentProps } from '@msdyn365-commerce/core';
import classnames from 'classnames';
import React, { useState } from 'react';

import { BaseCartState, getCartState, GlobalStateInput, ICartActionResult, ICartState } from '@msdyn365-commerce/global-state';
import { AttributeValue, CartLine, OrgUnitLocation, ProductAvailableQuantity, ProductDimension, SimpleProduct } from '@msdyn365-commerce/retail-proxy';
import { addCartLinesAsync, updateCartLinesAsync, updateLineDeliverySpecificationsAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';

import getNotificationMessage, { createNotificationMessage } from '../dataActions/get-notification-message';
import { NotificationType } from '../models/notification-message-type';
import { publish } from '../Utilities/analytics/analytics-dispatcher';
import { CartUtilities } from '../Utilities/cart-utils';
import CommerceAttributesParser from '../Utilities/commerce-attributes-parser';
import ProductType from '../Utilities/productType';
import { filterCartLines } from '../Utilities/subscription-manager';

export interface IAddToCartComponentProps extends IComponentProps<{ product: SimpleProduct; emailDeliveryModeCode?: string | undefined; keyedInPrice?: number | undefined }> {
    shouldNavigateToCart?: boolean;
    className?: string;
    addToCartText: string;
    outOfStockText: string;
    disabled?: boolean;
    quantity?: number;
    navigationUrl?: string;
    productAvailability?: ProductAvailableQuantity;
    getSelectedProduct?: Promise<SimpleProduct | null>;
    isSubscriptionItem?: boolean;
    useElicitAddToCart: boolean;
    productTypeAttribute: AttributeValue | undefined;
    productAttributes: AttributeValue[];
    overrideOutOfStock?: boolean;
    onAdd?(result: ICartActionResult): void;
    onError?(result: IAddToCartFailureResult): void;
}

export declare type ICartActionFailureReason = 'EMPTYINPUT' | 'MISSINGDIMENSION' | 'OUTOFSTOCK' | 'CARTACTIONFAILED' | 'MIXEDCART';
export interface IAddToCartFailureResult {
    failureReason: ICartActionFailureReason;

    stockLeft?: number;
    cartActionResult?: ICartActionResult;
    missingDimensions?: ProductDimension[];
}

export interface IAddtoCartComponent extends IComponent<IAddToCartComponentProps> {
    onClick(): (event: React.MouseEvent<HTMLElement>, props: IAddToCartComponentProps) => void;
}

const buildGiftCardCartLine = (product: SimpleProduct, keyedInPrice?: number, quantity?: number, catalogId?: number, deliveryModeCode?: string): CartLine => {
    return {
        DeliveryMode: deliveryModeCode,
        CatalogId: catalogId || 0,
        Description: product.Description,
        EntryMethodTypeValue: 3,
        ItemId: product.ItemId,
        ProductId: product.RecordId,
        Quantity: quantity || 1,
        Price: keyedInPrice,
        NetPrice: keyedInPrice,
        GiftCardBalance: keyedInPrice,
        TrackingId: '',
        UnitOfMeasureSymbol: product.DefaultUnitOfMeasure,
        IsPriceKeyedIn: true,
        IsGiftCardLine: true
    };
};

const updateCart = (props: IAddToCartComponentProps) => {
    setTimeout(async () => {
        const context = props.context;
        const cartInput = new GlobalStateInput<ICartState>('CARTSTATE', BaseCartState, context.actionContext.requestContext.apiSettings);
        const newCart = await getCartState(context.actionContext);
        context.actionContext.update(cartInput, newCart);
        await newCart.refreshCart({});
    }, 10);
};

// tslint:disable-next-line: cyclomatic-complexity max-func-body-length
const onClick = async (_event: React.MouseEvent<HTMLElement>, props: IAddToCartComponentProps, setDisabled: (disabled: boolean) => void): Promise<void> => {
    const cartError = addToCartError(props);
    let productToAdd = props.data.product;

    if (cartError) {
        propogateError(props, cartError);
        return;
    }

    if (!(props.getSelectedProduct === undefined)) {
        productToAdd = (await props.getSelectedProduct) || props.data.product;
    }

    const cartState = await getCartState(props.context.actionContext);

    // Checking type of current Product Item before adding to cart
    const deliveryModeCode = props.productTypeAttribute?.TextValue === 'Ticket'
        ? ProductType.EventDeliveryMode
        : productToAdd.ItemId?.toLowerCase() === props.context.request.channel?.GiftCardItemId?.toLowerCase()
            ? props.context.request.channel?.EmailDeliveryModeCode
            : null;

    const productType = new ProductType();
    productType.check(deliveryModeCode, props.context);

    // Checking type of Product items which is already added in the CART.
    cartState.cart.CartLines?.find((product) => {
        productType.check(product.DeliveryMode, props.context);
    });

    // If it's a MIXED TYPE then don't proceed
    if (productType.isMixedProduct()) {
        propogateError(props, { failureReason: 'MIXEDCART' });
        return;
    }

    let message = (props.quantity! > 1) ?
        `${props.quantity} items added to your cart`
        : `${props.quantity} item added to your cart`;

    const eventAlreadyExist = cartState.cart.CartLines && cartState.cart.CartLines.find(cartLine => cartLine.ItemId === productToAdd.ItemId);

    if (props.isSubscriptionItem) {
        const subLines = filterCartLines(cartState.cart).subscriptionLines;
        const totalBottlesInCart = CartUtilities.countCartLineAmount(subLines);
        if (totalBottlesInCart + props.quantity! > 12) {
            getNotificationMessage(         // eslint-disable-line
                createNotificationMessage({ message: `No more than 12 bottles per subscription. You currently have ${totalBottlesInCart} bottles added.`, messagetype: NotificationType.error }),
                props.context.actionContext
            );
            return;
        }
    }

    // All error conditions (except those generated by server Add requests) have been processed. It's safe to emit an event.
    publish('addToCart', {
        product: props.data.product,
        quantity: props.quantity,
        attributes: CommerceAttributesParser.getParsedAttributes(props.productAttributes),
        subscription: props.isSubscriptionItem,
        context: props.context,
    });

    // Check for TICKETS type. if cart already have item then we will just update the quantity.
    if (props.productTypeAttribute?.TextValue === 'Ticket' && eventAlreadyExist) {
        const quantityLimit: number = props.context.actionContext.requestContext.app.config.maxQuantityForCartLineItem || 10;

        // Don't continue if QTY is equal/Greater then MAX Quantity.
        if ((eventAlreadyExist.Quantity || 0) >= quantityLimit) { return; }

        // Adding Quantity to the current cartLine
        eventAlreadyExist.Quantity = Math.min((eventAlreadyExist.Quantity || 0) + (props.quantity || 0), quantityLimit);

        // Update Quantity
        await updateCartLinesAsync({ callerContext: props.context.actionContext }, cartState.cart.Id, [eventAlreadyExist])
            .then(updatedCart => {
                getNotificationMessage(         // eslint-disable-line
                    createNotificationMessage({ message, messagetype: NotificationType.success }),
                    props.context.actionContext
                );
                updateCart(props);
            }).catch(error => {
                console.log('ERROR', error);
                return;
            });

    } else if (productToAdd.ItemId?.toLowerCase() === props.context.request.channel?.GiftCardItemId?.toLowerCase()) {
        const cartLineToUpdate = buildGiftCardCartLine(productToAdd, props.data.keyedInPrice, 1, 0, props.data.emailDeliveryModeCode);
        await addCartLinesAsync({ callerContext: props.context.actionContext }, cartState.cart.Id, [cartLineToUpdate], cartState.cart.Version ? cartState.cart.Version : null)
            .then(async updatedCart => {
                await cartState.refreshCart({});

                const giftMessage = 'Gift card added to your cart';

                getNotificationMessage(         // eslint-disable-line
                    createNotificationMessage({ message: giftMessage, messagetype: NotificationType.success }),
                    props.context.actionContext
                );
                updateCart(props);
            })
            .catch(error => {
                console.log(error);
                return;
            });
    } else {
        await getAddToCartResult(props.useElicitAddToCart, props.context.actionContext, cartState, { product: productToAdd, count: props.quantity, additionalProperties: { asSubscriptionItem: props.isSubscriptionItem! } })
            .then(async result => {
                if (result.status === 'SUCCESS') {
                    await cartState.refreshCart({});
                    const caseType = (props.quantity! > 1) ? 'items' : 'item';
                    message = `${props.quantity} ${caseType} added to your cart`;
                    if (props.isSubscriptionItem) {
                        message = `${props.quantity} ${caseType} added to your subscription box`;
                    }

                    getNotificationMessage(         // eslint-disable-line
                        createNotificationMessage({ message, messagetype: NotificationType.success }),
                        props.context.actionContext
                    );
                    if (props.productTypeAttribute?.TextValue === 'Ticket') {
                        const emailDeliveryModeCode = ProductType.EventDeliveryMode;
                        const recentlyAddedCartLine = cartState.cart.CartLines && cartState.cart.CartLines.find(cartLine => cartLine.ItemId === productToAdd.ItemId);
                        if (recentlyAddedCartLine && recentlyAddedCartLine.LineId !== emailDeliveryModeCode) {
                            await updateLineDeliverySpecificationsAsync({ callerContext: props.context.actionContext },
                                cartState.cart.Id,
                                [{
                                    LineId: recentlyAddedCartLine.LineId ? recentlyAddedCartLine.LineId : '',
                                    DeliverySpecification: {
                                        DeliveryModeId: emailDeliveryModeCode,
                                        DeliveryPreferenceTypeValue: 1,
                                        DeliveryAddress: {
                                        }
                                    }
                                }])
                                .then(newCart => cartState.refreshCart({}));
                        }
                    }

                    if (props.navigationUrl && props.shouldNavigateToCart) {
                        window.location.assign(props.navigationUrl);
                    }
                    if (parent === top) {
                        window.parent.postMessage({ url: window.location.href, version: cartState.cart.Version }, window.location.origin);
                    }
                    propogateResult(props, result);
                } else {
                    propogateError(props, { failureReason: 'CARTACTIONFAILED', cartActionResult: result });
                    setDisabled(false);
                }

            });
    }
};

const getAddToCartResult = async (
    isElicitOnly: boolean,
    actionContext: IActionContext,
    cartState: ICartState,
    input: {
        product: SimpleProduct;
        count?: number;
        location?: OrgUnitLocation;
        additionalProperties?: { asSubscriptionItem: boolean };
    }): Promise<ICartActionResult> => {
    if (isElicitOnly) {
        await cartState.refreshCart({});
        return new CartUtilities(actionContext, cartState).elicitAddProductToCart(input);
    } else {
        return cartState.addProductToCart(input);
    }
};

const AddToCartComponentActions = {
    onClick: onClick
};

const AddToCart: React.FC<IAddToCartComponentProps> = (props: IAddToCartComponentProps) => {
    const [disabled, setDisabled] = useState(false);

    const onClickHandler = (event: React.MouseEvent<HTMLElement>) => { return AddToCartComponentActions.onClick(event, props, setDisabled); };
    return (
        <button
            className={classnames('msc-add-to-cart ', props.className)}
            aria-label={props.disabled || disabled || shouldShowOutOfStock(props, false) ? 'Out of Stock' : 'Add to shopping cart'}
            onClick={onClickHandler}
            disabled={props.disabled || disabled || shouldShowOutOfStock(props, false)}
        >
            {getLinkText(props)}
        </button>
    );
};

// Set default props
AddToCart.defaultProps = {
    quantity: 1
};

const getLinkText = (props: IAddToCartComponentProps): string => {
    if (!shouldShowOutOfStock(props, false)) {
        return props.addToCartText;
    }

    return props.outOfStockText;
};

const addToCartError = (props: IAddToCartComponentProps): IAddToCartFailureResult | undefined => {
    if (!props.data || !props.data.product.RecordId) {
        // No product exists, won't be able to add to cart
        return { failureReason: 'EMPTYINPUT' };
    }

    if (props.data.product.Dimensions) {
        const missingDimensions = props.data.product.Dimensions.filter(dimension => !(dimension.DimensionValue && dimension.DimensionValue.Value));

        if (missingDimensions.length > 0) {
            // At least one dimension with no value exists on the product, won't be able to add to cart
            return { failureReason: 'MISSINGDIMENSION', missingDimensions: missingDimensions };
        }
    }

    if (shouldShowOutOfStock(props, true)) {
        const availableQuantity = (props.productAvailability && props.productAvailability.AvailableQuantity) || 0;
        const stockLeft = Math.max(availableQuantity - props.context.app.config.outOfStockThreshold, 0);

        return { failureReason: 'OUTOFSTOCK', stockLeft: stockLeft };
    }

    // Only allow adding to cart if not showing out of stock
    return undefined;
};

const shouldShowOutOfStock = (props: IAddToCartComponentProps, includeCurrentQuantity: boolean): boolean => {
    if (props.context.app.config.enableStockCheck === false) {
        // Out of stock turn off, don't bother showing out of stock
        return false;
    }

    if (props.overrideOutOfStock) {
        return false;
    }

    if (!props.data || !props.data.product.RecordId) {
        // No product exists, don't bother showing out of stock
        return false;
    }

    if (props.data.product.Dimensions) {
        if (props.data.product.Dimensions.find(dimension => !(dimension.DimensionValue && dimension.DimensionValue.Value))) {
            // At least one dimension with no value exists on the product, so also don't show out of stock
            return false;
        }
    }

    if ((props.data.product.ItemId?.toLowerCase() === props.context.request.channel?.GiftCardItemId?.toLowerCase()) || (
        props.productAvailability &&
        props.productAvailability.AvailableQuantity !== undefined &&
        props.productAvailability.AvailableQuantity >= Number(props.context.app.config.outOfStockThreshold) + (includeCurrentQuantity ? Number(props.quantity) : 1))) {
        return false;
    } else {
        // Out of stock
        return true;
    }
};

const propogateResult = (props: IAddToCartComponentProps, result: ICartActionResult): void => {
    if (props.onAdd) {
        props.onAdd(result);
    }
};

const propogateError = (props: IAddToCartComponentProps, result: IAddToCartFailureResult): void => {
    if (props.onError) {
        props.onError(result);
    }
};

// Following code is been replaced with 'AddToCart' in last line
// @ts-ignore
// export const AddToCartComponent: React.FunctionComponent<IAddToCartComponentProps> = msdyn365Commerce.createComponent<IAddtoCartComponent>(
//     'AddToCart',
//     { component: AddToCart, ...AddToCartComponentActions }
// );

export const AddToCartAction = onClick;
export default AddToCart;