import { Heading, IModuleProps } from '@msdyn365-commerce-modules/utilities';
import classnames from 'classnames';
import * as React from 'react';
import { IHeadingData, ISmweIframeConfig, ISmweIframeProps } from './smwe-iframe.props.autogenerated';

export interface IIframeViewProps extends ISmweIframeProps<ISmweIframeConfig> {
    Heading: React.ReactNode;
    IframeContainer: IModuleProps;
    IFrame: React.ReactNode;
    alert?: React.ReactNode;
    cookieConsentAccepted?: boolean;
}

/**
 *
 * Iframe component
 * @extends {React.PureComponent<ISmweIframeProps>}
 */
class Iframe extends React.PureComponent<ISmweIframeProps<ISmweIframeConfig>> {
    private iframeRef: React.RefObject<HTMLIFrameElement>;
    constructor(props: ISmweIframeProps<ISmweIframeConfig>) {
        super(props);
        this.iframeRef = React.createRef();
    }
    public render(): JSX.Element | null {
        const { config, resources } = this.props;
        const isConsentGiven =
            this.props.context.request &&
            this.props.context.request.cookies &&
            this.props.context.request.cookies.isConsentGiven &&
            this.props.context.request.cookies.isConsentGiven();

        if (!(config && config.target && config.target.destinationUrl)) {
            return null;
        }

        let srcURL;
        try {
            srcURL = new URL(config.target.destinationUrl);
        } catch (error) {
            this.props.telemetry.exception(error);
            return null;
        }

        if (srcURL && srcURL.protocol && srcURL.protocol.toLocaleLowerCase() !== 'https:') {
            return null;
        }
        const iframeClassName = classnames('ms-iframe', config.className);

        let viewProps: IIframeViewProps = {
            ...(this.props as ISmweIframeProps<ISmweIframeConfig>),
            Heading: this._createHeading(config.heading!),
            IframeContainer: {
                moduleProps: this.props,
                className: iframeClassName
            },
            IFrame: this._createIframe()
        };

        if (isConsentGiven === false) {
            viewProps = {
                ...viewProps,
                alert: this._renderAlert(resources.cookieConsentRequiredMessage)
            };
        } else {
            viewProps = {
                ...viewProps,
                cookieConsentAccepted : true
            };
        }
        return this.props.renderView(viewProps) as React.ReactElement;
    }

    private _createHeading(heading: IHeadingData): React.ReactNode | null {
        if (!heading || !heading.text || !heading.text.length) {
            return null;
        }

        return (
            <Heading {...heading} className='ms-iframe__heading' />
        );
    }

    private _renderAlert(message: string): React.ReactNode | null {
        return (
            <p className='ms-iframe__error-message' role='alert' aria-live='assertive'>
                {message}
            </p>);
    }

    private _createIframe(): React.ReactNode | null {
        const { config } = this.props;
        const targetURL = config.target.destinationUrl;
        const style: React.CSSProperties = {height: '100%', width: '100%'};

        return (
            // tslint:disable-next-line: react-iframe-missing-sandbox
            <iframe
                className={classnames('ms-iframe__content')}
                src={targetURL}
                style={style}
                frameBorder={0}
                aria-label={config.iframeAriaLabel}
                ref={this.iframeRef}
            />
        );
    }

}

export default Iframe;
