//==============================================================================
// Tools to aid in calculating shipping costs
//
// Generally, shipping is automatically calculated during checkout and left
// as unknown while shopping. Sites like Elicit have a fixed shipping cost
// model which is known during shopping. These tools examine the cart and
// apply the model to determine shipping cost.
// Note that shipping cost during checkout won't match these costs without
// careful ship method setup.
//==============================================================================
import { Cart } from '@msdyn365-commerce/retail-proxy';
import { CartUtilities } from './cart-utils';
import { filterCartLines } from './subscription-manager';

//==============================================================================
// INTERFACES AND ENUMS
//==============================================================================

// Incoming cart data used to perform the calculations
interface IOrderSummaryShipmentCalculation {
    cart: Cart;
}

// Calculated shipping cost information
interface IShipmentCalculationResult {
    price: number;
    summary: string;
}

//----------------------------------------------------------
// Table of shipping charges
//
// legend:
// G = greater than
// GE = greater than / equal to
// L = less than
// LE = less than / equal to
//----------------------------------------------------------
const CartOrderPriceGuide = {
    NONSUBSCRIPTION_LE_3: 10,
    NONSUBSCRIPTION_G_3: 20,

    SUBSCRIPTION_LE_3: 10,
    SUBSCRIPTION_GE_4_LE_5: 10,
    SUBSCRIPTION_GE_6_AND_LE_11: 5,
    SUBSCRIPTION_GE_12: 0.01,

    GIFT_CARDS_ONLY: 0,

    MIXED_ADD_PRICE: 5,
};

//==============================================================================
// EXPORTED FUNCTIONS
//==============================================================================

//----------------------------------------------------------
// Calculate shipping cost for Elicit-style shipping models
//----------------------------------------------------------
export function orderSummaryShipmentCalculation({ cart }: IOrderSummaryShipmentCalculation): IShipmentCalculationResult {

    // Split the cart into subscription and non-subscription lines
    const filter = filterCartLines(cart);

    // Determine the presense and quantity of subscriptions and non-subscription items (ignoring gift cards)
    // This assumes gift card shipping is $0. If that value changes for any reason, this logic will need to be improved.
    const subscriptionCartLength = CartUtilities.countCartLineAmountNoGiftCards(filter.subscriptionLines);
    const nonSubscriptionCartLength = CartUtilities.countCartLineAmountNoGiftCards(filter.lines);

    if (!subscriptionCartLength && !nonSubscriptionCartLength) {
        // Gift Card(s) only
        return formatShipmentCalculation('GIFT_CARDS_ONLY');
    } else if (subscriptionCartLength && !nonSubscriptionCartLength) {
        // Subscription only
        return orderSubscriptionBased(subscriptionCartLength);
    } else if (!subscriptionCartLength && nonSubscriptionCartLength) {
        // Non-subscription only
        return orderNonSubscriptionBased(nonSubscriptionCartLength);
    } else {
        // Mixed cart (subscription + non-subscription)
        return orderMixedBased(subscriptionCartLength);
    }
}

//==============================================================================
// INTERNAL FUNCTIONS
//==============================================================================

//----------------------------------------------------------
// Handle costs for non-subscription items
//----------------------------------------------------------
function orderNonSubscriptionBased(cartLength: number): IShipmentCalculationResult {
    if (cartLength <= 3) {
        return formatShipmentCalculation('NONSUBSCRIPTION_LE_3');
    } else {
        return formatShipmentCalculation('NONSUBSCRIPTION_G_3');
    }
}

//----------------------------------------------------------
// Only subscription items are in the cart
//----------------------------------------------------------
function orderSubscriptionBased(cartLength: number): IShipmentCalculationResult {
    if (cartLength <= 3) {
        return formatShipmentCalculation('SUBSCRIPTION_LE_3');
    } else if (cartLength <= 5) {
         return formatShipmentCalculation('SUBSCRIPTION_GE_4_LE_5');
    } else if (cartLength <= 11) {
        return formatShipmentCalculation('SUBSCRIPTION_GE_6_AND_LE_11');
    } else {
        return formatShipmentCalculation('SUBSCRIPTION_GE_12');
    }
}

//----------------------------------------------------------
//----------------------------------------------------------
function orderMixedBased(cartLength: number): IShipmentCalculationResult {

    // Start with the subscription cost
    const shipmentCalculationResult = orderSubscriptionBased(cartLength);

    // Increase the cost by a fixed amount
    shipmentCalculationResult.price += CartOrderPriceGuide.MIXED_ADD_PRICE;

    // Update the summary
    shipmentCalculationResult.summary = `MIXED_${shipmentCalculationResult.summary}`;

    return shipmentCalculationResult;
}

//----------------------------------------------------------
// Returns a properly formatted IShipmentCalculationResult
//----------------------------------------------------------
function formatShipmentCalculation(shipCostDefinition: keyof typeof CartOrderPriceGuide): IShipmentCalculationResult {

    // Gift cards have a price of 0, so be careful on this check
    if (CartOrderPriceGuide[shipCostDefinition] !== undefined) {
        return {
            price: CartOrderPriceGuide[shipCostDefinition],
            summary: shipCostDefinition
        };
    }

    throw new Error(`Undefined shipping cost definition: ${shipCostDefinition}`);
}