import { getSelectedProductIdFromActionInput, getSelectedVariant, SelectedVariantInput } from '@msdyn365-commerce-modules/retail-actions';
import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { getOrgUnitConfigurationAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/OrgUnitsDataActions.g';
import skuSelectorContainerClasses, { createSkuSelectorContainerClasses } from './sku-selector-container-classes';

/**
 * Input class for the getProductAvailabilitiesForSelectedVariant Data Action
 */
export class HideClubPriceInput implements IActionInput {
    public productId: number;
    public channelId: number;

    constructor(productId: number | string, channelId: number) {
        this.productId = typeof productId === 'string' ? +productId : productId;
        this.channelId = channelId;
    }

    public getCacheKey = () => `HideClubPrice|${this.productId}|${this.channelId}`;
    public getCacheObjectType = () => 'ProductAvailabilities';
    public dataCacheType = (): CacheType => 'request';
}

/**
 * createInput method for the getProductAvailabilitiesForSelectedVariant data action.
 * @param inputData The input data passed to the createInput method
 */
export const createHideClubPriceInput = (
    inputData: ICreateActionContext<IGeneric<IAny>>
): HideClubPriceInput => {
    const productId = getSelectedProductIdFromActionInput(inputData);

    if (productId) {
        return new HideClubPriceInput(+productId, +inputData.requestContext.apiSettings.channelId);
    } else {
        throw new Error('Unable to create HideClubPriceInput, no productId found on module config or query');
    }
};

/**
 * The action method for the getProductAvailabilitiesForSelectedVariant data action
 */
export async function hideClubPrice(
    input: HideClubPriceInput,
    ctx: IActionContext
): Promise<void> {
    const selectedVariantInput = new SelectedVariantInput(input.productId, input.channelId);
    const product = await getSelectedVariant(selectedVariantInput, ctx);
    const channelConfiguration = await getOrgUnitConfigurationAsync({ callerContext: ctx});

    if (
      product &&
      (product.ItemId === channelConfiguration.GiftCardItemId)
    ) {
      await skuSelectorContainerClasses(
        createSkuSelectorContainerClasses('no-club'),
        ctx
      );
    }
}

/**
 * The complete getProductAvailabilitiesForSelectedVariant data action
 * Get the currently selected variant via the getSelectedVariant data action, and
 * then gets the availabilities for the variant via the getProductAvailabilities RetailServer API
 */
export default createObservableDataAction({
    action: <IAction<void>>hideClubPrice,
    input: createHideClubPriceInput
});
