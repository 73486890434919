import { Button } from '@msdyn365-commerce-modules/utilities';
import classnames from 'classnames';
import * as React from 'react';

export type CallToActionLayout = 'button' | 'glyphOnly' | 'noGlyph';
export type CallToActionMotion = 'separate' | 'revelio';
export type CallToActionGlyphPosition = 'start' | 'end';

interface ICallToAction {
  title: string;
  href?: string;
  openInNewTab?: boolean;
  'aria-label'?: string;
  className?: string;
  color?: string;
  glyph?: string;
  layout: CallToActionLayout;
  glyphPosition?: CallToActionGlyphPosition;
  disabled?: boolean;
  onClick?(e: React.MouseEvent<HTMLButtonElement>): void;
}

function createInnerCTAFragment(
  glyphClassName: string,
  title: string,
  ctaLayout: CallToActionLayout,
  glyphPosition?: CallToActionGlyphPosition
): React.ReactFragment {
  if (ctaLayout === 'noGlyph') {
    return <span className='call-to-action__string'>{title}</span>;
  }

  if (ctaLayout === 'glyphOnly') {
    return <i className={glyphClassName} aria-hidden='true' />;
  }

  switch (glyphPosition) {
    case 'start':
      return (
        <div className='call-to-action__flex-container'>
          <i className={`${glyphClassName}`} aria-hidden='true' />
          <span className='call-to-action__string'>{title}</span>
        </div>
      );
    default:
      return (
        <div className='call-to-action__flex-container'>
          <span className='call-to-action__string'>{title}</span>
          <i className={`${glyphClassName}`} aria-hidden='true' />
        </div>
      );
  }
}

/**
 * Class that renders a Call to action link
 */
export default class CallToAction extends React.PureComponent<ICallToAction> {
  public static defaultProps: Partial<ICallToAction> = {
    glyph: 'fa fa-chevron-right',
    layout: 'button'
  };

  private static callToActionBaseClass: string = 'call-to-action';
  public render(): JSX.Element {
    const glyphClassname: string = `call-to-action__glyph ${this.props.glyph!}`;

    const callToActionContent: React.ReactFragment = createInnerCTAFragment(
      glyphClassname,
      this.props.title,
      this.props.layout,
      this.props.glyphPosition
    );

    const callToActionClass: string = classnames(
      CallToAction.callToActionBaseClass,
      this.props.className || 'smwe-cta__primary',
      'btn',
      this.props.color ? `btn-${this.props.color}` : ''
    );
    return (
      <Button
        className={callToActionClass}
        title={this.props.title}
        href={this.props.href}
        aria-label={this.props['aria-label']}
        target={this.props.openInNewTab ? '_blank' : undefined}
        color={this.props.color}
        onClick={this.props.onClick}
        disabled={this.props.disabled}
      >
        {callToActionContent}
      </Button>
    );
  }
}
