import { ICoreContext } from '@msdyn365-commerce/core-internal';
interface IBrandCodeProps {
    description?: string;
    appContext: ICoreContext<{
        // any is just matching the types here.
        // tslint:disable-next-line: no-any
        [x: string]: any;
    }>;
}

type BrandCodeTable = {
    brandName: string;
    brandCode: string;
}[];

/**
 * for converting a brand code to a brand name
 *
 */
export function brandCodeParser({ description, appContext }: IBrandCodeProps): string {

    // for mocking
    // const brandCodeTable: BrandCodeTable = [{ brandName: '14Hands', brandCode: 'FTH' }];

    const brandCodeTable = appContext.app.config.brandCodeTable as BrandCodeTable;

    // this is a safety check to ensure that brand code table does NOT crash in local or
    // if brand code table is somehow not connecting right to the global config. it will
    // essentially act as if there are no brand codes declared - keeping how everything
    // looks originally
    if (!brandCodeTable) { return ''; }

    // a brand code may and may only appear in the first three characters of a string
    // we are lowercasing in the off-chance that the authors type them differently between
    // the sources
    const currentBrandCode = description && description.slice(0, 3).toLowerCase();
    const brandFound = brandCodeTable.find(brand => brand.brandCode.toLowerCase() === currentBrandCode);
    if (brandFound) { return brandFound.brandName; }
    return '';
}