import { getMediaLocationsForSelectedVariant, MediaLocationsForSelectedVariantInput} from '@msdyn365-commerce-modules/retail-actions';
import { IActionContext, IImageData  } from '@msdyn365-commerce/core';
import { MediaLocation } from '@msdyn365-commerce/retail-proxy';

export async function getValidProductImages(productId: number, channelId: number, actionContext: IActionContext): Promise<IImageData[]> {
    const actionInput = new MediaLocationsForSelectedVariantInput(
        productId,
        channelId,
    );

    return getMediaLocationsForSelectedVariant(actionInput, actionContext)
        .then(mediaLocations => {
            if (mediaLocations) {
                return Promise.all(mediaLocations.map(validateMediaLocaionAsync))
                    .then(pairs => {
                        return pairs.filter(pair => pair[1]).map(pair => pair[0]);
                    });
            }

            return [];
        }).catch(error => {
            actionContext.telemetry.error(error);

            return [];
        });
}

async function validateMediaLocaionAsync(mediaLocation: MediaLocation): Promise<[IImageData, boolean]> {
    const imageData = {
        src: mediaLocation.Uri || '',
        altText: mediaLocation.AltText || ''
    };

    if (imageData.src === '') {
        return [imageData, false];
    }

    return new Promise<[IImageData, boolean]>((resolve) => {
        try {
            const http = new XMLHttpRequest();
            http.open('HEAD', mediaLocation.Uri!, true);

            http.onload = () => {
                resolve([imageData, http.status === 200 || http.status === 201]);
            };

            http.onerror = () => {
                resolve([imageData, false]);
            };

            http.send();
        } catch (e) {
            resolve([imageData, false]);
        }
    });
}